import { createIcon } from '@terminal/design-system';

export const LinkedInIcon = createIcon({
  displayName: 'LinkedInIcon',
  viewBox: '0 0 17 16',
  path: (
    <path
      d="M3.77999 15.0866H0.539987V5.79998H3.77999V15.0866ZM10.5067 8.82665C10.1283 8.82665 9.7654 8.97696 9.49785 9.24451C9.2303 9.51206 9.07999 9.87494 9.07999 10.2533V15.0866H5.67332V5.79998H9.07999V6.85998C9.87846 6.18402 10.8872 5.80691 11.9333 5.79332C14.0467 5.79332 15.5133 7.35998 15.5133 10.3333V15.0866H11.9333V10.2533C11.9342 10.0654 11.8979 9.87917 11.8266 9.70531C11.7553 9.53145 11.6504 9.37338 11.5178 9.2402C11.3853 9.10701 11.2277 9.00133 11.0542 8.92922C10.8806 8.8571 10.6946 8.81998 10.5067 8.81998V8.82665ZM3.83999 2.58665C3.83999 2.91629 3.74224 3.23852 3.5591 3.5126C3.37597 3.78668 3.11567 4.0003 2.81113 4.12645C2.50658 4.25259 2.17147 4.2856 1.84817 4.22129C1.52487 4.15698 1.2279 3.99825 0.994809 3.76516C0.761721 3.53207 0.602987 3.2351 0.538678 2.9118C0.474369 2.5885 0.507375 2.25339 0.633521 1.94884C0.759667 1.6443 0.973288 1.384 1.24737 1.20087C1.52145 1.01773 1.84368 0.919983 2.17332 0.919983C2.61303 0.923501 3.03354 1.10065 3.34322 1.41283C3.6529 1.72501 3.82667 2.14692 3.82665 2.58665H3.83999Z"
      fill="none"
      stroke="#27393F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export const WebsiteIcon = createIcon({
  displayName: 'WebsiteIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M0.5 4.5V5.84467C0.500105 6.60788 0.645821 7.36407 0.929333 8.07267L1.5 9.5L2.5 6L3.5 9.5L4.07067 8.07267C4.35418 7.36407 4.49989 6.60788 4.5 5.84467V4.5"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.5V5.84467C6.00011 6.60788 6.14582 7.36407 6.42933 8.07267L7 9.5L8 6L9 9.5L9.57067 8.07267C9.85418 7.36407 9.99989 6.60788 10 5.84467V4.5"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 4.5V5.84467C11.5001 6.60788 11.6458 7.36407 11.9293 8.07267L12.5 9.5L13.5 6L14.5 9.5L15.0707 8.07267C15.3542 7.36407 15.4999 6.60788 15.5 5.84467V4.5"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.5H6L6.5 12.5H9.5L10 15.5Z"
        fill="none"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 15.5H11.5" stroke="#27393F" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 0.5H15.5" stroke="#27393F" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5 12.5H15.5" stroke="#27393F" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export const PlayVideoIcon = createIcon({
  displayName: 'PlayVideoIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M1.05865 2.17001V13.83C1.05864 14.0029 1.10345 14.1729 1.18871 14.3233C1.27397 14.4737 1.39678 14.5994 1.54513 14.6882C1.69349 14.777 1.86233 14.8258 2.03517 14.8298C2.20802 14.8339 2.37896 14.7931 2.53132 14.7113L13.4133 8.88135C13.5727 8.79588 13.706 8.66878 13.7989 8.51358C13.8918 8.35838 13.9408 8.18089 13.9408 8.00001C13.9408 7.81914 13.8918 7.64165 13.7989 7.48645C13.706 7.33124 13.5727 7.20414 13.4133 7.11868L2.53132 1.28868C2.37896 1.20696 2.20802 1.16614 2.03517 1.1702C1.86233 1.17426 1.69349 1.22306 1.54513 1.31185C1.39678 1.40063 1.27397 1.52636 1.18871 1.67677C1.10345 1.82718 1.05864 1.99712 1.05865 2.17001Z"
      stroke="#25383F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
});

export const PDFIcon = createIcon({
  displayName: 'PDFIcon',
  viewBox: '0 0 17 17',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99731 16.4987H1.99731C1.44503 16.4987 0.997314 16.0509 0.997314 15.4987V2.49866C0.997314 1.94637 1.44503 1.49866 1.99731 1.49866H9.08331C9.34867 1.49871 9.60313 1.60424 9.79065 1.79199L13.7046 5.70599C13.8922 5.89362 13.9974 6.14806 13.9973 6.41332V9.49866"
        fill="none"
      />
      <path
        d="M3.99731 16.4987H1.99731C1.44503 16.4987 0.997314 16.0509 0.997314 15.4987V2.49866C0.997314 1.94637 1.44503 1.49866 1.99731 1.49866H9.08331C9.34867 1.49871 9.60313 1.60424 9.79065 1.79199L13.7046 5.70599C13.8922 5.89362 13.9974 6.14806 13.9973 6.41332V9.49866"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9973 6.49866H9.99731C9.44503 6.49866 8.99731 6.05094 8.99731 5.49866V1.49866"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99731 16.4987V11.4987"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99731 11.4987H6.49998C7.32841 11.4987 7.99998 12.1702 7.99998 12.9987C7.99998 13.8271 7.32841 14.4987 6.49998 14.4987H5.99998"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99731 16.4987C11.1019 16.4987 11.9973 15.6032 11.9973 14.4987V13.4987C11.9973 12.3941 11.1019 11.4987 9.99731 11.4987V16.4987Z"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9973 16.4987V12.4987C13.9973 11.9464 14.445 11.4987 14.9973 11.4987H15.9973"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9973 14.4987H15.4973"
        stroke="#334E57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const GithubIcon = createIcon({
  displayName: 'GithubIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <path
        d="M13.5 10.6666C13.5 6.66663 10.7467 8.3133 8.50001 8.3133C6.25334 8.3133 3.50001 6.66663 3.50001 10.6666C3.50877 11.2797 3.75846 11.8646 4.1951 12.295C4.63174 12.7254 5.22023 12.9667 5.83334 12.9666H11.1667C11.7798 12.9667 12.3683 12.7254 12.8049 12.295C13.2416 11.8646 13.4912 11.2797 13.5 10.6666Z"
        fill="none"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.02 6.26659C15.02 6.26659 16.8533 3.15325 14.6333 1.33325C13.26 1.33325 11.1667 3.58659 11.1667 3.58659C9.42392 3.08753 7.57609 3.08753 5.83334 3.58659C5.83334 3.58659 3.76001 1.33325 2.40001 1.33325C0.226673 3.10659 1.90001 6.12659 1.98001 6.26659C1.21953 7.4293 0.880696 8.8176 1.02001 10.1999C1.16667 12.3333 2.97334 14.6666 5.11334 14.6666H11.88C14.0267 14.6666 15.8067 12.3333 15.9733 10.1933C16.1141 8.81384 15.7777 7.42786 15.02 6.26659V6.26659Z"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8467 11.1333C10.66 11.1333 10.5133 10.9066 10.5133 10.6333C10.5133 10.36 10.66 10.1333 10.8467 10.1333C11.0333 10.1333 11.18 10.36 11.18 10.6333C11.18 10.9066 11.0333 11.1333 10.8467 11.1333Z"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.18001 11.1333C5.99334 11.1333 5.84667 10.9066 5.84667 10.6333C5.84667 10.36 5.99334 10.1333 6.18001 10.1333C6.36667 10.1333 6.51334 10.36 6.51334 10.6333C6.51334 10.9066 6.36667 11.1333 6.18001 11.1333Z"
        stroke="#27393F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
