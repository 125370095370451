import { useLayoutEffect } from 'react';
import { useLocalStorage, useQuery } from 'global/utils';
import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useCustomerAuthorizedUserSession, useSavedCandidates } from 'talent-hub/utils';
import { candidateSearchDateRange, ViewType } from 'talent-hub/shared/features';
import { sortOptionBackendValues } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';

// TODO: move these to a shared place
import type {
  SelectDashboardBrowseCandidatesQuery,
  SelectDashboardBrowseCandidatesQueryVariables,
} from 'talent-hub/role/prospect/dashboard/data';
import { SelectDashboardBrowseCandidates } from 'talent-hub/role/prospect/dashboard/data';
import { useHistory, useParams } from 'react-router-dom';
import { CandidateProfileController } from '../explore-candidates/candidate-profile';

import { Dashboard } from './Dashboard';
import type { SelectDashboardQuery, SelectDashboardQueryVariables } from './data';
import { SelectDashboard } from './data';
import { serializeDashboard, serializeDashboardOtherGreatCandidates } from './Dashboard.serializer';
import { DashboardActiveRolesController, DashboardPendingRolesController } from './dashboard-roles';

const millisecondsInADay = 24 * 60 * 60 * 1000;

const browseOtherCandidatesQuery_defaultVariables = ({
  organizationID,
  userID,
  isClientProspect,
}: {
  organizationID: number;
  userID: number;
  isClientProspect: boolean;
}) => {
  const candidateBrowseArgs = {
    organization_id: organizationID,
    candidate_role: null,
    must_have_skill_ids: [],
    should_have_skill_ids: [],
    regions: null,
    // country: null,
    min_years_of_experience: null,
    sort_option: sortOptionBackendValues.Recommended,
    employment_type: null,
    badges: null,
  };

  return {
    organization: organizationID,
    user_id: userID,
    updatedAfter: candidateSearchDateRange,
    isClientProspect,
    candidate_recently_active_args: {
      ...candidateBrowseArgs,
      sort_option: null,
    },
    candidate_top_company_experience_args: {
      ...candidateBrowseArgs,
      badges: ['top_company_exp'],
    },
    candidate_zero_one_experience_args: {
      ...candidateBrowseArgs,
      badges: ['built_new'],
    },
    candidate_tech_lead_args: {
      ...candidateBrowseArgs,
      badges: ['tech_leader'],
    },
  };
};

export function DashboardController() {
  const [active_roles_bookmark_ids] = useLocalStorage<number[]>('active_role_bookmark_ids', []);
  const history = useHistory();
  const { candidateID } = useParams<{ candidateID?: string }>();
  const {
    user: { created_at },
  } = useCustomerAuthorizedUserSession();
  const isRecentUser =
    // Check if the user was created less than 60 days ago in milliseconds
    new Date(created_at) > new Date(new Date().getTime() - 60 * millisecondsInADay);
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  useLayoutEffect(() => {
    // ! Since this page has multiples routes at same page ScrollToTopOnPathChange is not working properly, so we need to scroll up manually
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);

  const { loading: isLoading_selectDashboard, data: selectDashboard_data } = useQuery<
    SelectDashboardQuery,
    SelectDashboardQueryVariables
  >(SelectDashboard, {
    variables: {
      organization_id: viewingOrganization.ID,
      user_id: user.id,
      job_folder_approved: JOB_FOLDER_APPROVED,
      job_folder_filled: JOB_FOLDER_FILLED,
    },
    fetchPolicy: 'network-only',
  });

  const {
    totalSubmittedCandidates,
    totalActiveMembers,
    totalOpenRoles,
    totalOpenPositions,
    totalActiveCandidates,
    hasMoreRoles,
    roles,
  } = serializeDashboard({
    data: selectDashboard_data,
    bookmarkedRoleIDs: active_roles_bookmark_ids,
  });

  const { loading: isLoading_otherGreatCandidates, data: otherGreatCandidates_data } = useQuery<
    SelectDashboardBrowseCandidatesQuery,
    SelectDashboardBrowseCandidatesQueryVariables
  >(SelectDashboardBrowseCandidates, {
    variables: browseOtherCandidatesQuery_defaultVariables({
      organizationID: viewingOrganization.ID,
      userID: user?.id,
      isClientProspect,
    }),
    notifyOnNetworkStatusChange: true,
    skip: !user?.id || !viewingOrganization.ID,
  });

  const candidates = serializeDashboardOtherGreatCandidates(
    // TODO: Remove location from that query, is not being used
    otherGreatCandidates_data as Omit<SelectDashboardBrowseCandidatesQuery, 'location'>,
  );

  const { deleteSavedCandidate, insertSavedCandidate } = useSavedCandidates();
  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };
  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  return (
    <>
      <Dashboard
        isRecentUser={isRecentUser}
        handleCandidateClick={(currentCandidateID) =>
          history.push(`/dashboard/candidate/${currentCandidateID}`)
        }
        active_roles={roles.active.map((role) => (
          <DashboardActiveRolesController role={role} key={`role-${role.id}`} />
        ))}
        pending_roles={roles.pending.map((role) => (
          <DashboardPendingRolesController role={role} key={`role-${role.id}`} />
        ))}
        widgets={{
          totalSubmittedCandidates,
          totalActiveMembers,
          totalOpenRoles,
          totalOpenPositions,
          totalActiveCandidates,
        }}
        has_more_roles={hasMoreRoles}
        isLoadingDashboard={isLoading_selectDashboard}
        isLoading_otherGreatCandidates={isLoading_otherGreatCandidates}
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        candidatesRecentlyActive={candidates.recentlyActive}
        candidatesTopCandidateExperience={candidates.topCandidateExperience}
        candidateZeroOneExperience={candidates.zeroOneExperience}
        candidateTechLead={candidates.techLeadExperience}
      />
      <CandidateProfileController
        key={`candidate-${candidateID}`}
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={() => history.push(`/dashboard`)}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        viewPage={ViewType.DASHBOARD}
      />
    </>
  );
}
