import { useReducer } from 'react';
import type { Reducer } from 'react';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';

import { firebaseAuth } from 'global/firebaseApp';
import {
  linkWithCredential,
  createUserWithEmailAndPassword,
  updateProfile,
  EmailAuthProvider,
} from 'global/auth/authMethods';
import { getIdTokenResult } from 'firebase/auth';
import type { User } from 'firebase/auth';
import { graphqlClient } from 'global/graphqlClient';
import isWebview from 'is-ua-webview';
import { getUA } from 'react-device-detect';
import authContext from 'global/authContext';
import * as events from 'global/events';
import FindBlacklistPasswords from '../FindBlacklistPasswords.graphql';
import type { FindBlacklistPasswordsQuery, FindBlacklistPasswordsQueryVariables } from '../types';
import { AuthError, useHandleAuthBackendError, useShouldHideGoogleSSO } from '../auth.utils';

export function useSignUp({
  continueURL,
  contextRole = 'authenticated',
}: {
  continueURL: string;
  eventNames?: {
    hiddenGoogleSSO: string;
    firebaseInCompatibleWebView: string;
  };
  contextRole?: string;
}) {
  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );
  const { isError, isLoading, isResolved } = toAsyncProps(state);

  const shouldHideGoogleSSO = useShouldHideGoogleSSO({
    shouldTrack: false,
  });

  useHandleAuthBackendError({
    isError,
    error: state.error,
    errorsWithFriendlyMessage: {
      'auth/email-already-in-use': 'An account is already registered with this email address.',
      'candidate/match-blacklist-pass': 'That password is too common. Please try again.',
    },
  });

  // TODO: rename to handleManualSignUpSubmit
  const handleSignUpSubmit = async ({
    email,
    firstName = '',
    lastName = '',
    password,
    fullName,
  }: {
    email: string;
    firstName?: string;
    lastName?: string;
    password: string;
    fullName?: string;
  }) => {
    try {
      const cleanFirstName = firstName.trim();
      const cleanLastName = lastName.trim();

      events.track(events.name.firebaseRegistration.started, {
        type: 'email-password',
        user_agent: getUA,
        is_webview: isWebview(getUA),
      });
      dispatch({ type: 'pending' });

      const client = graphqlClient('candidate:auth', () => contextRole);

      const { data, errors } = await client.query<
        FindBlacklistPasswordsQuery,
        FindBlacklistPasswordsQueryVariables
      >({
        query: FindBlacklistPasswords,
        variables: {
          matching_password: password,
        },
        context: {
          role: contextRole,
        },
        fetchPolicy: 'network-only',
      });

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (errors?.length) throw errors[0];

      // is the password matching with the black list passwords?
      if (data.passwords.length) {
        throw new AuthError(
          `Password (${password}) matched blacklist password`,
          'candidate/match-blacklist-pass',
        );
      }

      if (firebaseAuth.currentUser?.isAnonymous) {
        await linkWithCredential(
          firebaseAuth.currentUser,
          EmailAuthProvider.credential(email, password),
        );
      } else {
        await createUserWithEmailAndPassword(firebaseAuth, email, password);
      }

      // we need to concatenate with \u2002 so it can be split in the backend
      const displayName = fullName?.trim?.() || `${cleanFirstName}\u2002${cleanLastName}`;
      await updateProfile(firebaseAuth.currentUser as User, { displayName });

      const url = `${import.meta.env.REACT_APP_AUTH_URL}/user/verify-email`;
      const { headers } = await authContext(firebaseAuth, 'web', 'verify');
      await fetch(url, {
        method: 'POST',
        headers: { ...headers, 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: continueURL }),
      });

      // syncs the user with the new display new  display name to the backend.
      await getIdTokenResult(firebaseAuth.currentUser as User, true);

      dispatch({ type: 'resolved', data: null });

      events.track(events.name.firebaseRegistration.completed, {
        type: 'email-password',
        user_agent: getUA,
        is_webview: isWebview(getUA),
      });
    } catch (error) {
      dispatch({
        type: 'rejected',
        error,
      });
    }
  };

  return {
    isError,
    isLoading,
    isResolved,
    handleSignUpSubmit,
    shouldHideGoogleSSO,
  };
}
