import type { SpaceProps } from '@terminal/design-system';
import { Flex, Checkbox } from '@terminal/design-system';

export interface InteractiveValidationProps extends SpaceProps {
  isComplete: boolean;
  isInvalid?: boolean;
  message: string;
}

export function RequiredInstruction({
  isComplete,
  isInvalid,
  message,
  ...spaceProps
}: InteractiveValidationProps) {
  return (
    <Flex alignItems="center" {...spaceProps}>
      <Checkbox
        isChecked={isComplete}
        isInvalid={isInvalid}
        variant="circle-readonly"
        isReadOnly
        _hover={{ cursor: 'default' }}
        color="text.primary"
        size="sm"
        tabIndex={-1}
      >
        {message}
      </Checkbox>
    </Flex>
  );
}
