import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { Dashboard } from './Dashboard';

import type { SelectDashboardQuery, SelectDashboardQueryVariables } from './data';
import { SelectDashboard } from './data';
import { serializeDashboard } from './Dashboard.serializer';

export function DashboardController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const { loading, data } = useQuery<SelectDashboardQuery, SelectDashboardQueryVariables>(
    SelectDashboard,
    {
      variables: {
        organization: viewingOrganization.ID,
        job_folder_approved: JOB_FOLDER_APPROVED,
        job_folder_filled: JOB_FOLDER_FILLED,
      },
      fetchPolicy: 'network-only',
    },
  );

  const {
    candidatesToReview,
    openRoles,
    openPositions,
    activeCandidates,
    totalSubmittedCandidates,
    viewMoreCandidatesTitle,
  } = serializeDashboard(data);

  return (
    <Dashboard
      positionsInfo={{
        openRoles,
        openPositions,
      }}
      candidatesInfo={{
        activeCandidates,
        submittedCandidates: totalSubmittedCandidates,
      }}
      loading={loading}
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      userDisplayName={user?.first_name || user?.email}
      candidatesToReview={candidatesToReview}
      viewMoreCandidatesTitle={viewMoreCandidatesTitle}
    />
  );
}
