import { useLocation } from 'react-router-dom';
import { EmailVerificationIcon, Link, Text } from '@terminal/design-system';
import { AuthPageTemplate, AuthMessage } from '../components';
import { useAuthContinueUrl } from '../auth.utils';
import { useSignUp } from './SignUp.utils';
import { ManualWithSSOSignUp } from './SignUp';
import type { AuthPageTemplateProps } from '../types';

// TODO: [TAL-690] SignUp Page should be customizable similar to the SignIn Page.
// And its stories should use the Sign Up page instead of composing the page in the story.
// Look up the SignInPage.stories.tsx

export function SignUpController({
  redirectTo,
  handleGoogleSignUp,
  pageTemplateOverwrite,
}: {
  redirectTo: (path: string) => void;
  handleGoogleSignUp: (props?: { hintEmail?: string }) => void;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const continueURL = useAuthContinueUrl();
  const location = useLocation<{
    from: { pathname: string; search: string; hash: string; url?: string }; // TODO: rename this to to
    email?: string;
    firstName?: string;
    lastName?: string;
    to?: { pathname: string; search: string; hash: string; url?: string };
  }>();
  const queryString = new URLSearchParams(useLocation().search);

  const predefinedEmail = location?.state?.email;
  const predefinedEmailFromQuery = queryString.get('email') || undefined;

  const { handleSignUpSubmit, isLoading, isResolved, shouldHideGoogleSSO } = useSignUp({
    continueURL,
  });

  let variant: 'default' | 'jobApply' | 'claim' = 'default';
  if (location?.state?.from?.pathname === '/job-apply') variant = 'jobApply';
  if (predefinedEmailFromQuery) variant = 'claim';

  const copyVariants = {
    default: {
      AuthPageTemplate: {
        headerTitle: 'Create an Account',
        headerSubtitle: 'Sign up for a Terminal account to view your profile.',
      },
      ManualWithSSOSignUp: {
        isEmailInputDisabled: false,
        googleSignUpInstruction: undefined,
        termsCopy: null,
        email: predefinedEmail,
        onGoogleLoginClicked: handleGoogleSignUp,
      },
    },
    jobApply: {
      AuthPageTemplate: {
        headerTitle: 'Create a Terminal Account',
        headerSubtitle: 'Join Terminal for access to the best U.S. technology jobs.',
      },
      ManualWithSSOSignUp: {
        isEmailInputDisabled: !!predefinedEmail,
        googleSignUpInstruction: `Please make sure to use the same email: ${predefinedEmail}`,
        termsCopy: (
          <>
            By signing up you agree to the{' '}
            <Link
              fontSize="inherit"
              href="https://terminal.io/terms-of-use"
              isExternal
              display="inline"
            >
              Terms Of Use
            </Link>
            {' & '}
            <Link
              fontSize="inherit"
              href="https://terminal.io/job-applicant-privacy"
              isExternal
              display="inline"
            >
              Job Applicant Notice
            </Link>
          </>
        ),
        email: predefinedEmail,
        onGoogleLoginClicked: handleGoogleSignUp,
      },
    },
    claim: {
      AuthPageTemplate: {
        // TODO: Although this works, it is only gonna do what intented in prod (where the talent
        // hub part of the app really lives under hub.terminal.io) this improvement is addressed
        // in https://terminal.atlassian.net/browse/GROW-108
        headerTitle: `Claim Your ${
          window.location.hostname === 'hub.terminal.io' ? 'Talent Hub' : ''
        } Account!`,
        headerSubtitle:
          'With your account you’ll be able to manage your job postings, hires and much more.',
      },
      ManualWithSSOSignUp: {
        isEmailInputDisabled: true,
        googleSignUpInstruction: `Please make sure to use the same email: ${predefinedEmailFromQuery}`,
        termsCopy: (
          <>
            By claiming you agree to the{' '}
            <Link
              fontSize="inherit"
              href="https://terminal.io/terms-of-use"
              isExternal
              display="inline"
            >
              Terms Of Use
            </Link>
          </>
        ),
        googleSignUpButton: 'Claim with Google',
        email: predefinedEmailFromQuery,
        signUpButton: 'Claim Account',
        onGoogleLoginClicked: () => handleGoogleSignUp({ hintEmail: predefinedEmailFromQuery }),
      },
    },
  };

  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <>
      {!isResolved && (
        <PageTemplate
          footerText="Already have an account?"
          footerLinkOnClick={() => redirectTo('login')}
          footerLinkLabel="Login"
          {...copyVariants[variant]?.AuthPageTemplate}
        >
          <ManualWithSSOSignUp
            onEmailSignSubmitted={handleSignUpSubmit}
            showLoadingForEmailSignUp={isLoading}
            firstName={location?.state?.firstName}
            lastName={location?.state?.lastName}
            shouldHideGoogleSSO={shouldHideGoogleSSO}
            {...copyVariants[variant]?.ManualWithSSOSignUp}
          />
        </PageTemplate>
      )}
      {isResolved && (
        <PageTemplate>
          <AuthMessage icon={<EmailVerificationIcon />} title="Please Verify Your Email">
            <Text>
              A verification email has been sent to your email account. Please check your inbox to
              verify. If you have any problems, please{' '}
              <Link href="https://help.terminal.io/" isExternal display="inline">
                contact us.
              </Link>
            </Text>
          </AuthMessage>
        </PageTemplate>
      )}
    </>
  );
}
