import { css } from '@emotion/react';
import {
  Heading,
  Text,
  Divider,
  GridItem,
  Badge,
  SimpleGrid,
  Flex,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';
import { useHistory, useParams } from 'react-router-dom';
import { roleTabs } from '../../components';

/** @deprecated
 * WARNING: This component is very exposed to XSS injection, it should be removed or an HTML sanitizer should be included to reduce the risk.
 * https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part2.html
 * */
export function RenderFieldHTML({ html }: { html: string }) {
  return (
    <Text whiteSpace="pre-line">
      <div
        css={css`
          white-space: pre-line;
          ul {
            padding-top: 0.2;
            padding-left: 1.2em;
          }
        `}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Text>
  );
}

export function EmptyFieldText({ children }: { children: React.ReactNode }) {
  return <Text>{children}</Text>;
}

export function FieldRow({
  children,
  emptyText = 'Not Specified',
  label,
  value,
}: {
  children?: React.ReactNode;
  emptyText?: string;
  label: string;
  value?: string | number | string[] | number[] | null;
}) {
  const isEmpty = Array.isArray(value) ? value.length : children || value;

  const renderValue = () => {
    if (children) {
      return children;
    }

    if (Array.isArray(value)) {
      return value.map((val) => (
        <Badge key={`${val}-${label}`} fontSize="sm" colorScheme="accent-lightest" mr={2} mb={2}>
          {val}
        </Badge>
      ));
    }

    return <Text>{value}</Text>;
  };

  return (
    <>
      <GridItem colSpan={1}>
        <Text variant="label" textAlign="left">
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={3} mb={2}>
        {isEmpty ? renderValue() : <EmptyFieldText>{emptyText}</EmptyFieldText>}
      </GridItem>
    </>
  );
}

export function FieldDivider() {
  return (
    <GridItem colSpan={4} my={6}>
      <Divider />
    </GridItem>
  );
}

function JobRequirementsLayout({ children }: { children: React.ReactElement }) {
  const history = useHistory();
  const { activeRole, activeTab } = useParams<{ activeRole: string; activeTab: string }>();
  return (
    <Drawer
      isOpen={activeTab === roleTabs.requirements}
      onClose={() => history.push(`/role/${activeRole}/${roleTabs.overview}`)}
      size="3xl"
    >
      <DrawerOverlay />
      <DrawerContent h="full" justifyContent="space-between">
        <DrawerCloseButton color="brand.main" size="lg" zIndex="sticky" left={3} top={3} />
        <DrawerBody p={0}>
          <Box border="1px solid" borderColor="ui.secondary">
            <Flex bg="bg.tertiary" w="full" borderBottom="1px solid" borderColor="brand.lighter">
              <Heading variant="heading-1" p={10} pt={14}>
                Role Requirements
              </Heading>
            </Flex>
            {children}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function JobRequirements({
  job,
  isLoading,
}: {
  job: {
    employment_type: string | null;
    additionalInfo: string | null;
    companySize: string | null;
    experiences: string[];
    headcount: string;
    location: string;
    niceToHaveSkills: string | null;
    requiredSkills: string[];
    roleDescription: string | null;
    seniority: string;
    title: string | null;
    whatYouBring: string | null;
    whatYouWillDo: string | null;
  } | null;
  isLoading: boolean;
}) {
  if (isLoading) {
    return (
      <JobRequirementsLayout>
        <SimpleGrid>
          <BlankScreenLoading />
        </SimpleGrid>
      </JobRequirementsLayout>
    );
  }

  if (!job) {
    throw new Error("Job doesn't exist");
  }

  const {
    title,
    employment_type,
    headcount,
    experiences,
    location,
    requiredSkills,
    niceToHaveSkills,
    roleDescription,
    additionalInfo,
    whatYouWillDo,
    whatYouBring,
    companySize,
  } = job;

  return (
    <JobRequirementsLayout>
      <SimpleGrid columns={4} gap={4} p={10}>
        <FieldRow label="Title" value={title} />
        <FieldRow label="Employment Type" value={employment_type} />
        <FieldRow label="Location" value={location} />
        <FieldRow label="Headcount" value={headcount} />
        <FieldDivider />
        <FieldRow label="Experience" value={experiences} />
        <FieldRow label="Required Skills" value={requiredSkills} />
        <FieldRow label="Good-to-Have Skills">
          {!!niceToHaveSkills && <RenderFieldHTML html={niceToHaveSkills} />}
        </FieldRow>
        <FieldDivider />
        <FieldRow label="Role Description">
          {!!roleDescription && <RenderFieldHTML html={roleDescription} />}
        </FieldRow>
        <FieldRow label="Day-to-Day Activities">
          {!!whatYouWillDo && <RenderFieldHTML html={whatYouWillDo} />}
        </FieldRow>
        <FieldRow label="What You Bring">
          {!!whatYouBring && <RenderFieldHTML html={whatYouBring} />}
        </FieldRow>
        {!!additionalInfo && (
          <FieldRow label="Additional Info">
            <RenderFieldHTML html={additionalInfo} />
          </FieldRow>
        )}
        <FieldDivider />
        <FieldRow label="Size of Company" value={companySize} />
      </SimpleGrid>
    </JobRequirementsLayout>
  );
}
