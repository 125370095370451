import { textTheme } from '../../Text/Text.theme';

const parts = ['container', 'control', 'label', 'icon'];

export const checkboxTheme = {
  parts,
  baseStyle: {
    control: {
      w: '100%',
      transition: 'box-shadow 250ms',
    },
    label: {
      userSelect: 'none',
      _disabled: { opacity: 0.4 },
      _invalid: {
        color: 'ui.error',
      },
    },
  },
  variants: {
    square: {
      container: {
        color: 'text.primary',

        _hover: {
          color: 'accent.main',
        },

        _focus: {
          color: 'accent.main',
          boxShadow: 'none',
          outlineColor: 'accent.darkest',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineOffset: '4px',
        },
      },
      label: {
        color: 'inherited',

        '[data-disabled] &': {
          opacity: 1,
          color: 'text.disabled',
        },
      },
      control: {
        border: '1.5px solid',
        borderRadius: 'sm',
        borderColor: 'currentColor',
        color: 'unset',

        _checked: {
          bg: 'brand.main',
          borderColor: 'brand.main',
          color: 'ui.inverse.primary',

          _hover: {},

          _disabled: {
            bg: 'ui.darker.disabled',
            borderColor: 'ui.darker.disabled',
            color: 'ui.inverse.primary',
          },

          _invalid: {
            borderColor: 'ui.error',
            color: 'ui.inverse.primary',
          },
        },

        _indeterminate: {
          bg: 'brand.main',
          borderColor: 'brand.main',
          color: 'ui.inverse.primary',

          _hover: {},

          _disabled: {
            bg: 'ui.darker.disabled',
            borderColor: 'ui.darker.disabled',
            color: 'ui.inverse.primary',
          },
        },

        _disabled: {
          bg: 'ui.inverse.primary',
          borderColor: 'ui.darker.disabled',
          color: 'ui.inverse.primary',
        },

        _invalid: {
          borderColor: 'ui.error',
          color: 'ui.error',
        },
      },
    },
    'circle-readonly': {
      container: {
        color: 'text.primary',

        _hover: {
          color: 'accent.main',
        },

        _focus: {
          color: 'accent.main',
          boxShadow: 'none',
          outlineColor: 'accent.darkest',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineOffset: '4px',
        },
      },
      label: {
        color: 'inherited',

        '[data-disabled] &': {
          opacity: 1,
          color: 'text.disabled',
        },
      },
      control: {
        border: '1.5px solid',
        borderRadius: 'xl',
        color: 'inherit',
        borderColor: 'currentColor',

        _checked: {
          bg: 'brand.main',
          borderColor: 'brand.main',
          color: 'ui.inverse.primary',

          _disabled: {
            bg: 'ui.darker.disabled',
            borderColor: 'ui.darker.disabled',
            color: 'ui.inverse.primary',
          },

          _invalid: {
            borderColor: 'ui.error',
            color: 'ui.inverse.primary',
          },
        },

        _indeterminate: {
          bg: 'brand.main',
          borderColor: 'brand.main',
          color: 'ui.inverse.primary',

          _hover: {},

          _disabled: {
            bg: 'ui.darker.disabled',
            borderColor: 'ui.darker.disabled',
            color: 'ui.inverse.primary',
          },
        },

        _disabled: {
          bg: 'ui.inverse.primary',
          borderColor: 'ui.darker.disabled',
          color: 'ui.inverse.primary',
        },

        _invalid: {
          borderColor: 'ui.error',
          color: 'ui.error',
        },
      },
    },
    icon: {
      control: {
        border: 'none',
        color: 'inherit',
        borderColor: 'currentColor',

        _checked: {
          borderColor: 'none',
          color: 'none',
        },

        _focus: {
          boxShadow: 'outline',
        },

        _invalid: {
          borderColor: 'ui.error',
          color: 'ui.error',
        },
      },
    },
  },
  sizes: {
    sm: {
      control: { h: 3, w: 3 },
      label: textTheme.variants.caption,
      icon: { fontSize: '0.425rem' },
    },
    md: {
      control: { w: 4, h: 4 },
      label: textTheme.variants.body,
      icon: { fontSize: '0.625rem' },
    },
    lg: {
      control: { w: 5, h: 5 },
      label: textTheme.variants.body,
      icon: { fontSize: '0.625rem' },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'square',
  },
};
