import { Redirect, Route } from 'react-router-dom';
import { DocTitle } from './DocTitle';

// TODO: [CA-173] add documentation
// This is the where the CustomRoute should be imported from.
export function CustomRoute({
  shouldRedirect = false,
  redirectURL,
  children,
  title,
  onBeforeRedirect,
  conditionalRedirects = [],
  ...props
}: React.ComponentProps<typeof Route> & {
  /**
   * shouldRedirect and redirectURL are deprecated
   */
  shouldRedirect?: boolean;
  redirectURL?: string;
  onBeforeRedirect?: () => void;
  conditionalRedirects?: { condition: boolean; redirectURL: string; search?: string }[]; // TODO: [CA-173] consolidate the two
  title?: string | string[];
}) {
  const conditionalRedirect = [
    { condition: shouldRedirect, redirectURL } as {
      condition: boolean;
      redirectURL: string;
      search?: string;
    },
    ...conditionalRedirects,
  ].find((x) => x.condition);

  if (onBeforeRedirect && conditionalRedirect?.condition) {
    onBeforeRedirect();
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        conditionalRedirect?.condition ? (
          <Redirect
            to={{
              pathname: conditionalRedirect.redirectURL,
              search: conditionalRedirect?.search,
              state: { from: location },
            }}
          />
        ) : (
          <>
            {/* using the DocTitle makes sure the effects of the Route children run in order
            to prevent a document.title update of parent CustomRoute to override the ones from its child
          */}
            <DocTitle title={title} />
            {children}
          </>
        )
      }
    />
  );
}
