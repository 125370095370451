import { forwardRef, useCheckbox } from '@chakra-ui/react';
import type { CheckboxProps } from '@chakra-ui/react';
import { Tag, TagRightIcon } from '../../Tag';
import { Box } from '../../layout';
import { ErrorCircleSolidIcon } from '../../icons';

export const CheckboxToggle = forwardRef<CheckboxProps, 'input'>(
  ({ isDisabled, onChange, children, ...rest }, ref) => {
    const { getInputProps, getCheckboxProps, getRootProps, state } = useCheckbox({
      ...rest,
      isDisabled,
      onChange,
    }) as any; // TODO: [TP-1470] Fix chakra-ui types
    return (
      <Box {...getRootProps()} role="checkbox" aria-checked={state.isChecked}>
        <input {...getInputProps({}, ref)} hidden />
        <Tag variant="toggle" {...getCheckboxProps()}>
          {children}
          {state.isChecked && (
            <TagRightIcon
              as={ErrorCircleSolidIcon}
              fontSize="xs"
              color="ui.inverse.secondary"
              aria-label="tag right icon"
              role="img"
            />
          )}
        </Tag>
      </Box>
    );
  },
);
