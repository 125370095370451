import { NavLink, useParams } from 'react-router-dom';
import { Link, Box, Tag, ArrowLeftIcon, Tabs, TabList, Tab } from '@terminal/design-system';
import { PageLayout, PageLayoutPaddedBody } from 'talent-hub/components';

export const roleTabs = {
  overview: 'overview',
  submissions: 'submissions',
  interviews: 'interviews',
  offers: 'offers',
  hires: 'hires',
  requirements: 'requirements',
  reports: 'reports',
};

export enum rolesTabsLayoutIndex {
  overview = 0,
  requirements = 0,
  reports = 0,
  submissions = 1,
  interviews = 2,
  offers = 3,
  hires = 4,
}

export function RoleLayout({
  children,
  hiredCompleteCandidates,
  pageLayoutProps,
  roleTitle,
  showSubHeader = true,
  upcomingInterviewCandidates,
  upcomingOffersCandidates,
  upcomingSubmissionCandidates,
  handleBackAction,
  superTitle,
  backActionMessage = 'Back to Manage Roles',
}: {
  children?: React.ReactNode;
  hiredCompleteCandidates: number;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  roleTitle: string;
  showSubHeader?: boolean;
  upcomingInterviewCandidates: number;
  upcomingOffersCandidates: number;
  upcomingSubmissionCandidates: number;
  handleBackAction: () => void;
  superTitle?: string;
  backActionMessage: string;
}) {
  const { activeRole, activeTab } = useParams<{
    activeRole: string;
    activeTab: string;
  }>();

  if (!showSubHeader)
    return (
      <PageLayout
        superTitle={
          <Link
            textDecoration="none"
            pb={2}
            colorScheme="brand"
            as="button"
            onClick={handleBackAction}
            role="link"
          >
            <ArrowLeftIcon mr={2} /> {superTitle || `Back to ${roleTitle}`}
          </Link>
        }
        headerTitle="Candidate Review"
        {...pageLayoutProps}
      >
        <Box pt="initial">{children}</Box>
      </PageLayout>
    );

  return (
    <PageLayout
      // disableMaxWidth
      superTitle={
        <Link
          textDecoration="none"
          pb={2}
          colorScheme="brand"
          as="button"
          onClick={handleBackAction}
          role="link"
        >
          <ArrowLeftIcon mr={2} />
          {backActionMessage}
        </Link>
      }
      headerTitle={roleTitle}
      subHeader={
        <Tabs defaultIndex={rolesTabsLayoutIndex[activeTab as keyof typeof rolesTabsLayoutIndex]}>
          <TabList
            // pos={['relative', 'relative', 'fixed']}
            pos="sticky"
            top={20}
            w="full"
            bgColor="bg.primary"
            zIndex="docked"
            overflowX={['scroll', 'auto', 'auto']}
            // ! * Required to add the extra space for the active tab border and not create a yaxis scroll bar
            paddingBottom="1px"
          >
            <Tab as={NavLink} to={`/role/${activeRole}/${roleTabs.overview}`} whiteSpace="pre">
              Overview
            </Tab>
            <Tab as={NavLink} to={`/role/${activeRole}/${roleTabs.submissions}`} whiteSpace="pre">
              Submissions
              <Tag
                color="brand.darker"
                ml={2}
                p={0}
                justifyContent="center"
                size="sm"
                backgroundColor={
                  activeTab === roleTabs.submissions ? 'brand.lighter' : 'bg.secondary'
                }
                fontWeight={activeTab === roleTabs.submissions ? 'bold' : 'normal'}
              >
                {upcomingSubmissionCandidates}
              </Tag>
            </Tab>
            <Tab as={NavLink} to={`/role/${activeRole}/${roleTabs.interviews}`} whiteSpace="pre">
              Interviews
              <Tag
                color="brand.darker"
                ml={2}
                p={0}
                justifyContent="center"
                size="sm"
                backgroundColor={
                  activeTab === roleTabs.interviews ? 'brand.lighter' : 'bg.secondary'
                }
                fontWeight={activeTab === roleTabs.interviews ? 'bold' : 'normal'}
              >
                {upcomingInterviewCandidates}
              </Tag>
            </Tab>
            <Tab as={NavLink} to={`/role/${activeRole}/${roleTabs.offers}`} whiteSpace="pre">
              Offers
              <Tag
                color="brand.darker"
                ml={2}
                p={0}
                justifyContent="center"
                size="sm"
                backgroundColor={activeTab === roleTabs.offers ? 'brand.lighter' : 'bg.secondary'}
                fontWeight={activeTab === roleTabs.offers ? 'bold' : 'normal'}
              >
                {upcomingOffersCandidates}
              </Tag>
            </Tab>
            <Tab as={NavLink} to={`/role/${activeRole}/${roleTabs.hires}`} whiteSpace="pre">
              Hires
              <Tag
                color="brand.darker"
                ml={2}
                p={0}
                justifyContent="center"
                size="sm"
                backgroundColor={activeTab === roleTabs.hires ? 'brand.lighter' : 'bg.secondary'}
                fontWeight={activeTab === roleTabs.hires ? 'bold' : 'normal'}
              >
                {hiredCompleteCandidates}
              </Tag>
            </Tab>
          </TabList>
        </Tabs>
      }
      {...pageLayoutProps}
    >
      <PageLayoutPaddedBody
        bgColor="bg.primary"
        mt={{ base: 14, sm: 10 }}
        p={{ base: 4, md: 6, lg: 10 }}
        pt={{ base: 4, md: 10 }}
      >
        {children}
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
