import { firebaseAuth } from 'global/firebaseApp';
import { Observable, from, Subject, interval, merge, of, defer } from 'rxjs';
import { switchMap, map, take, catchError, retry, mergeMap } from 'rxjs/operators';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  onIdTokenChanged,
  signInAnonymously as firebaseSignInAnonymously,
  signOut as firebaseSignOut,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  linkWithRedirect,
  signInWithRedirect,
  getIdTokenResult,
  applyActionCode as firebaseApplyActionCode,
} from 'firebase/auth';
import type { Auth, User, IdTokenResult } from 'firebase/auth';
import isWebview from 'is-ua-webview';
import { getUA } from 'react-device-detect';
import * as events from '../events';
import type { AuthData, AuthUserInfo } from './types';
import createAuthContext from '../authContext';

export {
  EmailAuthProvider,
  linkWithCredential,
  updateProfile,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';

export const hasuraClaims = 'https://hasura.io/jwt/claims';

type AuthFunctionOptions = { dispatch?: (action: AsyncAction<AuthData>) => void; auth: Auth };

export function observeAuthStateChanged(auth: Auth): Observable<User | null> {
  return new Observable((subscriber) => {
    const unsubscribe = firebaseOnAuthStateChanged(
      auth,
      subscriber.next.bind(subscriber),
      subscriber.error.bind(subscriber),
      subscriber.complete.bind(subscriber),
    );

    return { unsubscribe };
  });
}

export function observeIdTokenChanged(auth: Auth): Observable<User | null> {
  return new Observable((subscriber) => {
    const unsubscribe = onIdTokenChanged(
      auth,
      subscriber.next.bind(subscriber),
      subscriber.error.bind(subscriber),
      subscriber.complete.bind(subscriber),
    );

    return { unsubscribe };
  });
}

export async function emailSignIn(
  { dispatch, auth }: AuthFunctionOptions,
  email: string,
  password: string,
): Promise<ReturnType<typeof signInWithEmailAndPassword>> {
  try {
    events.track(events.name.authentication.started, {
      type: 'email-password',
      user_agent: getUA,
      is_webview: isWebview(getUA),
    });

    if (dispatch) dispatch({ type: 'pending' });

    const result = await signInWithEmailAndPassword(auth, email, password);

    // Track that auth is complete
    events.track(events.name.authentication.completed, {
      type: 'email-password',
      user_agent: getUA,
      is_webview: isWebview(getUA),
    });

    return result;
  } catch (error) {
    const result: AsyncAction<AuthData> = {
      type: 'rejected',
      error,
      data: { isAuthenticated: false, isEmailVerified: null, roles: [], user: null },
    };

    if (dispatch) dispatch(result);
    throw error;
  }
}

export function googleSignIn(
  { dispatch, auth }: AuthFunctionOptions,
  {
    forceWithoutLinking = false,
    hintEmail,
  }: { forceWithoutLinking?: boolean; hintEmail?: string } = {},
) {
  try {
    events.track(events.name.authentication.started, {
      type: 'google',
      user_agent: getUA,
      is_webview: isWebview(getUA),
    });

    const provider = new GoogleAuthProvider();

    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
      ...(hintEmail ? { login_hint: hintEmail } : {}),
    });

    if (!forceWithoutLinking && auth.currentUser?.isAnonymous) {
      linkWithRedirect(auth.currentUser, provider);
    } else {
      signInWithRedirect(auth, provider);
    }
  } catch (error) {
    dispatch?.({
      type: 'rejected',
      error,
      data: { isAuthenticated: false, isEmailVerified: null, roles: [], user: null },
    });
  }
}

export async function signInAnonymously({ dispatch, auth }: AuthFunctionOptions) {
  try {
    dispatch?.({ type: 'pending' });
    await firebaseSignInAnonymously(auth);
  } catch (error) {
    dispatch?.({
      type: 'rejected',
      error,
      data: { isAuthenticated: false, isEmailVerified: null, roles: [], user: null },
    });
  }
}

export async function signOut({ auth }: AuthFunctionOptions) {
  await firebaseSignOut(auth);
  events.track(events.name.signedOut);
  events.reset();
}

type PutUserResult = {
  firebaseUser?: User;
  user?: AuthUserInfo;
  claims?: { [key: string]: any };
  intercomUserHash?: string;
  error?: Error;
};

export const putUser$ = new Subject<PutUserResult>();

export async function putUser(firebaseUser: User, appName: string): Promise<PutUserResult> {
  try {
    const url = `${import.meta.env.REACT_APP_AUTH_URL}/users`;
    const { headers } = await createAuthContext(firebaseAuth, 'web', appName);
    /**
     * Set Hasura claims in the BE for that user
     *
     * If the user has the right claims already the endpoint is going to return refresh_token as false to avoid forcing a token refresh when calling getIdTokenResult
     */
    const resp = await fetch(url, { method: 'PUT', headers });
    const { user, refresh_token, intercom_user_hash } = await resp.json();

    /**
     * We need to call getIdTokenResult in order to get the user roles.
     *
     * if 'refresh_token' === true it means they were updated in the backend and the function behavior is to fetch the latest roles from firebase
     */
    const { claims } = await firebaseUser.getIdTokenResult(refresh_token);
    // Must get new user object from firebase
    const currentFirebaseUser = firebaseAuth.currentUser;

    if (!currentFirebaseUser || !(hasuraClaims in claims)) {
      throw new Error('Auth service did not setup the user properly.');
    }

    const result = {
      firebaseUser: currentFirebaseUser,
      user,
      claims,
      intercomUserHash: intercom_user_hash,
    };

    putUser$.next(result);
    return result;
  } catch (error) {
    putUser$.next({ error } as { error: Error });
    throw error;
  }
}

export function getIdTokenResult_andWaitForAuthToResolve(
  { auth }: AuthFunctionOptions,
  forceRefresh?: boolean,
): Promise<IdTokenResult> {
  return new Promise((resolve, reject) => {
    defer(() => from(getIdTokenResult(auth.currentUser as User, forceRefresh)))
      .pipe(
        switchMap((idTokenResult) =>
          forceRefresh
            ? /**
               * Waits for the fallback signal to emit or for the putUser signal.
               * We can be almost sure that putUser is always going to be called, but since we cannot be 100% sure it is better to have a fallback timeout to consider the operation done.
               *
               * putUser mergeMap explanation: putUser needs to happen twice in order to properly work on Firefox
               */
              merge(interval(4000).pipe(take(1)), putUser$.pipe(mergeMap(() => putUser$))).pipe(
                take(1),
                map(() => [idTokenResult]),
              )
            : of([idTokenResult]),
        ),
        retry({
          count: 3,
          delay: 250,
          resetOnSuccess: true,
        }),
        catchError((error) => of([null, error])),
      )
      .subscribe(([idTokenResult, error]) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(idTokenResult as IdTokenResult);
      });
  });
}

export async function applyEmailVerificationCode({ auth }: AuthFunctionOptions, oobCode: string) {
  await firebaseApplyActionCode(auth, oobCode);
  // The firebase currentUser is updated without making this call but user object in the
  // token is not.  The token needs this new information (in particular
  // {emailVerified: true} because it is used by the Terminal auth service.
  await getIdTokenResult_andWaitForAuthToResolve({ auth }, true);
}
