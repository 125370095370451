import { getIdTokenResult } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import type { Auth } from 'firebase/auth';

// eslint-disable-next-line import/no-default-export
export default async function authContext(
  auth: Auth,
  client_name: string = 'common',
  appName?: string,
) {
  /*
   * IMPORTANT:
   *   Add any new headers to the CORS allow list for auth and graphql service
   *   or ALL REQUESTS to those services WILL FAIL.
   */
  const headers: HeadersInit = {
    'x-request-id': `${client_name}:${uuidv4()}`,
    AppName: appName || '',
  };

  if (auth.currentUser) {
    const { claims, token } = await getIdTokenResult(auth.currentUser, false);
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      return {
        token,
        headers,
        // @ts-ignore
        roles: claims?.['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'] || [],
      };
    }
  }

  return { headers };
}
