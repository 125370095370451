import type { Role_Choices_Enum } from 'global/types';
import { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import { displayRolesNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { displayRolesOrder } from 'talent-hub/role/prospect/onboarding/steps/roles/Roles.serializer';
import type { SelectOnboardingDataQuery } from './graphql';

export function serializeRoleOptions({
  roles,
  skills,
}: {
  roles?: SelectOnboardingDataQuery['roles'];
  skills?: SelectOnboardingDataQuery['skills'];
}): {
  roles: {
    value: string;
    label: string;
  }[];
  skills: {
    value: string;
    label: string;
  }[];
  yearsOfExperience: {
    value: string;
    label: string;
  }[];
} {
  return {
    skills:
      skills
        ?.map?.(({ id, name }) => ({
          value: `${id}`,
          label: name,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || [],

    roles:
      roles
        ?.flatMap?.(({ value }) =>
          displayRolesNames[value as Role_Choices_Enum]
            ? {
                value,
                label: displayRolesNames[value as Role_Choices_Enum] || '',
              }
            : [],
        )
        ?.slice?.()
        ?.sort?.((a, b) => {
          if (Number(displayRolesOrder[a.value]) === Number(displayRolesOrder[b.value])) return 0;

          return Number(displayRolesOrder[a.value]) < Number(displayRolesOrder[b.value]) ? -1 : 1;
        }) || [],
    yearsOfExperience: [
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
    ].map((item) => ({
      value: item,
      label: toFriendlyYearsOfExperienceRange(item),
    })),
  };
}

export function serializeOnboardingData(data?: SelectOnboardingDataQuery) {
  return {
    roleOptions: serializeRoleOptions({ roles: data?.roles, skills: data?.skills }),
  };
}
