import type { SpaceProps } from '@terminal/design-system';
import { Box, Text, Heading } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';

export function AuthPageHeader({
  title,
  subtitle,
  containerMaxW = 'md',
  ...styleProps
}: {
  title: string;
  subtitle?: string;
  containerMaxW?: string;
} & SpaceProps) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box textAlign="center" {...styleProps} maxW={['sm', containerMaxW]}>
      <Heading variant="heading-2">{title}</Heading>
      {!!subtitle && (
        <Text variant={isDesktop ? 'body' : 'caption'} mt={1}>
          {subtitle}
        </Text>
      )}
    </Box>
  );
}
