export * from './candidate-stage';
export * from './icimsFields';
export * from './database';
export * from './statusMap';

export enum JobHealthStatus {
  Hired = 'Hired',
  WarningHighRejectionRate = 'WarningHighRejectionRate',
  WarningLate = 'WarningLate',
  OnTrack = 'OnTrack',
  Paused = 'Paused',
  OnHold = 'OnHold',
}

export enum AsyncStatus {
  Idle = 'Idle',
  InProgress = 'InProgress',
  Resolved = 'Resolved',
  Rejected = 'Rejected',
}
