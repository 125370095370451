import { textTheme } from '../../Text/Text.theme';

export const inputTheme = {
  baseStyle: {
    field: {
      ...textTheme.variants.body,
      color: 'text-primary',
      bg: 'ui.inverse.primary',
      border: '1px',
      borderColor: 'ui.secondary',
      px: 3,
      w: 'full',
    },
  },
  sizes: {
    md: {
      field: {
        ...textTheme.variants.body,
        px: 3,
        h: 12,
        borderRadius: 'none',
      },
      addon: {
        ...textTheme.variants.body,
        px: 3,
        h: 12,
        borderRadius: 'none',
      },
    },
  },
  variants: {
    outline: {
      field: {
        border: '1px solid',
        borderColor: 'ui.secondary',
        bg: 'ui.inverse.primary',
        _placeholder: { color: 'text.disabled' },
        _hover: {
          borderColor: 'brand.main',
          _invalid: {
            borderColor: 'ui.darker.error',
          },
        },
        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
        },
        _disabled: {
          opacity: 1,
          bg: 'ui.disabled',
          color: 'text.disabled',
          cursor: 'not-allowed',
          borderColor: 'ui.secondary',
          _hover: {
            borderColor: 'ui.secondary',
            cursor: 'not-allowed',
          },
        },
        _invalid: {
          borderColor: 'ui.error',
          boxShadow: `none`,
          _hover: {
            borderColor: 'ui.darker.error',
          },
          _focus: {
            borderColor: 'ui.darker.error',
          },
        },
        _focus: {
          outline: 'none',
          borderColor: 'brand.main',
          boxShadow: 'md',
          zIndex: 1,
        },
      },
      addon: {
        border: '1px solid',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
};
