import { forwardRef, useCheckbox } from '@chakra-ui/react';
import type { CheckboxProps } from '@chakra-ui/react';
import { Button } from '../../Button';
import { Box } from '../../layout';
import { PreferredIcon, PreferredFilledIcon } from '../../icons';

export interface IconCheckboxProps extends CheckboxProps {
  checkedIcon?: React.ReactNode;
  unCheckedIcon?: React.ReactNode;
}

export const IconCheckbox = forwardRef<IconCheckboxProps, 'input'>(
  (
    {
      isDisabled,
      isChecked,
      onChange,
      checkedIcon = <PreferredFilledIcon color="brand.main" />,
      unCheckedIcon = <PreferredIcon />,
      ...rest
    },
    ref,
  ) => {
    const { getInputProps, getCheckboxProps, getRootProps } = useCheckbox({
      ...rest,
      isDisabled,
      isChecked,
      onChange,
    }) as any; // TODO: [TP-1470] Fix chakra-ui types

    return (
      <Box {...getRootProps()}>
        <input {...getInputProps({}, ref)} />
        <Button _active={{ boxShadow: 'none' }} variant="ghost" {...getCheckboxProps()}>
          {isChecked ? checkedIcon : unCheckedIcon}
        </Button>
      </Box>
    );
  },
);
