import { Link, Text, EmailVerificationIcon } from '@terminal/design-system';
import type { AuthPageTemplateProps } from '../types';
import { AuthMessage, AuthPageTemplate } from '../components';

export function EmailNotVerifiedResendSuccessPage({
  pageTemplateOverwrite,
}: {
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;
  return (
    <PageTemplate>
      {/** PageTemplate could not use children at all. So, check the override template to confirm if the following UI is going to be displayed */}
      <AuthMessage icon={<EmailVerificationIcon />} title="Please Verify Your Email">
        <Text>
          A verification email has been sent to your email account, please check your inbox to
          verify. If you have any problems, please{' '}
          <Link href="https://help.terminal.io/" isExternal display="inline">
            contact us.
          </Link>
        </Text>
      </AuthMessage>
    </PageTemplate>
  );
}
