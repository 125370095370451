import type { HasuraTypes } from 'global/types';

type ReadableLocations = Array<Pick<HasuraTypes.Location, 'value' | 'city'>>;

/**
 * +33% markup
 */
export const markupPercentage = 1.33;

export const readableLocationOr = (
  readableLocations: ReadableLocations,
  orValue: string,
): string => {
  if (readableLocations.length > 0) {
    const location = readableLocations[0];
    return location?.city || location?.value || '';
  }
  return orValue;
};

/**
 * Capitalizes the first letter of each word in a given string.
 * @function
 * @param {string} str - The string to capitalize.
 * @returns {string} -  The capitalized string with the first letter of each word in uppercase
 */
export function capitalizeEachWord(str: string | null | undefined): string {
  if (str) {
    return str
      .toLocaleLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return '';
}
