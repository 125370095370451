import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ScrollToTopOnPathChange } from 'talent-hub/components';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Sentry from 'global/sentry';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { InsertActivityCustomerProfileView } from './data';
import type {
  InsertActivityCustomerProfileViewMutation,
  InsertActivityCustomerProfileViewMutationVariables,
} from './data';
import { SharedCandidatesController } from './shared-candidates';
import { ExploreCandidatesController } from './explore-candidates';
import { SavedCandidatesController } from './saved-candidates';

export function ExploreCandidatesRouter() {
  const { path } = useRouteMatch();

  const { user } = useCustomerAuthorizedUserSession();

  const match_viewCandidateProfileRoute = useRouteMatch<{
    candidateID: string;
  }>([
    `${path}/saved-candidates/candidate/:candidateID`,
    `${path}/shared-candidates/candidate/:candidateID`,
    `${path}/candidate/:candidateID`,
  ]);

  const [insertActivityCustomerProfileViewMutation] = useMutation<
    InsertActivityCustomerProfileViewMutation,
    InsertActivityCustomerProfileViewMutationVariables
  >(InsertActivityCustomerProfileView, {
    onError: (mutationError) => {
      Sentry.captureException(mutationError);
    },
  });

  let viewProfile_candidateID: null | number | string = null;

  try {
    viewProfile_candidateID =
      match_viewCandidateProfileRoute &&
      match_viewCandidateProfileRoute.params?.candidateID &&
      Number(match_viewCandidateProfileRoute.params?.candidateID);
  } catch (error: unknown) {
    Sentry.captureException(error);
  }

  useEffect(() => {
    if (user?.id && viewProfile_candidateID) {
      try {
        insertActivityCustomerProfileViewMutation({
          variables: {
            candidate_id: Number(viewProfile_candidateID),
            user_id: user?.id,
          },
        });
      } catch (error: unknown) {
        Sentry.captureException(error);
      }
    }
  }, [insertActivityCustomerProfileViewMutation, viewProfile_candidateID, user?.id]);

  return (
    <Switch>
      <Route
        exact
        path={[`${path}/saved-candidates/candidate/:candidateID`, `${path}/saved-candidates`]}
      >
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <SavedCandidatesController />
      </Route>
      <Route
        exact
        path={[`${path}/shared-candidates/candidate/:candidateID`, `${path}/shared-candidates`]}
      >
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <SharedCandidatesController />
      </Route>
      <Route exact path={[`${path}/candidate/:candidateID`, path]}>
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <ExploreCandidatesController />
      </Route>
    </Switch>
  );
}
