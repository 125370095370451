import moment from 'moment';
import { compareByPropDateAsc } from 'global/utils';
import type { Interview_Feedback_Rating_Choices_Enum } from 'global/types';
import type { SelectDashboardCustomerActivititesQuery } from './data';

export function serializerForCustomerActivityDashboard(
  data: SelectDashboardCustomerActivititesQuery,
): {
  recentCreatedCustomer: {
    id: number;
    name: string;
    numOfUsers: number;
    slackChannel: string | null;
    dayforceSetupStatus: 'incomplete' | 'complete' | 'need-inspection';
    isDirectoryAdded: boolean;
    isDomainAdded: boolean;
    isLogoAdded: boolean;
    createdDate: string | null;
  }[];
  recentCreatedJobs: SelectDashboardCustomerActivititesQuery['recently_createds_jobs'];
  allJobsActivities: SelectDashboardCustomerActivititesQuery['job'];
  recent_interview_feedbacks: {
    feedback_id: number;
    comment: string;
    rating: Interview_Feedback_Rating_Choices_Enum;
    date: string;
    candidate: {
      id: number | undefined;
      full_name: string;
    };
    reviewer: {
      email: string;
      full_name: string;
    };
    role: {
      id: number | undefined;
      title: string | undefined;
    };
    customer: {
      id: number | undefined;
      name: string | null | undefined;
    };
  }[];
  recent_profile_views: {
    viewer: {
      email: string;
      full_name: string;
    };
    candidate: {
      id: number;
      full_name: string;
      roles: string;
    } | null;
    customer: {
      id?: number;
      name?: string;
    };

    date: string;
  }[];
  recent_submissions_acceptance_statuses: {
    candidate: {
      id: number;
      full_name: string;
    } | null;
    customer: {
      id: number;
      name: string;
    } | null;
    role: {
      id: number;
      title: string;
    } | null;
    status: string | null;
    date: string;
  }[];
} {
  return {
    recentCreatedCustomer: data.recent_organizations.slice(0, 8).map((organization) => {
      let dayforceSetupStatus: 'incomplete' | 'complete' | 'need-inspection' = 'incomplete';

      if (organization.dayforce_customer_id) {
        dayforceSetupStatus = 'complete';
      } else {
        dayforceSetupStatus = data.dayforce_customer.some(
          (dayforceCustomer) => dayforceCustomer.name === organization.name,
        )
          ? 'need-inspection'
          : 'incomplete';
      }

      data.salesforce_customer.find(
        (customer) => customer.salesforce_id === organization.salesforce_id,
      );

      return {
        id: organization.id,
        name: organization.name,
        numOfUsers: organization.user_infos_aggregate.aggregate?.count || 0,
        dayforceSetupStatus,
        slackChannel: organization.slack_channel,
        isDirectoryAdded: data.terminal_employee_organization.some(
          (terminalEmployeeOrganization) =>
            terminalEmployeeOrganization.organization === organization.id,
        ),
        isDomainAdded: data.organization_domain.some(
          (domain) => domain.organization === organization.id,
        ),
        isLogoAdded: Boolean(organization.logo),
        createdDate: organization.first_user_created.length
          ? moment(organization.first_user_created[0]?.created_at).format('YYYY-MM-DD')
          : null,
      };
    }),
    recentCreatedJobs: data.recently_createds_jobs,
    allJobsActivities: data.job.sort(compareByPropDateAsc('updated_at')),
    recent_interview_feedbacks: data.interview_feedback.map((feedback) => {
      const icims_candidate =
        feedback.icims_profile_applicant_workflow.icims_applicant_workflows[0]?.icims_person;
      const icims_job =
        feedback.icims_profile_applicant_workflow.icims_applicant_workflows[0]?.icims_job;

      return {
        feedback_id: feedback.id,
        comment: feedback.comments,
        rating: feedback.rating,
        date: moment(feedback.updated_at).format('MMM D, YY HH:mm'),
        candidate: {
          id: icims_candidate?.candidate?.id,
          full_name: `${icims_candidate?.firstname} ${icims_candidate?.lastname}`,
        },
        reviewer: {
          email: feedback.created_by_user_info.email,
          full_name: `${feedback.created_by_user_info.first_name} ${feedback.created_by_user_info.last_name}`,
        },
        role: {
          id: icims_job?.job?.id,
          title: icims_job?.job?.title,
        },
        customer: {
          id: icims_job?.job?.organization?.id,
          name: icims_job?.job?.organization?.name || icims_job?.icims_company?.name,
        },
      };
    }),
    recent_profile_views: data.activity_customer_profile_view.map((profile_view) => {
      return {
        viewer: {
          id: profile_view.user_info.id,
          email: profile_view.user_info.email,
          full_name: `${profile_view.user_info.first_name} ${profile_view.user_info.last_name}`,
        },
        candidate: profile_view.candidate?.id
          ? {
              id: profile_view.candidate.id,
              full_name: `${profile_view.candidate.first_name} ${profile_view.candidate.last_name}`,
              roles: profile_view.candidate.candidate_roles.map((role) => role.role).join(', '),
            }
          : null,
        customer: {
          id: profile_view?.user_info?.organizationByOrganization?.id,
          name: profile_view?.user_info?.organizationByOrganization?.name,
        },
        date: moment(profile_view.created_at).format('MMM D, YY HH:mm'),
      };
    }),
    recent_submissions_acceptance_statuses: data.recent_submissions_acceptance_status.map(
      (status) => {
        const candidate = status.icims_applicant_workflow?.icims_person?.candidate;
        const customer = status.icims_applicant_workflow?.icims_job?.job?.organization;
        const role = status.icims_applicant_workflow?.icims_job?.job;

        const friendlyStatusMap: { [key: string]: string | null } = {
          'Submitted to Client - Client Portal Advance': 'Accepted',
          'Rejected - Rejected by Client - Client Portal Rejected': 'Rejected',
          default: null, // This case should never happen unless the query gets updated without updating this code
        };

        const friendlyStatus = friendlyStatusMap[status.icims_status?.value || 'default'] || null;

        return {
          candidate: candidate
            ? {
                id: candidate.id,
                full_name: `${candidate.first_name} ${candidate.last_name}`,
              }
            : null,
          customer: customer
            ? {
                id: customer.id,
                name: customer.name,
              }
            : null,
          role: role
            ? {
                id: role.id,
                title: role.title,
              }
            : null,
          status: friendlyStatus,
          date: moment(status.created_at).format('MMM D, YY HH:mm'),
        };
      },
    ),
  };
}
