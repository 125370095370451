export const ICIMS = {
  Status: {
    ClientPortalAdvance: { ID: 'C45710', Value: 'Submitted to Client - Client Portal Advance' },
    ClientPortalRejected: {
      ID: 'C45711',
      Value: 'Rejected - Client Rejected - Client Portal Rejected',
    },
  },
  RejectionReason: {
    IssueWithCompensation: {
      ID: 'C46656',
      Value: 'Issue with compensation',
      FieldName: 'rejected_reason_compensation',
    },
    LacksRelevantWorkExperience: {
      ID: 'C45804',
      Value: 'Lacks relevant work experience',
      FieldName: 'rejected_reason_experience',
    },
    MissingSkills: { ID: 'C45805', Value: 'Missing skills', FieldName: 'rejected_reason_skill' },
    Other: { ID: 'C45806', Value: 'Other', FieldName: 'rejected_reason_other' },
    TooJunior: { ID: 'C46655', Value: 'Too junior', FieldName: 'rejected_reason_junior' },
  },
};
