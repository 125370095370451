/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useCallback, useEffect, useState, useRef } from 'react';
import type { ChangeEvent } from 'react';
import { useDisclosure, useOutsideClick, Portal } from '@chakra-ui/react';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  ViewOnIcon,
  ViewOffIcon,
  VStack,
} from '@terminal/design-system';
import { doesContainRepeated, doesContainSequence } from 'global/utils';
import { RequiredInstruction } from 'global/components/RequiredInstruction';
import type { SpaceProps } from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils/hooks';

export const createPasswordYupValidation = () =>
  Yup.string().test(
    'custom-validation',
    'Custom validation failed',
    (password) =>
      (password || '').length >= 8 &&
      !doesContainSequence(password || '', { threshold: 4 }) &&
      !doesContainRepeated(password || '', { threshold: 4 }),
  );

type PasswordFieldProps = {
  onChange: (e: string | ChangeEvent<any>) => void;
  onBlur: (e: string | ChangeEvent<any>) => void;
  isInvalid: boolean;
  placeholder: string;
  title: string;
  touched: boolean;
  value: string;
} & SpaceProps;

function PasswordValidations({
  touched,
  value,
  isInvalid,
}: Pick<PasswordFieldProps, 'touched' | 'value' | 'isInvalid'>) {
  return (
    <>
      <RequiredInstruction
        isInvalid={isInvalid && value.length < 8}
        isComplete={value.length >= 8}
        message="Min. 8 characters"
        mr={3}
      />
      <RequiredInstruction
        isInvalid={touched && doesContainSequence(value, { threshold: 4 })}
        isComplete={value.length > 3 && !doesContainSequence(value, { threshold: 4 })}
        message="No sequential characters (1234)"
        mr={3}
        mt={[1, 0]}
      />
      <RequiredInstruction
        isInvalid={touched && doesContainRepeated(value, { threshold: 4 })}
        isComplete={value.length > 3 && !doesContainRepeated(value, { threshold: 4 })}
        message="No repeated characters (aaaa)"
        mr={3}
        mt={1}
      />
    </>
  );
}

function PasswordInput({
  showPassword,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  value,
  toggleShowPassword,
  // To help pass PopoverTrigger as a wrapper. Simply Wrapping PopoverTrigger outside of this component, the
  // popover will not be triggered.
  Wrapper = Box,
}: {
  showPassword: boolean;
  toggleShowPassword: () => void;
  onFocus?: (e: string | ChangeEvent<any>) => void;
  Wrapper?: any; // TODO: fix the type
} & Pick<PasswordFieldProps, 'onBlur' | 'onChange' | 'placeholder' | 'value'>) {
  return (
    <Wrapper>
      <InputGroup size="md">
        <Input
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
        <InputRightElement width="4.5rem">
          {showPassword ? (
            <ViewOnIcon _hover={{ cursor: 'pointer' }} onClick={toggleShowPassword} w={6} h={6} />
          ) : (
            <ViewOffIcon _hover={{ cursor: 'pointer' }} onClick={toggleShowPassword} w={6} h={6} />
          )}
        </InputRightElement>
      </InputGroup>
    </Wrapper>
  );
}

export function PasswordFieldWithInlineValidator({
  onChange,
  isInvalid,
  placeholder,
  title,
  touched,
  value,
  onBlur,
  ...spacingProps
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  return (
    <FormControl id="password" isInvalid={isInvalid} {...spacingProps}>
      <Flex>
        <FormLabel htmlFor="password">{title}</FormLabel>
      </Flex>
      <PasswordInput
        {...{ onChange, onBlur, placeholder, showPassword, toggleShowPassword, value }}
      />
      <Flex mt={3} wrap="wrap" flexDir={['column', 'row', 'row']} pl={[0, 1]}>
        <PasswordValidations {...{ value, touched, isInvalid }} />
      </Flex>
    </FormControl>
  );
}

export function PasswordFieldWithPopoverValidator({
  onChange,
  onBlur,
  isInvalid,
  placeholder,
  title,
  touched,
  submissionCount,
  value,
  ...spacingProps
}: { submissionCount: number } & PasswordFieldProps) {
  const { onOpen, onClose, isOpen } = useDisclosure({
    defaultIsOpen: false,
  });

  const popoverRef = useRef(null);

  const isDesktop = useIsViewPortDesktop();

  useOutsideClick({
    ref: popoverRef,
    handler: () => onClose(),
  });

  // Opens the popover when the password field is invalid on submission
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isInvalid) {
      if (isDesktop) {
        onOpen();
      } else {
        // To workaround a UX smoothness in mobile
        timeout = setTimeout(() => {
          onOpen();
        }, 100);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isInvalid, onOpen, submissionCount, isDesktop]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const isAllComplete =
      value.length >= 8 &&
      !doesContainSequence(value, { threshold: 4 }) && // TODO abstract these rules
      !doesContainRepeated(value, { threshold: 4 });

    if (isAllComplete) {
      timeout = setTimeout(() => {
        onClose();
      }, 200);
    } else if (value.length > 0) {
      if (isDesktop) {
        onOpen();
      } else {
        // To workaround a UX smoothness in mobile
        timeout = setTimeout(() => {
          onOpen();
        }, 100);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isInvalid, value, isDesktop, onClose, onOpen]);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  return (
    <FormControl id="password" isInvalid={isInvalid} {...spacingProps}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="top"
        closeOnBlur={false}
        closeOnEsc={false}
        closeDelay={200}
        openDelay={100}
        boundary="clippingParents"
        trigger="click"
        autoFocus={false}
      >
        <Flex>
          <FormLabel htmlFor="password">{title}</FormLabel>
        </Flex>

        <PasswordInput
          {...{ onChange, onBlur, placeholder, showPassword, toggleShowPassword, value }}
          onFocus={() => {
            // On mobile it does not behave smoothly, so only enabling it on desktop.
            if (isDesktop) {
              // Allows opening th popover when user tabs into the password field.
              onOpen();
            }
          }}
          onBlur={() => {
            // On mobile it does not behave smoothly, so only enabling it on desktop.
            if (isDesktop) {
              // Allows opening th popover when user tabs into the password field.
              onClose();
            }
          }}
          Wrapper={PopoverTrigger}
        />
        <Portal>
          <PopoverContent p={2}>
            <PopoverArrow />
            <VStack wrap="wrap" spacing={1} alignItems="flex-start">
              <PasswordValidations {...{ value, touched, isInvalid }} />
            </VStack>
          </PopoverContent>
        </Portal>
      </Popover>
    </FormControl>
  );
}
