import { useEffect } from 'react';

import * as events from 'global/events';
import { useLocalStorage, useQuery } from 'global/utils';
import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { serializer_for_manage_roles_dashboard } from './ManageRolesDashboard.serializer';
import { SelectManageRoles } from './data';
import type { ManageRolesQueryVariables, ManageRolesQuery as ManageRolesQueryType } from './data';

import { ManageRolesDashboard } from './ManageRolesDashboard';

export function ManageRolesDashboardController() {
  const {
    isClientReviewer,
    isRecruiter,
    isClientProspect,
    user,
    viewingOrganization,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const [active_roles_bookmark_ids, set_bookmarked_role] = useLocalStorage<number[]>(
    'active_role_bookmark_ids',
    [],
  );

  const { loading, data } = useQuery<ManageRolesQueryType, ManageRolesQueryVariables>(
    SelectManageRoles,
    {
      variables: {
        organization: viewingOrganization.ID,
        job_folder_approved: JOB_FOLDER_APPROVED,
        job_folder_filled: JOB_FOLDER_FILLED,
      },
      fetchPolicy: 'network-only',
    },
  );

  const ui_data_props = serializer_for_manage_roles_dashboard(
    data || null,
    active_roles_bookmark_ids,
  );

  useEffect(() => {
    events.track(events.name.viewedRole);
  }, []);

  return (
    <ManageRolesDashboard
      {...ui_data_props}
      is_loading={loading}
      pageLayoutProps={{
        userPrioritizedRole,
        disableMaxWidth: false,
        orgName: viewingOrganization.name,
        user,
        children: null,
        isClientProspect,
        isRecruiter,
        isClientReviewer,
      }}
      on_role_bookmark_click={(role_id) => {
        if (active_roles_bookmark_ids.includes(role_id)) {
          set_bookmarked_role(active_roles_bookmark_ids.filter((id) => id !== role_id));
          return;
        }

        set_bookmarked_role([...active_roles_bookmark_ids, role_id]);
      }}
    />
  );
}
