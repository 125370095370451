import { useState, useEffect } from 'react';

interface Args extends IntersectionObserverInit {
  // it helps to stop the execution of intersectionObserver once is visible
  freezeOnceVisible?: boolean;
}

/**
 * Allows to use Intersection observer web API by sending an Element reference of the DOM
 * @param {elementRef}: DOM element reference, if you are using useRef, the value would be .current,
 * @param {config}: IntersectionObserverInit and freezeOnceVisible (it helps to stop the execution of intersectionObserver once is visible)
 * @returns IntersectionObserverEntry object data, more info here: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
 * @example 
       useIntersectionObserver(divRef.current, {
        freezeOnceVisible: true,
        rootMargin: '200px',
        threshold: 0.8,
        root: null
      });
 */
export function useIntersectionObserver(
  elementRef: Element | null,
  { threshold = 0, root = null, rootMargin = '0px', freezeOnceVisible = false }: Args = {},
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([updatedEntry]: IntersectionObserverEntry[]): void => {
    setEntry(updatedEntry);
  };

  useEffect(() => {
    const node = elementRef; // DOM Ref
    // eslint-disable-next-line compat/compat
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return undefined;

    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(updateEntry, { threshold, root, rootMargin });

    observer.observe(node);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}
