import { DurationIcon, Spinner } from '@terminal/design-system';
import { AuthMessage, AuthPageTemplate } from '../components';
import type { AuthPageTemplateProps } from '../types';

export function EmailVerifyInProgressPage({
  pageTemplateOverwrite,
}: {
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate>
      <AuthMessage icon={<DurationIcon />} title="Verifying email">
        <Spinner size="lg" color="brand.main" />
      </AuthMessage>
    </PageTemplate>
  );
}
