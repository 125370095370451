import { chakra } from '@chakra-ui/react';
import LogoWhite from 'global/images/logo-white.svg?react';
import LogoOriginal from 'global/images/logo_color.svg?react';
import LogoBlock from 'global/images/logo-block.svg?react';

interface LogoProps {
  height?: number | string | 'full';
  width?: number | string | 'full';
  variation?: 'original' | 'one-color' | 'block';
  tint?: string;
}

export function Logo({
  height = 'full',
  width = 'full',
  variation = 'original',
  tint = 'white',
}: LogoProps) {
  let cmp;
  switch (variation) {
    case 'one-color':
      cmp = LogoWhite;
      break;
    case 'block':
      cmp = LogoBlock;
      break;
    default:
      cmp = LogoOriginal;
      break;
  }
  const LogoSvg = chakra(cmp);

  return <LogoSvg h={height} w={width} fill={tint} />;
}
