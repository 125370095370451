import { Route, useRouteMatch } from 'react-router-dom';

import { CustomRoute } from 'global/components';

import { useTalentHubFlags } from 'talent-hub/utils';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';
import { ManageCandidatesController } from 'talent-hub/shared/features/manage-candidates';
import { RoleRouter } from 'talent-hub/shared/features/roles';

import { ChangeOrganizationController } from './change-organization';
import { CustomerActivityDashboardController } from './customer-activity-dashboard';
import { EditOrganizationController } from './edit-organization';

// * Those imports are not expected, it's an exception since today these both roles are 99% the same.
import { MemberDirectoryController } from '../client/member-directory';
import { TerminalDirectoryController } from '../client/terminal-directory';
import { DashboardController } from '../client/dashboard';
import { ExploreCandidatesRouter } from '../client/explore-candidates';
import { DashboardController as NewDashboardController } from '../client/new.dashboard';

function InternalRouter() {
  const { path } = useRouteMatch();

  return (
    <SwitchWithPageNotFound>
      <Route path={`${path}/customer-activity-dashboard`}>
        <ScrollToTopOnPathChange />
        <CustomerActivityDashboardController />
      </Route>
    </SwitchWithPageNotFound>
  );
}

export function RecruiterRouter() {
  const featureFlags = useTalentHubFlags();
  return (
    <SwitchWithPageNotFound>
      <Route path="/change-organization">
        <ScrollToTopOnPathChange />
        <ChangeOrganizationController />
      </Route>
      <Route path="/edit-organization">
        <ScrollToTopOnPathChange />
        <EditOrganizationController />
      </Route>
      <Route path="/internal">
        <InternalRouter />
      </Route>
      <Route path="/role">
        <RoleRouter />
      </Route>
      <Route path="/candidates">
        <ManageCandidatesController />
      </Route>
      <Route path="/members">
        <ScrollToTopOnPathChange />
        <MemberDirectoryController />
      </Route>
      <Route path="/directory">
        <ScrollToTopOnPathChange />
        <TerminalDirectoryController />
      </Route>
      <Route path="/explore-candidates">
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <ExploreCandidatesRouter />
      </Route>
      <Route exact path={[`/dashboard/candidate/:candidateID`, `/dashboard`]}>
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <NewDashboardController />
      </Route>
      <CustomRoute
        path="/"
        conditionalRedirects={[
          { condition: featureFlags['role-based-rehaul'], redirectURL: `/dashboard` },
        ]}
      >
        <ScrollToTopOnPathChange />
        <DashboardController />
      </CustomRoute>
    </SwitchWithPageNotFound>
  );
}
