import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * We had the same component at Candidates
 * TODO: Move to design system (?)
 */
export function ScrollToTopOnPathChange({
  children,
  scrollAnimationBehavior = 'auto',
  shouldDisableAutoScroll,
}: {
  children?: React.ReactNode;
  scrollAnimationBehavior?: ScrollBehavior;
  shouldDisableAutoScroll?: boolean;
}) {
  const { pathname, state } = useLocation<{ scrollTop?: boolean }>();

  useLayoutEffect(() => {
    if (shouldDisableAutoScroll && !state?.scrollTop) return;
    window.scrollTo({ top: 0, left: 0, behavior: scrollAnimationBehavior });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
