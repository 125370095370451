import { Box, Flex } from '@terminal/design-system';

export function AuthPageBackground({ children }: { children: React.ReactNode }) {
  return (
    <Flex bg="bg.secondary" justifyContent="center" width="full" minH="100vh" height="100%">
      <Box
        sx={{
          '&::after': {
            content: '""',
            position: 'fixed',
            top: '40%',
            left: '0',
            bottom: '5%',
            w: ['65%', '35%'],
            backgroundImage: 'radial-gradient(#d5d5d5 4px, transparent 4px)',
            backgroundSize: '40px 40px',
            backgroundPosition: '0 0',
          },
        }}
      />

      {children}
    </Flex>
  );
}
