import type { SystemProps, HTMLChakraProps, ThemingProps, ResponsiveValue } from '@chakra-ui/react';
import { chakra, forwardRef, omitThemingProps, useStyleConfig } from '@chakra-ui/react';
import { cx, filterUndefined } from '@chakra-ui/utils';

export interface TextProps extends HTMLChakraProps<'p'>, ThemingProps<'Text'> {
  /**
   * Available text colors
   */
  color?:
    | 'text.primary'
    | 'text.secondary'
    | 'text.disabled'
    | 'text.inverse'
    | 'text.error'
    | 'text.success'
    | 'text.link'
    | 'brand.main'
    | 'accent.darkest'
    | 'accent.darker'
    | 'accent.main'
    | 'text.warning'
    | 'brand.darker'
    | 'inherit';

  /**
   * Text Variants
   */
  variant?: 'body' | 'label' | 'caption' | 'hint';
  /**
   * if `true`, it'll render an ellipsis when the text exceeds the width of the viewport or maxWidth set.
   */
  isTruncated?: boolean;
  /**
   * Used to truncate text at a specific number of lines
   */
  noOfLines?: ResponsiveValue<number>;

  /**
   * The CSS `text-align` property
   */
  align?: SystemProps['textAlign'];
  /**
   * The CSS `text-decoration` property
   */
  decoration?: SystemProps['textDecoration'];
  /**
   * The CSS `text-transform` property
   */
  casing?: SystemProps['textTransform'];
}

/**
 * Used to render texts or paragraphs.
 *
 * @see Docs https://chakra-ui.com/docs/typography/text
 */
export const Text = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyleConfig('Text', props);
  const { className, ...rest } = omitThemingProps(props);

  const aliasedProps = filterUndefined({
    textAlign: props.align,
    textDecoration: props.decoration,
    textTransform: props.casing,
  });

  return (
    <chakra.p
      ref={ref}
      className={cx('chakra-text', props.className)}
      {...aliasedProps}
      {...rest}
      __css={styles}
    />
  );
});

Text.defaultProps = {
  variant: 'body',
  color: 'text.primary',
};
