import { useState } from 'react';
import { useToast } from '@terminal/design-system';
import type { ApolloError } from '@apollo/client';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import { useCustomerAuth } from 'talent-hub/utils';
import { CompanyName } from './CompanyName';
import type { StepInfo } from '../../Onboarding.types';
import { UpsertCompanyName } from './data/graphql';
import type { UpsertCompanyNameMutation, UpsertCompanyNameMutationVariables } from './data/graphql';

export function CompanyNameController({
  onStepComplete,
  stepInfo,
}: {
  onStepComplete: () => void;
  stepInfo: StepInfo;
}) {
  const toast = useToast();
  const auth = useCustomerAuth();
  const [companyName, setCompanyName] = useState<string>('');

  const [upsertCompanyName, { loading: isLoading_upsertCompanyName }] = useMutation<
    UpsertCompanyNameMutation,
    UpsertCompanyNameMutationVariables
  >(UpsertCompanyName, {
    onCompleted: () => {
      onStepComplete();
    },
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
  });

  return (
    <CompanyName
      value={companyName}
      onValueChange={setCompanyName}
      onNextClick={() => {
        upsertCompanyName({
          variables: {
            userId: auth.user?.id as number,
            companyName,
            lastStepCompleted: 'Company Name',
          },
        });
      }}
      stepInfo={stepInfo}
      isNextDisabled={!companyName?.trim?.()}
      isLoading={isLoading_upsertCompanyName}
    />
  );
}
