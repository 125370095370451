import { compareByDateAsc } from 'global/utils';

import {
  calculateRoleAggregatesByStageGraphs,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  determineRoleHealth,
  hasCandidateHitStage,
  mergePostingsAndPositions,
  readableLocationOr,
} from 'talent-hub/utils';

import type { MergedPostingAndPosition } from 'talent-hub/utils';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';
import type { JobHealthStatus } from 'talent-hub/constants';
import moment from 'moment';
import type {
  ManageRolesQuery,
  OverviewApplicantWorkflow,
  OverviewPosting,
  OverviewFilledPosting,
} from './data';
import type { JobsToReview } from './components/JobsToReview';

export type PostingWithTotalsAndHealthStatus = MergedPostingAndPosition<OverviewPosting> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  healthStatus: JobHealthStatus;
};

function augmentPostingWithTotalsAndHealthStatus(
  postings: MergedPostingAndPosition<OverviewPosting>[],
): PostingWithTotalsAndHealthStatus[] {
  return postings.map((posting) => {
    const stagedCandidates = posting.applicant_workflows
      .map(({ workflow_statuses }) => {
        return workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus);
      })
      // Removes the candidate that has any of the statuses in the Ignored bucket
      .filter((statuses) => !hasCandidateHitStage('Ignored', statuses))
      .map(createCandidateStagesInfo);

    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const { totalCandidates, totalActiveCandidates, totalHired } =
      calculateRoleAggregatesByStageGraphs(candidateStageGraph);

    return {
      ...posting,
      openedDate: moment(posting.openedDate).format('MMM D, YYYY'),
      totalCandidates,
      totalActiveCandidates,
      totalHired,
      healthStatus: determineRoleHealth({
        totalHired,
        totalPositions: posting.openHeadCount,
        totalActiveCandidates,
        totalSubmissions: totalCandidates,
        roleCreateDate: posting.openedDate,
        jobStatus: posting.job?.status || null,
      }),
    };
  });
}

export type FilledRole = {
  id: number;
  openedDate: string;
  name: Maybe<string>;
  location: string;
  totalHired: number;
};

export type BasicWidget = {
  id: number;
  label: string;
  description?: string;
  number: string | number;
};

const widgets: Record<'positions' | 'candidates' | 'members', BasicWidget> = {
  positions: {
    id: 2,
    label: 'Active Positions',
    number: '',
  },
  candidates: {
    id: 4,
    label: 'Active Candidates',
    number: '',
  },
  members: {
    id: 1,
    label: 'Active Members',
    number: '',
  },
};

export function transformToFilledRoles(filledPostings: OverviewFilledPosting[]): FilledRole[] {
  const fieldPostingsWithEmptyWorkflowStatus = filledPostings.map((positing) => ({
    ...positing,
    applicant_workflows: [] as OverviewApplicantWorkflow[],
    positions: positing.positions.map((position) => ({
      ...position,
      applicant_workflows: [] as OverviewApplicantWorkflow[],
    })),
  }));

  const filledRoles = mergePostingsAndPositions(fieldPostingsWithEmptyWorkflowStatus);

  return (
    filledRoles
      // eslint-disable-next-line no-self-compare
      .filter((filledRole) => filledRole.filled_positions_aggregate.total?.hires || 0 > 0)
      .map((filledRole) => {
        return {
          id: filledRole.id,
          openedDate: moment(filledRole.openedDate).format('MMM D, YYYY'),
          name: filledRole.name,
          location: filledRole.locations
            .map((loc) =>
              loc.icims_location
                ? readableLocationOr(
                    loc.icims_location.readable_locations,
                    loc.icims_location?.value || '',
                  )
                : '',
            )
            .sort()
            .join('; '),
          totalHired: filledRole.filled_positions_aggregate.total?.hires || 0,
        };
      })
      .sort((roleA, roleB) => compareByDateAsc(roleA.openedDate, roleB.openedDate))
  );
}
export function serializeManageRoles(data?: ManageRolesQuery): {
  activeCandidates: number | string;
  headcount: number | string;
  postingsList: PostingWithTotalsAndHealthStatus[];
  activeMembers: number | string;
  totalHires: number | string;
  filledRoles: FilledRole[];
  widgets: BasicWidget[];
  rolesPendingReview: React.ComponentProps<typeof JobsToReview>['jobs'];
} {
  if (!data) {
    return {
      activeCandidates: '-',
      headcount: '-',
      postingsList: [],
      filledRoles: [],
      activeMembers: '-',
      totalHires: '-',
      widgets: [widgets.positions, widgets.candidates, widgets.members],
      rolesPendingReview: [],
    };
  }

  const postings = mergePostingsAndPositions(data.active_postings);
  const augmentedPostings = augmentPostingWithTotalsAndHealthStatus(postings);

  const filledRoles = transformToFilledRoles(data.filled_postings);

  const { roleTotalActiveCandidates, roleTotalOpenHeadCount, roleTotalHires } =
    augmentedPostings.reduce(
      (acc, augmentedPosting) => {
        return {
          roleTotalActiveCandidates:
            acc.roleTotalActiveCandidates + augmentedPosting.totalActiveCandidates,
          roleTotalOpenHeadCount: acc.roleTotalOpenHeadCount + augmentedPosting.openHeadCount,
          roleTotalHires: acc.roleTotalHires + augmentedPosting.totalHired,
        };
      },
      { roleTotalActiveCandidates: 0, roleTotalOpenHeadCount: 0, roleTotalHires: 0 },
    );

  const roleActiveMembers = Object.values(
    data.members.reduce((acc, next) => ({ ...acc, [next.member_details.id]: true }), {}),
  ).length;

  const filledPositionsTotalHired = data.filled_postings.reduce((acc, filledPosting) => {
    const totalHiredCount = filledPosting.filled_positions_aggregate.total?.hires || 0;
    return acc + totalHiredCount;
  }, 0);
  // Total hires should never be less than the current count of activeMembers
  // since all active members must have been hired
  const totalHires = Math.max(roleActiveMembers, roleTotalHires + filledPositionsTotalHired);

  return {
    postingsList: augmentedPostings,
    activeCandidates: roleTotalActiveCandidates,
    headcount: roleTotalOpenHeadCount,
    activeMembers: roleActiveMembers,
    totalHires,
    filledRoles,
    widgets: [
      {
        ...widgets.positions,
        description: `${roleTotalOpenHeadCount} open position${
          roleTotalOpenHeadCount === 1 ? '' : 's'
        } across ${augmentedPostings.length} role${augmentedPostings.length !== 1 ? 's' : ''}`,
        number: roleTotalOpenHeadCount,
      },
      {
        ...widgets.candidates,
        description: 'Candidates actively in process for your open roles',
        number: roleTotalActiveCandidates,
      },
      {
        ...widgets.members,
        description: `${roleActiveMembers} active, ${totalHires} hired all time`,
        number: roleActiveMembers,
      },
    ],
    rolesPendingReview: data.job.map((rolePendingReview) => ({
      submittedDate: moment(rolePendingReview.updated_at).format('MMM D, YYYY'),
      title: rolePendingReview.title,
      id: rolePendingReview.id,
      status: rolePendingReview.status,
      positions: rolePendingReview.job_positions_aggregate.aggregate?.count || 0,
      locations: rolePendingReview.job_acceptable_locations
        .map((loc) => loc.location.value)
        .sort()
        .join('; '),
    })),
  };
}
