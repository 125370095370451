import {
  calculateRoleAggregatesByStageGraphs,
  capitalizeEachWord,
  createCandidatesStageGraph,
  createCandidateStagesInfo,
  mergePostingsAndPositions,
} from 'talent-hub/utils';

import type {
  MergedPostingAndPosition,
  AllStagesAllBuckets,
  CandidateStageInfo,
  MergedPostingAndPosition as MergedPostingAndPositionGeneric,
} from 'talent-hub/utils';
import { CandidateInterviewSubStageName } from 'talent-hub/constants';
import { compareByObjectsDateAsc } from 'global/utils';
import type { CandidateWorkflowStatus } from 'talent-hub/types';
import moment from 'moment';
import type { SelectDashboardQuery, DashboardPosting } from './data';

type PostingWithTotals = MergedPostingAndPosition<DashboardPosting> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  candidatesToReviewByPosting: CandidateToReviewInfoWithStatusInfo[];
};

type CandidatesToReviewStages = Pick<
  AllStagesAllBuckets<CandidateToReviewStagingInfo>,
  'submission' | 'interview'
>;

export function candidateFriendlyStatusInfo(status: string | undefined): {
  status: string;
  friendlyStatustext: string;
  linkText: string;
} {
  // Product decided to use Awaiting Your Review and the warning color as a default state because it's a generic enough status to apply to just about any situation
  if (!status) {
    return {
      friendlyStatustext: 'Awaiting Your Review',
      status: 'warning',
      linkText: 'View Candidate',
    };
  }

  switch (status) {
    case 'Submitted to Client - Submitted':
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'Review Candidate',
      };

    case 'Client Review - Interview Completed':
    case 'Client Review - Awaiting Client Feedback':
    case 'Client Review - Final Interview Completed':
    case 'Client Review - Interview #1 - Initial Interview':
    case 'Client Review - Interview #2 - Technical Interview / Assessment':
    case 'Client Review - Interview #3 - Final interview':
    case 'Client Review - Interview #4 - Additional Interview':
    case 'Client Review - Interview #1':
    case 'Client Review - Interview #2':
    case 'Client Review - Interview #3':
    case 'Client Review - Interview #4':
      return {
        friendlyStatustext: 'Ready for Feedback',
        status: 'info',
        linkText: 'Leave Feedback',
      };
    case 'Client Review - Interview Scheduled':
    case 'Submitted to Client - Client Portal Advance':
    case 'Client Review - Interview to be Scheduled':
    case 'Client Review - Interview #1 to be Scheduled':
    case 'Client Review - Interview #2 to be Scheduled':
    case 'Client Review - Interview #3 to be Scheduled':
    case 'Client Review - Interview #4 to be Scheduled':
      return {
        friendlyStatustext: 'Interview Scheduled',
        status: 'info',
        linkText: 'Review Candidate',
      };

    default:
      return {
        friendlyStatustext: 'Awaiting Your Review',
        status: 'warning',
        linkText: 'View Candidate',
      };
  }
}

function groupCandidatesToDashboardCandidatesToReview({
  submission,
  interview,
}: CandidatesToReviewStages): {
  upcomingSubmissionCandidates: CandidateToReviewInfoWithStatusInfo[];
  upcomingInterviewCandidates: CandidateToReviewInfoWithStatusInfo[];
  interviewsToBeScheduledCandidates: CandidateToReviewInfoWithStatusInfo[];
} {
  const pickProfileData = (candidateProfile: CandidateToReviewStagingInfo) => {
    const { friendlyStatustext, status, linkText } = candidateFriendlyStatusInfo(
      candidateProfile.candidateToReviewInfo.statusText,
    );
    return {
      ...candidateProfile.candidateToReviewInfo,
      statusText: friendlyStatustext,
      status,
      linkText,
    };
  };
  const upcomingInterviewCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.Scheduled] || [];

  const interviewsToBeScheduledCandidatesUnMapped: CandidateToReviewStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.ToBeScheduled] || [];
  return {
    upcomingSubmissionCandidates: submission.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingInterviewCandidates: upcomingInterviewCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    interviewsToBeScheduledCandidates: interviewsToBeScheduledCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
  };
}

export type CandidateToReviewInfo = {
  jobID?: number | null;
  id?: number | null;
  name: string;
  statusText?: string;
  date?: string;
  role: string;
};

export type CandidateToReviewInfoWithStatusInfo = CandidateToReviewInfo & {
  status: string;
  linkText: string;
};

export type CandidateToReviewStagingInfo = {
  candidateToReviewInfo: CandidateToReviewInfo;
} & CandidateStageInfo;

type MergedPostingAndPositions = MergedPostingAndPositionGeneric<DashboardPosting>[];

type DashboardApplicantWorkflow = MergedPostingAndPositions[number]['applicant_workflows'][number];

function transformToCandidatesWithPartialStageInfo(
  applicantWorkFlows: DashboardApplicantWorkflow[],
  postingName: MergedPostingAndPosition<DashboardPosting>['name'],
): CandidateToReviewStagingInfo[] {
  return applicantWorkFlows.map(({ job_profile_id, icims_person, workflow_statuses }) => {
    const statuses = workflow_statuses.map(
      ({ date, status }): CandidateWorkflowStatus => ({
        date,
        title: status?.title || '',
      }),
    );

    const { currentStatus, ...rest } = createCandidateStagesInfo(statuses);

    return {
      ...rest,
      currentStatus,
      candidateToReviewInfo: {
        jobID: job_profile_id,
        id: icims_person?.profile_id,
        name: `${capitalizeEachWord(
          icims_person?.candidate?.firstname || icims_person?.firstname,
        )} ${capitalizeEachWord(icims_person?.candidate?.lastname || icims_person?.lastname)}`,
        statusText: currentStatus?.title,
        date: moment(currentStatus?.date).format('MMM D, YYYY'),
        role: postingName || '-',
      },
    };
  });
}

function augmentPostingWithTotals(
  postings: MergedPostingAndPosition<DashboardPosting>[],
): PostingWithTotals[] {
  return postings.map((posting) => {
    const stagedCandidates = transformToCandidatesWithPartialStageInfo(
      posting.applicant_workflows,
      posting.name,
    );
    const candidateStageGraph = createCandidatesStageGraph(stagedCandidates);

    const {
      upcomingSubmissionCandidates,
      upcomingInterviewCandidates,
      interviewsToBeScheduledCandidates,
    } = groupCandidatesToDashboardCandidatesToReview({
      submission: candidateStageGraph.submission,
      interview: candidateStageGraph.interview,
    });

    const { totalCandidates, totalActiveCandidates, totalHired } =
      calculateRoleAggregatesByStageGraphs(candidateStageGraph);

    return {
      ...posting,
      totalCandidates,
      totalActiveCandidates,
      totalHired,
      // We need to show on candidatesToReviewTable the same candidates under "Submissions Requiring Action" and "Interviews - Active" supported statuses on roles
      candidatesToReviewByPosting: upcomingSubmissionCandidates.concat(
        upcomingInterviewCandidates,
        interviewsToBeScheduledCandidates,
      ),
    };
  });
}

export function serializeDashboard(data?: SelectDashboardQuery): {
  activeCandidates: number | string;
  totalSubmittedCandidates: number | string;
  openPositions: number | string;
  openRoles: number | string;
  candidatesToReview: CandidateToReviewInfoWithStatusInfo[];
  viewMoreCandidatesTitle: string | boolean;
} {
  if (!data) {
    return {
      activeCandidates: '-',
      totalSubmittedCandidates: '-',
      openRoles: '-',
      candidatesToReview: [],
      viewMoreCandidatesTitle: false,
      openPositions: '-',
    };
  }

  const postings = mergePostingsAndPositions(data.active_postings);

  const augmentedPostings = augmentPostingWithTotals(postings);
  const candidatesToReviewList = augmentedPostings.flatMap((h) => h.candidatesToReviewByPosting);

  const { roleTotalActiveCandidates, roleTotalOpenHeadCount, roleTotalCandidateSubmissions } =
    augmentedPostings.reduce(
      (acc, augmentedPosting) => {
        return {
          roleTotalActiveCandidates:
            acc.roleTotalActiveCandidates + augmentedPosting.totalActiveCandidates,
          roleTotalOpenHeadCount: acc.roleTotalOpenHeadCount + augmentedPosting.openHeadCount,
          roleTotalCandidateSubmissions:
            acc.roleTotalCandidateSubmissions + augmentedPosting.totalCandidates,
        };
      },
      {
        roleTotalActiveCandidates: 0,
        roleTotalOpenHeadCount: 0,
        roleTotalCandidateSubmissions: 0,
      },
    );

  const missingCandidatesToReview =
    candidatesToReviewList.length > 3 ? candidatesToReviewList.length - 3 : 0;

  return {
    candidatesToReview: candidatesToReviewList.slice(0, 3),
    openRoles: augmentedPostings.length,
    openPositions: roleTotalOpenHeadCount,
    activeCandidates: roleTotalActiveCandidates,
    totalSubmittedCandidates: roleTotalCandidateSubmissions,
    viewMoreCandidatesTitle:
      missingCandidatesToReview !== 0 &&
      `View ${missingCandidatesToReview} more candidate${missingCandidatesToReview > 1 ? 's' : ''}`,
  };
}
