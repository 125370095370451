import type { FlexProps, BoxProps, IconButtonProps } from '@chakra-ui/react';
import { Flex, IconButton, Box } from '@chakra-ui/react';
import type { KeenSliderHooks, KeenSliderInstance, KeenSliderOptions } from 'keen-slider/react';
import { useKeenSlider } from 'keen-slider/react';
import { forwardRef, useRef } from 'react';

// This is similar to Candidate carrousel /web-customer/src/candidate/app/components/Carousel/Carousel.tsx but if has some different props
// In the future, I think that we should merge both and create a "global" Carousel component
export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
  return (
    <Flex
      ref={ref}
      className="chakra-carousel"
      overflow="hidden"
      position="relative"
      userSelect="none"
      {...props}
    />
  );
});

export function CarouselSlide(props: BoxProps) {
  return (
    <Box
      className="chakra-carousel__slide"
      position="relative"
      overflow="hidden"
      width="100%"
      minH="100%"
      {...props}
    />
  );
}

export function CarouselIconButton(props: IconButtonProps) {
  return (
    <IconButton
      minW="auto"
      fontSize="md"
      color="brand.main"
      _hover={{
        _disabled: { color: 'ui.darker.disabled' },
        boxShadow: 'none',
      }}
      _focus={{ boxShadow: 'none' }}
      _focusVisible={{ boxShadow: 'outline' }}
      _disabled={{
        color: 'ui.darker.disabled',
        cursor: 'not-allowed',
      }}
      {...props}
    />
  );
}

export type CarouselOptions = KeenSliderOptions & {
  /**
   * Set the true if the carousel is initially rendered in a UI that is not visible.
   * It will recalculates the width of sliced after it is created.
   */
  shouldDelaySizingCalculation?: boolean;
};

export const useCarousel = (options?: CarouselOptions) => {
  const timer = useRef<NodeJS.Timeout>();
  const defaultOptions = { selector: '.chakra-carousel__slide' };

  const otherOptions = options?.shouldDelaySizingCalculation
    ? {
        created(slider: KeenSliderInstance<{}, {}, KeenSliderHooks>) {
          timer.current = setTimeout(() => {
            slider.update();
          }, 200);
        },
        destroyed() {
          clearTimeout(timer.current as NodeJS.Timeout);
        },
      }
    : {};

  return useKeenSlider<HTMLDivElement>({
    ...defaultOptions,
    ...otherOptions,
    renderMode: 'precision',
    ...options,
  });
};
