import { Link as RouterLink } from 'react-router-dom';
import type { FormikHelpers, FormikState } from 'formik';
import {
  Image,
  Heading,
  Button,
  Text,
  ArrowRightIcon,
  Flex,
  InfoIconBold,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Box,
  Grid,
  GridItem,
  Divider,
  Tooltip,
  CheckboxGroup,
  CheckboxCard,
  ModalFooter,
  Skeleton,
} from '@terminal/design-system';

import { PageLayout, PageLayoutPaddedBody, Card, CardBody } from 'talent-hub/components';
import type { serializeCandidateBrowse } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { CandidateCarousel, OtherGreatCandidates } from 'talent-hub/shared/features/dashboard';

import { useCalendlyWidget } from '../components/CalendlyWidget';
import illustration from '../assets/guy.svg?url';
import plant from './assets/plant.svg?url';
import bulbSrc from './assets/bulb.svg?url';
import patternSrc from './assets/pattern.svg?url';
import suitCaseSrc from './assets/suitCase.svg?url';
import type { serializeDashboard } from './Dashboard.serializer';

export function Dashboard({
  pageLayoutProps,
  userDisplayName,
  onCandidatePreviewClick,
  clientProspect,
  candidates,
  candidatesRecentlyActive,
  candidatesTopCandidateExperience,
  candidateZeroOneExperience,
  candidateTechLead,
  displayViewAllAtCount = 3,
  hasRolesCreated = false,
  roleOptions = [],
  yoeOptions = [],
  values,
  errors,
  isFormValid,
  setFieldValue,
  submitForm,
  resetForm,
  isFormSubmitting,
  isLoadingMyRoles = false,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  userDisplayName: string;
  onCandidatePreviewClick: (candidateID: number) => void;
  clientProspect?: ReturnType<typeof serializeDashboard>['clientProspect'];
  candidates: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidatesRecentlyActive: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidatesTopCandidateExperience: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidateZeroOneExperience: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidateTechLead: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  displayViewAllAtCount?: number;
  hasRolesCreated?: boolean;
  onRoleCreationClick?: () => void;
  roleOptions?: ReturnType<typeof serializeDashboard>['roleOptions'];
  yoeOptions?: ReturnType<typeof serializeDashboard>['yoeOptions'];
  isFormValid?: boolean;
  isFormSubmitting?: boolean;
  isLoadingMyRoles?: boolean;
} & Partial<
  Pick<FormikState<{ role: string; yearsOfExperience: string }>, 'values' | 'errors' | 'touched'>
> &
  Partial<
    Pick<
      FormikHelpers<{ role: string; yearsOfExperience: string }>,
      'submitForm' | 'setFieldValue' | 'resetForm'
    >
  >) {
  const { isOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen_roleCreation,
    onClose: onClose_roleCreation,
    onOpen: onOpen_roleCreation,
  } = useDisclosure();
  const calendlyModal = useCalendlyWidget();

  return (
    <PageLayout headerTitle="Dashboard" {...pageLayoutProps} disableMaxWidth>
      <PageLayoutPaddedBody bgColor="bg.primary" pt={10} pl={[6, 6, 10]}>
        <Flex mb={3}>
          <Heading variant="heading-1">Welcome,</Heading>
          <Heading variant="heading-1">&nbsp;{userDisplayName}!</Heading>
        </Flex>
        <Grid
          templateColumns={['100%', '100%', 'minmax(37rem, 1fr) minmax(min-content, 22rem)']}
          gap={[0, 0, 12]}
        >
          <GridItem pos="relative" zIndex={1}>
            <Flex flexDir="column">
              <Heading variant="heading-2">My Roles</Heading>
              {hasRolesCreated && (
                <Card pb={6} px={0} pt={0} h="auto" w="full" mt={3}>
                  <CardBody flexDir="column">
                    <Box py={4} px={[6, 6, 8]} bgColor="ui.inverse.secondary">
                      <Heading variant="heading-3">
                        {clientProspect?.jobOpenings?.[0]?.label}
                      </Heading>
                      <Flex pos="relative" alignItems="center">
                        <Text variant="caption">Experience:</Text>
                        <Text variant="caption" fontWeight="bold" ml={1}>
                          {clientProspect?.jobOpenings?.[0]?.yoeLabel}
                        </Text>
                        <Divider orientation="vertical" h={6} mx={2} />
                        <Text variant="caption">Location:</Text>
                        <Text variant="caption" fontWeight="bold" ml={1}>
                          All
                        </Text>
                        <Tooltip
                          label="Check out Explore Candidates to search for specific locations"
                          aria-label="Check out Explore Candidates to search for specific locations"
                        >
                          <InfoIconBold fontSize="xs" ml={1} color="accent.main" />
                        </Tooltip>
                      </Flex>
                    </Box>
                    <Box px={6}>
                      {!isLoadingMyRoles && candidates.length > 0 && (
                        <CandidateCarousel
                          key="candidates-carousel"
                          candidates={candidates}
                          onItemClick={onCandidatePreviewClick}
                          tagLabel="New Matches!"
                          shouldDisplayViewAll={
                            candidates?.[0]?.totalCount >= displayViewAllAtCount
                          }
                          viewAllHref={`/explore-candidates?role=${clientProspect?.jobOpenings?.[0]?.type}&yoe=${clientProspect?.jobOpenings?.[0]?.yoe}`}
                        />
                      )}
                      {!isLoadingMyRoles && candidates.length === 0 && (
                        <Flex flexDir="column" alignItems="center">
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            w="4.875rem"
                            h="4.875rem"
                            borderRadius="4.875rem"
                            bgColor="bg.tertiary"
                            mt={4}
                          >
                            <Image w="2.875rem" src={suitCaseSrc} />
                          </Flex>
                          <Text variant="body" fontWeight="bold" color="text.secondary" mt={2}>
                            You Have No Matches
                          </Text>
                          <Button
                            as={RouterLink}
                            variant="solid"
                            mt={4}
                            w="full"
                            maxW={64}
                            to="/explore-candidates"
                          >
                            View All Candidates
                          </Button>
                        </Flex>
                      )}
                      {isLoadingMyRoles && <Skeleton h="11.813rem" mt={20} />}
                    </Box>
                  </CardBody>
                </Card>
              )}

              {!hasRolesCreated && (
                <Card pb={6} px={4} pt={4} h="auto" w="full" mt={3} bgColor="ui.inverse.secondary">
                  <CardBody flexDir="column" alignItems="center">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      w="4.875rem"
                      h="4.875rem"
                      borderRadius="4.875rem"
                      bgColor="bg.tertiary"
                    >
                      <Image w="2.875rem" src={suitCaseSrc} />
                    </Flex>
                    <Text variant="body" fontWeight="bold" color="text.secondary" mt={2}>
                      You Have No Roles
                    </Text>
                    <Text variant="body" color="text.disabled">
                      Open a role to start your hiring
                    </Text>
                    <Button variant="solid" mt={4} onClick={onOpen_roleCreation}>
                      Open Role
                    </Button>
                  </CardBody>
                </Card>
              )}
              <Box mt={6}>
                <Heading variant="heading-2" mb={3}>
                  Other Great Candidates
                </Heading>
                <OtherGreatCandidates
                  candidatesRecentlyActive={candidatesRecentlyActive}
                  candidatesTopCandidateExperience={candidatesTopCandidateExperience}
                  candidateZeroOneExperience={candidateZeroOneExperience}
                  candidateTechLead={candidateTechLead}
                  onCandidatePreviewClick={onCandidatePreviewClick}
                  displayViewAllAtCount={displayViewAllAtCount}
                />
              </Box>
            </Flex>
          </GridItem>
          <GridItem pos="relative" zIndex={1} display="flex" flexDir="column">
            <Flex
              p={4}
              border="1px solid"
              borderColor="ui.secondary"
              bgColor="#A0CFDA"
              flexDir="column"
              gap={3}
              mt={[6, 6, 0]}
            >
              <Heading variant="heading-3">Ready to start hiring your dream team?</Heading>
              <Text variant="body">Schedule a call with our team.</Text>
              <Button variant="solid" onClick={calendlyModal.onOpen}>
                Schedule Call
              </Button>
            </Flex>
            <Flex p={3} bgColor="bg.secondary" flexDir="row" gap={3} mt={[6, 6, 8]}>
              <Flex alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  w="3rem"
                  h="3rem"
                  borderRadius="3rem"
                  bgColor="accent.lighter"
                >
                  <Image w="1.75rem" src={bulbSrc} alt="bulb" />
                </Flex>
              </Flex>
              <Box>
                <Heading variant="heading-2">Did you know?</Heading>
                <Text variant="body" fontWeight="bold">
                  You can hire 35% faster?
                </Text>
                <Button
                  p={0}
                  variant="ghost"
                  colorScheme="primary"
                  size="small"
                  fontWeight="bold"
                  textDecoration="none"
                  fontFamily="body"
                >
                  Learn about Terminal hiring
                  <ArrowRightIcon ml={3} />
                </Button>
              </Box>
            </Flex>
            <Flex p={3} bgColor="bg.secondary" flexDir="row" gap={3} mt={[6, 6, 8]}>
              <Flex alignItems="center">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  w="3rem"
                  h="3rem"
                  borderRadius="3rem"
                  bgColor="accent.lighter"
                >
                  <Image w="1.75rem" src={bulbSrc} alt="bulb" />
                </Flex>
              </Flex>
              <Box>
                <Heading variant="heading-2">Did you know?</Heading>
                <Text variant="body" fontWeight="bold">
                  You can access the top 7% of talent?
                </Text>
                <Button
                  p={0}
                  variant="ghost"
                  colorScheme="primary"
                  size="small"
                  fontWeight="bold"
                  textDecoration="none"
                  fontFamily="body"
                >
                  Learn about Terminal recruiting
                  <ArrowRightIcon ml={3} />
                </Button>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
        <Image
          pos="fixed"
          w="34.25rem"
          maxW="unset"
          bottom={0}
          right={0}
          src={patternSrc}
          role="presentation"
        />
        <Image
          display={['none', 'none', 'block']}
          pos="fixed"
          bottom={6}
          right={6}
          w="10.375rem"
          src={plant}
          alt="Plant"
        />
      </PageLayoutPaddedBody>
      {/* Why I need to talk modal */}
      <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="scale">
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Image
              w={40}
              mx="auto"
              mb={18}
              src={illustration}
              alt="Guy with a computer and stars"
            />
            <Heading variant="heading-2" mb={1}>
              Why Do I Need to Talk to Someone?
            </Heading>
            <Text variant="body">
              Terminal considers each customer a true partner, and we take the time to listen to
              your team&apos;s unique hiring needs. Our team talks to companies just like yours
              every day, and we know what common pain points you may be experiencing. That insider
              knowledge, combined with an understanding of your unique needs, helps us craft the
              perfect plan for building an engineering team that will ensure your success!
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Create new role modal */}
      <Modal
        isCentered
        isOpen={isOpen_roleCreation}
        onClose={() => {
          resetForm?.();
          onClose_roleCreation();
        }}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent maxW="lg">
          <ModalCloseButton top={6} />
          <ModalHeader
            p={6}
            bgColor="bg.primary"
            borderBottom="1px solid"
            borderBottomColor="ui.secondary"
          >
            <Heading variant="heading-4" color="text.secondary">
              Create New Role
            </Heading>
          </ModalHeader>
          <ModalBody maxH="calc(100vh - 166px)" overflowY="scroll">
            <Box>
              <Text variant="label" fontWeight="bold" textAlign="left" mb={2}>
                Role Type
              </Text>
              <CheckboxGroup>
                <Grid
                  templateColumns={[
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(4, minmax(0, 1fr))',
                  ]}
                  gap={3}
                  mt={2}
                >
                  {roleOptions.map(({ value, label }) => {
                    const isSelected = value === values?.role;

                    return (
                      <GridItem>
                        <CheckboxCard
                          role="checkbox"
                          aria-checked={isSelected}
                          aria-labelledby="candidate-role"
                          onChange={() => {
                            setFieldValue?.('role', value);
                          }}
                          isChecked={isSelected}
                          key={`insider-role-${value}`}
                          h={20}
                          w="full"
                          sx={{
                            '.chakra-button': {
                              h: 20,
                              w: 'full',
                              minW: 'auto',
                            },
                            '.checkbox-card__icon-container': {
                              px: [3, 3, 2],
                            },
                            svg: {
                              color: 'ui.success',
                            },
                          }}
                        >
                          <Text variant="body" fontWeight={isSelected ? 'bold' : 'regular'}>
                            {label}
                          </Text>
                        </CheckboxCard>
                      </GridItem>
                    );
                  })}
                </Grid>
              </CheckboxGroup>
            </Box>
            <Box mt={4}>
              <Text variant="label" fontWeight="bold" textAlign="left" mb={2}>
                Years of Experience
              </Text>
              <CheckboxGroup>
                <Grid
                  templateColumns={[
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(2, minmax(0, 1fr))',
                    'repeat(4, minmax(0, 1fr))',
                  ]}
                  gap={3}
                  mt={2}
                >
                  {yoeOptions.map(({ value, label }) => {
                    const isSelected = value === values?.yearsOfExperience;

                    return (
                      <GridItem>
                        <CheckboxCard
                          role="checkbox"
                          aria-checked={isSelected}
                          aria-labelledby="candidate-role"
                          onChange={() => {
                            setFieldValue?.('yearsOfExperience', value);
                          }}
                          isChecked={isSelected}
                          key={`insider-role-${value}`}
                          h={20}
                          w="full"
                          sx={{
                            '.chakra-button': {
                              h: 20,
                              w: 'full',
                              minW: 'auto',
                            },
                            '.checkbox-card__icon-container': {
                              px: [3, 3, 2],
                            },
                            svg: {
                              color: 'ui.success',
                            },
                          }}
                        >
                          <Text variant="body" fontWeight={isSelected ? 'bold' : 'regular'}>
                            {label}
                          </Text>
                        </CheckboxCard>
                      </GridItem>
                    );
                  })}
                </Grid>
              </CheckboxGroup>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              onClick={() => {
                submitForm?.();
                onClose_roleCreation();
              }}
              isDisabled={!isFormValid || Object.keys(errors || {}).length > 0}
              isLoading={isFormSubmitting}
            >
              Create Role
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
}
