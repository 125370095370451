import {
  Flex,
  Box,
  Text,
  Heading,
  Button,
  Image,
  MailIcon,
  Divider,
} from '@terminal/design-system';
import { useOnScreen } from 'global/utils';
import { useRef } from 'react';
import plantBGSrc from '../../assets/plant_bg.svg?url';
import { LeftHeaderTitle, OnBoardingPage, RightContentContainer } from '../../components';

export function SignUpSuccess({
  is_resend_loading,
  on_logout_clicked,
  on_resend_verification_email_clicked,
  is_resend_disabled,
  resend_button_title,
}: {
  on_resend_verification_email_clicked: () => void;
  is_resend_loading: boolean;
  on_logout_clicked: () => void;
  is_resend_disabled: boolean;
  resend_button_title: string;
}) {
  const joinCompaniesTextRef = useRef(null);
  const isJoinCompaniesText_viewportVisible = useOnScreen(joinCompaniesTextRef);

  return (
    <OnBoardingPage
      leftContent={
        <>
          <LeftHeaderTitle text="Please verify your email address." />
          <Box
            display={['none', 'none', 'block']}
            position="fixed"
            width="100%"
            bottom={2}
            left="40%"
          >
            <Image src={plantBGSrc} alt="plant" />
          </Box>
        </>
      }
      rightContent={
        <RightContentContainer
          mx={[0, 0, '15%', '15%', '7.5rem']}
          maxW="35rem"
          justifyContent={[
            'flex-start',
            'flex-start',
            // On average desktop, we want the form to be at the top of it's container
            // On bigger desktops, we want the form to be centered
            isJoinCompaniesText_viewportVisible === false ? 'flex-start' : 'center',
          ]}
        >
          <Box
            pos="relative"
            w="full"
            py={8}
            px={10}
            border="1px solid"
            borderColor="ui.secondary"
            borderRadius="base"
            bg="bg.primary"
            zIndex="docked"
          >
            <Box>
              <Heading variant="heading-2" mb={5}>
                Check your inbox!
                <MailIcon
                  w={6}
                  h={6}
                  ml={3}
                  __css={{
                    path: {
                      fill: 'ui.lighter.warning',
                    },
                  }}
                />
              </Heading>
              <Text textAlign="left">
                You will need to verify your email address before you can proceed. Please check your
                inbox for a verification email from Terminal.
                <br />
                <br />
                If you haven&apos;t received an email in a few minutes, you can return to this
                screen and request a new link.
              </Text>
              <Divider py={4} />
              <Flex flexDirection={['column', 'row']} alignItems="center" pt={6}>
                <Button
                  variant="ghost"
                  colorScheme="primary"
                  mb={2}
                  w="full"
                  onClick={on_resend_verification_email_clicked}
                  isLoading={is_resend_loading}
                  disabled={is_resend_disabled}
                >
                  {resend_button_title || 'Request A New Link'}
                </Button>
                <Button
                  variant="outline"
                  colorScheme="primary"
                  mb={2}
                  w="full"
                  onClick={on_logout_clicked}
                >
                  Logout
                </Button>
              </Flex>
            </Box>
          </Box>
        </RightContentContainer>
      }
    />
  );
}
