import { textTheme } from '../Text/Text.theme';

type Dict = Record<string, any>;

export const buttonTheme = {
  baseStyle: () => ({
    width: 'auto',
    borderRadius: 'none',
    _focus: {
      boxShadow: 'outline',
    },
    _disabled: {
      opacity: 1,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {
      _disabled: {
        bg: 'initial',
      },
    },
  }),
  sizes: {
    lg: {
      h: 12,
      minW: 40,
      ...textTheme.variants.label,
      px: 6,
    },
    md: {
      h: 10,
      minW: 40,
      ...textTheme.variants.label,
      px: 6,
    },
    sm: {
      h: 8,
      minW: 40,
      ...textTheme.variants.label,
      px: 6,
    },
  },
  variants: {
    solid: (props: Dict) => {
      const { colorScheme } = props;
      type ButtonColorScheme = 'primary' | 'accent' | 'error';

      const validColorScheme: ButtonColorScheme = ['primary', 'accent', 'error'].includes(
        colorScheme,
      )
        ? colorScheme
        : 'primary';

      const colorMap: {
        [key in ButtonColorScheme]: {
          bg: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          bg: 'brand.main',
          hoverBg: 'brand.darkest',
          activeBg: 'brand.darkest',
        },
        accent: {
          bg: 'accent.main',
          hoverBg: 'accent.darker',
          activeBg: 'accent.darker',
        },
        error: {
          bg: 'ui.error',
          hoverBg: 'ui.darker.error',
          activeBg: 'ui.darker.error',
        },
      };

      return {
        bg: colorMap[validColorScheme].bg,
        color: 'text.inverse',
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            bg: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          bg: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },
    outline: (props: Dict) => {
      const { colorScheme } = props;
      const validColorScheme: ButtonColorScheme = ['primary', 'accent', 'error'].includes(
        colorScheme,
      )
        ? colorScheme
        : 'primary';

      type ButtonColorScheme = 'primary' | 'accent' | 'error';

      type AccessibleColor = {
        color: string;
        borderColor: string;
        hoverBg: string;
        activeBg: string;
      };

      const colorMap: { [key in ButtonColorScheme]: AccessibleColor } = {
        primary: {
          color: 'brand.darkest',
          borderColor: 'brand.darkest',
          hoverBg: 'brand.lightest',
          activeBg: 'brand.lightest',
        },
        accent: {
          color: 'accent.darkest',
          borderColor: 'accent.darkest',
          hoverBg: 'accent.lightest',
          activeBg: 'accent.lightest',
        },
        error: {
          color: 'ui.darkest.error',
          borderColor: 'ui.darkest.error',
          hoverBg: 'ui.lightest.error',
          activeBg: 'ui.lightest.error',
        },
      };

      return {
        color: colorMap[validColorScheme].color,
        border: '1px',
        borderColor: colorMap[validColorScheme].borderColor,
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            borderColor: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          borderColor: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },

    link: {
      padding: 0,
      color: 'text.primary',
      verticalAlign: 'baseline',
      _hover: {
        textDecoration: 'none',
      },
    },
    ghost: ({ colorScheme }: Dict) => {
      const validColorScheme: ButtonColorScheme = ['primary', 'accent', 'error'].includes(
        colorScheme,
      )
        ? colorScheme
        : 'accent';

      type ButtonColorScheme = 'primary' | 'accent' | 'error';

      const colorMap: {
        [key in ButtonColorScheme]: {
          color: string;
          hoverColor: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          color: 'brand.main',
          hoverColor: 'brand.darkest',
          hoverBg: 'brand.lightest',
          activeBg: 'brand.lightest',
        },
        accent: {
          color: 'accent.main',
          hoverColor: 'accent.darker',
          hoverBg: 'accent.lightest',
          activeBg: 'accent.lightest',
        },
        error: {
          color: 'text.error',
          hoverColor: 'ui.darker.error',
          hoverBg: 'ui.lightest.error',
          activeBg: 'ui.lightest.error',
        },
      };

      return {
        color: colorMap[validColorScheme].color,
        py: 3,
        px: 2,
        minW: 'auto',
        _hover: {
          color: colorMap[validColorScheme].hoverColor,
          textDecoration: 'none',
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            color: 'text.disabled',
          },
        },
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
        _disabled: {
          color: 'text.disabled',
        },
      };
    },
    unstyled: {
      bg: 'none',
      color: 'inherit',
      display: 'inline',
      lineHeight: 'inherit',
      m: 0,
      p: 0,
    },
  },
  defaultProps: {
    variant: 'primary', // TODO: [TAL-501] change this to solid
    colorScheme: 'default',
    size: 'lg',
  },
};
