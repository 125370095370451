import { Job_Status_Choices_Enum } from 'global/types';
import moment from 'moment';
import { JobHealthStatus } from 'talent-hub/constants';

export function determineRoleHealth({
  totalHired,
  totalPositions,
  totalActiveCandidates,
  totalSubmissions,
  roleCreateDate,
  jobStatus,
}: {
  totalHired: number;
  totalPositions: number;
  totalActiveCandidates: number;
  totalSubmissions: number;
  roleCreateDate: string;
  jobStatus: Job_Status_Choices_Enum | null;
}): JobHealthStatus {
  if (jobStatus === Job_Status_Choices_Enum.OnHold) {
    return JobHealthStatus.OnHold;
  }

  if (jobStatus === Job_Status_Choices_Enum.Paused) {
    return JobHealthStatus.Paused;
  }

  if (totalHired > 0) {
    return totalHired === totalPositions ? JobHealthStatus.Hired : JobHealthStatus.OnTrack;
  }

  const roleAge = moment().diff(moment(roleCreateDate), 'days');

  if (roleAge > 15) {
    const acceptanceRate = totalSubmissions === 0 ? 0 : totalActiveCandidates / totalSubmissions;

    if (acceptanceRate < 0.3) {
      return JobHealthStatus.WarningHighRejectionRate;
    }
  }

  if (roleAge > 90) {
    return JobHealthStatus.WarningLate;
  }

  return JobHealthStatus.OnTrack;
}

export function determinePositionHealth({
  isHired,
  isAnyPositionHired,
  positionCreateDate,
  roleTotalActiveCandidates,
  roleTotalSubmissions,
}: {
  isHired: boolean;
  isAnyPositionHired: boolean;
  positionCreateDate: string;
  roleTotalActiveCandidates: number;
  roleTotalSubmissions: number;
}): JobHealthStatus {
  if (isHired) {
    return JobHealthStatus.Hired;
  }

  if (isAnyPositionHired) {
    return JobHealthStatus.OnTrack;
  }

  const positionAge = moment().diff(moment(positionCreateDate), 'days');

  if (positionAge > 15) {
    const acceptanceRate =
      roleTotalSubmissions === 0 ? 0 : roleTotalActiveCandidates / roleTotalSubmissions;

    if (acceptanceRate < 0.3) {
      return JobHealthStatus.WarningHighRejectionRate;
    }
  }

  if (positionAge > 90) {
    return JobHealthStatus.WarningLate;
  }

  return JobHealthStatus.OnTrack;
}
