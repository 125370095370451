import { serializeJobCandidateProfile } from 'talent-hub/components/CandidateInformation/JobCandidateProfile.serializer';
import { serializeCandidates } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { SelectBrowseCandidatesByPublicIdQuery } from './data';

/**
 * Reusing these serializers is not ideal,
 * but it's a quick solution to make it work today with minimal drawbacks.
 * In the future, we could optimize by implementing custom serializers specifically for this use case.
 *
 * TODO: Create test for this serializer
 */
export function serializePublicCandidateProfile(data?: SelectBrowseCandidatesByPublicIdQuery) {
  if (!data)
    return {
      candidate: undefined,
      matchingCandidates: [],
    };

  const candidate = serializeJobCandidateProfile({
    data: data
      ? ({
          icims_person: data?.publicCandidateInfo?.candidate?.icims_person?.icims_people,
          candidate: [data?.publicCandidateInfo?.candidate],
        } as any)
      : undefined,
    organizationCurrency: 'USD',
  });

  const matchingCandidates = serializeCandidates(
    data?.publicCandidateInfo?.matching_candidates
      ?.map((candidateInformation) => ({
        // TODO: remove this any Mr. Carlos
        candidate: candidateInformation as any,
      }))
      .filter((x) => x != null) ?? [],
  );

  return {
    candidate,
    matchingCandidates,
  };
}
