import { Heading, Text, Flex, VStack, SkeletonText, Badge } from '@terminal/design-system';
import { Card, CardBody } from 'talent-hub/components/Card';

function StatWidgetThreeColumns({
  icon,
  label,
  description,
  isLoading,
  number,
  target,
  upcomingText,
  minH,
}: {
  icon?: React.ReactNode;
  description?: string | JSX.Element;
  label: string;
  isLoading: boolean;
  number?: number | string;
  target?: number;
  upcomingText?: string;
  minH?: number | string;
}) {
  return (
    <Card minH={minH} h="auto">
      <CardBody justifyContent="center">
        <Flex align="center" justify="space-between">
          {icon}
          <VStack pl={4} flex="1" align="left">
            <Heading variant="heading-3" color="text.primary">
              {label}
            </Heading>
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              {description && <Text color="text.secondary">{description}</Text>}
            </SkeletonText>
          </VStack>
          <SkeletonText isLoaded={!isLoading} noOfLines={1}>
            {(number || number === 0) && (
              <Heading pl={2} variant="heading-1" as="h3" color="text.primary">
                {Intl.NumberFormat('en-US').format(Number(number || 0))}
                {target != null && (
                  <>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    {target}
                  </>
                )}
              </Heading>
            )}
          </SkeletonText>
        </Flex>
        {upcomingText && (
          <SkeletonText mt={2} isLoaded={!isLoading} noOfLines={1} textAlign="right">
            <Badge fontSize="sm" alignSelf="start" colorScheme="primary">
              {upcomingText}
            </Badge>
          </SkeletonText>
        )}
      </CardBody>
    </Card>
  );
}

function StatWidgetTwoColumns({
  icon,
  label,
  description,
  isLoading,
  number,
  target,
  upcomingText,
  minH,
}: {
  icon?: React.ReactNode;
  description?: string;
  label: string;
  isLoading: boolean;
  number: number | string;
  target?: number;
  upcomingText?: string;
  minH?: number | string;
}) {
  return (
    <Card minH={minH} h="auto" px={4} py={6}>
      <CardBody justifyContent="center">
        <Flex align="center" justify="space-between">
          {icon}
          <VStack pl={3} flex="1" align="left" spacing={0}>
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              <Heading variant="heading-2" as="h3" color="text.primary">
                {Intl.NumberFormat('en-US').format(Number(number || 0))}
                {target != null && (
                  <>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    {target}
                  </>
                )}
              </Heading>
            </SkeletonText>
            <Heading variant="heading-3" color="text.primary">
              {label}
            </Heading>
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              {description && <Text color="text.secondary">{description}</Text>}
            </SkeletonText>
          </VStack>
        </Flex>
        {upcomingText && (
          <SkeletonText mt={2} isLoaded={!isLoading} noOfLines={1} textAlign="right">
            <Badge fontSize="sm" alignSelf="start" colorScheme="primary">
              {upcomingText}
            </Badge>
          </SkeletonText>
        )}
      </CardBody>
    </Card>
  );
}

export const StatWidget = {
  Variation: {
    TwoColumns: StatWidgetTwoColumns,
    ThreeColumns: StatWidgetThreeColumns,
  },
};
