// @ts-nocheck
/* eslint-disable */
import mixpanel from 'mixpanel-browser';
import { name as e } from '.';

export const errorDisplayed = async (name, properties) => {
  let mixpanelName = name;

  if (properties && properties.type) {
    const legacyErrors = {
      'no-local-storage': 'Email Sign Up - No Local Storage',
      'resume-invalid-entry': 'Resume - Attach - Invalid Entry',
      'resume-parse-error': 'Resume - Parse - Error',
    };

    mixpanelName = legacyErrors[properties.type] || name;
  }

  return mixpanel.track(mixpanelName, properties);
};

export const ctaClicked = async (name, properties) => {
  let mixpanelName = name;

  if (properties && properties.name) {
    const legacyCtas = {
      'go-to-profile': 'Home Page - Go To Profile Clicked',
      'quick-apply': 'Home Page - Quick Apply Clicked',
      applied: 'Home Page - Applied From Quick Apply Page',
    };

    mixpanelName = legacyCtas[properties.name] || name;
  }

  return mixpanel.track(mixpanelName, properties);
};

export const authentication = async (name, properties) => {
  if (properties.type === 'anonymous') {
    return;
  }

  // eslint-disable-next-line consistent-return
  return mixpanel.track(
    [
      properties.type === 'email-password' ? 'Email Sign In' : 'Google',
      name === e.firebaseRegistration.started ? 'User Started Auth' : 'Auth Completed',
    ].join(' - '),
    properties,
  );
};

export const registration = async (name, properties) => {
  if (!['email-password', 'google'].includes(properties.type)) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return mixpanel.track(
    [
      properties.type === 'email-password' ? 'Email Sign Up' : 'Google',
      name === e.firebaseRegistration.started ? 'User Started Auth' : 'Auth Completed',
    ].join(' - '),
    properties,
  );
};

export const transformPropKey = (val) => {
  // Handle a few odd kebab case parameters
  if (['file_extension', 'file_size'].includes(val)) return val.replace('_', '-');

  // Handle a few odd snake case parameters
  if (['is_webview', 'user_agent'].includes(val)) return val;

  // Switch all snake_case to Title Case
  return val
    .split('_')
    .map((word) => {
      if (word === 'cta') return 'CTA';
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join(' ');
};

export const transformPropCasing = (properties) => {
  // Segment prefers "snake_case" while Mixpanel prefers "Title Case"
  // Add "Title Case" properties to help maintain backwards compatibility
  // eslint-disable-next-line prefer-const
  let newProps = { ...properties };
  Object.keys(properties).forEach((key) => {
    newProps[transformPropKey(key)] = properties[key];
  });
  return newProps;
};

// NOTE: This list is for maintaining the old event names until this Mixpanel integration is removed
// New events SHOULD NOT be added to this list
export const legacyEventNames = {
  // Shared Events
  // *********************************************

  [e.errorDisplayed]: errorDisplayed,
  [e.ctaClicked]: ctaClicked,

  // User management
  [e.authentication.started]: authentication,
  [e.authentication.completed]: authentication,
  [e.firebaseRegistration.started]: registration,
  [e.firebaseRegistration.completed]: registration,
  [e.signedOut]: 'Logged Out',

  // Talent Hub
  // *********************************************

  // Your Members actions
  [e.viewedYourMembers]: 'Viewed Page - Your Members',
  [e.navigatedToDayforce]: 'Navigated to Dayforce',

  // Role actions
  [e.viewedRole]: 'Viewed Page - Role',
  [e.viewedRoleTab]: 'Viewed Role Tab',
  [e.clickedCreateNewRole]: 'Clicked Create New Role',

  // Candidate Actions
  [e.viewedCandidateProfile]: 'Viewed Candidate Profile',
  [e.requestedCandidateResume]: 'Requested Candidate Resume',
  [e.startedSubmissionAdvance]: 'Started Profile Submission - Advance',
  [e.startedSubmissionReject]: 'Started Profile Submission - Reject',
  [e.completedSubmissionAdvance]: 'Completed Profile Submission - Advance',
  [e.completedSubmissionReject]: 'Completed Profile Submission - Reject',
  [e.cancelledSubmission]: 'Cancelled Profile Submission',
  [e.dismissedModal]: 'Closed Modal',

  // Terminal Directory actions
  [e.viewedTerminalDirectory]: 'Viewed Page - Terminal Directory',

  // Change Organization
  [e.viewedChangeOrganization]: 'Viewed Page - Change Organization',

  // Candidate actions
  [e.assessment.viewed]: 'Technical Assessment - Viewed',
  [e.assessment.clicked]: 'Technical Assessment - Clicked to Begin',
  [e.assessment.invitationCreated]: 'Technical Assessment - Generated New Invitation',
  [e.assessment.redirected]: 'Technical Assessment - Attempted Redirect to HackerRank',
  [e.assessment.completed]: 'Technical Assessment - Completed Test',

  [e.jobApplicant.formSubmitted]: 'Job Applicant - Submitted Personal Info Form',
  [e.jobApplicant.waitlisted]: 'Job Applicant - Submitted to Waitlist',
  [e.jobApplicant.completed]: 'Application Completed',

  [e.resume.attach.start]: 'Resume - Attach - Attempt',
  [e.resume.parse.start]: 'Resume - Parse - Start',
  [e.resume.parse.success]: 'Resume - Parse - Success',
};

let hasInitialized = false;

const initialize = async (meta) => {
  hasInitialized = true;
  const token = meta.mixpanelToken || 'notset';
  let config = {};

  if (
    import.meta.env.REACT_APP_MIXPANEL_URL &&
    import.meta.env.REACT_APP_MIXPANEL_URL.startsWith('http')
  ) {
    config = {
      ...config,
      api_host: import.meta.env.REACT_APP_MIXPANEL_URL,
    };
  }

  try {
    await mixpanel.init(token, config);
    // console.log('Initialized Mixpanel');
  } catch (err) {
    // console.log('FAILED to initialize Mixpanel');
  }
};

const isInitialized = () => {
  return hasInitialized;
};

const identify = async (id) => {
  try {
    const prefix = import.meta.env.REACT_APP_ENVIRONMENT;
    await mixpanel.identify(`${prefix}-${id}`);
    // console.log('Identified user', id);
  } catch (err) {
    // console.log('FAILED to identify user', id);
  }
};

const track = async (name, properties) => {
  try {
    const legacyEvent = legacyEventNames[name];

    if (legacyEvent instanceof Function) {
      await legacyEvent(name, properties);
      return;
    }

    const mixpanelName = legacyEvent || name;
    const mixpanelProperties = mixpanelName !== name ? transformPropCasing(properties) : properties;

    if (properties) {
      await mixpanel.track(mixpanelName, mixpanelProperties);
    } else {
      await mixpanel.track(mixpanelName);
    }
    // console.log('mixpanel events: ', mixpanelName, properties);
  } catch (err) {
    // console.log('FAILED to track event: ', mixpanelName);
  }
};

const trackPage = async (/* category, name, properties */) => {
  try {
    await track('Viewed Page', {
      Page: document.location.pathname,
      Title: document.title,
    });
  } catch (err) {
    // console.log('FAILED to track page');
  }
};

const setUserProperties = async (name, email) => {
  try {
    await mixpanel.people.set({
      $name: name,
      $email: email,
    });
    // console.log('Set name:', name);
    // console.log('Set email:', email);
  } catch (err) {
    // console.log('FAILED to set user properties');
  }
};

const reset = async () => {
  try {
    await mixpanel.reset();
    // console.log('Mixpanel reset');
  } catch (err) {
    // console.log('FAILED to reset');
  }
};

export default {
  name: 'mixpanel',
  initialize,
  isInitialized,
  identify,
  track,
  trackPage,
  setUserProperties,
  reset,
};
