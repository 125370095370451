import { pathOr, uniqBy } from 'ramda';

import { compareByObjectsDateAsc } from 'global/utils/date';
import {
  createCandidatesStageGraph,
  calculateRoleAggregatesByStageGraphs,
  mergePostingsAndPositions,
  hasCandidateHitStage,
} from 'talent-hub/utils';
import { CandidateInterviewSubStageName } from 'talent-hub/constants';

import type { AllStagesAllBuckets } from 'talent-hub/utils';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';
import {
  candidateSearchDateRange,
  createExploreCandidatesURL,
  to_querystring_of_ai_matching_args,
} from 'talent-hub/shared/features';
import type { SelectAiCandidatesMatchingJobMutationVariables } from 'talent-hub/role/client/new.dashboard/dashboard-roles/data';
import type { RoleDataQuery, RolePosting } from './data';
import { transformToCandidatesWithStageInfo } from './utils';
import type { RoleApplicantWorkflow, ProfileInfo, CandidateWithStagingInfo } from './Role.types';
import { serializeToRoleMilestones } from './subfeatures/progress';
import { serializeForWeeklySummaries } from './subfeatures/weekly-summaries';

export function cleanedStatusStageName(status: string) {
  if (status.startsWith('Rejected - Client') || status.startsWith('Rejected - Terminal')) {
    return 'Rejected';
  }
  switch (status) {
    case 'New Applicant - External Portal':
    case 'New Applicant - Sourced':
    case 'Sourced':
      return 'New Applicant';
    case 'Incomplete - Incomplete':
      return 'Incomplete';
    case 'Terminal Screen - Terminal Review':
    case 'Terminal Screen - Terminal Screen to be Scheduled':
    case 'Terminal Screen - Terminal Screen Scheduled':
    case 'Terminal Screen - Terminal Feedback Pending':
      return 'Terminal Screen Started';
    case 'Terminal Screen - Terminal Screen Completed':
      return 'Terminal Screen Completed';
    case 'Internal Interview - Interview to be Scheduled':
    case 'Internal Interview - Interview Scheduled':
    case 'Internal Interview - Reference Check Completed':
    case 'Internal Interview - Interview Completed':
      return 'Internal Interview';
    case 'Submitted to Review - Submitted to Review':
      return 'Submitted to Talent Partner';
    case 'Submitted to Client - Submitted':
      return 'Pending Review';
    case 'Submitted to Client - Client Portal Advance':
      return 'Submission Advanced';
    case 'Rejected - Client Rejected - Client Portal Rejected':
      return 'Submission Rejected';
    case 'Client Review - Interview to be Scheduled':
      return 'Interview Scheduling';
    case 'Client Review - Interview Scheduled':
    case 'Client Review - Interview #1 - Initial Interview':
    case 'Client Review - Interview #2 - Technical Interview / Assessment':
    case 'Client Review - Interview #3 - Final interview':
    case 'Client Review - Interview #4 - Additional Interview':
      return 'Interview Scheduled';
    case 'Client Review - Interview Completed':
      return 'Interview Completed';
    case 'Client Review - Awaiting Client Feedback':
    case 'Client Review - Final Interview Completed':
      return 'Awaiting Interview Feedback';
    case 'Client Review - Reference Check Initiated':
      return 'Reference Check Initiated';
    case 'Client Review - Reference Check Completed':
      return 'Reference Check Completed';
    case 'Offer - New Offer':
      return 'Offer Started';
    case 'Offer - Background Check Initiated':
      return 'Offer: Background Check Initiated';
    case 'Offer - Background Check Completed':
      return 'Offer: Background Check Completed';
    case 'Offer - Offer Extended':
      return 'Offer Extended';
    case 'Offer - Offer Accepted':
      return 'Offer Accepted';
    case 'Hired - Placed':
      return 'Hired';
    case 'Candidate Withdrew - Self-Withdrew (Portal)':
      return 'Candidate Withdrew';
    case 'Rejected - Offer Declined':
    case 'Rejected - Offer Declined - Offer Declined - Accepted Another Offer':
    case 'Rejected - Offer Declined - Offer Declined - Compensation':
    case 'Rejected - Offer Declined - Offer Declined - Benefits':
    case 'Rejected - Offer Declined - Offer Declined - General':
    case 'Rejected - Offer Declined - Offer Declined - Personal Reasons':
    case 'Rejected - Offer Declined - Offer Declined - Stay With Current Employer':
      return 'Offer - Declined';
    default:
      return status;
  }
}

export function cleanedStatusColorScheme(statusStageName: string) {
  switch (statusStageName) {
    case 'Offer: Background Check Initiated':
    case 'Reference Check Initiated':
    case 'Terminal Screen Started':
    case 'Interview Scheduling':
    case 'New Applicant':
    case 'Offer Started':
      return 'primary';
    case 'Interview Scheduled':
    case 'Internal Interview':
      return 'info';
    case 'Candidate Withdrew':
    case 'Incomplete':
      return 'disabled';
    case 'Awaiting Interview Feedback':
    case 'Pending Review':
      return 'warning';
    case 'Offer Accepted':
    case 'Hired':
      return 'success';
    case 'Offer: Background Check Completed':
    case 'Terminal Screen Completed':
    case 'Reference Check Completed':
      return 'accent-lighter';
    case 'Submission Rejected':
    case 'Offer - Declined':
    case 'Rejected':
      return 'error';
    default:
      return 'accent-lightest';
  }
}

function groupCandidatesToRolesBucketsByStageGraph({
  submission,
  interview,
  offer,
  hired,
}: AllStagesAllBuckets<CandidateWithStagingInfo>): {
  pastSubmissionCandidates: ProfileInfo[];
  upcomingSubmissionCandidates: ProfileInfo[];
  pastInterviewCandidates: ProfileInfo[];
  interviewsToBeScheduledCandidates: ProfileInfo[];
  upcomingInterviewCandidates: ProfileInfo[];
  upcomingOffersCandidates: ProfileInfo[];
  pastOffersCandidates: ProfileInfo[];
  hiredCompleteCandidates: ProfileInfo[];
} {
  const pickProfileData = (candidateProfile: CandidateWithStagingInfo) => {
    const statusName =
      candidateProfile.profileInfo.statusText &&
      cleanedStatusStageName(candidateProfile.profileInfo.statusText);
    const statusColorScheme = statusName && cleanedStatusColorScheme(statusName);
    return {
      ...candidateProfile.profileInfo,
      statusName,
      statusColorScheme,
    };
  };

  const interviewsToBeScheduledCandidatesUnMapped: CandidateWithStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.ToBeScheduled] || [];

  const upcomingInterviewCandidatesUnMapped: CandidateWithStagingInfo[] =
    interview.inProgress.buckets[CandidateInterviewSubStageName.Scheduled] || [];

  return {
    pastSubmissionCandidates: [...submission.completed.all, ...submission.rejectedAt.all]
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingSubmissionCandidates: submission.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    pastInterviewCandidates: [...interview.completed.all, ...interview.rejectedAt.all]
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    interviewsToBeScheduledCandidates: interviewsToBeScheduledCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingInterviewCandidates: upcomingInterviewCandidatesUnMapped
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    upcomingOffersCandidates: offer.inProgress.all
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    pastOffersCandidates: [...offer.completed.all, ...offer.rejectedAt.all]
      .map(pickProfileData)
      .sort(compareByObjectsDateAsc),
    hiredCompleteCandidates: hired.completed.all.map(pickProfileData).sort(compareByObjectsDateAsc),
  };
}

function serializeQueryRoleData(data: RoleDataQuery) {
  const postings = mergePostingsAndPositions<RolePosting>(data ? data.postings : []);
  const role = postings.length ? postings[0] : null;

  const postingApplicantWorkflows: RoleApplicantWorkflow[] = pathOr(
    [] as RoleApplicantWorkflow[],
    ['applicant_workflows'],
    role || {},
  );

  const stagedCandidates = transformToCandidatesWithStageInfo(
    postingApplicantWorkflows.filter(
      // Removes the candidate that has any of the statuses in the Ignored bucket
      (postingApplicantWorkflow) =>
        !hasCandidateHitStage(
          'Ignored',
          postingApplicantWorkflow.workflow_statuses.map(
            workflowStatuses_ToCandidateWorkflowStatus,
          ),
        ),
    ),
  );

  const { screening, submission, interview, offer, hired } =
    createCandidatesStageGraph(stagedCandidates);

  const allRolesBuckets = groupCandidatesToRolesBucketsByStageGraph({
    screening,
    submission,
    interview,
    offer,
    hired,
  });

  const { totalCandidates, totalActiveCandidates, totalHired } =
    calculateRoleAggregatesByStageGraphs({ screening, submission, interview, offer, hired });

  const allCandidatesWithDuplicates = (
    Object.keys(allRolesBuckets) as Array<keyof typeof allRolesBuckets>
  ).reduce((accumulator: ProfileInfo[], groupKey) => {
    return [
      ...accumulator,
      ...(Array.isArray(allRolesBuckets[groupKey]) ? allRolesBuckets[groupKey] : []),
    ];
  }, []);

  const allCandidates = uniqBy(({ name }) => name, allCandidatesWithDuplicates);

  const recentCandidates = allCandidates.sort(compareByObjectsDateAsc).slice(0, 10);

  const totalHeadCounts = (role?.openHeadCount || 0) + (role?.closedHeadCount || 0);

  const weeklySummaries = serializeForWeeklySummaries({
    candidates: postingApplicantWorkflows,
    allNonScreeningCandidates: allCandidates,
  });

  return {
    ...allRolesBuckets,
    recentCandidates,
    totalCandidates,
    totalActiveCandidates,
    totalHired,
    role,
    totalHeadCounts,
    weeklySummaries,
  };
}

// TODO: rename this to activeRoleSerializer or selectedRoleSerializer?
// And move it to the subfeature/selectedRole
export function serializeRoleData(data?: RoleDataQuery) {
  const job = data?.icims_job[0]?.job;
  if (!data || !job)
    return {
      roleTitle: '',
      progress: {
        metrics: {
          totalSubmissions: null,
          totalActiveCandidates: null,
          totalPositions: null,
          totalHired: null,
        },
        milestones: {},
      },
      buckets: {
        upcomingSubmissionCandidates: [],
        pastSubmissionCandidates: [],
        interviewsToBeScheduledCandidates: [],
        upcomingInterviewCandidates: [],
        pastInterviewCandidates: [],
        upcomingOffersCandidates: [],
        pastOffersCandidates: [],
        hiredCompleteCandidates: [],
        recentCandidatesActivities: [],
      },
      weeklySummaries: [],
      ai_extra_matches: {
        candidates_ai_matching_args: null,
        must_have_skills: [],
        view_all_candidates_matches_link: '',
      },
    };

  const {
    recentCandidates,
    upcomingSubmissionCandidates,
    pastSubmissionCandidates,
    interviewsToBeScheduledCandidates,
    upcomingInterviewCandidates,
    pastInterviewCandidates,
    upcomingOffersCandidates,
    pastOffersCandidates,
    hiredCompleteCandidates,
    totalCandidates,
    totalActiveCandidates,
    totalHired,
    role,
    totalHeadCounts,
    weeklySummaries,
  } = serializeQueryRoleData(data);

  const { milestone1, milestone2, milestone3, milestone4 } = serializeToRoleMilestones({
    data,
    roleOpenedDate: role?.openedDate,
    tp_kickoff_date: role?.job?.tp_kickoff_date,
  });

  const exploreCandidateArgs_countries = job?.job_acceptable_locations
    ?.map(({ location: { country } }) => country)
    .filter((country) => country !== null);

  const candidates_ai_matching_args: SelectAiCandidatesMatchingJobMutationVariables = {
    jobId: job?.id,
    queryString: to_querystring_of_ai_matching_args({
      title: job?.title,
      description: job?.about,
      job_required_skills: job?.job_required_skills.flatMap(({ skill }) => skill.name) || [],
      job_tech_stack: job?.job_tech_stack.flatMap(({ skill }) => skill.name) || [],
      what_youll_do: job?.what_youll_do,
      what_youll_bring: job?.what_youll_bring,
    }),
    countries: exploreCandidateArgs_countries || [],
    employmentType: job?.employment_type || undefined,
    latestLastCurationWorkflowsUpdate: candidateSearchDateRange,
  };

  return {
    roleTitle: (role && role.name) || '',
    progress: {
      metrics: {
        totalSubmissions: totalCandidates,
        totalActiveCandidates,
        totalPositions: totalHeadCounts,
        totalHired,
      },
      milestones: {
        milestone1,
        milestone2,
        milestone3,
        milestone4,
      },
    },
    buckets: {
      upcomingSubmissionCandidates,
      pastSubmissionCandidates,
      interviewsToBeScheduledCandidates,
      upcomingInterviewCandidates,
      pastInterviewCandidates,
      upcomingOffersCandidates,
      pastOffersCandidates,
      hiredCompleteCandidates,
      recentCandidatesActivities: recentCandidates,
    },
    weeklySummaries,
    ai_extra_matches: {
      candidates_ai_matching_args,
      must_have_skills: job?.job_required_skills?.map(({ skill }) => skill.name) || [],
      view_all_candidates_matches_link: createExploreCandidatesURL({
        countries: exploreCandidateArgs_countries?.length ? exploreCandidateArgs_countries : null,
        employment_type: job?.employment_type || null,
        candidate_role: job?.role_type || null,
        sort_option: '',
        must_have_skill_ids: [],
        regions: null,
        badges: null,
        should_have_skill_ids: [],
        min_years_of_experience: null,
      }),
    },
  };
}
