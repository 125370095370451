import { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import type { Location } from 'history';

import { BlankScreenLoading } from 'global/components/BlankScreenLoading';
import { CustomRoute } from 'global/components/router';

import { firebaseAuth } from 'global/firebaseApp';
import { signOut, googleSignIn } from './authMethods';
import type { UseAuth } from './useAuth';
import { AuthErrorFallback } from './components';
import { SignUpController } from './Signup';
import { SignInController } from './SignIn';
import { ForgotPasswordController } from './forgot-password';
import { PasswordResetController } from './password-reset';
import { EmailNotVerifiedController } from './email-not-verified';
import { VerifyEmailController } from './verify-email';
import type { AuthenticationRouterOverwrite, AuthRedirectTo } from './types';
import { NoAccessPage } from './no-access';
import { IncompatibleBrowserErrorPage } from './incompatible-browser/IncompatibleBrowserErrorPage';

type Authorizable = { isAuthorized: boolean };

// TODO (CAND-536): Fix after signing user is not redirected back to where they had landed.

export function AuthenticatedRoute({
  redirectPath,
  children,
  isAuthenticated,
  ...rest
}: {
  redirectPath: string;
  children: JSX.Element;
  isAuthenticated: boolean;
  path: string;
}) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
              search: location.search,
            }}
          />
        )
      }
    />
  );
}

export function AuthenticationRouter({
  error,
  isAuthenticated,
  isEmailVerified,
  isAuthorized,
  isResolved,
  isError,
  isReady,
  friendlyErrorMessage,
  skipEmailVerify,
  onEmailVerificationComplete,
  overwrite = {},
  dispatch,
}: {
  onEmailVerificationComplete?: (firebaseUID: string) => Promise<string | null>;
  skipEmailVerify: boolean;
  overwrite?: AuthenticationRouterOverwrite;
} & UseAuth &
  Authorizable) {
  const history = useHistory();
  const location = useLocation<{
    from: {
      pathname: string;
      search: string;
      hash: string;
      url?: string;
      state?: { from: Location<unknown> };
    }; // TODO: rename this to to
    email?: string;
    firstName?: string;
    lastName?: string;
    to?: { pathname: string; search: string; hash: string; url?: string };
  }>();
  const { path, url } = useRouteMatch();
  const queryString = new URLSearchParams(useLocation().search);
  const mode = queryString.get('mode'); // firebase email action mode
  const fromStorage_pathname = localStorage.getItem('from_pathname');
  const { from } = location.state || {
    from: { pathname: fromStorage_pathname || '/', search: location.search },
  };

  useEffect(() => {
    if (!from?.state?.from?.pathname) return;

    localStorage.setItem('from_pathname', from?.state?.from?.pathname);
  }, [from?.state?.from?.pathname]);

  const redirectTo: AuthRedirectTo = (
    redirectPath: string,
    options: { passQuerystringAlong?: boolean; overwrite?: boolean } = {
      passQuerystringAlong: false,
      overwrite: false,
    },
  ): void => {
    const { passQuerystringAlong, overwrite: redirectToOverwrite } = options;
    const redirectFunc = redirectToOverwrite ? history.replace : history.push;

    // TODO: document what redirectPath === 'from-with-refresh' means
    if (redirectPath === 'from-with-refresh') {
      const queryContinueUrl = queryString.get('continueUrl');
      window.location.href = queryContinueUrl || from.pathname;
      return;
    }

    // TODO: document what redirectPath === 'from' means
    if (redirectPath === 'from') {
      const queryContinueUrl = queryString.get('continueUrl');
      redirectFunc(queryContinueUrl || from);
      return;
    }

    // @ts-ignore the code handles the cases where redirectPath is not inside the overwrite.route
    if (overwrite.route?.[redirectPath]) {
      // @ts-ignore the code handles the cases where redirectPath is not insider he overwrite.route
      redirectFunc(overwrite.route[redirectPath], { from });
      return;
    }

    if (passQuerystringAlong) {
      redirectFunc(`${url}/${redirectPath}?${queryString}`, { from });
    } else {
      redirectFunc(`${url}/${redirectPath}`, { from });
    }
  };

  useEffect(() => {
    if (error?.code === 'auth/operation-not-supported-in-this-environment') {
      redirectTo('incompatible-browser', { passQuerystringAlong: true });
    }
    // In order to put redirectTo in dependency array it needs to be wrapper inside useCallback
    // which seems to be a something we need to do.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.code]);

  useEffect(() => {
    // TODO (TP-1981): improve this logic and remove the dependencies of if blocks on each other or at least reduce that
    if (isReady && mode === 'resetPassword') {
      redirectTo('reset-password', { passQuerystringAlong: true });
      return;
    }
    if (isResolved && isAuthenticated) {
      if (mode === 'verifyEmail') {
        if (!location.pathname.includes('verify-email')) {
          redirectTo('verify-email', { passQuerystringAlong: true });
        }
        return;
      }

      // The controller signed anonymous user out. So we need to make sure no redirection occurs before the
      // that sign out resolves.
      if (firebaseAuth?.currentUser?.isAnonymous) return;

      if (isAuthorized) {
        localStorage.removeItem('from_pathname');
        history.replace(from);
      } else if (!['/auth/signup'].includes(location.pathname)) {
        if (isEmailVerified === false && !skipEmailVerify) {
          redirectTo('email-not-verified', { passQuerystringAlong: true, overwrite: true });
        } else {
          redirectTo('no-access');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    isResolved,
    isAuthorized,
    isAuthenticated,
    isEmailVerified,
    location.pathname,
    isReady,
  ]);

  if (!isResolved && !isError) {
    return <BlankScreenLoading />;
  }

  return (
    <ErrorBoundary fallback={<AuthErrorFallback />}>
      <Switch>
        <Route path={`${path}/login`}>
          <SignInController
            handleGoogleSignIn={() =>
              googleSignIn({ dispatch, auth: firebaseAuth }, { forceWithoutLinking: true })
            }
            friendlyErrorMessage={friendlyErrorMessage}
            redirectTo={redirectTo}
            overwrite={overwrite}
          />
        </Route>
        <Route path={`${path}/signup`}>
          <SignUpController
            redirectTo={redirectTo}
            handleGoogleSignUp={() => googleSignIn({ dispatch, auth: firebaseAuth })}
            pageTemplateOverwrite={
              overwrite?.pageTemplate?.signup ?? overwrite?.pageTemplate?.default
            }
          />
        </Route>
        <CustomRoute
          path={`${path}/incompatible-browser`}
          conditionalRedirects={[
            {
              condition: error?.code !== 'auth/operation-not-supported-in-this-environment',
              redirectURL: '/',
              search: location.search,
            },
          ]}
        >
          <IncompatibleBrowserErrorPage
            pageTemplateOverwrite={
              overwrite?.pageTemplate?.['incompatible-browser'] ?? overwrite?.pageTemplate?.default
            }
          />
        </CustomRoute>
        <Route path={`${path}/forgot-password`}>
          <ForgotPasswordController redirectTo={redirectTo} overwrite={overwrite} />
        </Route>
        <Route path={`${path}/reset-password`}>
          <PasswordResetController redirectTo={redirectTo} overwrite={overwrite} />
        </Route>
        <AuthenticatedRoute
          path={`${path}/no-access`}
          isAuthenticated={isAuthenticated}
          redirectPath={`${path}/login`}
        >
          <NoAccessPage
            onSignOutClick={() => signOut({ auth: firebaseAuth })}
            pageTemplateOverwrite={
              overwrite?.pageTemplate?.['no-access'] || overwrite?.pageTemplate?.default
            }
          />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${path}/email-not-verified`}
          isAuthenticated={isAuthenticated}
          redirectPath={`${path}/login`}
        >
          <EmailNotVerifiedController overwrite={overwrite} />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          path={`${path}/verify-email`}
          isAuthenticated={isAuthenticated}
          redirectPath={`${path}/login`}
        >
          <VerifyEmailController
            redirectTo={redirectTo}
            onEmailVerificationComplete={onEmailVerificationComplete}
            overwrite={overwrite}
          />
        </AuthenticatedRoute>
        <Route path={`${path}/logging-in`}>
          <BlankScreenLoading />
        </Route>
        <Route
          render={() =>
            mode ? (
              <BlankScreenLoading />
            ) : (
              <Redirect
                to={{
                  pathname: `${path}/login`,
                  state: { from },
                }}
              />
            )
          }
        />
      </Switch>
    </ErrorBoundary>
  );
}
