import { Text, CheckCircleIcon } from '@terminal/design-system';
import type { AuthPageTemplateProps } from '../types';
import { AuthMessage, AuthPageTemplate } from '../components';

export function ForgotPasswordSuccessPage({
  onTryAgainClick,
  pageTemplateOverwrite,
}: {
  onTryAgainClick: () => void;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate
      footerLinkOnClick={onTryAgainClick}
      footerText="Haven’t received the link yet?"
      footerLinkLabel="Try Again"
    >
      <AuthMessage icon={<CheckCircleIcon color="text.success" />} title="Reset Link Sent!">
        <Text>
          A reset link was sent to the email provided. Follow the instructions in the email to reset
          your password.
        </Text>
      </AuthMessage>
    </PageTemplate>
  );
}
