import type { BoxProps } from '@terminal/design-system';

import Sentry from 'global/sentry';
import { Logo } from 'global/components/Logo';
import {
  Box,
  Button,
  Flex,
  MenuIcon,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  forwardRef,
  DashboardIcon,
  JobsIcon,
  UserGroupIcon,
  SearchUserIcon,
  MembersIcon,
  WarningCircleIcon,
  DirectoryIcon,
  RefreshIcon,
  EditIcon,
  Grid,
  GridItem,
} from '@terminal/design-system';
import { useRedirection, useIsViewPortDesktop } from 'global/utils';
import type { useCustomerAuth } from 'talent-hub/utils';
import { useTalentHubFlags } from 'talent-hub/utils/flags';

import { ErrorFallback } from '../ErrorFallback';
import { TopBarHeader } from '../TopBarHeader/TopBarHeader';
import { SidebarContent } from '../SidebarContent';
import { HamburgerMenu } from '../HamburgerMenu';

export function PageLayoutBaseLayout({
  children,
  mobile_side_menu,
  desktop_side_menu,
  top_bar,
  disableMaxWidth = false, // TODO: can this be removed
  has_sub_header = false,
}: {
  children: React.ReactNode;
  mobile_side_menu: React.ReactNode;
  desktop_side_menu: React.ReactNode;
  top_bar: React.ReactNode;
  disableMaxWidth?: boolean;
  has_sub_header?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useIsViewPortDesktop();
  const redirectTo = useRedirection();

  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            redirectTo('/');
            window.location.reload();
          }}
          actionTitle="Go back to Home Page"
        />
      }
    >
      <Box minH="100vh">
        <Flex
          visibility={['visible', 'visible', 'hidden']}
          bg="bg.primary"
          h={16}
          alignItems="center"
          justifyContent="space-between"
          as="nav"
          role="navigation"
          position="fixed"
          w="full"
          px={3}
          py={1}
          shadow="sm"
          zIndex="sticky"
        >
          <Logo width="100px" />
          <Button
            _hover={{ bg: 'inherit', opacity: 0.8 }}
            variant="ghost"
            onClick={onOpen}
            w={12}
            h={12}
          >
            <MenuIcon color="brand.main" fontSize="2xl" />
          </Button>
        </Flex>
        <Box
          as="header"
          h={['initial', 'initial', 'inherit']}
          pl={[0, 0, 20]}
          pt={[16, 16, 0]}
          pos={['static', 'static', 'fixed']}
          role="banner"
          top={0}
          w="full"
          zIndex="sticky"
        >
          {top_bar}
        </Box>
        <Drawer isOpen={!isDesktop && isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton color="ui.primary" fontSize="md" left={0} top={0} zIndex="sticky" />
            <DrawerBody p={0}>{mobile_side_menu}</DrawerBody>
          </DrawerContent>
        </Drawer>
        <Box
          display={['none', 'none', 'block']}
          h="full"
          pos="fixed"
          top={0}
          transition="3s ease"
          w={20}
          zIndex="sticky"
        >
          {desktop_side_menu}
        </Box>
        <Box
          as="main"
          flexDirection="column"
          maxW={disableMaxWidth ? 'full' : ['full', 'full', 'container.lg', 'container.xl']}
          mx="auto"
          pl={[0, 0, 20]}
          pt={[0, 0, has_sub_header ? 22 : 20]}
          role="main"
          w="full"
        >
          <Sentry.ErrorBoundary
            fallback={
              <ErrorFallback
                onActionClick={() => {
                  window.location.reload();
                }}
                useFullHeight={false}
                showBackButton
              />
            }
          >
            {children}
          </Sentry.ErrorBoundary>
        </Box>
      </Box>
    </Sentry.ErrorBoundary>
  );
}

export function PageLayoutClient({
  children,
  headerTitle,
  orgName,
  subHeader,
  superTitle,
  user_display_name,
  first_name,
  disableMaxWidth = false,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  user_display_name: string; // user.name
  first_name: string;
  disableMaxWidth?: boolean;
}) {
  return (
    <PageLayoutBaseLayout
      has_sub_header={!!subHeader}
      desktop_side_menu={<SidebarContent isClientReviewer={false} isClientProspect={false} />}
      mobile_side_menu={
        <HamburgerMenu userName={first_name || ''} companyName={orgName}>
          <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
          <HamburgerMenu.NavLink to="/role" icon={<JobsIcon />} label="Manage Roles" />
          <HamburgerMenu.NavLink
            to="/candidates"
            icon={<UserGroupIcon />}
            label="Manage Candidates"
          />
          <HamburgerMenu.NavLink to="/members" icon={<MembersIcon />} label="Your Members" />
          <HamburgerMenu.NavLink
            to={{
              pathname: `/explore-candidates`,
              state: { scrollTop: true },
            }}
            icon={<SearchUserIcon />}
            label="Explore Candidates"
          />
          <HamburgerMenu.NavLink
            to="/directory"
            isStrongIcon
            icon={<DirectoryIcon />}
            label="Terminal Directory"
          />
        </HamburgerMenu>
      }
      top_bar={
        <TopBarHeader
          orgName={orgName}
          superTitle={superTitle}
          title={headerTitle}
          subHeader={subHeader}
          badgeCopy=""
          userName={user_display_name || ''}
          userFirstName={first_name || ''}
          isRecruiter={false}
          isClientProspect={false}
          isClientReviewer={false}
          isCallScheduled={false}
        />
      }
      disableMaxWidth={disableMaxWidth}
    >
      {children}
    </PageLayoutBaseLayout>
  );
}

export function PageLayoutRecruiter({
  children,
  headerTitle,
  orgName,
  subHeader,
  superTitle,
  user_display_name,
  first_name,
  disableMaxWidth = false,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  user_display_name: string; // user.name
  first_name: string;
  disableMaxWidth?: boolean;
}) {
  const featureFlags = useTalentHubFlags();

  return (
    <PageLayoutBaseLayout
      has_sub_header={!!subHeader}
      desktop_side_menu={<SidebarContent isClientReviewer={false} isClientProspect={false} />}
      mobile_side_menu={
        <HamburgerMenu userName={first_name || ''} companyName={orgName}>
          <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
          <HamburgerMenu.NavLink to="/role" icon={<JobsIcon />} label="Manage Roles" />
          <HamburgerMenu.NavLink
            to="/candidates"
            icon={<UserGroupIcon />}
            label="Manage Candidates"
          />
          <HamburgerMenu.NavLink to="/members" icon={<MembersIcon />} label="Your Members" />
          <HamburgerMenu.NavLink
            to={{
              pathname: `/explore-candidates`,
              state: { scrollTop: true },
            }}
            icon={<SearchUserIcon />}
            label="Explore Candidates"
          />
          <HamburgerMenu.NavLink
            to="/directory"
            isStrongIcon
            icon={<DirectoryIcon />}
            label="Terminal Directory"
          />
          <HamburgerMenu.NavLink
            to="/change-organization"
            icon={<RefreshIcon />}
            label="Change Organization"
          />
          {featureFlags.showRetoolEditOrganization && (
            <HamburgerMenu.NavLink
              to="/edit-organization"
              icon={<EditIcon />}
              label="Edit Organization"
            />
          )}
        </HamburgerMenu>
      }
      top_bar={
        <TopBarHeader
          orgName={orgName}
          superTitle={superTitle}
          title={headerTitle}
          subHeader={subHeader}
          badgeCopy=""
          userName={user_display_name || ''}
          userFirstName={first_name || ''}
          isRecruiter
          isClientProspect={false}
          isClientReviewer={false}
          isCallScheduled={false}
        />
      }
      disableMaxWidth={disableMaxWidth}
    >
      {children}
    </PageLayoutBaseLayout>
  );
}

export function PageLayoutClientReviewer({
  children,
  headerTitle,
  orgName,
  subHeader,
  superTitle,
  user_display_name,
  first_name,
  disableMaxWidth = false,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  user_display_name: string; // user.name
  first_name: string;
  disableMaxWidth?: boolean;
}) {
  return (
    <PageLayoutBaseLayout
      has_sub_header={!!subHeader}
      desktop_side_menu={<SidebarContent isClientReviewer isClientProspect={false} />}
      mobile_side_menu={
        <HamburgerMenu userName={first_name || ''} companyName={orgName}>
          <HamburgerMenu.NavLink
            to="/candidates"
            icon={<UserGroupIcon />}
            label="Manage Candidates"
          />
        </HamburgerMenu>
      }
      top_bar={
        <TopBarHeader
          orgName={orgName}
          superTitle={superTitle}
          title={headerTitle}
          subHeader={subHeader}
          badgeCopy=""
          userName={user_display_name || ''}
          userFirstName={first_name || ''}
          isRecruiter={false}
          isClientProspect={false}
          isClientReviewer
          isCallScheduled={false}
        />
      }
      disableMaxWidth={disableMaxWidth}
    >
      {children}
    </PageLayoutBaseLayout>
  );
}

export function PageLayoutClientProspect({
  children,
  headerTitle,
  orgName,
  subHeader,
  superTitle,
  user_display_name,
  first_name,
  disableMaxWidth = false,
  isCallScheduled,
  onUpgradeAccountClick,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  user_display_name: string; // user.name
  first_name: string;
  disableMaxWidth?: boolean;
  isCallScheduled: boolean;
  onUpgradeAccountClick?: () => void;
}) {
  return (
    <PageLayoutBaseLayout
      has_sub_header={!!subHeader}
      desktop_side_menu={<SidebarContent isClientReviewer={false} isClientProspect />}
      mobile_side_menu={
        <HamburgerMenu userName={first_name || ''} companyName={orgName} badgeCopy="Free Trial">
          {isCallScheduled ? (
            <HamburgerMenu.Item
              bgColor="ui.lighter.warning"
              pointerEvents="none"
              icon={<WarningCircleIcon />}
              label="Meeting Scheduled"
            />
          ) : (
            <HamburgerMenu.Button
              bgColor="ui.lighter.warning"
              onClick={onUpgradeAccountClick}
              pointerEvents={isCallScheduled ? 'none' : 'auto'}
              icon={<WarningCircleIcon />}
              label="Upgrade Your Account"
            />
          )}
          <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
          <HamburgerMenu.NavLink
            to={{
              pathname: `/explore-candidates`,
              state: { scrollTop: true },
            }}
            icon={<SearchUserIcon />}
            label="Explore Candidates"
          />
          <HamburgerMenu.NavLink to="/role" isDisabled icon={<JobsIcon />} label="Manage Roles" />
          <HamburgerMenu.NavLink
            to="/candidates"
            isDisabled
            icon={<UserGroupIcon />}
            label="Manage Candidates"
          />
          <HamburgerMenu.NavLink
            to="/members"
            isDisabled
            icon={<MembersIcon />}
            label="Your Members"
          />
        </HamburgerMenu>
      }
      top_bar={
        <TopBarHeader
          orgName={orgName}
          superTitle={superTitle}
          title={headerTitle}
          subHeader={subHeader}
          badgeCopy=""
          userName={user_display_name || ''}
          userFirstName={first_name || ''}
          isRecruiter={false}
          isClientProspect
          isClientReviewer={false}
          isCallScheduled={isCallScheduled}
        />
      }
      disableMaxWidth={disableMaxWidth}
    >
      {children}
    </PageLayoutBaseLayout>
  );
}

export function PageLayout({
  children,
  headerTitle,
  // TODO: the next 4 props should be removed
  isRecruiter = false,
  isClientReviewer = false,
  isClientProspect = false,
  disableMaxWidth = false,
  // TODO: this should be removed and get added to al storybook stories that are using this components
  userPrioritizedRole = 'client',
  orgName,
  subHeader,
  superTitle,
  user,
  isCallScheduled = false,
  onUpgradeAccountClick,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  isRecruiter?: boolean;
  isClientReviewer?: boolean;
  isClientProspect?: boolean;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  // TODO: this should change to user_name and user_first_name
  user: Pick<NonNullable<ReturnType<typeof useCustomerAuth>['user']>, 'first_name' | 'name'>;
  // TODO: Improve this behavior
  disableMaxWidth?: boolean;
  isCallScheduled?: boolean;
  userPrioritizedRole: ReturnType<typeof useCustomerAuth>['userPrioritizedRole'];
  onUpgradeAccountClick?: () => void;
}) {
  const fake_flag = false;
  // This is temporary short cut to avoid eslint error for these unused variables
  if (fake_flag) {
    // eslint-disable-next-line no-console
    console.log(isRecruiter, isClientReviewer, isClientProspect, disableMaxWidth);
  }

  if (userPrioritizedRole === 'client') {
    return (
      <PageLayoutClient
        children={children}
        headerTitle={headerTitle}
        orgName={orgName}
        subHeader={subHeader}
        superTitle={superTitle}
        user_display_name={user.name || ''}
        first_name={user.first_name || ''}
        disableMaxWidth={disableMaxWidth}
      />
    );
  }

  if (userPrioritizedRole === 'recruiter') {
    return (
      <PageLayoutRecruiter
        children={children}
        headerTitle={headerTitle}
        orgName={orgName}
        subHeader={subHeader}
        superTitle={superTitle}
        user_display_name={user.name || ''}
        first_name={user.first_name || ''}
        disableMaxWidth={disableMaxWidth}
      />
    );
  }

  if (userPrioritizedRole === 'client-reviewer') {
    return (
      <PageLayoutClientReviewer
        children={children}
        headerTitle={headerTitle}
        orgName={orgName}
        subHeader={subHeader}
        superTitle={superTitle}
        user_display_name={user.name || ''}
        first_name={user.first_name || ''}
        disableMaxWidth={disableMaxWidth}
      />
    );
  }

  if (userPrioritizedRole === 'client-prospect') {
    return (
      <PageLayoutClientProspect
        children={children}
        headerTitle={headerTitle}
        orgName={orgName}
        subHeader={subHeader}
        superTitle={superTitle}
        user_display_name={user.name || ''}
        first_name={user.first_name || ''}
        disableMaxWidth={disableMaxWidth}
        isCallScheduled={!!isCallScheduled}
        onUpgradeAccountClick={onUpgradeAccountClick}
      />
    );
  }

  return null;
}

export function PageLayoutSimpleHeader({
  children,
  headerTitle,
  isRecruiter,
  isClientReviewer = false,
  isClientProspect = false,
  userPrioritizedRole,
  orgName,
  subHeader,
  superTitle,
  user,
  // TODO: Improve this behavior
  disableMaxWidth,
  isCallScheduled = false,
  onUpgradeAccountClick,
}: {
  children?: React.ReactNode;
  headerTitle?: string | React.ReactNode;
  isRecruiter: boolean;
  isClientReviewer?: boolean;
  isClientProspect?: boolean;
  orgName: string;
  subHeader?: React.ReactNode;
  superTitle?: string | React.ReactNode;
  // TODO: this should change to user_name and user_first_name
  user: Pick<NonNullable<ReturnType<typeof useCustomerAuth>['user']>, 'first_name' | 'name'>;
  disableMaxWidth?: boolean;
  isCallScheduled?: boolean;
  userPrioritizedRole: ReturnType<typeof useCustomerAuth>['userPrioritizedRole'];
  onUpgradeAccountClick?: () => void;
}) {
  const featureFlags = useTalentHubFlags();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDesktop = useIsViewPortDesktop();
  const redirectTo = useRedirection();

  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            redirectTo('/');
            window.location.reload();
          }}
          actionTitle="Go back to Home Page"
        />
      }
    >
      <Box minH="100vh">
        <Flex
          visibility={['visible', 'visible', 'hidden']}
          bg="bg.primary"
          h={16}
          alignItems="center"
          justifyContent="space-between"
          as="nav"
          role="navigation"
          position="fixed"
          w="full"
          px={3}
          py={1}
          shadow="sm"
          zIndex="sticky"
        >
          <Logo width="100px" />
          <Button
            _hover={{ bg: 'inherit', opacity: 0.8 }}
            variant="ghost"
            onClick={onOpen}
            w={12}
            h={12}
          >
            <MenuIcon color="brand.main" fontSize="2xl" />
          </Button>
        </Flex>
        <Box
          as="header"
          h={['initial', 'initial', 'inherit']}
          pl={[0, 0, 20]}
          pt={[16, 16, 0]}
          pos={['static', 'static', 'fixed']}
          role="banner"
          top={0}
          w="full"
          zIndex="sticky"
        >
          {/* TODO: [GROW-348] TopBarHeader and SidebarContent inversion of control */}
          <TopBarHeader
            orgName={isClientProspect ? orgName.replace(/trial/gi, '') : orgName}
            superTitle={superTitle}
            title={headerTitle}
            subHeader={subHeader}
            badgeCopy={isClientProspect ? 'Free Trial' : ''}
            userName={user.name || ''}
            userFirstName={user.first_name || ''}
            isRecruiter={isRecruiter}
            isClientProspect={isClientProspect}
            isClientReviewer={isClientReviewer}
            onUpgradeAccountClick={onUpgradeAccountClick}
            isCallScheduled={isCallScheduled}
          />
        </Box>
        <Drawer isOpen={!isDesktop && isOpen} placement="right" onClose={onClose} size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton color="ui.primary" fontSize="md" left={0} top={0} zIndex="sticky" />
            <DrawerBody p={0}>
              {userPrioritizedRole === 'client' && (
                <HamburgerMenu userName={user.first_name || ''} companyName={orgName}>
                  <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
                  <HamburgerMenu.NavLink to="/role" icon={<JobsIcon />} label="Manage Roles" />
                  <HamburgerMenu.NavLink
                    to="/candidates"
                    icon={<UserGroupIcon />}
                    label="Manage Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/members"
                    icon={<MembersIcon />}
                    label="Your Members"
                  />
                  <HamburgerMenu.NavLink
                    to={{
                      pathname: `/explore-candidates`,
                      state: { scrollTop: true },
                    }}
                    icon={<SearchUserIcon />}
                    label="Explore Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/directory"
                    isStrongIcon
                    icon={<DirectoryIcon />}
                    label="Terminal Directory"
                  />
                </HamburgerMenu>
              )}
              {userPrioritizedRole === 'recruiter' && (
                <HamburgerMenu userName={user.first_name || ''} companyName={orgName}>
                  <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
                  <HamburgerMenu.NavLink to="/role" icon={<JobsIcon />} label="Manage Roles" />
                  <HamburgerMenu.NavLink
                    to="/candidates"
                    icon={<UserGroupIcon />}
                    label="Manage Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/members"
                    icon={<MembersIcon />}
                    label="Your Members"
                  />
                  <HamburgerMenu.NavLink
                    to={{
                      pathname: `/explore-candidates`,
                      state: { scrollTop: true },
                    }}
                    icon={<SearchUserIcon />}
                    label="Explore Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/directory"
                    isStrongIcon
                    icon={<DirectoryIcon />}
                    label="Terminal Directory"
                  />
                  <HamburgerMenu.NavLink
                    to="/change-organization"
                    icon={<RefreshIcon />}
                    label="Change Organization"
                  />
                  {featureFlags.showRetoolEditOrganization && (
                    <HamburgerMenu.NavLink
                      to="/edit-organization"
                      icon={<EditIcon />}
                      label="Edit Organization"
                    />
                  )}
                </HamburgerMenu>
              )}
              {userPrioritizedRole === 'client-reviewer' && (
                <HamburgerMenu userName={user.first_name || ''} companyName={orgName}>
                  <HamburgerMenu.NavLink
                    to="/candidates"
                    icon={<UserGroupIcon />}
                    label="Manage Candidates"
                  />
                </HamburgerMenu>
              )}
              {userPrioritizedRole === 'client-prospect' && (
                <HamburgerMenu
                  userName={user.first_name || ''}
                  companyName={orgName}
                  badgeCopy="Free Trial"
                >
                  {isCallScheduled ? (
                    <HamburgerMenu.Item
                      bgColor="ui.lighter.warning"
                      pointerEvents="none"
                      icon={<WarningCircleIcon />}
                      label="Meeting Scheduled"
                    />
                  ) : (
                    <HamburgerMenu.Button
                      bgColor="ui.lighter.warning"
                      onClick={onUpgradeAccountClick}
                      pointerEvents={isCallScheduled ? 'none' : 'auto'}
                      icon={<WarningCircleIcon />}
                      label="Upgrade Your Account"
                    />
                  )}
                  <HamburgerMenu.NavLink to="/" exact icon={<DashboardIcon />} label="Dashboard" />
                  <HamburgerMenu.NavLink
                    to={{
                      pathname: `/explore-candidates`,
                      state: { scrollTop: true },
                    }}
                    icon={<SearchUserIcon />}
                    label="Explore Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/role"
                    isDisabled
                    icon={<JobsIcon />}
                    label="Manage Roles"
                  />
                  <HamburgerMenu.NavLink
                    to="/candidates"
                    isDisabled
                    icon={<UserGroupIcon />}
                    label="Manage Candidates"
                  />
                  <HamburgerMenu.NavLink
                    to="/members"
                    isDisabled
                    icon={<MembersIcon />}
                    label="Your Members"
                  />
                </HamburgerMenu>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Box
          display={['none', 'none', 'block']}
          h="full"
          pos="fixed"
          top={0}
          transition="3s ease"
          w={20}
          zIndex="sticky"
        >
          {/* TODO: [GROW-348] TopBarHeader and SidebarContent inversion of control */}
          <SidebarContent isClientReviewer={isClientReviewer} isClientProspect={isClientProspect} />
        </Box>
        <Box
          as="main"
          flexDirection="column"
          maxW={disableMaxWidth ? 'full' : ['full', 'full', 'container.lg', 'container.xl']}
          mx="auto"
          pl={[0, 0, 20]}
          pt={[0, 0, subHeader ? 22 : 20]}
          role="main"
          w="full"
        >
          <Sentry.ErrorBoundary
            fallback={
              <ErrorFallback
                onActionClick={() => {
                  window.location.reload();
                }}
                useFullHeight={false}
                showBackButton
              />
            }
          >
            {children}
          </Sentry.ErrorBoundary>
        </Box>
      </Box>
    </Sentry.ErrorBoundary>
  );
}

export const PageLayoutPaddedBody = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box p={6} ref={ref} {...props} />;
});

export function MainSideLayout({
  pageLayoutProps,
  main,
  sidebar,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  main: React.ReactNode;
  sidebar: React.ReactNode;
}) {
  return (
    <PageLayout {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <Grid templateColumns={['4fr', '4fr', '3fr 1fr']} gap={8}>
          <GridItem>{main}</GridItem>
          <GridItem>{sidebar}</GridItem>
        </Grid>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
