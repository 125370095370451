import {
  useBreakpointValue,
  Flex,
  Heading,
  Box,
  Grid,
  Link,
  Center,
  Text,
  Button,
  ArrowRightIcon,
} from '@terminal/design-system';
import { Link as RouterLink } from 'react-router-dom';

import { Logo as TerminalLogo } from 'global/components/Logo';
import type { AuthPageTemplateProps } from 'global/auth';
import plantAndMug from './assets/plantAndMug.svg?url';
import backgroundImg from './assets/background.svg?url';

export function CustomerAuthDefaultPageTemplate({
  children,
  bottomCTALink,
  headerSubtitle,
  headerTitle,
  footerLinkOnClick,
  footerText,
  footerLinkLabel,
}: AuthPageTemplateProps & {
  bottomCTALink?: { text: string; href: string; linkText: string };
}) {
  // Had declare ssr: false because of flickering issue when app is not ssr
  // @see: https://chakra-ui.com/docs/hooks/use-breakpoint-value
  const logoHeight = useBreakpointValue(['30px', '40px', '40px'], { ssr: false }) || '40px';

  return (
    <Flex
      flexDir="column"
      w="full"
      role="main"
      as="main"
      h={['auto', 'auto', '100vh']}
      bg="bg.primary"
      pb={[4, 4, 0]}
    >
      <Box
        backgroundImage={plantAndMug}
        zIndex={1}
        pointerEvents="none"
        backgroundRepeat="no-repeat"
        height="50%"
        width="50%"
        right={18}
        bottom={0}
        position="absolute"
        backgroundPosition="left"
        display={['none', 'none', 'block']}
      />
      <Box
        backgroundImage={backgroundImg}
        pointerEvents="none"
        backgroundRepeat="no-repeat"
        position={['fixed', 'fixed', 'absolute']}
        height="full"
        width={['full', 'full', '47vw']}
        bottom={[-7, -7, 0]}
        backgroundPosition={['0 100%', '0 100%', 'right bottom']}
      />

      <Grid
        templateColumns="repeat(2, 1fr)"
        position={['relative', 'relative', 'absolute']}
        w="full"
        px={[3, 3, 8]}
        py={[3, 3, 5]}
        bg={['bg.tertiary', 'bg.tertiary', 'none']}
      >
        <Flex alignItems="center">
          <Box height={logoHeight}>
            <TerminalLogo />
          </Box>
        </Flex>
        {bottomCTALink && (
          <Box alignItems="center" justifyContent="flex-end" display={['flex', 'flex', 'none']}>
            <Button
              variant="ghost"
              to={bottomCTALink.href}
              as={RouterLink}
              colorScheme="accent"
              rightIcon={<ArrowRightIcon />}
            >
              {bottomCTALink.linkText}
            </Button>
          </Box>
        )}
      </Grid>

      <Flex flexDir={['column', 'column', 'row']} flex={['none', 'none', 1]} px={0}>
        <Flex
          flex={1}
          bg="bg.tertiary"
          justifyContent={['center', 'center', 'flex-end']}
          alignItems="center"
          px={[3, 3, 0]}
          py={[6, 6, 0]}
          zIndex={[1, 1, 'initial']}
        >
          <Flex
            mr={[0, 0, '10%']}
            alignItems="center"
            justifyContent="flex-start"
            width="full"
            maxW="415px"
          >
            <Flex w={['auto', 'auto', 'full']} flexDir="column" alignItems="flex-start">
              {headerTitle && (
                <>
                  <Flex gridGap={2}>
                    <Box backgroundColor="#6DA8BB" width={2} height={2} />
                    <Box backgroundColor="#F6AE2D" width={2} height={2} />
                    <Box backgroundColor="#91B667" width={2} height={2} />
                  </Flex>
                  <Heading mt={[8, 8, 4]} variant="heading-1" mx={['auto', 'auto', 0]}>
                    {headerTitle}
                  </Heading>
                </>
              )}
              {headerSubtitle && (
                <Heading
                  mt={[3, 3, 4]}
                  variant="heading-3"
                  fontWeight="normal"
                  w={['100%', '100%', '90%']}
                  textAlign={['justify', 'justify', 'left']}
                >
                  {headerSubtitle}
                </Heading>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} justifyContent={['center', 'center', 'flex-start']}>
          <Flex
            alignItems="center"
            width="full"
            maxW="545px"
            ml={[0, 0, '13%']}
            mr={[0, 0, 8]}
            flex={1}
            p={[4, 4, 0]}
          >
            <Flex
              flexDir="column"
              flex={1}
              alignItems={['center', 'center', 'flex-start']}
              justifyContent={['flex-start', 'flex-start', 'center']}
              h="full"
            >
              <Flex
                w="full"
                justifyContent="center"
                flexDir="column"
                borderColor="ui.secondary"
                borderWidth="1px"
                backgroundColor="bg.primary"
                zIndex={1}
                borderRadius={4}
              >
                <Box p={8}>{children}</Box>
                {!!footerText && (
                  <Center bg="bg.secondary" p={5} borderBottomRadius="base">
                    <Text mr={2}>{footerText}</Text>
                    <Link fontWeight="bold" onClick={footerLinkOnClick}>
                      {footerLinkLabel}
                    </Link>
                  </Center>
                )}
              </Flex>
              {bottomCTALink && (
                <Center my={4} mx="auto" display={['none', 'none', 'flex']}>
                  <Text mr={2}>{bottomCTALink.text}</Text>
                  <Link fontWeight="bold" to={bottomCTALink.href} as={RouterLink}>
                    {bottomCTALink.linkText}
                  </Link>
                </Center>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
