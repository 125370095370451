import type {
  As,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  PositionProps,
  BackgroundProps,
  EffectProps,
} from '@terminal/design-system';

import { Flex } from '@terminal/design-system';

type CardProps = {
  children: React.ReactNode;
  as?: As;
} & FlexboxProps &
  SpaceProps &
  LayoutProps &
  PositionProps &
  BackgroundProps &
  EffectProps;

export function CardHeader({ children, ...stylingProps }: CardProps) {
  return <Flex {...stylingProps}>{children}</Flex>;
}

export function CardBody({ children, ...stylingProps }: CardProps) {
  return (
    <Flex flexDir="column" flex={1} {...stylingProps}>
      {children}
    </Flex>
  );
}

/**
 * Component is based in: candidate/components/Card/Card.tsx
 */
export function Card({ children, ...stylingProps }: CardProps) {
  return (
    <Flex
      bg="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
      flexDir="column"
      w="full"
      h="fit-content"
      p="6"
      gridGap="4"
      {...stylingProps}
    >
      {children}
    </Flex>
  );
}
