export const drawerTheme = {
  parts: ['body', 'header', 'dialog', 'closeButton', 'footer', 'overlay'],
  baseStyle: {
    header: {
      pb: 4,
      pl: 6,
      borderBottom: '1px',
      borderColor: 'ui.secondary',
    },
    overlay: {
      bg: 'rgba(57, 66, 76, 0.3)', // ui.primary opacity 0.3
      zIndex: 'overlay',
    },
    dialog: {
      borderRadius: 'base',
      boxShadow: 'md',
      maxW: 80,
      bg: 'bg.primary',
    },
    closeButton: {
      boxShadow: 'none',
      color: 'accent.main',
      w: 12,
      h: 12,
      left: 3,
      top: 3,
      _focus: {
        boxShadow: 'none',
      },
    },
    footer: {
      p: 0,
    },
  },
  sizes: {
    full: { dialog: { maxW: '100vw' } },
    xs: { dialog: { maxW: '20rem' } }, // 320 px
    sm: { dialog: { maxW: '25rem' } }, // 400 px
    md: { dialog: { maxW: '30rem' } }, // 480 px
    ml: { dialog: { maxW: '32rem' } }, // 512 px
    lg: { dialog: { maxW: '35rem' } }, // 560 px
    xl: { dialog: { maxW: '40rem' } }, // 640 px
    '2xl': { dialog: { maxW: '45rem' } }, // 720 px
    '3xl': { dialog: { maxW: '50rem' } }, // 800 px
    '4xl': { dialog: { maxW: '55rem' } }, // 880 px
    '5xl': { dialog: { maxW: '60rem' } }, // 960 px
  },
};
