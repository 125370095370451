/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect, useRef } from 'react';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import type { FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GoogleIcon,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useToast,
  ViewOffIcon,
  ViewOnIcon,
} from '@terminal/design-system';

const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .required('Please include an email address.'),
  password: Yup.string().required('Please include a password.'),
});

export interface SignInFormProps {
  email?: string;
  isEmailInputDisabled?: boolean;
  onForgotPasswordClick?: () => void;
  onGoogleLoginClicked: () => void;
  onEmailSignSubmitted: (email: string, password: string) => void;
  hideForgotPassword?: boolean;
  showLoadingForEmailSignIn?: boolean;
  disableGoogleSignInButton?: boolean;
  errorMessage?: string | null;
  googleSignInInstruction?: string;
  shouldHideGoogleSSO: boolean;
  logInWithEmailLabel?: string;
  logInWithGoogleLabel?: string;
  validationSchema?: Yup.AnySchema;
  renderPasswordField?: (
    formik: FormikProps<{ email: string; password: string }>,
  ) => React.ReactNode;
}

export function SignInForm({
  email,
  isEmailInputDisabled,
  onGoogleLoginClicked,
  onForgotPasswordClick,
  onEmailSignSubmitted,
  showLoadingForEmailSignIn = false,
  disableGoogleSignInButton = false,
  hideForgotPassword = false,
  errorMessage,
  googleSignInInstruction,
  shouldHideGoogleSSO,
  logInWithEmailLabel = 'Log In',
  logInWithGoogleLabel = 'Log In with Google',
  validationSchema = signInValidationSchema,
  renderPasswordField,
}: SignInFormProps) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const validationToastIDRef = useRef<string | number | undefined>();
  const submissionCount = useRef<number>(1);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, [setShowPassword]);

  const formik = useCustomFormik({
    initialValues: {
      email: email || '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => onEmailSignSubmitted(values.email, values.password),
  });

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        description: errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const hasValidationError =
    (formik.errors.email && formik.touched.email) ||
    (formik.errors.password && formik.touched.password);

  useEffect(() => {
    if (formik.isSubmitting && hasValidationError) {
      validationToastIDRef.current = toast({
        status: 'error',
        description: 'Please review your entry and try again.',
      });
    } else if (validationToastIDRef.current && !hasValidationError) {
      toast.close(validationToastIDRef.current);
    } else {
      submissionCount.current += 1;
    }
  }, [formik.isSubmitting, submissionCount.current, hasValidationError]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <FormControl id="email" isInvalid={!!formik.errors.email && !!formik.touched.email}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          type="email"
          placeholder="Email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          disabled={isEmailInputDisabled}
          autoCapitalize="none"
          spellCheck={false}
        />
        <FormErrorMessage role="alert">{formik.errors.email}</FormErrorMessage>
      </FormControl>

      {renderPasswordField?.(formik) || (
        <FormControl
          id="password"
          mt={6}
          isInvalid={!!formik.errors.password && !!formik.touched.password}
        >
          <Flex justifyContent="space-between">
            <FormLabel htmlFor="password">Password</FormLabel>
            {!hideForgotPassword && <Link onClick={onForgotPasswordClick}>Forgot Password?</Link>}
          </Flex>
          <InputGroup size="md">
            <Input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <InputRightElement width="4.5rem">
              {showPassword ? (
                <ViewOnIcon
                  _hover={{ cursor: 'pointer' }}
                  onClick={toggleShowPassword}
                  w={6}
                  h={6}
                />
              ) : (
                <ViewOffIcon
                  _hover={{ cursor: 'pointer' }}
                  onClick={toggleShowPassword}
                  w={6}
                  h={6}
                />
              )}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage role="alert">{formik.errors.password}</FormErrorMessage>
        </FormControl>
      )}

      <Button
        isLoading={showLoadingForEmailSignIn}
        onClick={() => {
          submissionCount.current += 1;
        }}
        variant="solid"
        colorScheme="primary"
        w="full"
        mt={6}
        type="submit"
      >
        {logInWithEmailLabel}
      </Button>

      {!shouldHideGoogleSSO && (
        <>
          <HStack spacing={3} my={6}>
            <Divider />
            <Text fontWeight="bold" variant="hint">
              OR
            </Text>
            <Divider />
          </HStack>

          <Button
            onClick={onGoogleLoginClicked}
            leftIcon={<GoogleIcon w={6} h={6} mr={1} />}
            isDisabled={disableGoogleSignInButton}
            variant="outline"
            colorScheme="accent"
            w="full"
            type="button"
            id="google-sign-in-button"
          >
            {logInWithGoogleLabel}
          </Button>
          {googleSignInInstruction && (
            <Text variant="caption" mt={6} textAlign="center">
              {googleSignInInstruction}
            </Text>
          )}
        </>
      )}
    </form>
  );
}
