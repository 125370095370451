import { extendTheme, theme as defaultTheme } from '@terminal/design-system';

const ui = {
  error: {
    lightest: '#F9E1DC',
    lighter: '#EDA596',
    main: '#DC4B2E',
    darker: '#9D301B',
    darkest: '#46150C',
  },
  warning: {
    lightest: '#FEF8EB',
    lighter: '#FFE6AD',
    main: '#FFAD00',
    darker: '#AE7704',
    darkest: '#3A2903',
  },
  success: {
    lightest: '#E6F0DA',
    lighter: '#CEE9AF',
    main: '#96BA6E',
    darker: '#546D3C',
    darkest: '#324224',
  },
  info: {
    lightest: '#D4E0ED',
    lighter: '#B6C7E2',
    main: '#7B9ACC',
    darker: '#426D9E',
    darkest: '#2A4665',
  },
};

export const colors = {
  brand: {
    lightest: '#F2F6F8',
    lighter: '#B0C8D4',
    main: '#517E94',
    darker: '#334E57',
    darkest: '#25383F',
  },
  accent: {
    lightest: '#DBE1CB',
    lighter: '#B5C397',
    main: '#889D58',
    darker: '#667642',
    darkest: '#394125',
  },
  ui: {
    primary: '#25383F',
    secondary: '#D5DADC',
    disabled: '#EEF0F1',
    error: ui.error.main,
    info: ui.info.main,
    success: ui.success.main,
    warning: ui.warning.main,
    inverse: {
      primary: '#FFFFFF',
      secondary: '#F4F7F9',
    },
    lightest: {
      error: ui.error.lightest,
      success: ui.success.lightest,
      warning: ui.warning.lightest,
    },
    lighter: {
      error: ui.error.lighter,
      success: ui.success.lighter,
      warning: ui.warning.lighter,
      info: ui.info.lighter,
    },
    darker: {
      error: ui.error.darker,
      success: ui.success.darker,
      warning: ui.warning.darker,
      disabled: '#AFAFAF', // remove?
    },
    darkest: {
      error: ui.error.darkest,
      success: ui.success.darkest,
      warning: ui.warning.darkest,
    },
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#F5F5F5',
    tertiary: '#EBF0F5',
  },
  text: {
    primary: '#25383F',
    secondary: '#637A83',
    disabled: '#ABB6BA',
    inverse: '#FFFFFF',
    error: '#DC4B2E',
    success: '#96BA6E',
    warning: '#FFAD00',
    link: '#6C9AAE',
  },
};

export const styles = {
  global: {
    html: {
      h: 'fill-available',
    },
    body: {
      display: 'block',
      bg: '#FCFCFC',
    },
    '#root': {
      display: 'block',
      flex: 'initial',
    },
  },
};

export const theme = extendTheme(
  {
    colors,
    styles,
  },
  defaultTheme,
);
