import { useEffect, useReducer } from 'react';
import type { Reducer } from 'react';
import { useLocation } from 'react-router-dom';
import { firebaseAuth } from 'global/firebaseApp';
import {
  verifyPasswordResetCode,
  emailSignIn,
  confirmPasswordReset,
} from 'global/auth/authMethods';
import { useToast } from '@terminal/design-system';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { PasswordResetPage } from './PasswordResetPage';
import { PasswordResetCodeExpiredPage } from './PasswordResetCodeExpiredPage';
import type { AuthenticationRouterOverwrite } from '../types';

export function PasswordResetController({
  redirectTo,
  overwrite,
}: {
  redirectTo: (path: string) => void;
  overwrite?: AuthenticationRouterOverwrite;
}) {
  type ResetPasswordState = {
    email: string | null;
    view: 'reset-password' | 'code-expired';
  };

  const toast = useToast({
    position: 'top',
    duration: 6000,
  });

  const queryString = new URLSearchParams(useLocation().search);
  const oobCode = queryString.get('oobCode'); // security code from firebase base reset password action email

  const [state, dispatch] = useReducer<
    Reducer<AsyncState<ResetPasswordState>, AsyncAction<ResetPasswordState>>
  >(asyncReducer, createAsyncInitialState({ email: null, view: 'reset-password' }));

  const { isLoading, isError } = toAsyncProps(state);
  const view = state.data?.view || 'reset-password';

  useEffect(() => {
    const validateCodeAsync = async () => {
      if (oobCode) {
        try {
          dispatch({
            type: 'pending',
          });

          const email = await verifyPasswordResetCode(firebaseAuth, oobCode);

          dispatch({
            type: 'resolved',
            data: { email, view: email ? 'reset-password' : 'code-expired' },
          });
        } catch (error) {
          dispatch({
            type: 'rejected',
            error,
          });
        }
      } else {
        dispatch({
          type: 'resolved',
          data: { email: null, view: 'code-expired' },
        });
      }
    };
    validateCodeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetPasswordSubmit = async (newPassword: string) => {
    try {
      dispatch({
        type: 'pending',
      });

      await confirmPasswordReset(firebaseAuth, oobCode as string, newPassword);

      toast({
        status: 'success',
        description: 'Password updated successfully',
      });
    } catch (error) {
      dispatch({
        type: 'rejected',
        error,
      });
    }

    try {
      await emailSignIn({ auth: firebaseAuth }, state.data?.email as string, newPassword);

      redirectTo('from-with-refresh');
      dispatch({
        type: 'resolved',
        data: null,
      });
    } catch (authError) {
      dispatch({
        type: 'rejected',
        error: authError,
      });
      redirectTo('login');
    }
  };

  const codeExpiredErrorCodes = [
    'auth/expired-action-code',
    'auth/invalid-action-code',
    'auth/user-disabled',
    'auth/user-not-found',
  ];

  if (isError && !codeExpiredErrorCodes.includes(state.error?.code || '')) {
    throw state.error;
  }

  if (view === 'code-expired' || isError) {
    return (
      <PasswordResetCodeExpiredPage
        redirectTo={redirectTo}
        pageTemplateOverwrite={
          overwrite?.pageTemplate?.['password-reset-expired'] || overwrite?.pageTemplate?.default
        }
      />
    );
  }

  return (
    <PasswordResetPage
      isLoading={isLoading}
      onResetPasswordSubmit={handleResetPasswordSubmit}
      pageTemplateOverwrite={
        overwrite?.pageTemplate?.['password-reset'] || overwrite?.pageTemplate?.default
      }
    />
  );
}
