import { useReducer } from 'react';
import type { Reducer } from 'react';

import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth/authMethods';
import authContext from 'global/authContext';
import { asyncReducer, createAsyncInitialState, toAsyncProps, CodeError } from 'global/utils';
import { useHandleAuthBackendError } from '../auth.utils';
import { EmailNotVerifiedWarningPage } from './EmailNotVerifiedWarningPage';
import { EmailNotVerifiedResendSuccessPage } from './EmailNotVerifiedResendSuccessPage';
import type { AuthenticationRouterOverwrite } from '../types';

export function useSendEmailVerifyLink({
  generateContinueURL,
}: {
  generateContinueURL?: () => string;
} = {}) {
  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );

  const { isLoading, isError, isResolved, error } = toAsyncProps(state);

  useHandleAuthBackendError({
    isError,
    error: state.error,
    throwIfNoFriendlyError: false,
    errorsWithFriendlyMessage: {
      'no-code': 'There is not code in your email validation link',
      'auth/expired-action-code': 'The validation link has expired. Please request a new one.',
      'auth/invalid-action-code': 'The validation link is not valid. Please request a new one.',
      'auth/too-many-requests': 'Too many attempts. Please wait a few minutes before trying again.',
    },
  });

  const handleSendVerifyEmail = async () => {
    try {
      dispatch({ type: 'pending' });

      const url = `${import.meta.env.REACT_APP_AUTH_URL}/user/verify-email`;

      const { headers } = await authContext(firebaseAuth, 'web', 'verify');

      const result = await fetch(url, {
        method: 'POST',
        headers: { ...headers, 'Content-Type': 'application/json' },
        body: JSON.stringify({
          url: `${window.location.origin}${
            generateContinueURL?.() || '/auth/?utm_source=email-verification&utm_medium=email'
          }`,
        }),
      });

      if (result.ok) {
        dispatch({ type: 'resolved', data: null });
      } else {
        const { error: result_error } = await result.json();

        if (result_error?.includes?.('TOO_MANY_ATTEMPTS_TRY_LATER')) {
          throw new CodeError(result_error, 'auth/too-many-requests');
        }

        throw new Error(result_error);
      }
    } catch (_error: unknown) {
      dispatch({ type: 'rejected', error: _error });
    }
  };

  return {
    isLoading,
    handleSendVerifyEmail,
    isError,
    error,
    isResolved,
  };
}

export function EmailNotVerifiedController({
  overwrite,
}: {
  overwrite?: AuthenticationRouterOverwrite;
}) {
  const { handleSendVerifyEmail, isLoading, isResolved } = useSendEmailVerifyLink({
    generateContinueURL: overwrite?.attrs?.['email-not-verified']?.generateContinueURL,
  });

  if (isResolved) {
    return (
      <EmailNotVerifiedResendSuccessPage
        pageTemplateOverwrite={
          overwrite?.pageTemplate?.['email-not-verified-success'] ||
          overwrite?.pageTemplate?.default
        }
      />
    );
  }

  return (
    <EmailNotVerifiedWarningPage
      isLoading={isLoading}
      onLogoutClick={() => {
        signOut({ auth: firebaseAuth });
      }}
      onResendVerificationEmailClick={handleSendVerifyEmail}
      pageTemplateOverwrite={
        overwrite?.pageTemplate?.['email-not-verified'] || overwrite?.pageTemplate?.default
      }
    />
  );
}
