import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { useToast } from '@terminal/design-system';
import { GenerateJobFromAI } from 'talent-hub/shared/features/roles/subfeatures/create-edit-job/data';
import type {
  GenerateJobFromAiQuery,
  GenerateJobFromAiQueryVariables,
} from 'talent-hub/shared/features/roles/subfeatures/create-edit-job/data';

import Sentry from 'global/sentry';
import type {
  SelectAiCandidatesMatchingJobMutation,
  SelectAiCandidatesMatchingJobMutationVariables,
  UpsertJobFromDashboardMutation,
  UpsertJobFromDashboardMutationVariables,
} from './data';
import { type PendingJob } from '../Dashboard.serializer';
import { DashboardPendingRole } from './DashboardPendingRole';

import { SelectAICandidatesMatchingJob, UpsertJobFromDashboard } from './data';
import {
  dashboard_pending_role_serializer,
  serialize_update_job,
} from './DashboardRole.serializer';

export function DashboardPendingRolesController({ role }: { role: PendingJob }) {
  const history = useHistory();
  const { viewingOrganization } = useCustomerAuthorizedUserSession();
  const [candidates_by_ai_matching_mutation, { data, loading, error }] = useMutation<
    SelectAiCandidatesMatchingJobMutation,
    SelectAiCandidatesMatchingJobMutationVariables
  >(SelectAICandidatesMatchingJob, {
    variables: role.candidates_ai_matching_args,
    context: {
      service: 'service',
    },
  });

  if (error) throw error;

  useEffect(() => {
    candidates_by_ai_matching_mutation();
    // We don't want to run this effect again irrespective of anything changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role.name,
    role.yoe,
    role.topSkills.length,
    role.rawRole.about,
    role.rawRole.what_youll_bring,
    role.rawRole.what_youll_do,
  ]);

  const pending_role = dashboard_pending_role_serializer(data, role);

  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const [handleGenerateJob, job_prompt] = useLazyQuery<
    GenerateJobFromAiQuery,
    GenerateJobFromAiQueryVariables
  >(GenerateJobFromAI, {
    onError: () => {
      toast({
        status: 'error',
        description: 'Something went wrong. Please try again!',
      });
    },
    context: {
      service: 'service',
    },
    fetchPolicy: 'network-only',
  });

  const [updateJob] = useMutation<
    UpsertJobFromDashboardMutation,
    UpsertJobFromDashboardMutationVariables
  >(UpsertJobFromDashboard, {
    update(cache, mutationResult) {
      // * This manual update is required because this is an array of objects, and the auto-update just works in plain fields
      if (!mutationResult.data?.update_job_by_pk?.id) return;
      cache.modify({
        id: cache.identify({
          __typename: 'job',
          id: mutationResult.data?.update_job_by_pk?.id,
        }),
        fields: {
          job_required_skills() {
            return (
              mutationResult.data?.insert_job_required_skill?.returning.map(({ skill }) => skill) ||
              []
            );
          },
        },
      });
    },
    onCompleted: () => {
      return toast({
        title: 'Draft successfully Updated',
        status: 'success',
      });
    },
    onError: (mutationError) => {
      toast({
        title: 'Failed to update the Job',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
  });

  return (
    <DashboardPendingRole
      id={pending_role.id.toString()}
      name={pending_role.name || ''}
      positions_count={pending_role.positions_count}
      yoe={pending_role.yoe}
      topSkills={pending_role.topSkills}
      status={pending_role.status}
      currentRoleCandidateMatches={pending_role.currentRoleCandidateMatches}
      handleCandidateClick={(candidate_id) => {
        history.push(`/dashboard/candidate/${candidate_id}`); // TODO: I think this should be a router link instead?
      }}
      view_all_candidate_matches_link={pending_role.view_all_candidate_matches_link}
      is_loading_candidate_matches_by_role={loading}
      action={pending_role.action}
      generateJob={{
        handleGenerateJob: async ({ query }: { query: string }) => {
          const generatedData = await handleGenerateJob({
            variables: { query, company_name: viewingOrganization.name },
          });

          if (!generatedData.data?.aiJob || generatedData.error) {
            toast({
              status: 'error',
              description: 'Something went wrong. Please try again!',
            });
            return { success: false };
          }

          await updateJob({
            variables: serialize_update_job(generatedData.data?.aiJob, role.rawRole),
          });

          return { success: true };
        },
        isLoading: job_prompt.loading,
      }}
    />
  );
}
