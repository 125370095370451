import { useHistory } from 'react-router-dom';
import { Center, Heading, Text, Image, Button, Box, Flex } from '@terminal/design-system';
import { useRedirection } from 'global/utils';

import plant from './assets/plant.svg?url';

export function ErrorFallback({
  title = 'Oops!',
  subTitle = 'Something went wrong',
  imageSrc = plant,
  description = "We're sorry. An error has occured, please try again",
  onActionClick,
  actionTitle = 'Try Again',
  useFullHeight = true,
  showBackButton,
}: {
  title?: string;
  subTitle?: string;
  imageSrc?: string;
  description?: string | React.ReactNode;
  onActionClick: () => void;
  actionTitle?: string;
  useFullHeight?: boolean;
  showBackButton?: boolean;
}) {
  const redirectTo = useRedirection();
  const history = useHistory();

  const handleBackAction = () => {
    if (history.action === 'PUSH') {
      history.goBack();
      window.location.reload();
      return;
    }

    redirectTo('/');
    window.location.reload();
  };

  return (
    <Center flexDirection="column" w="full" p={[6, 8]} minH={useFullHeight ? '100vh' : 'auto'}>
      <Box w="full" maxW="350px">
        <Image src={imageSrc} alt="" mx="auto" />
      </Box>
      <Center flexDirection="column" maxW="lg" mt={6}>
        <Heading variant="heading-1" color="brand.main">
          {title}
        </Heading>
        <Heading variant="heading-2" pt={1}>
          {subTitle}
        </Heading>
        <Text mt={2}>{description}</Text>
        <Flex flexDir={['column', 'row', 'row']}>
          {showBackButton && (
            <Button onClick={handleBackAction} mt={6} w="auto" variant="outline" mr={4}>
              Back to Previous page
            </Button>
          )}
          <Button onClick={onActionClick} mt={6} w="auto" variant="solid">
            {actionTitle}
          </Button>
        </Flex>
      </Center>
    </Center>
  );
}
