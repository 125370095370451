import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Employment_Type_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types';
import { stripAllHTMLAttributes, stripHtmlTags } from 'global/utils';
import { capitalizeEachWord } from 'talent-hub/utils';
import type { SelectAiCandidatesMatchingJobMutation } from 'talent-hub/role/client/new.dashboard/dashboard-roles/data';

export function to_querystring_of_ai_matching_args(role: {
  title?: string;
  description?: string | null;
  what_youll_do?: string | null;
  what_youll_bring?: string | null;
  job_required_skills?: string[];
  job_tech_stack?: string[];
}): string {
  const query_parts: string[] = [];

  if (role.title) {
    query_parts.push(`Job Title: ${role.title}`);
  }

  if (role.description) {
    query_parts.push(`Description: ${stripHtmlTags(stripAllHTMLAttributes(role.description))}`);
  }

  if (role.what_youll_do) {
    query_parts.push(
      `What You'll Do: ${stripHtmlTags(stripAllHTMLAttributes(role.what_youll_do))}`,
    );
  }

  if (role.what_youll_bring) {
    query_parts.push(
      `What You'll Bring: ${stripHtmlTags(stripAllHTMLAttributes(role.what_youll_bring))}`,
    );
  }

  if (role.job_required_skills?.length) {
    query_parts.push(`Required Skills: ${role.job_required_skills.join(', ')}`);
  }

  if (role.job_tech_stack?.length) {
    query_parts.push(`Tech Stack: ${role.job_tech_stack.join(', ')}`);
  }

  return query_parts.join('. ');
}

export function createExploreCandidatesURL(candidates_matches_query_args: {
  must_have_skill_ids: number[];
  regions: null;
  countries: string[] | null;
  sort_option: string;
  employment_type: Employment_Type_Choices_Enum | null;
  badges: null;
  candidate_role: Role_Choices_Enum | null;
  should_have_skill_ids: never[];
  min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null;
}): string {
  const {
    candidate_role,
    min_years_of_experience,
    sort_option,
    employment_type,
    countries,
    must_have_skill_ids,
    should_have_skill_ids,
  } = candidates_matches_query_args;

  const queryParams: string[] = [];

  if (candidate_role) {
    queryParams.push(`role=${encodeURIComponent(candidate_role)}`);
  }

  if (min_years_of_experience) {
    queryParams.push(`yoe=${encodeURIComponent(min_years_of_experience)}`);
  }

  if (sort_option) {
    queryParams.push(`sort=${encodeURIComponent(sort_option)}`);
  }

  if (employment_type) {
    queryParams.push(`emp_type=${encodeURIComponent(employment_type)}`);
  }

  if (countries && countries.length > 0) {
    queryParams.push(`countries=${encodeURIComponent(countries.join(','))}`);
  }

  if (must_have_skill_ids && must_have_skill_ids.length > 0) {
    queryParams.push(`must_skills=${encodeURIComponent(must_have_skill_ids.join(','))}`);
  }

  if (should_have_skill_ids && should_have_skill_ids.length > 0) {
    queryParams.push(`nice_skills=${encodeURIComponent(should_have_skill_ids.join(','))}`);
  }

  return queryParams.length > 0
    ? `/explore-candidates?${queryParams.join('&')}`
    : '/explore-candidates';
}

function convert_yoe_to_friendly_range_of_yoe(yoe: number) {
  if (yoe > 10) {
    return 'More than 10 years';
  }

  if (yoe > 5) {
    return '5 to 10 years';
  }

  if (yoe > 2) {
    return '2 to 5 years';
  }

  return '0 to 2 years';
}

const quantitySkillsToShow = 4;

export function to_matching_candidates(
  data: SelectAiCandidatesMatchingJobMutation | null | undefined,
  role_required_skills: string[],
): {
  id: number;
  firstName: string;
  lastName?: string;
  last_name_initial?: string;
  currentRole: string;
  yearsExperience: string;
  city?: string;
  country_choice?: {
    name: string;
  };
  skills: Array<{
    id: number | string;
    name: string;
    isSelected: boolean;
  }>;
  remainingSkills: number;
}[] {
  if (!data?.searchCandidates?.candidates?.length) {
    return [];
  }

  const candidates = data.searchCandidates.candidates
    .filter((candidate): candidate is NonNullable<typeof candidate> => candidate !== null)
    .map((candidate) => {
      return {
        id: candidate.candidateId ?? 0,
        firstName: capitalizeEachWord(candidate.firstName) ?? '',
        lastName: capitalizeEachWord(candidate.lastName) ?? undefined,
        currentRole: candidate.lastRoleTitle ?? '',
        yearsExperience:
          candidate.yoe != null ? convert_yoe_to_friendly_range_of_yoe(candidate.yoe) : '-',
        city: undefined, // Not provided in input
        country_choice: candidate.country ? { name: candidate.country } : undefined, // TODO: should this be a simple string?
        skills: (candidate.skills ?? [])
          .filter((skill): skill is string => skill !== null)
          .map((skill, index) => ({
            /**
             * The Index of the skills in the array of skills
             */
            id: index, // TODO: change this to index, as id is usually preserved for the ID of a record in DB. So this can get confusing.
            name: skill,
            isSelected: Boolean(
              role_required_skills.find((role_required_skill) => role_required_skill === skill),
            ),
          }))
          .sort((a, b) => Number(b.isSelected) - Number(a.isSelected))
          .slice(0, quantitySkillsToShow),
        remainingSkills:
          (candidate.skills ?? []).length - quantitySkillsToShow > 0
            ? (candidate.skills ?? []).length - quantitySkillsToShow
            : 0,
      };
    });

  return candidates;
}
