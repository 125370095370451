import { Box, Heading, HStack, Badge } from '@terminal/design-system';
import { Card, CardHeader, CardBody } from 'talent-hub/components';
import { CandidateStageStatus } from 'talent-hub/constants';

export function ModalCandidateStatus({
  stepTitle,
  info,
  status,
  statusList,
  children,
  showActionNeeded,
}: {
  stepTitle: string;
  info: string;
  status: string;
  statusList?: React.ReactNode;
  children?: React.ReactNode;
  showActionNeeded?: boolean;
}) {
  if (info === 'N/A') {
    return null;
  }

  const isRejectedAt = status === CandidateStageStatus.RejectedAt;
  const statusIsCompletedOrSkipped =
    status === CandidateStageStatus.Completed || status === CandidateStageStatus.Skipped;
  const isHired = status === CandidateStageStatus.Completed && stepTitle === 'Hired';
  const headingTextColor =
    status === CandidateStageStatus.InProgress ? 'text.primary' : 'text.disabled';

  return (
    <Box w="full">
      <Card bg={isHired ? 'ui.success' : 'bg.primary'} justifyContent="center">
        <CardHeader justifyContent="space-between">
          <Heading variant="heading-3" color={isHired ? 'text.inverse' : headingTextColor}>
            {stepTitle}
          </Heading>
          {!isHired && (
            <HStack spacing={2}>
              {showActionNeeded ? (
                <Badge colorScheme="warning">Action Needed</Badge>
              ) : (
                <Badge colorScheme={statusIsCompletedOrSkipped ? 'success' : 'info'}>
                  {isRejectedAt ? 'Declined' : info}
                </Badge>
              )}
            </HStack>
          )}
        </CardHeader>
        {children && <CardBody>{children}</CardBody>}
      </Card>
      {statusList}
      {isRejectedAt && (
        <Card bg="ui.disabled" minH="108px" mt={6}>
          <CardBody justifyContent="center">
            <HStack justifyContent="space-between">
              <Heading variant="heading-3" color="text.secondary">
                Candidate No Longer Active
              </Heading>
              <HStack spacing={2}>
                <Badge colorScheme="error">{info}</Badge>
              </HStack>
            </HStack>
          </CardBody>
        </Card>
      )}
    </Box>
  );
}
