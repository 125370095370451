import { ViewType } from 'talent-hub/shared/features/explore-candidates';
import { useParams } from 'react-router-dom';
import {
  SavedCandidates,
  useSavedCandidatesController,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/saved-candidates';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { useCalendlyWidget } from '../../components/CalendlyWidget';
import { CandidateProfileController } from '../candidate-profile';

export function SavedCandidatesController({ isInLimitedMode }: { isInLimitedMode?: boolean }) {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const {
    loading,
    candidates,
    handleOnSaveCandidate,
    handleOnRemoveSaveCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    onCloseCandidateProfileClick,
  } = useSavedCandidatesController();
  const calendlyModal = useCalendlyWidget();

  return (
    <>
      <SavedCandidates
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        loading={loading}
        candidates={candidates}
        handleOnSaveCandidate={handleOnSaveCandidate}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        sharedCandidateWithProps={{
          // * not required data, since isInLimitedMode
          teamMembers: undefined,
          handleInsertSharedWith: () => {},
          isShareWithModalOpen: false,
          onOpenShareWithModal: calendlyModal.onOpen,
          onCloseShareWithModal: () => {},
        }}
        isInLimitedMode={isInLimitedMode}
      />
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={onCloseCandidateProfileClick}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        isInLimitedMode={isInLimitedMode}
        onOpenBookCallModal={calendlyModal.onOpen}
        viewPage={ViewType.SAVED}
      />
    </>
  );
}
