import { textTheme } from '../../Text/Text.theme';

export const formLabelTheme = {
  baseStyle: {
    marginEnd: 3, // TODO (CAND-234): remove this from the base style. This style should be lifted to the consumer of this component, and the position of form label should not be assumed here.
    mb: 2, // TODO (CAND-234): same as above
    transition: 'all 0.2s',
    opacity: 1,
    _disabled: {
      opacity: 0.4,
    },
  },
  variants: {
    primary: {
      ...textTheme.variants.label,
    },
    secondary: {
      ...textTheme.variants.caption,
      fontWeight: 'bold',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
