import { useReducer } from 'react';
import type { Reducer } from 'react';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { createStorageRef, getDownloadURL } from 'global/firebaseApp';
import { useTalentHubFlags } from 'talent-hub/utils/flags';

interface RemoteFileProps {
  /**
   * Firebase directory path.
   */
  path: string;
  /**
   * Firebase filename. Will also be used as the default name when downloaded.
   */
  fileName: string;
  /**
   * Firebase fileExtension. Will be used to force a certain file extension when downloaded.
   */
  fileExtension?: string;
}

/**
 * Returns a function to download a file stored in firebase, as well as the
 * state of the async function.
 *
 * @privateRemarks
 * To download the file it creates a blob and a temporary <a> HTML element,
 * which is automatically clicked and then removed from the DOM. This with the
 * intention to avoid a CORS error and download the file instead of opening. in
 * a new tab
 *
 * @returns an object meant to be destructured with the download function
 *
 */

export function useDownloadFile() {
  const featureFlags = useTalentHubFlags();

  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );

  const { isError, isLoading, isResolved } = toAsyncProps(state);

  const downloadFile = async ({ path, fileName, fileExtension }: RemoteFileProps) => {
    try {
      const resumeRef = createStorageRef(path);
      const downloadURL = await getDownloadURL(resumeRef);
      const fileResponse = await fetch(downloadURL, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: 'cors',
      });
      const fileBlob = await fileResponse.blob();
      const blobURL = window.URL.createObjectURL(fileBlob);
      const a = document.createElement('a');

      a.setAttribute(
        'download',
        fileExtension &&
          fileBlob.type === 'application/octet-stream' &&
          featureFlags.enableCandidateResumeViewer
          ? `${fileName}${fileExtension}`
          : fileName,
      );

      a.setAttribute('href', blobURL);
      document.body.appendChild(a);
      a.click();
      a.remove();

      dispatch({
        type: 'resolved',
        data: null,
      });
    } catch (error) {
      dispatch({
        type: 'rejected',
        error,
      });
    }
  };

  return {
    isError,
    isLoading,
    isResolved,
    downloadFile,
  };
}
