import { Box, Button, Text, ErrorCircleIcon, Link } from '@terminal/design-system';
import { AuthMessage, AuthPageTemplate } from '../components';
import type { AuthRedirectTo, AuthPageTemplateProps } from '../types';

export function VerifyEmailLinkExpiredPage({
  redirectTo,
  pageTemplateOverwrite,
}: {
  redirectTo: AuthRedirectTo;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate>
      <AuthMessage icon={<ErrorCircleIcon />} title="Link Expired!">
        <Box>
          <Text>
            The verification email link has expired, please re-send it. If you have any problems,
            please{' '}
            <Link href="mailto:support@terminal.io" display="inline">
              contact us.
            </Link>
          </Text>
          <Button
            variant="solid"
            colorScheme="primary"
            onClick={() => redirectTo('email-not-verified')}
            mt={6}
            w={64}
          >
            Re-Send Link
          </Button>
        </Box>
      </AuthMessage>
    </PageTemplate>
  );
}
