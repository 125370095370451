import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import type { RoleLayout } from '../../components';
import { RoleBlankLoadingScreen } from '../../components';
import { useInterviewFeedbackUtils } from '../../useInterviewFeedbackUtils';
import { CandidateFeedback } from './CandidateFeedback';
import { serializeCandidateFeedback } from './CandidateFeedback.serializer';
import type { SelectCandidateFeedbackQuery, SelectCandidateFeedbackQueryVariables } from './data';
import { SelectCandidateFeedback } from './data';

export function CandidateFeedbackController({
  roleLayoutProps,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  const { activeRole, candidateID } = useParams<{ activeRole: string; candidateID: string }>();
  const history = useHistory();
  const { state } = useLocation<{
    from?: 'role-stage-candidate-list';
  }>();
  const { isClientReviewer } = useCustomerAuthorizedUserSession();
  const { loading, data } = useQuery<
    SelectCandidateFeedbackQuery,
    SelectCandidateFeedbackQueryVariables
  >(SelectCandidateFeedback, {
    variables: {
      person_profile_id: Number(candidateID),
      role_id: Number(activeRole),
      isClientReviewer,
    },
  });

  const { feedbackList, candidate, applicantWorkflowID } = serializeCandidateFeedback(data);
  const interviewFeedbackUtils = useInterviewFeedbackUtils({
    applicantWorkflowID,
    jobID: data?.icims_applicant_workflow?.['0']?.icims_job?.job?.id!,
    shouldRefreshFeedbackList: true,
  });

  const superTitle =
    state?.from === 'role-stage-candidate-list' ||
    (history.action !== 'PUSH' && history.action !== 'POP')
      ? `Back to ${roleLayoutProps.roleTitle}`
      : `Back to ${candidate.fullName}'s Candidate Profile`;

  const handleBackAction = () => {
    if (history.action === 'PUSH') {
      history.goBack();
      return;
    }
    if (isClientReviewer) {
      history.push(`/role/${activeRole}/candidate/${candidateID}`);
      return;
    }
    history.push(`/role/${activeRole}`);
  };

  if (loading) {
    return <RoleBlankLoadingScreen roleLayoutProps={roleLayoutProps} />;
  }

  return (
    <CandidateFeedback
      roleLayoutProps={{ ...roleLayoutProps, handleBackAction, superTitle }}
      feedbackList={feedbackList}
      candidate={candidate}
      interviewFeedbackUtils={interviewFeedbackUtils}
      isClientReviewer={isClientReviewer}
    />
  );
}
