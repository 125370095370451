import { displayRolesNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { Client_Prospect_Role_Insert_Input } from 'global/types';
import {
  Client_Prospect_Constraint,
  Client_Prospect_Update_Column,
  Role_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
} from 'global/types';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';

import type { serializeOnboardingData } from '../../data';
import type { DeleteInsertClientProspectRolesMutation } from './data';
import type { FormData } from './Roles.controller';

export function serializeSelectedRoleOptions({
  roleList,
  options,
  userID,
}: {
  roleList: FormData['roleList'];
  options: ReturnType<typeof serializeOnboardingData>['roleOptions'];
  userID: number;
}): Client_Prospect_Role_Insert_Input[] {
  return roleList.map((values) => {
    return {
      role_type:
        (Object.keys(displayRolesNames).find(
          (key) => displayRolesNames[key as Role_Choices_Enum] === values.role,
        ) as Role_Choices_Enum) || null,
      years_of_experience:
        (options.yearsOfExperience.find((key) => key.label === values.yearsOfExperience)
          ?.value as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum) || null,
      client_prospect_role_skills: {
        data: values.skills?.map(({ value }) => ({
          skill_id: Number(value),
        })),
      },
      client_prospect: {
        data: {
          user_info_id: userID,
        },
        on_conflict: {
          constraint: Client_Prospect_Constraint.ClientProspectUserInfoIdKey,
          update_columns: [Client_Prospect_Update_Column.Email],
        },
      },
    };
  });
}

const displayExperience: { [key in Candidate_Curation_Years_Of_Exp_Range_Choices_Enum]: string } = {
  [Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo]: '',
  [Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive]: '',
  [Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen]: 'Senior ',
  [Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus]: 'Senior ',
};

const displayCustomRolesNames: Record<Role_Choices_Enum | string, string> = {
  [Role_Choices_Enum.Fed]: 'Frontend Engineer',
  [Role_Choices_Enum.Bed]: 'Backend Engineer',
  [Role_Choices_Enum.Fsd]: 'Full Stack',
  [Role_Choices_Enum.Aqa]: 'Automation QA',
  [Role_Choices_Enum.Mqa]: 'Manual QA',
  [Role_Choices_Enum.Mod]: 'Mobile',
  [Role_Choices_Enum.Dsc]: 'Data Science',
  [Role_Choices_Enum.Dan]: 'Data Analyst',
  [Role_Choices_Enum.Den]: 'Data Engineer',
  [Role_Choices_Enum.Dev]: 'DevOps',
  [Role_Choices_Enum.Enl]: 'Eng. Lead',
  [Role_Choices_Enum.Aml]: 'AI/ML',
  [Role_Choices_Enum.Scm]: 'Scrum Master',
  [Role_Choices_Enum.Des]: 'Designer',
  [Role_Choices_Enum.Prm]: 'Product Manager',
};

export const displayRolesOrder: Record<Role_Choices_Enum | string, number> = Object.keys(
  displayCustomRolesNames,
).reduce((result, key, index) => ({ ...result, [key]: index }), {});

export function serializerInsertedRoles(
  data: NonNullable<DeleteInsertClientProspectRolesMutation['insert_client_prospect_role']>,
): {
  value: string;
  experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  skillIDs: number[];
  skillNames: string[];
  roleDisplayName: string;
  experienceDescription: string;
}[] {
  return data.returning.map?.(
    ({ role_choice, years_of_experience, client_prospect_role_skills }) => ({
      value: role_choice.value,
      experience: years_of_experience,
      skillIDs: client_prospect_role_skills.map(({ skill_id }) => skill_id),
      skillNames: client_prospect_role_skills.map(({ skill }) => skill.name as string),
      roleDisplayName: `${displayExperience[years_of_experience] || ''}${
        displayCustomRolesNames[role_choice.value as Role_Choices_Enum]
      }`,
      experienceDescription: `${toFriendlyYearsOfExperienceRange(
        years_of_experience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
      )} of experience`,
    }),
  );
}
