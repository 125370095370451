import { Candidate_Availability_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import type { AuthData } from './types';

function createFixture_firebaseUser(
  overwrite: Partial<Required<AuthData['user']>> = {} as Partial<Required<AuthData['user']>>,
  roles: string[] = ['candidate', 'authenticated', 'user'],
): Required<AuthData['user']> {
  return {
    candidate: {
      first_name: 'Sarah',
      id: 10021,
      last_name: 'Khan',
      public_id: 'b232-16b88aeb8c37',
      availability: Candidate_Availability_Choices_Enum.InterestedAndAvailableNow,
      email: 'sarah+firebasetest@gmail.com',
      linkedin_url: null,
      resume_filename: null,
      profile_flow_started_at: null,
      profile_flow_furthest_step: null,
      __typename: 'candidate',
    },
    created_at: '2021-10-13T12:45:21.743374+00:00',
    email: 'sarah+firebasetest@gmail.com',
    firebase_uid: 'cat22211tree',
    first_name: 'Sarah',
    id: 2738,
    last_name: 'Khan',
    name: 'Sarah Khan',
    organization: 1,
    photo_url: null,
    skip_email_verify: true,
    candidate_skip_email_verify: false,
    updated_at: '2021-10-13T12:55:49.067344+00:00',
    __typename: 'user_info',
    user_roles: roles.map((role) => ({ role: { value: role } })),
    client_prospect: null,
    organization_data: null,
    ...overwrite,
  };
}

function createFixture_authDispatch_pending(): AsyncAction<AuthData> {
  return {
    type: 'pending',
  };
}

function createFixture_authResolvedDispatch(
  overwrite: {
    roles: string[];
    isAuthenticated: boolean;
    isEmailVerified: boolean | null;
    skipEmailVerify: boolean;
  } & Partial<AsyncAction<AuthData>['data']>,
): AsyncAction<AuthData> {
  return {
    type: 'resolved',
    data: {
      user: createFixture_firebaseUser(
        {
          skip_email_verify: overwrite.skipEmailVerify,
        },
        overwrite.roles,
      ),
      ...overwrite,
    },
  };
}

function createFixture_authRejectedDispatch(error?: unknown): AsyncAction<AuthData> {
  return {
    type: 'rejected',
    data: {
      isAuthenticated: false,
      isEmailVerified: false,
      roles: [],
      user: null,
    },
    error: error || new Error('fixture auth rejected'),
  };
}

export const authFixture = {
  createDispatch: {
    resolved: createFixture_authResolvedDispatch,
    rejected: createFixture_authRejectedDispatch,
    pending: createFixture_authDispatch_pending,
  },
  createFirebaseUser: createFixture_firebaseUser,
  results: {
    /**
     * @returns
     * ```
     * {
     *    ...
     *    isAuthenticated: true,
     *    isEmailVerified: true,
     *    roles: ['candidate', 'authenticated', 'user'],
     *    skipEmailVerify: true
     * }
     * ```
     */
    'authenticated-candidate-validEmail-dontSkipEmailValidation':
      createFixture_authResolvedDispatch({
        isAuthenticated: true,
        isEmailVerified: true,
        roles: ['candidate', 'authenticated', 'user'],
        skipEmailVerify: false,
      }),
    /**
     * @returns
     * ```
     * {
     *    ...
     *    isAuthenticated: true,
     *    isEmailVerified: false,
     *    roles: ['authenticated', 'user'],
     *    skipEmailVerify: true
     * }
     * ```
     */
    'authenticated-noValidEmail-dontSkipEmailValidation': createFixture_authResolvedDispatch({
      isAuthenticated: true,
      isEmailVerified: false,
      roles: ['authenticated', 'user'],
      skipEmailVerify: false,
    }),

    /**
     * @returns
     * ```
     * {
     *    ...
     *    isAuthenticated: false,
     *    isEmailVerified: null,
     *    roles: [],
     *    user: null,
     * }
     * ```
     */
    anonymous: createFixture_authResolvedDispatch({
      isAuthenticated: false,
      isEmailVerified: null,
      roles: [],
      skipEmailVerify: false,
      user: null,
    }),
  },
};
