import { getBlob } from 'firebase/storage';
import { createStorageRef } from 'global/firebaseApp';
import {
  Box,
  Button,
  DownloadIcon,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  PreferredIcon,
  ShareIcon,
} from '@terminal/design-system';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'talent-hub/app/theme';
import type { serializeJobCandidateProfile } from '../CandidateInformation/JobCandidateProfile.serializer';
import { InvertedCheckboxButton } from '../InvertedCheckboxButton';

export function CandidateResumeViewerModal({
  profile,
  modalUtils: { isOpen, onClose },
  handleResumeLinkClicked,
  onOpenShareWithModal,
  saveCandidateUtils,
}: {
  // TODO: Improve this type - is not the righ type used here
  profile: ReturnType<typeof serializeJobCandidateProfile>['profile'];
  modalUtils: {
    isOpen: boolean;
    onClose: () => void;
  };
  handleResumeLinkClicked: (fileExtension?: string | undefined) => Promise<void>;
  onOpenShareWithModal?: () => void;
  saveCandidateUtils?: {
    handleOnSaveCandidateClick: (e: MouseEvent<HTMLElement>) => Promise<void>;
    isSaved: boolean;
  };
}) {
  const [urlResume, setUrlResume] = useState('');

  useEffect(() => {
    const createURLResume = async function name() {
      if (!profile.resume.remotePath) {
        return null;
      }
      const resumeRef = createStorageRef(profile.resume.remotePath);

      const downloadURL = await getBlob(resumeRef);
      const blobURL = window.URL.createObjectURL(
        new Blob([downloadURL], { type: 'application/pdf' }),
      );

      setUrlResume(blobURL);

      return downloadURL;
    };
    createURLResume();
  }, [profile.resume.remotePath]);

  const handleResumeDownload = useCallback(() => {
    handleResumeLinkClicked('.pdf');
  }, [handleResumeLinkClicked]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="scale"
      size="2xl"
      variant="mySpecialVariant"
      styleConfig={{
        ...theme.components.Modal,
        sizes: {
          ...theme.components.Modal.sizes,
          '2xl': {
            ...theme.components.Modal.sizes['2xl'],
            dialogContainer: {
              ...theme.components.Modal.sizes['2xl'].dialogContainer,
              height: 'calc(100vh - 3px)',
              overflowY: 'hidden',
            },
          },
        },
      }}
    >
      <Box
        position="fixed"
        backgroundColor="red"
        left={0}
        top={0}
        width="100vw"
        zIndex="popover"
        p={0}
      >
        <Flex
          w="full"
          bg="ui.primary"
          justifyContent="space-between"
          alignItems="center"
          h={21}
          px={12}
          py={4}
        >
          <Flex textColor="text.inverse" flexDir="column" justifyContent="left">
            <Button
              onClick={() => {
                onClose();
              }}
              variant="ghost"
              zIndex="tooltip"
              textColor="inherit"
              p={0}
              m={0}
              w="fit-content"
              justifyContent="flex-start"
              fontWeight="normal"
              h="fit-content"
              sx={{
                _hover: {
                  bg: 'initial',
                },
              }}
            >
              Back to Profile
            </Button>
            <Heading variant="heading-2" textColor="inherit">
              {profile.firstName}&apos;s Resume
            </Heading>
          </Flex>

          <Flex gridGap={4}>
            <Button
              onClick={handleResumeDownload}
              variant="outline"
              borderColor="text.inverse"
              minW="fit-content"
              p={4}
              sx={{
                _hover: {
                  bg: 'initial',
                },
              }}
            >
              <DownloadIcon color="text.inverse" fontSize="lg" />
            </Button>
            {saveCandidateUtils && (
              <InvertedCheckboxButton
                variant="outline"
                borderColor="text.inverse"
                minW="fit-content"
                p={4}
                onClick={async (e: MouseEvent<HTMLElement>) => {
                  saveCandidateUtils.handleOnSaveCandidateClick(e);
                }}
                checkedIcon={
                  <PreferredIcon
                    color="text.inverse"
                    sx={{
                      '& path': {
                        fill: '#EFD58B',
                        stroke: 'brand.darkest',
                      },
                    }}
                  />
                }
                unCheckedIcon={<PreferredIcon />}
                isChecked={saveCandidateUtils.isSaved}
              />
            )}

            {onOpenShareWithModal && (
              <Button
                onClick={onOpenShareWithModal}
                variant="outline"
                borderColor="text.inverse"
                minW="fit-content"
                p={4}
                sx={{
                  _hover: {
                    bg: 'initial',
                  },
                }}
              >
                <ShareIcon color="text.inverse" fontSize="lg" />
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
      {/* TODO: [TAl-949] Replace this colors once the UI-primary-darker and UI-primary-lighter color tokens are created */}
      <ModalOverlay bg="#25383FCC" />

      <ModalContent mt={28} zIndex="popover" h="inherit" mb={1} pb={28}>
        <ModalBody>
          <object
            width="100%"
            height="100%"
            data={`${urlResume}#toolbar=0&navpanes=0`}
            type="application/pdf"
          >
            <p>Pdf cannot be displayed, please try downloading it.</p>
          </object>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
