import { useLayoutEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { Box } from '@terminal/design-system';
import type { Candidate_Curation_Years_Of_Exp_Range_Choices_Enum } from 'global/types';
import { useQuery } from 'global/utils';
import {
  ExploreCandidates,
  useExploreCandidatesController,
  candidateSearchDateRange,
  candidateLimit,
  useShareCandidates,
  ViewType,
  useSelectExploreCandidateFilterOptions,
  useExploreCandidateQueryParams,
} from 'talent-hub/shared/features/explore-candidates';
import type {
  SelectBrowseCandidatesQuery,
  SelectBrowseCandidatesQueryVariables,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/data';
import { SelectBrowseCandidates } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/data';
import {
  serializeCandidateBrowse,
  sortOptionBackendValues,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';
import { CandidateProfileController } from '../candidate-profile';

// TODO: Rename this to BrowseCandidatesController
export function ExploreCandidatesController() {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const history = useHistory();

  useLayoutEffect(() => {
    // ! Since this page has multiples routes at same page ScrollToTopOnPathChange is not working properly, so we need to scroll up manually
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);

  const explore_candidate_filter_options = useSelectExploreCandidateFilterOptions();

  const { search_query_args, form_initial_values } = useExploreCandidateQueryParams({
    skills: explore_candidate_filter_options.serialized_data.skills,
  });

  const { topCandidatesCriteria } = useTalentHubFlags();

  const browseCandidatesQuery_defaultVariables = useMemo(() => {
    const candidate_browse_args: SelectBrowseCandidatesQueryVariables = {
      limit: candidateLimit,
      offset: 0,
      candidate_browse_args: {
        organization_id: viewingOrganization.ID,
        candidate_role: null,
        must_have_skill_ids: [],
        should_have_skill_ids: [],
        countries: [],
        min_years_of_experience: null,
        sort_option: sortOptionBackendValues.Recommended,
        top_candidates_criteria: topCandidatesCriteria,
        employment_type: null,
        badges: null,
      },
      organization: viewingOrganization.ID,
      user_id: user?.id,
      updatedAfter: candidateSearchDateRange,
      isClientProspect,
    };

    return candidate_browse_args;
  }, [isClientProspect, topCandidatesCriteria, user?.id, viewingOrganization.ID]);

  const {
    loading: loadingCandidates,
    data,
    refetch,
    fetchMore,
    networkStatus,
    client,
    variables,
  } = useQuery<SelectBrowseCandidatesQuery, SelectBrowseCandidatesQueryVariables>(
    SelectBrowseCandidates,
    {
      variables: {
        ...browseCandidatesQuery_defaultVariables,
        candidate_browse_args: {
          ...browseCandidatesQuery_defaultVariables.candidate_browse_args,
          ...search_query_args,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isFilterSelected =
    /* eslint-disable eqeqeq */
    browseCandidatesQuery_defaultVariables.candidate_browse_args.candidate_role !=
      variables?.candidate_browse_args.candidate_role ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.min_years_of_experience !=
      variables?.candidate_browse_args.min_years_of_experience ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.employment_type !=
      variables?.candidate_browse_args.employment_type ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.regions !=
      variables?.candidate_browse_args.regions ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.countries !=
      variables?.candidate_browse_args.countries ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.badges !=
      variables?.candidate_browse_args.badges ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.must_have_skill_ids?.length !=
      variables?.candidate_browse_args.must_have_skill_ids?.length ||
    browseCandidatesQuery_defaultVariables.candidate_browse_args.should_have_skill_ids?.length !=
      variables?.candidate_browse_args.should_have_skill_ids?.length;
  /* eslint-enable */

  const {
    exploreCandidates: {
      onCloseCandidateProfileClick,
      refetchCandidates,
      setInfiniteScrollRef,
      searches,
    },
    saveCandidate: {
      savedByMeCandidatesCount,
      sharedWithMeCandidatesCount,
      handleOnRemoveSaveCandidate,
      handleOnSaveCandidate,
    },
  } = useExploreCandidatesController({
    browseCandidates: {
      refetch,
      loadingCandidates,
      fetchMore,
      networkStatus,
      client,
      data,
      defaultVariables: browseCandidatesQuery_defaultVariables,
    },
    filter_options: {
      employmentType: explore_candidate_filter_options.serialized_data.employmentType,
      yearsOfExperience: explore_candidate_filter_options.serialized_data.yearsOfExperience,
      popularSearches: explore_candidate_filter_options.serialized_data.popularSearches,
      savedSearches: explore_candidate_filter_options.serialized_data.savedSearches,
    },
  });

  const selectedYoE = variables?.candidate_browse_args
    .min_years_of_experience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum;
  const skillsToSearch = variables?.candidate_browse_args.must_have_skill_ids;

  const {
    onCloseShareWithModal,
    onOpenShareWithModal,
    isShareWithModalOpen,
    onInsertSharedCandidate,
    teamMembers,
  } = useShareCandidates();

  // ! Refactor the serializers to be smallers, to be able to compose each one using small serializers ! warning: Easly to create early abstractions
  const {
    candidates,
    otherCandidates,
    highlightedCandidates,
    highlightedCandidatesTitle,
    highlightedCandidatesSubtitle,
  } = serializeCandidateBrowse({
    data,
    skillsToSearch,
    selectedYoE,
    highlightBadgeType: topCandidatesCriteria,
    isFilterSelected,
    isSortByRecommended:
      browseCandidatesQuery_defaultVariables.candidate_browse_args.sort_option ===
      variables?.candidate_browse_args.sort_option,
    isLoadingCandidates: networkStatus === NetworkStatus.refetch ? false : loadingCandidates,
  });

  return (
    <>
      <ExploreCandidates
        filterOptions={{
          ...explore_candidate_filter_options.serialized_data,
          isLoading: explore_candidate_filter_options.loading,
        }}
        searches={searches}
        // When we do a refetch after deleting/adding a favorite candidate, we dont want to show the loading state again
        loadingCandidates={networkStatus === NetworkStatus.refetch ? false : loadingCandidates}
        networkStatus={networkStatus}
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        refetchCandidates={refetchCandidates}
        onBeforeClearAllFilters={() => {
          history.replace({ pathname: '/explore-candidates', search: '' });
        }}
        candidates={candidates}
        initialValues={form_initial_values}
        quantityResults={data?.candidate_browse?.[0]?.total_count || 0}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        sharedCandidateWithProps={{
          teamMembers,
          handleInsertSharedWith: onInsertSharedCandidate,
          isShareWithModalOpen,
          onOpenShareWithModal,
          onCloseShareWithModal,
        }}
        selectedYoE={selectedYoE}
        otherCandidates={otherCandidates}
        highlightedCandidates={highlightedCandidates}
        highlightedCandidatesTitle={highlightedCandidatesTitle}
        highlightedCandidatesSubtitle={highlightedCandidatesSubtitle}
        isFilterSelected={isFilterSelected}
      />
      {/* Div used as target to observe in infinite scroll feature */}
      {candidates?.length && <Box ref={setInfiniteScrollRef} height={4} />}
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={onCloseCandidateProfileClick}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        viewPage={ViewType.SEARCH}
      />
    </>
  );
}
