import { Job_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { createDaysAgoDates, toHasuraDate } from 'global/utils';
import { JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { SelectRoleDataFixture } from '../../../data';
import type { ManageRolesQuery, OverviewPendingReviewRoles } from './graphql';

const daysToGo = createDaysAgoDates([3, 100]);
function createJob(overwrite?: Partial<OverviewPendingReviewRoles>): OverviewPendingReviewRoles {
  return {
    title: 'Professional Sleeper',
    id: 1,
    status: Job_Status_Choices_Enum.Submitted,
    updated_at: toHasuraDate('2022-09-29'),
    job_positions_aggregate: {
      aggregate: {
        count: 1,
      },
    },
    job_acceptable_locations: [
      {
        location: {
          value: 'Colombia',
        },
      },
    ],
    ...overwrite,
  };
}
function createManageRoles(overwrite?: Partial<ManageRolesQuery>): ManageRolesQuery {
  return {
    job: [
      createJob({
        title: 'Professional Sleeper',
        id: 1,
        status: Job_Status_Choices_Enum.Submitted,
        updated_at: toHasuraDate('2022-09-29'),
        job_positions_aggregate: {
          aggregate: {
            count: 1,
          },
        },
        job_acceptable_locations: [
          {
            location: {
              value: 'Remote, Canada',
            },
          },
        ],
      }),
    ],
    active_postings: [
      SelectRoleDataFixture.createPosting({
        id: 10,
        created_at: daysToGo[3],
        locations: [
          {
            icims_location: {
              value: 'Remote, US',
              readable_locations: [],
            },
          },
        ],
      }),
      SelectRoleDataFixture.createPosting({
        id: 11,
        name: 'Business Development Representative',
        initial_headcount: 1,
        created_at: daysToGo[100],
        applicant_workflows: [
          SelectRoleDataFixture.results.applicantWorkflow.inProgressSubmission_candidate(
            {},
            daysToGo[3],
          ),
        ],
      }),
      SelectRoleDataFixture.createPosting({
        id: 12,
        name: 'Diagnostic radiographer',
        initial_headcount: 4,
        created_at: daysToGo[100],
      }),
    ],
    filled_postings: [
      {
        ...SelectRoleDataFixture.createPosting({
          created_at: daysToGo[3],
          locations: [
            {
              icims_location: {
                value: 'Remote, US',
                readable_locations: [],
              },
            },
          ],
        }),
        filled_positions_aggregate: {
          __typename: 'icims_job_aggregate',
          total: { hires: 2 },
        },
        positions: [
          SelectRoleDataFixture.createPostingPosition({
            id: 100,
            icims_folder: { value: JOB_FOLDER_FILLED },
          }),
        ],
      },
    ],
    members: [],
    ...overwrite,
  };
}

// TODO: Rename the fixture to match the its corresponding query name (query to be renamed to SelectManageRoles)
// and follow fixture convention. For example, the main fixture should be assinged to the key "createData"
export const ManageRolesFixture = {
  createData: createManageRoles,
  /** @deprecated Use `createData` instead */
  createManageRoles,
  createJob,
  createPosting: SelectRoleDataFixture.createPosting,
  createPostingPosition: SelectRoleDataFixture.createPostingPosition,
  createPerson: SelectRoleDataFixture.createPerson,
  createPosting_applicantWorkflow_statuses:
    SelectRoleDataFixture.createPosting_applicantWorkflow_statuses,
  createPosting_applicantWorkflow: SelectRoleDataFixture.createPosting_applicantWorkflow,
};
