import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import {
  Center,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Badge,
  CoffeeCupIcon,
  Button,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader, TableContentLoading } from 'talent-hub/components';
import { JobHealthStatus } from 'talent-hub/constants';
import { readableLocationOr } from 'talent-hub/utils';

import type { PostingWithTotalsAndHealthStatus } from '../../ManageRoles.serializer';

function JobListCard({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardHeader>
        <Heading variant="heading-3" color="brand.darker">
          Roles Currently Hiring
        </Heading>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

export function JobList({
  isLoading,
  jobs,
}: {
  isLoading: boolean;
  jobs: PostingWithTotalsAndHealthStatus[];
}) {
  if (isLoading)
    return (
      <JobListCard>
        <TableContainer whiteSpace="break-spaces">
          <Table>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Date opened</Th>
                <Th>Days open</Th>
                <Th>Positions open</Th>
                <Th>Candidates active</Th>
                <Th>Current status</Th>
              </Tr>
            </Thead>
            <Tbody>
              <TableContentLoading columns={6} rows={3} />
            </Tbody>
          </Table>
        </TableContainer>
      </JobListCard>
    );

  if (!jobs?.length)
    return (
      <JobListCard>
        <Center alignItems="center" flexDirection="column">
          <Heading variant="heading-2" mt={4}>
            No Open Roles
          </Heading>
          <CoffeeCupIcon h={20} w={20} mt="6" />
          <Text mt={2} maxW={['90%', '50%']} textAlign="center">
            New roles can be opened using the Open New Role button at the top of the page, or by
            clicking below.
          </Text>
          <Button variant="solid" mt={6} colorScheme="primary" to="/role/create" as={RouterLink}>
            Open New Role
          </Button>
        </Center>
      </JobListCard>
    );

  const jobBadgeFriendlyStatusMap = {
    [JobHealthStatus.Hired]: { copy: 'On Track', color: 'success' },
    [JobHealthStatus.OnTrack]: { copy: 'On Track', color: 'success' },
    [JobHealthStatus.WarningHighRejectionRate]: { copy: 'Behind', color: 'warning' },
    [JobHealthStatus.WarningLate]: { copy: 'Behind', color: 'warning' },
    [JobHealthStatus.OnHold]: { copy: 'On Hold', color: 'disabled' },
    [JobHealthStatus.Paused]: { copy: 'Paused', color: 'disabled' },
  } as const;

  return (
    <JobListCard>
      <TableContainer whiteSpace="break-spaces">
        <Table>
          <Thead>
            <Tr>
              <Th>Role</Th>
              <Th>Date opened</Th>
              <Th>Days open</Th>
              <Th>Positions open</Th>
              <Th>Candidates active</Th>
              <Th>Current status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {jobs.map(
              ({
                id,
                name,
                locations,
                openedDate,
                totalActiveCandidates,
                openHeadCount,
                healthStatus,
              }) => {
                const location = locations
                  .map((loc) =>
                    loc.icims_location
                      ? readableLocationOr(
                          loc.icims_location.readable_locations,
                          loc.icims_location?.value || '',
                        )
                      : '',
                  )
                  .sort()
                  .join('; ');

                return (
                  <Tr key={id}>
                    <Td>
                      <Link variant="label" to={`/role/${id}`} as={RouterLink}>
                        {name}
                      </Link>
                      <Text variant="caption" mt={1}>
                        {location}
                      </Text>
                    </Td>
                    <Td>{openedDate}</Td>
                    <Td isNumeric>{moment().diff(moment(openedDate), 'days')}</Td>
                    <Td isNumeric>{openHeadCount}</Td>
                    <Td isNumeric>{totalActiveCandidates}</Td>
                    <Td>
                      <Badge
                        colorScheme={jobBadgeFriendlyStatusMap[healthStatus].color}
                        fontSize="sm"
                      >
                        {jobBadgeFriendlyStatusMap[healthStatus].copy}
                      </Badge>
                    </Td>
                  </Tr>
                );
              },
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </JobListCard>
  );
}
