import { FormControl, Checkbox } from '@terminal/design-system';

export function ModalCandidateSubmissionCheckbox({
  name,
  value,
  handleChange,
  setFieldValue,
  children,
}: {
  name: string;
  value: boolean;
  handleChange: () => void;
  setFieldValue: (name: string, value: boolean) => void;
  children: React.ReactNode;
}) {
  const listenForEnter = (e: any) => {
    if (e.key === 'Enter') {
      setFieldValue(name, !value);
      // Prevent enter from bubbling up
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <FormControl id={`candidateSubmission.${name}`} aria-checked={value}>
      <Checkbox
        name={name}
        onChange={handleChange}
        onKeyDown={(e) => listenForEnter(e)}
        isChecked={value}
      >
        {children}
      </Checkbox>
    </FormControl>
  );
}
