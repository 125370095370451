/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { PasswordResetPage } from './PasswordResetPage';
import { PasswordResetCodeExpiredPage } from './PasswordResetCodeExpiredPage';

export default {
  title: 'Global/Pages/Auth/Password Reset',
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template_PasswordResetPage: StoryFn<ComponentProps<typeof PasswordResetPage>> = (args) => (
  <PasswordResetPage {...args} />
);

export const story_PasswordResetPage_unfilled = Template_PasswordResetPage.bind({} as {});
story_PasswordResetPage_unfilled.args = {
  isLoading: false,
};
story_PasswordResetPage_unfilled.storyName = 'Unfilled';

export const story_PasswordResetPage_loading = Template_PasswordResetPage.bind({} as {});
story_PasswordResetPage_loading.args = {
  isLoading: true,
};
story_PasswordResetPage_loading.storyName = 'Unfilled - loading';

const Template_PasswordResetCodeExpiredPage: StoryFn<
  ComponentProps<typeof PasswordResetCodeExpiredPage>
> = (args) => <PasswordResetCodeExpiredPage {...args} />;

export const story_PasswordResetCodeExpiredPage_expired =
  Template_PasswordResetCodeExpiredPage.bind({} as {});
story_PasswordResetCodeExpiredPage_expired.args = {};
story_PasswordResetCodeExpiredPage_expired.storyName = 'Code expired';
