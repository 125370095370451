import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import { Candidate_Curation_Desired_Salary_Currency_Choices_Enum } from 'global/types/hasura-tables.generated.types';

// Get the values of an enum dynamically https://dev.to/prod/mastering-enums-in-typescript-1c1j#enum-get-values
type candidateValues = `${Candidate_Curation_Desired_Salary_Currency_Choices_Enum}`;
const candidateCurrencies: candidateValues[] = Object.values(
  Candidate_Curation_Desired_Salary_Currency_Choices_Enum,
);

const currencyList = [
  ...candidateCurrencies,
  'AUD',
  'CNY',
  'GBP',
  'HKD',
  'INR',
  'JPY',
  'NGN',
  'RON',
  'SGD',
  'ZAR',
  'HUF',
] as const;

export type AvailableCurrency = (typeof currencyList)[number];

export function formatAsCurrency(
  number: number,
  currency: AvailableCurrency,
  {
    withCurrencyPrefix = false,
    showformatAsSimpleCurrency = false,
    ...numberFormatPropsOverride
  }: {
    withCurrencyPrefix?: boolean;
    showformatAsSimpleCurrency?: boolean;
  } & Intl.NumberFormatOptions = {},
) {
  const validCurrency = currency && currencyList.includes(currency) ? currency : 'USD';

  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: withCurrencyPrefix || showformatAsSimpleCurrency ? 'narrowSymbol' : 'symbol', // If displaying prefix, display as COP $100 vs COP COP100
    minimumFractionDigits: 0,
    currency: validCurrency,
    ...numberFormatPropsOverride,
  }).format(number);

  return withCurrencyPrefix ? `${validCurrency} ${amount}` : amount;
}

export function convertCurrencyToUSD(
  value: number,
  currentCurrency: AvailableCurrency,
  conversionTable: Array<{
    iso_code: Candidate_Curation_Desired_Salary_Currency_Choices_Enum;
    live_exchange_rate: any;
  }>,
) {
  if (currentCurrency === Candidate_Curation_Desired_Salary_Currency_Choices_Enum.Usd) return value;

  const exchangeRate = conversionTable.find((el) => el.iso_code === currentCurrency);
  if (exchangeRate && !Number.isNaN(exchangeRate.live_exchange_rate)) {
    return value / Number(exchangeRate.live_exchange_rate);
  }

  return 0;
}
