/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { ForgotPasswordFormPage } from './ForgotPasswordFormPage';
import { ForgotPasswordSuccessPage } from './ForgotPasswordSuccessPage';

export default {
  title: 'Global/Pages/Auth/Forgot Password',
  component: ForgotPasswordFormPage,
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template_ForgotPasswordFormPage: StoryFn<ComponentProps<typeof ForgotPasswordFormPage>> = (
  args,
) => <ForgotPasswordFormPage {...args} />;

export const story_ForgotPasswordFormPage_unfilled = Template_ForgotPasswordFormPage.bind({} as {});
story_ForgotPasswordFormPage_unfilled.args = {
  isLoading: false,
};
story_ForgotPasswordFormPage_unfilled.storyName = 'Unfilled';

export const story_ForgotPasswordFormPage_loading = Template_ForgotPasswordFormPage.bind({} as {});
story_ForgotPasswordFormPage_loading.args = {
  isLoading: true,
};
story_ForgotPasswordFormPage_loading.storyName = 'Loading';

const Template_ForgotPasswordSuccessPage: StoryFn<
  ComponentProps<typeof ForgotPasswordSuccessPage>
> = (args) => <ForgotPasswordSuccessPage {...args} />;

export const story_ForgotPasswordSuccessPage_success = Template_ForgotPasswordSuccessPage.bind(
  {} as {},
);
story_ForgotPasswordSuccessPage_success.args = {};
story_ForgotPasswordSuccessPage_success.storyName = 'Success';
