import {
  Flex,
  Box,
  Button,
  Text,
  Heading,
  Image,
  Center,
  Grid,
  LinkoutIcon,
  ArrowRightIcon,
  useMediaQuery,
} from '@terminal/design-system';
import type { useCalendlyEventListener } from 'react-calendly';
import { Logo as TerminalLogo } from 'global/components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import { CalendlyWidget } from 'talent-hub/role/prospect/components/CalendlyWidget';
import clientsLogosImageSrc from '../../assets/clientsLogos.svg?url';
import backgroundImg from '../../assets/background.svg?url';

export function BookCall({
  prospectID,
  onProfilePageViewed,
  onDateAndTimeSelected,
  onEventTypeViewed,
  onEventScheduled,
  campaignInfoInSession,
  onClickSignUp,
}: {
  prospectID?: number;
  campaignInfoInSession: {
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_term?: string;
  };
  onClickSignUp: () => void;
} & Parameters<typeof useCalendlyEventListener>[number]) {
  // The iframe viewport is based on the container width. So, this might change if the layout changes.
  const [isIframeOnXSViewport, isIframeOnDesktopViewport] = useMediaQuery([
    '(max-width: 649px)',
    '(min-width: 1000px)',
  ]);

  return (
    <Flex
      flexDir="column"
      w="full"
      role="main"
      as="main"
      minH={['unset', 'unset', '100vh']}
      bg="bg.tertiary"
      pb={[4, 4, 0]}
    >
      <Grid
        templateColumns="repeat(2, 1fr)"
        position={['relative', 'relative', 'absolute']}
        w="full"
        px={[3, 3, 8]}
        py={[3, 3, 5]}
        bg={['bg.tertiary', 'bg.tertiary', 'none']}
      >
        <Flex alignItems="center" zIndex={1}>
          <Box height={['1.875rem', 10, '2.375rem']}>
            <TerminalLogo />
          </Box>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end">
          <Button
            variant="ghost"
            to="/auth/login"
            as={RouterLink}
            colorScheme="accent"
            rightIcon={<LinkoutIcon />}
          >
            Login
          </Button>
        </Flex>
      </Grid>

      <Box
        display={['none', 'none', 'block']}
        backgroundImage={backgroundImg}
        pointerEvents="none"
        backgroundRepeat="no-repeat"
        position={['fixed', 'fixed', 'absolute']}
        height="full"
        width="95%"
        bottom={0}
        left={0}
        backgroundPosition={['0 100%', '0 100%', 'left bottom']}
      />
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        w="full"
        minH={['unset', 'unset', '100vh']}
        my={[0, 0, '6.25rem']}
      >
        <Heading
          variant="heading-2"
          textColor="text.primary"
          textAlign="center"
          maxW="50rem"
          mt={[6, 6, 0]}
        >
          Chat With Our Experts{' '}
          <span aria-label="rocket" role="img">
            🚀
          </span>
        </Heading>
        <Text textColor="text.primary" textAlign="center" mt={2} px={[6, 8, 2]} maxW="50rem">
          Based on the role you&apos;re hiring for we think it makes sense to talk to an expert
          first.
        </Text>

        <Button colorScheme="primary" mt={4} variant="ghost" zIndex={20} onClick={onClickSignUp}>
          Skip for now and see candidates
          <ArrowRightIcon ml={3} />
        </Button>
        <Flex
          alignItems="center"
          w="full"
          bgColor="bg.primary"
          mt={isIframeOnXSViewport ? 4 : -16}
          mb={isIframeOnXSViewport ? -22 : 0}
          bg="bg.tertiary"
        >
          <CalendlyWidget
            styles={{
              width: '100%',
              height: isIframeOnDesktopViewport ? '43rem' : '70rem',
              zIndex: 10,
            }}
            onProfilePageViewed={onProfilePageViewed}
            onDateAndTimeSelected={onDateAndTimeSelected}
            onEventTypeViewed={onEventTypeViewed}
            onEventScheduled={onEventScheduled}
            utm={{
              utmCampaign: campaignInfoInSession.utm_campaign,
              utmSource: campaignInfoInSession.utm_source,
              utmMedium: campaignInfoInSession.utm_medium,
              utmTerm: campaignInfoInSession.utm_term,
              // ! By Jonathan's request, this should not be overwritten.
              utmContent: `prospect:${prospectID}`,
            }}
          />
        </Flex>
        <Center mt={[0, 0, 14]}>
          <Image src={clientsLogosImageSrc} alt="Terminal's client logos" />
        </Center>
        <Text
          mt={3}
          variant="caption"
          color="text.secondary"
          fontWeight="bold"
          textAlign="center"
          px={12}
          maxW="25rem"
        >
          Join Calendly and other top companies that use Terminal for their hiring needs
        </Text>
      </Flex>
    </Flex>
  );
}
