import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { useIntersectionObserver } from 'global/hooks';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@terminal/design-system';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import type { AcceptTermsOfUseMutation, AcceptTermsOfUseMutationVariables } from './data';
import { AcceptTermsOfUse } from './data';

const validationSchema = Yup.object().shape({
  acceptTermsOfUse: Yup.boolean()
    .required(
      'You must accept the Terms of Use to access Talent Hub. Please review and agree to continue.',
    )
    .oneOf(
      [true],
      'You must accept the Terms of Use to access Talent Hub. Please review and agree to continue.',
    ),
  hasScrolledToEnd: Yup.boolean()
    .required(
      'To proceed, you must read the entire Terms of Use by scrolling down to the very end.',
    )
    .oneOf(
      [true],
      'To proceed, you must read the entire Terms of Use by scrolling down to the very end.',
    ),
});

export function TermsOfUseModal() {
  const { user } = useCustomerAuthorizedUserSession();

  const toast = useToast({
    position: 'top',
    duration: 4000,
    containerStyle: {
      maxWidth: '100%',
    },
  });

  const [acceptTermsOfUse] = useMutation<
    AcceptTermsOfUseMutation,
    AcceptTermsOfUseMutationVariables
  >(AcceptTermsOfUse, {
    variables: {
      userID: user.id,
    },
    onError: () => {
      toast({
        title: 'Terms of Use Acceptance Failed',
        description: 'Please try again.',
        status: 'error',
      });
    },
    onCompleted: () => {
      toast({
        title: 'Terms of Use Accepted',
        description: 'You can now proceed.',
        status: 'success',
      });
    },
  });

  const [bottomOfTermsRef, setbottomOfTermsRef] = useState<HTMLSpanElement | null>(null);
  const entry = useIntersectionObserver(bottomOfTermsRef);

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useCustomFormik({
    initialValues: {
      acceptTermsOfUse: false,
      hasScrolledToEnd: false,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {
      if (values.acceptTermsOfUse && values.hasScrolledToEnd) {
        acceptTermsOfUse();
        setSubmitting(false);
        return;
      }

      toast({
        title: 'Action Required',
        description: 'Please read and accept the Terms of Use to continue.',
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (!entry?.isIntersecting || values.hasScrolledToEnd) return;
    setFieldValue('hasScrolledToEnd', true);
  }, [entry?.isIntersecting, setFieldValue, values.hasScrolledToEnd]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen
      // * This modal cannot be closed, so onClose is a no-op
      onClose={() => {}}
      size="3xl"
      isCentered
    >
      {/* TODO: [TAl-949] Replace this colors once the UI-primary-darker and UI-primary-lighter color tokens are created */}
      <ModalOverlay bg="#25383FCC" />

      <ModalContent zIndex="popover">
        <ModalHeader bgColor="bg.primary" px={6} pb={0}>
          <Heading variant="heading-2">Terms of Use</Heading>
          <Heading variant="heading-4" color="text.secondary">
            Effective date: November 14, 2024
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text mb={6}>
            Welcome to Talent Hub! To proceed, please review and accept our Terms of Use.
          </Text>
          <Box
            px={4}
            py={2}
            maxHeight={[40, 80]}
            overflow="auto"
            __css={{
              textAlign: 'justify',
              fontFamily: "'Times New Roman',serif",
              '&::-webkit-scrollbar-track': {
                background: 'brand.lightest',
                borderRadius: 'xl',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'brand.darker',
                borderRadius: 'xl',
              },
              '&::-webkit-scrollbar': {
                width: 2,
              },
              '& p,': {
                marginBottom: 4,
              },
              '& h3': {
                marginBottom: 2,
              },
            }}
          >
            <p style={{ textAlign: 'center' }}>
              <strong>
                <u>
                  <span>TERMINAL, INC.</span>
                </u>
              </strong>
            </p>
            <p style={{ textAlign: 'center' }}>
              <strong>
                <u>
                  <span>WEBSITE TERMS OF USE&nbsp;</span>
                </u>
              </strong>
            </p>
            <p>
              <span>
                The following terms and conditions (the &ldquo;Agreement&rdquo;) govern all use of
                the hub.terminal.io website (the &ldquo;Site&rdquo;). &nbsp;The Site is owned and
                operated by Terminal, Inc. (&ldquo;Terminal&rdquo;). &nbsp;Access to the Site is
                offered subject to your (and the entity or company you represent) (the
                &ldquo;User&rdquo;) acceptance without modification of all of the terms and
                conditions contained herein and all other operating rules, policies and procedures
                that may be published from time to time on the Site by Terminal. &nbsp;BY USING OR
                ACCESSING ANY PART OF THE SITE, YOU&nbsp;
              </span>
              <u>
                <em>
                  <span>AND THE ENTITY AND COMPANY YOU REPRESENT</span>
                </em>
              </u>
              <span>
                &nbsp;AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN; IF YOU NOT AGREE,
                DO NOT USE OR ACCESS THE SITE.&nbsp;
              </span>
            </p>
            <p>
              <span>
                Terminal reserves the right, at its sole discretion, to modify or replace any of the
                terms or conditions of this Agreement at any time. &nbsp;It is User&rsquo;s
                responsibility to check this Agreement periodically for changes. &nbsp;User&rsquo;s
                continued use of the Site following the posting of any changes to this Agreement
                constitutes acceptance of those changes.&nbsp;
              </span>
            </p>
            <p>
              <span>
                To the extent User and Terminal have executed a commercial or master agreement (such
                as a subscription agreement) for access to any of Terminal&rsquo;s products or
                services, such agreement will control.
              </span>
            </p>
            <ol style={{ marginLeft: 12 }}>
              <li>
                <h3>
                  <span>&nbsp;PURPOSE; NON-SOLICITATION.</span>
                </h3>
                <p>
                  <span>
                    User has a non-exclusive right to access the Site solely for User&rsquo;s own
                    personal use in helping evaluate if User wants to engage in a commercial
                    relationship with Terminal (&ldquo;Purpose&rdquo;). &nbsp; &nbsp;
                  </span>
                </p>
                <p>
                  <span>
                    The Site will provide User with access to information regarding individuals that
                    can be engaged through Terminal to provide services to User (&ldquo;Engagement
                    Information&rdquo;). User acknowledges that Terminal expends considerable time
                    and resources to source and provide such Engagement Information and that
                    misappropriation or misuse of such information would cause Terminal significant
                    damage. Such information will be treated at Terminal&rsquo;s Confidential
                    Information (as defined and described in Section 4 below) and will not be used
                    for any purpose other than the Purpose. User will not use such information to
                    help User solicit or otherwise engage any such individual or otherwise to
                    circumvent any relationship with Terminal. In addition, during the term of this
                    Agreement and for one (1) year after User last ceases to use the Site, if User
                    hires or otherwise engages any such individual, User will pay Terminal, without
                    limiting Terminal&rsquo;s other rights or remedies, User will promptly pay
                    Terminal $50,000. User agrees such amounts is not a penalty but, rather, is
                    reasonable in light of the actual and anticipated damages Terminal will suffer.
                    Such amounts will be payable on invoice. Late payments will be subject to a
                    finance charge of 1.5% for each thirty (30) days late (plus all costs of
                    collection, including, without limitation, attorneys&rsquo; fees). The foregoing
                    restrictions will not apply to the extent any individual is hired based on
                    general public job postings and User can clearly demonstrate was not
                    specifically targeted based the Engagement Information.&nbsp;
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>SITE CONTENT.&nbsp;</span>
                </h3>
                <p>
                  <span>
                    User agrees that all content (including, without limitation, Engagement
                    Information) and materials delivered via the Site or otherwise made available by
                    Terminal at the Site (collectively, &ldquo;Content&rdquo;) are protected by
                    copyrights, trademarks, service marks, patents, trade secrets or other
                    proprietary rights and laws. &nbsp;Except as expressly authorized by Terminal in
                    writing, User agrees not to sell, license, rent, modify, distribute, copy,
                    reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or
                    create derivative works from such materials or content. &nbsp;However, User may
                    print or download a reasonable number of copies of the materials or content on
                    the Site for User&rsquo;s own informational purposes;&nbsp;
                  </span>
                  <u>
                    <em>
                      <span>provided</span>
                    </em>
                  </u>
                  <span>
                    , that User retain all copyright and other proprietary notices contained
                    therein. &nbsp;Reproducing, copying or distributing any content, materials or
                    design elements on the Site for any other purpose is strictly prohibited without
                    the express prior written permission of Terminal. &nbsp;
                  </span>
                </p>
                <p>
                  <span>
                    Use of Content for any purpose not expressly permitted in this Agreement is
                    prohibited. &nbsp;Any rights not expressly granted herein are reserved.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>RESTRICTIONS.</span>
                </h3>
                <p>
                  <span>
                    User shall not submit any information to the Site that is false, misleading or
                    inaccurate.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not use any &ldquo;deep-link&rdquo;, &ldquo;page-scrape&rdquo;,
                    &ldquo;robot&rdquo;, &ldquo;spider&rdquo; or other automatic device, program,
                    algorithm or methodology, or any similar or equivalent manual process, to
                    access, acquire, copy or monitor any portion of the Site or any Content, or in
                    any way reproduce or circumvent the navigational structure or presentation of
                    the Site or any Content, to obtain or attempt to obtain any materials, documents
                    or information through any means not purposely made available through the Site.
                    &nbsp;Terminal reserves the right to bar any such activity.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not attempt to gain unauthorized access to any portion or feature of
                    the Site, or any other systems or networks connected to the Site or to any
                    Terminal server, by hacking, password &ldquo;mining&rdquo;, or any other
                    illegitimate means.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not probe, scan or test the vulnerability of the Site or any network
                    connected to the Site, nor breach the security or authentication measures on the
                    Site or any network connected to the Site. &nbsp;User shall not reverse look-up,
                    trace or seek to trace any information on any other user of or visitor to the
                    Site, or any other customer of Terminal, including any Terminal account not
                    owned by User, to its source, or exploit the Site or any service or information
                    made available or offered by or through the Site, in any way where the purpose
                    is to reveal any information, including but not limited to personal
                    identification or information, other than User&rsquo;s own information, as
                    provided for by the Site.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not take any action that imposes an unreasonable or
                    disproportionately large load on the infrastructure of the Site or
                    Terminal&rsquo;s systems or networks, or any systems or networks connected to
                    the Site or to Terminal.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not use any device, software or routine to interfere or attempt to
                    interfere with the proper working of the Site or any transaction being conducted
                    on the Site, or with any other person&rsquo;s use of the Site.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not forge headers or otherwise manipulate identifiers in order to
                    disguise the origin of any message or transmittal User sends to Terminal on or
                    through the Site. &nbsp;User shall not, in connection with the Site, pretend
                    (e.g. through impersonation) that User is any other individual or entity.
                  </span>
                </p>
                <p>
                  <span>
                    User shall not use the Site for any purpose that is unlawful or prohibited by
                    this Agreement, or which infringes the rights of Terminal or others.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>CONFIDENTIALITY.</span>
                </h3>
                <p>
                  <span>
                    All other information that Terminal makes available to User via the Site or in
                    any other manner (including via email or verbally), will be deemed
                    Terminal&rsquo;s confidential information if it is identified as confidential by
                    Terminal or should otherwise be reasonable understood by User to be confidential
                    from the nature of the information or the manner of discloser
                    (&ldquo;Confidential Information). This will not apply to information that is or
                    becomes generally available to the public through no fault of User.
                    Notwithstanding the foregoing, Engagement Information is, as between Terminal
                    and User, deemed Terminal&rsquo;s Confidential Information (regardless of the
                    fact its constituent parts may be publicly available). Confidential Information
                    will only be used by User internally for the Purpose. It will not be disclosed
                    to any third party without Terminal&rsquo;s written consent. On request, User
                    will promptly provide to Terminal (or, at Terminal&rsquo;s request, destroy, all
                    Confidential Information. On request, User will provide Terminal with written
                    certification it has complied with the foregoing. For clarity, will Section 5
                    will survive any termination of this Agreement.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>INJUNCTIVE RELIEF.</span>
                </h3>
                <p>
                  <span>
                    In the event of a breach or threatened breach of Sections 2, 3, 4, or 5, User
                    acknowledges and agreed that Terminal is entitled to immediate injunctive or
                    other equitable relief (without a showing of damages or the posting of a bond).
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>INDEMNIFICATION. &nbsp;</span>
                </h3>
                <p>
                  <span>
                    User is responsible for all of its activity in connection with the Site. User
                    shall defend, indemnify, and hold harmless Terminal and each of its employees,
                    contractors, directors, suppliers and representatives from all liabilities,
                    damages, losses, settlements, claims, actions, demands, costs and expenses,
                    including attorneys&apos; fees, that arise from or in connection with
                    User&rsquo;s (i) use or misuse of the Site, or (ii) violation of this Agreement.
                    &nbsp;&nbsp;
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>WARRANTY DISCLAIMER.</span>
                </h3>
                <p>
                  <span>
                    THE SITE IS PROVIDED ON AN &quot;AS IS&quot; BASIS, WITHOUT WARRANTIES OF ANY
                    KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                    NON-INFRINGEMENT
                  </span>
                  <span>
                    . &nbsp;IN SUCH JURISDICTIONS, THE FOREGOING DISCLAIMERS MAY NOT APPLY TO USER
                    INSOFAR AS THEY RELATE TO IMPLIED WARRANTIES.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>LIMITATION ON LIABILITY.</span>
                  <strong>
                    <span>&nbsp;</span>
                  </strong>
                </h3>

                <p>
                  <span>
                    IN NO EVENT SHALL TERMINAL, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, VENDORS
                    OR SUPPLIERS BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY
                    OTHER LEGAL THEORY WITH RESPECT TO THE SITE (OR ANY CONTENT OR INFORMATION
                    AVAILABLE ON THE SITE): (I) FOR ANY LOST PROFITS OR SPECIAL, INDIRECT,
                    INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, EVEN IF
                    FORESEEABLE, (III) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS
                    OF THE SOURCE OF ORIGINATION), (IV) FOR ANY ERRORS OR OMISSIONS IN ANY CONTENT
                    OR INFORMATION OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
                    USER&rsquo;S USE OF ANY CONTENT OR INFORMATION POSTED, EMAILED, TRANSMITTED OR
                    OTHERWISE MADE AVAILABLE AT OR THROUGH&nbsp;
                  </span>
                  <span>
                    THE SITE, OR (V) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $10.00
                    (U.S.). &nbsp;THE FOREGOING LIMITATIONS SHALL NOT APPLY TO THE EXTENT PROHIBITED
                    BY APPLICABLE LAW.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>TERMINATION.&nbsp;</span>
                </h3>
                <p>
                  <span>
                    Terminal may terminate User&rsquo;s access to all or any part of the Site at any
                    time, with or without cause, effective upon notice thereof to User or blocking
                    User from accessing the Site. All provisions which by their nature should
                    survive termination (including, without limitation, the second paragraph of
                    Section 1 and all use restrictions herein).
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>ELECTRONIC COMMUNICATIONS.</span>
                </h3>
                <p>
                  <span>
                    When User visits the Site or send e-mails to Terminal, User is communicating
                    with Terminal electronically. &nbsp;User hereby consents to receive
                    communications from us electronically. &nbsp;User agrees that all agreements,
                    notices, disclosures and other communications that Terminal provides to User
                    electronically satisfy any legal requirement that such communications be in
                    writing.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>NOTICES.</span>
                </h3>
                <p>
                  <span>
                    All notices to User may be provided by Terminal via email to the email address
                    provided by User (or, if none is provided, the email address otherwise
                    identified by Terminal). &nbsp;All notices to Terminal must be sent by User via
                    email to hello@terminal.io.
                  </span>
                </p>
              </li>
              <li>
                <h3>
                  <span>GENERAL.&nbsp;</span>
                </h3>
                <p>
                  <span>
                    The failure of either party to exercise in any respect any right provided for
                    herein shall not be deemed a waiver of any further rights hereunder. Terminal
                    shall not be liable for any failure to perform its obligations hereunder where
                    such failure results from any cause beyond Terminal&rsquo;s reasonable control,
                    including, without limitation, mechanical, electronic or communications failure
                    or degradation. If any provision of this Agreement is found to be unenforceable
                    or invalid, that provision shall be limited or eliminated to the minimum extent
                    necessary so that this Agreement shall otherwise remain in full force and effect
                    and enforceable. This Agreement is not assignable, transferable or sublicensable
                    by User except with Terminal&rsquo;s prior written consent. &nbsp;Terminal may
                    transfer, assign or delegate this Agreement and its rights and obligations
                    without consent. &nbsp;This Agreement shall be governed by and construed in
                    accordance with the laws of the state of California, without regard to any
                    conflict of law provision, and the parties submit to the exclusive jurisdiction
                    and venue of the state and Federal courts located in Santa Clara, California.
                    &nbsp;Both parties agree that this Agreement is the complete and exclusive
                    statement of the mutual understanding of the parties and supersedes and cancels
                    all previous written and oral agreements, communications and other
                    understandings relating to the subject matter of this Agreement, and that all
                    modifications must be in a writing signed by both parties, except as otherwise
                    provided herein. No agency, partnership, joint venture, or employment is created
                    as a result of this Agreement and User does not have any authority of any kind
                    to bind Terminal in any respect whatsoever. The prevailing party in any action
                    or proceeding to enforce this Agreement will be entitles to collect all
                    associated costs and attorneys&rsquo; fees.
                  </span>
                  <span ref={setbottomOfTermsRef} />
                </p>
              </li>
            </ol>
          </Box>
          <FormControl id="hasScrolledToEnd" mb={4} isInvalid={!!errors.hasScrolledToEnd}>
            <FormErrorMessage>{errors.hasScrolledToEnd}</FormErrorMessage>
          </FormControl>
          <FormControl id="acceptTermsOfUse" isInvalid={!!errors.acceptTermsOfUse}>
            <Checkbox
              isRequired
              id="acceptTermsOfUse"
              name="acceptTermsOfUse"
              onBlur={handleBlur}
              onChange={handleChange}
              mt={2}
              isInvalid={!!errors.acceptTermsOfUse}
              isChecked={values.acceptTermsOfUse}
            >
              I have read and agree to the Terms of Use
              <Text ml={1} color="text.error" display="inline-block">
                *
              </Text>
            </Checkbox>
            <FormErrorMessage>{errors.acceptTermsOfUse}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button
            variant="ghost"
            colorScheme="accent"
            isDisabled={isSubmitting}
            w="full"
            onClick={() => {
              signOut({ auth: firebaseAuth });
              window.location.href = 'https://terminal.io/';
            }}
          >
            Sign out
          </Button>
          <Button
            isLoading={isSubmitting}
            variant="solid"
            colorScheme="primary"
            w="full"
            onClick={() => handleSubmit()}
          >
            Accept & Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
