import { textTheme } from '../Text/Text.theme';

const parts = ['root', 'tablist', 'tab', 'tabpanels', 'tabpanel', 'indicator', 0];

type Dict = Record<string, any>;

function baseStyleRoot(props: Dict) {
  const { orientation } = props;
  return {
    display: orientation === 'vertical' ? 'flex' : 'block',
  };
}

function baseStyleTab(props: Dict) {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transition: 'all 0.2s',
    _focus: {
      zIndex: 1,
      boxShadow: 'none',
    },
  };
}

function baseStyleTablist(props: Dict) {
  const { align = 'start', orientation } = props;

  const alignments = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
  };

  return {
    // @ts-ignore
    justifyContent: alignments[align],
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
}

const baseStyleTabpanel = { p: 0, pt: 7 };

const baseStyle = (props: Dict) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
});

const sizes = {
  md: {
    tab: {
      ...textTheme.variants.label,
      px: 6,
      py: 2,
    },
  },
};

function variantLine(props: Dict) {
  const { orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderOrientationProp = orientation === 'vertical' ? 'borderLeft' : 'borderBottom';
  const marginProp = isVertical ? 'ml' : 'mb';

  return {
    tablist: {
      [borderOrientationProp]: '1px solid',
      borderColor: 'ui.secondary',
    },
    tab: {
      color: 'text.disabled',
      border: '1px solid ',
      borderColor: 'transparent',
      [marginProp]: '-1px',

      _selected: {
        color: 'accent.main',
        [`${borderOrientationProp}Color`]: 'currentColor',
        _focus: {
          color: 'text.primary', // otherwise, selected rules overwrite focus ones
        },
      },
      _hover: {
        color: 'text.primary',
        bgColor: 'bg.tertiary',
      },
      _focus: {
        color: 'text.primary',
        bgColor: 'bg.tertiary',
        border: '1px solid',
        borderColor: 'accent.darkest',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  };
}

function variantEnclosedColored() {
  return {
    tab: {
      border: 'none',
      borderRadius: 'base',
      borderBottomRadius: 0,
      shadow: 'none',
      bg: 'transparent',
      color: 'text.secondary',
      mb: '-1px',
      _notLast: {
        marginEnd: '-1px',
      },
      _selected: {
        shadow: 'md',
        bg: 'bg.primary',
        zIndex: -1,
        color: 'brand.main',
        borderColor: 'inherit',
        borderTopColor: 'currentColor',
      },
    },
    tablist: {
      mb: '-1px',
      borderBottom: 'none',
      borderColor: 'inherit',
    },
  };
}

const variants = {
  line: variantLine,
  'enclosed-colored': variantEnclosedColored,
};

const defaultProps = {
  size: 'md',
  variant: 'line',
};

export const tabsTheme = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
