import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  Divider,
  Flex,
  EmailVerificationIcon,
  CoffeeCupIcon,
  useDisclosure,
  Badge,
} from '@terminal/design-system';
import { Fragment } from 'react';

import {
  StrongNoFaceFeedbackIcon,
  NoFaceFeedbackIcon,
  YesFaceFeedbackIcon,
  StrongYesFaceFeedbackIcon,
  RoleLayout,
  InviteToFeedbackModal,
} from 'talent-hub/shared/features/roles/components/';
import type { FeedbackRatinglabel } from './CandidateFeedback.types';
import { FeedbackRatinglabelValues } from './CandidateFeedback.types';

const feedbackRatingIconMapping = {
  [FeedbackRatinglabelValues.STRONG_YES]: StrongYesFaceFeedbackIcon,
  [FeedbackRatinglabelValues.YES]: YesFaceFeedbackIcon,
  [FeedbackRatinglabelValues.NO]: NoFaceFeedbackIcon,
  [FeedbackRatinglabelValues.STRONG_NO]: StrongNoFaceFeedbackIcon,
  [FeedbackRatinglabelValues.default]: null,
};

export function CandidateFeedback({
  roleLayoutProps,
  feedbackList,
  candidate: { fullName },
  interviewFeedbackUtils,
  isClientReviewer,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
  feedbackList: {
    title: string;
    description: string;
    status: string | null;
    ratingLabel: FeedbackRatinglabel;
    id: number | null;
    createdDate: string;
  }[];
  candidate: { fullName: string | null };
  interviewFeedbackUtils: {
    isLoading: boolean;
    users: { value: string; label: string }[];
    handleOnSubmitInviteFeedback: (currentValues: {
      users: {
        value: string;
        label: string;
      }[];
      isRoleAccess: boolean;
    }) => { error: boolean };
  };
  isClientReviewer: boolean;
}) {
  const feedbackModal = useDisclosure();

  if (!feedbackList.length)
    return (
      <RoleLayout {...roleLayoutProps}>
        <InviteToFeedbackModal
          options={interviewFeedbackUtils.users}
          isLoading={interviewFeedbackUtils.isLoading}
          isOpen={feedbackModal.isOpen}
          onClose={feedbackModal.onClose}
          modalTitle={`Invite Others to Leave Feedback for ${fullName}`}
          modalDescription="Team members will be able to see this candidate's profile and leave interview
            feedback. Compensation information will be hidden."
          handleOnSubmitInviteFeedback={interviewFeedbackUtils.handleOnSubmitInviteFeedback}
          shouldHide_candidateCheckbox={false}
        />
        <Box w="full" p={6} pt={2}>
          <VStack w="full" pt={2} spacing={6} alignItems="start">
            <Flex w="full" flexDirection={['column', 'row']} justify="space-between" align="center">
              <Heading variant="heading-2">Interview Feedback for {fullName}</Heading>
              {!isClientReviewer && (
                <Button
                  variant="outline"
                  colorScheme="accent"
                  onClick={feedbackModal.onOpen}
                  size="lg"
                  p={0}
                  mt={[4, 0]}
                  leftIcon={<EmailVerificationIcon color="accent.darkest" fontSize="lg" />}
                >
                  Invite Others
                </Button>
              )}
            </Flex>
            <Flex w="full" justify="space-between" align="center" direction="column" mt={5}>
              <CoffeeCupIcon fontSize="8xl" />
              <Heading variant="heading-3" mt={6}>
                No feedback to show
              </Heading>
              <Text variant="body" color="text.secondary" textAlign="center">
                Invite your colleagues to start collecting feedback for this candidate.
              </Text>
            </Flex>
          </VStack>
        </Box>
      </RoleLayout>
    );

  return (
    <RoleLayout {...roleLayoutProps}>
      <InviteToFeedbackModal
        options={interviewFeedbackUtils.users}
        isLoading={interviewFeedbackUtils.isLoading}
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
        modalTitle={`Invite Others to Leave Feedback for ${fullName}`}
        modalDescription="Team members will be able to see this candidate's profile and leave interview
            feedback. Compensation information will be hidden."
        handleOnSubmitInviteFeedback={interviewFeedbackUtils.handleOnSubmitInviteFeedback}
        shouldHide_candidateCheckbox={false}
      />
      <Box w="full" p={6} pt={2}>
        <VStack w="full" pt={6} spacing={6} alignItems="start">
          <Flex w="full" flexDirection={['column', 'row']} justify="space-between" align="center">
            <Heading variant="heading-2">Interview Feedback for {fullName}</Heading>
            {!isClientReviewer && (
              <Button
                variant="outline"
                colorScheme="accent"
                onClick={feedbackModal.onOpen}
                size="lg"
                p={0}
                mt={[4, 0]}
                leftIcon={<EmailVerificationIcon color="accent.darkest" fontSize="lg" />}
              >
                Invite Others
              </Button>
            )}
          </Flex>
          {feedbackList.map(({ status, title, description, ratingLabel, id, createdDate }) => {
            const Icon = feedbackRatingIconMapping[ratingLabel];
            if (Icon) {
              return (
                <Fragment key={id || title}>
                  <Box
                    bg="bg.secondary"
                    w="full"
                    display="flex"
                    p={4}
                    gridGap={6}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box>
                      <Heading variant="heading-3">{title}</Heading>
                      <Text variant="caption">{createdDate}</Text>
                    </Box>
                    {status && <Badge colorScheme="info">{status}</Badge>}
                  </Box>
                  <Box pl={4}>
                    <Flex>
                      {Icon && <Icon color="accent.darkest" fontSize="2xl" mr={3} fill="none" />}
                      <Text variant="label" color="text.primary">
                        {ratingLabel}
                      </Text>
                    </Flex>
                    <Text whiteSpace="pre-wrap" variant="body" mt={3} color="text.primary">
                      {description}
                    </Text>
                  </Box>
                  <Divider orientation="horizontal" />
                </Fragment>
              );
            }
            return (
              <Fragment key={id || title}>
                <Box
                  bg="bg.secondary"
                  w="full"
                  display="flex"
                  p={4}
                  gridGap={6}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <Heading variant="heading-3">{title}</Heading>
                </Box>
                <Box pl={4}>
                  <Flex>
                    <Text variant="label" color="text.disabled">
                      {ratingLabel}
                    </Text>
                  </Flex>
                  <Text whiteSpace="pre-wrap" variant="body" mt={3} color="text.disabled">
                    {description}
                  </Text>
                </Box>
                <Divider orientation="horizontal" />
              </Fragment>
            );
          })}
        </VStack>
      </Box>
    </RoleLayout>
  );
}
