import { useMutation } from '@apollo/client';
import { useToast } from '@terminal/design-system';
import type { ApolloError } from '@apollo/client';
import Sentry from 'global/sentry';
import { useCustomerAuth } from 'talent-hub/utils';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import { FormikProvider } from 'formik';
import * as Yup from 'yup';
import type { serializeOnboardingData } from '../../data';
import type { StepInfo } from '../../Onboarding.types';
import type {
  DeleteInsertClientProspectRolesMutation,
  DeleteInsertClientProspectRolesMutationVariables,
} from './data';
import { DeleteInsertClientProspectRoles } from './data';
import { Roles } from './Roles';
import { serializerInsertedRoles, serializeSelectedRoleOptions } from './Roles.serializer';
import { onboardingState } from '../../Onboarding.utils';

export type FormData = {
  roleList: {
    role: string;
    yearsOfExperience: string;
    skills: {
      value: string;
      label: string;
    }[];
  }[];
};

const validationSchema = Yup.object().shape({
  roleList: Yup.array()
    .of(
      Yup.object().shape({
        role: Yup.string().required('Role is required'),
        yearsOfExperience: Yup.string().required('Years of experience is required'),
        skills: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required(),
            label: Yup.string().required(),
          }),
        ),
      }),
    )
    .min(1),
});

export function RolesController({
  onStepComplete,
  stepInfo,
  options,
}: {
  onStepComplete: () => void;
  stepInfo: StepInfo;
  options: ReturnType<typeof serializeOnboardingData>['roleOptions'];
}) {
  const toast = useToast();
  const auth = useCustomerAuth();
  const [saveRoles, { loading: isLoading_deleteInsertRole }] = useMutation<
    DeleteInsertClientProspectRolesMutation,
    DeleteInsertClientProspectRolesMutationVariables
  >(DeleteInsertClientProspectRoles, {
    onCompleted: (values) => {
      if (values.insert_client_prospect_role == null || values.insert_client_prospect_one == null) {
        // Technically this should not happen as apollo is indicating the insertion was successful
        Sentry.captureException(new Error('Save role data did not return roles data'));
        return;
      }
      onboardingState.update('prospectID', values.insert_client_prospect_one?.id);
      onboardingState.update('roles', serializerInsertedRoles(values.insert_client_prospect_role));
      onStepComplete();
    },
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
  });

  const formik = useCustomFormik<FormData>({
    initialValues: {
      roleList: [{ role: '', skills: [], yearsOfExperience: '' }],
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {
      const valuesToBeSubmitted = serializeSelectedRoleOptions({
        roleList: formik.values.roleList,
        options,
        userID: auth.user?.id as number,
      });

      saveRoles({
        variables: {
          user_info_id: auth?.user?.id as number,
          roles: valuesToBeSubmitted,
          lastStepCompleted: 'Roles',
          roleChoicesBlob: formik.values.roleList.map((item) => ({
            role: item.role,
            yoe: item.yearsOfExperience,
            skills: item.skills.map((skill) => skill.label),
          })),
          roleChoicesLength: formik.values.roleList.length,
        },
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Roles.Variation.CheckboxSelection
        stepInfo={stepInfo}
        options={options}
        loading={isLoading_deleteInsertRole}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        setFieldValue={formik.setFieldValue}
        handleBlur={formik.handleBlur}
        submitForm={formik.submitForm}
        resetForm={formik.resetForm}
      />
    </FormikProvider>
  );
}
