import { AuthPageTemplate } from '../components';
import type { AuthRedirectTo, AuthPageTemplateProps } from '../types';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export function ForgotPasswordFormPage({
  redirectTo,
  onResetClick,
  isLoading,
  pageTemplateOverwrite,
}: {
  redirectTo: AuthRedirectTo;
  onResetClick: (email: string) => Promise<void>;
  isLoading: boolean;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate
      footerLinkOnClick={() => redirectTo('login')}
      footerText="Changed your mind?"
      footerLinkLabel="Login"
      headerSubtitle="Don’t worry we can help."
      headerTitle="Forgot Password?"
    >
      <ForgotPasswordForm onResetSubmit={onResetClick} showLoadingSpinner={isLoading} />
    </PageTemplate>
  );
}
