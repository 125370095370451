import { Box, Center, Text } from '@terminal/design-system';

export function AuthFormCard({
  children,
  footerText,
  footerLink,
  containerMaxW = 'md',
  hasShadow = true,
}: {
  children: React.ReactNode;
  footerLink?: React.ReactNode;
  footerText?: string;
  containerMaxW?: string;
  hasShadow?: boolean;
}) {
  return (
    <Box
      bg="bg.primary"
      maxW={['sm', containerMaxW]}
      w="full"
      mt={6}
      boxShadow={
        hasShadow
          ? '-24px 28px 0px 1px rgba(71, 95, 122, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1)'
          : 'none'
      }
      zIndex="modal"
      borderRadius="base"
    >
      <Box px={[6, 12]} pt={[6, 12]} pb={[6, 10]}>
        {children}
      </Box>
      {!!footerText && (
        <Center bg="bg.secondary" p={5} borderBottomRadius="base">
          <Text mr={2}>{footerText}</Text>
          {footerLink}
        </Center>
      )}
    </Box>
  );
}
