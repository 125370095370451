import {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Job_Status_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { createDaysAgoDates, toHasuraDate } from 'global/utils';
import type { ManageRolesQuery } from './graphql';

const daysToGo = createDaysAgoDates([3, 10, 15, 100]);

function create_manage_roles(overwrite?: Partial<ManageRolesQuery>): ManageRolesQuery {
  return {
    job: [],
    active_postings: [],
    filled_postings: [],
    members: [],
    ...overwrite,
  };
}

function create_filled_posting_position(
  overwrite?: Partial<ManageRolesQuery['active_postings'][0]['positions'][0]>,
): ManageRolesQuery['filled_postings'][0]['positions'][0] {
  return {
    id: 1097,
    name: 'Research officer, political party',
    initial_headcount: 2,
    created_at: toHasuraDate('2022-09-29'),
    accurate_date: {
      created_date: null,
      last_approved: null,
    },
    applicant_workflows: [],
    icims_folder: { value: 'Closed (Filled)' },
    ...overwrite,
  };
}

function create_filled_posting(
  overwrite?: Partial<ManageRolesQuery['filled_postings'][0]>,
): ManageRolesQuery['filled_postings'][0] {
  return {
    id: 1019,
    name: 'Radiographer, therapeutic',
    initial_headcount: 1,
    created_at: daysToGo[15],
    applicant_workflows: [
      {
        workflow_statuses: [
          {
            date: daysToGo[10],
            status: {
              title: 'New Applicant - External Portal',
            },
          },
          {
            date: daysToGo[3],
            status: {
              title: 'Offer - Offer Accepted',
            },
          },
        ],
      },
    ],
    accurate_date: {
      created_date: null,
      last_approved: null,
    },
    icims_folder: { value: 'Closed (Filled)' },
    positions: [create_filled_posting_position()],
    locations: [],
    filled_positions_aggregate: {
      total: { hires: 1 },
    },

    ...overwrite,
  };
}

function create_active_postive_position(
  overwrite?: Partial<ManageRolesQuery['active_postings'][0]>,
): ManageRolesQuery['active_postings'][0]['positions'][0] {
  return {
    id: 1097,
    name: 'Research officer, political party',
    initial_headcount: 2,
    created_at: toHasuraDate('2022-09-29'),
    accurate_date: {
      created_date: null,
      last_approved: null,
    },
    applicant_workflows: [],
    icims_folder: { value: 'Approved' },
    is_position: true,
    job: {
      status: Job_Status_Choices_Enum.Posted,
    },
    posting: 1019, // Ensure you pass this value down
    ...overwrite,
  };
}

function create_active_posting(
  overwrite?: Partial<ManageRolesQuery['active_postings'][0]>,
): ManageRolesQuery['active_postings'][0] {
  return {
    id: 1019,
    name: 'Radiographer, therapeutic',
    initial_headcount: 1,
    created_at: daysToGo[15],
    is_position: false,
    job: {
      status: Job_Status_Choices_Enum.Posted,
    },
    applicant_workflows: [
      {
        workflow_statuses: [
          {
            date: daysToGo[10],
            status: {
              title: 'New Applicant - External Portal',
            },
          },
        ],
      },
    ],
    accurate_date: {
      created_date: null,
      last_approved: null,
    },
    icims_folder: { value: 'Approved' },
    posting: null,
    positions: [create_active_postive_position({ posting: 1019 })],
    locations: [
      {
        icims_location: {
          value: 'Colombia',
          readable_locations: [
            {
              value: 'Colombia',
              city: null,
              id: 30,
            },
          ],
        },
      },
    ],
    ...overwrite,
  };
}

function create_draft_posting(
  overwrite?: Partial<ManageRolesQuery['job'][0]>,
): ManageRolesQuery['job'][0] {
  return {
    title: 'Professional Sleeper',
    id: 1,
    job_required_skills: [
      {
        id: 1,
        skill: {
          name: 'React',
        },
      },
      {
        id: 1,
        skill: {
          name: 'Python',
        },
      },
      {
        id: 1,
        skill: {
          name: 'Go',
        },
      },
    ],
    min_years_of_experience: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
    status: Job_Status_Choices_Enum.Submitted,
    updated_at: toHasuraDate('2022-09-29'),
    job_positions_aggregate: {
      aggregate: {
        count: 1,
      },
    },
    job_acceptable_locations: [
      {
        location: {
          value: 'Colombia',
        },
      },
    ],
    ...overwrite,
  };
}

export const ManageRolesDashboardFixture = {
  create_data: create_manage_roles,
  create_filled_posting_position,
  create_filled_posting,
  create_active_posting,
  create_active_postive_position,
  create_draft_posting,
};
