import type { PositionProps } from '@terminal/design-system';
import { Center, Spinner } from '@terminal/design-system';

export function BlankScreenLoading({
  'aria-label': ariaLabel,
  ...positionProps
}: { 'aria-label'?: string } & PositionProps) {
  return (
    <Center w="100%" minH="100vh" {...positionProps}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="ui.secondary"
        color="brand.main"
        size="xl"
        aria-label={ariaLabel || 'App Loading'}
        role="status"
      />
    </Center>
  );
}
