import 'firebase/auth';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Flex,
  DashboardIcon,
  Menu,
  Text,
  JobsIcon,
  UserGroupIcon,
  SearchUserIcon,
  MembersIcon,
  MenuItem,
  Box,
  Tooltip,
} from '@terminal/design-system';
import { Logo } from 'global/components/Logo';
import { useTalentHubFlags } from 'talent-hub/utils/flags';

const StyledNavLink = styled(NavLink)`
  &:not(.active) {
    & svg path {
      fill: none;
    }
  }
`;

const FlexNavLink = styled(Flex)`
  & {
    & svg path {
      fill: none;
    }
  }
`;

// TODO: [TAL-977] move this to a different folder and restructure the component
function TooltipWrapper({
  showTooltip,
  children,
}: {
  showTooltip: boolean;
  children: React.ReactElement;
}) {
  if (showTooltip) {
    return (
      <Tooltip
        label="Upgrade from your free trial to access this feature of Talent Hub."
        arrowSize={15}
        bg="bg.primary"
        maxW={56}
        ml={3}
        hasArrow
      >
        {children}
      </Tooltip>
    );
  }

  return children;
}

// TODO: // TODO: break this component by roles
export function SidebarContent({
  isClientReviewer,
  isClientProspect,
}: {
  isClientReviewer: boolean;
  isClientProspect: boolean;
}) {
  const featureFlags = useTalentHubFlags();
  return (
    <Flex
      flexDir="column"
      h="full"
      px={[2, 2, 0]}
      pt={4}
      bg="bg.secondary"
      borderRight="1px solid"
      borderColor="ui.secondary"
      overflow="hidden"
      boxSizing="border-box"
    >
      <Flex height="58px" mb={8}>
        <Logo variation="block" />
      </Flex>
      <Menu autoSelect={false} variant="secondary">
        <Box flex={1} mb={2} role="group" display="flex" flexDir="column">
          {!isClientReviewer && (
            <>
              {featureFlags['role-based-rehaul'] ? (
                <MenuItem
                  as={StyledNavLink}
                  to="/dashboard"
                  display="flex"
                  flexDir="column"
                  order={isClientProspect ? -2 : 'initial'}
                  borderRadius={['base', 'base', 0]}
                  p={0}
                  mb={[2, 2, 0]}
                  alignItems="center"
                  justifyContent="center"
                  w={['full', 'full', 20]}
                  h={20}
                >
                  <DashboardIcon w={5} h={5} />
                  <Text variant="hint" mt={1} textAlign="center" px={2}>
                    Dashboard
                  </Text>
                </MenuItem>
              ) : (
                <MenuItem
                  as={StyledNavLink}
                  exact
                  to="/"
                  display="flex"
                  flexDir="column"
                  order={isClientProspect ? -2 : 'initial'}
                  borderRadius={['base', 'base', 0]}
                  p={0}
                  mb={[2, 2, 0]}
                  alignItems="center"
                  justifyContent="center"
                  w={['full', 'full', 20]}
                  h={20}
                >
                  <DashboardIcon w={5} h={5} />
                  <Text variant="hint" mt={1} textAlign="center" px={2}>
                    Dashboard
                  </Text>
                </MenuItem>
              )}
              <TooltipWrapper showTooltip={isClientProspect}>
                <MenuItem
                  as={isClientProspect ? FlexNavLink : StyledNavLink}
                  to="/role"
                  display="flex"
                  flexDir="column"
                  isDisabled={isClientProspect}
                  borderRadius={['base', 'base', 0]}
                  p={0}
                  mb={[2, 2, 0]}
                  alignItems="center"
                  justifyContent="center"
                  w={['full', 'full', 20]}
                  h={20}
                >
                  <JobsIcon w={5} h={5} />
                  <Text variant="hint" mt={1} textAlign="center" px={2}>
                    Manage Roles
                  </Text>
                </MenuItem>
              </TooltipWrapper>
            </>
          )}
          <TooltipWrapper showTooltip={isClientProspect}>
            <MenuItem
              as={isClientProspect ? FlexNavLink : StyledNavLink}
              exact
              to="/candidates"
              display="flex"
              flexDir="column"
              isDisabled={isClientProspect}
              borderRadius={['base', 'base', 0]}
              p={0}
              mb={[2, 2, 0]}
              alignItems="center"
              justifyContent="center"
              w={['full', 'full', 20]}
              h={20}
            >
              <UserGroupIcon w={5} h={5} />
              <Text variant="hint" mt={1} textAlign="center" px={2}>
                Manage Candidates
              </Text>
            </MenuItem>
          </TooltipWrapper>
          {!isClientReviewer && (
            <>
              <TooltipWrapper showTooltip={isClientProspect}>
                <MenuItem
                  as={isClientProspect ? FlexNavLink : StyledNavLink}
                  exact
                  to="/members"
                  display="flex"
                  flexDir="column"
                  isDisabled={isClientProspect}
                  borderRadius={['base', 'base', 0]}
                  p={0}
                  mb={[2, 2, 0]}
                  alignItems="center"
                  justifyContent="center"
                  w={['full', 'full', 20]}
                  h={20}
                >
                  <MembersIcon w={5} h={5} />
                  <Text variant="hint" mt={1} textAlign="center" px={2}>
                    Your Members
                  </Text>
                </MenuItem>
              </TooltipWrapper>
              <MenuItem
                as={StyledNavLink}
                to={{
                  pathname: `/explore-candidates`,
                  state: { scrollTop: true },
                }}
                display="flex"
                flexDir="column"
                order={isClientProspect ? -1 : 'initial'}
                borderRadius={['base', 'base', 0]}
                p={0}
                mb={[2, 2, 0]}
                alignItems="center"
                justifyContent="center"
                w={['full', 'full', 20]}
                h={20}
              >
                <SearchUserIcon w={5} h={5} />
                <Text variant="hint" mt={1} textAlign="center" px={2}>
                  Explore Candidates
                </Text>
              </MenuItem>
            </>
          )}
        </Box>
      </Menu>
    </Flex>
  );
}
