import { useParams } from 'react-router-dom';
import { useCustomerAuth } from 'talent-hub/utils';
import { useQuery, useRedirection } from 'global/utils';
import { CandidateProfile } from './CandidateProfile';
import { SelectBrowseCandidatesByPublicID } from './data';
import type {
  SelectBrowseCandidatesByPublicIdQuery,
  SelectBrowseCandidatesByPublicIdQueryVariables,
} from './data';
import { serializePublicCandidateProfile } from './CandidateProfile.serializer';

export function CandidatePublicProfileController() {
  const { publicID } = useParams<{ publicID: string }>();
  const auth = useCustomerAuth();
  const redirectTo = useRedirection();

  const { data, loading } = useQuery<
    SelectBrowseCandidatesByPublicIdQuery,
    SelectBrowseCandidatesByPublicIdQueryVariables
  >(SelectBrowseCandidatesByPublicID, {
    context: {
      service: 'service',
    },
    variables: {
      public_id: publicID,
    },
  });

  const candidateID = data?.publicCandidateInfo?.candidate?.id;
  const candidateProfileURL = `/explore-candidates/candidate/${candidateID}`;

  // If the user is logged redirect to the candidate profile
  if (auth.isAuthorized && candidateID) {
    redirectTo(candidateProfileURL);
    return null;
  }

  const { candidate, matchingCandidates } = serializePublicCandidateProfile(data);

  return (
    <CandidateProfile
      isLoading={loading}
      candidateProfileURL={candidateProfileURL}
      candidate={candidate}
      matchingCandidates={matchingCandidates}
    />
  );
}
