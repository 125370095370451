import type React from 'react';
import { firstItemPathOr } from 'global/utils';
import moment from 'moment';
import { capitalizeEachWord } from 'talent-hub/utils';
import type { CandidateFeedback } from './CandidateFeedback';
import { FeedbackRatinglabelValues } from './CandidateFeedback.types';
import type { SelectCandidateFeedbackQuery } from './data';

function transformFeedBackDataToFeedbackProps(
  interview_feedback: SelectCandidateFeedbackQuery['interview_feedback'],
  interview_feedback_request: SelectCandidateFeedbackQuery['interview_feedback_request'],
): React.ComponentProps<typeof CandidateFeedback>['feedbackList'] {
  const feedbackList: React.ComponentProps<typeof CandidateFeedback>['feedbackList'] = [];

  // Need to iterate over the invite_feedback to check which users invited to leave feedback have send it and wich ones dont
  interview_feedback_request.forEach(({ interviewer_email, interviewer_user }) => {
    const userFeedback: SelectCandidateFeedbackQuery['interview_feedback'][number] | undefined =
      interview_feedback.find(
        (feedback) => feedback.created_by_user_info?.email === interviewer_email,
      );

    if (userFeedback) {
      const {
        id,
        comments,
        rating,
        created_by_user_info: { first_name, last_name },
        created_at,
        icims_applicant_workflow_status,
      } = userFeedback;

      // We know that this will never be null
      const title: string = `${capitalizeEachWord(first_name)} ${capitalizeEachWord(last_name)}`;

      feedbackList.push({
        id,
        title,
        description: comments,
        status: icims_applicant_workflow_status?.replace('Client Review - ', '') || null,
        ratingLabel: FeedbackRatinglabelValues[rating],
        createdDate: moment(created_at).format('MMMM D, YYYY'),
      });
      return;
    }

    const title: string = interviewer_user
      ? `${capitalizeEachWord(interviewer_user.first_name)} ${capitalizeEachWord(
          interviewer_user.last_name,
        )}`
      : interviewer_email;

    feedbackList.push({
      id: null,
      title,
      description: `${title} hasn't submitted their feedback for this candidate yet`,
      status: null,
      ratingLabel: FeedbackRatinglabelValues.default,
      createdDate: '',
    });
  });

  // Need to iterate to the actual feedback list, because some members can leave feedback without invitation
  interview_feedback.forEach((feedback) => {
    const userFeedbackInvitation:
      | SelectCandidateFeedbackQuery['interview_feedback_request'][number]
      | undefined = interview_feedback_request.find(
      (feedbackInvite) => feedbackInvite.interviewer_email === feedback.created_by_user_info?.email,
    );

    if (!userFeedbackInvitation) {
      const {
        id,
        comments,
        rating,
        created_by_user_info: { first_name, last_name },
        created_at,
        icims_applicant_workflow_status,
      } = feedback;

      // We know that this will never be null
      const title: string = `${capitalizeEachWord(first_name)} ${capitalizeEachWord(last_name)}`;

      feedbackList.push({
        id,
        title,
        description: comments,
        status: icims_applicant_workflow_status?.replace('Client Review - ', '') || null,
        ratingLabel: FeedbackRatinglabelValues[rating],
        createdDate: moment(created_at).format('MMMM D, YYYY'),
      });
    }
  });

  // Sort by Not Yet Submitted feedback entries at the end and Submitted feedback sort ascending
  return feedbackList.sort(({ id }, { id: id2 }) => {
    // equal items sort equally
    if (id === id2) {
      return 0;
    }
    // nulls sort after anything else
    if (id === null) {
      return 1;
    }

    if (id2 === null) {
      return -1;
    }
    // highest sorts first
    return id < id2 ? 1 : -1;
  });
}

const transformIcimsPersonFullName = (
  icims_person: SelectCandidateFeedbackQuery['icims_person'],
) => {
  const person = icims_person[0]?.candidate || {
    first_name: icims_person[0]?.firstname,
    last_name: icims_person[0]?.lastname,
  };
  if (!person) {
    return null;
  }

  const { first_name = '', last_name = '' } = person;

  if (!first_name && !last_name) {
    return null;
  }

  if (!first_name) {
    return `${capitalizeEachWord(last_name) || ''}`;
  }

  if (!last_name) {
    return `${capitalizeEachWord(first_name) || ''}`;
  }

  return `${capitalizeEachWord(first_name)} ${capitalizeEachWord(last_name)}`;
};

export function serializeCandidateFeedback(data?: SelectCandidateFeedbackQuery) {
  if (!data)
    return {
      candidate: {
        fullName: null,
      },
      feedbackList: [],
      applicantWorkflowID: null,
    };
  return {
    candidate: {
      fullName: transformIcimsPersonFullName(data.icims_person),
    },
    feedbackList: transformFeedBackDataToFeedbackProps(
      data.interview_feedback,
      data.interview_feedback_request,
    ),
    applicantWorkflowID: firstItemPathOr(null, ['workflow_id'], data.icims_applicant_workflow),
  };
}
