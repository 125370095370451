import { Flex, Center, Heading, Text, Button } from '@terminal/design-system';

export function PendingForm({
  onAdvanceClick,
  onRejectClick,
}: {
  onRejectClick: () => void;
  onAdvanceClick: () => void;
}) {
  return (
    <>
      <Center flexDirection="column">
        <Heading variant="heading-3">Action Required:</Heading>
        <Text>Leave feedback on submission.</Text>
      </Center>
      <Flex direction="row" justifyContent="space-around" mt={4}>
        <Button width="48%" minWidth="48%" variant="solid" size="md" onClick={onAdvanceClick}>
          Advance
        </Button>
        <Button
          width="48%"
          minWidth="48%"
          variant="solid"
          colorScheme="error"
          size="md"
          onClick={onRejectClick}
        >
          Reject
        </Button>
      </Flex>
    </>
  );
}
