// TODO: Convert the rest of isMobile = useBreakpointValue({ base: true, md: false }) to use this hook.

import { useBreakpointValue } from '@terminal/design-system';
import { useState, useMemo } from 'react';

/**
 * Detects if the user is on desktop viewport the width of witch is greater than mobile and tablet viewport
 * @since On component mount it returns undefined, be cautious how you use this utility
 * @todo See if its possible to make this function return the correct return value on mount
 * @returns boolean
 */
export function useIsViewPortDesktop(): boolean | undefined {
  // Had declare ssr: false because of flickering issue when app is not ssr
  // @see: https://chakra-ui.com/docs/hooks/use-breakpoint-value
  return useBreakpointValue({ base: false, md: true }, { ssr: false });
}

/**
 * Allows to sort data by object keys from an array of objects
 * @param {items}: Array of items that want to sort -> items: Array<T> = [],
 * @param {config}: Configuration object with key as the object key to sort & direction for the sorting direction - default null
 *    config: {
        key: string;
        direction: SortDirection;
      }
 * @returns sorted data array, function to trigger a new data sorting with the desired key (column) & object with current sort config
 * @example
       useSortableData(listOfEmployees, {
        key: 'name',
        direction: 'descending',
      });
 */

type SortDirection = 'ascending' | 'descending';
type SupportedRecordTypes = string | null | Date | number;
export function useSortableData<T extends Record<string, SupportedRecordTypes>>(
  items: Array<T> = [],
  config: {
    key: string;
    direction: SortDirection;
  } | null = null,
) {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: SortDirection;
  } | null>(config);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig && items.length && sortableItems.length) {
      sortableItems.sort((a, b) => {
        const first = a[sortConfig.key] ?? 0;
        const second = b[sortConfig.key] ?? 0;
        if (first < second) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (first > second) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    let direction: SortDirection = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
      return setSortConfig(null);
    }

    return setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
}
