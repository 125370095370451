import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useToast } from '@terminal/design-system';
import * as events from 'global/events';
import { getUA } from 'react-device-detect';
import isWebview from 'is-ua-webview';

export function useHandleAuthBackendError({
  error,
  isError,
  errorsWithFriendlyMessage,
  throwIfNoFriendlyError = true,
}: {
  error: any;
  isError: boolean;
  errorsWithFriendlyMessage: { [key: string]: string };
  throwIfNoFriendlyError?: boolean;
}) {
  const toast = useToast({
    position: 'top',
    duration: 6000,
  });

  useEffect(() => {
    if (isError) {
      const friendlyError = errorsWithFriendlyMessage[error?.code || ''];

      if (friendlyError) {
        toast({
          status: 'error',
          description: friendlyError,
        });
      } else if (throwIfNoFriendlyError) {
        // throw error so that Error boundary can catch it and log it in sentry
        throw error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);
}

export class AuthError extends Error {
  public code: string;

  constructor(message: string, code: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.code = code;
  }
}

/**
 *  For a lot of users that open the sign up up from webview as a result of opening
 * the link in twitter, facebook etc apps, we need to hide the Google SSO button
 * to prevent the failure due to a new google sign up security policy.
 * ref: https://developers.googleblog.com/2021/06/upcoming-security-changes-to-googles-oauth-2.0-authorization-endpoint.html
 */
export function useShouldHideGoogleSSO({
  shouldTrack,
  trackingName,
}: {
  /**
   * Tracks when Google SSO should be hidden
   */
  shouldTrack: boolean;
  /**
   * Tracking event name
   */
  trackingName?: string;
}) {
  let shouldHideGoogleSSO = false;

  if (shouldTrack && !trackingName)
    throw new Error('trackingName is required when shouldTrack is true');

  const googleSSOOffenderUserAgentPhrases = [
    /.*\(.*Android [\d.]+;.*wv\).*Version\/.*Chrome\/.*(Mobile )?Safari\//,
    /^Mozilla\/5.0 \((iPhone|iPad|iPod);.*\d{2,}_.*OS.*\).*AppleWebKit((?!( Safari\/|Instagram|FBIOS)).)*$/,
  ];

  if (googleSSOOffenderUserAgentPhrases.some((pattern) => getUA.match(pattern))) {
    shouldHideGoogleSSO = true;
  }

  useEffect(() => {
    if (shouldHideGoogleSSO && shouldTrack) {
      events.track(trackingName as string, {
        user_agent: getUA,
        is_webview: isWebview(getUA),
      });
    }
  }, [shouldHideGoogleSSO, shouldTrack, trackingName]);

  return shouldHideGoogleSSO;
}

export function useAuthContinueUrl() {
  const location = useLocation<{
    from: { pathname: string; search: string; hash: string; url?: string }; // TODO: rename this to to
    to?: { pathname: string; search: string; hash: string; url?: string };
  }>();
  const { from, to } = location.state || {
    from: { pathname: '/', search: location.search },
  };

  const redirectToOptions = to || from;

  const continueURL = `${window.location.origin}${redirectToOptions.pathname}${
    redirectToOptions.search || ''
  }${redirectToOptions.hash || ''}`;

  return continueURL;
}
