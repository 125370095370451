import { NavLink } from 'react-router-dom';
import {
  Box,
  PreferredIcon,
  SearchIcon,
  ShareIcon,
  Tab,
  TabList,
  Tabs,
  Tag,
} from '@terminal/design-system';

export enum ExploreCandidatesTabsLayoutIndex {
  Search = 0,
  SavedByMe = 1,
  SharedWithMe = 2,
}

export function ExploreCandidatesTabsLayout({
  currentTab = 0,
  children,
  savedByMeCandidatesCount,
  sharedWithMeCandidatesCount,
  isInLimitedMode,
  handleOnTabClick,
}: {
  currentTab: number;
  children?: React.ReactNode;
  savedByMeCandidatesCount: number;
  sharedWithMeCandidatesCount: number;
  isInLimitedMode?: boolean;
  handleOnTabClick?: () => void;
}) {
  return (
    <Tabs defaultIndex={currentTab} isLazy>
      <TabList
        pos={['relative', 'relative', 'fixed']}
        w="full"
        bgColor="bg.primary"
        zIndex="docked"
        overflowX={['scroll', 'auto', 'auto']}
        // ! * Required to add the extra space for the active tab border and not create a yaxis scroll bar
        paddingBottom="1px"
      >
        <Tab
          as={NavLink}
          to="/explore-candidates"
          whiteSpace="pre"
          onClick={() => {
            if (handleOnTabClick) {
              handleOnTabClick();
            }
          }}
        >
          <SearchIcon
            w={4}
            h={4}
            mr={2}
            sx={
              currentTab === ExploreCandidatesTabsLayoutIndex.Search
                ? {
                    '& circle': {
                      fill: '#EFD58B',
                      stroke: 'brand.darkest',
                    },
                    '& path': {
                      stroke: 'brand.darkest',
                    },
                  }
                : {}
            }
          />
          Search Candidates
        </Tab>
        <Tab
          as={NavLink}
          to="/explore-candidates/saved-candidates"
          whiteSpace="pre"
          onClick={() => {
            if (handleOnTabClick) {
              handleOnTabClick();
            }
          }}
        >
          <PreferredIcon
            w={4}
            h={4}
            mr={2}
            sx={
              currentTab === ExploreCandidatesTabsLayoutIndex.SavedByMe
                ? {
                    '& path': {
                      stroke: 'brand.darkest',
                      fill: '#EFD58B',
                    },
                  }
                : {}
            }
          />
          Saved by Me
          {!!savedByMeCandidatesCount && (
            <Tag
              color="brand.darker"
              ml={2}
              p={0}
              justifyContent="center"
              size="sm"
              backgroundColor={
                currentTab === ExploreCandidatesTabsLayoutIndex.SavedByMe
                  ? 'brand.lighter'
                  : 'bg.secondary'
              }
              fontWeight={
                currentTab === ExploreCandidatesTabsLayoutIndex.SavedByMe ? 'bold' : 'normal'
              }
            >
              {savedByMeCandidatesCount}
            </Tag>
          )}
        </Tab>
        {!isInLimitedMode && (
          <Tab
            as={NavLink}
            to="/explore-candidates/shared-candidates"
            whiteSpace="pre"
            onClick={() => {
              if (handleOnTabClick) {
                handleOnTabClick();
              }
            }}
          >
            <ShareIcon
              w={4}
              h={4}
              mr={2}
              sx={
                currentTab === ExploreCandidatesTabsLayoutIndex.SharedWithMe
                  ? {
                      '& path': {
                        stroke: 'brand.darkest',
                        fill: '#EFD58B',
                      },
                    }
                  : {
                      '& path': {
                        stroke: 'currentColor',
                      },
                    }
              }
            />
            Shared with Me
            {!!sharedWithMeCandidatesCount && (
              <Tag
                color="brand.darker"
                ml={2}
                p={0}
                justifyContent="center"
                size="sm"
                backgroundColor={
                  currentTab === ExploreCandidatesTabsLayoutIndex.SharedWithMe
                    ? 'brand.lighter'
                    : 'bg.secondary'
                }
                fontWeight={
                  currentTab === ExploreCandidatesTabsLayoutIndex.SharedWithMe ? 'bold' : 'normal'
                }
              >
                {sharedWithMeCandidatesCount}
              </Tag>
            )}
          </Tab>
        )}
      </TabList>
      <Box pt={[0, 0, 10]}>{children}</Box>
    </Tabs>
  );
}
