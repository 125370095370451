// eslint-disable-next-line import/no-extraneous-dependencies
import Retool from 'react-retool';
import { PageLayout } from 'talent-hub/components';
import { useEffect } from 'react';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

export function EditOrganizationController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  // Track page views
  useEffect(() => {
    // Track page view
    // events.track(events.name.viewedChangeOrganization);
  }, []);

  const pageLayoutProps = {
    isClientReviewer,
    isRecruiter,
    orgName: viewingOrganization.name,
    user,
    isClientProspect,
    userPrioritizedRole,
  };

  return (
    <PageLayout headerTitle="Edit Organization" {...pageLayoutProps} disableMaxWidth>
      <Retool
        url={`https://terminal.retool.com/apps/b32a9300-b848-11eb-a33c-93bdc17fc6fc/Admin%20Tools/Organizations%20-%20Edit%20Organization?_embed=true#org=${viewingOrganization.ID}`}
        styling={{
          minHeight: 'calc(100vh - var(--chakra-sizes-20))',
          width: '100%',
        }}
      />
    </PageLayout>
  );
}
