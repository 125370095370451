import React from 'react';
import 'firebase/auth';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import {
  Flex,
  Avatar,
  Text,
  Box,
  Badge,
  Tooltip,
  Heading,
  Button,
  LogoutIcon,
} from '@terminal/design-system';

import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';

const StyledButton = styled(Button)`
  & .hamburger__strong-svg path {
    stroke-width: 2;
  }

  &:not(.active) svg path {
    fill: none;
  }

  & > span {
    width: 100%;
    height: 100%;
  }
`;

function TooltipWrapper({
  showTooltip,
  children,
}: {
  showTooltip: boolean;
  children: React.ReactElement;
}) {
  if (showTooltip) {
    return (
      <Tooltip
        label="Upgrade from your free trial to access this feature of Talent Hub."
        arrowSize={15}
        bg="bg.primary"
        maxW={56}
        ml={3}
        hasArrow
      >
        {React.cloneElement(children, {
          isDisabled: true,
          onClick: (event: React.MouseEvent<HTMLElement>) => event.preventDefault(),
          to: '#',
          sx: {
            '&&': { opacity: 0.4 },
            '& svg path': { fill: 'none' },
          },
        })}
      </Tooltip>
    );
  }

  return children;
}
export function HamburgerMenu({
  userName,
  companyName,
  badgeCopy,
  children,
}: {
  userName?: string;
  companyName?: string;
  badgeCopy?: string;
  children: React.ReactNode;
}) {
  return (
    <Flex pt={10} flex={1} h="full" justifyContent="space-between" flexDir="column">
      <Box>
        <Flex
          p={6}
          pr={14}
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
          w="full"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="ui.secondary"
        >
          <Flex flexDir="row" alignItems="center">
            <Avatar
              size="xl"
              className="notranslate"
              name={userName}
              color="ui.darkest.info"
              bg="brand.lighter"
            />
            <Flex flexDir="column" ml={3}>
              <Heading variant="heading-3">{userName}</Heading>
              <Text variant="caption" fontWeight="bold">
                {companyName}
              </Text>
            </Flex>
          </Flex>
          {badgeCopy && (
            <Badge variant="subtle" colorScheme="warning" size="sm" ml={3}>
              {badgeCopy}
            </Badge>
          )}
        </Flex>
        <Flex w="full" flexDir="column">
          {children}
        </Flex>
      </Box>
      <Box>
        <StyledButton
          onClick={() => signOut({ auth: firebaseAuth })}
          w="full"
          h="3.813rem"
          bgColor="bg.secondary"
        >
          <Flex flexDir="row" w="full" h="full" alignItems="center">
            <LogoutIcon fontSize="lg" mr={3} className="strong-svg" />
            <Text variant="caption" fontWeight="bold">
              Sign Out
            </Text>
          </Flex>
        </StyledButton>
      </Box>
    </Flex>
  );
}

HamburgerMenu.NavLink = function HamburgerMenuNavLink({
  label,
  icon,
  isDisabled,
  isStrongIcon = false,
  ...props
}: { label: string; icon: React.ReactElement; isStrongIcon?: boolean } & React.ComponentProps<
  typeof StyledButton
>) {
  return (
    <TooltipWrapper showTooltip={!!isDisabled}>
      <StyledButton
        as={NavLink}
        w="full"
        sx={{
          '&.active': {
            bgColor: 'accent.lighter',
          },
        }}
        isDisabled={isDisabled}
        {...props}
      >
        <Flex flexDir="row" w="full" h="full" alignItems="center">
          {React.cloneElement(icon, {
            className: isStrongIcon ? '' : 'hamburger__strong-svg',
            mr: 3,
            fontSize: 'lg',
          })}
          <Text variant="caption" fontWeight="bold">
            {label}
          </Text>
        </Flex>
      </StyledButton>
    </TooltipWrapper>
  );
};

HamburgerMenu.Button = function HamburgerMenuButton({
  onClick,
  label,
  icon,
  isDisabled,
  isStrongIcon = false,
  ...props
}: { label: string; icon: React.ReactElement; isStrongIcon?: boolean } & React.ComponentProps<
  typeof StyledButton
>) {
  return (
    <TooltipWrapper showTooltip={!!isDisabled}>
      <StyledButton w="full" isDisabled={isDisabled} onClick={onClick} {...props}>
        <Flex flexDir="row" w="full" h="full" alignItems="center">
          {React.cloneElement(icon, {
            className: isStrongIcon ? '' : 'hamburger__strong-svg',
            mr: 3,
            fontSize: 'lg',
          })}
          <Text variant="caption" fontWeight="bold">
            {label}
          </Text>
        </Flex>
      </StyledButton>
    </TooltipWrapper>
  );
};

HamburgerMenu.Item = function HamburgerMenuItem({
  label,
  icon,
  isDisabled,
  isStrongIcon = false,
  ...props
}: { label: string; icon: React.ReactElement; isStrongIcon?: boolean } & React.ComponentProps<
  typeof StyledButton
>) {
  return (
    <TooltipWrapper showTooltip={!!isDisabled}>
      <StyledButton as="div" isDisabled={isDisabled} w="full" {...props}>
        <Flex flexDir="row" w="full" h="full" alignItems="center">
          {React.cloneElement(icon, {
            className: isStrongIcon ? '' : 'hamburger__strong-svg',
            mr: 3,
            fontSize: 'lg',
          })}
          <Text variant="caption" fontWeight="bold">
            {label}
          </Text>
        </Flex>
      </StyledButton>
    </TooltipWrapper>
  );
};
