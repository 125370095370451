// @ts-nocheck
/* eslint-disable */
import Analytics from 'analytics';
// Using the mixpanel util to maintain consistency in our device reporting. IE, we want to identify
// users in segment the same way that Mixpanel would.
import { _ as mpUtil } from 'mixpanel-browser';
import Cookies from 'js-cookie';
import * as events from '.';
import segmentPlugin from './segment-plugin';

let analytics = null;
let candidateId = null;
let userData = null;
let organizationData = null;

const excludedEvents = []
  .concat(Object.values(events.name.editProfile))
  .concat(Object.values(events.name.profileFlow))

  // Talent Hub events
  .concat([
    events.name.viewedDashboard,
    events.name.viewedYourMembers,
    events.name.navigatedToDayforce,
    events.name.viewedRole,
    events.name.viewedRoleTab,
    events.name.clickedCreateNewRole,
    events.name.startedSubmissionAdvance,
    events.name.startedSubmissionReject,
    events.name.completedSubmissionAdvance,
    events.name.completedSubmissionReject,
    events.name.cancelledSubmission,
    events.name.dismissedModal,
    events.name.viewedTerminalDirectory,
    events.name.viewedChangeOrganization,
  ]);

const preferredRole = (roles) => {
  const roleOrder = [
    'recruiter',
    'client',
    'client-reviewer',
    'client-prospect',
    'candidate',
    'user',
    'authenticated',
  ];

  for (let i = 0; i < roleOrder.length; i += 1) {
    if (roles.includes(roleOrder[i])) {
      return roleOrder[i];
    }
  }

  return null;
};

/* eslint-disable no-unused-vars */
const notImplemented = (name) => {
  return async () => {
    // console.log(`${name} not implemented`);
  };
};
/* eslint-enable no-unused-vars */

let hasInitialized = false;

const initialize = async (meta) => {
  hasInitialized = true;
  let segmentConfig = {
    writeKey: meta.segmentWriteKey,
    skipLoad: meta.segmentSkipLoad || false,
  };

  if (meta.segmentCdn) {
    segmentConfig = {
      ...segmentConfig,
      cdnHost: meta.segmentCdn,
      // Rewriting the URL to get around some ad blocking rules
      customScriptSrc: `https://${meta.segmentCdn}/ajs/v1/${meta.segmentWriteKey}/a.js`,
    };
  }

  analytics = Analytics({
    app: meta.appName,
    plugins: [meta.segmentWriteKey ? segmentPlugin(segmentConfig) : null].filter(Boolean),
  });
  // console.log('Analytics initialized:', meta);
};

const isInitialized = () => {
  return hasInitialized;
};

export type CookieCampaignInfo = {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  cid?: string;
  tracking_id?: string;
};

export function parse_utm_querystring_to_object(
  utm_cookie_params_querystring: string,
): CookieCampaignInfo {
  return utm_cookie_params_querystring.split('&').reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    if (value == null || value == undefined || value == '') return acc;
    acc[key as keyof CookieCampaignInfo] = value;
    return acc;
  }, {});
}

/**
 * This function creates and manages campaign UTC parameters.
 * @function
 * @param {Object} campaign_params - An object containing the following properties:
 *   - utm_campaign {string}
 *   - utm_source {string}
 *   - utm_medium {string}
 *   - utm_term {string}
 *   - utm_content {string}
 * @returns {Object} - An object containing the campaign parameters, either from the provided campaignParams object or from cookie if available.
 */
function createCampaignParams() {
  const utm_cookie_params_querystring: string = Cookies.get('utm_cookie_params') ?? '';

  return parse_utm_querystring_to_object(utm_cookie_params_querystring);
}

const getContext = () => {
  const mpProperties = mpUtil.info.properties();
  const campaignParams = createCampaignParams();

  const campaignFiltered = Object.fromEntries(
    Object.entries({
      name: campaignParams.utm_campaign,
      source: campaignParams.utm_source,
      medium: campaignParams.utm_medium,
      term: campaignParams.utm_term,
      content: campaignParams.utm_content,
    }).filter(([_, value]) => value !== null && value !== undefined && value !== ''),
  );

  return {
    os: {
      name: mpProperties.$os || null,
    },
    browser: {
      name: mpProperties.$browser || null,
      version: mpProperties.$browser_version || null,
    },
    device: {
      name: mpProperties.$device || null,
    },
    screen: {
      height: mpProperties.$screen_height || null,
      width: mpProperties.$screen_width || null,
    },
    campaign: campaignFiltered,
    user: userData,
    organization: organizationData,
  };
};

const identify = async (id, options) => {
  try {
    const { candidate, user } = options;
    const userId = id ? `${id}` : null;
    candidateId = (candidate && candidate.id) || null;
    const roles = user.user_roles.map((user_role) => user_role.role.value) || [];

    userData = {
      // Segment can't filter by "candidate in user.roles" but we can check "user.roles.candidate = true"
      roles:
        roles.reduce((result, role) => {
          result[role] = true;
          return result;
        }, {}) || {},
      preferredRole: preferredRole(roles),
      id: userId,
      email: user.email,
    };

    await analytics.identify(
      userId,
      {
        first_name: (candidate && candidate.first_name) || (user && user.first_name) || null,
        last_name: (candidate && candidate.last_name) || (user && user.last_name) || null,
        email: user.email,
        user_id: userId,
        candidate_id: candidateId,
        roles,
        preferred_role: preferredRole(roles),
      },
      getContext(),
    );

    if (user.organization_data) {
      const { id: org_id, name: org_name, salesforce_customer } = user.organization_data;
      organizationData = {
        id: org_id,
        name: org_name,
        salesforce_status: salesforce_customer?.status,
      };
      await analytics.plugins.segment.group(org_id, {
        name: org_name,
        salesforce_status: salesforce_customer?.status,
      });
    }
  } catch (err) {
    // console.log('FAILED to identify user', id);
  }
};

const track = async (name, properties) => {
  try {
    if (!excludedEvents.includes(name)) {
      await analytics.track(name, properties, getContext());
    }
  } catch (err) {
    // console.log('FAILED to track', name, properties);
  }
};

const trackPage = async (category, name, properties) => {
  try {
    await analytics.page(
      {
        category,
        name: name || document.title.replace(/ \| Terminal$/, ''),
        properties,
      },
      getContext(),
    );
  } catch (err) {
    // console.log('FAILED to trackPage', category, name, properties);
  }
};

const setUserProperties = async (_name, email) => {
  try {
    // Note: This can be called from firebase where we only have a single name
    await analytics.identify(
      userData.id,
      {
        email: email.toLowerCase().trim(),
      },
      getContext(),
    );
  } catch (err) {
    // console.log('FAILED to set user properties');
  }
};

export default {
  name: 'segment',
  initialize,
  isInitialized,
  identify,
  setUserProperties,
  track,
  trackPage,
  reset: notImplemented('reset'),
};
