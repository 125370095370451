import { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, useToast } from '@terminal/design-system';
import { PasswordFieldWithInlineValidator, createPasswordYupValidation } from '../components';

const passwordResetValidationSchema = Yup.object().shape({
  password: createPasswordYupValidation(),
});

export interface PasswordResetFormProps {
  // errorMessage?: string | null; // TODO: is not being used
  onResetPasswordSubmit: (newPassword: string) => void;
  showLoading: boolean;
}

export function PasswordResetForm({ onResetPasswordSubmit, showLoading }: PasswordResetFormProps) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const validationToastIDRef = useRef<string | number | undefined>();
  const submissionCount = useRef<number>(0);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordResetValidationSchema,
    onSubmit: (values) => onResetPasswordSubmit(values.password),
  });

  const hasValidationError = formik.errors.password && formik.touched.password;

  useEffect(() => {
    if (hasValidationError) {
      validationToastIDRef.current = toast({
        status: 'error',
        description: 'Please review your entry and try again.',
      });
    } else if (validationToastIDRef.current) {
      toast.close(validationToastIDRef.current);
    } else {
      submissionCount.current += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionCount.current]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <PasswordFieldWithInlineValidator
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors.password && !!formik.touched.password}
        placeholder="New Password"
        title="New Password"
        touched={!!formik.touched.password}
        value={formik.values.password}
      />

      <Button
        isLoading={showLoading}
        onClick={() => {
          submissionCount.current += 1;
        }}
        variant="solid"
        colorScheme="primary"
        w="full"
        mt={6}
        type="submit"
      >
        Reset Password
      </Button>
    </form>
  );
}
