import { defineStyle } from '@chakra-ui/styled-system';

export const formControlTheme = {
  baseStyle: {
    container: {
      w: 'full',
    },
    helperText: defineStyle({
      mt: '2',
      color: 'text.secondary',
      lineHeight: 'normal',
      fontSize: 'sm',
    }),
  },
  parts: ['container', 'helperText'],
};
