import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  CoffeeCupIcon,
  Badge,
} from '@terminal/design-system';
import type { ProfileInfo } from '../../Role.types';

export function JobCandidateTable({
  candidates,
  status,
  columnName,
  actionName = 'View Feedback',
  emptyStateText = 'No candidates to display',
  shouldShowFeedbackCTA,
}: {
  candidates: ProfileInfo[];
  status: string;
  columnName: string;
  actionName?: string;
  emptyStateText?: string;
  shouldShowFeedbackCTA?: boolean;
}) {
  const { activeRole } = useParams<{ activeRole: string }>();

  if (!candidates.length)
    return (
      <Center alignItems="center" flexDirection="column">
        <Box textAlign="center" w={['full', 'sm']}>
          <CoffeeCupIcon h={20} w={20} mb="2" />
          <Text>{emptyStateText}</Text>
        </Box>
      </Center>
    );

  return (
    <TableContainer whiteSpace="break-spaces">
      <Table>
        <Thead>
          <Tr>
            <Th>Candidate</Th>
            <Th>Title</Th>
            <Th>{columnName}</Th>
            <Th>{status === 'COMPLETE' && 'Status'}</Th>
            {shouldShowFeedbackCTA && <Th />}
          </Tr>
        </Thead>
        <Tbody>
          {candidates.map(({ id, name, company, title, date, statusName, statusColorScheme }) => (
            <Tr key={`${id}-${date}`}>
              <Td>
                <Link variant="label" to={`/role/${activeRole}/candidate/${id}`} as={RouterLink}>
                  {name}
                </Link>
                <Text variant="caption" mt={1}>
                  {company || '-'}
                </Text>
              </Td>
              <Td>{title || '-'}</Td>
              <Td>{date}</Td>
              <Td>
                {status === 'UPCOMING' && (
                  <Button
                    size="md"
                    variant="outline"
                    to={`/role/${activeRole}/candidate/${id}`}
                    as={RouterLink}
                  >
                    View Candidate
                  </Button>
                )}
                {status === 'PENDING' && (
                  <Button
                    variant="solid"
                    size="md"
                    to={`/role/${activeRole}/candidate/${id}`}
                    as={RouterLink}
                  >
                    {actionName}
                  </Button>
                )}
                {status === 'COMPLETE' && statusName && (
                  <Badge fontSize="sm" colorScheme={statusColorScheme}>
                    {statusName}
                  </Badge>
                )}
              </Td>
              {shouldShowFeedbackCTA && (
                <Td>
                  <Button
                    size="md"
                    variant="outline"
                    to={{
                      pathname: `/role/${activeRole}/candidate/${id}/feedback`,
                      state: { from: 'role-stage-candidate-list' },
                    }}
                    as={RouterLink}
                  >
                    Review Feedback
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
