import { useMutation } from '@apollo/client';
import { useQuery } from 'global/utils';
import { useDisclosure, useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import { useState } from 'react';
import { useCandidateInformation } from 'talent-hub/components';
import moment from 'moment';
import { serializeToInsertSharedCandidate } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { useCustomerAuthorizedUserSession } from '../authorizedUserSession';
import {
  InsertInterviewInvite,
  SelectCandidateProfile,
  InsertSavedCandidate,
  DeleteSavedCandidate,
  SelectFavoriteCandidatesCounts,
  InsertSharedCandidate,
  SelectTeamMembers,
} from './data';
import type {
  InsertInterviewInvitationMutation,
  InsertInterviewInvitationMutationVariables,
  InsertSavedCandidateMutationVariables,
  InsertSavedCandidateMutation,
  DeleteSavedCandidateMutation,
  DeleteSavedCandidateMutationVariables,
  SelectFavoriteCandidatesCountsQuery,
  SelectFavoriteCandidatesCountsQueryVariables,
  InsertSharedCandidateMutation,
  InsertSharedCandidateMutationVariables,
  SelectTeamMembersQuery,
  SelectTeamMembersQueryVariables,
} from './data';

export const ViewType = {
  Pending: 'Pending',
  Submitted: 'Submitted',
} as const;

// ! i think this abstraction could be eliminated, I don't see it as required
export function useCandidateModalInfo_withInvitation({
  handleOnCloseModal,
  candidateID,
}: {
  handleOnCloseModal?: () => void;
  candidateID?: number;
}) {
  const inviteModal = useDisclosure();
  const [inviteView, setInviteView] = useState<keyof typeof ViewType>(ViewType.Pending);
  const { isClientReviewer, isClientProspect, user, viewingOrganization } =
    useCustomerAuthorizedUserSession();

  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const [createInvitation] = useMutation<
    InsertInterviewInvitationMutation,
    InsertInterviewInvitationMutationVariables
  >(InsertInterviewInvite, {
    onCompleted: () => {
      setInviteView(ViewType.Submitted);
    },
    onError: (mutationError) => {
      toast({
        status: 'error',
        description: 'Something went wrong with this invite request. Please try again!',
      });
      Sentry.captureException(mutationError);
    },
  });

  const {
    data: candidateInformationData,
    loading: loadingCandidateInformation,
    error: candidateQueryErrors,
  } = useQuery(SelectCandidateProfile, {
    variables: {
      organization: viewingOrganization.ID,
      candidate_id: candidateID,
      isClientReviewer,
      savedByUserId: user?.id,
      updatedAfter: moment().subtract(45, 'days').utc().format('YYYY-MM-DD'),
      isClientProspect,
    },
    fetchPolicy: 'network-only',
    skip: !candidateID,
  });
  const { profile, handleResumeLinkClicked, candidateHighlights } =
    useCandidateInformation(candidateInformationData);

  const onCloseInviteModalClick = () => {
    if (handleOnCloseModal) {
      handleOnCloseModal();
    }
    inviteModal.onClose();
    setInviteView(ViewType.Pending);
  };

  return {
    createInvitation,
    inviteView,
    inviteModal,
    onCloseInviteModalClick,
    loadingCandidateInformation,
    candidateQueryErrors,
    profile: {
      ...profile,
      isSaved:
        (candidateInformationData?.candidate?.[0]?.saved_candidates_aggregate?.aggregate?.count ||
          0) > 0,
      id: Number(candidateID),
    },
    handleResumeLinkClicked,
    candidateHighlights,
  };
}

export function useSavedCandidates({
  insertSavedCandidate_onCompletedHandler,
  deleteSavedCandidate_onCompletedHandler,
}: {
  insertSavedCandidate_onCompletedHandler?: () => void;
  deleteSavedCandidate_onCompletedHandler?: () => void;
} = {}) {
  const { user } = useCustomerAuthorizedUserSession();

  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });
  const [insertSavedCandidate] = useMutation(InsertSavedCandidate, {
    onError: (mutationError) => {
      toast({
        title: 'Error saving candidate',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
    optimisticResponse: ({
      candidate_id,
    }: InsertSavedCandidateMutationVariables): InsertSavedCandidateMutation => ({
      insert_saved_candidate_one: {
        candidate_id,
      },
    }),
    onCompleted: () => {
      if (insertSavedCandidate_onCompletedHandler) insertSavedCandidate_onCompletedHandler();
    },
  });

  const [deleteSavedCandidate] = useMutation(DeleteSavedCandidate, {
    onError: (mutationError) => {
      toast({
        title: 'Error removing candidate',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
    optimisticResponse: ({
      candidate_id,
    }: DeleteSavedCandidateMutationVariables): DeleteSavedCandidateMutation => ({
      delete_saved_candidate: {
        returning: [
          {
            candidate_id,
          },
        ],
      },
    }),
    onCompleted: () => {
      if (deleteSavedCandidate_onCompletedHandler) deleteSavedCandidate_onCompletedHandler();
    },
  });

  // Favorite candidates count
  const {
    data: favoriteCandidatesCount,
    refetch: refetchSelectFavoriteCandidatesCounts,
    loading: loadingFavoriteCandidatesCount,
  } = useQuery<SelectFavoriteCandidatesCountsQuery, SelectFavoriteCandidatesCountsQueryVariables>(
    SelectFavoriteCandidatesCounts,
    {
      variables: {
        user_id: user?.id || 0,
      },
    },
  );

  return {
    deleteSavedCandidate,
    insertSavedCandidate,
    savedByMeCandidatesCount:
      favoriteCandidatesCount?.saved_candidate_aggregate?.aggregate?.count || 0,
    sharedWithMeCandidatesCount:
      favoriteCandidatesCount?.shared_candidate_aggregate?.aggregate?.count || 0,
    refetchSelectFavoriteCandidatesCounts,
    loadingFavoriteCandidatesCount,
  };
}

export function useSharedCandidates({
  insertSharedCandidate_onCompletedHandler,
}: {
  insertSharedCandidate_onCompletedHandler?: () => void;
}) {
  const { user, viewingOrganization } = useCustomerAuthorizedUserSession();
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  // insert shared_candidate
  const [insertSharedCandidate] = useMutation<
    InsertSharedCandidateMutation,
    InsertSharedCandidateMutationVariables
  >(InsertSharedCandidate, {
    // TODO: [TAL-792] update the number in shared with me tab
    refetchQueries: [SelectTeamMembers],
    onError: (mutationError) => {
      toast({
        title: 'Error sharing the candidate',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
    onCompleted: () => {
      // TODO: [TAL-792] update the number in shared with me tab
      if (insertSharedCandidate_onCompletedHandler) insertSharedCandidate_onCompletedHandler();
    },
  });

  // get team members to share candidates with
  const { data: teamMembers } = useQuery<SelectTeamMembersQuery, SelectTeamMembersQueryVariables>(
    SelectTeamMembers,
    {
      variables: {
        organization_id: viewingOrganization.ID,
        current_user_id: user?.id || 0,
      },
    },
  );

  // handle insert share candidate function
  const handleOnInsertSharedCandidate = (
    candidateId: number,
    teamMembersIds: string[],
    onCompleted: () => void,
  ) => {
    insertSharedCandidate({
      variables: {
        ...serializeToInsertSharedCandidate(
          teamMembersIds,
          candidateId,
          user?.id || 0,
          viewingOrganization.ID,
        ),
      },
      onCompleted,
    });
  };

  return {
    insertSharedCandidate,
    teamMembers,
    handleOnInsertSharedCandidate,
  };
}
