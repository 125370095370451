import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { forwardRef, Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';
import { Step } from './Step';

export interface StepsProps extends FlexProps {
  /**
   * Steps to be shown
   */
  stepsList: {
    title: string;
    color: 'brand' | 'success' | 'primary';
    icon?: React.ReactElement;
  }[];
}

/**
 * Extends the Flex Chakra component to render a set of <Step>
 */
export const Steps = forwardRef<StepsProps, 'div'>(({ stepsList, ...rest }, ref) => {
  return (
    <Flex borderRadius={100} {...rest} bg="ui.inverse.primary" w="max" h="fit-content" ref={ref}>
      {stepsList.map(({ title, color, icon }) => (
        <Step colorScheme={color} leftIcon={icon} key={uuidv4()}>
          {title}
        </Step>
      ))}
    </Flex>
  );
});
