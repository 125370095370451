import type * as Types from 'global/types/hasura-tables.generated.types';
import { reduceToEarliestDate } from 'global/utils';
import { JOB_FOLDER_APPROVED } from 'talent-hub/constants/statusMap';

type ApplicantWorkflow = Array<{
  workflow_statuses: Array<
    { date: Types.Icims_Applicant_Workflow_Status['created_at'] } & {
      status?: Types.Maybe<{ title: Types.Icims_Status['value'] }>;
    }
  >;
}>;

type PostingAndPositionRequiredFragmentFields = Pick<
  Types.Icims_Job,
  'initial_headcount' | 'created_at'
> & { id: Types.Icims_Job['profile_id']; name: Types.Icims_Job['jobtitle'] } & {
  accurate_date: Pick<Types.Icims_Profile_Job, 'created_date' | 'last_approved'>;
  icims_folder?: Types.Maybe<Pick<Types.Icims_Folder, 'value'>>;
  applicant_workflows: ApplicantWorkflow;
};

type PostingRequiredFields = {
  positions: Array<PostingAndPositionRequiredFragmentFields>;
  // TODO: this location will be removed in the future since is not being used
  locations?: Array<{
    icims_location?: Types.Maybe<
      Pick<Types.Icims_Location, 'value'> & {
        readable_locations: Array<Pick<Types.Location, 'value' | 'city' | 'id'>>;
      }
    >;
  }>;
} & PostingAndPositionRequiredFragmentFields;

type PositionRequiredFields = PostingRequiredFields['positions'][number];

export type MergedPostingAndPosition<T> = Omit<T, 'created_at' | 'accurate_date'> & {
  openHeadCount: number;
  closedHeadCount: number | null;
  openedDate: string;
};

export const selectDateByFavoringApprovedDate = (
  objectDate: Pick<PositionRequiredFields, 'created_at' | 'accurate_date'>,
) => {
  return (
    objectDate.accurate_date?.last_approved ||
    objectDate.accurate_date?.created_date ||
    objectDate.created_at
  );
};

/**
 * Find the correct posting created date:
 * - If any associated positions exist, get the earliest created date across all of them
 * - Otherwise, use the date from the posting itself
 * NOTE: In all cases, accurate_date.created_date is preferred over created_at
 */
function findPostingAccurateCreatedDate<T extends PostingRequiredFields>(posting: T): string {
  const earliestApprovedPositionDate = posting.positions
    .map((position) => position.accurate_date?.last_approved)
    .filter(Boolean)
    .reduce(reduceToEarliestDate, null);

  if (earliestApprovedPositionDate) {
    return earliestApprovedPositionDate;
  }

  const earliestPositionAccurateDate = posting.positions
    .map((position) => position.accurate_date?.created_date)
    .filter(Boolean)
    .reduce(reduceToEarliestDate, null);

  if (earliestPositionAccurateDate) {
    return earliestPositionAccurateDate;
  }

  // preferring posting.accurate_date.last_approved & posting.accurate_date.created_at over
  // earliest positions created_at
  return selectDateByFavoringApprovedDate(posting);
}

function mergePositionsHeadCountsAndWorkflows<T extends PositionRequiredFields>(
  positions: T[],
): {
  openHeadCount: number;
  closeHeadcount: number;
  applicantWorkflows: T['applicant_workflows'];
} {
  type Accumulator = {
    openHeadCount: number;
    closeHeadcount: number;
    applicantWorkflows: T['applicant_workflows'];
  };
  return positions.reduce<Accumulator>(
    (acc, position) => {
      return {
        applicantWorkflows: [...acc.applicantWorkflows, ...position.applicant_workflows],
        openHeadCount:
          position.icims_folder?.value === JOB_FOLDER_APPROVED
            ? acc.openHeadCount + 1
            : acc.openHeadCount,
        closeHeadcount:
          position.icims_folder?.value !== JOB_FOLDER_APPROVED
            ? acc.closeHeadcount + 1
            : acc.closeHeadcount,
      };
    },
    {
      openHeadCount: 0,
      closeHeadcount: 0,
      applicantWorkflows: [] as T['applicant_workflows'],
    },
  );
}

/**
 * Merges posting its position's applicant workflows and calculate the posting head count
 */
export function mergePostingsAndPositions<T extends PostingRequiredFields>(
  postings: T[],
): MergedPostingAndPosition<T>[] {
  return postings.map((posting) => {
    const openedDate = findPostingAccurateCreatedDate(posting);

    const mergedPositions = mergePositionsHeadCountsAndWorkflows(posting.positions);

    // Set the correct headcount for the position:
    // - If open positions exist and are Approved, get the count of those positions
    // - Otherwise, if initial_headcount exists for the posting use that
    // - Otherwise, just set the headcount to 1 by default
    let openHeadCount: number = 0;
    let closedHeadCount: number | null = 0;
    if (mergedPositions.openHeadCount + mergedPositions.closeHeadcount > 0) {
      openHeadCount = mergedPositions.openHeadCount;
      closedHeadCount = mergedPositions.closeHeadcount;
    } else {
      openHeadCount = posting?.initial_headcount || 0 >= 1 ? posting.initial_headcount || 0 : 1;
      closedHeadCount = null;
    }

    const { created_at, accurate_date, ...restPosting } = posting;

    return {
      ...restPosting,
      applicant_workflows: [
        ...posting.applicant_workflows,
        ...mergedPositions.applicantWorkflows, // Even though applicant workflow should not be added to a posting's position, in our database, sometimes they are added there
      ],
      openedDate,
      openHeadCount,
      closedHeadCount,
    };
  });
}
