import { useEffect, useState } from 'react';
import { useToast, useClipboard, Text, Button, ErrorCircleIcon } from '@terminal/design-system';
import { AuthMessage, AuthPageTemplate } from '../components';
import type { AuthPageTemplateProps } from '../types';

export function IncompatibleBrowserErrorPage({
  pageTemplateOverwrite,
}: {
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const toast = useToast({
    status: 'success',
    duration: 4000,
  });

  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const { hasCopied, onCopy } = useClipboard(`${window.location.href}&btm=webview`, 10000000);

  const handleOnActionClick = () => {
    onCopy();
    setIsActionDisabled(true);

    setTimeout(() => {
      setIsActionDisabled(false);
    }, 4000);
  };

  useEffect(() => {
    if (hasCopied && isActionDisabled) {
      toast({
        title: 'Link Copied!',
        description: 'Paste the link on a browser to continue signing up.',
      });
    }
    // toast is a static design system component and doesn't need to be included in dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, isActionDisabled]);

  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate>
      <AuthMessage icon={<ErrorCircleIcon />} title="Something went wrong.">
        <Text>
          There was an error accessing this page. Please use the button below to copy the link and
          paste it on your browser to continue signing up.
        </Text>
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={handleOnActionClick}
          mt={6}
          w={64}
          isDisabled={isActionDisabled}
        >
          Copy Link
        </Button>
      </AuthMessage>
    </PageTemplate>
  );
}
