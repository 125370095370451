import { createIcon } from '@terminal/design-system';

export const ResumeProfile = createIcon({
  displayName: 'ResumeProfile',
  viewBox: '0 0 64 64',
  path: (
    <>
      <path
        d="M7 13C7 12.4477 7.44772 12 8 12H48C48.5523 12 49 12.4477 49 13V62C49 62.5523 48.5523 63 48 63H14C10.134 63 7 59.866 7 56V13Z"
        fill="white"
        stroke="#25383F"
        strokeWidth="2"
      />
      <circle cx="17" cy="34" r="2" fill="#F9C66C" />
      <circle cx="17" cy="43" r="2" fill="#F9C66C" />
      <circle cx="17" cy="25" r="2" fill="#F9C66C" />
      <path
        d="M48.9432 63C52.2883 63 55 60.5056 55 57.4286V56H20.0568V56.9432C20.0568 60.2883 17.3451 63 14 63H48.9432Z"
        fill="#25383F"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line x1="22" y1="34" x2="41" y2="34" stroke="#25383F" strokeWidth="2" />
      <line x1="22" y1="43" x2="41" y2="43" stroke="#25383F" strokeWidth="2" />
      <line x1="22" y1="25" x2="32" y2="25" stroke="#25383F" strokeWidth="2" />
      <circle cx="50" cy="15" r="12" fill="#25383F" stroke="#25383F" strokeWidth="2" />
      <circle cx="51" cy="14" r="12" fill="white" stroke="#25383F" strokeWidth="2" />
      <path
        d="M60.5 21C57.5 17.6667 49.5 13 41.5 21"
        stroke="#25383F"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle cx="51" cy="10" r="3" stroke="#25383F" strokeWidth="2" />
    </>
  ),
});
