import type { MutableRefObject } from 'react';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 *
 * @example
 * url: /account?name=netflix
 * const queryString = useQueryString();
 * queryString.get("name") // returns "netflix"
 *
 * @todo Does this need to be use hook? Can we just access the query params from browser history
 * instead of useLocation? Using hook has that cost that it needs be used before any conditionals.
 */
export function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Returns the current window width and height by reacting to window resize events.
 */
export function useWindowSize(): { width: number; height: number } {
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

/**
 * Returns the previous value
 * @see https://usehooks.com/usePrevious/
 */
export function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

/**
 * This hook allows you to easily detect when an element is visible on the screen as well as specify how much of the element should be visible before being considered on screen. Perfect for lazy loading images or triggering animations when the user has scrolled down to a particular section.
 * @see https://usehooks.com/useOnScreen/
 */
export function useOnScreen(
  ref: MutableRefObject<null>,
  rootMargin: string = '0px',
): boolean | null {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry!.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref?.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

/**
 * Creates and returns a function that abstract route redirection.
 * By default it moves the scroll to the top with smooth animation.
 */
export function useRedirection({
  onBeforeRedirection: global_OnBeforeRedirection,
  onAfterRedirection: global_onAfterRedirection,
}: {
  onBeforeRedirection?: () => void;
  onAfterRedirection?: () => void;
} = {}) {
  const history = useHistory();
  const queryString = new URLSearchParams(useLocation().search);

  return (
    redirectPath: string,
    {
      shouldPassQuerystringAlong,
      shouldReplacePath,
      shouldScrollToTopOnChange,
      scrollAnimationBehavior,
      routeChangeState,
      onBeforeRedirection = global_OnBeforeRedirection,
      onAfterRedirection = global_onAfterRedirection,
    }: {
      /** When true, it automatically suffixes the path with the current querystring  */
      shouldPassQuerystringAlong?: boolean;
      /** When true it would use history.replace instead of history.push. */
      shouldReplacePath?: boolean;
      shouldScrollToTopOnChange?: boolean;
      scrollAnimationBehavior?: ScrollBehavior;
      routeChangeState?: unknown;
      onBeforeRedirection?: () => void;
      onAfterRedirection?: () => void;
    } = {
      shouldPassQuerystringAlong: false,
      shouldReplacePath: false,
      shouldScrollToTopOnChange: true,
      scrollAnimationBehavior: 'smooth',
    },
  ): void => {
    const redirectFunc = shouldReplacePath ? history.replace : history.push;

    if (/^https?/.test(redirectPath)) {
      window.open(redirectPath, '_blank');
      return;
    }

    if (shouldPassQuerystringAlong) {
      if (onBeforeRedirection) {
        onBeforeRedirection();
      }
      redirectFunc(`${redirectPath}?${queryString}`, routeChangeState);
      if (onAfterRedirection) {
        onAfterRedirection();
      }
    } else {
      if (onBeforeRedirection) {
        onBeforeRedirection();
      }
      redirectFunc(redirectPath, routeChangeState);
      if (onAfterRedirection) {
        onAfterRedirection();
      }
    }

    if (shouldScrollToTopOnChange) {
      window.scrollTo({ top: 0, left: 0, behavior: scrollAnimationBehavior });
    }
  };
}

/**
 * Custom hook to use Local Storage in React.
 *
 * @param {string} key - The key to use in Local Storage.
 * @param {T} initialValue - The initial value to use if there's no value in Local Storage.
 * @returns {[T, (value: T | ((prevState: T) => T)) => void]} - An array containing the current value and a function to update it.
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((prevState: T) => T)) => void] {
  const [state, setState] = useState((prevState: T) => {
    try {
      // Initialize the state from Local Storage, or use the initialValue if there's no value in Local Storage.
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      // console.error(`Error reading ${key} from Local Storage:`, error);
      return prevState;
    }
  });

  /**
   * Update the state and save the value to Local Storage.
   * If the passed value is a function, call it with the current state to get the new value.
   * @param {T | ((prevState: T) => T)} value - The new value to set.
   */
  const setValue = (value: T | ((prevState: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(state) : value;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      setState(value);
    } catch (error) {
      // console.error(`Error writing ${key} to Local Storage:`, error);
    }
  };

  return [state, setValue];
}
