import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ScrollToTopOnPathChange } from 'talent-hub/components';
import { ExploreCandidatesController } from './explore-candidates';
import { SavedCandidatesController } from './saved-candidates';

export function ExploreCandidatesRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={[`${path}/saved-candidates/candidate/:candidateID`, `${path}/saved-candidates`]}
      >
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <SavedCandidatesController isInLimitedMode />
      </Route>
      <Route exact path={[`${path}/candidate/:candidateID`, path]}>
        <ScrollToTopOnPathChange shouldDisableAutoScroll />
        <ExploreCandidatesController isInLimitedMode />
      </Route>
    </Switch>
  );
}
