import { useState } from 'react';
import {
  useBreakpointValue,
  Flex,
  Heading,
  Box,
  Grid,
  GridItem,
  Container,
  Text,
  Button,
  Tag,
  ArrowRightIcon,
  ViewOnIcon,
  CheckCircleSolidIcon,
  ProgrammingIcon,
  EducationIcon,
  MonitorIcon,
  LocationIcon,
  CalendarIcon,
  ActiveIcon,
  Badge,
  SimpleGrid,
  DurationIcon,
} from '@terminal/design-system';
import { Link } from 'react-router-dom';
import { Logo as TerminalLogo } from 'global/components/Logo';
import { BlankScreenLoading, CardWithViewMore } from 'global/components';
import { stripAllHTMLAttributes } from 'global/utils';
import { CustomField } from 'talent-hub/components';
import type { serializePublicCandidateProfile } from './CandidateProfile.serializer';

export function CandidateProfile({
  isLoading,
  candidateProfileURL,
  candidate,
  matchingCandidates,
}: {
  isLoading: boolean;
  candidateProfileURL: string;
  candidate: ReturnType<typeof serializePublicCandidateProfile>['candidate'];
  matchingCandidates: ReturnType<typeof serializePublicCandidateProfile>['matchingCandidates'];
}) {
  const logoHeight = useBreakpointValue([8, 10, 10], { ssr: false }) || 10;
  const [shouldShowFullRecruiterSummary, setShouldShowFullRecruiterSummary] = useState(false);

  if (isLoading) return <BlankScreenLoading />;

  const { badges, highlights, activeMessage, profile } = candidate || {};

  const {
    firstName,
    lastNameInitial,
    currentRole,
    desiredStart,
    educations, // : { title, subTitle, datesTitle },
    experience,
    location,
    remoteReadiness,
    topSkills,
    workExperiences, // : { title, employer, employmentYears, employmentDuration },
    noticePeriod,
    recruiterSummary,
  } = profile || {};

  const handleSignUpLink = {
    as: Link,
    to: {
      pathname: '/trial/signup',
      state: {
        to: { pathname: candidateProfileURL },
      },
    },
  };

  return (
    <>
      <Box
        as="nav"
        w="full"
        px={[3, 3, 8]}
        py={[3, 3, 5]}
        borderColor="ui.secondary"
        borderBottomWidth="1px"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Box height={logoHeight}>
            <TerminalLogo />
          </Box>
          <Box>
            <Button variant="solid" {...handleSignUpLink}>
              Sign Up
            </Button>
          </Box>
        </Flex>
      </Box>

      <Container w="full" maxW="85.5rem" mt={10}>
        <Grid
          templateColumns={['100%', '100%', 'minmax(37rem, 1fr) minmax(min-content, 30rem)']}
          gap={[0, 0, 10]}
        >
          <GridItem pos="relative" pb={[0, 0, 18]}>
            <Flex flexDir="row" justifyContent="space-between" flexWrap="nowrap">
              <Box>
                <Heading variant="heading-1">
                  {firstName} {lastNameInitial}
                </Heading>
                <Heading variant="heading-3" color="text.secondary">
                  {currentRole}
                </Heading>
                <Flex alignItems="center" mt={1}>
                  {activeMessage?.isActivelyInterviewing ? (
                    <ViewOnIcon mr={1} />
                  ) : (
                    <ActiveIcon mr={1} color="text.secondary" />
                  )}
                  <Text
                    color="text.secondary"
                    variant="caption"
                    dangerouslySetInnerHTML={{
                      __html: stripAllHTMLAttributes(activeMessage?.text || ''),
                    }}
                  />
                </Flex>
              </Box>
              <Flex
                flexDir={{ base: 'row', sm: 'column' }}
                justifyContent="flex-start"
                alignItems="flex-end"
                alignContent="flex-end"
                gap={2}
                my={2}
                ml={2}
                flex={1}
                flexWrap="wrap"
                maxHeight={24}
                maxWidth="50%"
              >
                {badges?.map(
                  (item) =>
                    item && (
                      <Badge colorScheme={item.variant} mr={2} size="lg">
                        {item.text}
                      </Badge>
                    ),
                )}
              </Flex>
            </Flex>

            {!!topSkills?.length && (
              <Box mt={6}>
                <Flex gridGap={3} flexWrap="wrap" alignItems="center">
                  {topSkills.slice(0, 4).map((name) => (
                    <Tag
                      px={2}
                      size="lg"
                      variant="subtle"
                      colorScheme="disabled"
                      key={name}
                      h="full"
                    >
                      {name}
                    </Tag>
                  ))}
                  {!!topSkills.slice(4).length && (
                    <Text variant="caption" minW="5.625rem" mt={1}>
                      + {topSkills.slice(4).length} more skill
                      {topSkills.slice(4).length > 1 ? 's' : ''}
                    </Text>
                  )}
                </Flex>
              </Box>
            )}
            {!!highlights?.length && (
              <Box bgColor="bg.tertiary" mt={4} p={6}>
                <Text variant="label" pb={3} borderBottomWidth="1px" borderColor="ui.secondary">
                  {firstName}&apos;s Highlights
                </Text>
                <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap={3} columnGap={2} mt={4}>
                  {highlights.map((highlight) => {
                    if (!highlight) return null;
                    return (
                      <Flex alignItems="center" key={highlight}>
                        <CheckCircleSolidIcon color="ui.success" mr={2} />
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: stripAllHTMLAttributes(highlight || ''),
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Grid>
              </Box>
            )}

            <SimpleGrid
              mt={6}
              columns={2}
              templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
              gridGap={4}
            >
              <CustomField
                label="Current Position"
                value={currentRole}
                icon={<ProgrammingIcon w={6} h={6} />}
              />
              <CustomField
                label="Years of Experience"
                value={experience}
                icon={<EducationIcon w={6} h={6} />}
              />
              <CustomField label="Location" value={location} icon={<LocationIcon w={6} h={6} />} />
              <CustomField
                label="Desired Start Date"
                value={desiredStart}
                icon={<CalendarIcon w={6} h={6} />}
              />
              <CustomField
                label="Remote Readiness"
                value={remoteReadiness ? `${remoteReadiness} Remote Experience` : null}
                icon={<MonitorIcon w={6} h={6} />}
              />
              {noticePeriod && (
                <CustomField
                  label="Notice Period"
                  value={noticePeriod}
                  icon={<DurationIcon w={6} h={6} />}
                />
              )}
            </SimpleGrid>

            {!!recruiterSummary && (
              <Box pt={[4, 4, 8]} w="full">
                <Text color="text.primary" variant="label" mb={2}>
                  Recruiter Summary
                </Text>
                <Box pos="relative">
                  <CardWithViewMore
                    header={null}
                    descriptionVariant="body"
                    descriptionProps={{
                      whiteSpace: 'pre-wrap',
                    }}
                    shouldShowFullBody={shouldShowFullRecruiterSummary}
                    isDesktop={false}
                    viewMoreText="Read"
                    onViewMoreClick={() =>
                      setShouldShowFullRecruiterSummary(!shouldShowFullRecruiterSummary)
                    }
                    description={recruiterSummary || 'Not specified'}
                  />
                </Box>
              </Box>
            )}

            <SimpleGrid
              mt={6}
              columns={2}
              templateColumns={['1fr', '3fr 2fr', '1fr 1fr']}
              gridGap={10}
            >
              <Box>
                <Text variant="label" mt={6} mb={2}>
                  Work
                </Text>
                {workExperiences?.length ? (
                  workExperiences.map(
                    ({ title, employer, employmentYears, employmentDuration }) => (
                      <Flex
                        alignItems={['start', 'start', 'center']}
                        flexDir={['column', 'column', 'row']}
                        key={title}
                        borderBottom="1px solid"
                        borderColor="ui.secondary"
                        py={2}
                        gridGap={[0, 0, 2]}
                      >
                        <Box flex={1}>
                          <Text variant="caption">{employmentYears}</Text>
                          <Text fontWeight="bold">
                            {title} {employer && `@ ${employer}`}
                          </Text>
                        </Box>
                        {employmentDuration && <Text fontWeight="bold">{employmentDuration}</Text>}
                      </Flex>
                    ),
                  )
                ) : (
                  <Text>Not specified</Text>
                )}
              </Box>
              <Box>
                <Text variant="label" mt={6} mb={2}>
                  Education
                </Text>
                {educations?.length ? (
                  educations.map(({ title, subTitle, datesTitle }) => (
                    <Flex py={2} key={title} flexDir="column" gridGap={1}>
                      {!!datesTitle && <Text variant="hint">{datesTitle}</Text>}
                      <Text variant="caption">{subTitle}</Text>
                      <Text fontWeight="bold">{title}</Text>
                    </Flex>
                  ))
                ) : (
                  <Text>Not specified</Text>
                )}
              </Box>
            </SimpleGrid>
          </GridItem>

          <GridItem mt={[6, 6, 0]} pb={[18, 18, 6]}>
            <Box bgColor="bg.secondary" p={6} h="full" minH="calc(100vh - 10rem)">
              <Heading variant="heading-3" color="text.primary">
                Other Matching Candidates
              </Heading>

              <Flex flexDir="column" gap={6} mt={6}>
                {matchingCandidates.map((matchingCandidate) => (
                  <Box p={4} bgColor="bg.primary" border="1px solid" borderColor="ui.secondary">
                    {!!matchingCandidate.badges.length && (
                      <Flex flexDir="row" gap={2} mb={4}>
                        {matchingCandidate.badges.map(
                          (item) =>
                            item && (
                              <Badge key={item.text} colorScheme={item.variant} mr={2} size="lg">
                                {item.text}
                              </Badge>
                            ),
                        )}
                      </Flex>
                    )}
                    <Heading variant="heading-2">
                      {matchingCandidate.firstName} {matchingCandidate.last_name_initial}
                    </Heading>
                    <Text variant="body" mt={1}>
                      {matchingCandidate.currentRole}
                    </Text>
                    {!!matchingCandidate.yearsExperience && (
                      <Text variant="caption" mt={1}>
                        {matchingCandidate.yearsExperience} years of experience
                      </Text>
                    )}
                    <Text variant="hint" color="text.secondary" mt={1}>
                      {matchingCandidate.country_choice?.name ||
                        // @ts-ignore TODO (TP-1666): fix ignored typescript error
                        matchingCandidate.state ||
                        matchingCandidate.city}
                    </Text>
                    <Box mt={4}>
                      <Flex gridGap={3} flexWrap="wrap">
                        {matchingCandidate.skills.map(({ id, name }: any) => (
                          <Tag
                            px={2}
                            size="sm"
                            variant="subtle"
                            colorScheme="disabled"
                            key={id}
                            h="full"
                          >
                            {name}
                          </Tag>
                        ))}
                        {!!matchingCandidate.remainingSkills && (
                          <Text variant="caption" minW="5.625rem" mt={1}>
                            + {matchingCandidate.remainingSkills} more skill
                            {matchingCandidate.remainingSkills > 1 ? 's' : ''}
                          </Text>
                        )}
                      </Flex>
                    </Box>
                  </Box>
                ))}
              </Flex>
              <Button
                w="full"
                variant="outline"
                mt={6}
                rightIcon={<ArrowRightIcon />}
                {...handleSignUpLink}
                zIndex={[0, 0, 1]}
              >
                Find More Candidates
              </Button>
            </Box>
          </GridItem>
        </Grid>
      </Container>

      <Box pos="fixed" w="full" left={0} right={0} bottom={0}>
        <Grid
          templateColumns={['100%', '100%', 'minmax(37rem, 1fr) minmax(min-content, 30rem)']}
          gap={[0, 0, 10]}
          maxW="85.5rem"
          marginX="auto"
        >
          <Flex bgColor="#FCFCFC" py={3} alignItems="center" justifyContent="center">
            <Button variant="solid" {...handleSignUpLink}>
              See Full Profile
            </Button>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
