import { useReducer } from 'react';
import type { Reducer } from 'react';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { firebaseAuth } from 'global/firebaseApp';
import { useShouldHideGoogleSSO, useHandleAuthBackendError } from '../auth.utils';
import { useAuth } from '../useAuth';
import { emailSignIn } from '../authMethods';

export function useSignIn() {
  const { isLoading: authIsLoading } = useAuth();
  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );
  const shouldHideGoogleSSO = useShouldHideGoogleSSO({
    shouldTrack: false,
  });

  const { isError, isLoading, isResolved } = toAsyncProps(state);

  useHandleAuthBackendError({
    isError,
    error: state.error,
    errorsWithFriendlyMessage: {
      // ref: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
      'auth/wrong-password': 'Email and password did not match.',
      'auth/user-not-found': 'An account was not found for this email address.',
      // Thrown if there already exists an account with the email address asserted by the credential.
      'auth/account-exists-with-different-credential':
        'This account exists, but cannot be accessed with this login method.',
      'auth/user-disabled': 'Your account has been locked. Please contact support@terminal.io',
    },
  });

  const handleSignInSubmit = async (email: string, password: string) => {
    try {
      dispatch({ type: 'pending' });
      await emailSignIn({ auth: firebaseAuth }, email, password);

      dispatch({
        type: 'resolved',
        data: null,
      });
    } catch (handleSignInError) {
      dispatch({
        type: 'rejected',
        error: handleSignInError,
      });
    }
  };

  return {
    isError,
    isLoading: isLoading || authIsLoading, // To avoid early removals of the loading spinner of the CTA
    isResolved,
    handleSignInSubmit,
    shouldHideGoogleSSO,
  };
}
