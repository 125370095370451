export function is_enum_value<T extends { [key: string]: string }>(
  value: string | undefined | null,
  enumObject: T,
): value is T[keyof T] {
  if (value === undefined || value === null) {
    return false;
  }
  return Object.values(enumObject).includes(value as T[keyof T]);
}

export type ExcludeUISpecificPropsKeys<T> = Omit<
  T,
  `on${string}` | `handle${string}` | `${string}loading${string}`
>;

export type ComponentSerializerProps<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
> = T extends React.JSXElementConstructor<any>
  ? ExcludeUISpecificPropsKeys<React.ComponentProps<T>>
  : T extends keyof JSX.IntrinsicElements
  ? ExcludeUISpecificPropsKeys<JSX.IntrinsicElements[T]>
  : never;
