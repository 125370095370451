import { createContext, useState, useContext, useEffect } from 'react';
import { BlankScreenLoading } from 'global/components/BlankScreenLoading';
import { EndlessLoadingBarWithIcon } from 'global/components/EndlessLoadingBarWithIcon';
import { AnimatePresence } from 'framer-motion';
import type { Dispatch, SetStateAction, ReactNode } from 'react';

type Indicator = {
  type: 'simple-spinner' | 'endless-loading-bar-with-icon';
  'aria-label'?: string;
  description?: string;
};

const activityIndicatorMap: Record<
  Indicator['type'],
  React.ComponentType<Omit<Indicator, 'type'>>
> = {
  'simple-spinner': () => <BlankScreenLoading pos="fixed" top={0} left={0} />,
  'endless-loading-bar-with-icon': EndlessLoadingBarWithIcon,
};

const ActivityIndicatorContext = createContext<{
  setIndicator: Dispatch<SetStateAction<Indicator[]>>;
} | null>(null);

export function useActivityContext() {
  const context = useContext(ActivityIndicatorContext);

  if (!context) {
    throw new Error('useActivityContext must be used within a ActivityIndicatorProvider');
  }

  return context;
}

export function ActivityIndicator({ type = 'simple-spinner', ...props }: Partial<Indicator>) {
  const { setIndicator } = useActivityContext();

  useEffect(() => {
    setIndicator((prevState) => [...prevState, { type, ...props }]);

    return () => {
      // To make sure removals takes some extra time than adding
      setTimeout(() => {
        setIndicator((prevState) => prevState.slice(0, prevState.length - 1));
      }, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function ActivityProvider({ children }: { children: ReactNode }) {
  const [[{ type: headIndicatorType, ...headIndicatorProps } = {} as Indicator], setIndicator] =
    useState<Indicator[]>([]);
  const ActivityIndicatorComponent = headIndicatorType
    ? activityIndicatorMap[headIndicatorType]
    : null;

  /**
   * Children is mounting the Activity Indicators, if we unmount children it would unmount the indicators.
   * The ActivityIndicator component inside children are just nulls but since we are taking advantage of the component life cycle we are keeping track of actual UI that we need to display to avoid having multiple instances of the same UI interfering each other.
   */
  return (
    // TODO (TP-1874): fix this eslint error
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ActivityIndicatorContext.Provider value={{ setIndicator }}>
      {children}
      <AnimatePresence>
        {ActivityIndicatorComponent && <ActivityIndicatorComponent {...headIndicatorProps} />}
      </AnimatePresence>
    </ActivityIndicatorContext.Provider>
  );
}
