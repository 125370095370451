import { BlankScreenLoading } from 'global/components';
import { RoleLayout } from '../RoleLayout';

export function RoleBlankLoadingScreen({
  roleLayoutProps,
}: {
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  return (
    <RoleLayout {...roleLayoutProps}>
      <BlankScreenLoading />
    </RoleLayout>
  );
}
