import { useEffect } from 'react';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { SelectTerminalEmployees } from './data/graphql';
import type {
  TerminalEmployeesQueryVariables,
  TerminalEmployeesQuery as TerminalEmployeesQueryType,
} from './data';

import { TerminalDirectory } from './TerminalDirectory';
import { serializeTerminalDirectory } from './TerminalDirectory.serializer';

export function TerminalDirectoryController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const { loading, data } = useQuery<TerminalEmployeesQueryType, TerminalEmployeesQueryVariables>(
    SelectTerminalEmployees,
    {
      variables: {
        organization: viewingOrganization.ID,
      },
    },
  );

  const { employees } = serializeTerminalDirectory(data);

  // Track page views
  useEffect(() => {
    // Track page view
    events.track(events.name.viewedTerminalDirectory);
  }, []);

  return (
    <TerminalDirectory
      employees={employees}
      loading={loading}
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
    />
  );
}
