import type { SelectSuggestedOrganizationUsersQuery } from './data';
import type { InsertUsersAndFeedbackInvitationsMutationVariables } from './subfeatures/candidate-feedback/data';

function getUserIdFromOrganizationUsers({
  email,
  organizationUsers,
}: {
  email: string;
  organizationUsers?: SelectSuggestedOrganizationUsersQuery['users'];
}): undefined | number {
  if (!organizationUsers) {
    return undefined;
  }
  return organizationUsers.find((user) => user.email === email)?.id;
}

export function InsertUsersAndFeedbackInvitations_toUserAndFeedbackInvitation({
  modalInvitedUsers,
  applicantWorkflowID,
  jobID,
  user,
  organizationUsers,
  outsideUsersInvited,
}: {
  modalInvitedUsers: {
    users: {
      value: string;
      label: string;
    }[];
    isRoleAccess: boolean;
  };
  applicantWorkflowID: number;
  jobID: number;
  user: {
    organization: number;
    id: number;
  };
  organizationUsers?: SelectSuggestedOrganizationUsersQuery['users'];
  outsideUsersInvited?: SelectSuggestedOrganizationUsersQuery['user_invitation'];
}) {
  const usersInvitations: InsertUsersAndFeedbackInvitationsMutationVariables['users_invites'] = [];
  const feedbackInvitations: InsertUsersAndFeedbackInvitationsMutationVariables['feedback_invites'] =
    [];
  const jobInterviewFeedbackRequest: InsertUsersAndFeedbackInvitationsMutationVariables['job_interview_feedback_request'] =
    [];

  modalInvitedUsers.users.forEach(({ value }) => {
    const userId = getUserIdFromOrganizationUsers({ email: value, organizationUsers });
    const userCreationAlreadySend =
      outsideUsersInvited && outsideUsersInvited.some((userInvite) => userInvite.email === value);

    if (!userId && !userCreationAlreadySend) {
      usersInvitations.push({
        email: value,
        organization: user.organization,
      });
    }
    if (modalInvitedUsers.isRoleAccess) {
      jobInterviewFeedbackRequest.push({
        interviewer_email: value,
        interviewer_user_id: userId,
        job_id: jobID,
        requester_user_id: user.id,
      });
    } else {
      feedbackInvitations.push({
        interviewer_email: value,
        requester_user_id: user.id,
        icims_applicant_workflow_id: applicantWorkflowID,
        interviewer_user_id: userId,
      });
    }
  });
  return {
    users_invites: usersInvitations,
    feedback_invites: feedbackInvitations,
    job_interview_feedback_request: jobInterviewFeedbackRequest,
  };
}
