import { Button, Stack, VStack, Flex, Heading } from '@terminal/design-system';
import {
  PageLayout,
  StatWidget,
  PageLayoutPaddedBody,
  Card,
  CardBody,
} from 'talent-hub/components';
import { Link as RouterLink } from 'react-router-dom';
import { PeopleIcon, JobsIcon, MembersIcon } from '../../components';
import type {
  BasicWidget,
  FilledRole,
  PostingWithTotalsAndHealthStatus,
} from './ManageRoles.serializer';
import { JobList, FilledRolesTable } from './components';
import { JobsToReview } from './components/JobsToReview';

const widgetIcons: Record<number, React.ReactNode> = {
  2: <JobsIcon w={16} h={16} />,
  4: <PeopleIcon w={16} h={16} />,
  1: <MembersIcon w={16} h={16} />,
};

export function ManageRoles({
  filledRoles,
  isLoading,
  pageLayoutProps,
  postingsList,
  widgets,
  rolesPendingReview,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  filledRoles: FilledRole[];
  isLoading: boolean;
  postingsList: PostingWithTotalsAndHealthStatus[];
  widgets: BasicWidget[];
  rolesPendingReview: React.ComponentProps<typeof JobsToReview>['jobs'];
}) {
  return (
    <PageLayout headerTitle="Manage Roles" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <VStack spacing={6}>
          <Stack w="full" direction={['column', 'column', 'row']} spacing={6}>
            {widgets.map((widget: BasicWidget) => (
              <StatWidget.Variation.TwoColumns
                {...widget}
                key={`stat-${widget.id}`}
                icon={widgetIcons[widget.id]}
                isLoading={isLoading}
                minH="9.5rem"
              />
            ))}
            <Card minH="9.5rem" h="auto" bgColor="bg.tertiary" px={4} py={6}>
              <CardBody justifyContent="center">
                <Flex flex="1" align="left" justifyContent="center" flexDir="column">
                  <Heading variant="heading-2" color="text.primary" textAlign="center">
                    Ready for a new role?
                  </Heading>
                  <Button
                    variant="solid"
                    colorScheme="primary"
                    size="lg"
                    p={0}
                    mt={4}
                    to="/role/create"
                    as={RouterLink}
                  >
                    Open New Role
                  </Button>
                </Flex>
              </CardBody>
            </Card>
          </Stack>
          <JobList isLoading={isLoading} jobs={postingsList} />
          {(rolesPendingReview.length || isLoading) && (
            <JobsToReview isLoading={isLoading} jobs={rolesPendingReview} />
          )}
          <FilledRolesTable isLoading={isLoading} roles={filledRoles} />
        </VStack>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
