import { useRadio, forwardRef, Box } from '@terminal/design-system';
import type { RadioProps } from '@terminal/design-system';

export const RadioButton = forwardRef<RadioProps, 'input'>(
  ({ isDisabled, isChecked, onChange, children, ...rest }, ref) => {
    const { getInputProps, getCheckboxProps, getLabelProps } = useRadio({
      ...rest,
      isDisabled,
      isChecked,
      onChange,
    }) as any; // TODO: [TP-1470] Fix chakra-ui types;

    return (
      <Box as="label" flex="1" {...getLabelProps()}>
        <input {...getInputProps({}, ref)} />
        <Box
          {...getCheckboxProps()}
          cursor="pointer"
          borderRadius="md"
          __css={{
            _hover: { bg: 'bg.tertiary' },
            _checked: {
              bg: 'bg.tertiary',
              fontWeight: 'bold',
            },
            _focus: { boxShadow: 'outline' },
          }}
          transition="transform 290ms"
        >
          {children}
        </Box>
      </Box>
    );
  },
);
