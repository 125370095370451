import { useState, useEffect } from 'react';
import { gql, useReactiveVar, useMutation, useQuery } from '@apollo/client';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useCustomerAuth } from 'talent-hub/utils';
import { CustomRoute, ActivityIndicator } from 'global/components';
import { firebaseAuth } from 'global/firebaseApp';
import Sentry from 'global/sentry';
import type { ApolloError } from '@apollo/client';

import {
  Region_Choices_Enum,
  Role_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { EuropeanCountry } from 'global/constants';
import { SignUpController } from '../sign-up';
import { BookCallController } from '../book-call';
import { serializeFinalStepData } from './Final.serializer';
import type { DynamicSelectCandidateBrowseAggregateQuery } from './Final.types';
import { onboardingState } from '../../Onboarding.utils';
import type { OnboardingState } from '../../../Prospect.types';
import { UpsertOnboardingVariation } from './data';
import type {
  UpsertOnboardingVariationMutation,
  UpsertOnboardingVariationMutationVariables,
} from './data';

export function determineData_fromGivenProps({
  location,
  roles,
  isCurrentUserAnonymous,
}: {
  isCurrentUserAnonymous: boolean;
} & Pick<OnboardingState, 'location' | 'roles'>): {
  innerStep: 'sign-up' | 'book-call' | null;
  filteredRoles: OnboardingState['roles'];
  regionsFilter: Region_Choices_Enum[];
} {
  // After google SSO, user is redirected to this route. But user will not have anonymous authentication anymore. Therefore, with this flag, we can determine that the user was on the sign-up inner step, so we return that step here.
  // Note: In the parent controller, users who don't have anonymous authentication, are redirected out of onboarding, with the exception of users who land on this route.
  if (!isCurrentUserAnonymous)
    return {
      innerStep: 'sign-up',
      filteredRoles: [],
      regionsFilter: [],
    };

  const filteredRoles = roles?.filter(
    (role) =>
      [
        Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
        Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
      ].includes(role.experience) &&
      [
        Role_Choices_Enum.Fed,
        Role_Choices_Enum.Bed,
        Role_Choices_Enum.Mod,
        Role_Choices_Enum.Fsd,
      ].includes(role.value as Role_Choices_Enum),
  );

  return {
    // If the prospect location is [US, Canada] the regions filter is for Latam and Canada, Otherwise it should be UK which filters for Europe
    regionsFilter: ['United States', 'Canada'].includes(`${location}`)
      ? [Region_Choices_Enum.Canada, Region_Choices_Enum.LatAm]
      : [Region_Choices_Enum.Europe],
    innerStep:
      [
        ...Object.values(EuropeanCountry),
        'United States',
        'Canada',
        'United Arab Emirates',
      ].includes(`${location}`) && filteredRoles?.length
        ? null
        : 'book-call',
    filteredRoles,
  };
}

export function FinalController({
  onStepComplete,
  onMissingData,
}: {
  onStepComplete: () => void;
  onMissingData?: ({ from, missingInfo }: { from: string; missingInfo: string }) => void;
}) {
  const { path, url } = useRouteMatch();
  const auth = useCustomerAuth();
  const { location, roles, prospectID } = useReactiveVar(onboardingState.stateVar);
  const {
    innerStep: innerStep_fromGivenProps,
    filteredRoles,
    regionsFilter,
  } = determineData_fromGivenProps({
    location,
    roles,
    isCurrentUserAnonymous: firebaseAuth.currentUser?.isAnonymous === true,
  });
  const [innerStep, setInnerStep] = useState<'sign-up' | 'book-call' | null>(
    innerStep_fromGivenProps,
  );

  const {
    loading: isLoading_queryCandidateBrowseAggregate,
    data: data_queryCandidateBrowseAggregate,
  } = useQuery<DynamicSelectCandidateBrowseAggregateQuery, any>(
    gql`
      query SelectCandidateBrowseAggregate {
        ${(filteredRoles?.length
          ? filteredRoles
          : [{ value: 'dummy', skillIDs: [], experience: null }]
        )
          ?.map?.(
            ({ value: roleType, skillIDs, experience }, index) => `
              candidate_browse_aggregate_${index}: explore_candidates (
                args: {
                  organization_id: -1,
                  candidate_role: "${roleType}",
                  must_have_skill_ids: [],
                  should_have_skill_ids:  [${skillIDs}],
                  min_years_of_experience: "${experience}",
                  regions: ["${regionsFilter.join('","')}"]
                },
                limit: 1,
                ${skillIDs.length ? 'where: { score: { _gt: 0 } }' : ''}
              ) {
                total_count
              }
            `,
          )
          .join('\n')}
      }
    `,
    {
      // if innerStep hasn't been determined yet we call the aggregate query
      skip: innerStep_fromGivenProps !== null,
      // Adding no-cache to avoid an issue with the cache and aliases in apollo
      fetchPolicy: 'no-cache',
    },
  );

  const [upsertOnboardingVariation] = useMutation<
    UpsertOnboardingVariationMutation,
    UpsertOnboardingVariationMutationVariables
  >(UpsertOnboardingVariation, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  const { hasEnoughMatches, mostMatchingAggregateIndex } = serializeFinalStepData(
    data_queryCandidateBrowseAggregate,
  );

  useEffect(() => {
    if (innerStep === null) return;

    upsertOnboardingVariation({
      variables: {
        userId: auth.user?.id as number,
        lastStepCompleted: 'Processing Data',
        onboardingVariations: {
          'final-step': innerStep,
        },
      },
    });
    // The function 'upsertOnboardingVariation' doesn't need to ve invalidated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerStep]);

  useEffect(() => {
    if (typeof mostMatchingAggregateIndex !== 'number' || !auth?.user?.id) return;

    const data = filteredRoles[mostMatchingAggregateIndex];

    localStorage.setItem(
      'onboarding_mostMatchingRole',
      JSON.stringify({
        [auth.user.id]: data,
      }),
    );
  }, [mostMatchingAggregateIndex, filteredRoles, auth?.user?.id]);

  useEffect(() => {
    if (typeof data_queryCandidateBrowseAggregate === 'undefined' || innerStep !== null) return;

    if (hasEnoughMatches) {
      setInnerStep('sign-up');
    } else {
      setInnerStep('book-call');
    }
  }, [hasEnoughMatches, data_queryCandidateBrowseAggregate, innerStep]);

  useEffect(() => {
    if (!roles?.length || !prospectID) {
      const missingInfo = [];
      if (!roles?.length) missingInfo.push('roles');
      if (!prospectID) missingInfo.push('prospectID');

      onMissingData?.({ from: 'final', missingInfo: missingInfo.join(', ') });
    }

    // disabling because roles are required and should be there in the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading_queryCandidateBrowseAggregate || innerStep === null) {
    return <ActivityIndicator aria-label="Loading" />;
  }

  return (
    <Switch>
      <CustomRoute
        path={`${path}/sign-up`}
        conditionalRedirects={[
          { condition: innerStep !== 'sign-up', redirectURL: `${url}/${innerStep}` },
        ]}
      >
        <SignUpController
          continueURL={`${window.location.origin}/onboard/final/sign-up`}
          roles={roles}
          onStepComplete={onStepComplete}
        />
      </CustomRoute>
      <CustomRoute
        path={`${path}/book-call`}
        conditionalRedirects={[
          { condition: innerStep !== 'book-call', redirectURL: `${url}/${innerStep}` },
        ]}
      >
        {/* We are throwing an error when prospectID is null in this controller, so by this
        time we know its number */}
        <BookCallController
          prospectID={prospectID as number}
          onClickSignUp={() => {
            setInnerStep('sign-up');
          }}
        />
      </CustomRoute>
      <Route
        path={path}
        render={() => {
          return (
            <Redirect
              to={{
                pathname: `${url}/${innerStep}`,
              }}
            />
          );
        }}
      />
    </Switch>
  );
}
