import { displayRolesNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { type Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import type { OnboardingState } from '../../Prospect.types';

export function serializeOnboardingMostMatchingRole({
  dataString,
  userID,
  onError,
}: {
  dataString?: string | null;
  userID?: number;
  onError?: (error: any) => void;
}) {
  if (!dataString || !userID) return {};
  try {
    const { [userID]: mostMatchingRole } = JSON.parse(dataString) as Record<
      string,
      OnboardingState['roles'][number]
    >;

    return mostMatchingRole
      ? {
          queryArgs: {
            should_have_skill_ids: mostMatchingRole.skillIDs,
            candidate_role: mostMatchingRole.value,
            min_years_of_experience: mostMatchingRole.experience,
          },
          initialValues: {
            niceToHaveSkills: mostMatchingRole?.skillIDs?.map((skillID, index) => ({
              value: `${skillID}`,
              label: mostMatchingRole.skillNames[index] as string,
            })),
            candidateRole: displayRolesNames[mostMatchingRole.value as Role_Choices_Enum],
            yearsOfExperience: toFriendlyYearsOfExperienceRange(mostMatchingRole.experience),
          },
        }
      : {};
  } catch (error) {
    onError?.(error);
    return {};
  }
}
