/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { NoAccessPage } from './NoAccessPage';

export default {
  title: 'Global/Pages/Auth/No Access',
  component: NoAccessPage,
  chromatic: { disableSnapshot: true },
} as Meta;

const Template: StoryFn<ComponentProps<typeof NoAccessPage>> = (args) => <NoAccessPage {...args} />;

export const story_NoAccessPage = Template.bind({} as {});
story_NoAccessPage.args = {};
story_NoAccessPage.storyName = 'No Access';
