import styled from '@emotion/styled';
import { Text } from '@terminal/design-system';
import type { ProgressStatus } from 'talent-hub/utils';

type PropTypes = {
  progressStatuses: ProgressStatus[];
  isFinal: boolean;
};

type TimelineProps = {
  isFinal: boolean;
};

const Timeline = styled.div<TimelineProps>`
  padding: 1rem 0;
  left: 1.5rem;
  position: relative;
  margin-bottom: -0.75rem;
  margin-top: 0.5rem;

  ::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #dfdfdf;
    top: 2px;
    ${(props) => (props.isFinal ? 'bottom: 30px;' : 'bottom: 0;')};
    left: 0;
  }
`;

const TimeLineContent = styled.div<{ index: number }>`
  ${(props) => (props.index ? 'margin-top: 0.5rem;' : '')};
  padding-left: 1rem;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    left: -3px;
    background-color: rgb(239, 242, 246);
    border: 2px solid #dfdfdf;
    top: 9px;
    border-radius: 50%;
    z-index: 1;
  }
`;

export function StageProgressStatuses({ progressStatuses, isFinal }: PropTypes) {
  if (!progressStatuses.length) {
    return null;
  }

  return (
    <Timeline isFinal={isFinal}>
      {progressStatuses.map((progressStatus, index) => (
        <TimeLineContent
          // eslint-disable-next-line react/no-array-index-key
          key={`time-line-${index}`}
          index={index}
        >
          <Text variant="label" display="inline-block">
            {progressStatus.friendlyTitle}
          </Text>
          <Text ml={1} variant="caption" display="inline-block">
            - {progressStatus.date}
          </Text>
        </TimeLineContent>
      ))}
    </Timeline>
  );
}
