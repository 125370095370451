import { Button as ChakraButton, forwardRef } from '@chakra-ui/react';
import type { ButtonProps as ChakraButtonProps, SystemProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
// Had to import this path relatively to work around Chakra UI cli not resolving the global path.
// eslint-disable-next-line import/no-useless-path-segments
import { v4 as uuidv4 } from 'uuid';
// TODO: [TP-1471] refactor (?) is duplicated at global/components
import { UIErrorBoundary } from './UIErrorBoundary';

// TODO: remove this type and use chakra UI type for button instead
export interface ButtonProps extends ChakraButtonProps {
  /**
   * Button variants
   */
  variant?: 'primary' | 'secondary' | 'link' | 'ghost' | 'unstyled' | 'outline' | 'solid';
  // TODO: [TAL-501] remove primary and secondary

  /**
   * Button different color sets
   */
  colorScheme?: 'default' | 'brand' | 'accent' | 'error' | 'primary';
  // TODO: [TAL-501] remove default and brand

  /**
   * If `true`, the button will show a spinner.
   */
  isLoading?: boolean;
  /**
   * If `true`, the button will be styled in its active state.
   */
  isActive?: boolean;
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * The label to show in the button when `isLoading` is true
   * If no text is passed, it shows the spinner with the Button text
   */
  loadingText?: string;
  /**
   * The html button type to use.
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * If added, the button will show an icon before the button's label.
   * @type React.ReactElement
   */
  leftIcon?: React.ReactElement;
  /**
   * If added, the button will show an icon after the button's label.
   * @type React.ReactElement
   */
  rightIcon?: React.ReactElement;
  /**
   * The space between the button icon and label.
   * @type SystemProps["marginRight"]
   */
  iconSpacing?: SystemProps['marginRight'];
  /**
   * Replace the spinner component when `isLoading` is set to `true`
   * @type React.ReactElement
   */
  spinner?: React.ReactElement;
  /**
   * If `true`, the button will not have a hover state on iOS devices.
   * See reason at: [https://github.com/terminalinc/product/pull/1967]
   */
  disableIOSHoverBugHandler?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef<ButtonProps, 'button'>(
  ({ loadingText, colorScheme, disableIOSHoverBugHandler, ...rest }, ref) => {
    const [buttonId, setButtonId] = useState<any>();
    const [styles, setStyles] = useState<{
      backgroundColor: string;
      color: string;
    }>();

    useEffect(() => {
      if (disableIOSHoverBugHandler) {
        // An identifier for each button is needed, we can´t use ref since it can be null
        setButtonId(uuidv4);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (disableIOSHoverBugHandler) {
        // Search for button element
        const button = buttonId ? document.querySelector(`[ios-button-id='${buttonId}']`) : null;

        // We don't want to change styles for a disabled button
        if (!button?.hasAttribute('disabled') && button instanceof Element) {
          // Gets the styles of the button
          const elementStyles = window.getComputedStyle(button, null);

          // Saves the styles of 'normal' state so it can be re-used while :hover state on iOS devices
          setStyles({
            backgroundColor: elementStyles.backgroundColor,
            color: elementStyles.color,
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonId]);

    const is_iOS_Device = () => {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform,
        ) ||
        // iPad on iOS 13 detection
        (typeof navigator !== 'undefined' &&
          navigator.userAgent.includes('Mac') &&
          'ontouchend' in document)
      );
    };

    return (
      <UIErrorBoundary
        FallbackComponent={
          <ChakraButton
            ios-button-id={buttonId}
            colorScheme={colorScheme}
            {...rest}
            ref={ref}
            isLoading={false}
            // TODO: remove this span
            children={<span>{rest.children}</span>}
            {...(is_iOS_Device() && disableIOSHoverBugHandler ? { style: styles } : {})}
          />
        }
      >
        <ChakraButton
          ios-button-id={buttonId}
          loadingText={
            loadingText || (typeof rest.children === 'string' ? rest.children : undefined)
          }
          colorScheme={colorScheme}
          {...rest}
          ref={ref}
          children={<span>{rest.children}</span>}
          {...(is_iOS_Device() && disableIOSHoverBugHandler ? { style: styles } : {})}
        />
      </UIErrorBoundary>
    );
  },
);

Button.defaultProps = {
  variant: 'primary',
  colorScheme: 'default',
};
