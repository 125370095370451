import { CloseIcon, Link, Text } from '@terminal/design-system';
import { AuthMessage } from '../AuthMessage';
import { AuthPageBackground } from '../AuthPageBackground';
import { AuthPageTemplate } from '../AuthPageTemplate';

export function AuthErrorFallback() {
  return (
    <AuthPageBackground>
      <AuthPageTemplate>
        <AuthMessage icon={<CloseIcon />} title="Something went wrong!">
          <Text>
            Please try again. If the problem continues, please{' '}
            <Link isExternal display="inline" href="mailto:support@terminal.io">
              contact us.
            </Link>
          </Text>
        </AuthMessage>
      </AuthPageTemplate>
    </AuthPageBackground>
  );
}
