import type { AuthPageTemplateProps } from '../types';
import { AuthPageTemplate } from '../components';
import { PasswordResetForm } from './PasswordResetForm';

export function PasswordResetPage({
  isLoading,
  onResetPasswordSubmit,
  pageTemplateOverwrite,
}: {
  onResetPasswordSubmit: (newPassword: string) => void;
  isLoading: boolean;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate headerTitle="Reset your password" headerSubtitle="Create a new password">
      <PasswordResetForm showLoading={isLoading} onResetPasswordSubmit={onResetPasswordSubmit} />
    </PageTemplate>
  );
}
