import { textTheme } from '../Text/Text.theme';
import { inputTheme } from '../form/Input';

export const listTheme = {
  parts: ['container', 'item', 'icon'],
  baseStyle: {
    container: {},
    item: {},
    icon: {
      marginEnd: '0.5rem',
      display: 'inline',
      verticalAlign: 'text-bottom',
    },
  },
  variants: {
    dropdown: {
      container: {
        ...inputTheme.baseStyle.field,
        bg: 'ui.inverse.primary',
        p: 0,
        paddingInline: 0,
        mt: 2,
        maxH: 'xs',
        overflowY: 'auto',
        zIndex: 'tooltip',
        position: 'absolute',
        w: 'full',
      },
      item: {
        ...textTheme.variants.body,
        transition: 'background-color 220ms, color 220ms',
        bg: 'ui.inverse.primary',
        cursor: 'pointer',
        zIndex: 'tooltip',
        p: 3,
      },
      icon: {
        marginEnd: 4,
        display: 'inline',
        verticalAlign: 'text-bottom',
      },
    },
  },
};
