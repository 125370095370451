import { useCallback, useState } from 'react';

import {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Education_Degree_Choices_Enum,
  Employment_Type_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { CountryChoice } from 'global/constants';

export const displayRolesNames: Record<Role_Choices_Enum | string, string> = {
  [Role_Choices_Enum.Fed]: 'Frontend',
  [Role_Choices_Enum.Bed]: 'Backend',
  [Role_Choices_Enum.Fsd]: 'Full Stack',
  [Role_Choices_Enum.Mqa]: 'Manual QA',
  [Role_Choices_Enum.Aqa]: 'Auto QA',
  [Role_Choices_Enum.Dev]: 'DevOps',
  [Role_Choices_Enum.Mod]: 'Mobile',
  [Role_Choices_Enum.Dsc]: 'Data Science',
  [Role_Choices_Enum.Den]: 'Data Engineer',
  [Role_Choices_Enum.Dan]: 'Data Analyst',
  [Role_Choices_Enum.Enl]: 'Eng. Lead',
  [Role_Choices_Enum.Aml]: 'AI/ML',
  [Role_Choices_Enum.Scm]: 'Scrum Master',
  [Role_Choices_Enum.Prm]: 'Product Manager',
  [Role_Choices_Enum.Des]: 'Designer',
};

export const displayRolesOrder: Record<Role_Choices_Enum | string, number> = Object.keys(
  displayRolesNames,
).reduce((result, key, index) => ({ ...result, [key]: index }), {});

export function formatNameAsInitial(name: string): string {
  return name ? name.charAt(0) : '';
}

export const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(
    (e: any) => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export function isSmallScreen() {
  return window.matchMedia('(max-width: 767px)').matches;
}

export function toFriendlyYearsOfExperience(yearsOfExperience: number) {
  if (yearsOfExperience > 10) {
    return '10+ years';
  }

  return yearsOfExperience > 1 ? `${yearsOfExperience} years` : `1 year`;
}

export function toFriendlyYearsOfExperienceRange(
  yearsExpRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null,
) {
  switch (yearsExpRange) {
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo:
      return '0 to 2 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive:
      return '2 to 5 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen:
      return '5 to 10 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus:
      return 'More than 10 years';
    default:
      return '-';
  }
}

export function is_country_choice(value: string): value is CountryChoice {
  return Object.values(CountryChoice).includes(value as CountryChoice);
}

export function is_employment_type(value: string): value is Employment_Type_Choices_Enum {
  return Object.values(Employment_Type_Choices_Enum).includes(
    value as Employment_Type_Choices_Enum,
  );
}

export const FriendlyEmploymentType = {
  [Employment_Type_Choices_Enum.FullTime]: 'Full-time',
  [Employment_Type_Choices_Enum.Contract]: 'Contractor',
  [Employment_Type_Choices_Enum.FullTimeAndContract]: 'Full-time & Contract',
} as const;

export function to_friendly_years_of_experience_range(
  yearsExpRange: Candidate_Curation_Years_Of_Exp_Range_Choices_Enum | null,
) {
  switch (yearsExpRange) {
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo:
      return '0-2 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive:
      return '2-5 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen:
      return '5-10 years';
    case Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus:
      return '10+ years';
    default:
      return '-';
  }
}

// same as - https://github.com/terminalinc/product/blob/master/web-candidate/src/candidate/shared/modules/roles/data/Roles.serializer.ts#L4
export function toFriendlyRolesNames(role: Role_Choices_Enum, defaultValue: string = '-') {
  switch (role) {
    case Role_Choices_Enum.Fed:
      return 'Frontend';
    case Role_Choices_Enum.Bed:
      return 'Backend';
    case Role_Choices_Enum.Fsd:
      return 'Full Stack';
    case Role_Choices_Enum.Dev:
      return 'DevOps';
    case Role_Choices_Enum.Mqa:
      return 'Manual QA';
    case Role_Choices_Enum.Mod:
      return 'Mobile';
    case Role_Choices_Enum.Dsc:
      return 'Data Science';
    case Role_Choices_Enum.Enl:
      return 'Eng. Lead';
    case Role_Choices_Enum.Des:
      return 'Designer';
    case Role_Choices_Enum.Dan:
      return 'Data Analyst';
    case Role_Choices_Enum.Den:
      return 'Data Engineer';
    case Role_Choices_Enum.Aqa:
      return 'Automation QA';
    case Role_Choices_Enum.Aml:
      return 'AI/ML';
    case Role_Choices_Enum.Prm:
      return 'Product Manager';
    case Role_Choices_Enum.Scm:
      return 'Scrum Master';
    default:
      return defaultValue;
  }
}

export function toFriendlyDegree(
  degree: Candidate_Education_Degree_Choices_Enum,
  defaultValue: string = '-',
) {
  switch (degree) {
    case Candidate_Education_Degree_Choices_Enum.Bootcamp:
      return 'Bootcamp';
    case Candidate_Education_Degree_Choices_Enum.Associate:
      return 'Associate';
    case Candidate_Education_Degree_Choices_Enum.Bachelors:
      return "Bachelor's";
    case Candidate_Education_Degree_Choices_Enum.Masters:
      return "Master's";
    case Candidate_Education_Degree_Choices_Enum.Doctoral:
      return 'Doctoral';
    case Candidate_Education_Degree_Choices_Enum.Other:
      return 'Other';
    case Candidate_Education_Degree_Choices_Enum.NoDegree:
      return 'No Degree';
    default:
      return defaultValue;
  }
}

/**
 * Recursively sets an already existing interface/type props to be required.
 * Can be used to select a property of a nested type without having yo ignore TS,
 * as many generated types are optional.
 *
 * @example
 * NoUndefinedField<SelectHomePageInfoQuery>['candidate'][number]['icims_profile_person']['icims_people'][number]['icims_applicant_workflows'][number]['icims_applicant_workflow_statuses'][number]
 *
 * some properties from the example are optional, and will raise the type guard
 * at the icims_people prop as well as the other child of optional props.
 * @deprecated
 */
export type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

/**
 * Makes all properties of an object type non-nullable, recursively.
 *
 * @template T The object type to be transformed.
 *
 * @example
 * type OriginalType = {
 *   a: string | null;
 *   b: {
 *     c: number | null;
 *     d: {
 *       e: boolean | null;
 *     } | null;
 *   } | null;
 * };
 *
 * type NonNullableType = DeepNonNullable<OriginalType>;
 *
 * NonNullableType would be:
 * {
 *   a: string;
 *   b: {
 *     c: number;
 *     d: {
 *       e: boolean;
 *     };
 *   };
 * }
 */
export type DeepNonNullable<T> = {
  [P in keyof T]: NonNullable<T[P]> extends object
    ? DeepNonNullable<NonNullable<T[P]>>
    : NonNullable<T[P]>;
};

type Switch<T, R> = { match: T | T[]; to: R };

/**
 * Switch a matched string
 *
 * @example
 * When youre looking to match some of the possibles values to the target value
 *
 * ```ts
 *  const periods: 'MONTHLY' | 'YEARLY' | 'QUARTERLY' = 'YEARLY';
 *  const candidateSalaryTimeFrame = switchMatchingValue(
 *   periods,
 *   {
 *      match: 'YEARLY',
 *     to: 'Annually',
 *    },
 *    {
 *      match: 'QUARTERLY',
 *     to: 'Quarterly',
 *    },
 *  );
 *  console.log(candidateSalaryTimeFrame) // 'Annually'
 * ```
 *
 * @example
 * When youre looking to match different posibilities of one value to the target value
 *
 * ```ts
 *  const periods: 'MONTHLY' | 'YEARLY' | 'QUARTERLY' | 'ANNUALLY' = 'YEARLY';
 *  const candidateSalaryTimeFrame = switchMatchingValue(
 *   periods,
 *   {
 *      match: ['YEARLY', 'ANNUALLY'],
 *     to: 'Annually',
 *    },
 *  );
 *  console.log(candidateSalaryTimeFrame) // 'Annually'
 * ```
 */
export function switchMatchingValue<T, R>(
  subject: T,
  switches: Switch<T, R>[] | Switch<T, R>,
): T | R {
  // eslint-disable-next-line no-restricted-syntax
  for (const { match, to } of Array.isArray(switches) ? switches : [switches]) {
    // eslint-disable-next-line no-restricted-syntax
    for (const currentMatch of Array.isArray(match) ? match : [match]) {
      if (currentMatch === subject) return to;
    }
  }
  return subject;
}

// utility to catch error message in a typesafe way, taken from https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function toFriendlyBoolean(value?: boolean) {
  if (value === false) return 'No';
  if (value === true) return 'Yes';
  return '';
}

/**
 * Replaces any `undefined` values in an object with the corresponding default values
 * on the shallow level.
 *
 * @param {T} obj The original object to modify.
 * @param {T} defaults The default values to use when replacing `undefined` values.
 * @returns {T} A new object with any `undefined` values replaced with their corresponding default values.
 *
 * @example
 * const obj = {
 *   a: 1,
 *   b: undefined,
 * };
 *
 * const defaults = {
 *   a: 22,
 *   b: 0,
 * };
 *
 * const result = switchUndefinedWithDefault(obj, defaults);
 * console.log(result);
 * // Output: { a: 1, b: 0  }
 *
 * @todo mage this function work with nested properties
 */
export function switchUndefinedWithDefault<T extends { [key: string]: any }>(
  obj: T,
  defaults: T,
): T {
  const result: any = {};

  Object.entries(defaults).forEach(([key, value]) => {
    result[key] = obj[key] === undefined ? value : obj[key];
  });

  return result;
}
