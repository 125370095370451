import {
  Heading,
  Text,
  Flex,
  Image,
  Link,
  ArrowLeftIcon,
  Box,
  UnorderedList,
  ListItem,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Tag,
  Badge,
  HStack,
} from '@terminal/design-system';
import { PageLayout } from 'talent-hub/components';
import { Link as RouterLink } from 'react-router-dom';
import {
  Employment_Type_Choices_Enum,
  Job_Status_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { employmentTypeDisplayNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { serializeJob } from './ViewJob.serializer';
import backgroundImg from '../create-edit-job/assets/background.svg?url';
import bagImg from './assets/bag.svg?url';

export function ViewJob({
  pageLayoutProps,
  job,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  job: ReturnType<typeof serializeJob>;
}) {
  return (
    <PageLayout
      {...pageLayoutProps}
      headerTitle={
        <HStack mt={1}>
          <Heading variant="heading-2" as="h1">
            {job.jobTitle}
          </Heading>
          {job.status === Job_Status_Choices_Enum.Draft ? (
            <Badge colorScheme="warning" fontSize="sm">
              Draft
            </Badge>
          ) : (
            <Badge colorScheme="primary" fontSize="sm">
              Pending Approval
            </Badge>
          )}
        </HStack>
      }
      superTitle={
        <Link
          textDecoration="none"
          pb={2}
          colorScheme="brand"
          as={RouterLink}
          to="/role"
          textTransform="none"
        >
          <ArrowLeftIcon mr={2} /> Back to Manage Roles
        </Link>
      }
    >
      <Box
        backgroundImage={backgroundImg}
        pos="fixed"
        left={0}
        top={0}
        bottom={0}
        right={0}
        zIndex={-1}
        pointerEvents="none"
      />
      <Flex flexDir={['column', 'column', 'row']} alignItems="start" p={6}>
        <Flex
          w="xs"
          flexDir="column"
          mr={[0, 0, 6]}
          py={6}
          px={3}
          bg="bg.tertiary"
          border="1px solid"
          borderColor="ui.secondary"
        >
          <Heading variant="heading-3">We&apos;re Reviewing Your New Role</Heading>
          <Text mt={2}>
            Thank you for submitting your new role. Our team is reviewing your requested position,
            with approvals for core roles within one business day. Here&apos;s what to expect next:
          </Text>
          <UnorderedList pl={2} pt={2}>
            <ListItem>
              <Text>Your new role will be live seven days after approval</Text>
            </ListItem>
            <ListItem>
              <Text>
                Your assigned Customer Success Director will contact you to discuss compensation,
                leveling, and the Terminal process
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                We&apos;ll check in with you on role progress after 2 weeks, 30 days, and 90 days
              </Text>
            </ListItem>
          </UnorderedList>
          <Image alignSelf="center" src={bagImg} alt="" maxH={48} maxW={48} mt={10} />
        </Flex>
        <Flex
          w="full"
          h="full"
          flexDir="column"
          py={7}
          px={[6, 6, 10]}
          mt={[6, 6, 0]}
          bg="bg.primary"
          border="1px solid"
          borderColor="ui.secondary"
        >
          <Heading variant="heading-2">Basic Job Information</Heading>
          <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
            <FormControl>
              <FormLabel mb={2}>Job Title</FormLabel>
              <Text>{job.jobTitle}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Number of Positions</FormLabel>
              <Text>{job.numberPositions}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Job Type/Family</FormLabel>
              <Text>{job.jobType}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Employment Type</FormLabel>
              <Text>{job.employmentType}</Text>
            </FormControl>
          </Grid>
          <Flex mt={[4, 4, 6]}>
            <FormControl>
              <FormLabel>Acceptable Locations</FormLabel>
              <Flex flexWrap="wrap">
                {job.locations?.map((location) => (
                  <Tag key={location} mr={3} mt={2} colorScheme="disabled" variant="subtle">
                    {location}
                  </Tag>
                ))}
              </Flex>
            </FormControl>
          </Flex>

          <Divider mb={10} mt={8} />

          <Heading variant="heading-2">Salary Information</Heading>
          <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
            <FormControl justifyContent="center" display="flex" flexDirection="column">
              <FormLabel mb={2}>
                {job.employmentType ===
                employmentTypeDisplayNames[Employment_Type_Choices_Enum.Contract]
                  ? 'Contractor Rate Range (USD)'
                  : 'Salary Range (USD)'}
              </FormLabel>
              <Text>
                {job.useTerminalRange
                  ? "This job listing will use Terminal's recommended range"
                  : job.salaryRange}
              </Text>
            </FormControl>
            <Flex flexDir="column" gridGap={[4, 4, 6]}>
              {job.employmentType ===
              employmentTypeDisplayNames[Employment_Type_Choices_Enum.Contract] ? (
                <FormControl maxW={44}>
                  <FormLabel mb={2}>Contract Length</FormLabel>
                  <Text>{job.contractLength}</Text>
                </FormControl>
              ) : (
                <>
                  <FormControl maxW={44}>
                    <FormLabel mb={2}>Offering Bonus?</FormLabel>
                    <Text>{job.offeringBonus}</Text>
                  </FormControl>
                  <FormControl maxW={44}>
                    <FormLabel mb={2}>Offering Equity?</FormLabel>
                    <Text>{job.offeringEquity}</Text>
                  </FormControl>
                </>
              )}
            </Flex>
          </Grid>

          <Divider mb={10} mt={8} />

          <Heading variant="heading-2">Skills & Experience</Heading>
          <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
            <FormControl>
              <FormLabel mb={2}>Level</FormLabel>
              <Text>{job.level}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Tech Stack</FormLabel>
              {job.techStack?.map((skill: string) => (
                <Tag key={skill} mr={3} mt={2} colorScheme="disabled" variant="subtle">
                  {skill}
                </Tag>
              ))}
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Years of Experience</FormLabel>
              <Text>{job.yearsExperience}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Education Required</FormLabel>
              <Text>{job.educationRequired}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Required Skills</FormLabel>
              {job.requiredSkills?.map((skill: string) => (
                <Tag key={skill} mr={3} mt={2} colorScheme="disabled" variant="subtle">
                  {skill}
                </Tag>
              ))}
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Nice-to-Have Skills</FormLabel>
              {job.niceToHaveSkills?.map((skill: string) => (
                <Tag key={skill} mr={3} mt={2} colorScheme="disabled" variant="subtle">
                  {skill}
                </Tag>
              ))}
            </FormControl>
          </Grid>

          <Divider mb={10} mt={8} />

          <Heading variant="heading-2">Role Description Details</Heading>
          <Flex w="full" mt={7} flexDir="column" gridGap={6}>
            <FormControl>
              <FormLabel mb={2}>About the Role</FormLabel>
              <Text whiteSpace="pre-wrap">{job.aboutRole}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>What You&apos;ll Do</FormLabel>
              <Text whiteSpace="pre-wrap">{job.whatYouDo}</Text>
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>What You&apos;ll Bring</FormLabel>
              <Text whiteSpace="pre-wrap">{job.whatYouBring}</Text>
            </FormControl>
          </Flex>

          {/* Hiring manager info */}
          <Flex bg="bg.tertiary" mt={9} p={6} flexDir="column">
            <Heading variant="heading-2" mt={2}>
              Hiring Manager Info
            </Heading>
            <Flex w="full" mt={7} flexDir="column" gridGap={6}>
              <FormControl>
                <FormLabel mb={2}>Hiring Manager</FormLabel>
                <Text>{job.hiringManager}</Text>
              </FormControl>
              <FormControl>
                <FormLabel mb={2}>Additional Notes</FormLabel>
                <Text whiteSpace="pre-wrap">{job.additionalNotes}</Text>
              </FormControl>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
}
