import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleFunction, PartsStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { checkboxTheme } from '../Checkbox';
import { textTheme } from '../../Text';

const baseStyleControl: SystemStyleFunction = (controlStyle) => {
  return {
    ...controlStyle,
    borderRadius: 'full',
    borderColor: 'ui.primary',
    _checked: {
      ...controlStyle?.['_checked'],
      color: 'brand.main',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        bg: 'currentColor',
      },
    },
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  label: checkboxTheme.baseStyle.label,
  control: baseStyleControl(checkboxTheme?.baseStyle?.control as any),
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    control: { width: 3, height: 3 },
    label: textTheme.variants.caption,
  },
  md: {
    control: { w: 4, h: 4 },
    label: textTheme.variants.body,
  },
  lg: {
    control: { w: 5, h: 5 },
    label: textTheme.variants.body,
  },
};

export const radioTheme = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
  },
};
