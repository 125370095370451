import moment from 'moment';
import { Duration } from 'luxon';
import { reject, equals } from 'ramda';
// TODO: All hasura dates come in string format. We need to update the date typings in functions
// and here to be very explicit about whether their type is string or Date

// newest to oldest
export function compareByDateAsc(dateA: Date | string, dateB: Date | string): number {
  return new Date(dateB).getTime() - new Date(dateA).getTime();
}

// oldest to newest
export function compareByDateDsc(dateA: Date | string, dateB: Date | string): number {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

export function compareByObjectsDateAsc(
  { date: dateA }: { date?: Date | string },
  { date: dateB }: { date?: Date | string },
): number {
  if (!dateB) {
    return 1;
  }

  if (!dateA) {
    return -1;
  }

  return compareByDateAsc(dateA, dateB);
}

export function compareByObjectsDateDsc(
  { date: dateA }: { date?: Date | string },
  { date: dateB }: { date?: Date | string },
): number {
  if (!dateB) {
    return -1;
  }

  if (!dateA) {
    return 1;
  }

  return compareByDateDsc(dateA, dateB);
}

export const compareByPropDateAsc =
  (prop: string) =>
  (
    objA: { [key: string]: Date | string | any },
    objB: { [key: string]: Date | string | any },
  ): number => {
    // @ts-ignore
    return new Date(objB[prop]).getTime() - new Date(objA[prop]).getTime();
  };

export const compareByPropDateDsc =
  (prop: string) =>
  (objA: { [key: string]: Date }, objB: { [key: string]: Date }): number => {
    // @ts-ignore
    return new Date(objA[prop]).getTime() - new Date(objB[prop]).getTime();
  };

export function reduceToEarliestDate(prevDate: Date, nextDate: Date): Date {
  if (prevDate != null) {
    return new Date(nextDate).getTime() < new Date(prevDate).getTime() ? nextDate : prevDate;
  }

  return nextDate;
}

export const convertFromMinutesToHuman = (minutes: number) => {
  return Duration.fromObject(
    reject(equals(0))(
      Duration.fromMillis(minutes * 60000)
        .shiftTo('years', 'weeks', 'days', 'hours', 'minutes')
        .toObject() as any,
    ),
  ).toHuman();
};

export function toDatesTitle({
  startDate,
  endDate,
  isCurrent,
  format,
}: {
  startDate?: string | null;
  endDate?: string | null;
  isCurrent: boolean;
  format: string;
}) {
  if (startDate && isCurrent) {
    return `${moment(startDate).format(format)} - Current`;
  }

  if (startDate && endDate) {
    return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`;
  }

  if (startDate && !endDate) {
    return `${moment(startDate).format(format)} - Unknown`;
  }

  if (!startDate && endDate) {
    return isCurrent ? 'Current' : `Unknown - ${moment(endDate).format(format)}`;
  }

  return isCurrent ? 'Current' : null;
}
