import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { cssVar } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('switch-bg');

export const switchTheme = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    track: defineStyle({
      [$bg.variable]: 'colors.ui.darker.disabled',
      _checked: {
        [$bg.variable]: `colors.brand.main`,
      },
      bg: $bg.reference,
    }),
    thumb: defineStyle({
      [$bg.variable]: 'colors.ui.darker.disabled',
    }),
  }),
  defaultProps: {
    size: 'md',
  },
});
