import { useEffect, useReducer } from 'react';
import type { Reducer } from 'react';
import { useLocation } from 'react-router-dom';

import { firebaseAuth } from 'global/firebaseApp';
import { useToast } from '@terminal/design-system';
import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { useHandleAuthBackendError } from '../auth.utils';
import { applyEmailVerificationCode } from '../authMethods';
import { VerifyEmailLinkExpiredPage } from './VerifyEmailLinkExpiredPage';
import { EmailVerifyInProgressPage } from './EmailVerifyInProgressPage';
import type { AuthenticationRouterOverwrite } from '../types';

export function VerifyEmailController({
  redirectTo,
  onEmailVerificationComplete,
  overwrite,
}: {
  redirectTo: (path: string) => void;
  onEmailVerificationComplete?: (firebaseUID: string) => Promise<string | null>;
  overwrite?: AuthenticationRouterOverwrite;
}) {
  const queryString = new URLSearchParams(useLocation().search);
  const oobCode = queryString.get('oobCode'); // security code from firebase base email verification action email

  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );

  const toast = useToast({
    position: 'top',
    duration: 6000,
  });

  const { isError } = toAsyncProps(state);

  useHandleAuthBackendError({
    isError,
    error: state.error,
    throwIfNoFriendlyError: true,
    errorsWithFriendlyMessage: {
      'no-code': 'There is not code in your email validation link',
      'auth/expired-action-code': 'The validation link has expired. Please request a new one.',
      'auth/invalid-action-code': 'The validation link is not valid. Please request a new one.',
    },
  });

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        dispatch({ type: 'pending' });

        if (oobCode) {
          await applyEmailVerificationCode({ auth: firebaseAuth }, oobCode);

          toast({
            status: 'success',
            description: 'Email validated successfully',
          });

          dispatch({ type: 'resolved', data: null });

          if (onEmailVerificationComplete) {
            const redirectionURL = await onEmailVerificationComplete(
              firebaseAuth.currentUser?.uid as string,
            );

            if (redirectionURL) {
              // Do we really need a full page refresh or we can use the SPA approach?
              window.location.href = redirectionURL;
              return;
            }
          }

          // redirect the page to the continueURL of the querystring
          redirectTo('from-with-refresh');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw { message: 'No code in querystring', code: 'no-code' };
        }
      } catch (e) {
        dispatch({ type: 'rejected', error: e });
      }
    };

    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return (
      <VerifyEmailLinkExpiredPage
        redirectTo={redirectTo}
        pageTemplateOverwrite={
          overwrite?.pageTemplate?.['verify-email'] || overwrite?.pageTemplate?.default
        }
      />
    );
  }

  return (
    <EmailVerifyInProgressPage
      pageTemplateOverwrite={
        overwrite?.pageTemplate?.['verify-email'] || overwrite?.pageTemplate?.default
      }
    />
  );
}
