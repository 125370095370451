import { useMutation } from '@apollo/client';
import { useToast } from '@terminal/design-system';
import type { ApolloError } from '@apollo/client';
import Sentry from 'global/sentry';
import { useState } from 'react';
import { useCustomerAuth } from 'talent-hub/utils';
import { useImportGooglePlacesScript, useQuery } from 'global/utils';
import type { StepInfo } from '../../Onboarding.types';
import { CompanyLocation } from './CompanyLocation';
import type {
  SelectCountryListQuery,
  SelectCountryListQueryVariables,
  UpsertCompanyLocationMutation,
  UpsertCompanyLocationMutationVariables,
} from './data';
import { SelectCountryList, UpsertCompanyLocation } from './data';
import { onboardingState } from '../../Onboarding.utils';

export function CompanyLocationController({
  onStepComplete,
  stepInfo,
}: {
  onStepComplete: () => void;
  stepInfo: StepInfo;
}) {
  const isGooglePlaceAPIReady = useImportGooglePlacesScript();
  const [location, setLocation] = useState<{ country: string; country_id: number | null }>({
    country: '',
    country_id: null,
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState<number | string>(-1);
  const toast = useToast();
  const { user } = useCustomerAuth();

  const normalizedCountries = useQuery<SelectCountryListQuery, SelectCountryListQueryVariables>(
    SelectCountryList,
  );

  const { placeIDToCountryIDMap, countryNameToIDMap } =
    normalizedCountries.data?.country_choices?.reduce<{
      placeIDToCountryIDMap: Record<string, number>;
      countryNameToIDMap: Record<string, number>;
    }>(
      (map, obj: { place_id: string | null; name: string; id: number }) => {
        if (!obj.place_id) return map;
        return {
          placeIDToCountryIDMap: {
            ...map.placeIDToCountryIDMap,
            [obj.place_id]: obj.id,
          },
          countryNameToIDMap: {
            ...map.countryNameToIDMap,
            [obj.name.toLowerCase()]: obj.id,
          },
        };
      },
      { placeIDToCountryIDMap: {}, countryNameToIDMap: {} },
    ) || { placeIDToCountryIDMap: {}, countryNameToIDMap: {} };

  const [saveCompanyLocation, { loading: isLoading_upsertCompanyLocation }] = useMutation<
    UpsertCompanyLocationMutation,
    UpsertCompanyLocationMutationVariables
  >(UpsertCompanyLocation, {
    onCompleted: (value) => {
      if (value.insert_client_prospect_one == null) {
        // Technically this should not happen as apollo is indicating the insertion was successful
        Sentry.captureException(new Error('Saving location data did not return location data'));
        return;
      }

      onboardingState.update(
        'location',
        value.insert_client_prospect_one.country ? value.insert_client_prospect_one.country : null,
      );
      onStepComplete();
    },
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
  });

  function handleNextStep() {
    if (!location.country) return;

    saveCompanyLocation({
      variables: {
        companyLocation: location.country,
        country_id: location.country_id,
        userId: user?.id as number,
        lastStepCompleted: 'Location',
      },
    });
  }

  const handleOnCheckboxClick = (value: number | string) => {
    setSelectedCheckbox(value);
  };

  return (
    <CompanyLocation
      placeIDToCountryIDMap={placeIDToCountryIDMap}
      countryNameToIDMap={countryNameToIDMap}
      isTypeaheadReady={isGooglePlaceAPIReady === 'ready'}
      onNextClick={() => {
        handleNextStep();
      }}
      onLocationChange={({ country, country_id }) => {
        setLocation({ country, country_id });
      }}
      loadingMutation={isLoading_upsertCompanyLocation}
      selectedLocation={location.country}
      selectedCheckbox={selectedCheckbox}
      onCheckboxClick={handleOnCheckboxClick}
      stepInfo={stepInfo}
    />
  );
}
