import { Link as RouterLink } from 'react-router-dom';
import {
  CheckCircleSolidIcon,
  Text,
  HStack,
  DashedCircleIcon,
  Button,
  Box,
  Badge,
  ArrowRightIcon,
  Skeleton,
} from '@terminal/design-system';

export function RoleMilestone({
  milestoneNumber,
  milestoneTitle,
  buttonText,
  buttonRoute,
  date,
  datePrefix,
  completed,
  isLoading,
}: {
  milestoneNumber: number;
  milestoneTitle: string;
  buttonText: string;
  buttonRoute: string;
  date: string;
  datePrefix: string;
  completed: boolean;
  isLoading: boolean;
}) {
  return (
    <HStack
      __css={{
        '&:not(:last-child)': {
          borderBottom: '1px',
          borderColor: 'ui.inverse.secondary',
        },
      }}
      py={4}
      justifyContent="space-between"
    >
      <Box pl={4} flex="2">
        <Text variant="caption" color="text.secondary">{`Milestone ${milestoneNumber}`}</Text>
        <Text color="text.primary">{milestoneTitle}</Text>
        <Skeleton isLoaded={!isLoading} w={40}>
          <Badge fontSize="sm" colorScheme={completed ? 'success' : 'disabled'}>
            {`${datePrefix} ${date}`}
          </Badge>
        </Skeleton>
        <Button
          as={RouterLink}
          colorScheme="primary"
          mt={1}
          rightIcon={<ArrowRightIcon />}
          size="sm"
          to={buttonRoute}
          variant="ghost"
        >
          {buttonText}
        </Button>
      </Box>
      <Skeleton isLoaded={!isLoading}>
        {completed ? (
          <CheckCircleSolidIcon color="ui.success" w={8} h={8} />
        ) : (
          <DashedCircleIcon color="brand.main" w={8} h={8} />
        )}
      </Skeleton>
    </HStack>
  );
}
