export type Tracker = {
  name: string;
  initialize: (meta: {
    mixpanelToken?: string;
    segmentWriteKey?: string;
    appName?: string;
  }) => Promise<any>;
  identify: (id: any, options?: Object) => Promise<any>;
  isInitialized: () => Boolean;
  track: (name: string, properties?: Object) => Promise<any>;
  trackPage: (category?: string, name?: string, properties?: Object) => Promise<any>;
  setUserProperties: (name: string, email: string) => Promise<any>;
  reset: () => Promise<any>;
};

let enabledTrackers: Tracker[] = [];

const allSettled = async (mapFunction: (t: Tracker) => Promise<any>) => {
  return Promise.all(enabledTrackers.map(mapFunction).map((p) => p.catch((e) => e)));
};

export const initialize = async (trackers: Tracker[], meta: Object) => {
  enabledTrackers = trackers;
  await allSettled(async (t) => t.initialize(meta || {}));
};

export const enableTracker = (tracker: Tracker) => {
  // console.log('^^^ enabledTracker', tracker.name);
  enabledTrackers.push(tracker);
};

export const identify = async (id: any, options: Object) => {
  await allSettled(async (t) => t.identify(id, options));
};

export const track = async (name: string, properties?: Object) => {
  // console.log('^^^ track', JSON.stringify({ name, properties }, null, 2));
  await allSettled(async (t) => t.track(name, properties));
};

export const trackPage = async (category?: string, name?: string, properties?: Object) => {
  // console.log('^^^ trackPage', JSON.stringify({ name, properties }, null, 2));
  await allSettled(async (t) => t.trackPage(category, name, properties));
};

export const setUserProperties = async (name: string, email: string) => {
  await allSettled(async (t) => t.setUserProperties(name, email));
};

export const reset = async () => {
  await allSettled(async (t) => t.reset());
};

export const name = {
  ///
  // ATTENTION: All event names should follow the <object> <action> format. Please consult our docs
  // for more information. https://www.notion.so/Naming-Conventions-0b0f25636d014e8c8ce95dff32e234d5
  ///
  errorDisplayed: 'Error Displayed',
  ctaClicked: 'Cta Clicked',

  // User management
  authentication: {
    started: 'Authentication Started',
    completed: 'Authentication Completed',
  },
  firebaseRegistration: {
    started: 'Firebase Registration Started',
    completed: 'Firebase Registration Completed',
  },
  signedOut: 'Signed Out',

  // Talent Hub
  // *********************************************

  // Dashboard actions
  viewedDashboard: 'Viewed Page - Dashboard', // Deprecated

  // Your Members actions
  viewedYourMembers: 'Viewed Page - Your Members', // Deprecated
  navigatedToDayforce: 'Navigated to Dayforce', // Deprecated

  // Role actions
  viewedRole: 'Viewed Page - Role', // Deprecated
  viewedRoleTab: 'Viewed Role Tab', // Deprecated
  clickedCreateNewRole: 'Clicked Create New Role', // Deprecated

  // Candidate Actions
  viewedCandidateProfile: 'Viewed Candidate Profile', // Deprecated
  requestedCandidateResume: 'Requested Candidate Resume', // Deprecated
  startedSubmissionAdvance: 'Started Profile Submission - Advance', // Deprecated
  startedSubmissionReject: 'Started Profile Submission - Reject', // Deprecated
  completedSubmissionAdvance: 'Completed Profile Submission - Advance', // Deprecated
  completedSubmissionReject: 'Completed Profile Submission - Reject', // Deprecated
  cancelledSubmission: 'Cancelled Profile Submission', // Deprecated
  dismissedModal: 'Closed Modal', // Deprecated

  // Terminal Directory actions
  viewedTerminalDirectory: 'Viewed Page - Terminal Directory', // Deprecated

  // Change Organization
  viewedChangeOrganization: 'Viewed Page - Change Organization', // Deprecated

  exploreCandidates: {
    searchSavedRun: 'Run Candidates Saved Search',
    searchSaved: 'Candidates Search Saved',
    searchUsed: 'Candidates Searched',
    profileViewed: 'Candidate Profile Viewed',
    inviteToInterview: 'Customer Interview Requested',
  },

  // Candidate actions
  assessment: {
    /**
     * Mixpanel only. Segment receives the event from the backend
     */
    completed: 'Assessment Completed',
    redirected: 'Assessment Redirect Started',
    invitationCreated: 'Assessment Invitation Created',
    clicked: 'Assessment Begin Clicked',
    viewed: 'Assessment Viewed',
  },

  homePage: {
    onboarding: {
      slidesViewed: 'Onboarding Slides Viewed',
      modalViewed: 'Onboarding Modal Viewed',
      modalClosed: 'Onboarding Modal Closed',
    },
    jobMatches: {
      viewed: 'Job Matches Viewed',
    },
    personalizedHeader: {
      actionViewed: 'Personalized Header Action Viewed',
    },
  },

  referralPage: {
    referralLinkCopied: 'Referral Link Copied',
  },

  job: {
    detailsViewed: 'Job Details Viewed',
  },
  // TODO: remove this when preparing to deploy the direct application feature branch
  jobApplicant: {
    formSubmitted: 'Application Form Submitted',
    waitlisted: 'Applicant Waitlisted',
    completed: 'Application Completed',
  },

  directApplication: {
    sectionViewed: 'Direct App Section Viewed',
    sectionCompleted: 'Direct App Section Completed',
    formSubmitted: 'Application Form Submitted',
  },

  profileFlow: {
    completed: 'Profile Flow Completed',
    started: 'Profile Flow Started',
  },

  editProfile: {
    editedEntry: 'Edit Profile - Edited Entry', // Deprecated
    addedEntry: 'Edit Profile - Added Entry', // Deprecated
    deletedEntry: 'Edit Profile - Deleted Entry', // Deprecated
    clickedToAddFirstEntry: 'Edit Profile - Clicked to Add First Entry', // Deprecated
    openedEditModal: 'Edit Profile - Opened Edit Modal', // Deprecated
  },

  resume: {
    attach: {
      start: 'Resume Attached',
    },
    parse: {
      start: 'Resume Parse Started',
      success: 'Resume Parse Completed',
    },
  },

  prospectOnBoarding: {
    stepCompleted: 'Prospect Step Completed',
  },
};
