import type { FetchResult } from '@apollo/client';
import type { DeleteSavedCandidateMutation, InsertSavedCandidateMutation } from 'talent-hub/utils';
import {
  CandidateProfile,
  useCandidateProfileController,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/candidate-profile';
import type { ViewType } from 'talent-hub/shared/features/explore-candidates/';
import { useState, useEffect } from 'react';

export function CandidateProfileController({
  onCloseCandidateProfileClick,
  handleOnRemoveSaveCandidate,
  handleOnSaveCandidate,
  isInLimitedMode,
  onOpenBookCallModal,
  viewPage,
  candidateID,
}: {
  onCloseCandidateProfileClick: () => void;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  isInLimitedMode?: boolean;
  onOpenBookCallModal: () => void;
  viewPage: ViewType;
  candidateID: number;
}) {
  const {
    showCandidateProfile,
    profile,
    loadingCandidateInformation,
    candidateQueryErrors,
    candidateHighlights,
  } = useCandidateProfileController({ candidateID });
  // TODO: [TAL-948] - Refactor save_candidate logic
  // Start save_candidate logic
  const [isSaved, setIsSaved] = useState<boolean>(profile.isSaved);
  useEffect(() => {
    setIsSaved(profile.isSaved);
  }, [profile.isSaved]);
  // end save_candidate logic

  return (
    <CandidateProfile
      onResumeLinkClicked={onOpenBookCallModal}
      onInviteRequestClick={onOpenBookCallModal}
      isInLimitedMode={isInLimitedMode}
      profile={profile}
      loading={loadingCandidateInformation}
      isOpen={showCandidateProfile}
      onClose={onCloseCandidateProfileClick}
      queryError={candidateQueryErrors?.message}
      onOpenShareWithModal={onOpenBookCallModal}
      candidateHighlights={candidateHighlights}
      viewPage={viewPage}
      saveCandidateUtils={{
        handleOnRemoveSaveCandidate,
        handleOnSaveCandidate,
        isSaved,
        setIsSaved,
      }}
    />
  );
}
