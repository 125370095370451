import { Component } from 'react';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  FallbackComponent: ReactNode;
}

interface State {
  hasError: boolean;
}

/** @deprecated duplicated from global/components */
export class UIErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return this.props.FallbackComponent;
    }

    return this.props.children;
  }
}
