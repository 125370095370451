import { Box, Input, Heading } from '@terminal/design-system';
import {
  OnBoardingPage,
  LeftHeaderTitle,
  StepProgressBar,
  RightFooter,
  RightContentContainer,
  LeftQuote,
} from '../../components';
import type { StepInfo } from '../../Onboarding.types';
import quoteProfileImageSrc from '../../assets/customers/david-hegarty.jpeg';

export function CompanyName({
  onNextClick,
  stepInfo,
  value = '',
  onValueChange,
  isNextDisabled,
  isLoading,
}: {
  onNextClick: () => void;
  stepInfo: StepInfo;
  value?: string;
  onValueChange: (value: string) => void;
  isNextDisabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <OnBoardingPage
      leftContent={
        <Box ml={[0, 0, 10, 0, 0]}>
          <LeftHeaderTitle text="Our Vetted Candidates <strong>Are Waiting</strong>" />
          <Box mt={10}>
            <LeftQuote
              name="David Hegarty"
              description="Founder & CEO, Playbook"
              imageSrc={quoteProfileImageSrc}
            >
              We couldn&apos;t have built anything without the engineering hires from Terminal. Had
              we not partnered with Terminal, I don&apos;t actually know how we would have gotten
              started. It was extremely hard to hire engineers.
            </LeftQuote>
          </Box>
        </Box>
      }
      rightContent={
        <RightContentContainer mx={[0, 0, '6rem', '8rem', '12.5rem']} maxW="sm">
          <StepProgressBar stepInfo={stepInfo} />
          <Heading variant="heading-2" textColor="text.primary" px={8} whiteSpace="nowrap">
            What is your company&apos;s name?
          </Heading>
          <Box mt={6} w="full">
            <Input
              value={value}
              placeholder="Type your company’s name"
              onChange={(event) => onValueChange(event.target.value)}
            />
          </Box>
          <RightFooter onNextStep={onNextClick} disabled={isNextDisabled} isLoading={isLoading} />
        </RightContentContainer>
      }
    />
  );
}
