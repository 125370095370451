import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Center,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  CoffeeCupIcon,
  Badge,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader, TableContentLoading } from 'talent-hub/components';

import type { ProfileInfo } from '../../../../Role.types';

function ProgressCandidatesCard({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardHeader>
        <Heading variant="heading-3" color="brand.darker">
          Recent Candidate Activity
        </Heading>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

export function ProgressCandidates({
  loading,
  recentCandidatesActivities,
}: {
  loading: boolean;
  recentCandidatesActivities: ProfileInfo[];
}) {
  const { activeRole } = useParams<{ activeRole: string }>();

  if (loading)
    return (
      <ProgressCandidatesCard>
        <TableContainer whiteSpace="break-spaces">
          <Table>
            <Thead>
              <Tr>
                <Th>Candidate</Th>
                <Th>Title</Th>
                <Th>Date Updated</Th>
                <Th>Current Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              <TableContentLoading columns={4} rows={3} />
            </Tbody>
          </Table>
        </TableContainer>
      </ProgressCandidatesCard>
    );

  if (!recentCandidatesActivities?.length)
    return (
      <ProgressCandidatesCard>
        <Center alignItems="center" flexDirection="column">
          <Center textAlign="center" flexDirection="column" w={['full', 'sm']}>
            <CoffeeCupIcon h={20} w={20} />
            <Text mt="2">This role does not have any recent candidate activity</Text>
          </Center>
        </Center>
      </ProgressCandidatesCard>
    );

  return (
    <ProgressCandidatesCard>
      <TableContainer whiteSpace="break-spaces" w="full" overflowX={{ base: 'auto', sm: 'hidden' }}>
        <Table w="full" overflowX={{ base: 'auto', sm: 'hidden' }}>
          <Thead>
            <Tr>
              <Th>Candidate</Th>
              <Th>Title</Th>
              <Th>Date Updated</Th>
              <Th>Current Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {recentCandidatesActivities.map(
              ({ id, name, company, title, statusName, statusColorScheme, date: updated }) => (
                <Tr key={id}>
                  <Td>
                    <Link
                      variant="label"
                      to={`/role/${activeRole}/candidate/${id}`}
                      as={RouterLink}
                    >
                      {name}
                    </Link>
                    <Text variant="caption" mt={1}>
                      {company || '-'}
                    </Text>
                  </Td>
                  <Td>{title || '-'}</Td>
                  <Td>{updated}</Td>
                  <Td>
                    {statusName && (
                      <Badge fontSize="sm" colorScheme={statusColorScheme}>
                        {statusName}
                      </Badge>
                    )}
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </ProgressCandidatesCard>
  );
}
