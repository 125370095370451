import { createContext, useContext, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import type { AvailableCurrency } from 'global/utils';
import { useLocalStorage, useQuery } from 'global/utils';
import { ActivityIndicator } from 'global/components';

import type { selectUserPrioritizedRole } from '../auth';
import { useCustomerAuth } from '../auth';
import type {
  SelectUserOrganizationDetailsQuery,
  SelectUserOrganizationDetailsQueryVariables,
} from './data';
import { SelectUserOrganizationDetails } from './data';

type Customer_AuthorizedUserSession_ContextType = {
  isClient: boolean;
  isRecruiter: boolean;
  isClientReviewer: boolean;
  isClientProspect: boolean;
  /** User role based on our hierarchy */
  userPrioritizedRole: ReturnType<typeof selectUserPrioritizedRole>;
  isAuthorized: boolean;
  /** @deprecated use viewingOrganization.ID */
  viewingOrg: number;
  /** Only works for recruiters */
  setViewingOrg: (value: number) => void;
  viewingOrganization: {
    /** The organizationID currently in session. NOTE: Only recruiters can see other organizations. */
    ID: number;
    name: string;
    /** Preferred currency for this organization */
    currency: AvailableCurrency;
  };
  isTermsOfUseAccepted: boolean;
} & ReturnType<typeof useCustomerAuth>;

const CustomerAuthorizedUserSessionContext =
  createContext<Customer_AuthorizedUserSession_ContextType | null>(null);

export function CustomerAuthorizedUserSessionProvider({ children }: { children: React.ReactNode }) {
  const ldClient = useLDClient();
  const customerAuth = useCustomerAuth();

  const [viewingOrg_recruiter, setViewingOrg_recruiter] = useLocalStorage<number>(
    'viewingOrg',
    customerAuth.user?.organization!,
  );

  const viewingOrg = customerAuth.isRecruiter
    ? viewingOrg_recruiter
    : customerAuth.user?.organization!;

  // * When the viewingOrg chages, fetch the new org name
  const { loading: userOrganizationDetail_isLoading, data: userOrganizationDetail_data } = useQuery<
    SelectUserOrganizationDetailsQuery,
    SelectUserOrganizationDetailsQueryVariables
  >(SelectUserOrganizationDetails, {
    variables: { organization: viewingOrg, userID: customerAuth.user?.id! },
    skip: !viewingOrg || !customerAuth.user?.id,
  });

  useEffect(() => {
    if (!ldClient || customerAuth.user == null || !userOrganizationDetail_data) return;
    ldClient.identify({
      key: `${customerAuth.user.id}`,
      name: `${customerAuth.user.name}`,
      custom: {
        organizationKey: `${viewingOrg}`,
        organization: `${userOrganizationDetail_data.organization[0]?.name}`,
        role: customerAuth.userPrioritizedRole,
        app: 'talent-hub',
      },
    });
  }, [
    ldClient,
    customerAuth.user,
    customerAuth.user?.id,
    customerAuth.userPrioritizedRole,
    userOrganizationDetail_data,
    userOrganizationDetail_data?.organization,
    viewingOrg,
  ]);

  if (
    userOrganizationDetail_isLoading ||
    !userOrganizationDetail_data?.organization?.[0]?.name
    // because unless this value is set we can't decide if user needs get redirected out of onboarding
    // shouldForceRedirect_fromOnboarding_toAuth_forEmailVerification === null
  ) {
    return <ActivityIndicator />;
  }

  return (
    <CustomerAuthorizedUserSessionContext.Provider
      // TODO (TP-1874): Remove this eslint ignore
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...customerAuth,
        viewingOrg,
        setViewingOrg: setViewingOrg_recruiter,
        viewingOrganization: {
          ID: viewingOrg,
          name: userOrganizationDetail_data.organization[0].name,
          currency: userOrganizationDetail_data.organization[0].currency as AvailableCurrency,
        },
        isTermsOfUseAccepted:
          !!userOrganizationDetail_data.user_info_by_pk?.agreement_acceptance_date,
      }}
    >
      {children}
    </CustomerAuthorizedUserSessionContext.Provider>
  );
}

export function useCustomerAuthorizedUserSession() {
  const context = useContext(CustomerAuthorizedUserSessionContext);

  if (!context) {
    throw new Error(
      'useCustomerAuthorizedUserSession have to be used where the CustomerAuthorizedUserSessionProvider is provided',
    );
  }

  // * this cast is required, because in the use of this hook we will be sure that there is a user in session.
  return context as {
    user: NonNullable<ReturnType<typeof useCustomerAuth>['user']>;
  } & Customer_AuthorizedUserSession_ContextType;
}
