import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Heading,
  Grid,
  Button,
  Image,
  Text,
  Badge,
  Flex,
  Skeleton,
  Divider,
  Accordion,
  SearchUserIcon,
  Modal,
  useDisclosure,
  ModalOverlay,
  CheckCircleSolidIcon,
  FormControl,
  FormErrorMessage,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@terminal/design-system';
import { Job_Status_Choices_Enum } from 'global/types';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import { Card, CardBody, CardHeader } from 'talent-hub/components';
import { NewDashboardCandidateCarousel } from 'talent-hub/shared/features/dashboard';
import machineImg from 'talent-hub/shared/features/roles/subfeatures/create-edit-job/assets/machine.svg?url';
import { ExpandIcon } from '../components';

const validation_prompt_schema = Yup.object().shape({
  prompt: Yup.string().min(1).required('Please provide a brief description of the role.'),
});

export function DashboardPendingRole({
  id,
  name,
  positions_count,
  yoe,
  topSkills,
  status,
  currentRoleCandidateMatches, // TODO: rename to current_role_candidate_matches
  handleCandidateClick,
  is_loading_candidate_matches_by_role,
  view_all_candidate_matches_link,
  action,
  generateJob,
}: {
  name: string;
  positions_count: number;
  yoe: string;
  topSkills: string;
  status: Job_Status_Choices_Enum;
  id: string;
  currentRoleCandidateMatches: {
    id: number;
    firstName: string;
    lastName?: string;
    last_name_initial?: string;
    currentRole: string;
    yearsExperience: string;
    city?: string;
    country_choice?: {
      name: string;
    };
    skills: Array<{
      id: number | string;
      name: string;
      isSelected: boolean;
    }>;
    remainingSkills?: number;
  }[];
  handleCandidateClick: (candidate: any) => void;
  view_all_candidate_matches_link: string;
  is_loading_candidate_matches_by_role: boolean;
  action: 'VIEW_JOB' | 'EDIT_JOB' | 'TELL_US_MORE';
  generateJob: {
    handleGenerateJob: ({ query }: { query: string }) => Promise<{
      success: boolean;
    }>;
    isLoading: boolean;
  };
}) {
  const promptModal = useDisclosure();
  const [was_draft_generation_successful, set_was_draft_generation_successful] = useState(false);
  const on_handle_close_prompt_modal = () => {
    set_was_draft_generation_successful(false);
    promptModal.onClose();
  };
  const promptForm = useCustomFormik<{
    prompt?: string;
  }>({
    initialValues: {
      prompt: undefined,
    },
    validateOnMount: true,
    validationSchema: validation_prompt_schema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async ({ prompt }) => {
      if (!prompt) return;
      const { success } = await generateJob.handleGenerateJob({
        query: prompt,
      });
      if (!success) return;
      set_was_draft_generation_successful(true);
    },
  });
  return (
    <>
      <Card p={0} h="auto" w="full" gap={0}>
        <CardHeader p={6} bgColor="ui.inverse.secondary" justifyContent="space-between">
          <Flex flexDir="column" w="full">
            <Heading variant="heading-3" display="block" mb={1} noOfLines={1}>
              {name}
            </Heading>
            <Flex
              flexWrap="nowrap"
              flexDir={{
                base: 'column',
                sm: 'row',
              }}
              w="ull"
            >
              <Box>
                <Text display="inline-block" variant="caption" noOfLines={1}>
                  Positions:&ensp;
                  <strong>{positions_count}</strong>
                </Text>
              </Box>
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Box>
                <Text display="inline-block" variant="caption" noOfLines={1}>
                  Experience:&ensp;<strong>{yoe}</strong>
                </Text>
              </Box>
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Box>
                <Text display="inline-block" variant="caption" noOfLines={1}>
                  Skills:&ensp;<strong>{topSkills}</strong>
                </Text>
              </Box>
              <Box
                ml={{
                  base: 0,
                  sm: 'auto',
                }}
              >
                {status === Job_Status_Choices_Enum.Draft ? (
                  <Badge colorScheme="warning" fontSize="sm">
                    Draft
                  </Badge>
                ) : (
                  <Badge colorScheme="primary" fontSize="sm">
                    Pending Approval
                  </Badge>
                )}
              </Box>
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Divider orientation="vertical" mx={3} />
            {action === 'EDIT_JOB' && (
              <Button
                id="edit-draft-button"
                variant="ghost"
                colorScheme="primary"
                to={{
                  pathname: `/role/edit/${id}`,
                  state: { from: 'dashboard' },
                }}
                as={RouterLink}
                rightIcon={<ExpandIcon />}
              >
                Edit Draft
              </Button>
            )}
            {action === 'VIEW_JOB' && (
              <Button
                variant="ghost"
                colorScheme="primary"
                to={{
                  pathname: `/role/view/${id}`,
                  state: { from: 'dashboard' },
                }}
                as={RouterLink}
                rightIcon={<ExpandIcon />}
              >
                View Role
              </Button>
            )}
            {action === 'TELL_US_MORE' && (
              <Button
                id="dashboard-tell-us-more-button"
                variant="ghost"
                colorScheme="primary"
                onClick={promptModal.onOpen}
              >
                Tell Us More
              </Button>
            )}
          </Flex>
        </CardHeader>
        <CardBody flexDir="column" p={0} py={4}>
          <Accordion
            px={4}
            py={0}
            arrowColor="brand.main"
            defaultIndex={[0]}
            customDivider={<Divider mt={4} mb={4} />}
            accordions={[
              {
                parent: (
                  <>
                    <Heading variant="heading-4" mr={2} display="inline-block">
                      Candidate Matches to Explore
                    </Heading>
                    <SearchUserIcon width={6} height={6} />
                  </>
                ),
                children: is_loading_candidate_matches_by_role ? (
                  <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                    <Skeleton height="full" width="full" />
                    <Skeleton height="full" width="full" />
                    <Skeleton height="full" width="full" />
                  </Grid>
                ) : (
                  <NewDashboardCandidateCarousel
                    showDisplayEmptyMessage
                    shouldDeferInitialization
                    key={`candidates-carousel-${id}`}
                    candidates={currentRoleCandidateMatches}
                    onItemClick={handleCandidateClick}
                    maxElements={5}
                    shouldDisplayViewAll={currentRoleCandidateMatches.length >= 3}
                    viewAllHref={view_all_candidate_matches_link}
                  />
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
      <Modal
        isCentered
        isOpen={promptModal.isOpen}
        onClose={on_handle_close_prompt_modal}
        scrollBehavior="outside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        motionPreset="scale"
      >
        <ModalOverlay bg="#25383FCC" />

        <ModalContent maxW={['21.938rem', '21.938rem', 'lg']}>
          {was_draft_generation_successful ? (
            <>
              <ModalBody textAlign="center">
                <CheckCircleSolidIcon color="ui.success" w={24} h={24} mb={4} />
                <Heading variant="heading-2">Draft Role Successfully Filled</Heading>
                <Text>
                  We&apos;ve filled out your draft job based on the information you provided. Feel
                  free to review and edit it to match your specific needs. Check out the new
                  candidate matches we&apos;ve generated based on your updated preferences!
                </Text>
              </ModalBody>
              <ModalFooter p={4} px={4}>
                <Button
                  id="review-role-tell-us-more-modal"
                  variant="solid"
                  colorScheme="primary"
                  size="lg"
                  type="button"
                  w="full"
                  onClick={on_handle_close_prompt_modal}
                >
                  Review Role
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader px={3} py={6} bgColor="bg.tertiary" textAlign="center">
                <Image src={machineImg} alt="" maxW={40} my={4} mx="auto" />
                <ModalCloseButton size="lg" top={5} right={4} id="close-tell-us-more-modal" />
                <Heading variant="heading-2" textAlign="center">
                  Tell Us More
                </Heading>
              </ModalHeader>
              <ModalBody>
                <Text>
                  In 1-3 sentences, tell us a little about the candidate you&apos;re looking for.
                  Include any details you know about, such as required skills, years of experience,
                  nice-to-haves, your preferred time zone or location, etc. We&apos;ll tailor
                  candidate suggestions to match your preferences and save the details in your draft
                  job description (which you can edit any time).
                </Text>
                <FormControl
                  mt={8}
                  isInvalid={promptForm.touched.prompt && !!promptForm.errors.prompt}
                >
                  <Textarea
                    id="prompt"
                    name="prompt"
                    aria-label="job-description"
                    rows={4}
                    onChange={promptForm.handleChange}
                    onBlur={promptForm.handleBlur}
                    value={promptForm.values.prompt}
                    disabled={promptForm.isSubmitting}
                    placeholder="Senior full stack engineer, Node and React, at least 5 years experience..."
                  />
                  {promptForm.errors.prompt && (
                    <FormErrorMessage>{promptForm.errors.prompt}</FormErrorMessage>
                  )}
                </FormControl>
              </ModalBody>
              <ModalFooter p={4} px={4}>
                <Button
                  variant="solid"
                  id="find-matches-tell-us-more-modal"
                  colorScheme="primary"
                  size="lg"
                  isLoading={generateJob.isLoading || promptForm.isSubmitting}
                  type="button"
                  w="full"
                  onClick={() => promptForm.handleSubmit()}
                >
                  Find Matches
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
