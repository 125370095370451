import type { SelectSharedCandidatesQuery } from './data';

import type { Candidate } from '../explore-candidates/data';

import { serializeCandidates } from '../explore-candidates/ExploreCandidates.serializer';

export function serializeSharedCandidates(data?: SelectSharedCandidatesQuery): {
  candidates: Candidate[];
} {
  if (!data) {
    return {
      candidates: [],
    };
  }

  return { candidates: serializeCandidates(data.shared_candidate) };
}
