import { makeVar } from '@apollo/client';
import type { ReactiveVar } from '@apollo/client';
import type { OnboardingState } from '../Prospect.types';

function createUpdateFlow(flowVar: ReactiveVar<OnboardingState>) {
  return <T extends keyof OnboardingState>(key: T, value: OnboardingState[T]) => {
    flowVar({
      ...flowVar(),
      [key]: value,
    });
  };
}

const initialValues: OnboardingState = {
  prospectID: null,
  roles: [],
  location: null,
};

const flowVar: ReactiveVar<OnboardingState> = makeVar<OnboardingState>(initialValues);

export const onboardingState = {
  update: createUpdateFlow(flowVar),
  stateVar: flowVar,
  reset: () => {
    flowVar(initialValues);
  },
};
