import {
  Flex,
  Box,
  Badge,
  HStack,
  Text,
  Heading,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  UnorderedList,
  ListItem,
  Tag,
  Divider,
  ViewOnIcon,
  Image,
  Center,
} from '@terminal/design-system';
import { SignUp as AuthSignUp, PasswordFieldWithPopoverValidator } from 'global/auth';
import type { FormikState, FormikHandlers } from 'formik';
import type { BoxProps } from '@terminal/design-system';
import { useOnScreen } from 'global/utils';
import { useRef } from 'react';
import { Dashboard } from 'talent-hub/role/prospect/dashboard/Dashboard';
import plantBGSrc from '../../assets/plant_bg.svg?url';
import {
  LeftHeaderSubtitle,
  LeftHeaderTitle,
  OnBoardingPage,
  RightContentContainer,
} from '../../components';
import clientsLogosImageSrc from '../../assets/clientsLogos.svg?url';

export function DummyCard({
  title,
  subtitle,
  caption,
  children,
  badgeColor,
  skills = [],
  ...props
}: BoxProps & {
  title: string;
  subtitle: string;
  caption: string;
  skills?: string[];
  badgeColor?: React.ComponentProps<typeof Badge>['colorScheme'];
}) {
  return (
    <Box
      w="17.75rem"
      bg="bg.primary"
      border="1px solid"
      borderColor="ui.secondary"
      p={4}
      {...props}
    >
      <Heading variant="heading-3">{title}</Heading>
      <Text variant="hint">{subtitle}</Text>
      <Text variant="hint" color="text.secondary">
        {caption}
      </Text>
      <HStack mt={4}>
        <Badge colorScheme={badgeColor || 'success'} px={2} whiteSpace="initial">
          Top Candidate
        </Badge>
        <Badge colorScheme={badgeColor || 'warning'} px={2} whiteSpace="initial">
          In-demand
        </Badge>
      </HStack>
      <Box
        mt={4}
        p={3}
        bg="ui.inverse.secondary"
        overflow="hidden"
        flex={1}
        __css={{
          display: '-webkit-box',
          WebkitLineClamp: '7',
          WebkitBoxOrient: 'vertical',
        }}
      >
        <UnorderedList>
          <ListItem color="brand.main">
            <Text variant="caption" color="text.primary">
              4 years of Tech Lead experience
            </Text>
          </ListItem>
          <ListItem color="brand.main">
            <Text variant="caption" color="text.primary">{`Has built 0->1 product with Wish`}</Text>
          </ListItem>
          <ListItem color="brand.main">
            <Text variant="caption" color="text.primary">
              M.S. Degree in Computer Science
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>

      <Flex mt={6} gridGap={2} flexWrap="wrap" pr={6} alignItems="center">
        {[
          ...skills.slice(0, 4),
          ...['Node.js', 'HTML', 'MySQL', 'Express']
            .filter((value) => !skills.slice(0, 4).includes(value))
            .slice(0, 4 - skills.slice(0, 4).length),
        ].map((skill) => (
          <Tag key={skill} px={2} size="lg" variant="subtle" colorScheme="disabled">
            {skill}
          </Tag>
        ))}
        <Text variant="caption" minW="5.5rem">
          + 4 more skills
        </Text>
      </Flex>
      <Divider mt={4} />
      <Flex alignItems="center" justifyContent="flex-end" mt={4}>
        <ViewOnIcon w={4} h={4} color="text.secondary" />
        <Text ml={1} variant="hint" color="text.secondary">
          Interviewing with <strong>4</strong> other customers
        </Text>
      </Flex>
    </Box>
  );
}

export function SignUpMatchingCandidates({
  isLoading,
  submitCount,
  onSubmit,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  onGoogleLoginClicked,
  shouldHideGoogleSSO,
  highlightedRole,
  hasMatches,
}: {
  onSubmit: () => void;
  isLoading: boolean;
  submitCount: number;
  onGoogleLoginClicked: () => void;
  shouldHideGoogleSSO: boolean;
  highlightedRole: {
    roleName: string;
    skills: string[];
    experienceDescription: string;
  };
  hasMatches: boolean;
} & Pick<
  FormikState<{ email: string; fullName: string; password: string }>,
  'errors' | 'values' | 'touched'
> &
  Pick<FormikHandlers, 'handleChange' | 'handleBlur'>) {
  const joinCompaniesTextRef = useRef(null);
  const isJoinCompaniesText_viewportVisible = useOnScreen(joinCompaniesTextRef);

  return (
    <OnBoardingPage
      leftContent={
        !hasMatches ? (
          <>
            <LeftHeaderTitle text="Sign up to browse our top vetted candidates" />
            <LeftHeaderSubtitle text="Find your next dream hire today" />
            <Box
              display={['none', 'none', 'block']}
              position="fixed"
              width="100%"
              bottom={2}
              left="40%"
            >
              <Image src={plantBGSrc} alt="plant" />
            </Box>
          </>
        ) : (
          <>
            <Heading variant="heading-1" color="brand.main" textAlign="center" w="full">
              Great News!
              <span aria-label="celebrate" role="img">
                🎉
              </span>
            </Heading>
            <Heading variant="heading-2" color="text.secondary" textAlign="center" w="full" mt={2}>
              We found vetted candidates that match your needs
            </Heading>

            <Flex w="full" mt={4} justifyContent="flex-end" pos="relative" right={[8, 12, 12]}>
              {Array.from({ length: 5 }).map((_, index, items) => {
                const inverseIndex = items.length - index;

                return (
                  <DummyCard
                    key={inverseIndex}
                    pos="absolute"
                    h="full"
                    right={`${inverseIndex * 24}px`}
                    opacity={inverseIndex > items.length - 2 ? [0.6, 0.8][index] : 1}
                    style={{ filter: `blur(${inverseIndex * 0.5}px)` }}
                    badgeColor="primary"
                    title={['Senior Frontend Engineer', 'Frontend Engineer'][index % 2] as string}
                    subtitle={
                      ['2-5 years of experience', '5-10 years of experience'][index % 2] as string
                    }
                    caption={['Medellin', 'Canada'][index % 2] as string}
                  />
                );
              })}
              <DummyCard
                pos="relative"
                title={highlightedRole?.roleName}
                subtitle={highlightedRole?.experienceDescription}
                caption="Medellín, Colombia"
                skills={highlightedRole?.skills}
              />
            </Flex>
            <Heading variant="heading-3" textAlign="center" w="full" mt={10} px={10}>
              Create your free account to see all your top matches
            </Heading>
            <Center w="full" mt={6}>
              <Image src={clientsLogosImageSrc} alt="Terminal's client logos" />
            </Center>
            <Text
              mt={3}
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
              textAlign="center"
              px={12}
              ref={joinCompaniesTextRef}
            >
              Join Calendly and other top companies that use Terminal for their hiring needs
            </Text>
          </>
        )
      }
      rightContent={
        <RightContentContainer
          mx={[0, 0, '15%', '15%', '7.5rem']}
          maxW="35rem"
          justifyContent={[
            'flex-start',
            'flex-start',
            // On average desktop, we want the form to be at the top of it's container
            // On bigger desktops, we want the form to be centered
            isJoinCompaniesText_viewportVisible === false ? 'flex-start' : 'center',
          ]}
        >
          <Box
            pos="relative"
            w="full"
            py={8}
            px={10}
            border="1px solid"
            borderColor="ui.secondary"
            borderRadius="base"
            bg="bg.primary"
            zIndex="docked"
          >
            {!shouldHideGoogleSSO && (
              <>
                <AuthSignUp.GoogleSignUpSSO
                  onGoogleLoginClicked={onGoogleLoginClicked}
                  googleSignUpButton="Create Free Account with Google"
                />
                <AuthSignUp.SignUpDivider my={6} />
              </>
            )}
            <form onSubmit={onSubmit} noValidate>
              <FormControl id="email" isInvalid={!!errors.email && !!touched.email}>
                <FormLabel htmlFor="email" color="text.primary">
                  Company Email
                </FormLabel>
                <Input
                  type="email"
                  placeholder="Your work email"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  autoCapitalize="none"
                  spellCheck={false}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                id="fullName"
                mt={[4, 4, 6]}
                isInvalid={!!errors.fullName && !!touched.fullName}
              >
                <FormLabel htmlFor="fullName" color="text.primary">
                  Full Name
                </FormLabel>
                <Input
                  placeholder="Your full name"
                  id="fullName"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                />
                <FormErrorMessage>{errors.fullName}</FormErrorMessage>
              </FormControl>

              <PasswordFieldWithPopoverValidator
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.password && !!touched.password}
                placeholder="Your Password"
                title="Your Password"
                touched={!!touched.password}
                value={values.password}
                submissionCount={submitCount}
                mt={[4, 4, 6]}
              />

              <Button
                isLoading={isLoading}
                variant="solid"
                colorScheme="primary"
                w="full"
                mt={[4, 4, 6]}
                type="submit"
                data-testid="manual-sign-up-button"
                id="manual-sign-up-button"
              >
                Create Free Account
              </Button>
              <Box mt={6}>
                <AuthSignUp.SignUpTerms />
              </Box>
            </form>
          </Box>
        </RightContentContainer>
      }
    />
  );
}

export function SignUpBlurredDashboard({
  isLoading,
  submitCount,
  onSubmit,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  onGoogleLoginClicked,
  shouldHideGoogleSSO,
  hasMatches,
}: React.ComponentProps<typeof SignUpMatchingCandidates>) {
  return (
    <Flex pos="relative" w="full" minH="100vh" justifyContent="center" alignItems="center">
      <Box
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflow="hidden"
        onClickCapture={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
        style={{ filter: `blur(5px)` }}
      >
        <Dashboard
          pageLayoutProps={{
            isClientProspect: true,
            isRecruiter: false,
            orgName: 'Trial',
            userPrioritizedRole: 'client-prospect',
            user: { name: 'User', first_name: 'User' },
            onUpgradeAccountClick: () => {},
          }}
          userDisplayName="User"
          onCandidatePreviewClick={() => {}}
          candidates={[]}
          candidateTechLead={[]}
          candidatesRecentlyActive={[]}
          candidateZeroOneExperience={[]}
          candidatesTopCandidateExperience={[]}
        />
      </Box>
      <Box pos="absolute" top={0} left={0} right={0} bottom={0} opacity={0.3} bgColor="#2C2C2C" />
      <Flex pos="relative" w="35rem" px={10} pt={8} pb={16} flexDir="column" bgColor="bg.primary">
        <Heading variant="heading-2" textAlign="center">
          {hasMatches
            ? `We found vetted candidates that match your needs! 🎉`
            : 'Sign up to browse our top vetted candidates'}
        </Heading>
        <Text mt={1} textAlign="center">
          {hasMatches
            ? 'Create your free account to see your matches'
            : 'Find your next dream hire today'}
        </Text>
        {!shouldHideGoogleSSO && (
          <Box mt={8}>
            <AuthSignUp.GoogleSignUpSSO
              onGoogleLoginClicked={onGoogleLoginClicked}
              googleSignUpButton="Create Free Account with Google"
            />
            <AuthSignUp.SignUpDivider my={6} />
          </Box>
        )}
        <form onSubmit={onSubmit} noValidate>
          <FormControl id="email" isInvalid={!!errors.email && !!touched.email}>
            <FormLabel htmlFor="email" color="text.primary">
              Company Email
            </FormLabel>
            <Input
              type="email"
              placeholder="Your work email"
              id="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              autoCapitalize="none"
              spellCheck={false}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="fullName"
            mt={[4, 4, 6]}
            isInvalid={!!errors.fullName && !!touched.fullName}
          >
            <FormLabel htmlFor="fullName" color="text.primary">
              Full Name
            </FormLabel>
            <Input
              placeholder="Your full name"
              id="fullName"
              name="fullName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullName}
            />
            <FormErrorMessage>{errors.fullName}</FormErrorMessage>
          </FormControl>

          <PasswordFieldWithPopoverValidator
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.password && !!touched.password}
            placeholder="Your Password"
            title="Your Password"
            touched={!!touched.password}
            value={values.password}
            submissionCount={submitCount}
            mt={[4, 4, 6]}
          />

          <Button
            isLoading={isLoading}
            variant="solid"
            colorScheme="primary"
            w="full"
            mt={[4, 4, 6]}
            type="submit"
            data-testid="manual-sign-up-button"
            id="manual-sign-up-button"
          >
            Create Free Account
          </Button>
          <Box mt={6}>
            <AuthSignUp.SignUpTerms />
          </Box>
        </form>
      </Flex>
    </Flex>
  );
}

export const SignUp = {
  Variation: {
    MatchingCandidates: SignUpMatchingCandidates,
    BlurredDashboard: SignUpBlurredDashboard,
  },
};
