import { Employment_Type_Choices_Enum } from 'global/types';
import { compareByObjectsDateAsc, compareByObjectsDateDsc } from 'global/utils';
import moment from 'moment';
import type { NonNullable_BrowseCandidate_Candidate } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/data';
import type { SelectSavedCandidatesQuery } from 'talent-hub/shared/features/explore-candidates/subfeatures/saved-candidates/data';
import type { SelectSharedCandidatesQuery } from 'talent-hub/shared/features/explore-candidates/subfeatures/shared-candidates/data';
import type { JobCandidateProfileQuery } from 'talent-hub/shared/features/roles/subfeatures/candidate-profile/data';
import type { CandidateWorkflowStatus } from 'talent-hub/types';
import type { AllStagesAllBuckets } from '../workflow-status.utils';
import { stripStatusesOfCandidatePreviousAttempt } from '../workflow-status.utils';

export type InDemandWorkflowStage = {
  statusText?: string;
  date?: string;
};

type CandidateStageInfoWithWorkflowInfo = {
  workflowInfo: InDemandWorkflowStage;
};

export function groupCandidates_toCandidateHightlights_interviewStage({
  interview,
}: Pick<AllStagesAllBuckets<CandidateStageInfoWithWorkflowInfo>, 'interview'>): {
  interviewStage: InDemandWorkflowStage[];
} {
  const pickWorkflowData = (candidateProfile: CandidateStageInfoWithWorkflowInfo) => {
    return {
      ...candidateProfile.workflowInfo,
    };
  };

  const activeInterviews: CandidateStageInfoWithWorkflowInfo[] = interview.inProgress.all || [];

  return {
    interviewStage: activeInterviews.map(pickWorkflowData).sort(compareByObjectsDateAsc),
  };
}

export function workflowStatuses_ToCandidateWorkflowStatus<
  T extends {
    date: any;
    status: {
      title: string | null;
    } | null;
  },
>(workflowStatus: T): CandidateWorkflowStatus {
  return {
    date: workflowStatus.date,
    title: workflowStatus.status && workflowStatus.status.title ? workflowStatus.status.title : '',
  };
}

function transformToCandidatesWithStageAndCurrentStateInfo(
  applicantWorkFlows: JobCandidateProfileQuery['icims_person'][number]['all_applicant_workflows'],
): CandidateStageInfoWithWorkflowInfo[] {
  return applicantWorkFlows.map(({ workflow_statuses }) => {
    const statuses = workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus);

    const candidateStatuses: CandidateWorkflowStatus[] = stripStatusesOfCandidatePreviousAttempt(
      statuses.sort(compareByObjectsDateDsc),
    );

    const candidateLastStatus = candidateStatuses[candidateStatuses.length - 1];

    return {
      workflowInfo: {
        statusText: candidateLastStatus?.title,
        date: moment(candidateLastStatus?.date).format('MMM D, YYYY'),
      },
    };
  });
}

export function transformCandidateProfile_toInDemandBadgeInfo(
  candidateWorkflows:
    | JobCandidateProfileQuery['icims_person'][number]['all_applicant_workflows']
    | NonNullable<
        SelectSavedCandidatesQuery['saved_candidate'][number]['candidate']['icims_profile_person']
      >['icims_people'][number]['all_applicant_workflows']
    | NonNullable<
        NonNullable_BrowseCandidate_Candidate['icims_profile_person']
      >['icims_people'][number]['all_applicant_workflows']
    | NonNullable<
        SelectSharedCandidatesQuery['shared_candidate'][number]['candidate']['icims_profile_person']
      >['icims_people'][number]['all_applicant_workflows'],
) {
  const candidateStatuses = transformToCandidatesWithStageAndCurrentStateInfo(candidateWorkflows);

  const inDemand_clientPortalAdvanceStage = candidateStatuses.filter(
    ({ workflowInfo }) =>
      workflowInfo && workflowInfo.statusText === 'Submitted to Client - Client Portal Advance',
  );

  const indDemand_interviewStage = candidateStatuses.filter(
    ({ workflowInfo }) => workflowInfo && workflowInfo.statusText?.includes('Client Review'),
  );

  return {
    interviewStage: indDemand_interviewStage.length,
    clientPortalAdvanceStage: inDemand_clientPortalAdvanceStage.length,
  };
}

export const serializeBadges = {
  mutualInterest: (
    candidateJobs: JobCandidateProfileQuery['candidate'][number]['candidate_jobs'],
  ) => {
    const interestedInSameCompanyJob = candidateJobs?.some(
      (job) =>
        job.icims_profile_job.icims_jobs[0]?.icims_company?.organizations[0]
          ?.icims_company_profile_id,
    );

    if (interestedInSameCompanyJob) {
      return { text: 'Mutual Interest', variant: 'success' };
    }
    return null;
  },
  topCompany: (value?: boolean | null) =>
    value && { text: 'Top Company Experience', variant: 'accent-lightest' },
  startupCompany: (value?: boolean | null) =>
    value && { text: 'Startup Experience', variant: 'success' },
  previousHire: (value?: boolean | null) => value && { text: 'Previous Hire', variant: 'primary' },
  referredCandidate: (value?: boolean | null) =>
    value && { text: 'Referred Candidate', variant: 'accent-lighter' },
  inDemand: ({
    interviewStage,
    clientPortalAdvanceStage,
  }: {
    interviewStage: number;
    clientPortalAdvanceStage: number;
  }) => {
    if (clientPortalAdvanceStage + interviewStage >= 2)
      return { text: 'In Demand', variant: 'info' };
    return null;
  },
  builtProduct: (value: boolean) => value && { text: '0 -> 1 Experience', variant: 'primary' },
};

export const serializeHighlights = {
  peopleLeadershipExperience: (value?: number | null) => {
    if (value)
      return `<strong>${value}</strong> year${
        value > 1 ? 's' : ''
      } of people leadership experience`;
    return null;
  },
  techLeadExperience: (value?: number | null) => {
    if (value)
      return `<strong>${value}</strong> year${value > 1 ? 's' : ''} of Tech Lead experience`;
    return null;
  },
  builtProduct: (value?: string | null) =>
    value ? `Built 0->1 product with <strong>${value}</strong>` : null,
  computerScienceDegree: (value?: boolean | null) => value && 'Degree in Computer Science',
  multipleLanguagesSkills: (value?: boolean | null) =>
    value && 'Skilled in multiple languages/frameworks',
  previousHire: (value?: boolean | null) => value && 'Previously hired by a Terminal customer',
  previousWorks: (value?: string | null, value2?: string | null) => {
    if (!value && !value2) return null;
    if (value2?.trim() === value?.trim()) return `Worked for <strong>${value}</strong>`;
    if (value && value2)
      return `Worked for <strong>${value}</strong> and <strong>${value2}</strong>`;
    if (value) return `Worked for <strong>${value}</strong>`;
    if (value2) return `Worked for <strong>${value2}</strong>`;
    return null;
  },
  industryExperience: (value?: string | null, value2?: string | null) => {
    if (!value && !value2) return null;
    if (value2?.trim() === value?.trim()) return `<strong>${value}</strong> experience`;
    if (value && value2)
      return `<strong>${value}</strong> and <strong>${value2}</strong> experience`;
    if (value) return `<strong>${value}</strong> experience`;
    if (value2) return `<strong>${value2}</strong> experience`;
    return null;
  },
  englishProficiency: (value?: string | null) =>
    value ? `<strong>${value}</strong> English level` : null,
  activeMessage: ({
    interviewStageCount,
    candidateActivityInfo: { candidateCurationWorkflows, candidateStageLogs },
  }: {
    interviewStageCount: number | null;
    candidateActivityInfo: {
      candidateCurationWorkflows: NonNullable_BrowseCandidate_Candidate['candidate_curation_workflows'];
      candidateStageLogs: NonNullable_BrowseCandidate_Candidate['candidate_stage_logs'];
    };
  }): {
    isActivelyInterviewing: boolean;
    text: string;
  } => {
    /*  If candidate has “In Demand” badge, the copy at the bottom of the card should reflect how many customers candidate is interviewing with
    -> “Actively interviewing with # other customers” */
    if (interviewStageCount) {
      return {
        isActivelyInterviewing: true,
        text: `Actively interviewing with <strong>${interviewStageCount}</strong> customer${
          interviewStageCount > 1 ? 's' : ''
        }`,
      };
    }
    /*
    If candidate does not have In Demand badge, copy should reflect when candidate was last active
    -> “Active # days ago”
    -> if user is active same day, “Active today”

     active - refers to the date we use for the Browse Candidates query sort. The query looks at a few things (candidateCurationWorkflows & candidateStageLogs) to determine recent activity, then orders the results most recent --> least recent. That date is what we want to use for this "active" descriptor here
     */

    // Create and array of moment dates using the candidateCurationWorkflows and candidateStageLogs
    const candidateCurationWorkflowsDates = candidateCurationWorkflows.map((item) =>
      moment(item.created_at),
    );
    const candidateStageLogsDates = candidateStageLogs.map((item) => moment(item.created_at));

    // Create an array merging all the dates without duplicates and get the more recent date
    const allActiveDates = Array.from(
      new Set(candidateCurationWorkflowsDates.concat(candidateStageLogsDates)),
    );

    // We need to check if we have any date because moment by default if we try to get the max from an empty array it will return today's date and in this case will cause some discrepancies --- please check https://github.com/moment/moment/issues/3386
    const lastActiveDate = allActiveDates.length ? moment.max(allActiveDates) : null;

    // Get the number of days that have passed since the last day the user was active
    const daysAgoActive = moment().diff(lastActiveDate, 'days');

    // * Business logic from: https://terminal.atlassian.net/browse/TAL-934

    if (daysAgoActive === 0) {
      return {
        isActivelyInterviewing: false,
        text: 'Active today',
      };
    }

    if (daysAgoActive === 1) {
      return {
        isActivelyInterviewing: false,
        text: 'Active yesterday',
      };
    }

    if (daysAgoActive >= 2 && daysAgoActive <= 6) {
      return {
        isActivelyInterviewing: false,
        text: `Active <strong>${daysAgoActive}</strong> days ago`,
      };
    }

    if (daysAgoActive >= 7 && daysAgoActive <= 13) {
      return {
        isActivelyInterviewing: false,
        text: 'Active last week',
      };
    }

    if (daysAgoActive >= 14 && daysAgoActive <= 20) {
      return {
        isActivelyInterviewing: false,
        text: 'Active 2 weeks ago',
      };
    }

    if (daysAgoActive >= 21 && daysAgoActive <= 27) {
      return {
        isActivelyInterviewing: false,
        text: 'Active 3 weeks ago',
      };
    }

    /*
     If the diff is NaN (no activity logs from past 45 days therefore the activeDates array is empty) or is grather than 28, show a different text.
     This should be an edge case, since the query only shows the active candidates in the last 45 days.
     */
    return {
      isActivelyInterviewing: false,
      text: 'Active a month ago',
    };
  },
};

export function serializeCandidateHighlights({
  maxBadges,
  maxHighlights,
  candidateInformation,
}: {
  maxBadges: number;
  maxHighlights?: number;
  candidateInformation: {
    englishProficiency: string | null | undefined;
    industries: Array<string | null | undefined>;
    previouslyWorked: Array<string | null | undefined>;
    skilledInMultipleLanguages: boolean | undefined;
    csDegree: boolean | undefined;
    hasBuiltProductsWith: string | null | undefined;
    yearsTechLeadExperience: number | null | undefined;
    yearsPeopleLeadershipExperience: number | null | undefined;
    mutualInterest: JobCandidateProfileQuery['candidate'][number]['candidate_jobs'];
    topCompanyExperience: boolean | undefined | null;
    startupCompanyExperience: boolean | undefined;
    referredCandidate: boolean | undefined;
    inDemand: { interviewStage: number; clientPortalAdvanceStage: number };
    activeMessage: {
      candidateCurationWorkflows: NonNullable_BrowseCandidate_Candidate['candidate_curation_workflows'];
      candidateStageLogs: NonNullable_BrowseCandidate_Candidate['candidate_stage_logs'];
    };
    previousHire: boolean | null;
  };
}) {
  const inDemand = serializeBadges.inDemand(candidateInformation.inDemand);

  return {
    badges: [
      // Currently ordered in rank of priority top-low
      serializeBadges.mutualInterest(candidateInformation.mutualInterest),
      serializeBadges.topCompany(candidateInformation.topCompanyExperience),
      serializeBadges.startupCompany(candidateInformation.startupCompanyExperience),
      serializeBadges.previousHire(candidateInformation.previousHire),
      serializeBadges.referredCandidate(candidateInformation.referredCandidate),
      inDemand,
      serializeBadges.builtProduct(!!candidateInformation.hasBuiltProductsWith),
    ]
      .filter((badge) => !!badge)
      .slice(0, maxBadges),
    highlights: [
      // Currently ordered in rank of priority top-low
      serializeHighlights.peopleLeadershipExperience(
        candidateInformation.yearsPeopleLeadershipExperience,
      ),
      serializeHighlights.techLeadExperience(candidateInformation.yearsTechLeadExperience),
      serializeHighlights.builtProduct(candidateInformation.hasBuiltProductsWith),
      serializeHighlights.computerScienceDegree(candidateInformation.csDegree),
      serializeHighlights.multipleLanguagesSkills(candidateInformation.skilledInMultipleLanguages),
      serializeHighlights.previousWorks(
        candidateInformation.previouslyWorked[0],
        candidateInformation.previouslyWorked[1],
      ),
      serializeHighlights.industryExperience(
        candidateInformation.industries[0],
        candidateInformation.industries[1],
      ),
      serializeHighlights.englishProficiency(candidateInformation.englishProficiency),
      serializeHighlights.previousHire(candidateInformation.previousHire),
    ]
      .filter((highlight) => !!highlight)
      .slice(0, maxHighlights),
    activeMessage: serializeHighlights.activeMessage({
      interviewStageCount: inDemand
        ? candidateInformation.inDemand.interviewStage +
          candidateInformation.inDemand.clientPortalAdvanceStage
        : null,
      candidateActivityInfo: candidateInformation.activeMessage,
    }),
  };
}

export const employmentTypeMap: Nullable<Record<Employment_Type_Choices_Enum, string>> = {
  [Employment_Type_Choices_Enum.Contract]: 'Contractor',
  [Employment_Type_Choices_Enum.FullTime]: 'Full-time',
  [Employment_Type_Choices_Enum.FullTimeAndContract]: 'Full-time or Contractor',
};
