import {
  toFriendlyRolesNames,
  toFriendlyDegree,
  toFriendlyYearsOfExperienceRange,
  formatAsCurrency,
  toFriendlyBoolean,
} from 'global/utils';
import { Employment_Type_Choices_Enum } from 'global/types';
import { employmentTypeDisplayNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type { SelectJobQuery } from './data';
import { contactLengthsDisplayNames } from '../create-edit-job/CreateEditJob.serializer';

export function serializeJob(data?: SelectJobQuery) {
  const job = data?.job[0];
  if (!data || !job) return {};

  let salaryRange = '';
  if (
    job.employment_type === Employment_Type_Choices_Enum.FullTime &&
    (job.min_salary || job.max_salary)
  ) {
    salaryRange = `${job.min_salary ? formatAsCurrency(job.min_salary, 'USD') : '-'} to ${
      job.max_salary ? formatAsCurrency(job.max_salary, 'USD') : '-'
    }`;
  } else if (job.max_contract_rate || job.min_contract_rate) {
    salaryRange = `${
      job.min_contract_rate ? formatAsCurrency(job.min_contract_rate, 'USD') : '-'
    } to ${job.max_contract_rate ? formatAsCurrency(job.max_contract_rate, 'USD') : '-'}`;
  }

  return {
    jobTitle: job.title,
    numberPositions: job.job_positions_aggregate?.aggregate?.count,
    jobType: job.role_type ? toFriendlyRolesNames(job.role_type) : '',
    locations: job.job_acceptable_locations.map(({ location: { value } }) => value),
    useTerminalRange:
      // * Since we don't have a field to specify the `useTerminalRange` value, we use both values in 0, to infer it
      job.employment_type === Employment_Type_Choices_Enum.Contract
        ? job.min_contract_rate === 0 && job.max_contract_rate === 0
        : job.min_salary === 0 && job.max_salary === 0,
    contractLength: job.contract_length ? contactLengthsDisplayNames[job.contract_length] : '',
    employmentType: job.employment_type ? employmentTypeDisplayNames[job.employment_type] : '',
    salaryRange,
    offeringBonus: toFriendlyBoolean(job.offering_bonus),
    offeringEquity: toFriendlyBoolean(job.offering_equity),
    level: job.level,
    techStack: job.job_tech_stack.map(({ skill: { name } }) => name),
    yearsExperience: job.min_years_of_experience
      ? toFriendlyYearsOfExperienceRange(job.min_years_of_experience)
      : '',
    requiredSkills: job.job_required_skills.map(({ skill: { name } }) => name),
    educationRequired: job.education_requirement ? toFriendlyDegree(job.education_requirement) : '',
    niceToHaveSkills: job.job_nice_to_have_skills.map(({ skill: { name } }) => name),
    aboutRole: job.about,
    whatYouDo: job.what_youll_do,
    whatYouBring: job.what_youll_bring,
    hiringManager: job.hiring_manager?.email
      ? `${job.hiring_manager?.name || ''} (${job.hiring_manager.email})`
      : '',
    additionalNotes: job.notes,
    status: job.status,
  };
}
