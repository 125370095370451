import { useHistory } from 'react-router-dom';
import { useDisclosure, nativeToastHook as useToast } from '@terminal/design-system';
import moment from 'moment';
import { useQuery } from 'global/utils';
import {
  useSharedCandidates,
  useCustomerAuthorizedUserSession,
  useSavedCandidates,
} from 'talent-hub/utils';
import type { SelectSharedCandidatesQuery, SelectSharedCandidatesQueryVariables } from './data';
import { SelectSharedCandidates } from './data';
import { serializeSharedCandidates } from './SharedCandidates.serializer';
import type {
  SelectSavedCandidatesQuery,
  SelectSavedCandidatesQueryVariables,
} from '../saved-candidates/data';
import { SelectSavedCandidates } from '../saved-candidates/data';
import { serializeToInsertSharedCandidate } from '../explore-candidates/ExploreCandidates.serializer';

const candidateSearchDateRange = moment().subtract(45, 'days').utc().format('YYYY-MM-DD');

export function useSharedCandidatesController() {
  const history = useHistory();
  const { user, isClientProspect, viewingOrganization } = useCustomerAuthorizedUserSession();
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const { loading, data, refetch } = useQuery<
    SelectSharedCandidatesQuery,
    SelectSharedCandidatesQueryVariables
  >(SelectSharedCandidates, {
    variables: {
      user_id: user?.id || 0,
      updatedAfter: candidateSearchDateRange,
      organization: viewingOrganization.ID,
      isClientProspect,
    },
  });

  const { refetch: refetchSavedCandidate } = useQuery<
    SelectSavedCandidatesQuery,
    SelectSavedCandidatesQueryVariables
  >(SelectSavedCandidates, {
    variables: {
      user_id: user?.id || 0,
      updatedAfter: candidateSearchDateRange,
      organization: viewingOrganization.ID,
      isClientProspect,
    },
  });

  const { candidates } = serializeSharedCandidates(data);

  const {
    insertSavedCandidate,
    deleteSavedCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    refetchSelectFavoriteCandidatesCounts,
  } = useSavedCandidates({
    insertSavedCandidate_onCompletedHandler: () => {
      refetch();
      refetchSavedCandidate();
      refetchSelectFavoriteCandidatesCounts();
    },
    deleteSavedCandidate_onCompletedHandler: () => {
      refetch();
      refetchSavedCandidate();
      refetchSelectFavoriteCandidatesCounts();
    },
  });

  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  const { teamMembers, insertSharedCandidate } = useSharedCandidates({});

  const {
    isOpen: isShareWithModalOpen,
    onClose: onCloseShareWithModal,
    onOpen: onOpenShareWithModal,
  } = useDisclosure();

  const handleOnInsertSharedCandidate = (candidateId: number, teamMembersIds: string[]) => {
    insertSharedCandidate({
      variables: {
        ...serializeToInsertSharedCandidate(
          teamMembersIds,
          candidateId,
          user?.id || 0,
          viewingOrganization.ID,
        ),
      },
      onCompleted: () => {
        toast({
          title: 'Shared Successfully!',
          description: 'The candidate was shared successfully.',
          status: 'success',
        });
        onCloseShareWithModal();
      },
    });
  };

  return {
    onCloseCandidateProfileClick: () => history.push(`/explore-candidates/shared-candidates`),
    loading,
    candidates,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    handleOnRemoveSaveCandidate,
    handleOnSaveCandidate,
    handleOnInsertSharedCandidate,
    teamMembers,
    isShareWithModalOpen,
    onOpenShareWithModal,
    onCloseShareWithModal,
  };
}
