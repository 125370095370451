import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';

import { firebaseAuth } from 'global/firebaseApp';
import authContext from 'global/authContext';
import Sentry, { isSentryEnabled } from 'global/sentry';
import { omit } from 'ramda';
import type { OperationDefinitionNode } from 'graphql';

export function graphqlClient(client_name: string, determineUserRole: Function, overwrite = {}) {
  const graphqlEngineHttpLink = new HttpLink({
    uri: import.meta.env.REACT_APP_HTTP_LINK_URL,
    // Use explicit `window.fetch` so tha outgoing requests
    // are captured and deferred until the MSW Service Worker is ready.
    fetch: (...args) => fetch(...args),
  });

  const graphqlServiceHttpLink = new HttpLink({
    uri: `${import.meta.env.REACT_APP_GRAPHQL_SERVICE_URL}/graphql`,
  });

  return new ApolloClient({
    link: ApolloLink.from(
      [
        new RetryLink({
          attempts: {
            retryIf: (_error, operation) => {
              const hasMutation = operation.query.definitions
                .map((d) => (d as OperationDefinitionNode).operation)
                // @ts-ignore // TODO: Fix types
                .includes('mutation');
              return !hasMutation;
            },
          },
        }),
        setContext(async (_, context) => {
          /*
           * IMPORTANT:
           *   Add any new headers to the CORS allow list for auth and graphql service
           *   or ALL REQUESTS to those services WILL FAIL.
           */
          const { token, headers, roles } = await authContext(firebaseAuth, client_name);

          const hasuraHeaders: { [key: string]: string } = {};
          Object.assign(hasuraHeaders, headers);

          if (!token) {
            hasuraHeaders['x-hasura-role'] = 'anonymous';
          } else if (context.role) {
            hasuraHeaders['x-hasura-role'] = context.role;
          } else {
            hasuraHeaders['x-hasura-role'] = determineUserRole(roles);
          }
          // eslint-disable-next-line no-param-reassign
          context.headers = hasuraHeaders;
          return context;
        }),
        isSentryEnabled &&
          new SentryLink({
            setFingerprint: true,
            setTransaction: true,
          }),
        // @ts-ignore consistent-return
        // eslint-disable-next-line consistent-return
        onError(({ graphQLErrors, networkError, operation, forward }) => {
          // Sets the query variables and context into sentry extra field
          const { variables, getContext } = operation;
          const context = getContext();

          Sentry.setExtra('variables', JSON.stringify(variables, null, 2));
          // So that we see mostly the context thats set programatically and headers
          Sentry.setExtra('context', omit(['getCacheKey', 'cache', 'clientAwareness'], context));

          if (graphQLErrors) {
            // eslint-disable-next-line no-restricted-syntax
            for (const graphQLError of graphQLErrors) {
              const { message, locations, path } = graphQLError;
              if (message === 'Your requested role is not in allowed roles') {
                // Using foward operation to retry the operation that failed because of roles
                // From Apollo Docs:
                // > If your retried operation also results in errors, those errors are not passed to your onError link to prevent an infinite loop of operations.
                // > This means that an onError link can retry a particular operation only once.
                return new Observable((observer) => {
                  async function retryFromRolesError() {
                    // delay 500 milliseconds in case we are facying a race condition issue in the backend while setting the roles
                    await new Promise((res) => {
                      setTimeout(res, 500);
                    });
                    const { token, headers, roles } = await authContext(firebaseAuth, client_name);
                    const hasuraHeaders: { [key: string]: string } = {};
                    Object.assign(hasuraHeaders, headers);

                    if (!token) {
                      hasuraHeaders['x-hasura-role'] = 'anonymous';
                    } else if (context.role) {
                      hasuraHeaders['x-hasura-role'] = context.role;
                    } else {
                      hasuraHeaders['x-hasura-role'] = determineUserRole(roles);
                    }
                    // eslint-disable-next-line no-param-reassign
                    context.headers = hasuraHeaders;
                    operation.setContext(context);

                    const handle = forward(operation);
                    handle.subscribe({
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    });
                  }

                  retryFromRolesError();
                });
              }

              if (!message.includes('Could not verify JWT: JWTExpired')) {
                // eslint-disable-next-line no-console
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
              }
            }
          }
          // eslint-disable-next-line no-console
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
      ].filter(Boolean),
    ).split(
      (operation) => operation.getContext().service === 'service',
      graphqlServiceHttpLink,
      graphqlEngineHttpLink,
    ),
    cache: new InMemoryCache(),
    connectToDevTools: import.meta.env.NODE_ENV === 'development',
    ...overwrite,
  });
}
