import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from 'global/utils';
import { useToast } from '@terminal/design-system';
import { useLazyQuery, useMutation } from '@apollo/client';
import Sentry from 'global/sentry';

import { Job_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { BlankScreenLoading } from 'global/components';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { CreateEditJob } from './CreateEditJob';
import type {
  SelectCreateJobOptionsQuery,
  SelectCreateJobOptionsQueryVariables,
  InsertJobMutationVariables,
  InsertJobMutation,
  SelectJobQuery,
  SelectJobQueryVariables,
  UpsertDeleteJobMutation,
  GenerateJobFromAiQuery,
  GenerateJobFromAiQueryVariables,
} from './data';
import {
  UpsertDeleteJob,
  SelectJob,
  SelectCreateJobOptions,
  InsertJob,
  GenerateJobFromAI,
} from './data';
import {
  serializeCreateJobOptions,
  serializeSelectJob,
  serializeUpdateJob,
} from './CreateEditJob.serializer';

const ViewMode = {
  form: 'form',
  success: 'success',
} as const;

export function CreateEditJobController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const { jobID } = useParams<{ jobID: string }>();
  const history = useHistory();

  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const [handleGenerateJob, job_prompt] = useLazyQuery<
    GenerateJobFromAiQuery,
    GenerateJobFromAiQueryVariables
  >(GenerateJobFromAI, {
    onError: () => {
      toast({
        status: 'error',
        description: 'Something went wrong. Please try again!',
      });
    },
    context: {
      service: 'service',
    },
    fetchPolicy: 'network-only',
  });

  const [viewMode, setViewMode] = useState<'form' | 'success'>(ViewMode.form);

  const [insertJob] = useMutation(InsertJob, {
    onCompleted: (res: InsertJobMutation) => {
      switch (res.insert_job_one?.status) {
        case Job_Status_Choices_Enum.Submitted:
          return setViewMode(ViewMode.success);
        case Job_Status_Choices_Enum.Draft:
          history.push(`/role`);

          return toast({
            title: 'Draft successfully saved',
            status: 'success',
          });

        default:
          return setViewMode(ViewMode.success);
      }
    },
    onError: (mutationError) => {
      toast({
        title: 'Failed to save the Job',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
  });

  const [updateDeleteJob] = useMutation(UpsertDeleteJob, {
    onCompleted: (res?: UpsertDeleteJobMutation) => {
      switch (res?.update_job?.returning[0]?.status) {
        case Job_Status_Choices_Enum.Submitted:
          return setViewMode(ViewMode.success);
        case Job_Status_Choices_Enum.Draft:
          return toast({
            title: 'Draft successfully Updated',
            status: 'success',
          });

        default:
          return setViewMode(ViewMode.success);
      }
    },
    onError: (mutationError) => {
      toast({
        title: 'Failed to update the Job',
        description: 'Please try again',
        status: 'error',
      });
      Sentry.captureException(mutationError);
    },
  });

  const createJobOptions = useQuery<
    SelectCreateJobOptionsQuery,
    SelectCreateJobOptionsQueryVariables
  >(SelectCreateJobOptions, {
    variables: {
      organization_id: viewingOrganization.ID,
    },
  });

  const { options } = serializeCreateJobOptions(createJobOptions.data);

  const selectJob = useQuery<SelectJobQuery, SelectJobQueryVariables>(SelectJob, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_id: Number(jobID),
    },
    skip: !jobID,
  });

  const initialValues = serializeSelectJob(selectJob.data);

  const handleSubmit = (formData: InsertJobMutationVariables) => {
    if (jobID) {
      const updateJobData = serializeUpdateJob(
        formData,
        initialValues,
        options.organizationUsers,
        Number(jobID),
      );

      updateDeleteJob({ variables: updateJobData });
    } else {
      insertJob({ variables: formData });
    }
  };

  useEffect(() => {
    return () => {
      // We want to remove the cached data for the the SelectJob query
      // selectJob.client.resetStore();
      selectJob.client.cache.reset();
    };
  }, [selectJob.client]);

  // [TAL-744] Change this to a skelleton loading page
  if (selectJob.loading && jobID) {
    return <BlankScreenLoading />;
  }
  return (
    <CreateEditJob
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      options={options}
      viewMode={viewMode}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      viewingOrg={viewingOrganization.ID}
      generateJob={{
        handleGenerateJob: ({ query }: { query: string }) =>
          handleGenerateJob({ variables: { query, company_name: viewingOrganization.name } }),
        isLoading: job_prompt.loading,
        prompt: job_prompt.variables?.query || null,
      }}
    />
  );
}
