import { useEffect } from 'react';
import { firebaseAuth } from 'global/firebaseApp';
import { useToast } from '@terminal/design-system';
import { ActivityIndicator } from 'global/components';

import { signInAnonymously } from '../authMethods';
import type { AuthContextType } from '../useAuth';

export function AnonymousSignInController({
  children,
  auth,
}: {
  children: React.ReactNode;
  auth: NonNullable<AuthContextType>['state'];
}) {
  const toast = useToast({
    position: 'top',
    duration: 10000,
    status: 'error',
  });

  useEffect(() => {
    if (auth.friendlyErrorMessage) {
      toast({
        description: auth.friendlyErrorMessage,
      });
    }
  }, [auth.friendlyErrorMessage, toast]);

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
      // room for grace when auth fails
      auth.attemptCount <= 3
    ) {
      signInAnonymously({ auth: firebaseAuth, dispatch: auth.dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // To prevent anonymous sign in loop when auth fails. Chose 3 attempts to leave some
  // room for grace when auth fails
  if (auth.attemptCount >= 3) {
    throw auth.error || new Error('Auth failed 3 times while attempting anonymous sign in.');
  }

  if (!auth.isAuthenticated) {
    return <ActivityIndicator aria-label="Loading" />;
  }

  return children as React.ReactElement;
}
