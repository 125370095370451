import { Switch as ChakraSwitch, forwardRef } from '@chakra-ui/react';
import type { SwitchProps as ChakraSwitchProps } from '@chakra-ui/react';

export interface SwitchProps extends ChakraSwitchProps {
  /**
   * Switch sizes
   */
  size?: 'md' | 'sm';
}

/**
 * Primary UI component for user interaction
 */
export const Switch = forwardRef<SwitchProps, 'switch'>(({ ...rest }, ref) => {
  return <ChakraSwitch {...rest} ref={ref} />;
});

Switch.defaultProps = {
  size: 'md',
};
