import {
  Box,
  Grid,
  Heading,
  Text,
  CustomSelect,
  MultiSelectTypeahead,
  Flex,
  DeleteIcon,
  Center,
  AddIcon,
  Button,
  FormErrorMessage,
  FormControl,
  useToast,
  CheckboxGroup,
  CheckboxCard,
} from '@terminal/design-system';

import type { FormikErrors, FormikHandlers, FormikHelpers, FormikState } from 'formik';
import { FieldArray } from 'formik';
import {
  OnBoardingPage,
  LeftHeaderTitle,
  StepProgressBar,
  RightFooter,
  RightContentContainer,
  LeftQuote,
} from '../../components';
import quoteProfileImageSrc from '../../assets/customers/david-hegarty.jpeg';
import type { StepInfo } from '../../Onboarding.types';
import type { serializeOnboardingData } from '../../data';

type RolesData = {
  roleList: {
    role: string;
    yearsOfExperience: string;
    skills: {
      value: string;
      label: string;
    }[];
  }[];
};

function Role({
  options,
  values,
  setFieldValue,
  handleBlur,
  index,
  handleDeleteRole,
  errors,
  touched,
  shouldShowDeleteIcon = true,
}: {
  options: ReturnType<typeof serializeOnboardingData>['roleOptions'];
  values: {
    role: string;
    yearsOfExperience: string;
    skills: {
      value: string;
      label: string;
    }[];
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  index: number;
  handleBlur: FormikHandlers['handleBlur'];
  handleDeleteRole: () => void;
  shouldShowDeleteIcon?: boolean;
} & Pick<FormikState<RolesData['roleList'][number]>, 'errors' | 'touched'>) {
  return (
    <Grid
      bg="bg.secondary"
      gridTemplateColumns="1fr 28px"
      w="full"
      pr={3}
      pl={6}
      py={6}
      gridGap={3}
      mt={6}
    >
      <Flex width="full" flexDir="column">
        <Grid gridGap={4} gridTemplateColumns="1fr 1fr">
          <FormControl isInvalid={touched?.role && !!errors?.role}>
            <CustomSelect
              initialValue={values.role}
              name={`roleList.${index}.role`}
              fullScreen={{
                title: 'Role',
              }}
              placeholder="Select a Role"
              shouldOpenOnFocus
              options={options.roles.map(({ label }) => label)}
              noOptionsMatchedCopy="Role not found"
              formInputValue={values.role}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                  '& ul': {
                    ...(index === 1 && { 'max-height': '14rem' }),
                    ...(index === 2 && { 'max-height': '12rem' }),
                  },
                },
              }}
              onBlur={(event) => {
                handleBlur(event);

                const role = options.roles.find((currentRole) => {
                  return currentRole.label?.toLowerCase() === event.target.value?.toLowerCase();
                });

                if (!!role || !event.target.value) {
                  setFieldValue(`roleList.${index}.role`, role?.label || '');
                }
              }}
              onSelectionChange={(field, value) => setFieldValue(field, value)}
              onInputChange={(event) => setFieldValue(`roleList.${index}.role`, event.target.value)}
              showSelectIcon
            />

            <FormErrorMessage>{errors?.role}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={touched?.yearsOfExperience && !!errors?.yearsOfExperience}>
            <CustomSelect
              initialValue={values.yearsOfExperience}
              name={`roleList.${index}.yearsOfExperience`}
              fullScreen={{
                title: 'Experience',
              }}
              placeholder="Select years of experience"
              shouldOpenOnFocus
              options={options.yearsOfExperience.map(({ label }) => label)}
              noOptionsMatchedCopy="Year of experience not found"
              formInputValue={values.yearsOfExperience}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& input': {
                    bg: 'bg.primary',
                  },
                },
              }}
              onBlur={(event) => {
                handleBlur(event);

                const yearsOfExperience = options.roles.find((currentyearsOfExperience) => {
                  return (
                    currentyearsOfExperience.label?.toLowerCase() ===
                    event.target.value?.toLowerCase()
                  );
                });

                if (!!yearsOfExperience || !event.target.value) {
                  setFieldValue(
                    `roleList.${index}.yearsOfExperience`,
                    yearsOfExperience?.label || '',
                  );
                }
              }}
              onSelectionChange={(field, value) => setFieldValue(field, value)}
              onInputChange={(event) =>
                setFieldValue(`roleList.${index}.yearsOfExperience`, event.target.value)
              }
              showSelectIcon
            />
            <FormErrorMessage>{errors?.yearsOfExperience}</FormErrorMessage>
          </FormControl>
        </Grid>

        <Box mt={6}>
          <FormControl isInvalid={touched?.skills && !!errors?.skills}>
            <MultiSelectTypeahead
              noOptionsMatchedCopy="Skill, comma-separated"
              shouldOpenOnFocus
              options={options.skills}
              formInputValue={values.skills?.length}
              fullScreen={{
                title: 'Must Have Skills',
              }}
              name={`roleList.${index}.skills`}
              placeholder={values.skills?.length >= 1 ? '' : 'Search up to 3 skills'}
              containerProps={{
                display: 'flex',
                flexDirection: 'column',
                sx: {
                  '& div': {
                    bg: 'white',
                    cursor: values.skills?.length === 3 ? 'no-drop' : 'auto',
                    '& div': {
                      'min-width': '20%',
                    },
                  },
                  '& span': {
                    _first: {
                      mb: values.skills?.length === 3 ? '0.25rem' : '0',
                    },
                    cursor: 'auto',
                  },
                  '& input': {
                    _disabled: {
                      bg: 'white',
                    },
                  },
                  '& ul': {
                    ...(index === 1 && { 'max-height': '10rem' }),
                    ...(index === 2 && { 'max-height': '8rem' }),
                  },
                },
              }}
              isDisabled={values.skills?.length >= 3}
              onBlur={handleBlur}
              onSelectedItemsChange={(_name, selectedValues: { value: string; label: string }[]) =>
                setFieldValue(`roleList.${index}.skills`, selectedValues)
              }
              forceNewValues={values.skills}
            />
            {/* @ts-ignore */}
            <FormErrorMessage>{errors?.skills}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      {shouldShowDeleteIcon && (
        <Button
          m={2}
          variant="ghost"
          alignItems="flex-start"
          sx={{ _hover: { bg: 'initial' } }}
          h="fit-content"
          onClick={handleDeleteRole}
          p={0}
        >
          <DeleteIcon color="ui.error" fontSize="sm" />
        </Button>
      )}
    </Grid>
  );
}

export function RolesDropdownSelection({
  stepInfo,
  options,
  loading,
  rolesVariant,
  values,
  errors,
  touched,
  handleBlur,
  submitForm,
  resetForm,
  setFieldValue,
}: {
  stepInfo: StepInfo;
  options: ReturnType<typeof serializeOnboardingData>['roleOptions'];
  loading: boolean;
  rolesVariant?: 'single-role' | 'multiple-roles';
} & Pick<FormikState<RolesData>, 'values' | 'errors' | 'touched'> &
  Pick<FormikHandlers, 'handleBlur'> &
  Pick<FormikHelpers<RolesData>, 'submitForm' | 'resetForm' | 'setFieldValue'>) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  return (
    <OnBoardingPage
      leftContent={
        <Box ml={[0, 0, 10, 0, 0]}>
          <LeftHeaderTitle text="Our Vetted Candidates <strong>Are Waiting</strong>" />
          <Box mt={10}>
            <LeftQuote
              name="David Hegarty"
              description="Founder & CEO, Playbook"
              imageSrc={quoteProfileImageSrc}
            >
              We couldn&apos;t have built anything without the engineering hires from Terminal. Had
              we not partnered with Terminal, I don&apos;t actually know how we would have gotten
              started. It was extremely hard to hire engineers.
            </LeftQuote>
          </Box>
        </Box>
      }
      rightContent={
        <RightContentContainer mx={[0, 0, '3rem', '5rem', '7.5rem']} maxW="35.125rem">
          <StepProgressBar stepInfo={stepInfo} />
          <Heading variant="heading-2" textColor="text.primary">
            What is the most important role you are hiring?
          </Heading>

          <Text mt={2} color="accent.darkest">
            {rolesVariant === 'multiple-roles'
              ? 'Add up to 3 roles if you have multiple needs'
              : 'You can change this later'}
          </Text>
          <Box w={['full', '33rem', '35.125rem']}>
            <FieldArray name="roleList">
              {({ remove, push }) => (
                <>
                  {values.roleList.map((value, index) => (
                    <Role
                      // @ts-ignore
                      key={value}
                      values={value}
                      options={options}
                      setFieldValue={setFieldValue}
                      index={index}
                      handleBlur={handleBlur}
                      touched={touched.roleList?.[index] || {}}
                      errors={
                        errors.roleList?.[index] as FormikErrors<RolesData['roleList'][number]>
                      }
                      handleDeleteRole={() => {
                        if (values.roleList.length > 1) {
                          remove(index);
                        } else {
                          resetForm({
                            errors: {
                              roleList: 'Empty form',
                            },
                          });
                        }
                      }}
                      shouldShowDeleteIcon={rolesVariant === 'multiple-roles'}
                    />
                  ))}
                  {values.roleList.length <= 2 && rolesVariant === 'multiple-roles' && (
                    <Center mt={6}>
                      <Button
                        variant="ghost"
                        colorScheme="primary"
                        h="fit-content"
                        onClick={() => {
                          push({ role: '', skills: [], yearsOfExperience: '' });
                        }}
                        px={2}
                        py={1}
                        leftIcon={<AddIcon fontSize="lg" />}
                      >
                        Add Another Role
                      </Button>
                    </Center>
                  )}
                </>
              )}
            </FieldArray>
          </Box>

          <RightFooter
            onNextStep={() => {
              submitForm();
              if (errors.roleList && !!errors.roleList?.length) {
                toast({
                  status: 'error',
                  description: (
                    <Flex>
                      <Text variant="label">Missing field!</Text>
                      <Text variant="body" ml={2}>
                        Please include all necessary fields.
                      </Text>
                    </Flex>
                  ),
                });
              }
            }}
            isLoading={loading}
            disabled={loading}
          />
        </RightContentContainer>
      }
    />
  );
}

export function RolesCheckboxSelection({
  stepInfo,
  options,
  loading,
  values,
  errors,
  submitForm,
  setFieldValue,
}: React.ComponentProps<typeof RolesDropdownSelection>) {
  return (
    <OnBoardingPage
      leftContent={
        <Box ml={[0, 0, 10, 0, 0]}>
          <LeftHeaderTitle text="Our Vetted Candidates <strong>Are Waiting</strong>" />
          <Box mt={10}>
            <LeftQuote
              name="David Hegarty"
              description="Founder & CEO, Playbook"
              imageSrc={quoteProfileImageSrc}
            >
              We couldn&apos;t have built anything without the engineering hires from Terminal. Had
              we not partnered with Terminal, I don&apos;t actually know how we would have gotten
              started. It was extremely hard to hire engineers.
            </LeftQuote>
          </Box>
        </Box>
      }
      rightContent={
        <RightContentContainer mx={[0, 0, '3rem', '3rem', '5rem']} maxW="40rem">
          <StepProgressBar stepInfo={stepInfo} />
          <Heading variant="heading-2" textColor="text.primary">
            What is the most important role you are hiring?
          </Heading>

          {/* @ts-ignore */}
          <FormControl isInvalid={!!errors?.roleList?.[0].role}>
            <FormErrorMessage ml={4} w="full" display="inline-block" textAlign="center">
              {/* @ts-ignore */}
              {errors?.roleList?.[0].role}
            </FormErrorMessage>
            <CheckboxGroup>
              <Flex w="full" flexWrap="wrap" justifyContent="center" gap={4} mt={6}>
                {options.roles.map(({ value, label }) => {
                  const isSelected = values.roleList.some(({ role }) => role === label);

                  return (
                    <CheckboxCard
                      role="checkbox"
                      aria-checked={isSelected}
                      aria-labelledby="candidate-role"
                      onChange={() => {
                        setFieldValue(`roleList.0.role`, label);
                      }}
                      isChecked={isSelected}
                      key={`insider-role-${value}`}
                      h={20}
                      w={['8.75rem', '8.75rem', '8.875rem']}
                      sx={{
                        '.chakra-button': {
                          h: 20,
                          w: 'full',
                        },
                        '.checkbox-card__icon-container': {
                          px: [3, 3, 2],
                        },
                        svg: {
                          color: 'ui.success',
                        },
                      }}
                    >
                      <Text variant="body" fontWeight={isSelected ? 'bold' : 'regular'}>
                        {label}
                      </Text>
                    </CheckboxCard>
                  );
                })}
              </Flex>
            </CheckboxGroup>
          </FormControl>

          <Text variant="label" mt={6}>
            How many years of experience should candidates have?
          </Text>
          {/* @ts-ignore */}
          <FormControl isInvalid={!!errors?.roleList?.[0].yearsOfExperience}>
            <FormErrorMessage ml={4} w="full" display="inline-block" textAlign="center">
              {/* @ts-ignore */}
              {errors?.roleList?.[0].yearsOfExperience}
            </FormErrorMessage>
            <CheckboxGroup>
              <Flex w="full" flexWrap="wrap" justifyContent="center" gap={4} mt={4}>
                {options.yearsOfExperience.map(({ value, label }) => {
                  const isSelected = values.roleList.some(
                    ({ yearsOfExperience }) => yearsOfExperience === label,
                  );

                  return (
                    <CheckboxCard
                      role="checkbox"
                      aria-checked={isSelected}
                      aria-labelledby="candidate-role"
                      onChange={() => setFieldValue(`roleList.0.yearsOfExperience`, label)}
                      isChecked={isSelected}
                      key={`insider-role-${value}`}
                      w={['8.75rem', '8.75rem', '8.875rem']}
                      h={20}
                      sx={{
                        '.chakra-button': {
                          h: 20,
                        },
                        svg: {
                          color: 'ui.success',
                        },
                      }}
                    >
                      <Text variant="body" fontWeight={isSelected ? 'bold' : 'regular'}>
                        {label}
                      </Text>
                    </CheckboxCard>
                  );
                })}
              </Flex>
            </CheckboxGroup>
          </FormControl>

          <RightFooter
            onNextStep={() => {
              submitForm();
            }}
            isLoading={loading}
            disabled={loading}
          />
        </RightContentContainer>
      }
    />
  );
}

export const Roles = {
  Variation: {
    CheckboxSelection: RolesCheckboxSelection,
    DropdownSelection: RolesDropdownSelection,
  },
};
