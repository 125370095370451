import moment from 'moment';

/**
 *
 * @param data yyyy-MM-DD ex 2021-02-10
 * @returns ${data}T${time}.819849+00:00
 */
export const toHasuraDate = (data: string) => `${data}T17:13:07.819849+00:00`;

/**
 *
 * @param data yyyy-MM-DD ex 2021-02-10
 * @param time hh:mm:ss ex 17:13:03
 * @returns ${data}T${time}.819849+00:00
 */
export const toHasuraDateTime = (data: string, time: string) => `${data}T${time}.819849+00:00`;

export const createHasuraDateByDaysOld = (numberOfDays: number) =>
  toHasuraDate(moment().subtract(numberOfDays, 'day').format('yyyy-MM-DD'));
export const createHasuraDateByDaysToCome = (numberOfDays: number) =>
  toHasuraDate(moment().add(numberOfDays, 'day').format('yyyy-MM-DD'));

/**
 *
 * @param daysAgo number[] ex [17, 20, 30]
 * @returns {Object} number: yyyy-MM-DDThh:mm:ss.819849+00:00
 */
export function createDaysAgoDates<T extends number>(daysAgo: T[]): Record<T, string> {
  return daysAgo.reduce((dates, currDay) => {
    return { ...dates, [currDay]: createHasuraDateByDaysOld(currDay) };
  }, {} as Record<T, string>);
}
