import type { SelectSuggestedOrganizationUsersQuery } from './data';

export function serializeSuggestedOrganizationUsers_toFeedback(
  data?: SelectSuggestedOrganizationUsersQuery,
) {
  if (!data) return [];

  return data.users.reduce((usersFiltered: { label: string; value: string }[], user) => {
    if (
      data.interview_feedback_request.findIndex(
        ({ interviewer_email }) => interviewer_email === user.email,
      ) !== -1
    )
      return usersFiltered;
    return [...usersFiltered, { label: user.name || user.email, value: user.email }];
  }, []);
}

export function serializeSuggestedOrganizationUsers_toRoleFeedback(
  data?: SelectSuggestedOrganizationUsersQuery,
) {
  if (!data) return [];

  return data.users.reduce((usersFiltered: { label: string; value: string }[], user) => {
    if (
      data.job_interview_feedback_request.findIndex(
        ({ interviewer_email }) => interviewer_email === user.email,
      ) !== -1
    )
      return usersFiltered;
    return [...usersFiltered, { label: user.name || user.email, value: user.email }];
  }, []);
}
