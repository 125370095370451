import { Link } from 'react-router-dom';
import {
  Text,
  Flex,
  Heading,
  Box,
  Button,
  Badge,
  LogoutIcon,
  DirectoryIcon,
  WarningCircleIcon,
  Menu,
  MenuItem,
  MenuButton,
  Avatar,
  MenuList,
  RefreshIcon,
  EditIcon,
  DashboardIcon,
} from '@terminal/design-system';
import { useIsViewPortDesktop } from 'global/utils';

import { firebaseAuth } from 'global/firebaseApp';
import { signOut } from 'global/auth';
import { useTalentHubFlags } from 'talent-hub/utils/flags';

// TODO: break this component by roles
export function TopBarHeader({
  orgName,
  superTitle,
  title,
  subHeader,
  userName,
  userFirstName,
  badgeCopy,
  isRecruiter,
  isClientProspect,
  isClientReviewer,
  isCallScheduled = false,
  onUpgradeAccountClick,
}: {
  orgName?: string;
  superTitle?: string | React.ReactNode;
  title?: string | React.ReactNode;
  subHeader?: React.ReactNode;
  userName: string;
  userFirstName: string;
  badgeCopy?: string;
  isRecruiter?: boolean;
  isClientProspect?: boolean;
  isClientReviewer?: boolean;
  isCallScheduled?: boolean;
  onUpgradeAccountClick?: () => void;
}) {
  const featureFlags = useTalentHubFlags();
  const isDesktop = useIsViewPortDesktop();

  return (
    <Flex
      as="nav"
      bg="bg.primary"
      borderBottom="1px solid"
      borderColor="ui.secondary"
      role="navigation"
      direction="column"
      // * this is related with the padding-top of the pageLayout - https://github.com/terminalinc/product/blob/master/web-customer/src/talent-hub/components/PageLayout/PageLayout.tsx
      height={subHeader ? 22 : 20}
    >
      <Flex
        px={[4, 4, 6]}
        py={4}
        alignItems="center"
        justifyContent="space-between"
        h="full"
        sx={{
          '.chakra-menu__menu-list': {
            borderWidth: '1px',
            borderColor: 'ui.secondary',
          },
        }}
      >
        <Box>
          {superTitle && (
            <Text variant="caption" textTransform="uppercase">
              {superTitle}
            </Text>
          )}
          {typeof title === 'string' ? (
            <Heading variant="heading-2" as="h1">
              {title || <>&nbsp;</>}
            </Heading>
          ) : (
            title
          )}
        </Box>
        {isDesktop && (
          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              variant="outline"
              colorScheme="primary"
              px={4}
              py={2}
              borderRadius="base"
              borderColor="brand.lighter"
              minW="auto"
            >
              <Flex alignItems="center">
                {badgeCopy && (
                  <Badge variant="subtle" colorScheme="warning" size="sm" mr={1}>
                    {badgeCopy}
                  </Badge>
                )}
                {orgName && (
                  <Text variant="caption" fontWeight="bold">
                    {orgName}
                  </Text>
                )}
                <Avatar
                  m={0}
                  ml={3}
                  className="notranslate"
                  name={userName}
                  color="ui.darkest.info"
                  bg="brand.lighter"
                />
              </Flex>
            </MenuButton>
            <MenuList>
              <Flex
                p={6}
                pr={14}
                flexDir="row"
                alignItems="center"
                justifyContent="space-between"
                w="full"
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor="ui.secondary"
              >
                <Flex flexDir="row" alignItems="center">
                  <Avatar
                    size="xl"
                    className="notranslate"
                    name={userName}
                    color="ui.darkest.info"
                    bg="brand.lighter"
                  />
                  <Flex flexDir="column" ml={3}>
                    <Heading variant="heading-3">{userFirstName}</Heading>
                    <Text variant="caption" fontWeight="bold">
                      {orgName}
                    </Text>
                  </Flex>
                </Flex>
                {badgeCopy && (
                  <Badge variant="subtle" colorScheme="warning" size="sm" ml={3}>
                    {badgeCopy}
                  </Badge>
                )}
              </Flex>
              {isClientProspect && (
                <MenuItem
                  icon={<WarningCircleIcon fontSize="lg" />}
                  bgColor="ui.lighter.warning"
                  onClick={onUpgradeAccountClick}
                  pointerEvents={isCallScheduled ? 'none' : 'auto'}
                >
                  <Text variant="caption">
                    {isCallScheduled ? 'Meeting Scheduled' : 'Upgrade Your Account'}
                  </Text>
                </MenuItem>
              )}
              {isRecruiter && !isClientProspect && (
                <MenuItem to="/change-organization" icon={<RefreshIcon fontSize="lg" />} as={Link}>
                  <Text variant="caption">Change Organization</Text>
                </MenuItem>
              )}
              {featureFlags.showRetoolEditOrganization && isRecruiter && (
                <MenuItem to="/edit-organization" icon={<EditIcon fontSize="lg" />} as={Link}>
                  <Text variant="caption">Edit Organization</Text>
                </MenuItem>
              )}
              {featureFlags.showInternalDashboard && isRecruiter && (
                <MenuItem
                  to="/internal/customer-activity-dashboard"
                  icon={<DashboardIcon fontSize="lg" />}
                  as={Link}
                >
                  <Text variant="caption">Customer Activity Dashboard</Text>
                </MenuItem>
              )}
              {!isClientReviewer && !isClientProspect && (
                <MenuItem to="/directory" icon={<DirectoryIcon fontSize="lg" />} as={Link}>
                  <Text variant="caption">Terminal Directory</Text>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => signOut({ auth: firebaseAuth })}
                bgColor="bg.secondary"
                icon={<LogoutIcon fontSize="lg" />}
                sx={{
                  '& svg path': {
                    strokeWidth: 2,
                  },
                }}
              >
                <Text variant="caption">Sign Out</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      {subHeader}
    </Flex>
  );
}
