import moment from 'moment';
import { groupBy, uniqBy } from 'ramda';

import {
  capitalizeEachWord,
  createCandidatesStageGraph,
  hasCandidateHitStage,
  isMatchingStatus,
  toFriendlyCandidateStatusTitleOrRaw,
} from 'talent-hub/utils';
import type { AllStagesAllBuckets } from 'talent-hub/utils';
import { CandidateStageStatusGroup } from 'talent-hub/constants';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';

import type { WeekSummariesProps } from '../../data';
import { transformToCandidatesWithStageInfo } from '../../utils';
import type {
  RoleApplicantWorkflow,
  ProfileInfo,
  CandidateWithStagingInfo,
} from '../../Role.types';

type ApplicantWorkflowStatus = RoleApplicantWorkflow['workflow_statuses'][number];

function toBucketSummaries(bucketCandidates: CandidateWithStagingInfo[]): {
  total: number;
  candidateNames: string;
} {
  const uniqueBucketCandidates = uniqBy(
    (candidate) => candidate.profileInfo.name,
    bucketCandidates,
  );

  return {
    total: uniqueBucketCandidates.length,
    candidateNames: uniqueBucketCandidates
      .map((candidate) => candidate.profileInfo.name)
      .sort()
      .join(', '),
  };
}
/**
 *
 * @param allTimeTotalSubmitted  Total number of candidates ever submitted
 * @param allTimeTotalSubmissionsAccepted  Total number of candidates have ever passed submission
 */
export function serializeForWeeklySummaries({
  allNonScreeningCandidates,
  candidates,
}: {
  allNonScreeningCandidates: ProfileInfo[];
  candidates: RoleApplicantWorkflow[];
}) {
  type CandidateID = NonNullable<RoleApplicantWorkflow['icims_person']>['profile_id'];
  type StatusWithApplicantWorkflow = [ApplicantWorkflowStatus, CandidateID];

  const nonScreeningCandidatesIDs = new Set<number>(
    allNonScreeningCandidates.map((candidate) => candidate.id || -1),
  );

  // Maps all candidates statuses, one to one, to their candidate IDs
  const statusCandidatesIDsMap: StatusWithApplicantWorkflow[] = candidates
    .filter(
      (candidate) =>
        candidate.icims_person?.profile_id &&
        nonScreeningCandidatesIDs.has(candidate.icims_person.profile_id || -1),
    )
    .flatMap((candidate) => {
      return candidate.workflow_statuses
        .filter((workflowStatus) => workflowStatus.status != null && candidate.icims_person != null)
        .map(
          (workflowStatus) =>
            [workflowStatus, candidate.icims_person?.profile_id] as StatusWithApplicantWorkflow,
        );
    });

  // Creates a dictionary of ID => Candidate, to utilize it for high performance look up later on
  const candidatesDictionary = candidates.reduce<{ [key: string]: RoleApplicantWorkflow }>(
    (acc, candidate) => {
      return candidate.icims_person != null
        ? {
            ...acc,
            [candidate.icims_person.profile_id.toString()]: candidate,
          }
        : acc;
    },
    {},
  );

  // Puts candidates into buckets that grouped by year-week
  const groupsByYearWeek = groupBy(
    ([workflowStatus]: StatusWithApplicantWorkflow) =>
      `${moment(workflowStatus.date).weekYear()}-${moment(workflowStatus.date).week()}`,
  )(statusCandidatesIDsMap);

  // @ts-ignore // TODO: [TP-1919] Fix this type
  const week_candidateActivityReviews_weekGraphList: [
    string,
    WeekSummariesProps[number]['candidatesActivityReviews'],
    AllStagesAllBuckets<CandidateWithStagingInfo>,
  ][] = Object.entries(groupsByYearWeek)
    .sort(([yearWeekA], [yearWeekB]) => {
      const [yearA, weekA] = yearWeekA.split('-');
      const [yearB, weekB] = yearWeekB.split('-');
      // Need to sort by the number of total weeks ever, so years need to be weighted
      // by at least 53 (there are somewhere between 52 and 53 weeks a year)
      return Number(yearB) === Number(yearA)
        ? Number(weekB) - Number(weekA)
        : Number(yearB) - Number(yearA);
    })
    .map(([yearWeek, statusesMapOneToOneToCandidates]) => {
      // Reduces [status, candidatesID] mapping into candidateID=>[weeks statuses] dictionary
      const candidateIDToWeeksStatuses = statusesMapOneToOneToCandidates?.reduce<{
        [key: number]: ApplicantWorkflowStatus[];
      }>((acc, [candidateStatus, candidateID]) => {
        acc[candidateID] =
          acc[candidateID] != null
            ? [...(acc[candidateID] || []), candidateStatus]
            : [candidateStatus];
        return acc;
      }, {});

      // @ts-ignore // TODO: [TP-1919] Fix this type
      const candidatesWithWeekStatuses = Object.entries(candidateIDToWeeksStatuses).map(
        ([candidateID, candidateWeekStatuses]) => ({
          ...candidatesDictionary[candidateID.toString()],
          workflow_statuses: candidateWeekStatuses,
        }),
      ) as RoleApplicantWorkflow[];

      const stagedCandidates = transformToCandidatesWithStageInfo(
        candidatesWithWeekStatuses.filter(
          (postingApplicantWorkflow) =>
            !hasCandidateHitStage(
              'Ignored',
              postingApplicantWorkflow.workflow_statuses.map(
                workflowStatuses_ToCandidateWorkflowStatus,
              ),
            ),
        ),
      );

      const candidatesActivityReviews = statusesMapOneToOneToCandidates
        ?.filter(
          ([status]) =>
            !isMatchingStatus(CandidateStageStatusGroup.Screening)({
              title: status.status?.title || '',
              date: status.date,
            }),
        )
        .map(([status, candidateID]) => {
          const candidate = candidatesDictionary[candidateID.toString()] as RoleApplicantWorkflow;

          return {
            id: candidate.icims_person?.profile_id as number,
            name: `${capitalizeEachWord(
              candidate.icims_person?.candidate?.firstname || candidate.icims_person?.firstname,
            )} ${capitalizeEachWord(
              candidate.icims_person?.candidate?.lastname || candidate.icims_person?.lastname,
            )}`,
            activity: '',
            status,
          };
        })
        .sort((a, b) => {
          if (a.name === b.name) {
            return new Date(a.status.date).getTime() - new Date(b.status.date).getTime();
          }
          return a.name > b.name ? 1 : -1;
        })
        .map((candidateActivity) => ({
          ...candidateActivity,
          status: {
            date: moment(candidateActivity.status.date).format('dddd, MMM D'),
            title: toFriendlyCandidateStatusTitleOrRaw(
              candidateActivity.status.status?.title || '',
            ),
          },
        }));

      return [yearWeek, candidatesActivityReviews, createCandidatesStageGraph(stagedCandidates)];
    });

  let totalSubmissions = 0;
  let totalSubmissionsAccepted = 0;
  let totalRejections = 0;

  const upToThisWeekSubmissionsIDs = new Set<number>();
  const upToThisWeekSubmissionsAcceptedIDs = new Set<number>();

  // The array mapping is separated because it needed to access the allWeeks to determine list
  // of candidates who were skipped in a given week but were not submitted previously
  return week_candidateActivityReviews_weekGraphList
    .reverse()
    .map(([yearWeek, candidatesActivityReviews, weekGraph]) => {
      const [yearNumber, weekNumber] = yearWeek.split('-');
      const weekDate = moment().week(Number(weekNumber)).weekYear(Number(yearNumber));

      const dateRange = `${weekDate.startOf('week').format('dddd, MMM D')} - ${weekDate
        .endOf('week')
        .format('dddd, MMM D, YYYY')}`;

      // This logic does not account for candidate restart process
      // We check for everyone ever submitted and then filter them by removing the ones who
      // were considered as new submission in previous weeks. We do that because, when a candidate
      // initially skips submission, for their first week we want to display them in both new
      // submissions and new submissions accepted. Same goes with if they were rejected in their
      // first week. We'd then display them in both new rejection and new submission.
      const submittedThisWeekAndNotSubmittedPreviously = [
        ...weekGraph.submission.inProgress.all,
        ...weekGraph.submission.skipped.all,
        ...weekGraph.submission.completed.all,
        ...weekGraph.submission.rejectedAt.all,
      ].filter(
        (submittedCandidate) =>
          !upToThisWeekSubmissionsIDs.has(submittedCandidate.profileInfo.id || -1),
      );

      submittedThisWeekAndNotSubmittedPreviously.forEach((candidate) => {
        upToThisWeekSubmissionsIDs.add(candidate.profileInfo.id || -1);
      });

      // Number of candidates submitted this week
      const weekSubmissionsTotal = toBucketSummaries(submittedThisWeekAndNotSubmittedPreviously);

      const acceptedThisWeekAndNotAcceptedPreviously = weekGraph.submission.completed.all.filter(
        (submittedCandidate) =>
          !upToThisWeekSubmissionsAcceptedIDs.has(submittedCandidate.profileInfo.id || -1),
      );

      acceptedThisWeekAndNotAcceptedPreviously.forEach((candidate) => {
        upToThisWeekSubmissionsAcceptedIDs.add(candidate.profileInfo.id || -1);
      });

      // Number of candidates who were accepted this week
      const weekSubmissionsAcceptedTotal = toBucketSummaries(
        acceptedThisWeekAndNotAcceptedPreviously,
      );

      const weekRejectionsTotal = toBucketSummaries([
        // Needed to add weekGraph.screening.rejectedAt.all because when in candidates week's statuses there is only a rejection
        // and no statuses from submission, interview, offer or hire stages, the statuses graph logic shows the user as
        // being being rejected in the screening stage
        ...weekGraph.screening.rejectedAt.all,
        ...weekGraph.submission.rejectedAt.all,
        ...weekGraph.interview.rejectedAt.all,
        ...weekGraph.offer.rejectedAt.all,
      ]);

      const upToThisWeekTotalSubmissions = totalSubmissions + weekSubmissionsTotal.total;
      const upToThisWeekTotalSubmissionsAccepted =
        totalSubmissionsAccepted + weekSubmissionsAcceptedTotal.total;
      const upToThisWeekTotalRejection = totalRejections + weekRejectionsTotal.total;
      totalSubmissions += weekSubmissionsTotal.total;
      totalSubmissionsAccepted += weekSubmissionsAcceptedTotal.total;
      totalRejections += weekRejectionsTotal.total;

      return {
        dateRange,
        bucketSummaries: [
          {
            title: 'New Submissions',
            week: weekSubmissionsTotal,
            allTime: upToThisWeekTotalSubmissions,
          },
          {
            title: 'New Submissions Accepted',
            week: weekSubmissionsAcceptedTotal,
            allTime: upToThisWeekTotalSubmissionsAccepted,
          },
          {
            title: 'Newly Inactive',
            week: weekRejectionsTotal,
            allTime: upToThisWeekTotalRejection,
          },
        ],
        candidatesActivityReviews,
      };
    })
    .reverse();
}
