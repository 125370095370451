import { useState } from 'react';

import * as events from 'global/events';
import { Center, Heading, Text, Spinner } from '@terminal/design-system';
import { ICIMS, AsyncStatus } from 'talent-hub/constants';

import { ViewType } from './constants';
import { AdvanceForm } from './AdvanceForm';
import { RejectForm } from './RejectForm';
import { PendingForm } from './PendingForm';

export function ModalCandidateSubmissionReview({
  candidateName,
  reviewerName,
  onSubmit,
  status,
}: {
  onSubmit: (data: any) => void;
  candidateName: string;
  reviewerName: string;
  status: AsyncStatus;
}) {
  const [view, setView] = useState(ViewType.Pending);
  const [saving] = useState(false);

  const submissionAction = (newView: string | null, event: string) => {
    // Update the view
    if (newView != null) {
      setView(newView);
    }
    // Track the event
    events.track(event);
  };

  if (status === AsyncStatus.Rejected) {
    return (
      <>
        <Heading variant="heading-3">Submission Failed!</Heading>
        <Text>
          Sorry! There was a technical problem with the submission. Please refresh the page and try
          again.
        </Text>
      </>
    );
  }

  if (status === AsyncStatus.InProgress) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (status === AsyncStatus.Resolved) {
    return (
      <>
        <Heading variant="heading-3">Thank you.</Heading>
        <Text>Your feedback has been submitted to Terminal.</Text>
      </>
    );
  }

  if (view === ViewType.Pending) {
    return (
      <PendingForm
        onAdvanceClick={() =>
          submissionAction(ViewType.Advance, events.name.startedSubmissionAdvance)
        }
        onRejectClick={() => submissionAction(ViewType.Reject, events.name.startedSubmissionReject)}
      />
    );
  }

  if (view === ViewType.Advance) {
    return (
      <AdvanceForm
        reviewerName={reviewerName}
        candidateName={candidateName}
        loading={saving}
        onSubmit={(values) => {
          submissionAction(null, events.name.completedSubmissionAdvance);
          onSubmit({
            comments: values.comments,
            decision: values.decision,
            reasons: null,
          });
        }}
        onCancel={() => {
          submissionAction(ViewType.Pending, events.name.cancelledSubmission);
        }}
      />
    );
  }

  if (view === ViewType.Reject) {
    return (
      <RejectForm
        reviewerName={reviewerName}
        candidateName={candidateName}
        loading={saving}
        onSubmit={(values) => {
          submissionAction(null, events.name.completedSubmissionReject);
          onSubmit({
            comments: values.comments,
            decision: values.decision,
            reasons: (
              [
                ['reasonTooJunior', 'TooJunior'],
                ['reasonMissingSkills', 'MissingSkills'],
                ['reasonMissingExperience', 'LacksRelevantWorkExperience'],
                ['reasonOther', 'Other'],
              ] as [string, keyof typeof ICIMS.RejectionReason][]
            )
              .map(([formField, enumField]: [string, keyof typeof ICIMS.RejectionReason]) => ({
                checked: values[formField],
                value: ICIMS.RejectionReason[enumField].Value,
                id: ICIMS.RejectionReason[enumField].ID,
                field: ICIMS.RejectionReason[enumField].FieldName,
              }))
              .filter(({ checked }) => checked)
              .map(({ value, id, field }) => ({ value, id, field })),
          });
        }}
        onCancel={() => {
          submissionAction(ViewType.Pending, events.name.cancelledSubmission);
        }}
      />
    );
  }

  throw new Error(`View ${view} is not a valid view type`);
}
