import moment from 'moment';
import { compareByDateAsc } from 'global/utils';
import { readableLocationOr } from 'talent-hub/utils';
import type { MemberDirectoryQuery } from './data';

export function serializerForMemberDirectory(data?: MemberDirectoryQuery) {
  if (!data) {
    return [];
  }

  const uniqueMembers = data.members.reduce<{
    [key: number]: MemberDirectoryQuery['members'][number];
  }>((acc, member) => {
    const userIndex = member.member_details.id;
    const matchedUser = acc[userIndex];

    if (!matchedUser)
      return {
        ...acc,
        [userIndex]: member,
      };

    // When a member has multiple records with the same effective_start date, should be displayed only the one with the most recent created_at
    if (
      new Date(member.effective_start).getTime() === new Date(matchedUser.effective_start).getTime()
    ) {
      return {
        ...acc,
        [userIndex]:
          new Date(member.created_at).getTime() > new Date(matchedUser.created_at).getTime()
            ? member
            : matchedUser,
      };
    }

    // When a member has multiple records with different effective_start date, should be displayed only the one with the most recent effective_start
    return {
      ...acc,
      [userIndex]:
        new Date(member.effective_start).getTime() > new Date(matchedUser.effective_start).getTime()
          ? member
          : matchedUser,
    };
  }, {});

  return Object.values(uniqueMembers)
    .sort((memberA, memberB) => compareByDateAsc(memberA.effective_start, memberB.effective_start))
    .map((member) => {
      const start = moment(member.effective_start);
      const differenceDuration = moment.duration(moment().diff(start));

      return {
        id: member.member_details.id,
        title: member.title,
        location: readableLocationOr(
          member.dayforce_location.readable_locations,
          member.dayforce_location.location,
        ),
        start: start.format('MMMM D, YYYY'),
        yearsOfDuration: differenceDuration.years(),
        monthsOfDuration: differenceDuration.months(),
        daysOfDuration: differenceDuration.days(),
        name: `${member.member_details.firstname} ${member.member_details.lastname}`,
      };
    });
}
