/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { SignInPage } from './SignInPage';

export default {
  title: 'Global/Pages/Auth/Sign In Page',
  component: SignInPage,
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template: StoryFn<ComponentProps<typeof SignInPage>> = (args) => <SignInPage {...args} />;

export const story_SignInPage_unfilled = Template.bind({} as {});
story_SignInPage_unfilled.args = {
  headerSubtitle: 'Log in to your account using one of the options below (ST)',
  signInFormProps: {
    shouldHideGoogleSSO: false,
    showLoadingForEmailSignIn: false,
    errorMessage: '',
    disableGoogleSignInButton: false,
    email: undefined,
    googleSignInInstruction: undefined,
    hideForgotPassword: false,
    isEmailInputDisabled: false,
    onEmailSignSubmitted: () => {},
    onForgotPasswordClick: () => {},
    onGoogleLoginClicked: () => {},
  },
};
story_SignInPage_unfilled.storyName = 'Unfilled';

export const story_SignInPage_noGoogleSSO = Template.bind({} as {});
story_SignInPage_noGoogleSSO.args = {
  ...story_SignInPage_unfilled.args,
  // @ts-ignore
  signInFormProps: {
    ...story_SignInPage_unfilled.args.signInFormProps,
    shouldHideGoogleSSO: true,
  },
};
story_SignInPage_noGoogleSSO.storyName = 'Hidden Google SSO';

export const story_SignInPage_filledEmail = Template.bind({} as {});
story_SignInPage_filledEmail.args = {
  ...story_SignInPage_unfilled.args,
  // @ts-ignore
  signInFormProps: {
    ...story_SignInPage_unfilled.args.signInFormProps,
    email: 'john.doe@gmail.com',
    googleSignInInstruction: 'Google Sign In instruction (ST)', // TODO: this is not showing in the UI
    isEmailInputDisabled: true,
  },
};
story_SignInPage_filledEmail.storyName = 'Filled Email';

export const story_SignInPage_error = Template.bind({} as {});
story_SignInPage_error.args = {
  ...story_SignInPage_unfilled.args,
  // @ts-ignore
  signInFormProps: {
    ...story_SignInPage_unfilled.args.signInFormProps,
    errorMessage: 'Something went wrong (ST)',
  },
};
story_SignInPage_error.storyName = 'Errored';
