import { HStack, Heading, Text, Divider } from '@terminal/design-system';

export function AppSubheader({ title, subTitle }: { title?: string; subTitle?: string }) {
  return (
    <HStack pb={6}>
      {title && (
        <Heading color="brand.main" variant="heading-2">
          {title}
        </Heading>
      )}
      {title && subTitle && (
        <>
          <Divider orientation="vertical" py={3} pl={2} />
          <Text color="brand.main">{subTitle}</Text>
        </>
      )}
    </HStack>
  );
}
