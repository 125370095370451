import type { OperationVariables, QueryResult, QueryFunctionOptions } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useQuery as useApolloQuery } from '@apollo/client';

export interface QueryHookOptions<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
> extends QueryFunctionOptions<TData, TVariables> {
  query?: DocumentNode;
}

export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const {
    client,
    error,
    data,
    loading,
    networkStatus,
    called,
    startPolling,
    stopPolling,
    subscribeToMore,
    updateQuery,
    refetch,
    fetchMore,
    variables,
    observable,
    reobserve,
    // @ts-ignore // TODO: [TP-1919] Fix this type
  } = useApolloQuery(query, options);

  // forcing throwing error, so that sentry ErrorBoundary can pick it up
  if (error) throw error;

  return {
    data,
    loading,
    networkStatus,
    client,
    called,
    startPolling,
    stopPolling,
    subscribeToMore,
    updateQuery,
    refetch,
    fetchMore,
    // @ts-ignore // TODO: [TP-1919] Fix this type
    variables,
    // @ts-ignore // TODO: [TP-1919] Fix this type
    observable,
    // @ts-ignore // TODO: [TP-1919] Fix this type
    reobserve,
  };
}

/**
 * Generic reducer that should be used for all async operation
 */
export function asyncReducer<T>(state: AsyncState<T>, action: AsyncAction<T>): AsyncState<T> {
  switch (action.type) {
    case 'idle': {
      return {
        status: 'idle',
        data: action.data || null,
        error: null,
        attemptsCount: state.attemptsCount,
      };
    }
    case 'pending': {
      return {
        status: 'pending',
        data: action.data || state.data || null,
        error: null,
        attemptsCount: state.attemptsCount,
      };
    }
    case 'resolved': {
      return {
        status: 'resolved',
        data: action.data,
        error: null,
        attemptsCount: state.attemptsCount,
      };
    }
    case 'rejected': {
      return {
        status: 'rejected',
        data: action.data || state.data || null,
        error: typeof action.error === 'string' ? new Error(action.error) : action.error,
        attemptsCount: state.attemptsCount + 1,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export function createAsyncInitialState<T>(data: T) {
  return {
    status: 'idle' as AsyncStatus,
    data,
    error: null,
    attemptsCount: 0,
  };
}

export function toAsyncProps(asyncData: AsyncState<any>) {
  return {
    error: asyncData.error,
    attemptCount: asyncData.attemptsCount,
    asyncStatus: asyncData.status,
    isIdle: asyncData.status === 'idle',
    isLoading: asyncData.status === 'pending',
    isError: asyncData.status === 'rejected',
    isResolved: asyncData.status === 'resolved',
  };
}
