import { createExperimentProvider } from 'global/utils/experiment';

export type Experiment = {};

const defaultExperiment: Experiment = {};

export const { ExperimentProvider, useExperiment, triggerExperiments, triggerGoal } =
  createExperimentProvider<Experiment>({
    defaultValue: defaultExperiment,
  });
