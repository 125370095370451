import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Center,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  CoffeeCupIcon,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader, TableContentLoading } from 'talent-hub/components';

import type { FilledRole } from '../../ManageRoles.serializer';

function FilledRolesCard({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardHeader>
        <Heading variant="heading-3" color="brand.darker">
          Roles Previously Filled
        </Heading>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

export function FilledRolesTable({
  isLoading,
  roles,
}: {
  roles: FilledRole[];
  isLoading: boolean;
}) {
  if (isLoading)
    return (
      <FilledRolesCard>
        <TableContainer whiteSpace="break-spaces">
          <Table>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Date Opened</Th>
                <Th>Positions Filled</Th>
              </Tr>
            </Thead>
            <Tbody>
              <TableContentLoading columns={3} rows={3} />
            </Tbody>
          </Table>
        </TableContainer>
      </FilledRolesCard>
    );

  if (!roles?.length)
    return (
      <FilledRolesCard>
        <Center alignItems="center" flexDirection="column">
          <Box textAlign="center" w={['full', 'sm']}>
            <CoffeeCupIcon h={20} w={20} mb="2" />
            <Text>No roles have been filled yet!</Text>
          </Box>
        </Center>
      </FilledRolesCard>
    );

  return (
    <FilledRolesCard>
      <TableContainer whiteSpace="break-spaces">
        <Table>
          <Thead>
            <Tr>
              <Th>Role</Th>
              <Th>Date Opened</Th>
              <Th>Positions Filled</Th>
            </Tr>
          </Thead>
          <Tbody>
            {roles.map(({ id, name, openedDate, totalHired, location }) => (
              <Tr key={id}>
                <Td>
                  <Link variant="label" to={`/role/${id}`} as={RouterLink}>
                    {name}
                  </Link>
                  <Text variant="caption" mt={1}>
                    {location}
                  </Text>
                </Td>
                <Td>{openedDate}</Td>
                <Td isNumeric>{totalHired}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </FilledRolesCard>
  );
}
