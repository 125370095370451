import { useFormik } from 'formik';
import type { FormikValues, FormikConfig, FormikErrors } from 'formik';

export function useCustomFormik<Values extends FormikValues = FormikValues>({
  validateAfterSubmit = true,
  ...props
}: FormikConfig<Values> & { validateAfterSubmit?: boolean }) {
  const formik = useFormik<Values>(props);

  return {
    ...formik,
    errors: (validateAfterSubmit && formik.submitCount === 0
      ? {}
      : formik.errors) as FormikErrors<Values>,
  };
}
