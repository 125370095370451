/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@terminal/design-system';

const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .required('Please include an email address.'),
});

export interface ForgotPasswordFormProps {
  errorMessage?: string | null;
  onResetSubmit: (email: string) => void;
  showLoadingSpinner?: boolean;
}

export function ForgotPasswordForm({
  errorMessage,
  onResetSubmit,
  showLoadingSpinner,
}: ForgotPasswordFormProps) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const validationToastIDRef = useRef<string | number | undefined>();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => onResetSubmit(values.email),
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        description: errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const hasValidationError = formik.errors.email;

  useEffect(() => {
    if (formik.isSubmitting && hasValidationError) {
      validationToastIDRef.current = toast({
        status: 'error',
        description: 'Please review your entry and try again.',
      });
    } else if (validationToastIDRef.current && !hasValidationError) {
      toast.close(validationToastIDRef.current);
    }
  }, [hasValidationError, formik.isSubmitting]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <FormControl id="email" isInvalid={!!formik.errors.email && !!formik.touched.email}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          type="email"
          placeholder="Your Email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          autoCapitalize="none"
          spellCheck={false}
        />
        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
      </FormControl>

      <Button
        isLoading={showLoadingSpinner}
        variant="solid"
        colorScheme="primary"
        mt={6}
        w="full"
        type="submit"
      >
        Reset Password
      </Button>
    </form>
  );
}
