import { textTheme } from '../Text/Text.theme';

type Dict = Record<string, any>;

const colorSchemeMap: { [key: string]: { ['color']: string } } = {
  primary: {
    color: 'text.secondary',
  },
  brand: {
    color: 'brand.main',
  },
  success: {
    color: 'text.success',
  },
};

export const stepTheme = {
  parts: ['container', 'leftIcon'],
  baseStyle: ({ colorScheme: c }: Dict) => ({
    container: {
      ...textTheme.variants.body,
      ...colorSchemeMap[c as string],
      width: 'max',
      px: 3,
      py: 1,
      display: 'flex',
      alignItems: 'center',
    },
    leftIcon: {
      mr: 2,
    },
  }),
  defaultProps: {
    colorScheme: 'primary',
  },
};
