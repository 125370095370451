import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';
import type { SystemStyleObject } from '@chakra-ui/react';

const baseStyleContainer: SystemStyleObject = {
  borderTopWidth: '0px',
  borderColor: 'inherit',
  _last: {
    borderBottomWidth: '0px',
  },
};

const baseStyleButton: SystemStyleObject = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontSize: '1rem',
  _focusVisible: {
    boxShadow: 'none',
  },
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    bg: 'none',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  px: 0,
  py: 0,
};

const baseStylePanel: SystemStyleObject = {
  pt: 2,
  px: 0,
  pb: 0,
};

const baseStyleIcon: SystemStyleObject = {
  fontSize: '1.25em',
};

const baseStyle: PartsStyleObject<typeof parts> = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
};

export const AccordionTheme = {
  parts: parts.keys,
  baseStyle,
};
