import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import type { Auth } from 'firebase/auth';
import type { FirebaseStorage } from 'firebase/storage';

export { getMetadata, uploadBytes, getDownloadURL } from 'firebase/storage';

/**
 * When set to true, REACT_APP_FIREBASE_FIRST_PARTY_AUTH will cause the web apps to use the current
 * hostname. If not explicitely set to "true" the REACT_APP_FIREBASE_AUTH_DOMAIN will be used instead.
 *
 * Huh? A security feature in the latest version of Safari (16.1) is automatically blocking third-party
 * cookies that are used by Firebase to authenticate. We were previously relying on a single domain
 * for prod, dev, and local (`login.terminal.io` and `login.dev.terminal.io`). This approach continues
 * to work in other browsers but has stopped working in Safari.
 *
 * This option cannot easily be used in local development:
 *   - Firebase is throwing an error when using localhost:3000 as a domain name
 *   - When the port is removed, the redirect from google go to http://localhost/... and produces
 *     an error.
 *   - Changing the domain and protocol for the failed redirect causes a manipulated URL error
 *   - We probably need to run a proxy locally on port 80. It is not a good idea to run the development
 *     server with root permissions (needed to bind to port 80)
 */
/*
 * A note about the .match():
 * The ternary operator is being optimized away when using comparison operators. The optimization
 * seems to persist even if wrapping functions are used. The code behaves as expected in the local
 * dev server, but the build reduces the operation down to a simple assignment to something like
 * `var o="__REACT_APP_FIREBASE_AUTH_DOMAIN__";`
 */
const authDomain = (import.meta.env.REACT_APP_FIREBASE_FIRST_PARTY_AUTH || '').match('true')
  ? window.location.hostname
  : import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN;

// * as we use strings in the env variables, this always be true
const firebaseApp = import.meta.env.REACT_APP_FIREBASE_API_KEY
  ? initializeApp({
      apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
      authDomain,
      projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    })
  : null;

// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = firebaseApp ? getAuth(firebaseApp) : ({} as Auth);

export const firebaseStorage = firebaseApp ? getStorage(firebaseApp) : ({} as FirebaseStorage);

export function createStorageRef(url: string) {
  return ref(firebaseStorage, url);
}
