import type { AuthenticationRouterOverwrite, AuthRedirectTo } from '../types';
import { SignInPage } from './SignInPage';
import { useSignIn } from './SignIn.utils';

export function SignInController({
  handleGoogleSignIn,
  friendlyErrorMessage,
  predefinedEmail,
  redirectedFromPathname,
  hideForgotPassword,
  redirectTo,
  overwrite,
}: {
  handleGoogleSignIn: () => void;
  friendlyErrorMessage?: string;
  predefinedEmail?: string;
  redirectedFromPathname?: string;
  hideForgotPassword?: boolean;
  redirectTo: AuthRedirectTo;
  overwrite?: AuthenticationRouterOverwrite;
}) {
  const { handleSignInSubmit, isLoading, shouldHideGoogleSSO } = useSignIn();

  return (
    <SignInPage
      {
        // TODO: '/job-apply' is specific to candidate app. Therefore it should be moved
        // out of auth sign in which is a global feature. We should utilize overwrite fort this.
        ...(redirectedFromPathname === '/job-apply'
          ? { headerSubtitle: 'Log in to your account to quickly apply to this opportunity.' }
          : {})
      }
      onSignUpClick={() => redirectTo('signup')}
      pageTemplateOverwrite={overwrite?.pageTemplate?.login ?? overwrite?.pageTemplate?.default}
      signInFormProps={{
        onGoogleLoginClicked: handleGoogleSignIn,
        onEmailSignSubmitted: handleSignInSubmit,
        showLoadingForEmailSignIn: isLoading,
        disableGoogleSignInButton: isLoading,
        errorMessage: friendlyErrorMessage,
        email: predefinedEmail,
        isEmailInputDisabled: !!predefinedEmail,
        hideForgotPassword: !!hideForgotPassword,
        onForgotPasswordClick: () => {
          redirectTo('forgot-password');
        },
        shouldHideGoogleSSO,
      }}
    />
  );
}
