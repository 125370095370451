import { Box, Button, Text, EmailVerificationIcon } from '@terminal/design-system';
import type { AuthPageTemplateProps } from '../types';
import { AuthMessage, AuthPageTemplate } from '../components';

export function EmailNotVerifiedWarningPage({
  isLoading,
  onLogoutClick,
  onResendVerificationEmailClick,
  pageTemplateOverwrite,
}: {
  isLoading: boolean;
  onLogoutClick: () => void;
  onResendVerificationEmailClick: () => void;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate
      footerLinkOnClick={onResendVerificationEmailClick}
      footerSecondaryClick={onLogoutClick}
      isLoading={isLoading}
    >
      <AuthMessage icon={<EmailVerificationIcon />} title="Please verify your email address.">
        <Box>
          <Text textAlign="left">
            You will need to verify your email address before you can proceed. Please check your
            inbox for a verification email from Terminal.
            <br /> <br />
            If you do not see an email, you can request a new link below.
          </Text>
          <Box>
            <Button
              variant="solid"
              colorScheme="primary"
              mt={6}
              w={64}
              onClick={onResendVerificationEmailClick}
              isLoading={isLoading}
            >
              Resend Verification Email
            </Button>
            <Button variant="outline" colorScheme="accent" mt={6} w={64} onClick={onLogoutClick}>
              Logout
            </Button>
          </Box>
        </Box>
      </AuthMessage>
    </PageTemplate>
  );
}
