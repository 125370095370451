import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import Sentry from 'global/sentry';
import { createContext, useContext, useReducer, useEffect } from 'react';
import type { ReactNode, Reducer } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import type { AuthData } from './types';
import { attachAuthToState } from './attachAuthToState';

const initialState = {
  ...createAsyncInitialState({
    isAuthenticated: false,
    isEmailVerified: null,
    roles: [] as string[],
    user: null,
  }),
  isReady: false,
};

type AuthAsyncState<T> = AsyncState<T> & { isReady?: boolean };

type AuthAsyncAction<T> = AsyncAction<T> | { type: 'initialized'; data?: T };

export type AuthContextType = {
  state: {
    dispatch: (action: AuthAsyncAction<AuthData>) => void;
    friendlyErrorMessage: string | undefined;
    isReady: boolean;
    roles: string[];
  } & AuthData &
    ReturnType<typeof toAsyncProps>;
  dispatch: (action: AuthAsyncAction<AuthData>) => void;
} | null;

const AuthPrivateContext = createContext<AuthContextType>(null);

function asyncReducer_withIsReady<T>(
  state: AuthAsyncState<T>,
  action: AuthAsyncAction<T>,
): AuthAsyncState<T> {
  switch (action.type) {
    case 'initialized': {
      return {
        ...state,
        isReady: true,
      };
    }
    default: {
      return {
        ...state,
        ...asyncReducer<T>(state, action),
      };
    }
  }
}

export function AuthProvider({
  children,
  appName,
  testing,
}: {
  children: ReactNode;
  appName: string;
  testing?: {
    createAuthMock: (dispatch: React.Dispatch<AuthAsyncAction<AuthData>>) => void;
  };
}) {
  const [state, dispatch] = useReducer<
    Reducer<AuthAsyncState<AuthData>, AuthAsyncAction<AuthData>>
  >(asyncReducer_withIsReady, initialState);

  useEffect(() => {
    if (testing?.createAuthMock) {
      testing?.createAuthMock(dispatch);
      return undefined;
    }

    const unsubscribe = attachAuthToState({ dispatch, appName });
    dispatch({ type: 'initialized' });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testing?.createAuthMock]);

  let friendlyErrorMessage: string | undefined;

  const nonErrorSignInFailureDictionary: { [key: string]: string } = {
    // ref: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
    'auth/user-not-found': 'An account was not found for this email address.',
    // Thrown if there already exists an account with the email address asserted by the credential.
    'auth/account-exists-with-different-credential':
      'This account exists, but cannot be accessed with this login method.',
    'auth/user-disabled': 'Your account has been locked. Please contact support@terminal.io',
    'auth/credential-already-in-use':
      'You already have an account. Please login with your account.',
  };

  // Error codes that don't want to display friendly error message for nor we want to log them in sentry
  const shouldBeSuppressed_fireBaseErrors = [
    // Thrown if the popup window is closed by the user without completing the sign in to the provider.
    'auth/popup-closed-by-user',
    // Thrown if successive popup operations are triggered. Only one popup request is allowed at one time. All the popups would fail with this error except for the last one.
    'auth/cancelled-popup-request',
  ];

  if (
    state.status === 'rejected' &&
    !shouldBeSuppressed_fireBaseErrors.includes(state.error?.code || '')
  ) {
    if (
      // if the error code did not exist in nonErrorSignInFailureDictionary object
      !Object.keys(nonErrorSignInFailureDictionary).includes(state.error?.code || '') &&
      // And if this specific code is not occurring inside of phone or tablet
      !(
        // since we believe this error only happens on mobile and tablet, we only want to sentry log it when its happening outside
        // of mobile or tablet
        (
          state.error?.code === 'auth/operation-not-supported-in-this-environment' &&
          (isAndroid || isIOS)
        )
      )
    ) {
      Sentry.captureException(state.error);
    }

    friendlyErrorMessage =
      nonErrorSignInFailureDictionary[state.error?.code || ''] ||
      'Something went wrong on our side. Please try again later!';
  }

  return (
    <AuthPrivateContext.Provider
      // TODO (TP-1874): fix this eslint error
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state: {
          dispatch,
          friendlyErrorMessage,
          isAuthenticated: state.data?.isAuthenticated || false,
          // TODO: find a way to make this work. When this value is passed to the code instead of
          // using authService?.currentUser?.isAnonymous directly, things start failing.
          // isAuthenticatedAnonymously: authService?.currentUser?.isAnonymous,
          isEmailVerified: state.data && state.data.isEmailVerified,
          roles: state.data?.roles || [],
          user: state.data?.user || null,
          isReady: !!state.isReady, // TODO (CAND-433): change this to (!auth.isResolved && !auth.isError) + creating a new field for isFirebaseReady
          ...toAsyncProps(state),
        },
        dispatch,
      }}
    >
      {children}
    </AuthPrivateContext.Provider>
  );
}

export type UseAuth = ReturnType<typeof useAuth>;

export function useAuth() {
  const context = useContext(AuthPrivateContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context.state;
}
