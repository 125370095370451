import React from 'react';
import { forwardRef, useStyleConfig, chakra, omitThemingProps } from '@chakra-ui/react';
import type { HTMLChakraProps, ThemingProps } from '@chakra-ui/react';

export interface StepProps extends HTMLChakraProps<'div'>, ThemingProps<'Text'> {
  /**
   * Button different color sets
   */
  colorScheme?: 'brand' | 'success' | 'primary';

  /**
   * If added, the button will show an icon before the button's label.
   * @type React.ReactElement
   */
  leftIcon?: React.ReactElement;
}

/**
 * Primary UI component for user interaction
 */
export const Step = forwardRef<StepProps, 'span'>((props, ref) => {
  const styles = useStyleConfig('Step', props);
  const { children, leftIcon, ...rest } = omitThemingProps(props);
  // @ts-ignore
  const LeftIcon = leftIcon ? React.cloneElement(leftIcon, { __css: styles.leftIcon }) : undefined;

  return (
    // @ts-ignore
    <chakra.div {...rest} ref={ref} __css={styles.container}>
      {LeftIcon}
      {children}
    </chakra.div>
  );
});
