import { cloneElement, isValidElement } from 'react';
import type { PropsOf, HTMLChakraProps } from '@chakra-ui/react';
import { Box } from '../../layout';

export const DefaultSelectIcon = (props: PropsOf<'svg'>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  </svg>
);

interface SelectIconProps extends HTMLChakraProps<'div'> {}

export const SelectIcon = (props: SelectIconProps) => {
  const { children = <DefaultSelectIcon />, ...rest } = props;

  const clone = cloneElement(children as any, {
    role: 'presentation',
    focusable: false,
    'aria-hidden': true,
    style: {
      width: '1em',
      height: '1em',
      color: 'currentColor',
    },
  });

  return (
    <Box
      {...rest}
      position="absolute"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      pointerEvents="none"
      top="50%"
      transform="translateY(-50%)"
    >
      {isValidElement(children) ? clone : null}
    </Box>
  );
};
