import moment from 'moment';
import {
  Client_Prospect_Events_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import type { Role_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import {
  serializeCandidateBrowse,
  displayRolesNames,
} from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { toFriendlyYearsOfExperienceRange } from 'global/utils';
import { displayRolesOrder } from 'talent-hub/role/prospect/onboarding/steps/roles/Roles.serializer';

import type {
  SelectBrowseCandidatesQuery,
  SelectDashboardBrowseCandidatesQuery,
  SelectProspectDashboardQueryQuery,
} from './data';

export type Status = {
  [T in Client_Prospect_Events_Choices_Enum]: 'completed' | 'pending' | 'locked';
};

export function serializeDashboard(data: SelectProspectDashboardQueryQuery | undefined) {
  const enumChoices = Object.values(Client_Prospect_Events_Choices_Enum);
  const status = {} as Status;

  enumChoices.forEach((key) => {
    if (key === Client_Prospect_Events_Choices_Enum.SaveCandidate) {
      status[key] = 'pending';

      if (
        !data?.client_prospect_event?.find(
          (el) => el.event_choice === Client_Prospect_Events_Choices_Enum.EnterBrowseCandidates,
        )
      ) {
        status[key] = 'locked';
      }
    } else {
      status[key] = 'pending';
    }
  });

  const clientProspect = data?.client_prospect?.[0];
  const eventLength = data?.client_prospect_event?.length || 0;
  const progressValue = (eventLength / enumChoices.length) * 100;
  data?.client_prospect_event?.forEach((el) =>
    Object.assign(status, { [el.event_choice]: 'completed' }),
  );

  return {
    statusInfo: {
      status,
      progressValue,
      completedSteps: eventLength,
    },
    clientProspect: {
      jobOpenings: clientProspect?.client_prospect_roles?.map((role) => ({
        type: role.role_type,
        label: displayRolesNames[role.role_type],
        yoe: role.years_of_experience,
        yoeLabel: toFriendlyYearsOfExperienceRange(role.years_of_experience),
        skills: role.client_prospect_role_skills.map((skill) => ({ id: skill.skill_id })),
      })),
    },
    roleOptions:
      data?.role_choices
        ?.flatMap?.(({ value }) =>
          displayRolesNames[value as Role_Choices_Enum]
            ? {
                value,
                label: displayRolesNames[value as Role_Choices_Enum] || '',
              }
            : [],
        )
        ?.slice?.()
        ?.sort?.((a, b) => {
          if (Number(displayRolesOrder[a.value]) === Number(displayRolesOrder[b.value])) return 0;

          return Number(displayRolesOrder[a.value]) < Number(displayRolesOrder[b.value]) ? -1 : 1;
        }) || [],
    yoeOptions: [
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.ZeroTwo,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TwoFive,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.FiveTen,
      Candidate_Curation_Years_Of_Exp_Range_Choices_Enum.TenPlus,
    ].map((item) => ({
      value: item,
      label: toFriendlyYearsOfExperienceRange(item),
    })),
  };
}

export function shouldCallSaveCandidateFunction(
  saveCandidateStatus: 'completed' | 'pending' | 'locked',
  savedByMeCandidatesCount: number,
  loading: boolean,
): boolean {
  if (saveCandidateStatus === 'completed') {
    return false;
  }

  return !loading && savedByMeCandidatesCount > 0;
}

export function serializeProspectCalendlyEventInfo(
  data: SelectProspectDashboardQueryQuery | undefined,
): string {
  if (!data) {
    return '';
  }

  if (!data.calendly_invitee[0]?.calendly_events[0]?.start_time) {
    return '';
  }

  const startTime = data.calendly_invitee[0].calendly_events[0].start_time;
  return moment(startTime).format('MMM D, YYYY @ h:mm a');
}

export function serializeDashboardCandidates(
  byRoleData: SelectBrowseCandidatesQuery | undefined,
  data: SelectDashboardBrowseCandidatesQuery | undefined,
  serializeCandidateBrowseArgs: Pick<
    Parameters<typeof serializeCandidateBrowse>[0],
    | 'skillsToSearch'
    | 'selectedYoE'
    | 'highlightBadgeType'
    | 'isFilterSelected'
    | 'isLoadingCandidates'
  >,
) {
  if (typeof data === 'undefined') {
    return {
      candidates: [],
      candidatesRecentlyActive: [],
      candidatesTopCandidateExperience: [],
      candidateZeroOneExperience: [],
      candidateTechLead: [],
    };
  }

  const { skillsToSearch, selectedYoE, highlightBadgeType, isFilterSelected, isLoadingCandidates } =
    serializeCandidateBrowseArgs;
  const { candidate_browse } = byRoleData || {};
  const {
    candidate_browse_recently_active,
    candidate_top_company_experience,
    candidate_zero_one_experience,
    candidate_tech_lead,
    location,
  } = data;
  const { candidates } = serializeCandidateBrowse({
    // @ts-ignore TODO (TP-1666): fix ignored typescript error
    data: candidate_browse && location ? { candidate_browse, location } : undefined,
    skillsToSearch,
    selectedYoE,
    highlightBadgeType,
    isFilterSelected,
    isSortByRecommended: true,
    isLoadingCandidates,
  });
  const { candidates: candidatesRecentlyActive } = serializeCandidateBrowse({
    data:
      candidate_browse_recently_active && location
        ? {
            candidate_browse: candidate_browse_recently_active,
            // @ts-ignore TODO: fix ignored typescript error
            location,
          }
        : undefined,
    isSortByRecommended: false,
    isLoadingCandidates,
  });
  const { candidates: candidatesTopCandidateExperience } = serializeCandidateBrowse({
    data:
      candidate_top_company_experience && location
        ? {
            candidate_browse: candidate_top_company_experience,
            // @ts-ignore TODO: fix ignored typescript error
            location,
          }
        : undefined,

    isSortByRecommended: false,
    isLoadingCandidates,
  });
  const { candidates: candidateZeroOneExperience } = serializeCandidateBrowse({
    data:
      candidate_zero_one_experience && location
        ? {
            candidate_browse: candidate_zero_one_experience,
            // @ts-ignore TODO: fix ignored typescript error
            location,
          }
        : undefined,
    isSortByRecommended: false,
    isLoadingCandidates,
  });
  const { candidates: candidateTechLead } = serializeCandidateBrowse({
    data:
      candidate_tech_lead && location
        ? {
            candidate_browse: candidate_tech_lead,
            // @ts-ignore TODO: fix ignored typescript error
            location,
          }
        : undefined,

    isSortByRecommended: false,
    isLoadingCandidates,
  });

  return {
    candidates,
    candidatesRecentlyActive,
    candidatesTopCandidateExperience,
    candidateZeroOneExperience,
    candidateTechLead,
  };
}
