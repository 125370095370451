type Dict = Record<string, any>;

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
  if (value === 'full') {
    return { dialog: { maxW: '100vw', minH: '100vh' } };
  }
  return { dialog: { maxW: value } };
}

export const modalTheme = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: (props: Dict) => ({
    overlay: {
      bg: 'rgba(57, 66, 76, 0.3)', // ui.primary opacity 0.3,
      zIndex: 'modal',
    },
    dialogContainer: {
      display: 'flex',
      zIndex: 'modal',
      justifyContent: 'center',
      alignItems: props.isCentered ? 'center' : 'flex-start',
      overflow: props.scrollBehavior === 'inside' ? 'hidden' : 'auto',
    },
    dialog: {
      borderRadius: 'base',
      bg: 'bg.primary',
      color: 'inherit',
      my: 'unset',
      zIndex: 'modal',
      maxH:
        props.scrollBehavior === 'inside'
          ? ['100%', 'calc(100vh - 4rem)', 'calc(100vh - 4rem)']
          : undefined,
      boxShadow: 'md',
    },
    header: {
      py: 4,
      px: 3,
      borderBottom: props.children ? '1px solid' : 'none',
      bg: 'bg.secondary',
      flexDir: 'row',
    },
    closeButton: {
      position: 'absolute',
      top: 3,
      right: 3,
      boxShadow: 'none !important',
      fontSize: 'xs',
      p: 3,
      color: 'accent.main',
      opacity: 0.6,
    },
    body: {
      p: [3, 6],
      flex: 1,
      bg: 'bg.primary',
      overflowY: props.scrollBehavior === 'inside' ? 'scroll' : undefined,
      '&::-webkit-scrollbar': {
        w: 1,
      },
      '&::-webkit-scrollbar-track': {
        bg: 'bg.primary',
      },
      '&::-webkit-scrollbar-thumb': {
        bgColor: 'ui.secondary',
        borderRadius: 'base',
      },
    },
    footer: {
      px: [3, 6],
      py: 6,
      borderBottomRadius: 'base',
      zIndex: '1100',
    },
  }),
  sizes: {
    md: getSize('md'),
    ml: getSize('ml'),
    lg: getSize('lg'),
  },
  defaultProps: {
    size: 'ml',
  },
};
