import React, { useEffect, useRef } from 'react';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GoogleIcon,
  HStack,
  Input,
  Link,
  Text,
  useToast,
} from '@terminal/design-system';
import type { SpaceProps } from '@terminal/design-system';
import { validators } from 'global/utils';
import { PasswordFieldWithPopoverValidator, createPasswordYupValidation } from '../components';

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please make sure your email address is valid')
    .required('Please include an email address.'),
  firstName: Yup.string()
    .trim()
    .matches(validators.firstName.regex, validators.firstName.message)
    .required('Please include your first name.'),
  lastName: Yup.string()
    .trim()
    .matches(validators.lastName.regex, validators.lastName.message)
    .required('Please include your last name.'),
  password: createPasswordYupValidation(),
});

type SignUpProps = {
  email?: string;
  errorMessage?: string | null;
  firstName?: string;
  googleSignUpInstruction?: string;
  googleSignUpButton?: string;
  isEmailInputDisabled?: boolean;
  isFirstNameInputDisabled?: boolean;
  isLastNameInputDisabled?: boolean;
  lastName?: string;
  onEmailSignSubmitted: (values: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => void;
  onGoogleLoginClicked: () => void;
  showLoadingForEmailSignUp?: boolean;
  shouldHideGoogleSSO: boolean;
  termsCopy: React.ReactNode;
  signUpButton?: string;
};

function SignUpDivider(spacingProps: SpaceProps) {
  return (
    <HStack spacing={3} {...spacingProps}>
      <Divider />
      <Text fontWeight="bold" variant="hint">
        OR
      </Text>
      <Divider />
    </HStack>
  );
}

/**
 * Google Sign up SSO Button with optional instruction text.
 */
function GoogleSignUpSSO({
  onGoogleLoginClicked,
  googleSignUpInstruction,
  buttonVariant = 'outline',
  colorScheme = 'accent',
  googleSignUpButton = 'Sign Up with Google',
  ...spaceProps
}: {
  buttonVariant?: 'solid' | 'outline';
  colorScheme?: 'primary' | 'accent';
} & Pick<SignUpProps, 'googleSignUpInstruction' | 'onGoogleLoginClicked' | 'googleSignUpButton'> &
  SpaceProps) {
  return (
    <Box {...spaceProps}>
      <Button
        onClick={onGoogleLoginClicked}
        leftIcon={<GoogleIcon w={6} h={6} mr={1} />}
        w="full"
        variant={buttonVariant}
        colorScheme={colorScheme}
        id="google-sign-up-button"
      >
        {googleSignUpButton}
      </Button>
      {googleSignUpInstruction && (
        <Text variant="caption" mt={[4, 4, 6]} textAlign="center">
          {googleSignUpInstruction}
        </Text>
      )}
    </Box>
  );
}

function SignUpDefaultTermsCopy() {
  return (
    <>
      By signing up you agree to the{' '}
      <Link fontSize="inherit" href="https://terminal.io/terms-of-use" isExternal display="inline">
        Terms Of Use
      </Link>
    </>
  );
}

function SignUpTerms({ children, ...spaceProps }: { children?: React.ReactNode } & SpaceProps) {
  return (
    <Center borderBottomRadius="base" {...spaceProps}>
      <Text as={Box} variant="hint" mr={2} textAlign="center">
        {children || <SignUpDefaultTermsCopy />}
      </Text>
    </Center>
  );
}

function ManualSignUpForm({
  email,
  errorMessage,
  firstName,
  isEmailInputDisabled = false,
  isFirstNameInputDisabled = false,
  isLastNameInputDisabled = false,
  lastName,
  onEmailSignSubmitted,
  showLoadingForEmailSignUp = false,
  signUpButton = 'Sign Up',
}: Pick<
  SignUpProps,
  | 'email'
  | 'errorMessage'
  | 'firstName'
  | 'isEmailInputDisabled'
  | 'isFirstNameInputDisabled'
  | 'isLastNameInputDisabled'
  | 'lastName'
  | 'onEmailSignSubmitted'
  | 'showLoadingForEmailSignUp'
  | 'signUpButton'
>) {
  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  const formInitialInputRef = useRef(null);
  const validationToastIDRef = useRef<string | number | undefined>();
  const submissionCount = useRef<number>(0);

  const formik = useCustomFormik({
    initialValues: {
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || '',
      password: '',
    },
    validationSchema: signUpValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => onEmailSignSubmitted(values),
  });

  const hasValidationError =
    (formik.errors.email && formik.touched.email) ||
    (formik.errors.firstName && formik.touched.firstName) ||
    (formik.errors.lastName && formik.touched.lastName) ||
    (formik.errors.password && formik.touched.password);

  useEffect(() => {
    if (errorMessage) {
      toast({
        status: 'error',
        description: errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (hasValidationError) {
      validationToastIDRef.current = toast({
        status: 'error',
        description: 'Please review your entry and try again.',
      });
    } else if (validationToastIDRef.current) {
      toast.close(validationToastIDRef.current);
    } else {
      submissionCount.current += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionCount.current]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <FormControl
        id="firstName"
        isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}
      >
        <FormLabel htmlFor="firstName" color={!firstName ? 'text.primary' : 'text.disabled'}>
          First Name
        </FormLabel>
        <Input
          placeholder="Your First Name"
          id="firstName"
          name="firstName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          disabled={isFirstNameInputDisabled}
        />
        <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
      </FormControl>

      <FormControl
        mt={[4, 4, 6]}
        id="lastName"
        isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}
      >
        <FormLabel htmlFor="lastName" color={!lastName ? 'text.primary' : 'text.disabled'}>
          Last Name
        </FormLabel>

        <Input
          type="lastName"
          placeholder="Your Last Name"
          id="lastName"
          name="lastName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          disabled={isLastNameInputDisabled}
        />

        <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
      </FormControl>

      <FormControl
        mt={[4, 4, 6]}
        id="email"
        isInvalid={!!formik.errors.email && !!formik.touched.email}
      >
        <FormLabel htmlFor="email" color={!email ? 'text.primary' : 'text.disabled'}>
          Email
        </FormLabel>
        <Input
          type="email"
          placeholder="Your Email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          ref={formInitialInputRef}
          value={formik.values.email}
          disabled={isEmailInputDisabled}
          autoCapitalize="none"
          spellCheck={false}
        />
        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
      </FormControl>

      <PasswordFieldWithPopoverValidator
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isInvalid={!!formik.errors.password && !!formik.touched.password}
        placeholder="Your Password"
        title="Your Password"
        touched={!!formik.touched.password}
        value={formik.values.password}
        submissionCount={submissionCount.current}
        mt={[4, 4, 6]}
      />

      <Button
        isLoading={showLoadingForEmailSignUp}
        onClick={() => {
          submissionCount.current += 1;
        }}
        variant="solid"
        colorScheme="primary"
        w="full"
        mt={[4, 4, 6]}
        type="submit"
        data-testid="manual-sign-up-button"
        id="manual-sign-up-button"
      >
        {signUpButton}
      </Button>
    </form>
  );
}

export function ManualWithSSOSignUp({
  email,
  errorMessage,
  firstName,
  googleSignUpInstruction,
  isEmailInputDisabled = false,
  isFirstNameInputDisabled = false,
  isLastNameInputDisabled = false,
  lastName,
  onEmailSignSubmitted,
  onGoogleLoginClicked,
  showLoadingForEmailSignUp = false,
  shouldHideGoogleSSO,
  termsCopy = <SignUpDefaultTermsCopy />,
  googleSignUpButton,
  signUpButton,
}: SignUpProps) {
  return (
    <Box>
      <ManualSignUpForm
        {...{
          email,
          errorMessage,
          firstName,
          onEmailSignSubmitted,
          showLoadingForEmailSignUp,
          isEmailInputDisabled,
          isFirstNameInputDisabled,
          isLastNameInputDisabled,
          lastName,
          signUpButton,
        }}
      />
      {!shouldHideGoogleSSO && (
        <>
          <SignUpDivider my={6} />
          <GoogleSignUpSSO
            {...{
              onGoogleLoginClicked,
              googleSignUpInstruction,
              googleSignUpButton,
            }}
          />
        </>
      )}
      <SignUpTerms mt={6}>{termsCopy || <SignUpDefaultTermsCopy />}</SignUpTerms>
    </Box>
  );
}

export const SignUp = {
  ManualSignUpForm,
  SignUpTerms,
  SignUpDivider,
  GoogleSignUpSSO,
  ManualWithSSOSignUp,
};
