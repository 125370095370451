import type { DynamicSelectCandidateBrowseAggregateQuery } from './Final.types';

export function serializeFinalStepData(data?: DynamicSelectCandidateBrowseAggregateQuery) {
  const keys = Object.keys(data || {});

  if (!data || !keys.length) {
    return {
      hasEnoughMatches: false,
    };
  }

  const mostMatchingAggregateKey = keys.slice(1).reduce((mostMatchingKey, key) => {
    const currentCount = data[key]?.[0]?.total_count || 0;
    const mostCount = data[mostMatchingKey]?.[0]?.total_count || 0;

    if (currentCount > mostCount) {
      return key;
    }

    return mostMatchingKey;
  }, keys[0] as string);

  const matchedData = data[mostMatchingAggregateKey];

  if (!matchedData?.[0]?.total_count) {
    return {
      hasEnoughMatches: false,
    };
  }

  return {
    hasEnoughMatches: matchedData[0].total_count >= 10,
    mostMatchingAggregateIndex: keys.indexOf(mostMatchingAggregateKey),
  };
}
