import { Formik } from 'formik';
import * as Yup from 'yup';

import { Flex, Box, Text, Button, FormControl, FormLabel, Textarea } from '@terminal/design-system';

import { ViewType } from './constants';

const SubmissionSchema = Yup.object().shape({});

export function AdvanceForm({
  candidateName,
  onSubmit,
  onCancel,
  reviewerName,
  loading,
}: {
  candidateName: string;
  loading: boolean;
  onSubmit: (values: any) => void;
  reviewerName: string;
  onCancel: () => void;
}) {
  return (
    <>
      <Box
        flexDirection="column"
        py={2}
        border="1px solid"
        borderColor="ui.success"
        borderRadius="sm"
      >
        <Text
          textAlign="center"
          variant="label"
          color="text.success"
        >{`Advancing ${candidateName}`}</Text>
        <Text textAlign="center" color="text.success">
          Please share your feedback about this candidate.
        </Text>
      </Box>
      <Formik
        initialValues={{
          candidateName,
          reviewerName,
          decision: ViewType.Advance,
          comments: '',
          reasonTooJunior: false,
          reasonMissingSkills: false,
          reasonMissingExperience: false,
          reasonCompensation: false,
          reasonOther: false,
        }}
        validationSchema={SubmissionSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleBlur, handleSubmit }: any) => (
          <>
            {/* Field: comments */}
            <FormControl id="comments" mt={2}>
              <FormLabel htmlFor="comments">Feedback:</FormLabel>
              <Textarea
                id="comments"
                name="comments"
                rows={4}
                placeholder="What did you like about this candidate?"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
              />
            </FormControl>
            <Flex wrap="wrap" justifyContent="space-around" mt={4}>
              <Button onClick={handleSubmit} variant="solid" size="md" disabled={loading}>
                Submit
              </Button>
              <Button variant="outline" size="md" onClick={onCancel} disabled={loading}>
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Formik>
    </>
  );
}
