/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import type { FetchResult } from '@apollo/client';
import styled from '@emotion/styled';
import {
  Box,
  Flex,
  Heading,
  Text,
  Badge,
  Tag,
  Button,
  PreferredIcon,
  ShareIcon,
  UnorderedList,
  ListItem,
  Divider,
  ViewOnIcon,
  ActiveIcon,
  DocumentDisabledIcon,
  Center,
  BriefcaseBoldIcon,
  CheckboxButton,
} from '@terminal/design-system';
import { stripAllHTMLAttributes } from 'global/utils';
import type { DeleteSavedCandidateMutation, InsertSavedCandidateMutation } from 'talent-hub/utils';
import { useTalentHubFlags } from 'talent-hub/utils';
import { Card, CardBody } from 'talent-hub/components';
// ! TAL- This component should import a type from a specific subfeature?
import type { serializeCandidateBrowse } from '../subfeatures/explore-candidates/ExploreCandidates.serializer';

const StyledLink = styled(Link)`
  > div {
    transition: box-shadow 300ms ease-in-out;
  }

  &:hover {
    > div {
      box-shadow: var(--chakra-shadows-md);
    }
  }
`;

export function CandidateCard({
  candidate,
  linkTo,
  handleOnSaveCandidate,
  handleOnRemoveSaveCandidate,
  handleOpenShareWithOthers,
  isInLimitedMode,
}: {
  candidate: ReturnType<typeof serializeCandidateBrowse>['candidates'][number];
  linkTo: string;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOpenShareWithOthers?: (candidateInfo: { name: string; id: number }) => void;
  isInLimitedMode?: boolean;
}) {
  const [isSaved, setIsSaved] = useState<boolean>(candidate.isSaved);
  const featureFlags = useTalentHubFlags();

  useEffect(() => {
    setIsSaved(candidate.isSaved);
  }, [candidate.isSaved]);

  const lasNameInitial = candidate.last_name_initial ? candidate.last_name_initial.concat('.') : '';

  return (
    <StyledLink to={linkTo}>
      <Card w="full" h="full" p={4} minH="428px">
        <CardBody>
          <Flex justifyContent="space-between" flexDir={['column', 'column', 'row']}>
            <Flex flexDir="column">
              {featureFlags.isContractorPilotEnabled && !!candidate.employmentType && (
                <Flex alignItems="center">
                  <BriefcaseBoldIcon w={3} h={3} mr={1} color="brand.main" />
                  <Text variant="caption" color="brand.main" fontWeight="bold">
                    {candidate.employmentType}
                  </Text>
                </Flex>
              )}
              <Heading variant="heading-3" noOfLines={1} maxH={7}>
                {candidate.firstName} {isInLimitedMode ? lasNameInitial : candidate.lastName}
              </Heading>
              <Text noOfLines={1} maxH={6}>
                {candidate.currentRole}
              </Text>
              {/* 1.125rem -> 18px */}
              <Text variant="caption" maxH="1.125rem" noOfLines={1}>
                {candidate.yearsExperience}{' '}
                <>{candidate.yearsExperience !== '-' && 'of experience'}</>
              </Text>
              <Text variant="caption" color="text.secondary" noOfLines={1} maxH="1.125rem">
                {candidate.city ? `${candidate.city}, ` : null} {candidate?.country_choice?.name}
              </Text>
            </Flex>
            <Flex gridGap={2} width={['full', 'full', 'auto']} ml={[0, 0, 6]}>
              <CheckboxButton
                maxH={['full', 'full', 12, 12]}
                maxW={['full', 'full', 12, 12]}
                isChecked={isSaved}
                onClick={async (e) => {
                  e.preventDefault();
                  if (isSaved) {
                    const { data } = await handleOnRemoveSaveCandidate(candidate.id);
                    if (data?.delete_saved_candidate?.returning[0]?.candidate_id) {
                      setIsSaved((prev) => !prev);
                    }
                  } else {
                    const { data } = await handleOnSaveCandidate(candidate.id);
                    if (data?.insert_saved_candidate_one?.candidate_id) {
                      setIsSaved((prev) => !prev);
                    }
                  }
                }}
                minW="auto"
                w={['inherit', 'inherit', 'auto']}
                h="fit-content"
                lineHeight={0}
                mt={[2, 2, 0]}
                p={4}
                checkedIcon={
                  <PreferredIcon
                    sx={{
                      '& path': {
                        fill: '#EFD58B',
                        stroke: 'text.primary',
                      },
                    }}
                  />
                }
                unCheckedIcon={<PreferredIcon />}
              />
              {handleOpenShareWithOthers && (
                <Button
                  maxH={['full', 'full', 12, 12]}
                  maxW={['full', 'full', 12, 12]}
                  mt={[2, 2, 0]}
                  variant="outline"
                  p={4}
                  minW="auto"
                  w={['inherit', 'inherit', 'auto']}
                  h="fit-content"
                  lineHeight={0}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenShareWithOthers({ id: candidate.id, name: candidate.fullName });
                  }}
                >
                  <ShareIcon />
                </Button>
              )}
            </Flex>
          </Flex>
          {!!candidate.badges?.length && (
            // 1.3128rem -> 21px
            <Flex mt={4} gridGap={2} flexWrap="wrap">
              {candidate.badges.map(
                (el: any) =>
                  el && (
                    <Badge key={el.text} colorScheme={el.variant} px={2} whiteSpace="initial">
                      {el.text}
                    </Badge>
                  ),
              )}
            </Flex>
          )}
          <Box mt={4} p={3} bg="ui.inverse.secondary" h="full" overflow="hidden" noOfLines={7}>
            {candidate.highlights.length > 0 ? (
              // 0.875rem -> 14px , 10.375rem -> 166px
              <UnorderedList ml="0.875rem" maxH="10.375rem">
                {candidate.highlights.map(
                  (el: any) =>
                    el && (
                      <ListItem key={el}>
                        <Text
                          dangerouslySetInnerHTML={{ __html: stripAllHTMLAttributes(el) }}
                          noOfLines={1}
                        />
                      </ListItem>
                    ),
                )}
              </UnorderedList>
            ) : (
              <Center flexDir="column" minH="10.375rem" p={4}>
                <DocumentDisabledIcon w={16} h={16} color="bg.tertiary" />
                <Text pt={2} variant="caption" fontWeight="bold" color="text.secondary">
                  Highlights Coming Soon
                </Text>
                <Text variant="caption" color="text.disabled">
                  Click on this candidate&apos;s card to see the full profile
                </Text>
              </Center>
            )}
          </Box>
          <Flex mt={4} gridGap={2} alignItems="end" flex={1} maxH={6}>
            <Flex gridGap={1} overflow="hidden" flexWrap="wrap" height="full">
              {candidate.skills.map(({ id, name, isSelected }: any) => (
                <Tag
                  px={2}
                  size="sm"
                  variant="subtle"
                  colorScheme={isSelected ? 'accent-lightest' : 'disabled'}
                  key={id}
                  h="full"
                >
                  {name}
                </Tag>
              ))}
            </Flex>
            {candidate.remainingSkills && (
              <Text variant="caption" minW="5.625rem" height="full">
                + {candidate.remainingSkills} more skill
                {candidate.remainingSkills > 1 ? 's' : ''}
              </Text>
            )}
          </Flex>
          <Divider mt={3} />
          {/* 1.125rem -> 18px */}
          <Flex justifyContent="flex-end" alignItems="center" mt={3} maxH="1.125rem">
            {candidate.activeMessage.isActivelyInterviewing ? (
              <ViewOnIcon w={4} h={4} color="text.secondary" />
            ) : (
              <ActiveIcon w={4} h={4} color="text.secondary" />
            )}
            <Text
              ml={1}
              variant="caption"
              color="text.secondary"
              dangerouslySetInnerHTML={{
                __html: stripAllHTMLAttributes(candidate.activeMessage.text),
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </StyledLink>
  );
}
