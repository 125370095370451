import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  Badge,
  Flex,
  Skeleton,
  Divider,
  Accordion,
  List,
  ListItem,
  SearchUserIcon,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader } from 'talent-hub/components';
import { roleTabs } from 'talent-hub/shared/features/roles/components';
import { NewDashboardCandidateCarousel } from 'talent-hub/shared/features/dashboard';
import { ExpandIcon } from '../components';

export function DashboardActiveRole({
  name,
  totalActiveCandidates,
  openHeadCount,
  totalHired,
  totalCandidates,
  id,
  candidates_to_review_per_role,
  currentRoleCandidateMatches,
  view_all_candidate_matches_link,
  handleCandidateClick,
  is_loading_candidate_matches_by_role,
}: {
  name: string;
  totalActiveCandidates: number;
  openHeadCount: number;
  totalHired: number;
  totalCandidates: number;
  id: string;
  candidates_to_review_per_role: {
    id: number;
    name: string;
    status: string;
    statusText: string | null;
  }[];
  currentRoleCandidateMatches: {
    id: number;
    firstName: string;
    lastName?: string;
    last_name_initial?: string;
    currentRole: string;
    yearsExperience: string;
    city?: string;
    country_choice?: {
      name: string;
    };
    skills: Array<{
      id: number | string;
      name: string;
      isSelected: boolean;
    }>;
    remainingSkills?: number;
  }[];
  handleCandidateClick: (candidate: any) => void;
  view_all_candidate_matches_link: string;
  is_loading_candidate_matches_by_role: boolean;
}) {
  return (
    <Card p={0} h="auto" w="full" gap={0}>
      <CardHeader p={6} bgColor="ui.inverse.secondary" justifyContent="space-between">
        <Flex flexDir="column">
          <Heading variant="heading-3" display="block" mb={1} noOfLines={1}>
            {name}
          </Heading>
          <Flex
            flexWrap="nowrap"
            flexDir={{
              base: 'column',
              sm: 'row',
            }}
          >
            <Box>
              <Text display="inline-block" variant="caption">
                Active Candidates:&ensp;
                <strong>{totalActiveCandidates}</strong>
              </Text>
            </Box>
            <Divider
              height={6}
              orientation="vertical"
              mx={3}
              display={{ base: 'none', sm: 'block' }}
            />
            <Box>
              <Text display="inline-block" variant="caption">
                Open Positions:&ensp;<strong>{openHeadCount}</strong>
              </Text>
            </Box>
            <Divider
              height={6}
              orientation="vertical"
              mx={3}
              display={{ base: 'none', sm: 'block' }}
            />
            <Box>
              <Text display="inline-block" variant="caption">
                Hires Made:&ensp;<strong>{totalHired}</strong>
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <Divider orientation="vertical" mx={3} />
          <Button
            variant="ghost"
            colorScheme="primary"
            to={{
              pathname: `/role/${id}/${roleTabs.overview}`,
              state: { from: 'dashboard' },
            }}
            as={RouterLink}
            rightIcon={<ExpandIcon />}
          >
            View Role
          </Button>
        </Flex>
      </CardHeader>
      <CardBody flexDir="column" p={0} py={4}>
        <Accordion
          px={4}
          py={0}
          arrowColor="brand.main"
          defaultIndex={[...(candidates_to_review_per_role.length ? [0] : [1])]}
          customDivider={<Divider mt={4} mb={4} />}
          motionProps={{
            // * required to avoid the overflow hidden https://github.com/chakra-ui/chakra-ui/blob/1a97c08b5bc159dfa17268a88e3a6a5c4ece339c/packages/components/transition/src/collapse.tsx#L146 - https://github.com/chakra-ui/chakra-ui/issues/2966
            style: { overflow: 'initial' },
          }}
          accordions={[
            {
              parent: (
                <Heading variant="heading-4" display="inline-block" mr={2}>
                  Candidates to Review
                  <Badge
                    ml={2}
                    fontSize="xs"
                    colorScheme={candidates_to_review_per_role.length ? 'warning' : 'disabled'}
                  >
                    {candidates_to_review_per_role.length}
                  </Badge>
                </Heading>
              ),
              children:
                candidates_to_review_per_role.length > 0 ? (
                  <List>
                    {candidates_to_review_per_role.map((candidate, i) => {
                      return (
                        <ListItem
                          key={`candidate-${candidate.id}`}
                          display="flex"
                          alignItems="center"
                          justifyContent="end"
                          p={4}
                          px={10}
                          {...(i === candidates_to_review_per_role.length - 1
                            ? { pb: 0 }
                            : { borderBottom: '1px solid' })}
                          borderColor="ui.secondary"
                          to={{
                            pathname: `/role/${id}/candidate/${candidate.id}`,
                            state: { from: 'dashboard' },
                          }}
                          as={RouterLink}
                        >
                          <Text mr="auto" noOfLines={1}>
                            {candidate.name}
                          </Text>
                          <Badge fontSize="sm" colorScheme={candidate.status} mr={2}>
                            {candidate.statusText || ''}
                          </Badge>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Text py={1} px={8} textAlign="center">
                    {totalCandidates > 0
                      ? "You're all caught up. Enjoy the extra free time!"
                      : "We're working hard to find the best candidates for you! Candidates needing your review will appear here."}
                  </Text>
                ),
            },
            {
              parent: (
                <>
                  <Heading variant="heading-4" mr={2} display="inline-block">
                    {candidates_to_review_per_role.length ? 'More' : ''} Candidate Matches to
                    Explore
                  </Heading>
                  <SearchUserIcon width={6} height={6} />
                </>
              ),
              children: is_loading_candidate_matches_by_role ? (
                <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                  <Skeleton height="full" width="full" />
                  <Skeleton height="full" width="full" />
                  <Skeleton height="full" width="full" />
                </Grid>
              ) : (
                <NewDashboardCandidateCarousel
                  showDisplayEmptyMessage
                  shouldDeferInitialization
                  key={`candidates-carousel-${id}`}
                  candidates={currentRoleCandidateMatches}
                  onItemClick={handleCandidateClick}
                  maxElements={5}
                  shouldDisplayViewAll={currentRoleCandidateMatches.length >= 3}
                  viewAllHref={view_all_candidate_matches_link}
                />
              ),
            },
          ]}
        />
      </CardBody>
    </Card>
  );
}
