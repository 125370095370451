import { Route, Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';

function ExploreCandidatesRedirectPrivateController() {
  const { candidateID } = useParams<{ candidateID: string }>();

  if (!candidateID) return <Redirect to="/" />;

  return <Redirect to={`/trial/signup?candidate=${candidateID}`} />;
}

export function ExploreCandidatesRedirectController() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={[`${path}/candidate/:candidateID`, `${path}/candidate`, path]}>
        <ExploreCandidatesRedirectPrivateController />
      </Route>
    </Switch>
  );
}
