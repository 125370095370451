import type { SelectOrganizationListQuery } from './data';

export function serializeOrganizationsList(
  favoriteOrganizationsIDs: number[],
  data?: SelectOrganizationListQuery,
) {
  if (!data?.organization.length) {
    return [];
  }

  const favoriteOrganizations: (SelectOrganizationListQuery['organization'][number] & {
    isFavorite: boolean;
  })[] = [];
  const otherOrganizations: (SelectOrganizationListQuery['organization'][number] & {
    isFavorite: boolean;
  })[] = [];

  data.organization.forEach((organization) => {
    if (favoriteOrganizationsIDs.includes(organization.id)) {
      favoriteOrganizations.push({ ...organization, isFavorite: true });
      return;
    }
    otherOrganizations.push({ ...organization, isFavorite: false });
  });

  return [...favoriteOrganizations, ...otherOrganizations];
}
