import { AsyncTypeahead, useToast } from '@terminal/design-system';
import Sentry from 'global/sentry';
import usePlacesAutocomplete, { getLatLng, getGeocode } from 'use-places-autocomplete';

// TODO: [CAND-140] replace this with the one in skills

/**
 * A battery included location typeahead input. It passes latitude, longitude, city, state, country
 * after the user selects a location.
 *
 * If onError is not assigned as a prop, on error a toast is displayed to the user and the error
 * is logged in sentry
 *
 * NOTE:
 * Must a add `const isGooglePlaceAPIReady = useImportGooglePlacesScript();` to the container of this
 * component and not render this component until the value of isGooglePlaceAPIReady is "ready". For example,
 * a loading screen can be shown until that value is true.
 */
export type LocationTypeAheadProps = {
  defaultValue: string;
  placeIDToCountryIDMap: Record<string, number>;
  onChange: ({
    latitude,
    longitude,
    city,
    state,
    country,
    country_id,
  }: {
    latitude: number | null;
    longitude: number | null;
    city: string;
    state: string;
    country: string;
    country_id: number | null;
  }) => void;
  onError?: () => void;
} & Pick<
  React.ComponentProps<typeof AsyncTypeahead>,
  'name' | 'onBlur' | 'placeholder' | 'noOptionsMatchedCopy' | 'containerProps' | 'renderBefore'
>;
export function LocationTypeAhead({
  defaultValue,
  placeIDToCountryIDMap = {},
  onChange,
  onError,
  name,
  onBlur,
  placeholder = 'Search Location',
  noOptionsMatchedCopy = 'Address not found',
  containerProps,
  renderBefore,
}: LocationTypeAheadProps) {
  const {
    ready: isGooglePlaceAPIReady,
    value: locationInputValue,
    suggestions: locationSuggestions,
    setValue: setLocationInputValue,
  } = usePlacesAutocomplete({
    defaultValue,
    debounce: 400,
    requestOptions: {
      types: ['(regions)'],
      language: 'en-US',
    },
  });

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  // temporarily added options.length to not show a empty line (UL border) when user starts typing
  // as usePlacesAutocomplete has a limitation where it only gives us loading state after some result
  // have already been shown
  const isLoadingSuggestions =
    locationSuggestions.loading ||
    // (personalInfo.formValues.formattedAddress !== locationInputValue &&
    (defaultValue !== locationInputValue &&
      locationInputValue.length > 0 &&
      locationSuggestions.status !== 'ZERO_RESULTS' &&
      locationSuggestions.data.length === 0);

  const handleLocationSuggestionSelect = async (selectAddress: string) => {
    try {
      const results = await getGeocode({ address: selectAddress, language: 'en-US' });

      const selectedResult: (typeof results)[number] =
        results.find(
          (result) =>
            result.types.includes('country') ||
            result.types.includes('locality') ||
            result.types.includes('administrative_area_level_1'),
        ) || results[0];

      if (!results.length) return;

      const { city, country, state } = selectedResult.address_components.reduce(
        // TODO: [TP-1472] Fix type
        (acc: any, next: any) => {
          if (next.types.includes('locality')) {
            return {
              ...acc,
              city: next.long_name,
            };
          }

          if (next.types.includes('country')) {
            return {
              ...acc,
              country: next.long_name,
            };
          }

          if (next.types.includes('administrative_area_level_1')) {
            return {
              ...acc,
              state: next.long_name,
            };
          }

          return acc;
        },
        {
          city: '',
          state: '',
          country: '',
          formattedAddress: selectedResult.formatted_address,
        },
      );

      const countryResults: (typeof results)[number] =
        results.find((result: (typeof results)[number]) => result.types.includes('country')) ||
        (await getGeocode({ address: country, language: 'en-US' }).then(
          (res) => res.find((result) => result.types.includes('country')) || res[0],
        ));

      const { lat: latitude, lng: longitude } = await getLatLng(selectedResult);

      onChange({
        latitude,
        longitude,
        city,
        state,
        country,
        country_id: placeIDToCountryIDMap[countryResults.place_id] || null,
      });
    } catch (error: unknown) {
      if (onError) {
        onError();
      } else {
        toast({
          description: 'Something went wrong trying to get location suggestions!',
          status: 'error',
        });

        Sentry.captureException(error);
      }
    }
  };

  if (isGooglePlaceAPIReady) {
    return (
      <AsyncTypeahead
        name={name}
        onSelectionChange={(_, address) => handleLocationSuggestionSelect(address)}
        onBlur={onBlur}
        placeholder={placeholder}
        options={locationSuggestions.data.map((suggestion) => suggestion.description)}
        inputValue={locationInputValue}
        fullScreen={{
          title: 'Search Location',
          renderBefore,
        }}
        onInputValueChange={(change: string) => {
          setLocationInputValue(change, true);
          onChange({
            latitude: null,
            longitude: null,
            city: '',
            state: '',
            country: '',
            country_id: null,
          });
        }}
        onInputValueChangeAfterSelection={(change: string) => setLocationInputValue(change, false)}
        isLoading={isLoadingSuggestions}
        showNoResult={locationSuggestions.status === 'ZERO_RESULTS'}
        noOptionsMatchedCopy={noOptionsMatchedCopy}
        containerProps={containerProps}
        renderBefore={renderBefore}
      />
    );
  }

  return null;
}
