import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Heading,
  Button,
  Text,
  ArrowRightIcon,
  ArrowLeftIcon,
  Flex,
  Box,
  Tag,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Skeleton,
  useBreakpointValue,
  Center,
} from '@terminal/design-system';
import { Card, CardBody } from 'talent-hub/components';
import {
  CarouselIconButton,
  CarouselSlide,
  Carousel,
  useCarousel,
} from 'talent-hub/shared/features/explore-candidates/components';
// TODO: This component should has his own types (?)
import type { serializeCandidateBrowse } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { useIntersectionObserver } from 'global/hooks';

/**
 * This component was not refactored; it was simply extracted from client-prospect for reuse.
 */
function CandidateCard({
  candidate,
}: {
  candidate: ReturnType<typeof serializeCandidateBrowse>['candidates'][number];
}) {
  const lasNameInitial = candidate.last_name_initial ? candidate.last_name_initial.concat('.') : '';

  return (
    <Card w="full" h="full" p={4} minH="11.813rem">
      <CardBody justifyContent="space-between">
        <Flex justifyContent="space-between" flexDir={['column', 'column', 'row']}>
          <Flex flexDir="column">
            <Heading variant="heading-3" noOfLines={1} maxH={7}>
              {candidate.firstName} {candidate.lastName || lasNameInitial}
            </Heading>
            <Text noOfLines={1} maxH={6}>
              {candidate.currentRole}
            </Text>
            <Text variant="hint" maxH="1.125rem" noOfLines={1}>
              {candidate.yearsExperience}{' '}
              <span>{candidate.yearsExperience !== '-' && 'of experience'}</span>
            </Text>
            <Text variant="hint" color="text.secondary" noOfLines={1} maxH="1.125rem">
              {candidate.city ? `${candidate.city}, ` : null} {candidate?.country_choice?.name}
            </Text>
          </Flex>
        </Flex>
        <Flex gridGap={2} alignItems="end">
          <Flex gridGap={1} flexWrap="wrap">
            {candidate.skills.map(({ id, name, isSelected }: any) => (
              <Tag
                px={2}
                size="sm"
                variant="subtle"
                colorScheme={isSelected ? 'accent-lightest' : 'disabled'}
                key={id}
                h="full"
              >
                {name}
              </Tag>
            ))}
            {!!candidate.remainingSkills && (
              <Text variant="caption" minW="5.625rem" mt={1}>
                + {candidate.remainingSkills} more skill
                {candidate.remainingSkills > 1 ? 's' : ''}
              </Text>
            )}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}

/**
 * This component was not refactored; it was simply extracted from client-prospect for reuse.
 */
export function calculateSlide({
  length,
  currentIndex,
  direction,
  slidesPerView = 3,
}: {
  length: number;
  currentIndex: number;
  direction: 'next' | 'prev';
  slidesPerView?: number;
}) {
  if (direction === 'next') {
    return currentIndex + slidesPerView < length - slidesPerView
      ? currentIndex + slidesPerView
      : length - slidesPerView;
  }
  if (direction === 'prev') {
    return currentIndex - slidesPerView > 0 ? currentIndex - slidesPerView : 0;
  }
  return 0;
}

// TODO: this duplication was to put a quick fix up. Move this out of shared to
export function NewDashboardCandidateCarousel({
  candidates,
  onItemClick,
  tagLabel,
  maxElements = 5,
  shouldDisplayViewAll = true,
  showDisplayEmptyMessage = false,
  shouldDeferInitialization = false,
  viewAllHref,
}: {
  candidates: {
    id: number;
    firstName: string;
    lastName?: string;
    last_name_initial?: string;
    currentRole: string;
    yearsExperience: string;
    city?: string;
    country_choice?: {
      name: string;
    };
    skills: Array<{
      id: number | string;
      name: string;
      isSelected: boolean;
    }>;
    remainingSkills?: number;
  }[];
  onItemClick: (candidateID: number) => void;
  tagLabel?: string;
  maxElements?: number;
  shouldDisplayViewAll?: boolean;
  showDisplayEmptyMessage?: boolean;
  shouldDeferInitialization?: boolean;
  viewAllHref: React.ComponentProps<typeof RouterLink>['to'];
}) {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
  const slidesPerView = isDesktop ? 3 : 1;
  const [{ isNextDisabled, isPrevDisabled }, setArrowsState] = useState({
    isNextDisabled: false,
    isPrevDisabled: true,
  });
  const maxCandidatesRef = useRef(candidates?.slice?.(0, maxElements) || []);
  const [ref, slider] = useCarousel({
    shouldDelaySizingCalculation: true,
    slides: {
      perView: slidesPerView,
      spacing: 16,
    },
    defaultAnimation: {
      duration: 15,
    },
    rubberband: false,
    drag: !isDesktop,
    loop: false,
    slideChanged: (_slider) => {
      const currentIndex = _slider.track.details.abs;

      setArrowsState({
        isNextDisabled: currentIndex >= _slider.slides.length - slidesPerView,
        isPrevDisabled: currentIndex === 0,
      });
    },
  });

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(containerRef);

  const shouldRenderCarousel =
    shouldDeferInitialization && !slider.current?.size ? entry?.isIntersecting : true;

  return (
    <Box w="full" maxW="100%" ref={setContainerRef} p={4} pb={0}>
      {shouldRenderCarousel ? (
        <Carousel ref={ref} direction="row" width="full">
          {maxCandidatesRef.current.map((candidate) => (
            <CarouselSlide
              key={candidate.id}
              data-index={candidate.id}
              cursor="pointer"
              transition="all 200ms"
              onClick={() => onItemClick(candidate.id)}
            >
              <CandidateCard candidate={candidate} />
            </CarouselSlide>
          ))}
          {shouldDisplayViewAll ? (
            <CarouselSlide transition="all 200ms">
              <Box w="full" h="full" p={4} minH="11.813rem">
                <Flex justifyContent="center" alignItems="center" flexDir="column">
                  <Text variant="caption" pt={5}>
                    Looking for more matches?
                  </Text>
                  <Heading variant="heading-4">Explore our candidates</Heading>
                  <Button
                    as={RouterLink}
                    to={viewAllHref}
                    variant="outline"
                    mt={4}
                    w="full"
                    maxW={64}
                  >
                    See More
                  </Button>
                </Flex>
              </Box>
            </CarouselSlide>
          ) : null}
          {!shouldDisplayViewAll && showDisplayEmptyMessage ? (
            <CarouselSlide transition="all 200ms">
              <Box
                w="full"
                h="full"
                minH="11.813rem"
                border="1px solid"
                borderColor="ui.secondary"
                px={3}
              >
                <Center height="full" flexDir="column" textAlign="center">
                  <Heading variant="heading-3">Want to see more?</Heading>
                  <Text color="text.primary" mt="1">
                    Our global sourcing team is always searching for more talent. We&apos;re working
                    hard to quickly find you more matching candidates.
                  </Text>
                </Center>
              </Box>
            </CarouselSlide>
          ) : null}
        </Carousel>
      ) : null}
      {(tagLabel || maxCandidatesRef.current?.length >= slidesPerView) && (
        <Flex justifyContent="center" pt={6}>
          {maxCandidatesRef.current?.length >= slidesPerView && (
            <Flex columnGap={6}>
              <CarouselIconButton
                onClick={() => {
                  slider.current?.moveToIdx(
                    calculateSlide({
                      length: slider.current.slides.length,
                      currentIndex: slider.current.track.details.abs,
                      direction: 'prev',
                      slidesPerView,
                    }),
                  );
                }}
                icon={<ArrowLeftIcon />}
                aria-label="Previous slide"
                minW={8}
                h={8}
                isDisabled={isPrevDisabled}
              />
              <CarouselIconButton
                onClick={() => {
                  slider.current?.moveToIdx(
                    calculateSlide({
                      length: slider.current.slides.length,
                      currentIndex: slider.current.track.details.abs,
                      direction: 'next',
                      slidesPerView,
                    }),
                  );
                }}
                icon={<ArrowRightIcon />}
                aria-label="Next slide"
                minW={8}
                h={8}
                isDisabled={isNextDisabled}
              />
            </Flex>
          )}
        </Flex>
      )}
    </Box>
  );
}

/**
 * This component was not refactored; it was simply extracted from client-prospect for reuse.
 */
export function CandidateCarousel({
  candidates,
  onItemClick,
  tagLabel,
  maxElements = 5,
  shouldDisplayViewAll = true,
  showDisplayEmptyMessage = false,
  shouldDeferInitialization = false,
  viewAllHref,
}: {
  candidates: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  onItemClick: (candidateID: number) => void;
  tagLabel?: string;
  maxElements?: number;
  shouldDisplayViewAll?: boolean;
  showDisplayEmptyMessage?: boolean;
  shouldDeferInitialization?: boolean;
  viewAllHref: React.ComponentProps<typeof RouterLink>['to'];
}) {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
  const slidesPerView = isDesktop ? 3 : 1;
  const [{ isNextDisabled, isPrevDisabled }, setArrowsState] = useState({
    isNextDisabled: false,
    isPrevDisabled: true,
  });
  const maxCandidatesRef = useRef(candidates?.slice?.(0, maxElements) || []);
  const [ref, slider] = useCarousel({
    shouldDelaySizingCalculation: true,
    slides: {
      perView: slidesPerView,
      spacing: 16,
    },
    defaultAnimation: {
      duration: 15,
    },
    rubberband: false,
    drag: !isDesktop,
    loop: false,
    slideChanged: (_slider) => {
      const currentIndex = _slider.track.details.abs;

      setArrowsState({
        isNextDisabled: currentIndex >= _slider.slides.length - slidesPerView,
        isPrevDisabled: currentIndex === 0,
      });
    },
  });

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(containerRef);

  const shouldRenderCarousel =
    shouldDeferInitialization && !slider.current?.size ? entry?.isIntersecting : true;

  return (
    <Box w="full" maxW="100%" ref={setContainerRef}>
      {(tagLabel || maxCandidatesRef.current?.length >= slidesPerView) && (
        <Flex justifyContent={tagLabel ? 'space-between' : 'flex-end'} py={6}>
          {tagLabel && (
            <Tag variant="solid" colorScheme="warning" size="sm">
              {tagLabel}
            </Tag>
          )}
          {maxCandidatesRef.current?.length >= slidesPerView && (
            <Flex columnGap={6}>
              <CarouselIconButton
                onClick={() => {
                  slider.current?.moveToIdx(
                    calculateSlide({
                      length: slider.current.slides.length,
                      currentIndex: slider.current.track.details.abs,
                      direction: 'prev',
                      slidesPerView,
                    }),
                  );
                }}
                icon={<ArrowLeftIcon />}
                aria-label="Previous slide"
                minW={8}
                h={8}
                isDisabled={isPrevDisabled}
              />
              <CarouselIconButton
                onClick={() => {
                  slider.current?.moveToIdx(
                    calculateSlide({
                      length: slider.current.slides.length,
                      currentIndex: slider.current.track.details.abs,
                      direction: 'next',
                      slidesPerView,
                    }),
                  );
                }}
                icon={<ArrowRightIcon />}
                aria-label="Next slide"
                minW={8}
                h={8}
                isDisabled={isNextDisabled}
              />
            </Flex>
          )}
        </Flex>
      )}
      {shouldRenderCarousel ? (
        <Carousel ref={ref} direction="row" width="full">
          {maxCandidatesRef.current.map((candidate) => (
            <CarouselSlide
              key={candidate.id}
              data-index={candidate.id}
              cursor="pointer"
              transition="all 200ms"
              onClick={() => onItemClick(candidate.id)}
            >
              <CandidateCard candidate={candidate} />
            </CarouselSlide>
          ))}
          {shouldDisplayViewAll ? (
            <CarouselSlide transition="all 200ms">
              <Box w="full" h="full" p={4} minH="11.813rem">
                <Flex justifyContent="center" alignItems="center" flexDir="column">
                  <Text variant="caption" fontWeight="bold">
                    There are
                  </Text>
                  <Heading variant="heading-3">{`+ ${candidates?.[0]?.totalCount} Matches`}</Heading>
                  <Button
                    as={RouterLink}
                    to={viewAllHref}
                    variant="outline"
                    mt={4}
                    w="full"
                    maxW={64}
                  >
                    View All
                  </Button>
                </Flex>
              </Box>
            </CarouselSlide>
          ) : null}
          {!shouldDisplayViewAll && showDisplayEmptyMessage ? (
            <CarouselSlide transition="all 200ms">
              <Box
                w="full"
                h="full"
                minH="11.813rem"
                border="1px solid"
                borderColor="ui.secondary"
                px={3}
              >
                <Center height="full" flexDir="column" textAlign="center">
                  <Heading variant="heading-3">Want to see more?</Heading>
                  <Text color="text.primary" mt="1">
                    Our global sourcing team is always searching for more talent. We&apos;re working
                    hard to quickly find you more matching candidates.
                  </Text>
                </Center>
              </Box>
            </CarouselSlide>
          ) : null}
        </Carousel>
      ) : null}
    </Box>
  );
}

/**
 * This component was not refactored; it was simply extracted from client-prospect for reuse.
 */
export function OtherGreatCandidates({
  candidatesRecentlyActive,
  candidatesTopCandidateExperience,
  candidateZeroOneExperience,
  candidateTechLead,
  onCandidatePreviewClick,
  displayViewAllAtCount,
}: {
  candidatesRecentlyActive: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidatesTopCandidateExperience: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidateZeroOneExperience: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  candidateTechLead: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  onCandidatePreviewClick: (candidateID: number) => void;
  displayViewAllAtCount: number;
}) {
  return (
    <Card pb={[4, 4, 6]} px={0} pt={0} h="auto" w="full">
      <CardBody flexDir="column" px={6} pt={6}>
        {candidatesRecentlyActive.length > 0 ||
        candidatesTopCandidateExperience.length > 0 ||
        candidateZeroOneExperience.length > 0 ||
        candidateTechLead.length > 0 ? (
          <Tabs isLazy>
            <TabList
              w="full"
              bgColor="bg.primary"
              borderColor="ui.secondary"
              // ! * Required to overwrite color (Due to a bug in chakra ui, the borderColor is not enough.)
              borderTopColor="ui.secondary"
              overflowX={['scroll', 'auto', 'auto']}
              // ! * Required to add the extra space for the active tab border and not create a yaxis scroll bar
              paddingBottom="1px"
            >
              {candidatesRecentlyActive.length > 0 && <Tab whiteSpace="pre">Recently Active</Tab>}
              {candidatesTopCandidateExperience.length > 0 && (
                <Tab whiteSpace="pre">Top Company Experience</Tab>
              )}
              {candidateZeroOneExperience.length > 0 && (
                <Tab whiteSpace="pre">0 to 1 Experience</Tab>
              )}
              {candidateTechLead.length > 0 && <Tab whiteSpace="pre">Tech Lead</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                {candidatesRecentlyActive.length > 0 && (
                  <CandidateCarousel
                    maxElements={2}
                    candidates={candidatesRecentlyActive}
                    onItemClick={onCandidatePreviewClick}
                    shouldDisplayViewAll={
                      candidatesRecentlyActive?.[0]?.totalCount >= displayViewAllAtCount
                    }
                    viewAllHref={`/explore-candidates?sort=${encodeURIComponent('Last Active')}`}
                  />
                )}
              </TabPanel>
              {candidatesTopCandidateExperience.length > 0 && (
                <TabPanel>
                  <CandidateCarousel
                    maxElements={2}
                    candidates={candidatesTopCandidateExperience}
                    onItemClick={onCandidatePreviewClick}
                    shouldDisplayViewAll={
                      candidatesTopCandidateExperience?.[0]?.totalCount >= displayViewAllAtCount
                    }
                    viewAllHref="/explore-candidates?badges=top_company_exp"
                  />
                </TabPanel>
              )}
              {candidateZeroOneExperience.length > 0 && (
                <TabPanel>
                  <CandidateCarousel
                    maxElements={2}
                    candidates={candidateZeroOneExperience}
                    onItemClick={onCandidatePreviewClick}
                    shouldDisplayViewAll={
                      candidateZeroOneExperience?.[0]?.totalCount >= displayViewAllAtCount
                    }
                    viewAllHref="/explore-candidates?badges=built_new"
                  />
                </TabPanel>
              )}
              {candidateTechLead.length > 0 && (
                <TabPanel>
                  <CandidateCarousel
                    maxElements={2}
                    candidates={candidateTechLead}
                    onItemClick={onCandidatePreviewClick}
                    shouldDisplayViewAll={
                      candidateTechLead?.[0]?.totalCount >= displayViewAllAtCount
                    }
                    viewAllHref="/explore-candidates?badges=tech_leader"
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        ) : (
          <Skeleton h="11.813rem" mt="4.438rem" />
        )}
      </CardBody>
    </Card>
  );
}
