export const headingTheme = {
  baseStyle: () => ({
    fontFamily: 'heading',
    fontWeight: 'bold',
    color: 'text.primary',
    mt: 0,
    mb: 0,
  }),
  variants: {
    'heading-1': {
      fontSize: ['3xl', '3xl', '4xl'],
      lineHeight: 'short',
    },
    'heading-2': {
      fontSize: '2xl',
      lineHeight: 'short',
    },
    'heading-3': {
      fontSize: 'xl',
      lineHeight: 'base',
    },
    'heading-4': {
      fontSize: 'md',
      lineHeight: 'base',
    },
  },
  defaultProps: {
    variant: 'h1',
    color: 'text.primary',
  },
};
