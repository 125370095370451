import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export { ErrorBoundary } from '@sentry/react';

export const isSentryEnabled = new Set(['dev', 'prod']).has(import.meta.env.REACT_APP_ENVIRONMENT);

// TODO: Fix types
export const init = ({ dsn }: any) => {
  if (isSentryEnabled && dsn) {
    Sentry.init({
      dsn,
      environment: import.meta.env.REACT_APP_ENVIRONMENT,
      release: import.meta.env.REACT_APP_GIT_SHA,
      ignoreErrors: ['Non-Error promise rejection captured'],
      integrations: [new BrowserTracing()], // TODO: TP-1189 Update when upgrading to react-router-dom-v6 https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
      beforeBreadcrumb(breadcrumb, hint: any) {
        if (breadcrumb.category === 'ui.click') {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = `${breadcrumb.message} > ${hint.event.target.innerText}`;
        }

        if (breadcrumb.category === 'fetch' && breadcrumb?.data?.url) {
          const { url } = breadcrumb.data;
          if (
            url.includes('segment') ||
            url.includes('googletagmanager') ||
            url.includes('launchdarkly')
          ) {
            // Do not add these breadcrumbs
            return null;
          }
        }

        return breadcrumb;
      },
      beforeSend(event) {
        // If Sentry is passed something other than an error, it adds this __serialized__ object to
        // extra. We can use these to filter out bad errors coming from third-party code (GoogleAnalytics)
        // and ResizeLoop errors caused by password manager extensions resizing forms.
        // eslint-disable-next-line no-underscore-dangle
        if (event?.extra?.__serialized__ === undefined) {
          return event;
        }
        return null;
      },
    });
  }
};

export const makeConfigureScope = (user: any) => (scope: any) => {
  const { id, name, email } = user;
  scope.setUser({
    id,
    name,
    email,
  });
};

// eslint-disable-next-line import/no-default-export
export default Sentry;
