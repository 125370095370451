import type { ComponentProps } from 'react';
import type { AuthenticationRouterOverwrite } from 'global/auth';
import { useEffect, useState } from 'react';
import {
  useToast,
  useClipboard,
  Text,
  Button,
  Image,
  Box,
  Flex,
  Link,
  Heading,
  Divider,
  MailIcon,
} from '@terminal/design-system';
import { CustomerAuthDefaultPageTemplate } from './CustomerAuthDefaultPageTemplate';
import plant from './assets/plant.svg?url';

function CustomerLogInPage(props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>) {
  return (
    <CustomerAuthDefaultPageTemplate
      {...props}
      headerSubtitle="Sign in to your Talent Hub account to continue."
      headerTitle="Welcome Back!"
    />
  );
}
function CustomerForgotPasswordPage(props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>) {
  const { footerText, ...propsWithoutFooter } = props;
  return (
    <CustomerAuthDefaultPageTemplate
      {...propsWithoutFooter}
      bottomCTALink={{
        text: 'Already have an account?',
        linkText: 'Login',
        href: `/auth/login`,
      }}
      headerSubtitle="Enter your email address to request a password reset. If an account exists, you'll receive an email with additional instructions."
    />
  );
}

function CustomerResetPasswordPage(props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>) {
  return (
    <CustomerAuthDefaultPageTemplate
      {...props}
      bottomCTALink={{
        text: 'Already have an account?',
        linkText: 'Login',
        href: `/auth/login`,
      }}
    />
  );
}

function IncompatibleBrowserErrorPage(
  props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>,
) {
  const toast = useToast({
    status: 'success',
    duration: 4000,
  });

  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const { hasCopied, onCopy } = useClipboard(`${window.location.href}&btm=webview`, 10000000);

  const handleOnActionClick = () => {
    onCopy();
    setIsActionDisabled(true);

    setTimeout(() => {
      setIsActionDisabled(false);
    }, 4000);
  };

  useEffect(() => {
    if (hasCopied && isActionDisabled) {
      toast({
        title: 'Link Copied!',
        description: 'Paste the link on a browser to continue signing up.',
      });
    }
    // toast is a static design system component and doesn't need to be included in dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, isActionDisabled]);

  return (
    <CustomerAuthDefaultPageTemplate {...props}>
      <Flex flexDirection="column" alignItems="center">
        <Image src={plant} alt="Plant" maxH="4xl" mb={7} />

        <Heading variant="heading-2" mb={5}>
          Something went wrong.
        </Heading>
        <Text>
          There was an error accessing this page. Please use the button below to copy the link and
          paste it on your browser to continue signing up.
        </Text>
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={handleOnActionClick}
          mt={6}
          w={64}
          isDisabled={isActionDisabled}
        >
          Copy Link
        </Button>
      </Flex>
    </CustomerAuthDefaultPageTemplate>
  );
}

function CustomerForgotPasswordSuccessPage(
  props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>,
) {
  return (
    <CustomerAuthDefaultPageTemplate
      {...props}
      headerTitle="Forgot Password?"
      bottomCTALink={{
        text: 'Already have an account?',
        linkText: 'Login',
        href: `/auth/login`,
      }}
    />
  );
}
function CustomerPasswordResetExpiredPage(
  props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>,
) {
  return <CustomerAuthDefaultPageTemplate {...props} />;
}

function CustomerEmailNotVerifiedPage(
  props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>,
) {
  const { footerLinkOnClick, footerSecondaryClick, isLoading } = props;

  return (
    <CustomerAuthDefaultPageTemplate {...props} headerTitle="Please verify your email address.">
      <Box>
        <Heading variant="heading-2" mb={5}>
          Check your inbox!
          <MailIcon
            w={6}
            h={6}
            ml={3}
            __css={{
              path: {
                fill: 'ui.lighter.warning',
              },
            }}
          />
        </Heading>
        <Text textAlign="left">
          You will need to verify your email address before you can proceed. Please check your inbox
          for a verification email from Terminal.
          <br />
          <br />
          If you haven&apos;t received an email in a few minutes, you can return to this screen and
          request a new link.
        </Text>
        <Divider py={4} />
        <Flex flexDirection={['column', 'row']} alignItems="center" pt={6}>
          <Button
            variant="ghost"
            colorScheme="primary"
            mb={2}
            w="full"
            onClick={footerLinkOnClick}
            isLoading={isLoading}
          >
            Request A New Link
          </Button>
          <Button
            variant="outline"
            colorScheme="primary"
            mb={2}
            w="full"
            onClick={footerSecondaryClick}
          >
            Logout
          </Button>
        </Flex>
      </Box>
    </CustomerAuthDefaultPageTemplate>
  );
}

function CustomerEmailNotVerifiedPageSuccess(
  props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>,
) {
  return (
    <CustomerAuthDefaultPageTemplate {...props} headerTitle="Please verify your email address.">
      <Text>
        A verification email has been sent to your email account, please check your inbox to verify.
        If you have any problems, please{' '}
        <Link href="https://help.terminal.io/" isExternal display="inline">
          contact us.
        </Link>
      </Text>
    </CustomerAuthDefaultPageTemplate>
  );
}

function CustomerNoAccessPage(props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>) {
  return <CustomerAuthDefaultPageTemplate {...props} />;
}
function CustomerSignUp(props: ComponentProps<typeof CustomerAuthDefaultPageTemplate>) {
  const { footerText, ...propsWithoutFooter } = props;

  return (
    <CustomerAuthDefaultPageTemplate
      {...propsWithoutFooter}
      bottomCTALink={{
        text: 'Already have an account?',
        linkText: 'Login',
        href: `/auth/login`,
      }}
      headerTitle="Claim Your Account"
      headerSubtitle="Claim your  account to manage your job postings, hires and much more."
    />
  );
}

export const customerAuthPageTemplateOverwrite: AuthenticationRouterOverwrite['pageTemplate'] = {
  default: CustomerAuthDefaultPageTemplate,
  login: CustomerLogInPage,
  signup: CustomerSignUp,
  'incompatible-browser': IncompatibleBrowserErrorPage,
  'forgot-password': CustomerForgotPasswordPage,
  'forgot-password-success': CustomerForgotPasswordSuccessPage,
  'password-reset': CustomerResetPasswordPage,
  'password-reset-expired': CustomerPasswordResetExpiredPage,
  'email-not-verified': CustomerEmailNotVerifiedPage,
  'email-not-verified-success': CustomerEmailNotVerifiedPageSuccess,
  'no-access': CustomerNoAccessPage,
};
