import { Flex, Link } from '@terminal/design-system';
import { Logo as TerminalLogo } from 'global/components/Logo';
import { useIsViewPortDesktop } from 'global/utils';
import type { AuthPageTemplateProps } from '../types';
import { AuthFormCard } from './AuthFormCard';
import { AuthPageBackground } from './AuthPageBackground';
import { AuthPageContainer } from './AuthPageContainer';
import { AuthContentWrapper } from './AuthContentWrapper';
import { AuthPageHeader } from './AuthPageHeader';

export function AuthPageTemplate({
  children,
  footerLinkOnClick,
  footerText,
  headerSubtitle,
  headerTitle,
  footerLinkLabel,
}: AuthPageTemplateProps) {
  const isDesktop = useIsViewPortDesktop();
  return (
    <AuthPageBackground>
      <AuthPageContainer>
        <Flex justifyContent="center" height={isDesktop ? 100 : 70}>
          <TerminalLogo />
        </Flex>

        <AuthContentWrapper>
          {!!headerTitle && (
            <AuthPageHeader title={headerTitle} subtitle={headerSubtitle} mt={[2, 4]} />
          )}
          <AuthFormCard
            footerText={footerText}
            footerLink={
              <Link fontWeight="bold" onClick={footerLinkOnClick}>
                {footerLinkLabel}
              </Link>
            }
          >
            {children}
          </AuthFormCard>
        </AuthContentWrapper>
      </AuthPageContainer>
    </AuthPageBackground>
  );
}
