export enum CandidateStageStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Skipped = 'Skipped',
  RejectedAt = 'RejectedAt',
  Pending = 'Pending',
  NotApplicable = 'NotApplicable',
}

export enum CandidateStageName {
  Screening = 'Screening',
  Submission = 'Submission',
  Interview = 'Interview',
  Offer = 'Offer',
  Hired = 'Hired',
}

export const ICIMS_Job_Folder = {
  Approved: 'Approved',
  Filled: 'Filled',
} as const;

export const Workflow_Status = {
  screening: {
    'New Applicant - Mobile Portal': 'New Applicant - Mobile Portal',
    'New Applicant - External Portal': 'New Applicant - External Portal',
    'New Applicant - Agency': 'New Applicant - Agency',
    'Incomplete - Incomplete': 'Incomplete - Incomplete',
    'Terminal Screen - Terminal Review': 'Terminal Screen - Terminal Review',
    'Terminal Screen - Terminal Screen Scheduled': 'Terminal Screen - Terminal Screen Scheduled',
    'Terminal Screen - Terminal Feedback Pending': 'Terminal Screen - Terminal Feedback Pending',
    'Terminal Screen - Terminal Screen Completed': 'Terminal Screen - Terminal Screen Completed',
    'Terminal Review - Intend to Submit': 'Terminal Review - Intend to Submit',
    'Terminal Screen - No Show to Screening': 'Terminal Screen - No Show to Screening',
    'Terminal Review - Terminal Decision': 'Terminal Review - Terminal Decision',
    'Internal Review - Candidate Screen': 'Internal Review - Candidate Screen',
    'Internal Review - Resume Review': 'Internal Review - Resume Review',
    'Internal Review - Interview Completed': 'Internal Review - Interview Completed',
    'Internal Review - Interview Scheduled': 'Internal Review - Interview Scheduled',
    'Internal Review - Interview to be Scheduled': 'Internal Review - Interview to be Scheduled',
    'Internal Review - Reference Check Completed': 'Internal Review - Reference Check Completed',
    // 'Submitted to Talent': 'Submitted to Talent', // TODO: find the compplete status
  },
  submission: {
    /**
     * This is how the candidat moves from Screening to Submission
     */
    'Submitted to Client - Submitted': 'Submitted to Client - Submitted',
    'Submitted to Client - Client Portal Advance': 'Submitted to Client - Client Portal Advance',
    'Submitted - Skipped': 'Submitted - Skipped',
    'Submitted - Awaiting Feedback': 'Submitted - Awaiting Feedback',
    'Submitted - Not Applicable': 'Submitted - Not Applicable',
  },

  interview: {
    'Submitted to Client - Client Portal Advance': 'Submitted to Client - Client Portal Advance',

    'Client Review - Interview to be Scheduled': 'Client Review - Interview to be Scheduled',
    'Client Review - Interview #1 to be Scheduled': 'Client Review - Interview #1 to be Scheduled',
    'Client Review - Interview #2 to be Scheduled': 'Client Review - Interview #2 to be Scheduled',
    'Client Review - Interview #3 to be Scheduled': 'Client Review - Interview #3 to be Scheduled',
    'Client Review - Interview #4 to be Scheduled': 'Client Review - Interview #4 to be Scheduled',

    'Client Review - Interview Scheduled': 'Client Review - Interview Scheduled',
    'Client Review - Interview #1 - Initial Interview':
      'Client Review - Interview #1 - Initial Interview',
    'Client Review - Interview #2 - Technical Interview / Assessment':
      'Client Review - Interview #2 - Technical Interview / Assessment',
    'Client Review - Interview #3 - Final interview':
      'Client Review - Interview #3 - Final interview',
    'Client Review - Interview #4 - Additional Interview':
      'Client Review - Interview #4 - Additional Interview',
    'Client Review - Interview #1': 'Client Review - Interview #1',
    'Client Review - Interview #2': 'Client Review - Interview #2',
    'Client Review - Interview #3': 'Client Review - Interview #3',
    'Client Review - Interview #4': 'Client Review - Interview #4',

    'Client Review - Interview Completed': 'Client Review - Interview Completed',
    'Client Review - Awaiting Client Feedback': 'Client Review - Awaiting Client Feedback',
    'Client Review - Final Interview Completed': 'Client Review - Final Interview Completed',
  },
  offer: {
    'Offer - New Offer': 'Offer - New Offer',
    'Offer - Offer Extended': 'Offer - Offer Extended',
    'Offer - Offer Accepted': 'Offer - Offer Accepted',
    'Offer - Background Check Initiated': 'Offer - Background Check Initiated',
    'Offer - Background Check Completed': 'Offer - Background Check Completed',
  },
  hired: {
    'Hired - Placed': 'Hired - Placed',
  },
  rejected: {
    'Candidate Withdrew - Self-Withdrew (Portal)': 'Candidate Withdrew - Self-Withdrew (Portal)',
    'Rejected - Client Rejected - Candidate Lacks Technical Proficiency':
      'Rejected - Client Rejected - Candidate Lacks Technical Proficiency',
    'Rejected - Client Rejected - Candidate Lacks Experience':
      'Rejected - Client Rejected - Candidate Lacks Experience',
    'Rejected - Client Rejected - Other (Explain)': 'Rejected - Client Rejected - Other (Explain)',
    'Rejected - Terminal Rejected - Role Filled': 'Rejected - Terminal Rejected - Role Filled',
    'Rejected - Client Rejected - Candidate Withdrew':
      'Rejected - Client Rejected - Candidate Withdrew',
    'Rejected - Client Rejected - Client Portal Rejected':
      'Rejected - Client Rejected - Client Portal Rejected',
    'Rejected - Terminal Rejected - Candidate Withdrew':
      'Rejected - Terminal Rejected - Candidate Withdrew',
    'Rejected - Terminal Rejected - Location': 'Rejected - Terminal Rejected - Location',
    'Rejected - Terminal Rejected - Candidate Unresponsive':
      'Rejected - Terminal Rejected - Candidate Unresponsive',
    'Rejected - Client Rejected - Took Another Opportunity':
      'Rejected - Client Rejected - Took Another Opportunity',
    'Rejected - Offer Declined - Offer Declined - Accepted Another Offer':
      'Rejected - Offer Declined - Offer Declined - Accepted Another Offer',
    'Rejected - Client Rejected - English Proficiency':
      'Rejected - Client Rejected - English Proficiency',
    'Rejected - Terminal Rejected - Role No Longer Needed':
      'Rejected - Terminal Rejected - Role No Longer Needed',
    'Rejected - Terminal Rejected - Other (Explain)':
      'Rejected - Terminal Rejected - Other (Explain)',
    'Rejected - Client Rejected - Offer Rescinded': 'Rejected - Client Rejected - Offer Rescinded',
    'Rejected - Client Rejected - Candidate not a Culture Fit':
      'Rejected - Client Rejected - Candidate not a Culture Fit',
    'Rejected - Terminal Rejected - Role / Level': 'Rejected - Terminal Rejected - Role / Level',
    'Rejected - Terminal Rejected - Candidate Lacks Technical Proficiency':
      'Rejected - Terminal Rejected - Candidate Lacks Technical Proficiency',
    'Rejected - Terminal Rejected - Compensation': 'Rejected - Terminal Rejected - Compensation',
    'Rejected - Terminal Rejected - Type of Companies':
      'Rejected - Terminal Rejected - Type of Companies',
    'Rejected - Terminal Rejected - Timing': 'Rejected - Terminal Rejected - Timing',
    'Rejected - Client Rejected - Contractor Background':
      'Rejected - Client Rejected - Contractor Background',
    'Rejected - Offer Declined - Offer Declined - Stay With Current Employer':
      'Rejected - Offer Declined - Offer Declined - Stay With Current Employer',
    'Rejected - Client Rejected - Role Filled': 'Rejected - Client Rejected - Role Filled',
    'Rejected - Terminal Rejected - Candidate not a Culture Fit':
      'Rejected - Terminal Rejected - Candidate not a Culture Fit',
    'Rejected - Terminal Rejected - English Proficiency':
      'Rejected - Terminal Rejected - English Proficiency',
    'Rejected - Client Rejected - Role No Longer Needed':
      'Rejected - Client Rejected - Role No Longer Needed',
    'Rejected - Terminal Rejected - Candidate Lacks Experience':
      'Rejected - Terminal Rejected - Candidate Lacks Experience',
    'Rejected - Client Rejected - Candidate Unresponsive':
      'Rejected - Client Rejected - Candidate Unresponsive',
    'Rejected - Offer Declined - Offer Declined - General':
      'Rejected - Offer Declined - Offer Declined - General',
    'Rejected - Client Rejected - Compensation': 'Rejected - Client Rejected - Compensation',
    'Rejected - Terminal Rejected - Contractor Background':
      'Rejected - Terminal Rejected - Contractor Background',
    'Rejected - Terminal Rejected - Job Stability': 'Rejected - Terminal Rejected - Job Stability',
    'Rejected - Client Rejected - Requirements Changed':
      'Rejected - Client Rejected - Requirements Changed',
    'Rejected - Terminal Rejected - Candidate Lacks Work Authorization':
      'Rejected - Terminal Rejected - Candidate Lacks Work Authorization',
    'Rejected - Client Rejected - Candidate Lacks Work Authorization':
      'Rejected - Client Rejected - Candidate Lacks Work Authorization',
    'Rejected - Offer Declined - Offer Declined - Personal Reasons':
      'Rejected - Offer Declined - Offer Declined - Personal Reasons',
    'Rejected - Offer Declined - Offer Declined - Compensation':
      'Rejected - Offer Declined - Offer Declined - Compensation',
    'Rejected - Terminal Rejected - Looking for Relocation':
      'Rejected - Terminal Rejected - Looking for Relocation',
    'Rejected - Client Rejected - Job Stability': 'Rejected - Client Rejected - Job Stability',
    'Rejected - Terminal Rejected - Company Size': 'Rejected - Terminal Rejected - Company Size',
    'Rejected - Terminal Rejected - Abandoned': 'Rejected - Terminal Rejected - Abandoned',
    'Rejected - Terminal Rejected - Took Another Opportunity':
      'Rejected - Terminal Rejected - Took Another Opportunity',
    'Rejected - Client Rejected - Gold Medalist': 'Rejected - Client Rejected - Gold Medalist',
    'Rejected - Terminal Rejected - Offer Rescinded':
      'Rejected - Terminal Rejected - Offer Rescinded',
    'Rejected - Terminal Rejected - Not Interested In Role':
      'Rejected - Terminal Rejected - Not Interested In Role',
    'Rejected - Terminal Rejected - Not Interested In Industry':
      'Rejected - Terminal Rejected - Not Interested In Industry',
    'Rejected - Offer Declined - Offer Declined - Title':
      'Rejected - Offer Declined - Offer Declined - Title',
    'Rejected - Terminal Rejected - Gold Medalist': 'Rejected - Terminal Rejected - Gold Medalist',
    'Rejected - Terminal Rejected - Not Open To Relocation':
      'Rejected - Terminal Rejected - Not Open To Relocation',
    'Rejected - Offer Declined - Offer Declined - Benefits':
      'Rejected - Offer Declined - Offer Declined - Benefits',
  },
  ignored: {
    'Withdrawn - Withdrawn by Terminal': 'Withdrawn - Withdrawn by Terminal',
  },
} as const;

export type WorkflowStatus = {
  [K in keyof typeof Workflow_Status]: keyof (typeof Workflow_Status)[K];
}[keyof typeof Workflow_Status];

export enum CandidateInterviewSubStageName {
  ToBeScheduled = 'ToBeScheduled',
  Scheduled = 'Scheduled',
  AwaitingFeedback = 'AwaitingFeedback',
}

export enum CandidateOfferSubStageName {
  BackgroundCheckInitiated = 'BackgroundCheckInitiated',
  BackgroundCheckCompleted = 'BackgroundCheckCompleted',
  OfferExtended = 'OfferExtended',
}

export const CandidateSubStageFriendlyName: { [key: string]: string } = {
  'Submitted to Client - Submitted': 'Candidate Submitted',
  'Submitted – Awaiting Feedback': 'Awaiting Submission Feedback',
  'Submitted to Client - Client Portal Advance': 'Candidate Proceeding to Interview',
  'Client Review - Interview to be Scheduled': 'Interview Scheduling',
  'Client Review - Interview #1 to be Scheduled': 'Interview #1 - Scheduling',
  'Client Review - Interview #2 to be Scheduled': 'Interview #2 - Scheduling',
  'Client Review - Interview #3 to be Scheduled': 'Interview #3 - Scheduling',
  'Client Review - Interview #4 to be Scheduled': 'Interview #4 - Scheduling',
  'Client Review - Interview Scheduled': 'Interview Scheduled',
  'Client Review - Interview #1 - Initial Interview': 'Interview Scheduled',
  'Client Review - Interview #2 - Technical Interview / Assessment': 'Interview Scheduled',
  'Client Review - Interview #3 - Final interview': 'Interview Scheduled',
  'Client Review - Interview #4 - Additional Interview': 'Interview Scheduled',
  'Client Review - Awaiting Client Feedback': 'Awaiting Interview Feedback',
  'Client Review - Final Interview Completed': 'Awaiting Interview Feedback',
  'Client Review - Interview Completed': 'Interview Completed',
  'Client Review - Reference Check Initiated': 'Reference Check Started',
  'Client Review - Reference Check Completed': 'Reference Check Completed',
  'Offer - New Offer': 'New Offer',
  'Offer - Offer Extended': 'Offer Extended',
  'Offer - Offer Accepted': 'Offer Accepted',
  'Offer - Background Check Initiated': 'Background Check Started',
  'Offer - Background Check Completed': 'Background Check Completed',
  'Hired - Placed': 'Hired',
};

export type CandidateStatusGroupMatchings = {
  specifics: string[];
  prefixes: string[];
  buckets: [CandidateOfferSubStageName | CandidateInterviewSubStageName, string[]][];
};

// TODO: since this constant has also contains keys of ignored and rejected and those no
// not stages, maybe we should rename this to CandidateBucketsStatusGroup
export const CandidateStageStatusGroup: {
  Hired: CandidateStatusGroupMatchings;
  Offer: CandidateStatusGroupMatchings;
  Interview: CandidateStatusGroupMatchings;
  Submission: CandidateStatusGroupMatchings;
  Screening: CandidateStatusGroupMatchings;
  /** Not a stage, more of a bucket */
  Rejected: CandidateStatusGroupMatchings;
  /** Not a stage, more of a bucket */
  Ignored: CandidateStatusGroupMatchings;
} = {
  Hired: {
    specifics: ['Offer - Offer Accepted'],
    prefixes: ['Hired', 'Offer - Background Check'],
    buckets: [],
  },
  Offer: {
    specifics: ['Offer - New Offer', 'Offer - Offer Extended'],
    prefixes: [],
    buckets: [
      [CandidateOfferSubStageName.BackgroundCheckInitiated, ['Offer - Background Check Initiated']],
      [CandidateOfferSubStageName.BackgroundCheckCompleted, ['Offer - Background Check Completed']],
      [CandidateOfferSubStageName.OfferExtended, ['Offer - Offer Extended']],
    ],
  },
  Interview: {
    specifics: ['Submitted to Client - Client Portal Advance'],
    prefixes: ['Client Review'],
    buckets: [
      [
        CandidateInterviewSubStageName.ToBeScheduled,
        [
          'Submitted to Client - Client Portal Advance',
          'Client Review - Interview to be Scheduled',
          'Client Review - Interview #1 to be Scheduled',
          'Client Review - Interview #2 to be Scheduled',
          'Client Review - Interview #3 to be Scheduled',
          'Client Review - Interview #4 to be Scheduled',
        ],
      ],
      [
        CandidateInterviewSubStageName.Scheduled,
        [
          'Client Review - Interview Scheduled',
          'Client Review - Interview #1 - Initial Interview',
          'Client Review - Interview #2 - Technical Interview / Assessment',
          'Client Review - Interview #3 - Final interview',
          'Client Review - Interview #4 - Additional Interview',
          'Client Review - Interview #1',
          'Client Review - Interview #2',
          'Client Review - Interview #3',
          'Client Review - Interview #4',
        ],
      ],
      [
        CandidateInterviewSubStageName.AwaitingFeedback,
        [
          'Client Review - Interview Completed',
          'Client Review - Awaiting Client Feedback',
          'Client Review - Final Interview Completed',
        ],
      ],
    ],
  },
  Submission: {
    specifics: ['Submitted to Client - Submitted'],
    prefixes: [],
    buckets: [],
  },
  Screening: {
    specifics: [],
    prefixes: ['New', 'Incomplete', 'Terminal', 'Internal', 'Submitted to Talent'],
    buckets: [],
  },
  Rejected: {
    specifics: ['Candidate Withdrew - Self-Withdrew (Portal)'],
    prefixes: ['Rejected'],
    buckets: [],
  },
  Ignored: {
    specifics: ['Withdrawn - Withdrawn by Terminal'],
    prefixes: [],
    buckets: [],
  },
} as const;
