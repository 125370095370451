import { Flex } from '@terminal/design-system';

export function AuthPageContainer({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDir="column"
      pt={[3, 3, 8]}
      pb={[4, 4, 20]}
      mx={[6, 6, 0]}
      w="full"
      role="main"
      as="main"
    >
      {children}
    </Flex>
  );
}
