import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  ArrowRightIcon,
  Flex,
  GridItem,
  Skeleton,
  Divider,
  SkeletonText,
} from '@terminal/design-system';
import {
  Card,
  CardBody,
  CardHeader,
  PageLayout,
  PageLayoutPaddedBody,
} from 'talent-hub/components';
import { OtherGreatCandidates } from 'talent-hub/shared/features/dashboard';
import { BulbIcon, BriefcaseIcon, LayersIcon, PeopleIcon, PersonIcon } from './components';
import plant from './assets/plant.bg.svg?url';

function DashboardLayout({
  isRecentUser,
  children,
  dashboardTitle,
  widgets,
  isLoadingDashboard: isLoading,
  pageLayoutProps,
  isInLimitedMode = false,
}: {
  isRecentUser: boolean;
  dashboardTitle: string;
  children: React.ReactNode;
  widgets: {
    totalSubmittedCandidates: number;
    totalActiveMembers: number;
    totalOpenRoles: number;
    totalOpenPositions: number;
    totalActiveCandidates: number;
  };
  isLoadingDashboard: boolean;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  // TODO: this is not being used now, but it should be useful to use this page to client-prospects too
  isInLimitedMode?: boolean;
}) {
  return (
    <PageLayout
      headerTitle={`Welcome, ${pageLayoutProps?.user?.first_name || pageLayoutProps?.user?.name}`}
      superTitle="Dashboard"
      {...pageLayoutProps}
    >
      <PageLayoutPaddedBody
        bgColor="bg.primary"
        p={{ base: 4, md: 6, lg: 10 }}
        bgImage={plant}
        backgroundSize="60%"
        minH="100vh"
        backgroundPosition="right bottom"
        backgroundRepeat="no-repeat"
      >
        <Heading variant="heading-2" mb={4}>
          {dashboardTitle}
        </Heading>
        <Grid
          templateColumns={{
            base: '1fr',
            md: '4fr 2fr',
            xl: '5fr 2fr',
          }}
          gap={6}
        >
          <GridItem
            // ! This is required to make slider works inside those Grid and Flex Parents
            minWidth={0}
          >
            {children}
          </GridItem>
          <GridItem
            pl={{ base: 0, lg: 6 }}
            borderLeftColor="ui.secondary"
            borderLeftWidth={{
              base: 'none',
              lg: '1px',
            }}
          >
            <Grid
              templateColumns={{
                base: '1fr',
                sm: '1fr 1fr',
                md: '1fr',
              }}
              gap={{ base: 6, md: 6 }}
              alignItems="center"
            >
              {isInLimitedMode && (
                <>
                  <GridItem
                    px={4}
                    py={3}
                    border="1px solid"
                    borderColor="ui.secondary"
                    bgColor="ui.lightest.info"
                  >
                    <Heading variant="heading-3">Ready to start hiring your dream team?</Heading>
                    <Text variant="body">Schedule a call with our team.</Text>
                    <Button variant="solid" onClick={pageLayoutProps.onUpgradeAccountClick} mt={3}>
                      Schedule Call
                    </Button>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
              {((isRecentUser && !isLoading) || isInLimitedMode) && (
                <>
                  <GridItem
                    px={4}
                    py={3}
                    bgColor="bg.secondary"
                    alignContent="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      w="3rem"
                      h="3rem"
                      borderRadius="3rem"
                      bgColor="accent.lighter"
                      mr={4}
                      sx={{
                        '@media (max-width: 400px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <BulbIcon width={8} height={8} />
                    </Flex>
                    <Box>
                      <Heading variant="heading-2">Did you know?</Heading>
                      <Text variant="label">You can hire 35% faster?</Text>
                      <Button
                        mt={2}
                        p={0}
                        variant="ghost"
                        colorScheme="primary"
                        size="small"
                        fontWeight="bold"
                        textDecoration="none"
                        fontFamily="body"
                        id="learn-about-terminal-hiring"
                      >
                        Learn about Terminal hiring
                        <ArrowRightIcon ml={3} />
                      </Button>
                    </Box>
                  </GridItem>
                  <GridItem
                    px={4}
                    py={3}
                    bgColor="bg.secondary"
                    alignContent="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      w="3rem"
                      h="3rem"
                      borderRadius="3rem"
                      bgColor="accent.lighter"
                      mr={4}
                      sx={{
                        '@media (max-width: 400px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <BulbIcon width={8} height={8} />
                    </Flex>
                    <Box>
                      <Heading variant="heading-2">Did you know?</Heading>
                      <Text variant="label">You can access the top 7% of talent?</Text>
                      <Button
                        mt={2}
                        p={0}
                        variant="ghost"
                        colorScheme="primary"
                        size="small"
                        fontWeight="bold"
                        textDecoration="none"
                        fontFamily="body"
                        id="learn-about-terminal-recruiting"
                      >
                        Learn about Terminal recruiting
                        <ArrowRightIcon ml={3} />
                      </Button>
                    </Box>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
              {!isInLimitedMode && (
                <>
                  <Grid gap={4} templateColumns="1fr 1fr">
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/role"
                    >
                      <Flex pb={2}>
                        <LayersIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalOpenPositions}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Open Positions</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/role"
                    >
                      <Flex pb={2}>
                        <BriefcaseIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalOpenRoles}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Open Roles</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/candidates"
                    >
                      <Flex pb={2}>
                        <PersonIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalActiveCandidates}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Active Candidates</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/members"
                    >
                      <Flex pb={2}>
                        <PeopleIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} noOfLines={1} width={8}>
                          <Heading variant="heading-2">{widgets.totalActiveMembers}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Active Members</Text>
                    </GridItem>
                  </Grid>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                  <GridItem bgColor="bg.primary" px={4} py={3}>
                    <Heading variant="heading-3">Expert guidance and support</Heading>
                    <Text>
                      Resources to help you navigate the complexities of multi-market global hiring
                    </Text>
                    <Button
                      variant="outline"
                      mt={3}
                      colorScheme="primary"
                      to={{
                        pathname:
                          'https://www.notion.so/Terminal-Customer-Hub-be5794a160594eac8bc8043fd94f8365',
                      }}
                      target="_blank"
                      as={RouterLink}
                      w="full"
                    >
                      Terminal Customer Hub
                    </Button>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
            </Grid>
          </GridItem>
        </Grid>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}

export function Dashboard({
  isRecentUser,
  has_more_roles,
  handleCandidateClick,
  active_roles,
  pending_roles,
  widgets,
  isLoadingDashboard,
  isLoading_otherGreatCandidates,
  pageLayoutProps,
  isInLimitedMode = false,
  candidatesRecentlyActive,
  candidatesTopCandidateExperience,
  candidateZeroOneExperience,
  candidateTechLead,
}: {
  has_more_roles: boolean;
  handleCandidateClick: (candidateID: number) => void;
  active_roles: React.ReactNode[];
  pending_roles: React.ReactNode[];
  isLoading_otherGreatCandidates: boolean;
  candidatesRecentlyActive: any[];
  candidatesTopCandidateExperience: any[];
  candidateZeroOneExperience: any[];
  candidateTechLead: any[];
} & Omit<React.ComponentProps<typeof DashboardLayout>, 'dashboardTitle' | 'children'>) {
  if (isLoadingDashboard) {
    return (
      <DashboardLayout
        dashboardTitle="My Roles"
        isRecentUser={isRecentUser}
        widgets={widgets}
        isLoadingDashboard={isLoadingDashboard}
        pageLayoutProps={pageLayoutProps}
        isInLimitedMode={isInLimitedMode}
      >
        <Card p={0} h="auto" w="full" gap={0} mb={8}>
          <CardHeader p={6} bgColor="ui.inverse.secondary" display="block">
            <Skeleton height={8} />
            <Flex maxW="60%" mt={4}>
              <Skeleton w="full" />
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Skeleton w="full" />
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Skeleton w="full" />
            </Flex>
          </CardHeader>
          <CardBody p={4}>
            <SkeletonText noOfLines={4} skeletonHeight={4} />
          </CardBody>
        </Card>
      </DashboardLayout>
    );
  }

  // TODO: rename this should_render_no_open_roles_card
  if (!active_roles.length && !pending_roles.length) {
    return (
      <DashboardLayout
        dashboardTitle="Great Candidates for your Team"
        isRecentUser={isRecentUser}
        widgets={widgets}
        isLoadingDashboard={isLoadingDashboard}
        pageLayoutProps={pageLayoutProps}
        isInLimitedMode={isInLimitedMode}
      >
        {isLoading_otherGreatCandidates ? (
          <SkeletonText noOfLines={4} skeletonHeight={10} />
        ) : (
          <OtherGreatCandidates
            candidatesRecentlyActive={candidatesRecentlyActive}
            candidatesTopCandidateExperience={candidatesTopCandidateExperience}
            candidateZeroOneExperience={candidateZeroOneExperience}
            candidateTechLead={candidateTechLead}
            onCandidatePreviewClick={handleCandidateClick}
            displayViewAllAtCount={3}
          />
        )}

        <Card p={0} h="auto" w="full" gap={0} mt={8}>
          <CardBody flexDir="column" p={6} py={10}>
            <Flex flexDirection="column" alignItems="center">
              <Heading variant="heading-2">No Open Roles</Heading>
              <Text textAlign="center" mt={1}>
                You don&apos;t have any open roles with us at the moment. <br />
                Click below to get started!
              </Text>
              <Button
                {...(isInLimitedMode
                  ? {
                      // ! This is required by a typescript error
                      to: '',
                      onClick: pageLayoutProps.onUpgradeAccountClick,
                    }
                  : {
                      as: RouterLink,
                      to: '/role/create',
                    })}
                variant="solid"
                mt={8}
                colorScheme="primary"
              >
                Open a New Role
              </Button>
            </Flex>
          </CardBody>
        </Card>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      dashboardTitle="My Roles"
      isRecentUser={isRecentUser}
      widgets={widgets}
      isLoadingDashboard={isLoadingDashboard}
      pageLayoutProps={pageLayoutProps}
      isInLimitedMode={isInLimitedMode}
    >
      <>
        <Flex flexDir="column">
          {active_roles.map((active_role, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={8} key={`active_role-${index}`}>
              {active_role}
            </Box>
          ))}
          {pending_roles.map((pending_role, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={8} key={`pending_role-${index}`}>
              {pending_role}
            </Box>
          ))}
          {has_more_roles && (
            <Button
              variant="ghost"
              mt={0}
              mb={8}
              colorScheme="primary"
              to="/role"
              as={RouterLink}
              px={8}
              mx="auto"
            >
              View All Jobs
            </Button>
          )}
        </Flex>
        <Heading variant="heading-2" mb={4}>
          Other Great Candidates
        </Heading>
        {isLoading_otherGreatCandidates ? (
          <SkeletonText noOfLines={4} skeletonHeight={10} />
        ) : (
          <OtherGreatCandidates
            candidatesRecentlyActive={candidatesRecentlyActive}
            candidatesTopCandidateExperience={candidatesTopCandidateExperience}
            candidateZeroOneExperience={candidateZeroOneExperience}
            candidateTechLead={candidateTechLead}
            onCandidatePreviewClick={handleCandidateClick}
            displayViewAllAtCount={3}
          />
        )}
      </>
    </DashboardLayout>
  );
}
