import { useReducer } from 'react';
import type { Reducer } from 'react';
import { firebaseAuth } from 'global/firebaseApp';
import { sendPasswordResetEmail } from 'global/auth/authMethods';

import { asyncReducer, createAsyncInitialState, toAsyncProps } from 'global/utils';
import { useHandleAuthBackendError, useAuthContinueUrl } from '../auth.utils';
import type { AuthenticationRouterOverwrite } from '../types';
import { ForgotPasswordFormPage } from './ForgotPasswordFormPage';
import { ForgotPasswordSuccessPage } from './ForgotPasswordSuccessPage';

export function ForgotPasswordController({
  redirectTo,
  overwrite,
}: {
  redirectTo: (path: string) => void;
  overwrite?: AuthenticationRouterOverwrite;
}) {
  const continueURL = useAuthContinueUrl();
  const [state, dispatch] = useReducer<Reducer<AsyncState<null>, AsyncAction<null>>>(
    asyncReducer,
    createAsyncInitialState(null),
  );

  const { isError, isLoading, isResolved } = toAsyncProps(state);

  useHandleAuthBackendError({
    isError,
    error: state.error,
    errorsWithFriendlyMessage: {
      'auth/user-not-found': 'Your user could not be found',
    },
  });

  const handleResetPasswordSubmit = async (email: string) => {
    try {
      dispatch({ type: 'pending' });

      await sendPasswordResetEmail(firebaseAuth, email, {
        url: continueURL,
      });

      dispatch({ type: 'resolved', data: null });
    } catch (e) {
      dispatch({ type: 'rejected', error: e });
    }
  };

  if (isResolved) {
    return (
      <ForgotPasswordSuccessPage
        onTryAgainClick={() => dispatch({ type: 'idle' })}
        pageTemplateOverwrite={
          overwrite?.pageTemplate?.['forgot-password-success'] || overwrite?.pageTemplate?.default
        }
      />
    );
  }

  return (
    <ForgotPasswordFormPage
      isLoading={isLoading}
      onResetClick={handleResetPasswordSubmit}
      redirectTo={redirectTo}
      pageTemplateOverwrite={
        overwrite?.pageTemplate?.['forgot-password'] || overwrite?.pageTemplate?.default
      }
    />
  );
}
