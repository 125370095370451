import { createIcon } from '@terminal/design-system';

export const StrongYesFaceFeedbackIcon = createIcon({
  displayName: 'StrongYesFaceFeedbackIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M45.208 15.186C46.6619 18.6074 47.2453 22.3357 46.9062 26.0377C46.5671 29.7397 45.316 33.3 43.2648 36.4004C41.2136 39.5007 38.4261 42.0445 35.1516 43.8044C31.8771 45.5643 28.2175 46.4854 24.5 46.4854C20.7825 46.4854 17.123 45.5643 13.8484 43.8044C10.5739 42.0445 7.78643 39.5007 5.7352 36.4004C3.68398 33.3 2.4329 29.7397 2.09381 26.0377C1.75471 22.3357 2.33816 18.6074 3.79201 15.186"
        fill="#CEE9AF"
      />
      <path
        d="M45.208 15.186C46.6619 18.6074 47.2453 22.3357 46.9062 26.0377C46.5671 29.7397 45.316 33.3 43.2648 36.4004C41.2136 39.5007 38.4261 42.0445 35.1516 43.8044C31.8771 45.5643 28.2175 46.4854 24.5 46.4854C20.7825 46.4854 17.123 45.5643 13.8484 43.8044C10.5739 42.0445 7.78643 39.5007 5.7352 36.4004C3.68398 33.3 2.4329 29.7397 2.09381 26.0377C1.75471 22.3357 2.33816 18.6074 3.79201 15.186"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62 8.06199C12.8366 3.85971 18.5469 1.50009 24.5 1.50009C30.4531 1.50009 36.1634 3.85971 40.38 8.06199"
        fill="#CEE9AF"
      />
      <path
        d="M8.62 8.06199C12.8366 3.85971 18.5469 1.50009 24.5 1.50009C30.4531 1.50009 36.1634 3.85971 40.38 8.06199"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.596 30C35.4789 32.2545 33.7541 34.1521 31.6161 35.4787C29.4782 36.8054 27.0121 37.5083 24.496 37.5083C21.9799 37.5083 19.5138 36.8054 17.3759 35.4787C15.2379 34.1521 13.5131 32.2545 12.396 30"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.064 8.50201C19.6837 8.1029 19.2263 7.78516 18.7196 7.56806C18.2128 7.35096 17.6673 7.23901 17.116 7.23901C16.5647 7.23901 16.0192 7.35096 15.5124 7.56806C15.0057 7.78516 14.5483 8.1029 14.168 8.50201L11.642 11.114L9.11599 8.50201C8.73587 8.10295 8.27866 7.78525 7.77208 7.56817C7.26551 7.3511 6.72012 7.23916 6.16899 7.23916C5.61786 7.23916 5.07248 7.3511 4.5659 7.56817C4.05933 7.78525 3.60212 8.10295 3.22199 8.50201C2.43545 9.3214 1.99623 10.4132 1.99623 11.549C1.99623 12.6848 2.43545 13.7766 3.22199 14.596L10.746 22.378C10.8619 22.5003 11.0014 22.5976 11.1562 22.6642C11.3109 22.7307 11.4776 22.765 11.646 22.765C11.8144 22.765 11.9811 22.7307 12.1358 22.6642C12.2906 22.5976 12.4301 22.5003 12.546 22.378L20.064 14.6C20.849 13.779 21.2871 12.6869 21.2871 11.551C21.2871 10.4151 20.849 9.32301 20.064 8.50201Z"
        fill="#EDA596"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.778 8.50201C45.3979 8.10295 44.9407 7.78525 44.4341 7.56817C43.9275 7.3511 43.3821 7.23916 42.831 7.23916C42.2799 7.23916 41.7345 7.3511 41.2279 7.56817C40.7213 7.78525 40.2641 8.10295 39.884 8.50201L37.358 11.114L34.832 8.50201C34.4517 8.1029 33.9943 7.78516 33.4876 7.56806C32.9808 7.35096 32.4353 7.23901 31.884 7.23901C31.3327 7.23901 30.7872 7.35096 30.2804 7.56806C29.7737 7.78516 29.3163 8.1029 28.936 8.50201C28.1522 9.32281 27.7148 10.4141 27.7148 11.549C27.7148 12.6839 28.1522 13.7752 28.936 14.596L36.46 22.378C36.5759 22.5003 36.7154 22.5976 36.8702 22.6642C37.0249 22.7307 37.1916 22.765 37.36 22.765C37.5284 22.765 37.6951 22.7307 37.8498 22.6642C38.0046 22.5976 38.1441 22.5003 38.26 22.378L45.778 14.6C46.5657 13.7804 47.0057 12.6878 47.0057 11.551C47.0057 10.4142 46.5657 9.3216 45.778 8.50201Z"
        fill="#EDA596"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const YesFaceFeedbackIcon = createIcon({
  displayName: 'YesFaceFeedbackIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M7.05801 13.3C8.65333 10.028 11.0718 7.22696 14.0761 5.17152C17.0805 3.1161 20.5676 1.87691 24.1952 1.57552C27.8228 1.27414 31.4666 1.92091 34.769 3.4524C38.0712 4.98388 40.9188 7.34746 43.0322 10.3113C45.1456 13.2752 46.4522 16.7375 46.824 20.3586C47.1958 23.9798 46.62 27.6354 45.1528 30.9668C43.6858 34.2982 41.378 37.1912 38.4558 39.3618C35.5336 41.5322 32.0972 42.906 28.484 43.348"
        fill="#FFE6AD"
      />
      <path
        d="M7.05801 13.3C8.65333 10.028 11.0718 7.22696 14.0761 5.17152C17.0805 3.1161 20.5676 1.87691 24.1952 1.57552C27.8228 1.27414 31.4666 1.92091 34.769 3.4524C38.0712 4.98388 40.9188 7.34746 43.0322 10.3113C45.1456 13.2752 46.4522 16.7375 46.824 20.3586C47.1958 23.9798 46.62 27.6354 45.1528 30.9668C43.6858 34.2982 41.378 37.1912 38.4558 39.3618C35.5336 41.5322 32.0972 42.906 28.484 43.348"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1 19.906C19.6858 19.906 19.35 19.5702 19.35 19.156C19.35 18.7418 19.6858 18.406 20.1 18.406"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M20.1 19.906C20.5142 19.906 20.85 19.5702 20.85 19.156C20.85 18.7418 20.5142 18.406 20.1 18.406"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M40.1 18.906C39.6858 18.906 39.35 18.5702 39.35 18.156C39.35 17.7418 39.6858 17.406 40.1 17.406"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M40.1 18.906C40.5142 18.906 40.85 18.5702 40.85 18.156C40.85 17.7418 40.5142 17.406 40.1 17.406"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M38.214 24.908C38.427 24.905 38.6384 24.9442 38.8364 25.023C39.0344 25.1018 39.2148 25.2188 39.3674 25.3674C39.52 25.516 39.642 25.6932 39.7262 25.889C39.8104 26.0846 39.8552 26.295 39.858 26.508C39.8586 26.643 39.8412 26.7776 39.806 26.908C39.2292 28.9864 37.8592 30.7552 35.9912 31.8332C34.123 32.9114 31.906 33.2126 29.818 32.672C28.4074 32.3282 27.1148 31.612 26.0756 30.598C25.0364 29.5842 24.2884 28.3098 23.91 26.908C23.8582 26.7024 23.8476 26.4886 23.8792 26.279C23.9108 26.0692 23.9836 25.868 24.0936 25.6868C24.2036 25.5056 24.3484 25.348 24.5198 25.2232C24.6912 25.0984 24.8858 25.009 25.092 24.96C25.226 24.9258 25.3636 24.9082 25.502 24.908H38.214Z"
        fill="white"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.058 28.92L3.752 24.936C3.49162 24.1448 3.55602 23.2828 3.93106 22.539C4.30608 21.7954 4.96104 21.231 5.752 20.97C6.54314 20.7096 7.4053 20.774 8.14898 21.149C8.89266 21.524 9.45702 22.179 9.718 22.97L11.024 26.954"
        fill="#FFE6AD"
      />
      <path
        d="M5.058 28.92L3.752 24.936C3.49162 24.1448 3.55602 23.2828 3.93106 22.539C4.30608 21.7954 4.96104 21.231 5.752 20.97C6.54314 20.7096 7.4053 20.774 8.14898 21.149C8.89266 21.524 9.45702 22.179 9.718 22.97L11.024 26.954"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.176 44.8C17.9308 44.5542 18.6296 44.1616 19.2323 43.6448C19.8349 43.1282 20.3296 42.4974 20.688 41.7892C21.0462 41.0808 21.261 40.3086 21.32 39.5168C21.3792 38.7252 21.2812 37.9298 21.032 37.176L17.754 27.274L17.736 27.218C17.543 26.6312 17.125 26.1448 16.5738 25.8658C16.0226 25.5868 15.3832 25.538 14.796 25.73L11.254 26.89L9.806 27.366L7.282 28.2C5.37266 28.8258 3.78992 30.1842 2.8816 31.9764C1.97329 33.7686 1.81374 35.8482 2.438 37.758L3.59 41.276C4.21622 43.187 5.57574 44.771 7.36962 45.6796C9.1635 46.5884 11.2448 46.7476 13.156 46.122L15.678 45.294L17.176 44.8Z"
        fill="#FFE6AD"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.756 30.306L13.21 32.454"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.704 36.19L15.32 38.208"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const NoFaceFeedbackIcon = createIcon({
  displayName: 'NoFaceFeedbackIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M7.1337 37.5046C4.6054 34.2536 3.04038 30.3582 2.61672 26.2618C2.19307 22.1652 2.92778 18.032 4.73728 14.3324C6.54676 10.6329 9.35836 7.51552 12.8522 5.33506C16.3459 3.1546 20.3816 1.9986 24.5 1.9986C28.6184 1.9986 32.654 3.1546 36.1478 5.33506C39.6416 7.51552 42.4532 10.6329 44.2628 14.3324C46.0722 18.032 46.807 22.1652 46.3832 26.2618C45.9596 30.3582 44.3946 34.2536 41.8664 37.5046"
        fill="#F9E1DC"
      />
      <path
        d="M7.1337 37.5046C4.6054 34.2536 3.04038 30.3582 2.61672 26.2618C2.19307 22.1652 2.92778 18.032 4.73728 14.3324C6.54676 10.6329 9.35836 7.51552 12.8522 5.33506C16.3459 3.1546 20.3816 1.9986 24.5 1.9986C28.6184 1.9986 32.654 3.1546 36.1478 5.33506C39.6416 7.51552 42.4532 10.6329 44.2628 14.3324C46.0722 18.032 46.807 22.1652 46.3832 26.2618C45.9596 30.3582 44.3946 34.2536 41.8664 37.5046"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9075 22.7792C16.5025 22.7792 16.1742 22.451 16.1742 22.0458C16.1742 21.6408 16.5025 21.3126 16.9075 21.3126"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M16.9075 22.7792C17.3125 22.7792 17.6409 22.451 17.6409 22.0458C17.6409 21.6408 17.3125 21.3126 16.9075 21.3126"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M33.0408 22.7792C32.6358 22.7792 32.3074 22.451 32.3074 22.0458C32.3074 21.6408 32.6358 21.3126 33.0408 21.3126"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M33.0408 22.7792C33.4458 22.7792 33.7742 22.451 33.7742 22.0458C33.7742 21.6408 33.4458 21.3126 33.0408 21.3126"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M18.8983 11.4194C17.3565 11.0838 15.7463 11.2845 14.334 11.9884C12.9026 12.6533 11.7408 13.7868 11.0409 15.2014"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3422 29.3342C29.2538 28.8718 28.0438 28.7782 26.8972 29.0682C25.7506 29.358 24.7304 30.0154 23.9926 30.9398"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.1076 14.3684C31.6504 14.0556 33.2534 14.2559 34.672 14.9387C36.0906 15.6215 37.247 16.7494 37.965 18.1504"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.814 43.516L15.7029 41.5604L14.8366 37.9876C14.6691 37.2972 14.7697 36.5688 15.1181 35.9496C15.4665 35.3306 16.0368 34.8666 16.7139 34.6514C17.0793 34.5366 17.4642 34.4978 17.8451 34.5372C18.226 34.5768 18.5948 34.6938 18.9288 34.8812C19.2627 35.0686 19.5548 35.3224 19.787 35.627C20.0192 35.9314 20.1866 36.2802 20.2789 36.652L21.0298 39.685L31.7638 35.2948C32.0926 35.1602 32.445 35.0918 32.8002 35.094C33.1556 35.096 33.507 35.1684 33.8342 35.307C34.1614 35.4454 34.4578 35.6474 34.7068 35.901C34.9556 36.1546 35.1516 36.455 35.2838 36.785C35.5398 37.4248 35.5406 38.1384 35.2858 38.7788C35.0312 39.4192 34.5406 39.9374 33.915 40.2266L29.8806 42.0904L30.3246 45.2192C30.3608 45.4782 30.3734 45.7402 30.3618 46.0014"
        fill="#F9E1DC"
      />
      <path
        d="M10.814 43.516L15.7029 41.5604L14.8366 37.9876C14.6691 37.2972 14.7697 36.5688 15.1181 35.9496C15.4665 35.3306 16.0368 34.8666 16.7139 34.6514C17.0793 34.5366 17.4642 34.4978 17.8451 34.5372C18.226 34.5768 18.5948 34.6938 18.9288 34.8812C19.2627 35.0686 19.5548 35.3224 19.787 35.627C20.0192 35.9314 20.1866 36.2802 20.2789 36.652L21.0298 39.685L31.7638 35.2948C32.0926 35.1602 32.445 35.0918 32.8002 35.094C33.1556 35.096 33.507 35.1684 33.8342 35.307C34.1614 35.4454 34.4578 35.6474 34.7068 35.901C34.9556 36.1546 35.1516 36.455 35.2838 36.785C35.5398 37.4248 35.5406 38.1384 35.2858 38.7788C35.0312 39.4192 34.5406 39.9374 33.915 40.2266L29.8806 42.0904L30.3246 45.2192C30.3608 45.4782 30.3734 45.7402 30.3618 46.0014"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const StrongNoFaceFeedbackIcon = createIcon({
  displayName: 'StrongNoFaceFeedbackIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M36.6328 42.619C32.7482 45.2546 28.1364 46.6092 23.4436 46.4932C18.7506 46.377 14.2115 44.796 10.462 41.9714C6.71244 39.1468 3.94028 35.2202 2.53372 30.7416C1.12717 26.2628 1.15668 21.4564 2.61812 16.9953C4.07956 12.5343 6.89974 8.642 10.6837 5.8637C14.4676 3.0854 19.0257 1.56022 23.7198 1.50175C28.4138 1.44327 33.0084 2.85444 36.8604 5.53764C40.7124 8.22082 43.6286 12.0416 45.2008 16.4649"
        fill="#EDA596"
      />
      <path
        d="M36.6328 42.619C32.7482 45.2546 28.1364 46.6092 23.4436 46.4932C18.7506 46.377 14.2115 44.796 10.462 41.9714C6.71244 39.1468 3.94028 35.2202 2.53372 30.7416C1.12717 26.2628 1.15668 21.4564 2.61812 16.9953C4.07956 12.5343 6.89974 8.642 10.6837 5.8637C14.4676 3.0854 19.0257 1.56022 23.7198 1.50175C28.4138 1.44327 33.0084 2.85444 36.8604 5.53764C40.7124 8.22082 43.6286 12.0416 45.2008 16.4649"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4973 22.4968C16.0831 22.4968 15.7473 22.161 15.7473 21.7468C15.7473 21.3326 16.0831 20.9968 16.4973 20.9968"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M16.4973 22.4968C16.9115 22.4968 17.2473 22.161 17.2473 21.7468C17.2473 21.3326 16.9115 20.9968 16.4973 20.9968"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M31.4974 22.4968C31.0832 22.4968 30.7474 22.161 30.7474 21.7468C30.7474 21.3326 31.0832 20.9968 31.4974 20.9968"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M31.4974 22.4968C31.9116 22.4968 32.2474 22.161 32.2474 21.7468C32.2474 21.3326 31.9116 20.9968 31.4974 20.9968"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M31.4534 37.4968C31.678 37.4984 31.9 37.4504 32.104 37.3562C32.3078 37.2622 32.4886 37.1242 32.6332 36.9524C32.7776 36.7806 32.8826 36.579 32.9404 36.362C32.998 36.1448 33.0074 35.9178 32.9674 35.6968C32.5128 33.6568 31.3764 31.8326 29.7456 30.5254C28.115 29.218 26.0874 28.5056 23.9974 28.5056C21.9072 28.5056 19.8797 29.218 18.249 30.5254C16.6183 31.8326 15.4819 33.6568 15.0273 35.6968C14.9874 35.9178 14.9966 36.1448 15.0543 36.362C15.1121 36.579 15.217 36.7806 15.3616 36.9524C15.5061 37.1242 15.6868 37.2622 15.8907 37.3562C16.0946 37.4504 16.3168 37.4984 16.5413 37.4968C18.9024 36.5066 21.437 35.9968 23.9974 35.9968C26.5576 35.9968 29.0922 36.5066 31.4534 37.4968Z"
        fill="white"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4973 16.3289C12.0742 16.6728 13.7212 16.4675 15.1653 15.7469C16.6296 15.0673 17.8178 13.908 18.5333 12.4609"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6652 16.3289C36.0872 16.6495 34.4476 16.445 32.9966 15.7466C31.5458 15.0482 30.3632 13.8943 29.6292 12.4609"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.486 32.4968C46.5492 33.7558 46.1114 34.9886 45.268 35.9256C44.4248 36.8626 43.2446 37.4276 41.986 37.4968C40.7272 37.4276 39.5472 36.8626 38.704 35.9256C37.8606 34.9886 37.4228 33.7558 37.486 32.4968C37.486 29.7348 41.986 22.4968 41.986 22.4968C41.986 22.4968 46.486 29.7348 46.486 32.4968Z"
        fill="#A0CFDA"
        stroke="#27393F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const JobsIcon = createIcon({
  displayName: 'JobsIcon',
  viewBox: '0 0 64 66',
  path: (
    <>
      <rect y=".5" width="64" height="65" rx="32" fill="#A0CFDA" />
      <path
        d="M38.852 25.058h-13.5l.811-4.87a2.25 2.25 0 0 1 2.22-1.88h7.438a2.25 2.25 0 0 1 2.221 1.88l.81 4.87Z"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.603 22.979H18.6a4.5 4.5 0 0 0-4.5 4.5v15.752a4.5 4.5 0 0 0 4.5 4.5h27.002a4.5 4.5 0 0 0 4.5-4.5V27.478a4.5 4.5 0 0 0-4.5-4.5Z"
        fill="#fff"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1 31.98h36.003M32.102 31.98v4.5"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const PeopleIcon = createIcon({
  displayName: 'PeopleIcon',
  viewBox: '0 0 64 66',
  path: (
    <>
      <rect y=".5" width="64" height="65" rx="32" fill="#EFD58B" />
      <path
        d="M28.482 46.243V35.47a1.86 1.86 0 1 1 3.722 0v7.043a1.04 1.04 0 0 0 1.039 1.04h2.353a4.893 4.893 0 0 1 4.894 4.893v3.596a.204.204 0 0 1-.204.203H27.04a.188.188 0 0 1-.138-.076l-.012-.015-.012-.015-3.861-4.64-.04-.047-.047-.038a1.445 1.445 0 0 1-.385-1.764l-.56-.276.56.276a1.445 1.445 0 0 1 2.607.028l.06.127.107.09 1.459 1.209.023.019.024.017a1.023 1.023 0 0 0 1.12.02 1.055 1.055 0 0 0 .536-.917ZM22.886 20.759h-1.658a.618.618 0 0 1 0-1.236h1.658a.618.618 0 0 1 0 1.236ZM24.65 15.887h-.025a.601.601 0 0 1-.33-.098l-1.277-.983-.01-.008-.011-.008a.6.6 0 0 1-.173-.795.618.618 0 0 1 .805-.153l1.394.918a.618.618 0 0 1-.373 1.127ZM39.375 15.886h-.008a.602.602 0 0 1-.528-.3l-.005-.007-.004-.007a.617.617 0 0 1 .145-.81l1.444-.843.011-.007a.619.619 0 0 1 .812.15.601.601 0 0 1-.17.793l-1.354.925h-.001a.601.601 0 0 1-.342.107ZM43.204 19.709a.635.635 0 0 1 .185.436.618.618 0 0 1-.617.614h-1.658a.618.618 0 0 1 0-1.236h1.653c.164.003.32.07.437.186ZM32 21.19h-.003a3.304 3.304 0 0 1-3.316-3.317v-.003A3.319 3.319 0 1 1 32 21.19ZM38.424 29.605l.002.013a.22.22 0 0 1-.214.255H25.789a.22.22 0 0 1-.165-.078l-.474.402.474-.402a.22.22 0 0 1-.05-.177l.002-.012a6.484 6.484 0 0 1 12.848 0ZM17.085 31.249h-.004a2.125 2.125 0 0 1-2.138-2.126v-.004a2.143 2.143 0 0 1 3.653-1.532l.431-.434-.431.434a2.143 2.143 0 0 1-1.51 3.662ZM21.295 37.252l.48.4-.48-.4a.221.221 0 0 1-.168.079h-8.083a.22.22 0 0 1-.216-.254 4.346 4.346 0 0 1 4.257-3.468 4.347 4.347 0 0 1 4.258 3.468.22.22 0 0 1-.048.175ZM46.915 31.249h-.004a2.125 2.125 0 0 1-2.139-2.126v-.004a2.143 2.143 0 1 1 2.143 2.13ZM46.914 33.61a4.346 4.346 0 0 1 4.258 3.467.22.22 0 0 1-.123.233l.266.565-.266-.565a.22.22 0 0 1-.093.02h-8.083a.221.221 0 0 1-.216-.253 4.346 4.346 0 0 1 4.258-3.468Z"
        fill="#fff"
        stroke="#27393F"
        strokeWidth="1.25"
      />
    </>
  ),
});

export const MembersIcon = createIcon({
  displayName: 'MembersIcon',
  viewBox: '0 0 64 66',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <rect y=".5" width="64" height="65" rx="32" fill="#CEE9AF" />
      <g clipPath="url(#members-icon-a)">
        <path
          d="M18.188 24.5a3.187 3.187 0 1 0 6.374 0 3.187 3.187 0 0 0-6.375 0Z"
          fill="#fff"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.375 29.813a5.312 5.312 0 0 0-5.313 5.312v3.188h2.125l1.063 8.5h4.25"
          fill="#fff"
        />
        <path
          d="M21.375 29.813a5.312 5.312 0 0 0-5.313 5.312v3.188h2.125l1.063 8.5h4.25"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.438 24.5a3.187 3.187 0 1 0 6.374 0 3.187 3.187 0 0 0-6.374 0Z"
          fill="#fff"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.625 29.813a5.312 5.312 0 0 1 5.313 5.312v3.188h-2.126l-1.062 8.5H40.5"
          fill="#fff"
        />
        <path
          d="M42.625 29.813a5.312 5.312 0 0 1 5.313 5.312v3.188h-2.126l-1.062 8.5H40.5"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.75 21.313a4.25 4.25 0 1 0 8.5 0 4.25 4.25 0 0 0-8.5 0ZM39.438 35.125a7.438 7.438 0 0 0-14.876 0v3.188h3.188l1.063 10.624h6.375l1.062-10.624h3.188v-3.188Z"
          fill="#fff"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="members-icon-a">
          <path fill="#fff" transform="translate(15 16)" d="M0 0h34v34H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
