import { findLastIndex, head, propOr } from 'ramda';

import {
  CandidateStageStatusGroup,
  CandidateInterviewSubStageName,
  CandidateStageStatus,
  CandidateStageName,
  CandidateSubStageFriendlyName,
} from 'talent-hub/constants';
import type { CandidateStatusGroupMatchings } from 'talent-hub/constants';
import type { CandidateWorkflowStatus } from 'talent-hub/types';
import { compareByObjectsDateDsc } from 'global/utils';
import moment from 'moment';

export const isMatchingStatus =
  (shouldMatchStatuses: CandidateStatusGroupMatchings) =>
  <T extends { title: string }>({ title }: T) => {
    const matchesStatus =
      shouldMatchStatuses.specifics.includes(title) ||
      shouldMatchStatuses.prefixes.some((matchingAgainstPrefix) =>
        title.startsWith(matchingAgainstPrefix),
      );

    return matchesStatus;
  };

type StageName = keyof typeof CandidateStageStatusGroup;

export function find_stage_by_status(status_title: string): StageName | null {
  // explcitly defining the order of the stages in order to prioritize the later stages
  const stage_names: StageName[] = [
    'Hired',
    'Offer',
    'Interview',
    'Submission',
    'Screening',
    'Rejected',
  ];

  return (
    stage_names.find((stage_name) =>
      isMatchingStatus(CandidateStageStatusGroup[stage_name])({ title: status_title }),
    ) || null
  );
}

/**
 * Assumes the candidateStatuses are sorted Asc
 */
function findFirstMatchingStatus(
  shouldMatchStatuses: CandidateStatusGroupMatchings,
  candidateStatuses: Array<CandidateWorkflowStatus>,
) {
  return head(candidateStatuses.filter(isMatchingStatus(shouldMatchStatuses)));
}

/**
 * Assumes the candidateStatuses are sorted Asc
 */
function findLastMatchingStatus(
  shouldMatchStatuses: CandidateStatusGroupMatchings,
  candidateStatuses: Array<CandidateWorkflowStatus>,
) {
  const matchingStatus = candidateStatuses.filter(isMatchingStatus(shouldMatchStatuses));
  return matchingStatus[matchingStatus.length - 1];
}

function findStageCompletionStatus(
  stage: CandidateStageName,
  candidateStatuses: Array<CandidateWorkflowStatus>,
) {
  let completingStatus: CandidateStatusGroupMatchings = {
    specifics: [],
    prefixes: [],
    buckets: [],
  };

  if (stage === CandidateStageName.Offer || stage === CandidateStageName.Hired) {
    completingStatus = CandidateStageStatusGroup.Hired;
  }

  // the lower stages accumulate the upper stages statuses, because when an upper stage status has
  // occurred, it means the lower stages are completed
  if (stage === CandidateStageName.Interview) {
    completingStatus.specifics = [
      ...CandidateStageStatusGroup.Hired.specifics,
      ...CandidateStageStatusGroup.Offer.specifics,
    ];
    completingStatus.prefixes = [
      ...CandidateStageStatusGroup.Hired.prefixes,
      ...CandidateStageStatusGroup.Offer.prefixes,
    ];
  }

  if (stage === CandidateStageName.Submission) {
    completingStatus.specifics = [
      ...CandidateStageStatusGroup.Hired.specifics,
      ...CandidateStageStatusGroup.Offer.specifics,
      ...CandidateStageStatusGroup.Interview.specifics,
    ];
    completingStatus.prefixes = [
      ...CandidateStageStatusGroup.Hired.prefixes,
      ...CandidateStageStatusGroup.Offer.prefixes,
      ...CandidateStageStatusGroup.Interview.prefixes,
    ];
  }

  if (stage === CandidateStageName.Screening) {
    completingStatus.specifics = [
      ...CandidateStageStatusGroup.Hired.specifics,
      ...CandidateStageStatusGroup.Offer.specifics,
      ...CandidateStageStatusGroup.Interview.specifics,
      ...CandidateStageStatusGroup.Submission.specifics,
    ];
    completingStatus.prefixes = [
      ...CandidateStageStatusGroup.Hired.prefixes,
      ...CandidateStageStatusGroup.Offer.prefixes,
      ...CandidateStageStatusGroup.Interview.prefixes,
      ...CandidateStageStatusGroup.Submission.prefixes,
    ];
  }

  return findFirstMatchingStatus(completingStatus, candidateStatuses);
}

function statusToDate(status?: CandidateWorkflowStatus): Date {
  // @ts-ignore
  return propOr(null, ['date'], status);
}

function findStageDates(
  stage: CandidateStageName,
  candidateStatuses: Array<CandidateWorkflowStatus>,
): {
  startDate?: Date | null;
  inProgressDate?: Date | null;
  completionDate?: Date | null;
  rejectionDate?: Date | null;
} {
  const stageFirstStatus = findFirstMatchingStatus(
    CandidateStageStatusGroup[stage],
    candidateStatuses,
  );

  const stageLatestStatus = findLastMatchingStatus(
    CandidateStageStatusGroup[stage],
    candidateStatuses,
  );

  const stageCompletionStatus = findStageCompletionStatus(stage, candidateStatuses);

  return {
    startDate: statusToDate(stageFirstStatus),
    inProgressDate: statusToDate(stageLatestStatus),
    completionDate: statusToDate(stageCompletionStatus),
  };
}

export function hasCandidateHitStage(
  stage: CandidateStageName | 'Ignored' | 'Rejected',
  candidateStatuses: Array<CandidateWorkflowStatus>,
) {
  return candidateStatuses.some(isMatchingStatus(CandidateStageStatusGroup[stage]));
}

function completedOrSkipped(
  stageName: CandidateStageName,
  candidateStatuses: Array<CandidateWorkflowStatus>,
): CandidateStageStatus.Completed | CandidateStageStatus.Skipped {
  return hasCandidateHitStage(stageName, candidateStatuses)
    ? CandidateStageStatus.Completed
    : CandidateStageStatus.Skipped;
}

function findCandidateCurrentSubStage(
  candidateCurrentStage: CandidateStageName,
  candidateCurrentStatus?: CandidateWorkflowStatus,
): CandidateInterviewSubStageName | null {
  if (!candidateCurrentStatus) {
    return null;
  }

  if (candidateCurrentStage === CandidateStageName.Interview) {
    // @ts-ignore
    return CandidateStageStatusGroup.Interview.buckets.reduce((prevResult, next) => {
      const [subStageName, subStageNameStatuses] = next;
      return subStageNameStatuses && subStageNameStatuses.includes(candidateCurrentStatus.title)
        ? subStageName
        : prevResult;
    }, null);
  }

  return null;
}

function findCandidateCurrentStage(
  candidateStatuses: Array<CandidateWorkflowStatus>,
): CandidateStageName {
  // Sometimes a user accidentally gets a status corresponding to Submission, Interview, Offer or Hired.
  // Then a correction screening status will be added to the user status. This if condition is handling this scenario.
  const latestStatus = candidateStatuses && candidateStatuses[candidateStatuses.length - 1];

  if (latestStatus && isMatchingStatus(CandidateStageStatusGroup.Screening)(latestStatus)) {
    return CandidateStageName.Screening;
  }

  if (candidateStatuses.some(isMatchingStatus(CandidateStageStatusGroup.Hired))) {
    return CandidateStageName.Hired;
  }

  if (candidateStatuses.some(isMatchingStatus(CandidateStageStatusGroup.Offer))) {
    return CandidateStageName.Offer;
  }

  if (candidateStatuses.some(isMatchingStatus(CandidateStageStatusGroup.Interview))) {
    return CandidateStageName.Interview;
  }

  if (candidateStatuses.some(isMatchingStatus(CandidateStageStatusGroup.Submission))) {
    return CandidateStageName.Submission;
  }

  return CandidateStageName.Screening;
}

export function toFriendlyCandidateStatusTitleOrRaw(statusRawTitle: string): string {
  return CandidateSubStageFriendlyName[statusRawTitle] || statusRawTitle;
}

function findStageProgressStatuses(
  stageName: CandidateStageName,
  candidateStatuses: CandidateWorkflowStatus[],
): ProgressStatus[] {
  return candidateStatuses
    .filter(isMatchingStatus(CandidateStageStatusGroup[stageName]))
    .map((candidateStatus) => {
      return {
        friendlyTitle: toFriendlyCandidateStatusTitleOrRaw(candidateStatus.title),
        date: moment(candidateStatus.date).format('MMM D, YYYY'),
      };
    })
    .sort(compareByObjectsDateDsc);
}

export type ProgressStatus = {
  friendlyTitle: string;
  date: string;
};

type CandidateStage = {
  startDate?: Date | null;
  inProgressDate?: Date | null;
  completionDate?: Date | null;
  rejectionDate?: Date | null;
  status: CandidateStageStatus;
  progressStatuses: ProgressStatus[];
};

type CandidateStages = {
  screening: CandidateStage;
  submission: CandidateStage;
  interview: CandidateStage;
  offer: CandidateStage;
  hired: CandidateStage;
};

export type CandidateStageInfo = {
  stage: CandidateStages;
  currentStageName: CandidateStageName;
  currentSubStage: CandidateInterviewSubStageName | null;
  currentStatus?: CandidateWorkflowStatus;
  currentFeedbackStatus: string | null;
};

/**
 * Strips the rejection status and any statuses before that when there are some rejection statuses in the candidate
 * statuses and there are statuses after the latest rejection status that are in either one of the submission,
 * interview, offer and hired stages
 */
export function stripStatusesOfCandidatePreviousAttempt(
  candidateStatuses: CandidateWorkflowStatus[],
): CandidateWorkflowStatus[] {
  const lastRejectionStatusesIndex = findLastIndex(
    isMatchingStatus(CandidateStageStatusGroup.Rejected),
    candidateStatuses,
  );

  if (
    lastRejectionStatusesIndex === -1 ||
    candidateStatuses.length === lastRejectionStatusesIndex + 1
  ) {
    return candidateStatuses;
  }

  const candidateStatusesAfterRejection = candidateStatuses.slice(lastRejectionStatusesIndex + 1);
  const candidateCurrentStageAfterRejection = findCandidateCurrentStage(
    candidateStatusesAfterRejection,
  );

  return candidateCurrentStageAfterRejection != null &&
    candidateCurrentStageAfterRejection !== CandidateStageName.Screening
    ? candidateStatusesAfterRejection
    : candidateStatuses;
}

function getCurrentFeedbackStatus(
  candidateStatuses: CandidateWorkflowStatus[],
  candidateCurrentStage: CandidateStageName,
  candidateCurrentSubStage: CandidateInterviewSubStageName | null,
  candidateCurrentStatus?: CandidateWorkflowStatus,
) {
  const fallbackStatus = { title: 'Client Review - Interview #1' };

  if (!candidateCurrentStage || candidateCurrentStage !== CandidateStageName.Interview) {
    return {
      title: null,
    };
  }

  if (
    candidateCurrentSubStage === CandidateInterviewSubStageName.ToBeScheduled ||
    candidateCurrentSubStage === CandidateInterviewSubStageName.AwaitingFeedback
  ) {
    const [, scheduledStatuses] = CandidateStageStatusGroup.Interview.buckets.find(
      ([subStageName]) => subStageName === CandidateInterviewSubStageName.Scheduled,
    )!;
    // use previous scheduled status
    return candidateStatuses.findLast((s) => scheduledStatuses.includes(s.title)) || fallbackStatus;
  }

  if (
    candidateCurrentSubStage === CandidateInterviewSubStageName.Scheduled &&
    candidateCurrentStatus
  ) {
    return candidateCurrentStatus;
  }

  return fallbackStatus;
}

export function createCandidateStagesInfo(
  candidateStatusesUnSorted: CandidateWorkflowStatus[],
): CandidateStageInfo {
  // Since this function requires the statuses to be sorted by date in desc order, even though the JobCandidateProfile query
  // already sort them in that way, as a confidence measure the function sorts the status by date it self.
  const candidateStatuses: CandidateWorkflowStatus[] = stripStatusesOfCandidatePreviousAttempt(
    candidateStatusesUnSorted.sort(compareByObjectsDateDsc),
  );

  const candidateCurrentStage = findCandidateCurrentStage(candidateStatuses);
  const candidateCurrentStatus = findLastMatchingStatus(
    CandidateStageStatusGroup[candidateCurrentStage],
    candidateStatuses,
  );

  const candidateCurrentSubStage = findCandidateCurrentSubStage(
    candidateCurrentStage,
    candidateCurrentStatus,
  );

  // If user is eligible to leave a feedback for this workflow, then this is the workflow status that will be set on the feedback.
  const { title: currentFeedbackStatus } = getCurrentFeedbackStatus(
    candidateStatuses,
    candidateCurrentStage,
    candidateCurrentSubStage,
    candidateCurrentStatus,
  );

  const latestRejectionStatus = findLastMatchingStatus(
    CandidateStageStatusGroup.Rejected,
    candidateStatuses,
  );

  const rejectionDate = statusToDate(latestRejectionStatus);

  const isCandidateRejected = candidateCurrentStatus
    ? latestRejectionStatus != null &&
      new Date(latestRejectionStatus.date).getTime() >
        new Date(candidateCurrentStatus.date).getTime()
    : latestRejectionStatus != null;

  const inProgressOrRejected = !isCandidateRejected
    ? CandidateStageStatus.InProgress
    : CandidateStageStatus.RejectedAt;

  const pendingOrNotApplicable = !isCandidateRejected
    ? CandidateStageStatus.Pending
    : CandidateStageStatus.NotApplicable;

  const rejectedDateOrNull = isCandidateRejected ? rejectionDate : null;

  const stage = {
    // Candidate current stage can never be ApplicantStages.Screening as they only appear as job candidate
    // after they have been submitted to the client (in Submission stage)
    screening: {
      status: completedOrSkipped(
        CandidateStageName.Screening,
        candidateStatuses,
      ) as CandidateStageStatus,
      progressStatuses: [],
      ...findStageDates(CandidateStageName.Screening, candidateStatuses),
    },
    submission: {
      status: CandidateStageStatus.Pending,
      progressStatuses: findStageProgressStatuses(CandidateStageName.Submission, candidateStatuses),
      ...findStageDates(CandidateStageName.Submission, candidateStatuses),
    },
    interview: {
      status: CandidateStageStatus.Pending,
      progressStatuses: findStageProgressStatuses(CandidateStageName.Interview, candidateStatuses),
      ...findStageDates(CandidateStageName.Interview, candidateStatuses),
    },
    offer: {
      status: CandidateStageStatus.Pending,
      progressStatuses: findStageProgressStatuses(CandidateStageName.Offer, candidateStatuses),
      ...findStageDates(CandidateStageName.Offer, candidateStatuses),
    },
    hired: {
      status: pendingOrNotApplicable,
      progressStatuses: findStageProgressStatuses(CandidateStageName.Hired, candidateStatuses),
      ...findStageDates(CandidateStageName.Hired, candidateStatuses),
    },
  };

  // For each stage the candidate is currently at, the { info, status, (potentially) moreInfo } for all the four stages are computed
  // this way of categorization was done to reduce the level complexity of the logic
  if (candidateCurrentStage === CandidateStageName.Screening) {
    stage.screening.rejectionDate = rejectedDateOrNull;
    stage.screening.status = inProgressOrRejected;
    stage.submission.status = pendingOrNotApplicable;
    stage.interview.status = pendingOrNotApplicable;
    stage.offer.status = pendingOrNotApplicable;
    stage.hired.status = pendingOrNotApplicable;
  }

  if (candidateCurrentStage === CandidateStageName.Submission) {
    stage.submission.rejectionDate = rejectedDateOrNull;
    stage.submission.status = inProgressOrRejected;
    stage.interview.status = pendingOrNotApplicable;
    stage.offer.status = pendingOrNotApplicable;
    stage.hired.status = pendingOrNotApplicable;
  }

  if (candidateCurrentStage === CandidateStageName.Interview) {
    stage.interview.rejectionDate = rejectedDateOrNull;
    stage.submission.status = completedOrSkipped(CandidateStageName.Submission, candidateStatuses);
    stage.interview.status = inProgressOrRejected;
    stage.offer.status = pendingOrNotApplicable;
    stage.hired.status = pendingOrNotApplicable;
  }

  if (candidateCurrentStage === CandidateStageName.Offer) {
    stage.offer.rejectionDate = rejectedDateOrNull;
    stage.submission.status = completedOrSkipped(CandidateStageName.Submission, candidateStatuses);
    stage.interview.status = completedOrSkipped(CandidateStageName.Interview, candidateStatuses);
    stage.offer.status = inProgressOrRejected;
    stage.hired.status = pendingOrNotApplicable;
  }

  if (candidateCurrentStage === CandidateStageName.Hired) {
    stage.offer.rejectionDate = null;
    stage.submission.status = completedOrSkipped(CandidateStageName.Submission, candidateStatuses);
    stage.interview.status = completedOrSkipped(CandidateStageName.Interview, candidateStatuses);
    stage.offer.status = completedOrSkipped(CandidateStageName.Offer, candidateStatuses);
    stage.hired.status = CandidateStageStatus.Completed;
  }

  return {
    stage,
    currentStageName: candidateCurrentStage,
    currentSubStage: candidateCurrentSubStage,
    currentStatus: isCandidateRejected ? latestRejectionStatus : candidateCurrentStatus,
    currentFeedbackStatus,
  };
}

type StageBucket<T> = {
  all: T[];
  buckets: {
    [key in keyof typeof CandidateInterviewSubStageName]?: T[];
  };
};

type StageAllBuckets<T> = {
  inProgress: StageBucket<T>;
  completed: StageBucket<T>;
  rejectedAt: StageBucket<T>;
  skipped: StageBucket<T>;
};

export type AllStagesAllBuckets<T> = {
  screening: StageAllBuckets<T>;
  submission: StageAllBuckets<T>;
  interview: StageAllBuckets<T>;
  offer: StageAllBuckets<T>;
  hired: StageAllBuckets<T>;
};

export function createCandidatesStageGraph<T extends CandidateStageInfo>(
  stagedCandidates: T[],
): AllStagesAllBuckets<T> {
  const interview_inProgress_all = stagedCandidates.filter(
    ({ stage }) => stage.interview.status === CandidateStageStatus.InProgress,
  );

  const interview_inProgress_toBeScheduled = interview_inProgress_all.filter(
    ({ currentSubStage }) =>
      currentSubStage && currentSubStage === CandidateInterviewSubStageName.ToBeScheduled,
  );

  const interview_inProgress_scheduled = interview_inProgress_all.filter(
    ({ currentSubStage }) =>
      currentSubStage &&
      (currentSubStage === CandidateInterviewSubStageName.Scheduled ||
        currentSubStage === CandidateInterviewSubStageName.AwaitingFeedback),
  );

  return {
    screening: {
      inProgress: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.screening.status === CandidateStageStatus.InProgress,
        ),
        buckets: {},
      },
      completed: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.screening.status === CandidateStageStatus.Completed,
        ),
        buckets: {},
      },
      rejectedAt: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.screening.status === CandidateStageStatus.RejectedAt,
        ),
        buckets: {},
      },
      skipped: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.screening.status === CandidateStageStatus.Skipped,
        ),
        buckets: {},
      },
    },
    submission: {
      inProgress: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.submission.status === CandidateStageStatus.InProgress,
        ),
        buckets: {},
      },
      completed: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.submission.status === CandidateStageStatus.Completed,
        ),
        buckets: {},
      },
      rejectedAt: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.submission.status === CandidateStageStatus.RejectedAt,
        ),
        buckets: {},
      },
      skipped: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.submission.status === CandidateStageStatus.Skipped,
        ),
        buckets: {},
      },
    },
    interview: {
      inProgress: {
        all: interview_inProgress_all,
        buckets: {
          [CandidateInterviewSubStageName.ToBeScheduled]: interview_inProgress_toBeScheduled,
          [CandidateInterviewSubStageName.Scheduled]: interview_inProgress_scheduled,
        },
      },
      completed: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.interview.status === CandidateStageStatus.Completed,
        ),
        buckets: {},
      },
      rejectedAt: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.interview.status === CandidateStageStatus.RejectedAt,
        ),
        buckets: {},
      },
      skipped: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.interview.status === CandidateStageStatus.Skipped,
        ),
        buckets: {},
      },
    },
    offer: {
      inProgress: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.offer.status === CandidateStageStatus.InProgress,
        ),
        buckets: {},
      },
      completed: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.offer.status === CandidateStageStatus.Completed,
        ),
        buckets: {},
      },
      rejectedAt: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.offer.status === CandidateStageStatus.RejectedAt,
        ),
        buckets: {},
      },
      skipped: {
        all: stagedCandidates.filter(
          ({ stage }) => stage.offer.status === CandidateStageStatus.Skipped,
        ),
        buckets: {},
      },
    },
    hired: {
      inProgress: {
        all: [],
        buckets: {},
      },
      completed: {
        all: stagedCandidates.filter(
          ({ currentStageName }) => currentStageName === CandidateStageName.Hired,
        ),
        buckets: {},
      },
      rejectedAt: {
        all: [],
        buckets: {},
      },
      skipped: {
        all: [],
        buckets: {},
      },
    },
  };
}

export function calculateRoleAggregatesByStageGraphs<T extends CandidateStageInfo>({
  submission,
  interview,
  offer,
  hired,
}: AllStagesAllBuckets<T>): {
  totalCandidates: number;
  totalActiveCandidates: number;
  totalHired: number;
  totalRejectedCandidates: number;
} {
  // TODO: This is the total of submitted candidates. Should be renamed to total_candidate_submission
  const totalCandidates =
    submission.inProgress.all.length +
    submission.completed.all.length +
    submission.rejectedAt.all.length +
    submission.skipped.all.length;

  const totalActiveCandidates =
    submission.inProgress.all.length +
    interview.inProgress.all.length +
    offer.inProgress.all.length;

  const totalRejectedCandidates =
    submission.rejectedAt.all.length +
    interview.rejectedAt.all.length +
    offer.rejectedAt.all.length;

  const totalHired = hired.completed.all.length;

  return {
    totalCandidates,
    totalActiveCandidates,
    totalHired,
    totalRejectedCandidates,
  };
}
