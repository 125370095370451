import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  Link,
  HStack,
  Grid,
  GridItem,
  ClockIcon,
  LinkedinIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
} from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';

import {
  AppSubheader,
  Card,
  CardBody,
  PageLayout,
  PageLayoutPaddedBody,
} from 'talent-hub/components';

import type { Employee } from './data';

export function TerminalDirectory({
  employees,
  loading,
  pageLayoutProps,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  employees: Employee[];
  loading: boolean;
}) {
  if (loading)
    return (
      <PageLayout headerTitle="Your Team at Terminal" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <AppSubheader title="Your Team" />
          <BlankScreenLoading />
        </PageLayoutPaddedBody>
      </PageLayout>
    );
  if (!employees.length)
    return (
      <PageLayout headerTitle="Your Team at Terminal" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <AppSubheader title="Your Team" />
          <Heading variant="heading-2" as="h2">
            No team members to display.
          </Heading>
        </PageLayoutPaddedBody>
      </PageLayout>
    );

  return (
    <PageLayout headerTitle="Your Team at Terminal" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <AppSubheader title="Your Team" />
        <Grid templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6} w="full">
          {employees.map(
            ({
              id,
              name,
              title,
              location,
              phone,
              email,
              linkedin_url,
              calendly_url,
              slack,
              localTime,
            }) => (
              <GridItem key={id}>
                <Card p={6} h="full">
                  <CardBody>
                    <Avatar
                      m={0}
                      className="notranslate"
                      name={name}
                      color="text.inverse"
                      bg="accent.main"
                      size="2xl"
                    />
                    <Heading variant="heading-3" as="h3" mt={2}>
                      {name}
                    </Heading>
                    <Text>{title}</Text>
                    <List pt={6}>
                      <ListItem>
                        <ListIcon as={LocationIcon} verticalAlign="baseline" w={3} h={3} />
                        <Text display="inline-block">{location}</Text>
                      </ListItem>
                      {!!localTime && (
                        <ListItem>
                          <ListIcon as={ClockIcon} verticalAlign="baseline" w={3} h={3} />
                          <Text display="inline-block">
                            Local time:&nbsp;
                            <time dateTime={localTime}>{localTime}</time>
                          </Text>
                        </ListItem>
                      )}
                      {!!phone && (
                        <ListItem>
                          <ListIcon as={PhoneIcon} verticalAlign="baseline" w={3} h={3} />
                          <Text display="inline-block">{phone}</Text>
                        </ListItem>
                      )}
                      {!!email && (
                        <ListItem>
                          <ListIcon as={MailIcon} verticalAlign="baseline" w={3} h={3} />
                          <Link
                            as={RouterLink}
                            display="inline-block"
                            rel="noopener noreferrer"
                            target="_blank"
                            to={{ pathname: `mailto:${email}` }}
                          >
                            {email}
                          </Link>
                        </ListItem>
                      )}
                      {!!linkedin_url && (
                        <ListItem>
                          <ListIcon as={LinkedinIcon} verticalAlign="baseline" w={3} h={3} />
                          <Text display="inline-block">
                            <Link
                              as={RouterLink}
                              display="inline-block"
                              rel="noopener noreferrer"
                              target="_blank"
                              to={{ pathname: linkedin_url }}
                            >
                              LinkedIn Profile
                            </Link>
                          </Text>
                        </ListItem>
                      )}
                    </List>
                    {(slack || calendly_url) && (
                      <HStack pt={6}>
                        {slack && (
                          <Link
                            as={RouterLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            to={{ pathname: slack }}
                          >
                            Slack
                          </Link>
                        )}
                        {calendly_url && (
                          <Link
                            as={RouterLink}
                            rel="noopener noreferrer"
                            target="_blank"
                            to={{ pathname: calendly_url }}
                          >
                            Calendly
                          </Link>
                        )}
                      </HStack>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            ),
          )}
        </Grid>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
