import { createIcon } from '@terminal/design-system';

export const BulbIcon = createIcon({
  displayName: 'BulbIcon',
  viewBox: '0 0 30 31',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path d="M18.078 25.605h-5.67v4.65h5.67v-4.65Z" fill="#25383F" />
      <path
        d="M22.027 15.618A6.784 6.784 0 1 0 9.52 19.256a6.78 6.78 0 0 0 2.847 2.5v4.14h5.752v-4.149a6.78 6.78 0 0 0 3.908-6.129Z"
        fill="#FBDF8F"
      />
      <path
        d="M15.296 25.605v-9.787M17.149 13.78l-1.852 1.852-1.852-1.852"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.166 15.632h4.864M15.296 6.609V1.745M.97 15.632h4.864M22.028 8.834l3.44-3.44M8.461 8.834l-3.44-3.44"
        stroke="#25383F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const BriefcaseIcon = createIcon({
  displayName: 'BriefcaseIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#A0CFDA" />
      <rect x="4.5" y="4" width="24" height="24" rx="12" fill="#A0CFDA" />
      <path
        d="M19.7458 12.3591H13.3506L13.7354 10.127C13.7767 9.88612 13.9051 9.66728 14.0975 9.50938C14.2899 9.35148 14.534 9.26476 14.7863 9.26465H18.3101C18.5626 9.26452 18.8069 9.35113 18.9996 9.50905C19.1922 9.66697 19.3207 9.88595 19.3621 10.127L19.7458 12.3591Z"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9435 11.4062H10.153C8.97566 11.4062 8.02124 12.3299 8.02124 13.4692V20.6897C8.02124 21.829 8.97566 22.7526 10.153 22.7526H22.9435C24.1208 22.7526 25.0752 21.829 25.0752 20.6897V13.4692C25.0752 12.3299 24.1208 11.4062 22.9435 11.4062Z"
        fill="white"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.02124 15.5322H25.0752"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5481 15.5322V17.5952"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const LayersIcon = createIcon({
  displayName: 'LayersIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#A0CFDA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.535 18.9365L6.9924 20.7665C6.81215 20.8598 6.69897 21.0459 6.69897 21.2488C6.69897 21.4518 6.81215 21.6379 6.9924 21.7312L15.9957 26.3885C16.3873 26.5908 16.8527 26.5908 17.2444 26.3885L26.2477 21.7365C26.4279 21.6432 26.5411 21.4571 26.5411 21.2541C26.5411 21.0511 26.4279 20.8651 26.2477 20.7717L22.7051 18.9365"
        fill="#8FA25B"
      />
      <path
        d="M10.535 18.9365L6.9924 20.7665C6.81215 20.8598 6.69897 21.0459 6.69897 21.2488C6.69897 21.4518 6.81215 21.6379 6.9924 21.7312L15.9957 26.3885C16.3873 26.5908 16.8527 26.5908 17.2444 26.3885L26.2477 21.7365C26.4279 21.6432 26.5411 21.4571 26.5411 21.2541C26.5411 21.0511 26.4279 20.8651 26.2477 20.7717L22.7051 18.9365"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.535 14.3066L6.9924 16.1392C6.81215 16.2326 6.69897 16.4186 6.69897 16.6216C6.69897 16.8246 6.81215 17.0107 6.9924 17.104L15.9957 21.7613C16.3873 21.9636 16.8527 21.9636 17.2444 21.7613L26.2477 17.104C26.4279 17.0107 26.5411 16.8246 26.5411 16.6216C26.5411 16.4186 26.4279 16.2326 26.2477 16.1392L22.7051 14.3066"
        fill="#EFD58B"
      />
      <path
        d="M10.535 14.3066L6.9924 16.1392C6.81215 16.2326 6.69897 16.4186 6.69897 16.6216C6.69897 16.8246 6.81215 17.0107 6.9924 17.104L15.9957 21.7613C16.3873 21.9636 16.8527 21.9636 17.2444 21.7613L26.2477 17.104C26.4279 17.0107 26.5411 16.8246 26.5411 16.6216C26.5411 16.4186 26.4279 16.2326 26.2477 16.1392L22.7051 14.3066"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2477 11.5065L17.2444 6.8519C16.8527 6.64963 16.3873 6.64963 15.9957 6.8519L6.9924 11.5065C6.81215 11.5999 6.69897 11.7859 6.69897 11.9889C6.69897 12.1919 6.81215 12.378 6.9924 12.4713L15.9957 17.1286C16.3873 17.3309 16.8527 17.3309 17.2444 17.1286L26.2477 12.4766C26.4279 12.3833 26.5411 12.1972 26.5411 11.9942C26.5411 11.7912 26.4279 11.6052 26.2477 11.5118V11.5065Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const PeopleIcon = createIcon({
  displayName: 'PeopleIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <circle cx="16" cy="16" r="16" fill="#A0CFDA" />
      <g clipPath="url(#clip0_2451_6490)">
        <path
          d="M7.96875 10.25C7.96875 10.7721 8.17617 11.2729 8.54538 11.6421C8.9146 12.0113 9.41536 12.2188 9.9375 12.2188C10.4596 12.2188 10.9604 12.0113 11.3296 11.6421C11.6988 11.2729 11.9062 10.7721 11.9062 10.25C11.9062 9.72786 11.6988 9.2271 11.3296 8.85788C10.9604 8.48867 10.4596 8.28125 9.9375 8.28125C9.41536 8.28125 8.9146 8.48867 8.54538 8.85788C8.17617 9.2271 7.96875 9.72786 7.96875 10.25Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.9375 13.5312C9.06726 13.5312 8.23266 13.877 7.61731 14.4923C7.00195 15.1077 6.65625 15.9423 6.65625 16.8125V18.7812H7.96875L8.625 24.0312H11.25"
          fill="white"
        />
        <path
          d="M9.9375 13.5312C9.06726 13.5312 8.23266 13.877 7.61731 14.4923C7.00195 15.1077 6.65625 15.9423 6.65625 16.8125V18.7812H7.96875L8.625 24.0312H11.25"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0938 10.25C21.0938 10.7721 21.3012 11.2729 21.6704 11.6421C22.0396 12.0113 22.5404 12.2188 23.0625 12.2188C23.5846 12.2188 24.0854 12.0113 24.4546 11.6421C24.8238 11.2729 25.0312 10.7721 25.0312 10.25C25.0312 9.72786 24.8238 9.2271 24.4546 8.85788C24.0854 8.48867 23.5846 8.28125 23.0625 8.28125C22.5404 8.28125 22.0396 8.48867 21.6704 8.85788C21.3012 9.2271 21.0938 9.72786 21.0938 10.25Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0625 13.5312C23.9327 13.5312 24.7673 13.877 25.3827 14.4923C25.998 15.1077 26.3438 15.9423 26.3438 16.8125V18.7812H25.0312L24.375 24.0312H21.75"
          fill="white"
        />
        <path
          d="M23.0625 13.5312C23.9327 13.5312 24.7673 13.877 25.3827 14.4923C25.998 15.1077 26.3438 15.9423 26.3438 16.8125V18.7812H25.0312L24.375 24.0312H21.75"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.875 8.28125C13.875 8.97744 14.1516 9.64512 14.6438 10.1374C15.1361 10.6297 15.8038 10.9062 16.5 10.9062C17.1962 10.9062 17.8639 10.6297 18.3562 10.1374C18.8484 9.64512 19.125 8.97744 19.125 8.28125C19.125 7.58506 18.8484 6.91738 18.3562 6.42509C17.8639 5.93281 17.1962 5.65625 16.5 5.65625C15.8038 5.65625 15.1361 5.93281 14.6438 6.42509C14.1516 6.91738 13.875 7.58506 13.875 8.28125Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0938 16.8125C21.0938 15.5942 20.6098 14.4257 19.7483 13.5642C18.8868 12.7027 17.7183 12.2188 16.5 12.2188C15.2817 12.2188 14.1132 12.7027 13.2517 13.5642C12.3902 14.4257 11.9062 15.5942 11.9062 16.8125V18.7812H13.875L14.5312 25.3438H18.4688L19.125 18.7812H21.0938V16.8125Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2451_6490">
          <rect width="21" height="21" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const PersonIcon = createIcon({
  displayName: 'PersonIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <circle cx="14.5" cy="17.5" r="14" fill="#25383F" stroke="#25383F" />
      <circle cx="16" cy="16" r="14" fill="#A0CFDA" stroke="#25383F" />
      <g clipPath="url(#clip0_2444_4350)">
        <path
          d="M10 9H21L20.5 16L22.5 17.5L18.5 23.5C18.5 23.5 19.5 27.5 19 29.5C18.5 31.5 12.5 31.5 12 29.5C11.5 27.5 12.5 24 12.5 24L8.5 18L10.5 14.5L10 9Z"
          fill="white"
        />
        <path
          d="M13.2967 11.33C13.2967 11.2583 16.6502 14.8058 20.6017 14.9166V16.41C20.7276 16.3315 20.8692 16.2819 21.0164 16.265C21.1637 16.2481 21.3128 16.2642 21.4531 16.3122C21.4531 16.3122 24.2737 7 15.4999 7C6.16713 7 9.54668 16.3122 9.54668 16.3122L10.4176 16.41V14.9753C11.7156 14.0728 12.7176 12.8042 13.2967 11.33Z"
          fill="#25383F"
        />
        <path
          d="M18.7365 23.9287C18.6455 26.5959 18.769 28.6109 19.3084 29.3478"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6914 29.3481C12.2568 27.9787 12.3413 26.0485 12.2698 23.8965"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3292 18.0275C13.6846 18.0275 13.9726 17.7385 13.9726 17.3819C13.9726 17.0254 13.6846 16.7363 13.3292 16.7363C12.9739 16.7363 12.6858 17.0254 12.6858 17.3819C12.6858 17.7385 12.9739 18.0275 13.3292 18.0275Z"
          fill="#25383F"
        />
        <path
          d="M17.6642 18.0275C18.0195 18.0275 18.3076 17.7385 18.3076 17.3819C18.3076 17.0254 18.0195 16.7363 17.6642 16.7363C17.3088 16.7363 17.0208 17.0254 17.0208 17.3819C17.0208 17.7385 17.3088 18.0275 17.6642 18.0275Z"
          fill="#25383F"
        />
        <path
          d="M14.5642 21.2549C14.6828 21.3837 14.8261 21.4872 14.9855 21.5591C15.1449 21.6311 15.3171 21.67 15.4918 21.6737C15.6665 21.6774 15.8402 21.6456 16.0024 21.5804C16.1647 21.5151 16.3121 21.4177 16.436 21.294"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5325 18.3145C15.5325 18.3145 15.9224 19.0057 15.8249 19.2013"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3852 19.7297C10.2358 19.7676 10.0804 19.7753 9.92797 19.7525C9.77557 19.7296 9.62922 19.6766 9.4974 19.5965C9.36559 19.5164 9.25094 19.4109 9.16012 19.286C9.06931 19.1611 9.00413 19.0193 8.96837 18.8689L8.68241 17.7733C8.6368 17.6225 8.6234 17.4636 8.64309 17.3072C8.66278 17.1508 8.71511 17.0003 8.79667 16.8655C8.87824 16.7308 8.9872 16.6148 9.11645 16.5251C9.2457 16.4355 9.39233 16.3742 9.54679 16.3452V16.3452C9.66406 16.3225 9.78509 16.3292 9.89921 16.3645C10.0133 16.3999 10.117 16.4629 10.2011 16.5479C10.2853 16.633 10.3472 16.7375 10.3816 16.8523C10.416 16.967 10.4217 17.0885 10.3982 17.206L10.3852 19.7297Z"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6149 19.7288C20.7642 19.7658 20.9194 19.7729 21.0715 19.7496C21.2236 19.7264 21.3696 19.6732 21.5012 19.5933C21.6328 19.5133 21.7474 19.4081 21.8384 19.2836C21.9295 19.1592 21.9951 19.0179 22.0317 18.868L22.3111 17.7333C22.3578 17.5829 22.3723 17.4243 22.3535 17.2678C22.3346 17.1114 22.283 16.9608 22.2019 16.8259C22.1209 16.6909 22.0123 16.5748 21.8832 16.485C21.7542 16.3953 21.6076 16.334 21.4532 16.3052V16.3052C21.336 16.2825 21.2149 16.2891 21.1008 16.3245C20.9867 16.3598 20.883 16.4228 20.7989 16.5079C20.7148 16.5929 20.6528 16.6975 20.6184 16.8122C20.5841 16.927 20.5784 17.0485 20.6019 17.166L20.6149 19.7288Z"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4178 14.9753V19.827C10.4152 20.4989 10.545 21.1647 10.7996 21.7861C11.0543 22.4075 11.4288 22.9722 11.9017 23.4479C12.3746 23.9236 12.9365 24.3009 13.5552 24.558C14.1738 24.8151 14.837 24.947 15.5066 24.9461C16.1754 24.947 16.8378 24.8155 17.456 24.5593C18.0741 24.3031 18.6359 23.9271 19.1091 23.4529C19.5824 22.9787 19.9578 22.4155 20.2139 21.7956C20.4701 21.1757 20.6019 20.5112 20.6019 19.8401V14.9297"
          stroke="#25383F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5398 15.8166C16.5398 15.8166 18.0216 14.9754 18.912 16.1101"
          stroke="#25383F"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M14.4146 15.8166C14.4146 15.8166 12.9263 14.9754 12.0359 16.1101"
          stroke="#25383F"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2444_4350">
          <rect width="15" height="23" fill="white" transform="translate(8 7)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const ExpandIcon = createIcon({
  displayName: 'ExpandIcon',
  viewBox: '0 0 18 18',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M16.5007 5.99902V1.49902H12.0007"
        stroke="#517E94"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5007 1.49902L6.50073 11.499"
        stroke="#517E94"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50073 4.49902H2.50073C1.94845 4.49902 1.50073 4.94674 1.50073 5.49902V15.499C1.50073 16.0513 1.94845 16.499 2.50073 16.499H12.5007C13.053 16.499 13.5007 16.0513 13.5007 15.499V9.49902"
        stroke="#517E94"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
