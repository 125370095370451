import moment from 'moment';

import {
  firstItemPathOr,
  switchMatchingValue,
  toFriendlyYearsOfExperienceRange,
  formatAsCurrency,
} from 'global/utils';
import {
  Candidate_Availability_Choices_Enum,
  Candidate_Curation_Desired_Salary_Frequency_Choices_Enum,
  Candidate_Curation_English_Proficiency_Choices_Enum,
  Candidate_Curation_Equity_Importance_Choices_Enum,
  Candidate_Curation_Remote_Work_Exp_Choices_Enum,
  Candidate_Education_Degree_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import {
  CandidateInterviewSubStageName,
  CandidateStageName,
  CandidateStageStatus,
} from 'talent-hub/constants';
import type { ProgressStatus } from 'talent-hub/utils';
import { capitalizeEachWord, markupPercentage } from 'talent-hub/utils/data';
import {
  employmentTypeMap,
  serializeCandidateHighlights,
  transformCandidateProfile_toInDemandBadgeInfo,
} from 'talent-hub/utils/candidate/serializer.utils';
import { createCandidateStagesInfo } from 'talent-hub/utils/workflow-status.utils';

import type { AvailableCurrency } from 'global/utils';
import { toDatesTitle } from 'global/utils/date';
import type { JobCandidateProfileQuery } from 'talent-hub/shared/features/roles/subfeatures/candidate-profile/data';
import { socialAccounts } from 'talent-hub/shared/features/roles/constants';

moment.updateLocale('en', {
  relativeTime: {
    m: '%d minute',
    h: '%d hour',
    d: '%d day',
    w: '%d week',
    M: '%d month',
    y: '%d year',
  },
});

function toSemicolonSeparatedPreferredFistLocations(
  preferredLocations: JobCandidateProfileQuery['icims_person'][number]['preferred_locations'] = [],
  interestedLocations: JobCandidateProfileQuery['icims_person'][number]['interested_locations'] = [],
): string {
  const noneNullInterestedLocations = interestedLocations
    .map(({ location }) => location?.name || '')
    .filter(Boolean);

  const noneNullPreferredLocations = preferredLocations
    .map(({ location }) => location?.name || '')
    .filter(Boolean);

  const locations = noneNullPreferredLocations.length
    ? noneNullPreferredLocations
    : noneNullInterestedLocations;

  return locations.slice(0, 3).join('; ');
}

type ICIMSPerson = JobCandidateProfileQuery['icims_person'][number];
type CandidateProfile = JobCandidateProfileQuery['candidate'][number];

export const englishProficiencyMap: Nullable<
  Record<Candidate_Curation_English_Proficiency_Choices_Enum, string>
> = {
  [Candidate_Curation_English_Proficiency_Choices_Enum.Basic]: 'Basic',
  [Candidate_Curation_English_Proficiency_Choices_Enum.Native]: 'Native',
  [Candidate_Curation_English_Proficiency_Choices_Enum.Proficient]: 'Proficient',
  [Candidate_Curation_English_Proficiency_Choices_Enum.None]: 'None',
};

export const candidateRemoteExperience: Record<
  Candidate_Curation_Remote_Work_Exp_Choices_Enum,
  string
> = {
  [Candidate_Curation_Remote_Work_Exp_Choices_Enum.OneTwo]: '1-3 Years',
  [Candidate_Curation_Remote_Work_Exp_Choices_Enum.ThreePlus]: '3+ Years',
  [Candidate_Curation_Remote_Work_Exp_Choices_Enum.ZeroOne]: '0-1 Years',
};

export const candidateSalaryTimeFrame: Record<
  Candidate_Curation_Desired_Salary_Frequency_Choices_Enum,
  string
> = {
  [Candidate_Curation_Desired_Salary_Frequency_Choices_Enum.Hourly]: 'Hourly',
  [Candidate_Curation_Desired_Salary_Frequency_Choices_Enum.Yearly]: 'Annually',
  [Candidate_Curation_Desired_Salary_Frequency_Choices_Enum.Monthly]: 'Monthly',
};

export const candidateEquityImportance: Record<
  Candidate_Curation_Equity_Importance_Choices_Enum,
  string
> = {
  [Candidate_Curation_Equity_Importance_Choices_Enum.NotImportant]: 'Not Important',
  [Candidate_Curation_Equity_Importance_Choices_Enum.SomewhatImportant]: 'Somewhat Important',
  [Candidate_Curation_Equity_Importance_Choices_Enum.VeryImportant]: 'Very Important',
};

// We have similar translation at: https://github.com/terminalinc/product/blob/bf41a62f97f3583a992244f09bbdfead4fef9606/web/src/candidate/features/profile/features/insider/steps/availability/Availability.controller.tsx#L15
export const candidateAvailability: Record<Candidate_Availability_Choices_Enum, string> = {
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableNow]: 'Right Now!',
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableSoon_1_3Months]: 'In 1-3 months',
  [Candidate_Availability_Choices_Enum.InterestedAndAvailableLater_3PlusMonths]:
    'More than 3 months',
  [Candidate_Availability_Choices_Enum.NotInterestedInANewRole]: 'Not Interested In A New Role',
};

export function mergeCandidateProfileAndICIMData(
  candidateProfile: CandidateProfile = {} as CandidateProfile,
  icimsProfile: ICIMSPerson = {} as ICIMSPerson,
): {
  applicantWorkflows: ICIMSPerson['applicant_workflows'];
  availability: ICIMSPerson['availability'];
  desiredSalaryAmount: ICIMSPerson['desired_salary_amount'];
  desiredSalaryCurrency: ICIMSPerson['desired_salary_currency'];
  desiredSalaryTimeframe: ICIMSPerson['desired_salary_timeframe'];
  /** Always comes in USD Annually from ICIMS and Retool */
  expectedTotalCompensation: ICIMSPerson['expected_total_compensation'];
  interestedLocations: ICIMSPerson['interested_locations'];
  preferredLocations: ICIMSPerson['preferred_locations'];
  englishProficiency: ICIMSPerson['english_proficiency'];
  equityImportance: ICIMSPerson['equity_importance'];
  remoteReadiness: ICIMSPerson['remote_readiness'];
  yearsExperience: string | ICIMSPerson['years_experience'];
  firstName: CandidateProfile['first_name'];
  lastName: CandidateProfile['last_name'];
  lastNameInitial: CandidateProfile['last_name_initial'];
  workExperiences: CandidateProfile['candidate_work_experiences'];
  educations: CandidateProfile['candidate_educations'];
  skills: CandidateProfile['candidate_skills'];
  githubUrl: CandidateProfile['github_url'];
  linkedinUrl: CandidateProfile['linkedin_url'];
  otherUrl: CandidateProfile['other_url'];
  resumeFilename: CandidateProfile['resume_filename'] | ICIMSPerson['profile']['resume_filename'];
  publicID: CandidateProfile['public_id'] | ICIMSPerson['profile']['public_id'];
  resumeRemotePath: Maybe<string>;
  interviewLink: GetNullable<CandidateProfile['candidate_curation_detail'], 'recording_link'>;
  location: string;
  employmentType: string | null;
  recruiterSummary: Maybe<string>;
  contractorCurrency: GetNullable<
    CandidateProfile['candidate_curation_detail'],
    'contractor_currency'
  >;
  contractorPaymentFrequency: GetNullable<
    CandidateProfile['candidate_curation_detail'],
    'contractor_payment_frequency'
  >;
  /** Contractor rate with a 30% markup applied to account for fees. */
  contractorRate: GetNullable<
    CandidateProfile['candidate_curation_detail'],
    'contractor_rate_markup'
  >;
  isFullTimeEmployment: boolean;
  isContractorEmployment: boolean;
} {
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    last_name_initial: lastNameInitial = '',
    city = '',
    country_choice,
    state = '',
    github_url: githubUrl = null,
    linkedin_url: linkedinUrl = null,
    other_url: otherUrl = null,
    candidate_educations: candidateEducations = [],
    candidate_skills: candidateSkills = [],
    candidate_work_experiences: candidateWorkExperiences = [],
    availability: candidateAvailabilityKey,
    candidate_curation_detail: candidateCurationDetail = null,
    employment_type,
  } = candidateProfile;

  const country = country_choice?.name || '';

  const {
    firstname: icimsFirstName = '',
    lastname: icimsLastName = '',
    lastname_initial: icimsLastNameInitial = '',
    applicant_workflows: applicantWorkflows,
    availability,
    desired_salary_amount: icimsDesiredSalaryAmount,
    desired_salary_currency: icimsDesiredSalaryCurrency,
    desired_salary_timeframe: icimsDesiredSalaryTimeframe,
    expected_total_compensation: icimsExpectedTotalCompensation,
    interested_locations: interestedLocations,
    preferred_locations: preferredLocations,
    english_proficiency: icimsEnglishProficiency,
    equity_importance: icimsEquityImportance,
    remote_readiness: icimsRemoteReadiness,
    years_experience: icmisYearsOfExperience,
    profile = { resume_filename: null, public_id: null },
  } = icimsProfile;

  let resumeFilename = null;
  let publicID = null;
  let parentFolderName = null;

  if (candidateProfile.resume_filename && candidateProfile.public_id) {
    resumeFilename = candidateProfile.resume_filename;
    publicID = candidateProfile.public_id;
    parentFolderName = 'candidate';
  } else if (profile.resume_filename && profile.public_id) {
    resumeFilename = profile.resume_filename;
    publicID = profile.public_id;
    parentFolderName = 'icims_profile_person';
  }

  const hasCandidateSalary =
    candidateCurationDetail &&
    candidateCurationDetail.desired_salary_amount &&
    candidateCurationDetail.desired_salary_currency &&
    candidateCurationDetail.desired_salary_frequency;

  const icimsAvailability = switchMatchingValue(availability, [
    {
      match: ['Immediately', 'Within 1 Week', 'Within 2 Weeks', 'Within 3 Weeks'],
      to: candidateAvailability.Interested_And_Available_Now,
    },
    {
      match: 'Within 4 Weeks',
      to: candidateAvailability.Interested_And_Available_Soon_1_3_Months,
    },
    {
      match: ['Other', null],
      to: candidateAvailability.Interested_And_Available_Later_3_Plus_Months,
    },
  ]);

  const employmentType = employment_type ? employmentTypeMap[employment_type] : null;

  return {
    applicantWorkflows,
    contractorCurrency: candidateCurationDetail?.contractor_currency,
    contractorPaymentFrequency: candidateCurationDetail?.contractor_payment_frequency,
    contractorRate: candidateCurationDetail?.contractor_rate_markup,
    availability:
      (candidateAvailabilityKey && candidateAvailability[candidateAvailabilityKey]) ||
      icimsAvailability,
    desiredSalaryAmount: hasCandidateSalary
      ? candidateCurationDetail?.desired_salary_amount
      : icimsDesiredSalaryAmount,
    desiredSalaryCurrency: hasCandidateSalary
      ? candidateCurationDetail?.desired_salary_currency
      : icimsDesiredSalaryCurrency,
    desiredSalaryTimeframe: hasCandidateSalary
      ? candidateSalaryTimeFrame[
          candidateCurationDetail?.desired_salary_frequency as Candidate_Curation_Desired_Salary_Frequency_Choices_Enum
        ]
      : icimsDesiredSalaryTimeframe,
    /* Always comes in annual USD (from retool and icims). */
    expectedTotalCompensation:
      candidateCurationDetail?.expected_total_comp || icimsExpectedTotalCompensation,
    interestedLocations,
    preferredLocations,
    englishProficiency:
      englishProficiencyMap[
        candidateCurationDetail?.english_proficiency as Candidate_Curation_English_Proficiency_Choices_Enum
      ] || icimsEnglishProficiency,
    equityImportance:
      candidateEquityImportance[
        candidateCurationDetail?.equity_importance as Candidate_Curation_Equity_Importance_Choices_Enum
      ] || icimsEquityImportance,
    remoteReadiness:
      candidateRemoteExperience[
        candidateCurationDetail?.remote_experience as Candidate_Curation_Remote_Work_Exp_Choices_Enum
      ] || icimsRemoteReadiness,
    yearsExperience: candidateCurationDetail?.years_of_exp_range
      ? toFriendlyYearsOfExperienceRange(candidateCurationDetail.years_of_exp_range)
      : icmisYearsOfExperience,
    firstName: firstName || icimsFirstName,
    lastName: lastName || icimsLastName,
    lastNameInitial: lastNameInitial || icimsLastNameInitial,
    workExperiences: candidateWorkExperiences,
    educations: candidateEducations,
    skills: candidateSkills,
    githubUrl,
    linkedinUrl,
    otherUrl,
    resumeFilename,
    publicID,
    resumeRemotePath: parentFolderName
      ? `/${parentFolderName}/resume/${publicID}/${resumeFilename}`
      : null,
    interviewLink:
      candidateCurationDetail?.recording_link ||
      firstItemPathOr(null, ['luma_link'], applicantWorkflows || []),
    location:
      [city, state, country]?.filter(Boolean).join(', ') ||
      toSemicolonSeparatedPreferredFistLocations(preferredLocations, interestedLocations),
    recruiterSummary:
      firstItemPathOr(null, ['submission_notes'], applicantWorkflows || []) ||
      candidateCurationDetail?.recruiter_summary,
    employmentType,
    isFullTimeEmployment:
      employmentType === employmentTypeMap.FULL_TIME ||
      employmentType === employmentTypeMap.FULL_TIME_AND_CONTRACT,
    isContractorEmployment:
      employmentType === employmentTypeMap.CONTRACT ||
      employmentType === employmentTypeMap.FULL_TIME_AND_CONTRACT,
  };
}

const degreeDictionary = {
  [Candidate_Education_Degree_Choices_Enum.Bootcamp]: 'Bootcamp',
  [Candidate_Education_Degree_Choices_Enum.Associate]: 'Associate',
  [Candidate_Education_Degree_Choices_Enum.Bachelors]: "Bachelor's",
  [Candidate_Education_Degree_Choices_Enum.Masters]: "Master's",
  [Candidate_Education_Degree_Choices_Enum.Doctoral]: 'Doctoral',
  [Candidate_Education_Degree_Choices_Enum.NoDegree]: 'No Degree',
  [Candidate_Education_Degree_Choices_Enum.Other]: 'Other',
};

function dateToMMMDD(date?: Date | null) {
  return date ? moment(date).format('MMM DD') : '';
}

function dateToMMMDDYYYY(date?: Date | string | null) {
  // Looks like the current date already have this spected structured so this function maybe is not required (?)
  return date
    ? moment(typeof date === 'string' ? new Date(date) : date).format('MMM DD, yyyy')
    : '';
}

const toObjectWithFormattedDate =
  <T extends { date: Date | string }>(dateFormatter: (date?: any) => string) =>
  (object: T): T & { date: string } => {
    return {
      ...object,
      date: dateFormatter(object.date),
    };
  };

function createMoreInfoPropsForInSubmissionStage({
  isCandidateRejected,
  firstName,
  lastName,
  applicantWorkflows,
  initialDataTransfer,
}: {
  isCandidateRejected: boolean;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  applicantWorkflows: JobCandidateProfileQuery['icims_person'][number]['applicant_workflows'];
  initialDataTransfer: number;
}) {
  return !isCandidateRejected
    ? {
        type: 'submission-review',
        text: `${firstName} ${lastName}`,
        applicantWorkflowID: firstItemPathOr(null, ['workflow_id'], applicantWorkflows || []),
        initialDataTransfer,
      }
    : null;
}

function findCountryNoticePeriod(
  country?: Exclude<JobCandidateProfileQuery['candidate'][number]['country_choice'], null>['name'],
): string | null {
  if (!country) {
    return null;
  }
  switch (country) {
    case 'Costa Rica':
      return '30 days';
    case 'Poland':
      return 'Up to 3 full calendar months';
    case 'Spain':
    case 'Mexico':
    case 'Canada':
      return '2 weeks';
    case 'Chile':
    case 'Colombia':
    case 'Romania':
      return '3 weeks';
    case 'Hungary':
      return '3 days';
    default:
      return null;
  }
}

function createMoreInfoPropsForInInterviewStage(
  isCandidateRejected: boolean,
  candidateSubStageName: CandidateInterviewSubStageName | null,
) {
  if (isCandidateRejected || !candidateSubStageName) {
    return null;
  }

  let moreInfo = null;

  if (candidateSubStageName === CandidateInterviewSubStageName.ToBeScheduled) {
    moreInfo = {
      type: 'status-alert',
      text: 'Interview being scheduled',
    };
  }

  if (candidateSubStageName === CandidateInterviewSubStageName.Scheduled) {
    moreInfo = {
      type: 'status-alert',
      text: 'Interview scheduled', // when date is added change the tet to "Interview scheduled for"
      date: '',
    };
  }

  if (candidateSubStageName === CandidateInterviewSubStageName.AwaitingFeedback) {
    moreInfo = {
      type: 'interview-review',
      text: 'Awaiting Feedback',
    };
  }

  return moreInfo;
}

type CandidateProfileWithApplicantWorkflows = Pick<
  JobCandidateProfileQuery['icims_person'][number],
  'applicant_workflows'
>;

type WorkflowStatus =
  CandidateProfileWithApplicantWorkflows['applicant_workflows'][number]['statuses'][number];
type CandidateStageProps = {
  info: string;
  status: CandidateStageStatus;
  progressStatuses: ProgressStatus[];
  moreInfo?: any; // TODO: create a more specific moreInfo
};
type InterviewStageProps = CandidateStageProps & {
  feedbackStatus: string | null;
};
type CandidateProfileStagesProps = {
  screening: CandidateStageProps;
  submission: CandidateStageProps;
  interview: InterviewStageProps;
  offer: CandidateStageProps;
  hired: CandidateStageProps;
};
/**
 * Transforms CandidateProfile data to props that can be used for all four screening, submission, interview and offer stages UI
 * For detail documentation refer to "candidate stage" tests in JobCandidateProfile.serializer.test.js
 */
function transformCandidateProfileToStagesProps(
  initialDataTransfer: number,
  candidateProfile?: JobCandidateProfileQuery['candidate'][number],
  icimsProfile?: JobCandidateProfileQuery['icims_person'][number],
): CandidateProfileStagesProps {
  if (!icimsProfile && !candidateProfile) {
    return {
      screening: {
        info: '',
        status: CandidateStageStatus.NotApplicable,
        progressStatuses: [],
      },
      submission: {
        info: '',
        status: CandidateStageStatus.NotApplicable,
        progressStatuses: [],
      },
      interview: {
        info: '',
        status: CandidateStageStatus.NotApplicable,
        progressStatuses: [],
        feedbackStatus: null,
      },
      offer: {
        info: '',
        status: CandidateStageStatus.NotApplicable,
        progressStatuses: [],
      },
      hired: {
        info: '',
        status: CandidateStageStatus.NotApplicable,
        progressStatuses: [],
      },
    };
  }

  const candidateData = mergeCandidateProfileAndICIMData(
    candidateProfile as JobCandidateProfileQuery['candidate'][number],
    icimsProfile as JobCandidateProfileQuery['icims_person'][number],
  );

  const candidateStatuses = firstItemPathOr<WorkflowStatus[]>(
    [],
    ['statuses'],
    candidateData.applicantWorkflows || [],
  ).map((workflowStatus: WorkflowStatus) => ({
    date: workflowStatus.date,
    title: workflowStatus.status && workflowStatus.status.title ? workflowStatus.status.title : '',
  }));

  const stagesData = createCandidateStagesInfo(candidateStatuses);

  const {
    stage: candidateStage,
    currentStageName: candidateCurrentStageName,
    currentSubStage: candidateCurrentSubStageName,
    currentFeedbackStatus,
  } = stagesData;

  const defaultScreeningStage = {
    info: 'Completed',
    status: candidateStage.screening.status,
    progressStatuses: [],
  };

  // For each stage the candidate is currently at, the { info, status, (potentially) moreInfo } for all the four stages are computed
  // this way of categorization was done to reduce the level complexity of the logic

  if (candidateCurrentStageName === CandidateStageName.Submission) {
    const isCandidateRejected =
      candidateStage.submission.status === CandidateStageStatus.RejectedAt;

    return {
      screening: defaultScreeningStage,
      submission: {
        info: !isCandidateRejected // info displays in front of the stage header label
          ? 'In Progress'
          : dateToMMMDD(candidateStage.submission.rejectionDate),
        status: candidateStage.submission.status,
        progressStatuses: candidateStage.submission.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        moreInfo: createMoreInfoPropsForInSubmissionStage({
          isCandidateRejected,
          firstName: candidateData.firstName,
          lastName: candidateData.lastName,
          applicantWorkflows: candidateData.applicantWorkflows,
          initialDataTransfer,
        }),
      },
      interview: {
        info: !isCandidateRejected ? 'Pending Submission' : 'N/A',
        progressStatuses: candidateStage.interview.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        status: candidateStage.interview.status,
        feedbackStatus: null,
      },
      offer: {
        info: !isCandidateRejected ? 'Pending Interview' : 'N/A',
        progressStatuses: candidateStage.offer.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        status: candidateStage.offer.status,
      },
      hired: {
        info: !isCandidateRejected ? 'Pending Offer' : 'N/A',
        progressStatuses: [],
        status: candidateStage.offer.status,
      },
    };
  }

  if (candidateCurrentStageName === CandidateStageName.Interview) {
    const isCandidateRejected = candidateStage.interview.status === CandidateStageStatus.RejectedAt;

    return {
      screening: defaultScreeningStage,
      submission: {
        info: 'Completed',
        status: candidateStage.submission.status,
        progressStatuses: candidateStage.submission.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      interview: {
        info: !isCandidateRejected
          ? 'In Progress'
          : dateToMMMDD(candidateStage.interview.rejectionDate),
        status: candidateStage.interview.status,
        progressStatuses: candidateStage.interview.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        moreInfo: createMoreInfoPropsForInInterviewStage(
          isCandidateRejected,
          candidateCurrentSubStageName,
        ),
        feedbackStatus: currentFeedbackStatus || null,
      },
      offer: {
        info: !isCandidateRejected ? 'Pending Interview' : 'N/A',
        status: candidateStage.offer.status,
        progressStatuses: candidateStage.offer.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      hired: {
        info: !isCandidateRejected ? 'Pending Offer' : 'N/A',
        status: candidateStage.hired.status,
        progressStatuses: candidateStage.hired.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
    };
  }

  if (candidateCurrentStageName === CandidateStageName.Offer) {
    const isCandidateRejected = candidateStage.offer.status === CandidateStageStatus.RejectedAt;

    return {
      screening: defaultScreeningStage,
      submission: {
        info: 'Completed',
        status: candidateStage.submission.status,
        progressStatuses: candidateStage.submission.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      interview: {
        info: 'Completed',
        status: candidateStage.interview.status,
        progressStatuses: candidateStage.interview.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        feedbackStatus: null,
      },
      offer: {
        info: !isCandidateRejected
          ? 'In Progress'
          : dateToMMMDD(candidateStage.offer.rejectionDate),
        status: candidateStage.offer.status,
        progressStatuses: candidateStage.offer.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      hired: {
        info: !isCandidateRejected ? 'Pending Offer' : 'N/A',
        status: candidateStage.hired.status,
        progressStatuses: candidateStage.hired.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
    };
  }

  if (candidateCurrentStageName === CandidateStageName.Hired) {
    return {
      screening: defaultScreeningStage,
      submission: {
        info: 'Completed',
        status: candidateStage.submission.status,
        progressStatuses: candidateStage.submission.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      interview: {
        info: 'Completed',
        status: candidateStage.interview.status,
        progressStatuses: candidateStage.interview.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
        feedbackStatus: null,
      },
      offer: {
        info: 'Completed',
        status: candidateStage.offer.status,
        progressStatuses: candidateStage.offer.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
      hired: {
        info: '',
        status: candidateStage.hired.status,
        progressStatuses: candidateStage.hired.progressStatuses.map(
          toObjectWithFormattedDate(dateToMMMDDYYYY),
        ),
      },
    };
  }

  // If candidate not in any of the Submission, Interview, Offer or Hired stages, should not occur in normal cases
  return {
    screening: defaultScreeningStage,
    submission: {
      info: '',
      status: candidateStage.submission.status,
      progressStatuses: [],
    },
    interview: {
      info: '',
      status: candidateStage.interview.status,
      progressStatuses: [],
      feedbackStatus: null,
    },
    offer: {
      info: '',
      status: candidateStage.offer.status,
      progressStatuses: [],
    },
    hired: {
      info: '',
      status: candidateStage.offer.status,
      progressStatuses: [],
    },
  };
}

export function convertAmountByCurrency({
  amount,
  sourceCurrency,
  targetCurrency,
  exchangeRates,
  roundingMode,
}: {
  amount: number;
  sourceCurrency: AvailableCurrency;
  targetCurrency: AvailableCurrency;
  exchangeRates?: JobCandidateProfileQuery['exchange_rates'];
  roundingMode?: 'round' | 'ceil' | 'floor' | undefined;
}) {
  if (!amount) {
    return { amount, currency: sourceCurrency };
  }

  const exchangeUSDRates: Record<AvailableCurrency, number> =
    exchangeRates?.reduce((parsed, rate) => {
      return {
        ...parsed,
        [rate.iso_code]: rate.live_exchange_rate,
      };
    }, {} as Record<AvailableCurrency, number>) || ({} as Record<AvailableCurrency, number>);

  const isSourceCurrencySupported =
    !!exchangeUSDRates[sourceCurrency] && typeof exchangeUSDRates[sourceCurrency] === 'number';
  const isTargetCurrencySupported =
    !!exchangeUSDRates[targetCurrency] && typeof exchangeUSDRates[targetCurrency] === 'number';

  if (
    !isSourceCurrencySupported ||
    !isTargetCurrencySupported ||
    sourceCurrency === targetCurrency ||
    amount <= 0
  )
    return {
      amount: roundingMode ? Math[roundingMode](amount) : amount,
      currency: sourceCurrency,
    };

  let amountInUSD = amount;

  if (sourceCurrency !== 'USD') {
    amountInUSD = amount / exchangeUSDRates[sourceCurrency];
  }

  if (targetCurrency === 'USD') {
    return {
      amount: roundingMode ? Math[roundingMode](amountInUSD) : amountInUSD,
      currency: targetCurrency,
    };
  }

  const amountExchanged = amountInUSD * exchangeUSDRates[targetCurrency];

  return {
    // * By converting into USD first, then into other currencies, there is a double conversion, which reduces the final amount a little bit.
    amount: roundingMode ? Math[roundingMode](amountExchanged) : amountExchanged,
    currency: targetCurrency,
  };
}

function transformCandidateProfileToProfile({
  candidateProfile,
  icimsProfile,
  exchangeRates,
  organizationCurrency,
}: {
  candidateProfile?: JobCandidateProfileQuery['candidate'][number];
  icimsProfile?: JobCandidateProfileQuery['icims_person'][number];
  exchangeRates?: JobCandidateProfileQuery['exchange_rates'];
  organizationCurrency: AvailableCurrency;
}): {
  currentRole: string;
  desiredComp: string;
  totalComp: string;
  totalContractorComp: string;
  desiredStart: string;
  educations: { title: string; subTitle: string; datesTitle: string | null }[];
  englishProficiency: string;
  experience: string;
  fullName: string;
  lastName: string;
  lastNameInitial: string;
  firstName: string;
  interviewLink: Maybe<string>;
  location: string;
  recruiterSummary: Maybe<string>;
  remoteReadiness: Maybe<string>;
  socialAccounts: { title: string | undefined; url: any }[];
  topSkills: Maybe<string>[];
  workExperiences: {
    title: Maybe<string>;
    employer: Maybe<string>;
    employmentYears: string;
    employmentDuration: Maybe<string>;
  }[];
  resume: {
    fileName: Maybe<string>;
    publicID: Maybe<string>;
    remotePath: Maybe<string>;
  };
  applicantWorkflowId: Maybe<number>;
  noticePeriod: Maybe<string>;
  employmentType: string;
  isFullTimeEmployment: boolean;
  isContractorEmployment: boolean;
} {
  if (!icimsProfile && !candidateProfile) {
    return {
      applicantWorkflowId: null,
      currentRole: '',
      desiredComp: '',
      desiredStart: '',
      totalContractorComp: '',
      totalComp: '',
      educations: [],
      englishProficiency: '',
      experience: '',
      fullName: '',
      lastName: '',
      firstName: '',
      lastNameInitial: '',
      interviewLink: null,
      location: '',
      recruiterSummary: null,
      remoteReadiness: null,
      socialAccounts: [],
      topSkills: [],
      workExperiences: [],
      resume: { fileName: null, publicID: null, remotePath: null },
      noticePeriod: null,
      employmentType: '',
      isFullTimeEmployment: false,
      isContractorEmployment: false,
    };
  }

  const {
    applicantWorkflows,
    availability,
    desiredSalaryAmount,
    desiredSalaryCurrency,
    desiredSalaryTimeframe,
    educations,
    englishProficiency,
    equityImportance,
    expectedTotalCompensation,
    firstName,
    interestedLocations,
    lastName,
    lastNameInitial,
    preferredLocations,
    remoteReadiness,
    skills,
    workExperiences,
    yearsExperience,
    resumeFilename,
    publicID,
    resumeRemotePath,
    interviewLink,
    location,
    recruiterSummary,
    employmentType,
    contractorCurrency,
    contractorPaymentFrequency,
    contractorRate,
    isContractorEmployment,
    isFullTimeEmployment,
    ...candidateRest
  } = mergeCandidateProfileAndICIMData(
    candidateProfile as JobCandidateProfileQuery['candidate'][number],
    icimsProfile as JobCandidateProfileQuery['icims_person'][number],
  );

  const currentSocialAccounts = socialAccounts.map(([title, dataKey]) => {
    // @ts-ignore
    const url = candidateRest[dataKey];
    return {
      title,
      url: url && (url.startsWith('http') ? url : `//${url}`),
    };
  });

  const serializedWorkExperiences = workExperiences.map(
    ({ company_name, job_title, start_date, end_date }) => {
      if (start_date && end_date) {
        return {
          title: job_title,
          employer: company_name,
          employmentYears: `${moment(start_date).format('yyyy')} - ${moment(end_date).format(
            'yyyy',
          )}`,
          employmentDuration: moment.duration(moment(end_date).diff(moment(start_date))).humanize(),
        };
      }

      if (start_date) {
        return {
          title: job_title,
          employer: company_name,
          employmentYears: `${moment(start_date).format('yyyy')} - Current`,
          employmentDuration: moment.duration(moment().diff(moment(start_date))).humanize(),
        };
      }

      if (end_date) {
        return {
          title: job_title,
          employer: company_name,
          employmentYears: `Unknown - ${moment(end_date).format('yyyy')}`,
          employmentDuration: null,
        };
      }

      return {
        title: job_title,
        employer: company_name,
        employmentYears: 'Unknown',
        employmentDuration: null,
      };
    },
  );

  const serializedEducations = [...educations]
    .sort((a, b) => {
      const earliestYear = 1800;

      const compareStartYear = (b.start_year || earliestYear) - (a.start_year || earliestYear);
      if (compareStartYear !== 0) {
        return compareStartYear;
      }

      return (b.graduation_year || earliestYear) - (a.graduation_year || earliestYear);
    })
    .map(({ start_year, graduation_year, concentration, school_name, degree, still_studying }) => {
      const degreeTitle = degree ? degreeDictionary[degree] : false;
      return {
        title: school_name || 'Unknown',
        subTitle: [concentration, degreeTitle].filter(Boolean).join(' - ') || 'Unknown',
        datesTitle: toDatesTitle({
          startDate: start_year?.toString(),
          endDate: graduation_year?.toString(),
          isCurrent: still_studying,
          format: 'yyyy',
        }),
      };
    });

  const allSkills = skills.map((skill) => skill.skill.name);

  const desiredSalary_inOrganizationCurrency = convertAmountByCurrency({
    amount: desiredSalaryAmount,
    sourceCurrency: desiredSalaryCurrency as AvailableCurrency,
    targetCurrency: organizationCurrency,
    exchangeRates,
    roundingMode: 'floor',
  });

  const desiredComp = `${
    desiredSalary_inOrganizationCurrency.amount
      ? formatAsCurrency(
          desiredSalary_inOrganizationCurrency.amount,
          desiredSalary_inOrganizationCurrency.currency as AvailableCurrency,
          {
            showformatAsSimpleCurrency: true,
            maximumSignificantDigits:
              `${desiredSalary_inOrganizationCurrency.amount}`.length > 3 // It is in thousands and beyond otherwise its in hundreds
                ? `${desiredSalary_inOrganizationCurrency.amount}`.length - 2
                : 2,
          },
        )
      : ''
  }${
    desiredSalary_inOrganizationCurrency.currency
      ? ` (${desiredSalary_inOrganizationCurrency.currency})`
      : ''
  }${desiredSalaryTimeframe ? ` ${desiredSalaryTimeframe}` : ''}${
    equityImportance ? `, Equity ${equityImportance}` : ''
  }`;

  const totalComp_inOrganizationCurrency = convertAmountByCurrency({
    amount: expectedTotalCompensation!,
    sourceCurrency: 'USD',
    targetCurrency: organizationCurrency,
    exchangeRates,
    roundingMode: 'floor',
  });

  const totalComp = expectedTotalCompensation
    ? `${formatAsCurrency(
        totalComp_inOrganizationCurrency.amount,
        totalComp_inOrganizationCurrency.currency,
        {
          showformatAsSimpleCurrency: true,
          maximumSignificantDigits:
            `${totalComp_inOrganizationCurrency.amount}`.length > 3 // It is in thousands and beyond otherwise its in hundreds
              ? `${totalComp_inOrganizationCurrency.amount}`.length - 2
              : 2,
        },
      )} (${totalComp_inOrganizationCurrency.currency}) Annually`
    : '';

  const contractorRate_inOrganizationCurrency = convertAmountByCurrency({
    amount: Number(contractorRate),
    sourceCurrency: contractorCurrency as AvailableCurrency,
    targetCurrency: organizationCurrency,
    exchangeRates,
    roundingMode: 'floor',
  });

  const totalContractorComp =
    contractorRate_inOrganizationCurrency.amount && contractorPaymentFrequency
      ? `${formatAsCurrency(
          contractorRate_inOrganizationCurrency.amount,
          contractorRate_inOrganizationCurrency.currency,
          {
            showformatAsSimpleCurrency: true,
            maximumSignificantDigits:
              `${contractorRate_inOrganizationCurrency.amount}`.length > 3 // It is in thousands and beyond otherwise its in hundreds
                ? `${contractorRate_inOrganizationCurrency.amount}`.length - 2
                : 2,
          },
        )} (${contractorRate_inOrganizationCurrency.currency}) ${
          candidateSalaryTimeFrame[contractorPaymentFrequency]
        }`
      : '';

  const noticePeriod = findCountryNoticePeriod(
    candidateProfile?.country_choice?.name ||
      (location.includes(';') ? location.split('; ').pop() : location),
  );

  return {
    applicantWorkflowId: firstItemPathOr(null, ['workflow_id'], applicantWorkflows || []),
    currentRole: serializedWorkExperiences.length ? serializedWorkExperiences[0]?.title || '' : '',
    desiredComp,
    totalComp,
    desiredStart: availability || '',
    educations: serializedEducations,
    englishProficiency: englishProficiency || '',
    experience: yearsExperience || '',
    fullName: capitalizeEachWord(`${firstName} ${lastName}`),
    firstName: capitalizeEachWord(firstName),
    lastName: capitalizeEachWord(lastName),
    lastNameInitial: capitalizeEachWord(lastNameInitial),
    interviewLink,
    location,
    recruiterSummary,
    remoteReadiness,
    socialAccounts: currentSocialAccounts,
    topSkills: allSkills,
    workExperiences: serializedWorkExperiences,
    resume: {
      fileName: resumeFilename,
      publicID,
      remotePath: resumeRemotePath,
    },
    noticePeriod,
    // @ts-ignore TODO (TP-1666): fix ignored typescript error
    employmentType,
    totalContractorComp,
    isFullTimeEmployment,
    isContractorEmployment,
  };
}

/**
 * TODO: Before edit this serializer, create test for serializePublicCandidateProfile
 */
export function serializeJobCandidateProfile({
  data,
  organizationCurrency,
  shouldConsiderPreSubmission_markupChange = false,
}: {
  data: JobCandidateProfileQuery;
  organizationCurrency: AvailableCurrency;
  shouldConsiderPreSubmission_markupChange?: boolean;
}) {
  if (!data) {
    return {
      profile: transformCandidateProfileToProfile({} as any),
      stage: transformCandidateProfileToStagesProps(1),
      hasInterviewFeedback: false,
      badges: [],
      highlights: [],
    };
  }
  const icimsProfile = data.icims_person?.[0];
  const candidateProfile = data.candidate?.[0];
  const initialDataTransfer = data.organization?.[0]?.initial_data_transfer || 1;
  const allCandidateWorkflows = data.icims_person?.[0]?.all_applicant_workflows || [];
  const exchangeRates = data.exchange_rates;

  if (!candidateProfile && !icimsProfile) {
    return {
      /**
       * Ideally, this property should be able to be null, but since it is currently being used in many places, I will leave this as a TODO to progressively update it.
       * TODO: Should be nullable.
       */
      profile: transformCandidateProfileToProfile({} as any),
      /**
       * Ideally, this property should be able to be null, but since it is currently being used in many places, I will leave this as a TODO to progressively update it.
       * TODO: Should be nullable.
       */
      stage: transformCandidateProfileToStagesProps(1),
      hasInterviewFeedback: false,
      badges: [],
      highlights: [],
    };
  }

  const candidateInDemandBadgeInfo =
    transformCandidateProfile_toInDemandBadgeInfo(allCandidateWorkflows);

  const computedTopCompanyExperience = candidateProfile?.candidate_work_experiences?.some(
    (workExperience) => workExperience.company?.is_top_company,
  );
  const computedStartupExperience = candidateProfile?.candidate_work_experiences?.some(
    (workExperience) => workExperience.company?.is_startup,
  );
  const computedCSDegree = candidateProfile?.candidate_educations?.some(
    (education) => education.has_cs_degree,
  );

  const { badges, highlights, activeMessage } = serializeCandidateHighlights({
    maxBadges: 2,
    candidateInformation: {
      englishProficiency:
        englishProficiencyMap[
          candidateProfile?.candidate_curation_detail
            ?.english_proficiency as Candidate_Curation_English_Proficiency_Choices_Enum
        ] || icimsProfile?.english_proficiency,
      industries: !candidateProfile?.candidate_badge?.top_industry
        ? [
            candidateProfile?.candidate_badge?.industry_1?.name,
            candidateProfile?.candidate_badge?.industry_2?.name,
          ]
        : [
            candidateProfile?.candidate_badge?.top_industry,
            candidateProfile?.candidate_badge?.second_top_industry,
          ],
      previouslyWorked: [
        candidateProfile?.candidate_work_experiences?.[0]?.company_name,
        candidateProfile?.candidate_work_experiences?.[1]?.company_name,
      ],
      skilledInMultipleLanguages: candidateProfile?.candidate_badge?.multiple_languages,
      csDegree:
        candidateProfile?.candidate_badge?.cs_degree == null
          ? computedCSDegree
          : candidateProfile?.candidate_badge?.cs_degree,
      hasBuiltProductsWith:
        candidateProfile?.candidate_badge?.built_new_candidate_work_experience?.company_name,
      yearsTechLeadExperience: candidateProfile?.candidate_badge?.years_tech_lead_exp,
      yearsPeopleLeadershipExperience: candidateProfile?.candidate_badge?.years_people_lead_exp,
      mutualInterest: candidateProfile?.candidate_jobs || [],
      topCompanyExperience:
        candidateProfile?.candidate_badge?.top_company_exp == null
          ? computedTopCompanyExperience
          : candidateProfile?.candidate_badge?.top_company_exp,
      startupCompanyExperience: computedStartupExperience,
      referredCandidate: candidateProfile?.candidate_badge?.referred,
      inDemand: candidateInDemandBadgeInfo,
      activeMessage: {
        candidateCurationWorkflows: data.candidate[0]?.candidate_curation_workflows || [],
        candidateStageLogs: data.candidate[0]?.candidate_stage_logs || [],
      },
      previousHire: !!candidateProfile?.is_previous_hire,
    },
  });

  const stage = transformCandidateProfileToStagesProps(
    initialDataTransfer,
    candidateProfile,
    icimsProfile,
  );

  const submittedBeforeTheMarkupChange =
    shouldConsiderPreSubmission_markupChange &&
    stage.submission.progressStatuses.find(
      (s) =>
        s.friendlyTitle === 'Candidate Submitted' &&
        s.date &&
        Date.parse(s.date) < Date.parse('May 29, 2024'),
    );

  const candidate_curation_detail = candidateProfile?.candidate_curation_detail
    ? {
        ...candidateProfile?.candidate_curation_detail,
        contractor_rate_markup:
          // * This is a hack-fix, to show the previous 30% rate in the candidates submitted before the change in the markup
          submittedBeforeTheMarkupChange &&
          candidateProfile?.candidate_curation_detail?.contractor_rate_markup
            ? Math.round(
                (Number(candidateProfile?.candidate_curation_detail?.contractor_rate_markup) /
                  markupPercentage) *
                  1.3,
              )
            : candidateProfile?.candidate_curation_detail?.contractor_rate_markup,
      }
    : null;

  const profile = transformCandidateProfileToProfile({
    candidateProfile: {
      ...candidateProfile,
      candidate_curation_detail,
    } as JobCandidateProfileQuery['candidate'][number],
    icimsProfile,
    exchangeRates,
    organizationCurrency,
  });

  return {
    profile,
    stage,
    hasInterviewFeedback: !!data.interview_feedback?.some(
      (feedback) => feedback.icims_applicant_workflow_status === stage.interview.feedbackStatus,
    ),
    badges,
    highlights,
    activeMessage,
  };
}
