import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import {
  VStack,
  Heading,
  Text,
  HStack,
  Divider,
  Box,
  Link,
  Flex,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@terminal/design-system';
import { Card, CardHeader, CardBody } from 'talent-hub/components';
import { BlankScreenLoading } from 'global/components';
import { roleTabs } from '../../components';

function RoleWeeklyReportsLayout({ children }: { children: React.ReactElement }) {
  const history = useHistory();
  const { activeRole, activeTab } = useParams<{ activeRole: string; activeTab: string }>();
  return (
    <Drawer
      isOpen={activeTab === roleTabs.reports}
      onClose={() => history.push(`/role/${activeRole}/${roleTabs.overview}`)}
      size="3xl"
    >
      <DrawerOverlay />
      <DrawerContent h="full" justifyContent="space-between">
        <DrawerCloseButton color="brand.main" size="lg" zIndex="sticky" left={3} top={3} />
        <DrawerBody p={0}>
          <Box border="1px solid" borderColor="ui.secondary">
            <Flex bg="bg.tertiary" w="full" borderBottom="1px solid" borderColor="brand.lighter">
              <Heading variant="heading-1" p={10} pt={14}>
                Weekly Reports
              </Heading>
            </Flex>
            {children}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function RoleWeeklyReports({
  weeklySummaries,
  isLoading,
}: {
  weeklySummaries: {
    dateRange: string;
    bucketSummaries: {
      title: string;
      week: { total: number; candidateNames: string };
      allTime: number;
    }[];
    candidatesActivityReviews: {
      id: number;
      name: string;
      activity: string;
      status: {
        date: string;
        title: string;
      };
    }[];
  }[];
  isLoading: boolean;
}) {
  const { activeRole } = useParams<{ activeRole: string }>();
  if (isLoading) {
    return (
      <RoleWeeklyReportsLayout>
        <SimpleGrid>
          <BlankScreenLoading />
        </SimpleGrid>
      </RoleWeeklyReportsLayout>
    );
  }

  return (
    <RoleWeeklyReportsLayout>
      <VStack p={10} spacing={10}>
        {weeklySummaries.map((weekSummary) => (
          <Card key={`week-${weekSummary.dateRange}`}>
            <CardHeader>
              <Heading variant="heading-3" color="brand.darker">
                Weekly Report: {weekSummary.dateRange}
              </Heading>
            </CardHeader>
            <CardBody px={2}>
              <Heading variant="heading-3">Stats</Heading>
              <>
                {weekSummary.bucketSummaries.map((bucketSummary) => (
                  <Box key={`bucket-summary${weekSummary.dateRange}-${bucketSummary.title}`} mt={4}>
                    <HStack pl={2} borderLeft="4px" borderLeftColor="brand.main">
                      <Text variant="label">{bucketSummary.title}:</Text>
                      <Text>{bucketSummary.week.total}</Text>
                      <Divider orientation="vertical" py={3} />
                      <Text variant="label">All Time:</Text>
                      <Text>{bucketSummary.allTime}</Text>
                    </HStack>
                    <Text pl={3} variant="caption">
                      {bucketSummary.week.candidateNames}
                    </Text>
                  </Box>
                ))}
              </>
              <Heading variant="heading-3" mt={6}>
                Candidate Activity
              </Heading>
              {!weekSummary.candidatesActivityReviews.length ? <Text>No Activity</Text> : null}
              {weekSummary.candidatesActivityReviews.map((candidateActivityReview, index) => (
                <HStack
                  // eslint-disable-next-line react/no-array-index-key
                  key={`candidate-activity-${weekSummary.dateRange}-${candidateActivityReview.id}-${index}`}
                  mt={4}
                >
                  <Link
                    to={`/role/${activeRole}/candidate/${candidateActivityReview.id}`}
                    as={RouterLink}
                    display="inline-block"
                  >
                    {candidateActivityReview.name}
                  </Link>
                  <Divider w={2} />
                  <Text variant="caption">
                    {`${candidateActivityReview.activity}  ${candidateActivityReview.status.title} on ${candidateActivityReview.status.date}`}
                  </Text>
                </HStack>
              ))}
              {/* <h5 className="mt-4 mb-4">Additional Notes</h5> */}
              {/* <p>- 5 screenings scheduled for next week</p> */}
              {/* <p>- Focusing on building a strong pipeline</p> */}
            </CardBody>
          </Card>
        ))}
      </VStack>
    </RoleWeeklyReportsLayout>
  );
}
