export enum EuropeanCountry {
  Albania = 'Albania',
  Andorra = 'Andorra',
  Austria = 'Austria',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Bulgaria = 'Bulgaria',
  Croatia = 'Croatia',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czechia',
  Denmark = 'Denmark',
  Estonia = 'Estonia',
  Finland = 'Finland',
  France = 'France',
  Germany = 'Germany',
  Greece = 'Greece',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  Ireland = 'Ireland',
  Italy = 'Italy',
  Kosovo = 'Kosovo',
  Latvia = 'Latvia',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Malta = 'Malta',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Montenegro = 'Montenegro',
  Netherlands = 'Netherlands',
  NorthMacedonia = 'North Macedonia',
  Norway = 'Norway',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Romania = 'Romania',
  // Russia = 'Russia',
  SanMarino = 'San Marino',
  Serbia = 'Serbia',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  Spain = 'Spain',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Ukraine = 'Ukraine',
  UnitedKingdom = 'United Kingdom',
  VaticanCity = 'Vatican City',
}

/**
 * This is the same CountryEnum we use in the graphql service
 * @see https://github.com/terminalinc/product/blob/46072787f0f4aee6ddc5da6ced8c5b5758d08f91/graphql-service/src/candidate/actions/explore_candidates/explore_candidates_action.py#L35-L45
 */
export enum CountryChoice {
  Canada = 'Canada',
  Chile = 'Chile',
  Colombia = 'Colombia',
  CostaRica = 'Costa Rica',
  Mexico = 'Mexico',
  Poland = 'Poland',
  Spain = 'Spain',
  Hungary = 'Hungary',
  Romania = 'Romania',
}
