/* eslint-disable react/no-unstable-nested-components */
import { Box, Button, CheckCircleSolidIcon, Flex, Link, Text } from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';
import { AppSubheader, PageLayout, PageLayoutPaddedBody } from 'talent-hub/components';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'talent-hub/components/DataTable';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { FeedbackRatinglabelValues } from 'talent-hub/shared/features/roles/subfeatures/candidate-feedback/CandidateFeedback.types';
import {
  NoFaceFeedbackIcon,
  StrongNoFaceFeedbackIcon,
  StrongYesFaceFeedbackIcon,
  YesFaceFeedbackIcon,
} from 'talent-hub/shared/features/roles/components';
import type { SelectDashboardCustomerActivititesQuery } from './data';
import type { serializerForCustomerActivityDashboard } from './CustomerActivityDashboard.serializer';

const createRetoolRoutes = {
  editOrganization: (orgId?: number) =>
    `https://terminal.retool.com/apps/b32a9300-b848-11eb-a33c-93bdc17fc6fc/Admin%20Tools/Organizations%20-%20Edit%20Organization=${orgId}`,
  manageHubUsers: (orgId?: number) =>
    `https://terminal.retool.com/editor/4d5910a0-b4f2-11eb-ad85-470f3d13b027/Admin%20Tools/Users%20-%20Manage%20Client%20Users#org=${orgId}`,
  editJob: (jobId?: number) =>
    `https://terminal.retool.com/apps/1e3184c6-475b-11ee-83c5-17cc86a44ca3/Job%20Management/Job%20Management%20-%20Edit%20Job#jobID=${jobId}`,
};

// Keeping this in the code as it will likely be used soon
// const CompletetnesssStatusMap_Hot = {
//   incomplete: '❌',
//   complete: '✔',
//   'need-inspection': '❔',
// };

const CompletetnesssStatusMap_LessImportant = {
  incomplete: '✖',
  complete: '✔',
  'need-inspection': '❔',
};

const feedbackRatingIconMapping = {
  [FeedbackRatinglabelValues.STRONG_YES]: StrongYesFaceFeedbackIcon,
  [FeedbackRatinglabelValues.YES]: YesFaceFeedbackIcon,
  [FeedbackRatinglabelValues.NO]: NoFaceFeedbackIcon,
  [FeedbackRatinglabelValues.STRONG_NO]: StrongNoFaceFeedbackIcon,
  [FeedbackRatinglabelValues.default]: null,
};

const submissionAcceptanceStatuses_IconMapping = {
  Accepted: StrongYesFaceFeedbackIcon,
  Rejected: StrongNoFaceFeedbackIcon,
};

function RecentInterviewFeedbacks({
  rows,
  onFeedbackClick,
}: {
  rows: ReturnType<typeof serializerForCustomerActivityDashboard>['recent_interview_feedbacks'];
  onFeedbackClick: (orgId: number) => void;
}) {
  const column_helper =
    createColumnHelper<
      ReturnType<
        typeof serializerForCustomerActivityDashboard
      >['recent_interview_feedbacks'][number]
    >();

  // Add slack channel column? organization_slack_channel

  const columns = [
    column_helper.accessor('customer.name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(rows?.[info.row.index as number]?.customer.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    column_helper.accessor('rating', {
      cell: (info) => {
        // @ts-ignore
        const Icon = feedbackRatingIconMapping[FeedbackRatinglabelValues[info.getValue()]];
        return Icon ? (
          <Flex alignItems="center">
            <Icon color="accent.darkest" fontSize="1.3rem" mr={3} fill="none" />
            <Text>{FeedbackRatinglabelValues[info.getValue()]}</Text>
          </Flex>
        ) : (
          <Text variant="label" color="text.primary">
            {FeedbackRatinglabelValues[info.getValue()]}
          </Text>
        );
      },
      header: 'Rating',
    }),
    column_helper.accessor('comment', {
      cell: (info) => (
        <Flex flexWrap="wrap">
          <Link
            // add email to in the href
            href={`mailto:${rows?.[info.row.index as number]?.reviewer.email}`}
            style={{
              textDecoration: 'none',
            }}
            target="_blank"
            title={rows?.[info.row.index as number]?.reviewer.email}
          >
            {rows?.[info.row.index as number]?.reviewer.full_name}
          </Link>
          &nbsp;
          <Text>wrote a</Text>
          &nbsp;
          <Link
            as={RouterLink}
            to={`/role/${rows?.[info.row.index as number]?.role.id}/candidate/${
              rows?.[info.row.index as number]?.candidate.id
            }/feedback`}
            style={{
              textDecoration: 'none',
            }}
            title={info.getValue()}
            onClick={() => {
              const orgID = rows?.[info.row.index as number]?.customer.id;
              if (orgID) {
                onFeedbackClick(orgID);
              }
            }}
          >
            feedback
          </Link>
          &nbsp;
          <Text>for</Text>
          &nbsp;
          <Link
            as={RouterLink}
            to={`/role/${rows?.[info.row.index as number]?.role.id}/candidate/${
              rows?.[info.row.index as number]?.candidate.id
            }`}
            style={{
              textDecoration: 'none',
            }}
          >
            {rows?.[info.row.index as number]?.candidate.full_name}
          </Link>
        </Flex>
      ),
      header: 'Action',
    }),

    column_helper.accessor('role.title', {
      cell: (info) => (
        <Link
          as="a"
          href={createRetoolRoutes.editJob(rows?.[info.row.index as number]?.role.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Role',
    }),
    column_helper.accessor('date', {
      cell: (info) => info.getValue(),
      header: 'Updated At',
    }),
  ];

  return <DataTable columns={columns} data={rows} />;
}

function RecentProfileViews({
  recent_profile_views,
}: {
  recent_profile_views: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recent_profile_views'];
}) {
  const column_helper =
    createColumnHelper<
      ReturnType<typeof serializerForCustomerActivityDashboard>['recent_profile_views'][number]
    >();

  // Add slack channel column? organization_slack_channel

  const columns = [
    column_helper.accessor('customer.name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(
            recent_profile_views?.[info.row.index as number]?.customer.id,
          )}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    column_helper.accessor('viewer.full_name', {
      cell: (info) => (
        <Link
          // add email to in the href
          href={`mailto:${recent_profile_views?.[info.row.index as number]?.viewer.email}`}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
          title={recent_profile_views?.[info.row.index as number]?.viewer.email}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Viewer',
    }),
    column_helper.accessor('candidate.full_name', {
      cell: (info) =>
        recent_profile_views?.[info.row.index as number]?.candidate != null ? (
          <Link
            as={RouterLink}
            to={`/explore-candidates/candidate/${
              recent_profile_views?.[info.row.index as number]?.candidate?.id
            }`}
            style={{
              textDecoration: 'none',
            }}
            target="_blank"
          >
            {recent_profile_views?.[info.row.index as number]?.candidate?.full_name}
          </Link>
        ) : (
          <Text>a deleted candidate</Text>
        ),
      header: 'Candidate',
    }),
    column_helper.accessor('candidate.roles', {
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: 'Candidate Roles',
    }),
    column_helper.accessor('date', {
      cell: (info) => moment(info.getValue()).format('MMM D, YYYY h:mm A'),
      header: 'Date',
    }),
  ];

  return <DataTable columns={columns} data={recent_profile_views} />;
}

function RecentSubmissionAcceptanceStatuses({
  rows,
}: {
  rows: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recent_submissions_acceptance_statuses'];
}) {
  const column_helper =
    createColumnHelper<
      ReturnType<
        typeof serializerForCustomerActivityDashboard
      >['recent_submissions_acceptance_statuses'][number]
    >();

  const columns = [
    column_helper.accessor('customer.name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(rows?.[info.row.index as number]?.customer?.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    column_helper.accessor('role.title', {
      cell: (info) => (
        <Link
          as="a"
          href={createRetoolRoutes.editJob(rows?.[info.row.index as number]?.role?.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Role',
    }),
    column_helper.accessor('status', {
      cell: (info) => {
        // @ts-ignore
        const Icon = submissionAcceptanceStatuses_IconMapping[info.getValue()];
        return Icon ? (
          <Flex alignItems="center">
            <Icon color="accent.darkest" fontSize="1.3rem" mr={3} fill="none" />
            <Text>{info.getValue()}</Text>
          </Flex>
        ) : (
          <Text variant="label" color="text.primary">
            {info.getValue()}
          </Text>
        );
      },
      header: 'Decision',
    }),
    column_helper.accessor('candidate.full_name', {
      cell: (info) => (
        <Link
          as={RouterLink}
          to={`/explore-candidates/candidate/${rows?.[info.row.index as number]?.candidate?.id}`}
          style={{
            textDecoration: 'none',
          }}
          title={info.getValue()}
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Candidate',
    }),
    column_helper.accessor('date', {
      cell: (info) => info.getValue(),
      header: 'Date',
    }),
  ];

  return <DataTable columns={columns} data={rows} />;
}

function RecentlyCreatedJobs({
  rows,
}: {
  rows: ReturnType<typeof serializerForCustomerActivityDashboard>['recentCreatedJobs'];
}) {
  const columnHelper =
    createColumnHelper<
      ReturnType<typeof serializerForCustomerActivityDashboard>['recentCreatedJobs'][number]
    >();

  const columns = [
    columnHelper.accessor('organization.name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(
            rows?.[info.row.index as number]?.organization_id,
          )}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    columnHelper.accessor('title', {
      cell: (info) => (
        <Link
          href={`https://terminal.retool.com/editor/1e3184c6-475b-11ee-83c5-17cc86a44ca3/Job%20Management/Job%20Management%20-%20Edit%20Job#=&jobID=${
            rows?.[info.row.index as number]?.id
          }`}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Role Name',
    }),
    columnHelper.accessor('updated_at', {
      cell: (info) => moment(info.getValue()).format('MMM D, YYYY h:mm A'),
      header: 'Updated At',
    }),
  ];

  if (rows.length === 0) {
    return (
      <Flex>
        <Text>No jobs pending approval</Text>
        <CheckCircleSolidIcon color="accent.darker" ml={2} mt={1} fontSize="lg" />
      </Flex>
    );
  }
  return <DataTable columns={columns} data={rows} />;
}

function RecentJobActivities({
  rows,
}: {
  rows: ReturnType<typeof serializerForCustomerActivityDashboard>['allJobsActivities'];
}) {
  const columnHelper =
    createColumnHelper<
      ReturnType<typeof serializerForCustomerActivityDashboard>['allJobsActivities'][number]
    >();

  const columns = [
    columnHelper.accessor('organization.name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(
            rows?.[info.row.index as number]?.organization_id,
          )}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    columnHelper.accessor('status', {
      cell: (info) => info.getValue(),
      header: 'Activity',
    }),
    columnHelper.accessor('title', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editJob(rows?.[info.row.index as number]?.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Role',
    }),
    columnHelper.accessor('updated_at', {
      cell: (info) => moment(info.getValue()).format('MMM D, YYYY h:mm A'),
      header: 'Updated At',
    }),
  ];

  // @ts-ignore
  return <DataTable columns={columns} data={rows} />;
}

function RecentlyCreatedCustomers({
  rows,
}: {
  rows: ReturnType<typeof serializerForCustomerActivityDashboard>['recentCreatedCustomer'];
}) {
  const columnHelper =
    createColumnHelper<
      ReturnType<typeof serializerForCustomerActivityDashboard>['recentCreatedCustomer'][number]
    >();

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => (
        <Link
          href={createRetoolRoutes.editOrganization(rows?.[info.row.index as number]?.id)}
          style={{
            textDecoration: 'none',
          }}
          target="_blank"
        >
          {info.getValue()}
        </Link>
      ),
      header: 'Customer',
    }),
    columnHelper.accessor('id', {
      header: 'ID',
    }),

    columnHelper.accessor('numOfUsers', {
      header: 'Users',
      meta: {
        isNumeric: true,
      },
    }),

    columnHelper.accessor('dayforceSetupStatus', {
      cell: (info) =>
        CompletetnesssStatusMap_LessImportant[
          info.getValue() as 'incomplete' | 'complete' | 'need-inspection'
        ],
      header: 'Dayforce',
    }),
    columnHelper.accessor('isDirectoryAdded', {
      cell: (info) => (info.getValue() ? '✔' : '❌'),
      header: 'Directory Added',
    }),
    columnHelper.accessor('isDomainAdded', {
      cell: (info) => (info.getValue() ? '✔' : '❌'),
      header: 'Domain Added',
    }),
    columnHelper.accessor('isLogoAdded', {
      cell: (info) => (info.getValue() ? '✔' : '❌'),
      header: 'Logo Added',
    }),
    columnHelper.accessor('slackChannel', {
      cell: (info) => (info.getValue() ? '✔' : '❌'),
      header: 'Slack Channel',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <Button
          variant="solid"
          colorScheme="brand"
          size="xs"
          as="a"
          target="_blank"
          href={createRetoolRoutes.manageHubUsers(rows?.[info.row.index as number]?.id)}
        >
          Users
        </Button>
      ),
    }),
  ];

  // @ts-ignore
  return <DataTable columns={columns} data={rows} />;
}

export function CustomerActivityDashboard({
  isLoading,
  pageLayoutProps,
  onChangeOrgRequest,
  recentlyCreatedsJobs,
  recentlyCreatedCustomers,
  allJobsActivities,
  recent_interview_feedbacks,
  recent_profile_views,
  recent_submissions_acceptance_statuses,
}: {
  isLoading: boolean;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  onChangeOrgRequest: (orgId: number) => void;
  recentlyCreatedsJobs: SelectDashboardCustomerActivititesQuery['recently_createds_jobs'];
  recentlyCreatedCustomers: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recentCreatedCustomer'];
  allJobsActivities: ReturnType<typeof serializerForCustomerActivityDashboard>['allJobsActivities'];
  recent_interview_feedbacks: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recent_interview_feedbacks'];
  recent_profile_views: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recent_profile_views'];
  recent_submissions_acceptance_statuses: ReturnType<
    typeof serializerForCustomerActivityDashboard
  >['recent_submissions_acceptance_statuses'];
}) {
  if (isLoading)
    return (
      <PageLayout headerTitle="Change Organization" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <AppSubheader title="Select Your New Organization" />
          <BlankScreenLoading />
        </PageLayoutPaddedBody>
      </PageLayout>
    );

  return (
    <PageLayout headerTitle="Customer Activity Dashboard" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <Box mt="4" maxW="900px">
          <AppSubheader title="Submitted Jobs Ready for Approval" />
          <RecentlyCreatedJobs rows={recentlyCreatedsJobs} />
        </Box>
        <Box mt="8">
          <AppSubheader title="Most Recent Signed Up Customers" />
          <RecentlyCreatedCustomers rows={recentlyCreatedCustomers} />
        </Box>
        <Box mt="8">
          <AppSubheader title="Recent Profile Views" />
          <RecentProfileViews recent_profile_views={recent_profile_views} />
        </Box>
        <Box mt="8">
          <AppSubheader title="Accept/Reject Submissions" />
          <RecentSubmissionAcceptanceStatuses rows={recent_submissions_acceptance_statuses} />
        </Box>
        <Box mt="8">
          <AppSubheader title="Recent Interview Feedbacks" />
          <RecentInterviewFeedbacks
            rows={recent_interview_feedbacks}
            onFeedbackClick={onChangeOrgRequest}
          />
        </Box>
        <Box mt="8">
          <AppSubheader title="Recent Job Activities" />
          <RecentJobActivities rows={allJobsActivities} />
        </Box>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
