import { toHasuraDate, createDaysAgoDates } from 'global/utils';
import {
  Employment_Type_Choices_Enum,
  Job_Status_Choices_Enum,
  Role_Choices_Enum,
} from 'global/types';

import type {
  RolePerson,
  RoleApplicantWorkflow,
  RoleWorkflowStatus,
  RolePosting,
  RolePosition,
  RoleDataQuery,
} from './graphql';

export const daysToGo = createDaysAgoDates([1, 4, 5, 10, 17, 20, 93]);

function createPerson(overwrites: Partial<RolePerson> = {}): RolePerson {
  return {
    candidate: {
      id: 1,
      firstname: 'Lindsay',
      lastname: 'Brown',
      email: 'LindsayBrown@yahoo.com',
      work_experience: [],
    },
    email: 'LindsayBrown@yahoo.com',
    profile_id: 328,
    firstname: null,
    lastname: null,
    ...overwrites,
  };
}

function createPosting_applicantWorkflow_statuses(
  datesAndStatuses: [string, string][],
): RoleWorkflowStatus[] {
  return datesAndStatuses.map(([date, status]) => ({
    // date: toHasuraDate(date),
    date,
    status: {
      title: status,
    },
  }));
}

function createPosting_applicantWorkflow(
  overwrites: Partial<RoleApplicantWorkflow> = {},
): RoleApplicantWorkflow {
  return {
    job_profile_id: 1062,
    icims_person: createPerson(),
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [toHasuraDate('2020-12-16'), 'Internal Interview - Interview to be Scheduled'],
    ]),
    ...overwrites,
  };
}

function createPosting(
  overwrites: Partial<RolePosting> & {
    accurate_date?: string;
    created_at?: string;
    last_approved?: string;
  } = {},
): RolePosting {
  const {
    created_at = toHasuraDate('2020-11-11'),
    accurate_date = null, // TODO: change this to get closer to the shape of RolePosting
    last_approved = null,
    ...restOverwrites
  } = overwrites;

  return {
    id: 1042,
    name: 'Fast food restaurant manager',
    positions: [],
    applicant_workflows: [],
    posting: null,
    is_position: false,
    initial_headcount: 3,
    // TODO: ask about if parse here or when is called
    // created_at: toHasuraDate(created_at),
    created_at,
    accurate_date: {
      // TODO: ask about if parse here or when is called
      created_date: accurate_date && accurate_date,
      // TODO: ask about if parse here or when is called
      last_approved: last_approved && last_approved,
    },
    job: {
      status: Job_Status_Choices_Enum.Approved,
      tp_kickoff_date: null,
    },
    icims_folder: {
      value: 'Approved',
    },
    locations: [
      {
        icims_location: { readable_locations: [], value: 'Toronto, Canada' },
      },
      {
        icims_location: { readable_locations: [], value: 'Remote, Canada' },
      },
    ],
    ...restOverwrites,
  };
}

function createPostingPosition(
  overwrites: Partial<RolePosition> & {
    accurate_date?: string; // TODO: Accurate data has a different type why are we defining two types for it
    created_at?: string;
    last_approved?: string;
  } = {},
): RolePosition {
  return createPosting({ is_position: true, posting: 1042, ...overwrites });
}

function createJob(
  overwrites: Partial<RoleDataQuery['icims_job'][number]['job']> = {},
): RoleDataQuery['icims_job'][number]['job'] {
  return {
    id: 1,
    title: 'Fullstack Engineer',
    about:
      'American agriculture has been facing a labor crisis for decades. Seso is addressing this problem by building the first end-to-end recruiting and workforce management platform for the industry\n\nSeso’s mission is to provide agricultural workers with meaningful, safe employment opportunities; provide security for the most critical aspects of America’s food production system; and reduce admin costs to employers through software. We are addressing American farmers’ biggest pain point - access to qualified labor - through a software-enabled labor solution that automates the H-2A visa. Our  comprehensive HR platform not only streamlines and automates the H-2A program, but helps agribusiness seamlessly integrate their efforts across recruitment, onboarding, payroll, and insurance while providing workers access to financial services for the first time! \n\nSeso has raised over $30m from Tier I investors including Index, Founders Fund and NfX, and has been recognized with awards like Forbes Rising Stars and Andreesen Horowitzs’, American Dynamism 50.',
    what_youll_do:
      "At Seso, we are passionate about building.\n\nWe focus heavily on Node.js (TypeScript), React, PostgreSQL, and Redis. Our codebase is a joy to build in - deployments (and rollbacks) are a breeze. We are obsessed with typing, tests, and iterative releases. Feature flags are a way of life. We deploy to production multiple times per day and we are only getting faster.\n\nIf these words resonate with you, you're in the right place.",
    what_youll_bring:
      '•Minimum of 5 years experience, preferably in a startup SaaS environment \n•Excellent communication skills\n•Highly-skilled in coding with typed Javascript (Typescript/Flow)\n•Background using testing frameworks like Mocha/Chai/Jest or any modern equivalent\n•Strong history working with modern client-side Javascript frameworks like React/Vue\n•Expertise in building and testing REST-ful/RPC APIs in Node.js/Express/Next\n•Seasoned in building, maintaining, and troubleshooting CI/Deployment pipelines — think Docker',
    role_type: Role_Choices_Enum.Fsd,
    employment_type: Employment_Type_Choices_Enum.FullTime,
    job_required_skills: [
      {
        skill: {
          id: 12,
          name: 'Typescript',
        },
      },
      {
        skill: {
          id: 8,
          name: 'Node',
        },
      },
      {
        skill: {
          id: 2,
          name: 'React',
        },
      },
    ],
    job_tech_stack: [
      {
        skill: {
          name: 'React',
          id: 2,
        },
      },
      {
        skill: {
          name: 'JavaScript',
          id: 3,
        },
      },
      {
        skill: {
          name: 'Node',
          id: 8,
        },
      },
      {
        skill: {
          name: 'Typescript',
          id: 12,
        },
      },
    ],
    job_acceptable_locations: [
      {
        location: {
          country: 'Colombia',
        },
      },
      {
        location: {
          country: 'Canada',
        },
      },
      {
        location: {
          country: 'Mexico',
        },
      },
      {
        location: {
          country: 'Chile',
        },
      },
      {
        location: {
          country: 'Costa Rica',
        },
      },
    ],
    tp_kickoff_date: null,
    ...overwrites,
  };
}

function createSelectRoleData(overwrite: Partial<RoleDataQuery> = {}): RoleDataQuery {
  return {
    icims_job: [
      {
        job: createJob(),
      },
    ],
    postings: [
      createPosting({
        created_at: toHasuraDate('2020-10-10'),
        initial_headcount: 2,
        applicant_workflows: [createPosting_applicantWorkflow()],
        positions: [createPostingPosition({ id: 100 }), createPostingPosition({ id: 101 })],
      }),
    ],
    first_submission: [
      {
        created_at: toHasuraDate('2020-10-01'),
      },
    ],
    first_interview_scheduled: [
      {
        created_at: toHasuraDate('2020-11-02'),
      },
    ],
    first_offer_accepted: [
      {
        created_at: toHasuraDate('2020-12-28'),
      },
    ],
    requirementsList: [],
    ...overwrite,
  };
}

function create_inProgressSubmission_candidate(
  overwrites: Partial<RoleApplicantWorkflow> = {},
  lastStatusDate = daysToGo[17],
) {
  return createPosting_applicantWorkflow({
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [daysToGo[20], 'New Applicant - External Portal'],
      [lastStatusDate, 'Submitted to Client - Submitted'],
    ]),
    ...overwrites,
  });
}

function create_rejectedInInterview_candidate(
  overwrites: Partial<RoleApplicantWorkflow> = {},
  lastStatusDate = daysToGo[10],
) {
  return createPosting_applicantWorkflow({
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [daysToGo[20], 'New Applicant - External Portal'],
      [daysToGo[17], 'Submitted to Client - Submitted'],
      [daysToGo[17], 'Client Review - Interview Scheduled'],
      [lastStatusDate, 'Candidate Withdrew - Self-Withdrew (Portal)'],
    ]),
    ...overwrites,
  });
}

function create_hired_skippedAll_candidate(
  overwrites: Partial<RoleApplicantWorkflow> = {},
  lastStatusDate = daysToGo[17],
) {
  return createPosting_applicantWorkflow({
    workflow_statuses: createPosting_applicantWorkflow_statuses([
      [daysToGo[20], 'New Applicant - External Portal'],
      [lastStatusDate, 'Hired - Placed'],
    ]),
    ...overwrites,
  });
}

export const SelectRoleDataFixture = {
  createData: createSelectRoleData,
  createPerson,
  createPosting,
  createPostingPosition,
  createPosting_applicantWorkflow,
  createPosting_applicantWorkflow_statuses,
  results: {
    lotsOfCandidates_inAllStages: createSelectRoleData({
      postings: [
        createPosting({
          created_at: toHasuraDate('2020-10-10'),
          initial_headcount: 2,
          applicant_workflows: [
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 123,
                  firstname: 'David',
                  lastname: 'Harrison',
                  email: 'smithchristine@yahoo.com',
                  work_experience: [],
                },
                profile_id: 572,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-09-16'), 'Client Review - Interview Completed'],
                [toHasuraDate('2020-07-13'), 'Internal Interview - Interview to be Scheduled'],
                [toHasuraDate('2020-07-02'), 'Terminal Screen - Terminal Screen Completed'],
                [toHasuraDate('2020-05-02'), 'Incomplete - Incomplete'],
                [toHasuraDate('2020-04-29'), 'New Applicant - External Portal'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 12,
                  firstname: 'Cynthia',
                  lastname: 'Baldwin',
                  email: 'dawn98@dudley.com',
                  work_experience: [],
                },
                profile_id: 11,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-16'), 'Offer - New Offer'],
                [toHasuraDate('2020-10-16'), 'Client Review - Interview Completed'],
                [toHasuraDate('2020-09-01'), 'Client Review - Interview Scheduled'],
                [toHasuraDate('2020-08-07'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-07-27'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-07-20'), 'Submitted to Review - Submitted to Review'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 32,
                  firstname: 'Kristen',
                  lastname: 'Adkens',
                  email: 'kristenadkins@king.com',
                  work_experience: [],
                },
                profile_id: 967,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-19'), 'Rejected - Client Rejected - Client Portal Rejected'],
                [toHasuraDate('2020-10-12'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-05-12'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 93,
                  firstname: 'Donald',
                  lastname: 'Knight',
                  email: 'wrightjennifer@king.com',
                  work_experience: [],
                },
                profile_id: 504,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-19'), 'Offer - New Offer'],
                [toHasuraDate('2020-10-06'), 'Client Review - Interview Completed'],
                [toHasuraDate('2020-10-05'), 'Client Review - Interview Scheduled'],
                [toHasuraDate('2020-10-01'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-05-12'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 36,
                  firstname: 'Daniel',
                  lastname: 'Curry',
                  email: 'justin41@saunders.info',
                  work_experience: [],
                },
                profile_id: 34,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-14'), 'Hired - Placed'],
                [toHasuraDate('2020-09-16'), 'Client Review - Interview Completed'],
                [toHasuraDate('2020-09-15'), 'Client Review - Interview Scheduled'],
                [toHasuraDate('2020-09-12'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-08-19'), 'Submitted to Client - Submitted'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 56,
                  firstname: 'Brandon',
                  lastname: 'Warner',
                  email: 'ugibson@clark.com',
                  work_experience: [],
                },
                profile_id: 212,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-02'), 'Terminal Screen - Terminal Screen Scheduled'],
                [toHasuraDate('2020-09-16'), 'Terminal Screen - Terminal Screen to be Scheduled'],
                [toHasuraDate('2020-06-29'), 'Terminal Screen - Terminal Review'],
                [toHasuraDate('2020-05-18'), 'Incomplete - Incomplete'],
                [toHasuraDate('2020-04-27'), 'New Applicant - External Portal'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 65,
                  firstname: 'Roberto',
                  lastname: 'Wood',
                  email: 'dhenderson@hotmail.com',
                  work_experience: [],
                },
                profile_id: 138,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-09-11'), 'Incomplete - Incomplete'],
                [toHasuraDate('2020-07-11'), 'New Applicant - External Portal'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 64,
                  firstname: 'Kevin',
                  lastname: 'Walker',
                  email: 'kristasimpson@gmail.com',
                  work_experience: [],
                },
                profile_id: 733,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-07-13'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 35,
                  firstname: 'Michelle',
                  lastname: 'Figueroa',
                  email: 'psanchez@hotmail.com',
                  work_experience: [],
                },
                profile_id: 702,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-08-28'), 'Internal Interview - Interview Completed'],
                [toHasuraDate('2020-06-20'), 'Incomplete - Incomplete'],
                [toHasuraDate('2020-05-25'), 'New Applicant - External Portal'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 546,
                  firstname: 'Thomas',
                  lastname: 'Williams',
                  email: 'oflores@yahoo.com',
                  work_experience: [],
                },
                profile_id: 390,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-09-23'), 'Submitted to Review - Submitted to Review'],
                [toHasuraDate('2020-07-02'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 543,
                  firstname: 'April',
                  lastname: 'Cordova',
                  email: 'amoreno@hotmail.com',
                  work_experience: [],
                },
                profile_id: 427,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-09-12'), 'Submitted to Client - Client Portal Advance'],
                [toHasuraDate('2020-08-19'), 'Submitted to Client - Submitted'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 542,
                  firstname: 'Jean',
                  lastname: 'Castaneda',
                  email: 'michelle00@gmail.com',
                  work_experience: [],
                },
                profile_id: 902,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-21'), 'Submitted to Client - Submitted'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 541,
                  firstname: 'Stephanie',
                  lastname: 'Walters',
                  email: 'james89@yahoo.com',
                  work_experience: [],
                },
                profile_id: 225,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-12'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-10-08'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-09-09'), 'Submitted to Review - Submitted to Review'],
                [toHasuraDate('2020-05-06'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 540,
                  firstname: 'Erica',
                  lastname: 'Campbell',
                  email: 'kimberlygraham@hotmail.com',
                  work_experience: [],
                },
                profile_id: 881,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-09-24'), 'Client Review - Interview Scheduled'],
                [toHasuraDate('2020-09-16'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-08-15'), 'Submitted to Review - Submitted to Review'],
                [toHasuraDate('2020-08-13'), 'Internal Interview - Interview Scheduled'],
                [toHasuraDate('2020-07-27'), 'Internal Interview - Interview to be Scheduled'],
                [toHasuraDate('2020-06-04'), 'New Applicant - Sourced'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 526,
                  firstname: 'Joe',
                  lastname: 'Meyers',
                  email: 'shaun92@hutchinson.com',
                  work_experience: [],
                },
                profile_id: 473,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-05-15'), 'Client Review - Interview Completed'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 526,
                  firstname: 'Michael',
                  lastname: 'Lewis',
                  email: 'bondpatrick@hotmail.com',
                  work_experience: [],
                },
                profile_id: 116,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [toHasuraDate('2020-10-17'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-09-22'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-09-15'), 'Internal Interview - Interview Scheduled'],
                [toHasuraDate('2020-09-02'), 'Internal Interview - Interview to be Scheduled'],
                [toHasuraDate('2020-04-26'), 'New Applicant - External Portal'],
              ]),
            },
            {
              job_profile_id: 1063,
              icims_person: createPerson({
                candidate: {
                  id: 596,
                  firstname: 'Tara',
                  lastname: 'Ward',
                  email: 'adriananderson@hotmail.com',
                  work_experience: [],
                },
                profile_id: 817,
              }),
              workflow_statuses: createPosting_applicantWorkflow_statuses([
                [
                  toHasuraDate('2020-10-22'),
                  'Rejected - Terminal Rejected - Took Another Opportunity',
                ],
                [toHasuraDate('2020-10-18'), 'Offer - Offer Accepted'],
                [toHasuraDate('2020-09-28'), 'Offer - Offer Extended'],
                [toHasuraDate('2020-09-23'), 'Offer - Background Check Initiated'],
                [toHasuraDate('2020-08-29'), 'Offer - New Offer'],
                [toHasuraDate('2020-08-29'), 'Client Review - Interview Completed'],
                [toHasuraDate('2020-08-27'), 'Client Review - Interview Scheduled'],
                [toHasuraDate('2020-07-21'), 'Client Review - Interview to be Scheduled'],
                [toHasuraDate('2020-07-10'), 'Submitted to Client - Submitted'],
                [toHasuraDate('2020-06-03'), 'Terminal Screen - Terminal Review'],
                [toHasuraDate('2020-05-12'), 'New Applicant - Sourced'],
              ]),
            },
          ],
        }),
      ],
    }),
    applicantWorkflow: {
      hired_skippedAll_candidate: create_hired_skippedAll_candidate,
      rejectedInInterview_candidate: create_rejectedInInterview_candidate,
      inProgressSubmission_candidate: create_inProgressSubmission_candidate,
    },
  },
};
