import { Icon, Heading, Box } from '@terminal/design-system';

export function AuthMessage({
  children,
  icon,
  title,
}: {
  children?: React.ReactNode;
  icon: React.ReactNode;
  title: string;
}) {
  return (
    <Box textAlign="center">
      <Icon fontSize="4xl" mb={7}>
        {icon}
      </Icon>
      <Heading variant="heading-2" mb={5}>
        {title}
      </Heading>
      {children}
    </Box>
  );
}
