import { textTheme } from '../Text/Text.theme';

type Dict = Record<string, any>;
const parts = ['container', 'title', 'description', 'icon'];

const baseStyle = {
  container: {
    borderRadius: 0,
    px: 4,
    py: 3,
  },
  title: {
    ...textTheme.variants.label,
    lineHeight: 6,
    mr: 3,
  },
  description: {
    ...textTheme.variants.body,
    lineHeight: 6,
  },
  icon: {
    flexShrink: 0,
    mr: 3,
    w: 5,
    h: 6,
  },
};

const StatusColor = {
  error: {
    borderColor: 'ui.error',
    bg: 'ui.lighter.error',
    color: 'ui.error', // used for the close button
  },
  success: {
    borderColor: 'ui.success',
    bg: 'ui.lighter.success',
    color: 'ui.success', // used for the close button
  },
  warning: {
    borderColor: 'ui.warning',
    bg: 'ui.lighter.warning',
    color: 'ui.warning', // used for the close button
  },
  info: {
    borderColor: 'ui.lighter',
    bg: 'ui.lighter.info',
    color: 'ui.lighter.info', // used for the close button
  },
};

function selectColorByStatus(status: keyof typeof StatusColor) {
  return StatusColor[status] ? StatusColor[status] : StatusColor.success;
}

function variantLeftAccent(props: Dict) {
  const { status } = props;
  const { borderColor, bg } = selectColorByStatus(status);

  return {
    container: {
      pl: 3,
      borderLeft: '4px',
      borderColor,
      bg,
      color: 'text.primary', // used for close button
    },
    icon: {
      color: borderColor,
    },
  };
}

function variantTopAccent(props: Dict) {
  const { status } = props;
  const { borderColor, bg } = selectColorByStatus(status);

  return {
    container: {
      pl: 3,
      borderTop: '4px',
      borderColor,
      bg,
      color: 'text.primary', // used for close button
    },
    icon: {
      color: 'text.primary',
    },
  };
}

function variantSolid(props: Dict) {
  const { status } = props;
  const { borderColor } = selectColorByStatus(status);

  return {
    container: {
      pl: 3,
      bg: borderColor,
      color: 'text.primary', // used for close button
    },
    title: {
      color: 'white',
    },
    description: {
      color: 'white',
    },
    icon: {
      color: 'text.primary',
    },
  };
}

function variantSubtle(props: Dict) {
  const { status } = props;
  const { bg } = selectColorByStatus(status);

  return {
    container: {
      pl: 3,
      bg,
      color: 'text.primary', // used for close button
    },
  };
}

const variants = {
  subtle: variantSubtle,
  'left-accent': variantLeftAccent,
  solid: variantSolid,
  'top-accent': variantTopAccent,
};

export type AlertVariant = keyof typeof variants;

const defaultProps = {
  variant: 'left-accent',
};

export const alertsTheme = {
  parts,
  baseStyle,
  variants,
  defaultProps,
};
