import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import { getColor } from '@chakra-ui/theme-tools';
import type { SystemStyleObject } from '@chakra-ui/react';
import type {
  SystemStyleFunction,
  PartsStyleFunction,
  PartsStyleObject,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools';

import { textTheme } from '../Text';

function filledStyle(props: StyleFunctionProps): SystemStyleObject {
  const { colorScheme: c, theme: t, isIndeterminate, hasStripe } = props;

  // TODO: Fix stripe color
  const stripeStyle = "mode(generateStripe(), generateStripe('1rem', 'rgba(0,0,0,0.1)'))(props)";

  const bgColor = c;

  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColor(t, bgColor)} 50%,
    transparent 100%
  )`;

  const addStripe = !isIndeterminate && hasStripe;

  return {
    // @ts-ignore
    ...(addStripe && stripeStyle),
    ...(isIndeterminate ? { bgImage: gradient } : { bgColor }),
  };
}

const baseStyleLabel: SystemStyleObject = {
  ...textTheme.variants.caption,
};

const baseStyleTrack = {
  bg: 'brand.lighter',
  borderRadius: 'lg',
};

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
  return {
    transitionProperty: 'common',
    transitionDuration: 'slow',
    ...filledStyle(props),
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack,
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    track: { h: 3 },
  },
  lg: {
    track: { h: 4 },
  },
};

export const progressTheme = {
  parts: parts.keys,
  sizes,
  baseStyle,
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
  },
};
