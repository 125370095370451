import {
  Box,
  SkeletonText,
  Heading,
  Grid,
  VStack,
  Text,
  ArrowRightIcon,
  Badge,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  DropdownIcon,
  ArrowUpwardIcon,
  MinusIcon,
  Flex,
  Stack,
} from '@terminal/design-system';
import { PageLayout, PageLayoutPaddedBody, TableContentLoading } from 'talent-hub/components';
import { Link as RouterLink } from 'react-router-dom';
import { useSortableData } from 'global/utils';
import { NoOpenRoles, EmptyTable, NeedInterviewFeedback, ReadyForReview } from './components/Icons';
import type { CandidateRequiringActionWithStatusInfo } from './ManageCandidates.serializer';

const ArrowHeadUp = <ArrowUpwardIcon h={6} w={8} />;
const ArrowHeadDown = <DropdownIcon h={6} w={8} />;
const NoFilter = <MinusIcon h={6} w={8} />;

function CandidatesRequiringActionTable({
  loading,
  candidates,
  hasOpenRoles,
  isClientReviewer,
}: {
  loading: boolean;
  candidates: CandidateRequiringActionWithStatusInfo[];
  hasOpenRoles: boolean;

  isClientReviewer: boolean;
}) {
  // @ts-ignore // TODO: Fix this ts error
  const { items, requestSort, sortConfig } = useSortableData(candidates, {
    key: 'role',
    direction: 'descending',
  });

  // TODO: Create a shared component/function that improve the Icon logic  https://terminal.atlassian.net/browse/TAL-622
  function selectSortIcon(key: string) {
    if (!sortConfig) {
      return NoFilter;
    }
    if (sortConfig.direction === 'ascending' && sortConfig.key === key) {
      return ArrowHeadUp;
    }
    if (sortConfig.direction === 'descending' && sortConfig.key === key) {
      return ArrowHeadDown;
    }
    return NoFilter;
  }

  if (loading) {
    return (
      <TableContainer whiteSpace="break-spaces">
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Button
                  variant="ghost"
                  rightIcon={selectSortIcon('name')}
                  colorScheme="primary"
                  mt={0}
                  p={0}
                  style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
                >
                  Candidate Name
                </Button>
              </Th>
              <Th>
                <Button
                  variant="ghost"
                  rightIcon={selectSortIcon('role')}
                  colorScheme="primary"
                  mt={0}
                  p={0}
                  style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
                >
                  Role
                </Button>
              </Th>
              <Th>
                <Button
                  variant="ghost"
                  rightIcon={selectSortIcon('name')}
                  colorScheme="primary"
                  mt={0}
                  p={0}
                  style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
                >
                  Status
                </Button>
              </Th>
              <Th>
                <Button
                  variant="ghost"
                  rightIcon={selectSortIcon('name')}
                  colorScheme="primary"
                  mt={0}
                  p={0}
                  style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
                >
                  Date Updated
                </Button>
              </Th>
              <Th>
                <Box w={24} />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <TableContentLoading columns={5} rows={5} />
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
  if (!hasOpenRoles) {
    return (
      <Flex py={32} flexDirection="column" alignItems="center">
        <NoOpenRoles h={16} w={16} />
        <Heading variant="heading-2" mt={6}>
          No Open Roles
        </Heading>
        <Text width="50%" textAlign="center" mt={1}>
          Candidates needing your review for open roles will appear here. Open a new role today to
          get started.
        </Text>
        {!isClientReviewer && (
          <Button variant="solid" mt={6} colorScheme="primary" to="/role/create" as={RouterLink}>
            Open a new role
          </Button>
        )}
      </Flex>
    );
  }

  if (!candidates.length) {
    return (
      <VStack py={32}>
        <EmptyTable h={16} w={16} />
        <Heading variant="heading-2" pt={6}>
          You&apos;re All Caught Up
        </Heading>
        <Text width="50%" textAlign="center" mt={1}>
          You have no candidates needing your review right now. Enjoy the extra free time!
        </Text>
      </VStack>
    );
  }

  return (
    <TableContainer whiteSpace="break-spaces">
      <Table>
        <Thead>
          <Tr>
            <Th>
              <Button
                variant="ghost"
                rightIcon={selectSortIcon('name')}
                colorScheme="primary"
                mt={0}
                p={0}
                onClick={() => requestSort('name')}
                style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
              >
                Candidate Name
              </Button>
            </Th>
            <Th>
              <Button
                variant="ghost"
                rightIcon={selectSortIcon('role')}
                colorScheme="primary"
                mt={0}
                p={0}
                onClick={() => requestSort('role')}
                style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
              >
                Role
              </Button>
            </Th>
            <Th>
              <Button
                variant="ghost"
                rightIcon={selectSortIcon('statusText')}
                colorScheme="primary"
                mt={0}
                p={0}
                onClick={() => requestSort('statusText')}
                style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
              >
                Status
              </Button>
            </Th>
            <Th>
              <Button
                variant="ghost"
                rightIcon={selectSortIcon('date')}
                colorScheme="primary"
                mt={0}
                p={0}
                onClick={() => requestSort('date')}
                style={{ outline: 'none', boxShadow: 'none', background: 'initial' }}
              >
                Date Updated
              </Button>
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {
            // TODO: Fix this ts error
            items.map(({ id, name, role, statusText, jobID, status, date, linkText }: any) => (
              <Tr key={`${id}-${date}`}>
                <Td>
                  <Link
                    variant="label"
                    to={{
                      pathname: `/role/${jobID}/candidate/${id}`,
                      state: { from: 'manage-candidates' },
                    }}
                    as={RouterLink}
                  >
                    {name}
                  </Link>
                </Td>
                <Td>{role}</Td>
                <Td>
                  <Badge fontSize="sm" colorScheme={status}>
                    {statusText}
                  </Badge>
                </Td>
                <Td>{date}</Td>
                <Td>
                  <Button
                    variant="ghost"
                    rightIcon={<ArrowRightIcon />}
                    colorScheme="primary"
                    mt={2}
                    to={{
                      pathname: `/role/${jobID}/candidate/${id}`,
                      state: { from: 'manage-candidates' },
                    }}
                    as={RouterLink}
                  >
                    {linkText}
                  </Button>
                </Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export function ManageCandidates({
  pageLayoutProps,
  candidatesReadyForReviewTitle,
  candidatesWithInterviewFeedbackTitle,
  loading,
  candidatesRequiringAction,
  hasOpenRoles,
  isClientReviewer,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  candidatesReadyForReviewTitle: string;
  candidatesWithInterviewFeedbackTitle: string;
  loading: boolean;
  candidatesRequiringAction: CandidateRequiringActionWithStatusInfo[];
  hasOpenRoles: boolean;
  isClientReviewer: boolean;
}) {
  return (
    <PageLayout headerTitle="Manage Candidates" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <VStack spacing={6}>
          <Grid gap={[4, 4, 6]} templateColumns={['1fr', '1fr', '1fr 1fr']} w="full">
            <Box border="1px solid" borderColor="ui.secondary" px={3} py={5}>
              <Stack direction={['column', 'row']} p={4} spacing={6} alignItems="center">
                <ReadyForReview h={16} w={16} />
                <Box flex="3 1 0" pr={1}>
                  <Heading variant="heading-3">Ready For Review</Heading>
                  <Text>New candidates submitted for your open roles that need your review</Text>
                </Box>
                <SkeletonText isLoaded={!loading} noOfLines={1}>
                  <Heading variant="heading-3">{candidatesReadyForReviewTitle}</Heading>
                </SkeletonText>
              </Stack>
            </Box>
            <Box border="1px solid" borderColor="ui.secondary" px={3} py={5}>
              <Stack direction={['column', 'row']} p={4} spacing={6} alignItems="center">
                <NeedInterviewFeedback h={16} w={16} />
                <Box flex="3 1 0" pr={1}>
                  <Heading variant="heading-3">Need Interview Feedback</Heading>
                  <Text>Candidates actively interviewing for your open roles</Text>
                </Box>
                <SkeletonText isLoaded={!loading} noOfLines={1}>
                  <Heading variant="heading-3">{candidatesWithInterviewFeedbackTitle}</Heading>
                </SkeletonText>
              </Stack>
            </Box>
          </Grid>
          <Box border="1px solid" borderColor="ui.secondary" p={6} w="full">
            <Heading variant="heading-3" mb={5}>
              Candidates To Review
            </Heading>
            <CandidatesRequiringActionTable
              candidates={candidatesRequiringAction}
              loading={loading}
              hasOpenRoles={hasOpenRoles}
              isClientReviewer={isClientReviewer}
            />
          </Box>
        </VStack>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
