import type { useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  ModalFooter,
  Box,
  Text,
  CustomSelect,
} from '@terminal/design-system';
import { useTalentHubFlags } from 'talent-hub/utils';
import type { serializeExploreCandidatesOptions } from '../../explore-candidates/ExploreCandidates.serializer';
import { InvitationSubmittedIcon, InvitationIcon } from '../../../components';

export function InviteToInterviewModal({
  inviteModal,
  activePostings,
  candidateName,
  candidateLastName,
  form,
  openPosition,
}: {
  openPosition?: string;
  inviteModal: {
    isOpen: boolean;
    onClose: () => void;
    view: 'Submitted' | 'Pending';
  };
  activePostings: ReturnType<typeof serializeExploreCandidatesOptions>['activePostings'];
  candidateName: string;
  candidateLastName: string;
  form: {
    handleSubmit: ReturnType<typeof useFormik>['handleSubmit'];
    handleBlur: ReturnType<typeof useFormik>['handleBlur'];
    handleChange: ReturnType<typeof useFormik>['handleChange'];
    isValid: ReturnType<typeof useFormik>['isValid'];
    values: ReturnType<typeof useFormik>['values'];
  };
}) {
  const featureFlags = useTalentHubFlags();
  if (inviteModal.view === 'Submitted')
    return (
      <Modal
        isCentered
        isOpen={inviteModal.isOpen}
        onClose={inviteModal.onClose}
        scrollBehavior="outside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent maxW={['21.938rem', '21.938rem', 'md']}>
          <ModalHeader px={3} py={6} bgColor="bg.tertiary" textAlign="center">
            <InvitationSubmittedIcon width={32} height={32} fill="none" />
            <ModalCloseButton size="lg" top={5} right={4} />
          </ModalHeader>
          <ModalBody p={0}>
            <Box textAlign="center" pt={6} px={6}>
              {featureFlags.candidateProfileInviteRequestButtonText === 'Invite to Interview' ? (
                <>
                  <Heading variant="heading-2">
                    Your Invitation to {candidateName} Has Been Sent!
                  </Heading>
                  <Text color="text.primary" mt={2}>
                    {openPosition === '0'
                      ? `Thank you for inviting ${candidateName} to interview! Before we confirm ${candidateName}'s interest in your role, we need to get some additional detail from you about the new role. Your assigned Onboarding Lead or Customer Success Director will be in touch soon! In the meantime, you can start the process of submitting details about your new role by clicking Open a New Role below.`
                      : `Thank you for inviting ${candidateName} to interview! We need to confirm ${candidateName}'s interest in applying for your role. Once confirmed, our team will be in touch to begin coordinating interview times with you.`}
                  </Text>
                </>
              ) : (
                <>
                  <Heading variant="heading-2">Interview Request Received!</Heading>
                  <Text color="text.primary" mt={2}>
                    Your request to interview {candidateName} {candidateLastName} has been received
                    by your Terminal team. We need to confirm {candidateName}&apos;s interest in
                    applying for your role. Once confirmed, our team will be in touch to begin
                    coordinating interview times with you.
                  </Text>
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter display="flex" flexDirection="column" pt={8} pb={4} px={4}>
            <Button
              variant="solid"
              colorScheme="primary"
              size="lg"
              type="button"
              onClick={inviteModal.onClose}
              w="full"
            >
              {`View ${candidateName}’s Profile`}
            </Button>
            {openPosition === '0' && (
              <Button
                variant="outline"
                colorScheme="primary"
                size="lg"
                to="/role/create"
                as={RouterLink}
                w="full"
                mt={2}
              >
                Open a New Role
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    );

  return (
    <Modal
      isCentered
      isOpen={inviteModal.isOpen}
      onClose={inviteModal.onClose}
      scrollBehavior="outside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent maxW={['21.938rem', '21.938rem', 'md']}>
        {featureFlags.candidateProfileInviteRequestButtonText === 'Invite to Interview' ? (
          <ModalHeader px={3} py={6} bgColor="bg.secondary">
            <Heading
              borderBottom="1px solid"
              borderColor="ui.inverse.secondary"
              color="brand.darker"
              display="inline-block"
              variant="heading-4"
            >
              Invite to Open Role
            </Heading>
            <ModalCloseButton size="lg" top={5} right={4} />
          </ModalHeader>
        ) : (
          <ModalHeader px={3} py={6} bgColor="bg.tertiary" textAlign="center">
            <InvitationIcon width={32} height={32} fill="none" />
            <ModalCloseButton size="lg" top={5} right={4} />
          </ModalHeader>
        )}
        <ModalBody>
          <Box mx={3}>
            {featureFlags.candidateProfileInviteRequestButtonText === 'Invite to Interview' ? (
              <>
                <Heading variant="heading-2">Invite {candidateName} to interview</Heading>
                <Text color="text.primary" mt={2}>
                  Select an open role to continue. If you want to open a new role for this
                  interview, select Open a New Role
                </Text>
              </>
            ) : (
              <Box textAlign="center">
                <Heading variant="heading-2">Request Interview with {candidateName}</Heading>
                <Text color="text.primary">
                  Select a role to request an interview with {candidateName} {candidateLastName}. We
                  will contact the candidate with information about your role and begin coordinating
                  interview times with you.
                </Text>
              </Box>
            )}
            <CustomSelect
              containerProps={{
                mt: 4,
              }}
              placeholder="Select an Open Role"
              name="openPosition"
              initialValue={form.values.openPosition}
              formInputValue={form.values.openPosition}
              onBlur={form.handleBlur}
              onSelectionChange={(name, value) => {
                form.handleChange({ target: { name, value, id: name } });
              }}
              options={activePostings.map(({ label }) => label)}
              fullScreen={{
                title: 'Select an Open Role',
              }}
            />
          </Box>
        </ModalBody>
        <ModalFooter p={4} px={4} justifyContent="center">
          <Button
            variant="solid"
            colorScheme="primary"
            size="lg"
            type="button"
            onClick={() => form.handleSubmit()}
            disabled={!form.isValid}
            w="85%"
          >
            {featureFlags.candidateProfileInviteRequestButtonText === 'Invite to Interview'
              ? 'Send Invitation'
              : 'Request Interview'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
