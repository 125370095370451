import 'moment-timezone';
import moment from 'moment';

import type { Employee, TerminalEmployeesQuery } from './data';

export function serializeTerminalDirectory(data?: TerminalEmployeesQuery): {
  employees: Employee[];
} {
  if (!data) {
    return {
      employees: [],
    };
  }

  return {
    employees: data.employees_for_org?.map(({ employee }) => ({
      ...employee,
      localTime: employee.timezone ? moment().tz(employee.timezone).format('h:mm a') : null,
    })),
  };
}
