import { Button, Text, Link, ErrorCircleIcon } from '@terminal/design-system';
import type { AuthPageTemplateProps } from '../types';
import { AuthMessage, AuthPageTemplate } from '../components';

export function NoAccessPage({
  onSignOutClick,
  message = (
    <>
      You need additional access to view this page. Email us at{' '}
      <Link href="mailto:support@terminal.io" isExternal display="inline">
        support@terminal.io
      </Link>{' '}
      to request permission.
    </>
  ),
  pageTemplateOverwrite,
}: {
  onSignOutClick: () => Promise<void>;
  message?: React.ReactNode;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate footerLinkOnClick={onSignOutClick}>
      <AuthMessage icon={<ErrorCircleIcon />} title="You Do Not Have Access">
        <Text>{message}</Text>
        <Button variant="solid" colorScheme="primary" onClick={onSignOutClick} mt={6} w={64}>
          Log Out
        </Button>
      </AuthMessage>
    </PageTemplate>
  );
}
