import { Tr, Td, SkeletonText } from '@terminal/design-system';

export function TableContentLoading({ rows = 1, columns = 1 }: { rows: number; columns: number }) {
  return (
    <>
      {Array.from({ length: rows }, (_, i) => i + 1).map((row) => (
        <Tr key={row}>
          {Array.from({ length: columns }, (_, i) => i + 1).map((column) => (
            <Td key={column}>
              <SkeletonText noOfLines={1} />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
}
