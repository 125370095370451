import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { Client_Prospect_Events_Choices_Enum } from 'global/types/hasura-tables.generated.types';
import { SelectClientProspect } from './data';
import type { SelectClientProspectQuery, SelectClientProspectQueryVariables } from './data';

export function useProspectInfo(): {
  data:
    | (SelectClientProspectQuery['client_prospect'][number] & { isCallScheduled: boolean })
    | null;
  loading: boolean;
} {
  const auth = useCustomerAuthorizedUserSession();

  const { data, loading } = useQuery<SelectClientProspectQuery, SelectClientProspectQueryVariables>(
    SelectClientProspect,
    {
      skip: !auth.user?.id,
      variables: {
        user_info_id: Number(auth.user?.id),
      },
    },
  );

  return {
    data: data?.client_prospect?.[0]
      ? {
          ...data.client_prospect[0],
          isCallScheduled: !!data.client_prospect_event.find(
            (event) => event.event_choice === Client_Prospect_Events_Choices_Enum.ScheduleCall,
          ),
        }
      : null,
    loading,
  };
}
