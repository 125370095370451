import { useFlags } from 'launchdarkly-react-client-sdk';
import { switchUndefinedWithDefault } from 'global/utils';
import type { FeatureFlagHighlightBadge } from 'talent-hub/shared/features';

/**
 * Default values:
 * - https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk
 * - https://docs.launchdarkly.com/sdk/features/evaluating#javascript
 *
 *
 * Avoid using underscores in variable names, despite the fact that LaunchDarkly's documentation suggests support for it since it does not work well.
 *
 */
export type FeatureFlag = {
  demo: boolean;
  prospectOnboarding: boolean;
  isContractorPilotEnabled: boolean;
  enableCandidateResumeViewer: boolean;
  topCandidatesCriteria: FeatureFlagHighlightBadge;
  candidateProfileInviteRequestButtonText: string;
  showRetoolEditOrganization: boolean;
  showInternalDashboard: boolean;
  'role-based-rehaul': boolean;
  showClientAgreement: boolean;
};

export const defaultLDFlag: FeatureFlag = {
  demo: false,
  prospectOnboarding: true,
  isContractorPilotEnabled: false,
  enableCandidateResumeViewer: false,
  topCandidatesCriteria: null,
  candidateProfileInviteRequestButtonText: 'Invite to Interview',
  showRetoolEditOrganization: false,
  showInternalDashboard: false,
  'role-based-rehaul': false,
  showClientAgreement: false,
};

export function useTalentHubFlags(): FeatureFlag {
  const featureFlags = useFlags<FeatureFlag>();
  return switchUndefinedWithDefault(featureFlags, defaultLDFlag);
}
