import { Route, Switch } from 'react-router-dom';
import { ErrorFallback } from './ErrorFallback';

// TODO: [TAL-945] Improve this component
export function SwitchWithPageNotFound({ children }: { children: React.ReactNode }) {
  return (
    <Switch>
      {children}
      <Route>
        <ErrorFallback
          onActionClick={() => {
            window.location.reload();
          }}
          subTitle="Page not Found"
          description="We're sorry."
          showBackButton
        />
      </Route>
    </Switch>
  );
}
