import { useParams } from 'react-router-dom';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import type { SelectJobQuery, SelectJobQueryVariables } from './data';
import { SelectJob } from './data';
import { serializeJob } from './ViewJob.serializer';
import { ViewJob } from './ViewJob';

export function ViewJobController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const { jobID } = useParams<{ jobID: string }>();
  const { data } = useQuery<SelectJobQuery, SelectJobQueryVariables>(SelectJob, {
    variables: {
      organization_id: viewingOrganization.ID,
      job_id: Number(jobID),
    },
    skip: !jobID,
  });

  const job = serializeJob(data);

  return (
    <ViewJob
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      job={job}
    />
  );
}
