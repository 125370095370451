import { Link as RouterLink } from 'react-router-dom';

import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Badge,
  ArrowRightIcon,
  Button,
  Link,
} from '@terminal/design-system';
import { Card, CardBody, CardHeader, TableContentLoading } from 'talent-hub/components';

import { Job_Status_Choices_Enum } from 'global/types/hasura-tables.generated.types';

function JobsToReviewCard({ children }: { children: React.ReactNode }) {
  return (
    <Card>
      <CardHeader>
        <Heading variant="heading-3" color="brand.darker">
          Submitted Roles Pending Review
        </Heading>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
}

export function JobsToReview({
  isLoading,
  jobs,
}: {
  isLoading: boolean;
  jobs: {
    submittedDate: string;
    title: string;
    id: number;
    status: Job_Status_Choices_Enum;
    positions: number;
    locations: string;
  }[];
}) {
  if (isLoading)
    return (
      <JobsToReviewCard>
        <TableContainer whiteSpace="break-spaces">
          <Table>
            <Thead>
              <Tr>
                <Th w="60%">Role</Th>
                <Th>Date Submitted</Th>
                <Th>Positions</Th>
                <Th>Current status</Th>
              </Tr>
            </Thead>
            <Tbody>
              <TableContentLoading columns={4} rows={2} />
            </Tbody>
          </Table>
        </TableContainer>
      </JobsToReviewCard>
    );

  return (
    <JobsToReviewCard>
      <TableContainer whiteSpace="break-spaces">
        <Table>
          <Thead>
            <Tr>
              <Th w="50%">Role</Th>
              <Th>Date Submitted</Th>
              <Th>Positions</Th>
              <Th>Current status</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {jobs.map(({ id, title, status, submittedDate, positions, locations }) => {
              return (
                <Tr key={id}>
                  <Td>
                    <Link
                      variant="label"
                      to={`/role/${
                        status === Job_Status_Choices_Enum.Draft ? 'edit' : 'view'
                      }/${id}`}
                      as={RouterLink}
                    >
                      {title}
                    </Link>
                    <Text variant="caption" mt={1}>
                      {locations}
                    </Text>
                  </Td>
                  <Td>{submittedDate}</Td>
                  <Td>{positions}</Td>
                  {status === Job_Status_Choices_Enum.Draft ? (
                    <>
                      <Td>
                        <Badge colorScheme="warning" fontSize="sm">
                          Draft
                        </Badge>
                      </Td>
                      <Td>
                        <Button
                          variant="ghost"
                          rightIcon={<ArrowRightIcon />}
                          colorScheme="primary"
                          mt={2}
                          to={`/role/edit/${id}`}
                          as={RouterLink}
                        >
                          Edit Draft
                        </Button>
                      </Td>
                    </>
                  ) : (
                    <>
                      <Td>
                        <Badge colorScheme="primary" fontSize="sm">
                          Pending Approval
                        </Badge>
                      </Td>
                      <Td>
                        <Button
                          variant="ghost"
                          rightIcon={<ArrowRightIcon />}
                          colorScheme="primary"
                          mt={2}
                          to={`/role/view/${id}`}
                          as={RouterLink}
                        >
                          View Role
                        </Button>
                      </Td>
                    </>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </JobsToReviewCard>
  );
}
