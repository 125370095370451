import { Route } from 'react-router-dom';
import { CustomRoute } from 'global/components';
import { ClientReviewerRoleRouter } from 'talent-hub/shared/features/roles';
import { ManageCandidatesController } from 'talent-hub/shared/features/manage-candidates';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';
import { TermsOfUseModal } from 'talent-hub/shared/features';
import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';

export function ClientReviewerRouter() {
  const featureFlags = useTalentHubFlags();
  const { isTermsOfUseAccepted } = useCustomerAuthorizedUserSession();
  return (
    <>
      {featureFlags.showClientAgreement && !isTermsOfUseAccepted && <TermsOfUseModal />}
      <SwitchWithPageNotFound>
        <Route path="/candidates">
          <ManageCandidatesController />
        </Route>
        <Route path="/role">
          <ScrollToTopOnPathChange />
          <ClientReviewerRoleRouter />
        </Route>
        <CustomRoute
          path="/"
          conditionalRedirects={[{ condition: true, redirectURL: `/candidates` }]}
        />
      </SwitchWithPageNotFound>
    </>
  );
}
