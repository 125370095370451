import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { inputTheme } from '../form/Input/Input.theme';

const baseStyle = defineStyle({
  ...inputTheme.baseStyle?.field,
  paddingY: '2',
  minHeight: '20',
  lineHeight: 'short',
  verticalAlign: 'top',
});

const variants = {
  outline: defineStyle({ ...inputTheme.variants?.outline.field }),
};

const sizes = {
  md: inputTheme.sizes?.md.field ?? {},
};

export const textareaTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});
