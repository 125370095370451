import { useState } from 'react';
import {
  Box,
  Heading,
  Grid,
  CheckboxCard,
  CheckboxGroup,
  Center,
  Text,
  Skeleton,
  Typeahead,
} from '@terminal/design-system';
import { LocationTypeAhead } from 'global/components';
import type { IconProps, ComponentWithAs } from '@terminal/design-system';
import { EuropeanCountry } from 'global/constants';
import { usePrevious } from 'global/utils';
import {
  OnBoardingPage,
  LeftHeaderTitle,
  StepProgressBar,
  RightFooter,
  RightContentContainer,
  CanadaIcon,
  SearchIcon,
  EuropeIcon,
  UsaIcon,
  LeftQuote,
} from '../../components';
import quoteProfileImageSrc from '../../assets/customers/david-hegarty.jpeg';

import type { StepInfo } from '../../Onboarding.types';

const locationOptionIconMapping: { [key: string]: ComponentWithAs<'svg', IconProps> } = {
  USA: UsaIcon,
  Canada: CanadaIcon,
};

const locationOptions = [
  { label: 'USA', value: 'United States' },
  { label: 'Canada', value: 'Canada' },
];

export function CompanyLocation({
  placeIDToCountryIDMap,
  countryNameToIDMap,
  onNextClick,
  stepInfo,
  selectedLocation,
  selectedCheckbox,
  onCheckboxClick,
  onLocationChange,
  loadingMutation,
  isTypeaheadReady,
}: {
  placeIDToCountryIDMap: Record<string, number>;
  countryNameToIDMap: Record<string, number>;
  onNextClick: () => void;
  stepInfo: StepInfo;
  selectedLocation: string;
  selectedCheckbox: number | string;
  onCheckboxClick: (value: number | string) => void;
  onLocationChange: ({
    country,
    country_id,
  }: {
    country: string;
    country_id: number | null;
  }) => void;
  loadingMutation: boolean;
  isTypeaheadReady: boolean;
}) {
  const prevSelectedCheckbox = usePrevious(selectedCheckbox);
  const [showTypeahead, setShowTypeahead] = useState<boolean>(false);

  return (
    <OnBoardingPage
      leftContent={
        <Box ml={[0, 0, 10, 0, 0]}>
          <LeftHeaderTitle text="Our Vetted Candidates <strong>Are Waiting</strong>" />
          <Box mt={10}>
            <LeftQuote
              name="David Hegarty"
              description="Founder & CEO, Playbook"
              imageSrc={quoteProfileImageSrc}
            >
              We couldn&apos;t have built anything without the engineering hires from Terminal. Had
              we not partnered with Terminal, I don&apos;t actually know how we would have gotten
              started. It was extremely hard to hire engineers.
            </LeftQuote>
          </Box>
        </Box>
      }
      rightContent={
        <RightContentContainer mx={[0, 0, '6rem', '8rem', '12.5rem']} maxW="sm">
          <StepProgressBar stepInfo={stepInfo} />
          <Heading variant="heading-2" textColor="text.primary">
            Where are you located?
          </Heading>

          <CheckboxGroup>
            <Grid gridTemplateColumns="1fr 1fr" gap={4} mt={6} w="full">
              {locationOptions.map(({ value, label }) => {
                const Icon = locationOptionIconMapping[label];
                return (
                  <CheckboxCard
                    key={value}
                    aria-checked={value === selectedCheckbox}
                    onChange={() => {
                      setShowTypeahead(false);
                      onCheckboxClick(value);
                      onLocationChange({
                        country: value,
                        country_id: countryNameToIDMap[value?.toLowerCase()] || null,
                      });
                    }}
                    isChecked={value === selectedCheckbox}
                    value={selectedCheckbox || undefined}
                    name="onBoardingCompanyLocation"
                    role="checkbox"
                    sx={{
                      button: {
                        maxH: 20,
                      },
                      svg: {
                        color: 'ui.success',
                      },
                      div: {
                        padding: 0,
                        paddingLeft: 1,
                        paddingRight: 1,
                      },
                    }}
                  >
                    <Center w="full" h="full">
                      {Icon && <Icon color="accent.darkest" fontSize="4xl" mr={2} fill="none" />}
                      {label}
                    </Center>
                  </CheckboxCard>
                );
              })}
              <CheckboxCard
                aria-checked={selectedCheckbox === 'Europe'}
                onChange={() => {
                  setShowTypeahead(true);
                  onCheckboxClick('Europe');

                  if (prevSelectedCheckbox !== 'Europe') {
                    onLocationChange({
                      country: '',
                      country_id: null,
                    });
                  }
                }}
                isChecked={selectedCheckbox === 'Europe'}
                name="onBoardingCompanyLocation"
                role="checkbox"
                sx={{
                  button: {
                    maxH: 20,
                  },
                  svg: {
                    color: 'ui.success',
                  },
                  div: {
                    padding: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                  },
                }}
              >
                <Center w="full" h="full">
                  <EuropeIcon color="accent.darkest" fontSize="4xl" mr={2} fill="none" />
                  Europe
                </Center>
              </CheckboxCard>
              <CheckboxCard
                aria-checked={selectedCheckbox === 'Other'}
                onChange={() => {
                  setShowTypeahead(true);
                  onCheckboxClick('Other');

                  if (prevSelectedCheckbox !== 'Other') {
                    onLocationChange({
                      country: '',
                      country_id: null,
                    });
                  }
                }}
                isChecked={selectedCheckbox === 'Other'}
                name="onBoardingCompanyLocation"
                role="checkbox"
                sx={{
                  button: {
                    maxH: 20,
                  },
                  svg: {
                    color: 'ui.success',
                  },
                  div: {
                    padding: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                  },
                }}
              >
                <Center w="full" h="full">
                  <SearchIcon color="accent.darkest" fontSize="4xl" mr={2} fill="none" />
                  Other
                </Center>
              </CheckboxCard>
            </Grid>
          </CheckboxGroup>

          {showTypeahead && selectedCheckbox === 'Europe' && (
            <Box w="full" mt={6}>
              <Text variant="label" color="text.primary" mb={2}>
                Which country in Europe?
              </Text>
              <Typeahead
                name="onBoardingCompanyLocation"
                onBlur={() => {}}
                shouldOpenOnFocus
                onSelectionChange={(_, value) =>
                  onLocationChange({
                    country: value,
                    country_id: countryNameToIDMap[value?.toLowerCase()] || null,
                  })
                }
                options={Object.values(EuropeanCountry)}
                placeholder="Type your location"
                fullScreen={{ title: 'Search Location' }}
              />
            </Box>
          )}
          {showTypeahead && selectedCheckbox === 'Other' && (
            <Box w="full" mt={6}>
              <Text variant="label" color="text.primary" mb={2}>
                Which country?
              </Text>
              <Skeleton isLoaded={isTypeaheadReady}>
                <LocationTypeAhead
                  defaultValue=""
                  placeIDToCountryIDMap={placeIDToCountryIDMap}
                  noOptionsMatchedCopy="Address not found"
                  placeholder="Type your location"
                  name="country"
                  onBlur={() => {}}
                  onChange={({ country, country_id }) => onLocationChange({ country, country_id })}
                />
              </Skeleton>
            </Box>
          )}
          <RightFooter
            onNextStep={onNextClick}
            disabled={
              ['-1', 'Other', 'Europe'].includes(`${selectedCheckbox}`) && !selectedLocation
            }
            isLoading={loadingMutation}
          />
        </RightContentContainer>
      }
    />
  );
}
