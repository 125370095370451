import {
  SavedCandidates,
  useSavedCandidatesController,
  ViewType,
} from 'talent-hub/shared/features/explore-candidates';
import { useParams } from 'react-router-dom';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { CandidateProfileController } from '../candidate-profile';

export function SavedCandidatesController() {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const {
    loading,
    candidates,
    handleOnSaveCandidate,
    handleOnRemoveSaveCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    teamMembers,
    onInsertSharedCandidate,
    isShareWithModalOpen,
    onOpenShareWithModal,
    onCloseShareWithModal,
    onCloseCandidateProfileClick,
  } = useSavedCandidatesController();
  return (
    <>
      <SavedCandidates
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        loading={loading}
        candidates={candidates}
        handleOnSaveCandidate={handleOnSaveCandidate}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        sharedCandidateWithProps={{
          teamMembers,
          handleInsertSharedWith: onInsertSharedCandidate,
          isShareWithModalOpen,
          onOpenShareWithModal,
          onCloseShareWithModal,
        }}
      />
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={onCloseCandidateProfileClick}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        viewPage={ViewType.SAVED}
      />
    </>
  );
}
