import { Heading } from '@terminal/design-system';
import { Card, CardBody, CardHeader, PageLayoutPaddedBody } from 'talent-hub/components';

import { RoleLayout } from '../../components';
import type { ProfileInfo } from '../../Role.types';
import { JobCandidateTable } from './JobCandidateTable';

export function JobCandidatesBuckets({
  candidatesBuckets,
  roleLayoutProps,
}: {
  candidatesBuckets: {
    bucketTitle: string;
    candidates: ProfileInfo[];
    defaultCandidateStatus: string;
    pendingActionName?: string;
    dateColumnTitle?: string;
    shouldShowFeedbackCTA?: boolean;
  }[];
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
}) {
  return (
    <RoleLayout {...roleLayoutProps}>
      <PageLayoutPaddedBody>
        {candidatesBuckets.map(
          (
            {
              bucketTitle,
              candidates,
              defaultCandidateStatus,
              pendingActionName,
              dateColumnTitle = 'Last Updated',
              shouldShowFeedbackCTA,
            },
            index,
          ) => (
            <Card key={`candidate-buckets-${bucketTitle}`} mt={index > 0 ? 6 : 0}>
              <CardHeader>
                <Heading variant="heading-3" color="brand.darker">
                  {bucketTitle}
                </Heading>
              </CardHeader>
              <CardBody>
                <JobCandidateTable
                  candidates={candidates}
                  status={defaultCandidateStatus}
                  columnName={dateColumnTitle}
                  actionName={pendingActionName}
                  shouldShowFeedbackCTA={shouldShowFeedbackCTA}
                />
              </CardBody>
            </Card>
          ),
        )}
      </PageLayoutPaddedBody>
    </RoleLayout>
  );
}
