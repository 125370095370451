import { useEffect, useState } from 'react';
import { signOut, useSendEmailVerifyLink } from 'global/auth';
import { firebaseAuth } from 'global/firebaseApp';
import { useToast } from '@terminal/design-system';

import { SignUpSuccess } from './SignUpSuccess';

const WAIT_TIME = 60;

export function SignUpSuccessController() {
  const email_verify_sender = useSendEmailVerifyLink();

  const [seconds, setSeconds] = useState(WAIT_TIME);

  const toast = useToast({
    position: 'top',
    duration: 4000,
  });

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [seconds]);

  useEffect(() => {
    if (email_verify_sender.isResolved) {
      setSeconds(WAIT_TIME);
      toast({
        status: 'success',
        description: 'New Email verifaction is sent.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email_verify_sender.isResolved]);

  const resend_title = seconds > 0 ? `Request A New Link (${seconds})` : 'Request A New Link';

  return (
    <SignUpSuccess
      is_resend_loading={email_verify_sender.isLoading}
      on_logout_clicked={() => {
        signOut({ auth: firebaseAuth });
      }}
      on_resend_verification_email_clicked={() => {
        email_verify_sender.handleSendVerifyEmail();
      }}
      is_resend_disabled={email_verify_sender.isLoading || seconds > 0}
      resend_button_title={resend_title}
    />
  );
}
