/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { EmailVerifyInProgressPage } from './EmailVerifyInProgressPage';
import { VerifyEmailLinkExpiredPage } from './VerifyEmailLinkExpiredPage';

export default {
  title: 'Global/Pages/Auth/Verifying Email',
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template_EmailVerifyInProgressPage: StoryFn<
  ComponentProps<typeof EmailVerifyInProgressPage>
> = () => <EmailVerifyInProgressPage />;

export const story_EmailVerifyInProgressPage_verifying = Template_EmailVerifyInProgressPage.bind(
  {} as {},
);
story_EmailVerifyInProgressPage_verifying.args = {};
story_EmailVerifyInProgressPage_verifying.storyName = 'Verifying Email';

const Template_VerifyEmailLinkExpiredPage: StoryFn<
  ComponentProps<typeof VerifyEmailLinkExpiredPage>
> = (args) => <VerifyEmailLinkExpiredPage {...args} />;

export const story_VerifyEmailLinkExpiredPage_resend = Template_VerifyEmailLinkExpiredPage.bind(
  {} as {},
);
story_VerifyEmailLinkExpiredPage_resend.args = {};
story_VerifyEmailLinkExpiredPage_resend.storyName = 'Link Expired';
