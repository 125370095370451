import { textTheme } from '../Text/Text.theme';

type Dict = Record<string, any>;

export const linkTheme = {
  baseStyle: ({ colorScheme: c }: Dict) => ({
    ...textTheme.variants.body,
    transition: `all 0.15s ease-out`,
    cursor: 'pointer',
    outline: 'none',
    color: c === 'text' ? 'text.primary' : 'text.link',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',

    _hover: {
      color: 'accent.darkest',
    },
    _focus: {
      color: 'accent.darkest',
      boxShadow: 'none',
      outlineColor: 'text.link',
      outlineStyle: 'solid',
      outlineWidth: '1px',
    },
  }),
  variants: {
    // TODO: (TP-1340) Add body, label, caption variant to design system Link Component
    default: {},
    accent: {
      fontWeight: 'bold',
    },
    label: {
      fontWeight: 'bold',
    },
  },
};
