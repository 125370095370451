import type { SelectSavedCandidatesQuery } from './data';

import type { Candidate } from '../explore-candidates/data';

import { serializeCandidates } from '../explore-candidates/ExploreCandidates.serializer';

export function serializeSavedCandidates(data?: SelectSavedCandidatesQuery): {
  candidates: Candidate[];
} {
  if (!data) {
    return {
      candidates: [],
    };
  }

  return { candidates: serializeCandidates(data.saved_candidate) };
}
