import { createIcon } from '@terminal/design-system';

export const ReadyForReview = createIcon({
  displayName: 'ReadyForReview',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1329_957)" fill="none">
        <mask id="path-1-inside-1_1329_957" fill="white">
          <rect x="9" y="8.75" width="46" height="45" rx="1" />
        </mask>
        <rect
          x="9"
          y="8.75"
          width="46"
          height="45"
          rx="1"
          fill="white"
          stroke="#25383F"
          strokeWidth="4"
          mask="url(#path-1-inside-1_1329_957)"
        />
        <path
          d="M9 7.75C9 7.19771 9.44772 6.75 10 6.75H54C54.5523 6.75 55 7.19772 55 7.75V17.75H9V7.75Z"
          fill="#BFD897"
        />
        <rect x="9" y="51.75" width="46" height="4" rx="1" fill="#25383F" />
        <path
          d="M23 6.75C23 3.98858 21.6569 1.75 20 1.75C18.3431 1.75 17 3.98858 17 6.75C17 9.51142 18.3431 11.75 20 11.75"
          stroke="#25383F"
          strokeWidth="2"
        />
        <path
          d="M46 6.75C46 3.98858 44.6569 1.75 43 1.75C41.3431 1.75 40 3.98858 40 6.75C40 9.51142 41.3431 11.75 43 11.75"
          stroke="#25383F"
          strokeWidth="2"
        />
        <path
          d="M63 49.25C63 57.2284 56.3144 63.75 48 63.75C39.6856 63.75 33 57.2284 33 49.25C33 41.2716 39.6856 34.75 48 34.75C56.3144 34.75 63 41.2716 63 49.25Z"
          fill="#25383F"
          stroke="#25383F"
          strokeWidth="2"
        />
        <circle cx="48" cy="46.75" r="15" fill="white" stroke="#25383F" strokeWidth="2" />
        <line x1="46" y1="25.75" x2="17" y2="25.75" stroke="#25383F" strokeWidth="2" />
        <line
          y1="-1"
          x2="17"
          y2="-1"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 34 33.75)"
          stroke="#25383F"
          strokeWidth="2"
        />
        <line
          y1="-1"
          x2="12"
          y2="-1"
          transform="matrix(-1 -8.43005e-08 -9.06607e-08 1 29 41.75)"
          stroke="#25383F"
          strokeWidth="2"
        />
        <path d="M48 35.75L48 47.75L55 51.75" stroke="#25383F" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_1329_957">
          <rect width="64" height="64" fill="white" transform="translate(0 0.75)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const NeedInterviewFeedback = createIcon({
  displayName: 'ReadyForReview',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1237_2276)">
        <path
          d="M7 13.75C7 13.1977 7.44772 12.75 8 12.75H48C48.5523 12.75 49 13.1977 49 13.75V62.75C49 63.3023 48.5523 63.75 48 63.75H14C10.134 63.75 7 60.616 7 56.75V13.75Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <circle cx="17" cy="34.75" r="2" fill="#8BBFD0" />
        <circle cx="17" cy="43.75" r="2" fill="#8BBFD0" />
        <circle cx="17" cy="25.75" r="2" fill="#8BBFD0" />
        <path
          d="M48.9432 63.75C52.2883 63.75 55 61.2556 55 58.1786V56.75H20.0568V57.6932C20.0568 61.0383 17.3451 63.75 14 63.75H48.9432Z"
          fill="#25383F"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <line x1="22" y1="34.75" x2="41" y2="34.75" stroke="#25383F" strokeWidth="2" />
        <line x1="22" y1="43.75" x2="41" y2="43.75" stroke="#25383F" strokeWidth="2" />
        <line x1="22" y1="25.75" x2="32" y2="25.75" stroke="#25383F" strokeWidth="2" />
        <path
          d="M46.3088 19.0106L50.3801 15.2455L61.5437 26.8422C62.6206 27.9609 62.5687 29.7458 61.4288 30.8C60.3206 31.8248 58.5963 31.7747 57.5495 30.6873L46.3088 19.0106Z"
          fill="#8BBFD0"
        />
        <circle cx="44" cy="11.75" r="10" fill="white" stroke="#25383F" strokeWidth="2" />
        <path d="M48 8.75L42.6667 14.75L40 11.75" stroke="#8BBFD0" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_1237_2276">
          <rect width="64" height="64" fill="white" transform="translate(0 0.75)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const EmptyTable = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1126_3272)">
        <path
          d="M5.20436 62.8376L1.5 63L1.65482 59.246C2.08027 48.9302 8.51665 39.8231 18.0988 35.9788L28.4697 46.3497C24.6236 55.9362 15.5237 62.3852 5.20436 62.8376Z"
          fill="#25383F"
        />
        <path
          d="M1.5 63L0.500849 62.9588L0.455981 64.0467L1.5438 63.999L1.5 63ZM5.20436 62.8376L5.16057 61.8386L5.20436 62.8376ZM28.4697 46.3497L29.3978 46.722L29.6436 46.1094L29.1768 45.6426L28.4697 46.3497ZM18.0988 35.9788L18.8059 35.2717L18.3391 34.8049L17.7265 35.0507L18.0988 35.9788ZM1.65482 59.246L0.655672 59.2048L1.65482 59.246ZM1.5438 63.999L5.24816 63.8366L5.16057 61.8386L1.4562 62.001L1.5438 63.999ZM29.1768 45.6426L18.8059 35.2717L17.3917 36.6859L27.7626 47.0568L29.1768 45.6426ZM0.655672 59.2048L0.500849 62.9588L2.49915 63.0412L2.65397 59.2872L0.655672 59.2048ZM17.7265 35.0507C7.77906 39.0416 1.09734 48.4958 0.655672 59.2048L2.65397 59.2872C3.06321 49.3646 9.25425 40.6047 18.4712 36.9069L17.7265 35.0507ZM5.24816 63.8366C15.9597 63.3671 25.4055 56.6729 29.3978 46.722L27.5416 45.9774C23.8417 55.1995 15.0877 61.4034 5.16057 61.8386L5.24816 63.8366Z"
          fill="#25383F"
        />
        <path
          d="M33.321 61.5221C41.3958 54.1246 43.1057 44.1182 42.9513 40.0397L30.7415 47.1702C32.4414 50.8883 33.1695 58.2873 33.321 61.5221Z"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33362 31.8701C9.73112 23.7953 19.7375 22.0853 23.816 22.2397L16.6856 34.4496C12.9674 32.7496 5.56839 32.0216 2.33362 31.8701Z"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.0244 37.1213L31.13 49.4556L15.552 33.8776L27.3215 18.0911C33.9277 9.23011 43.9834 3.58828 54.9888 2.56819L60.3154 2.07447C61.5646 1.95868 62.6107 3.00824 62.4909 4.25704L61.936 10.0369C60.9092 20.7339 55.5141 30.5331 47.0244 37.1213Z"
          fill="white"
        />
        <path
          d="M31.13 49.4556L30.4229 50.1627L31.0464 50.7862L31.7431 50.2456L31.13 49.4556ZM47.0244 37.1213L47.6375 37.9113L47.0244 37.1213ZM61.936 10.0369L62.9314 10.1325L61.936 10.0369ZM62.4909 4.25704L61.4954 4.16148L62.4909 4.25704ZM60.3154 2.07447L60.4077 3.0702L60.4077 3.0702L60.3154 2.07447ZM54.9888 2.56819L54.8965 1.57246L54.9888 2.56819ZM27.3215 18.0911L28.1232 18.6888L27.3215 18.0911ZM15.552 33.8776L14.7503 33.2799L14.2334 33.9732L14.8449 34.5847L15.552 33.8776ZM31.7431 50.2456L47.6375 37.9113L46.4113 36.3312L30.5169 48.6656L31.7431 50.2456ZM62.9314 10.1325L63.4863 4.35259L61.4954 4.16148L60.9406 9.94135L62.9314 10.1325ZM60.2231 1.07874L54.8965 1.57246L55.0811 3.56392L60.4077 3.0702L60.2231 1.07874ZM26.5197 17.4934L14.7503 33.2799L16.3537 34.4753L28.1232 18.6888L26.5197 17.4934ZM14.8449 34.5847L30.4229 50.1627L31.8371 48.7485L16.2591 33.1705L14.8449 34.5847ZM54.8965 1.57246C43.6089 2.61871 33.2953 8.40519 26.5197 17.4934L28.1232 18.6888C34.56 10.055 44.3579 4.55786 55.0811 3.56392L54.8965 1.57246ZM63.4863 4.35259C63.6661 2.4794 62.0969 0.905054 60.2231 1.07874L60.4077 3.0702C61.0323 3.01231 61.5554 3.53709 61.4954 4.16148L63.4863 4.35259ZM47.6375 37.9113C56.3448 31.1542 61.8783 21.1037 62.9314 10.1325L60.9406 9.94135C59.9401 20.364 54.6833 29.912 46.4113 36.3312L47.6375 37.9113Z"
          fill="#25383F"
        />
        <path d="M20 28.0858L36.4572 44.5429" stroke="#25383F" strokeWidth="2" />
        <path d="M59.8552 19.3674L44.8552 4.36741" stroke="#25383F" strokeWidth="2" />
        <circle
          cx="43.1689"
          cy="21.8137"
          r="8"
          transform="rotate(45 43.1689 21.8137)"
          fill="#F9C66C"
        />
        <rect
          x="30.3848"
          y="29.9142"
          width="6"
          height="24"
          rx="3"
          transform="rotate(45 30.3848 29.9142)"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <circle cx="43.1689" cy="21.5" r="3" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1126_3272">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const NoOpenRoles = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1255_1679)">
        <mask id="path-1-inside-1_1255_1679" fill="white">
          <rect x="10" y="11.5" width="44" height="53" rx="1" />
        </mask>
        <rect
          x="10"
          y="11.5"
          width="44"
          height="53"
          rx="1"
          fill="white"
          stroke="#25383F"
          strokeWidth="4"
          mask="url(#path-1-inside-1_1255_1679)"
        />
        <path
          d="M48 16.5H16C15.4477 16.5 15 16.9477 15 17.5V58.5C15 59.0523 15.4477 59.5 16 59.5H40.5858C40.851 59.5 41.1054 59.3946 41.2929 59.2071L48.7071 51.7929C48.8946 51.6054 49 51.351 49 51.0858V17.5C49 16.9477 48.5523 16.5 48 16.5Z"
          fill="#F9C66C"
        />
        <path d="M49 51.5H42C41.4477 51.5 41 51.9477 41 52.5V59.5L49 51.5Z" fill="#25383F" />
        <path
          d="M25.4583 9.18966H26.4583V8.18965V6.97917C26.4583 3.95311 28.9114 1.5 31.9375 1.5C34.9636 1.5 37.4167 3.95311 37.4167 6.97917V8.18965V9.18966H38.4167H43C45.7614 9.18966 48 11.4282 48 14.1897V16.5H16V14.1897C16 11.4282 18.2386 9.18966 21 9.18966H25.4583Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <rect x="15" y="17.5" width="34" height="2" fill="#25383F" />
        <circle cx="32" cy="7.5" r="2" fill="#25383F" />
        <line x1="29" y1="27.5" x2="43" y2="27.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="35.5" x2="43" y2="35.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="44.5" x2="43" y2="44.5" stroke="#25383F" strokeWidth="2" />
        <circle cx="23" cy="27.5" r="2" fill="white" />
        <circle cx="23" cy="35.5" r="2" fill="white" />
        <circle cx="23" cy="44.5" r="2" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1255_1679">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});
