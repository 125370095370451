import { firstItemPathOr } from 'global/utils';
import { capitalizeEachWord, createCandidateStagesInfo } from 'talent-hub/utils';
import { workflowStatuses_ToCandidateWorkflowStatus } from 'talent-hub/utils/candidate/serializer.utils';
import moment from 'moment';
import type { RoleApplicantWorkflow, CandidateWithStagingInfo } from '../Role.types';

// Adds to the candidates the stage info
export function transformToCandidatesWithStageInfo(
  applicantWorkFlows: RoleApplicantWorkflow[],
): CandidateWithStagingInfo[] {
  return applicantWorkFlows.map(({ job_profile_id, icims_person, workflow_statuses }) => {
    const statuses = workflow_statuses.map(workflowStatuses_ToCandidateWorkflowStatus);

    const { currentStatus, ...rest } = createCandidateStagesInfo(statuses);

    return {
      ...rest,
      currentStatus,
      profileInfo: {
        jobID: job_profile_id,
        id: icims_person?.profile_id,
        name: `${capitalizeEachWord(
          icims_person?.candidate?.firstname || icims_person?.firstname,
        )} ${capitalizeEachWord(icims_person?.candidate?.lastname || icims_person?.lastname)}`,
        statusText: currentStatus?.title,
        date: moment(currentStatus?.date).format('MMM D, YYYY'),
        company: firstItemPathOr('', ['company'], icims_person?.candidate?.work_experience || []),
        title: firstItemPathOr('', ['title'], icims_person?.candidate?.work_experience || []),
      },
    };
  });
}
