/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { IncompatibleBrowserErrorPage } from './IncompatibleBrowserErrorPage';

export default {
  title: 'Global/Pages/Auth/Incompatible Browser Error Page',
  component: IncompatibleBrowserErrorPage,
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template_IncompatibleBrowserErrorPage: StoryFn<
  ComponentProps<typeof IncompatibleBrowserErrorPage>
> = () => {
  return <IncompatibleBrowserErrorPage />;
};

export const story_incompatibleBrowser = Template_IncompatibleBrowserErrorPage.bind({} as {});
story_incompatibleBrowser.storyName = 'Incompatible Browser Error Page';
