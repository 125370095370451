import { Link as RouterLink } from 'react-router-dom';
import { Heading, Box, Text, Button, Image } from '@terminal/design-system';
import { Card, CardBody } from 'talent-hub/components';

export function DashboardCard({
  title,
  paragraph,
  link,
  imageSrc,
  bgColor = 'bg.primary',
  isSmall,
}: {
  title: string;
  paragraph?: string;
  link: { text: string; href: string; openInNewTab?: boolean };
  imageSrc: string;
  bgColor?: 'bg.tertiary' | 'bg.primary' | 'bg.secondary';
  isSmall?: boolean;
}) {
  const extraLinkProps = link.openInNewTab
    ? { target: '_blank', rel: 'noopener noreferrer', to: { pathname: link.href } }
    : {};
  return (
    <Card py={[4, 6]} px={[6, 6, 6, 4, 6]} h="auto" bg={bgColor} w="full">
      <CardBody flexDir={['column', 'row']} alignItems={['start', 'center']}>
        <Image
          src={imageSrc}
          alt=""
          maxH={['auto', 'auto', 'auto', isSmall ? '7.5rem' : '9.5rem', isSmall ? 32 : '9.5rem']}
          mr={[0, isSmall ? 6 : 9]}
        />
        <Box mt={[3, 0]}>
          <Heading variant="heading-3" color="brand.darker">
            {title}
          </Heading>
          {paragraph && (
            <Text color="brand.darker" mt={2}>
              {paragraph}
            </Text>
          )}
          <Button
            variant="outline"
            mt={4}
            whiteSpace="normal"
            textDecor="none"
            to={link.href}
            as={RouterLink}
            {...extraLinkProps}
            textAlign="center"
          >
            {link.text}
          </Button>
        </Box>
      </CardBody>
    </Card>
  );
}
