import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Text, FormControl, FormLabel, Textarea, Button, Flex } from '@terminal/design-system';

import { ModalCandidateSubmissionCheckbox } from '../ModalCandidateSubmissionCheckbox';
import { ViewType } from './constants';

const SubmissionSchema = Yup.object().shape({
  // fullName: Yup.string()
  //   .min(2, 'Please provide as much information as possible. Thanks!')
  //   .max(100, 'This name looks a little long :)')
  //   .required('Please add the name of your referral.'),
  // email: Yup.string()
  //   .email('Please make sure this email address is valid')
  //   .required('Please include an email address.'),
  // linkedin: Yup.string()
  //   .min(2, 'That URL looks a bit short. Please try the full URL')
  //   .required('Please include a LinkedIn profile URL.'),
  // relationship: Yup.string()
  //   .min(1, 'Could you provide a little more detail?')
  //   .required('Please tell us a little about this person.'),
  // suggested_job: Yup.string(),
  // active: Yup.string(),
  // aware: Yup.string(),
  // comments: Yup.string()
  //   .min(1, 'Could you provide a little more detail?'),
});

export function RejectForm({
  candidateName,
  loading,
  onSubmit,
  reviewerName,
  onCancel,
}: {
  candidateName: string;
  loading: boolean;
  onSubmit: (values: any) => void;
  reviewerName: string;
  onCancel: () => void;
}) {
  return (
    <>
      <Box
        flexDirection="column"
        py={2}
        border="1px solid"
        borderColor="ui.error"
        borderRadius="sm"
      >
        <Text
          textAlign="center"
          variant="label"
          color="text.error"
        >{`Rejecting ${candidateName}`}</Text>
        <Text textAlign="center" color="text.error">
          Please share your feedback about this candidate.
        </Text>
      </Box>
      <Formik
        initialValues={{
          candidateName,
          reviewerName,
          decision: ViewType.Reject,
          comments: '',
          reasonTooJunior: false,
          reasonMissingSkills: false,
          reasonMissingExperience: false,
          reasonOther: false,
        }}
        validationSchema={SubmissionSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue, handleBlur, handleSubmit }: any) => (
          <>
            <>
              <Text variant="label" mt={2}>
                Reason(s) for rejection:
              </Text>
              <div className="checkbox-list font-size-md mb-2">
                <ModalCandidateSubmissionCheckbox
                  name="reasonTooJunior"
                  value={values.reasonTooJunior}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                >
                  Too&nbsp;
                  <strong>junior</strong>
                </ModalCandidateSubmissionCheckbox>
                <ModalCandidateSubmissionCheckbox
                  name="reasonMissingSkills"
                  value={values.reasonMissingSkills}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                >
                  Missing&nbsp;
                  <strong>skills</strong>
                </ModalCandidateSubmissionCheckbox>
                <ModalCandidateSubmissionCheckbox
                  name="reasonMissingExperience"
                  value={values.reasonMissingExperience}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                >
                  Lacks relevant&nbsp;
                  <strong>work experience</strong>
                </ModalCandidateSubmissionCheckbox>
                <ModalCandidateSubmissionCheckbox
                  name="reasonOther"
                  value={values.reasonOther}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                >
                  <strong>Other</strong>
                </ModalCandidateSubmissionCheckbox>
              </div>
            </>
            {/* Field: comments */}
            <FormControl id="comments" mt={2}>
              <FormLabel htmlFor="comments">Feedback: </FormLabel>
              <Textarea
                id="comments"
                as="textarea"
                rows={4}
                size="md"
                name="comments"
                placeholder="What was wrong with this candidate? Please be specific to help us improve future submissions."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
              />
            </FormControl>
            <Flex wrap="wrap" justifyContent="space-around" mt={4}>
              <Button
                variant="solid"
                onClick={handleSubmit}
                colorScheme="error"
                size="md"
                disabled={loading}
              >
                Submit
              </Button>
              <Button variant="outline" size="md" onClick={onCancel} disabled={loading}>
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Formik>
    </>
  );
}
