import type { KeyboardEvent } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  Heading,
  Text,
  Flex,
  Image,
  Link,
  ArrowLeftIcon,
  Box,
  UnorderedList,
  ListItem,
  Button,
  VStack,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Textarea,
  Tooltip,
  FormLabel,
  MultiSelectTypeahead,
  Typeahead,
  Input,
  Select,
  CheckboxToggle,
  Checkbox,
  Grid,
  WarningCircleSolidIcon,
  Divider,
  CheckCircleSolidIcon,
} from '@terminal/design-system';
import { PageLayout, RadioButton } from 'talent-hub/components';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import {
  Employment_Type_Choices_Enum,
  Job_Status_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import { getErrorMessage } from 'global/utils';
import type {
  EducationDegreeEnum,
  EmploymentTypeEnum,
  LevelEnum,
  RoleTypeEnum,
  YearsOfExperienceEnum,
} from 'global/types/graphql-service-tables.generated.types';
import submittedImg from './assets/submitted.svg?url';
import backgroundImg from './assets/background.svg?url';
import interviewImg from './assets/interview.svg?url';
import machineImg from './assets/machine.svg?url';
import type { InsertJobMutationVariables, FormData } from './data';
import type { serializeCreateJobOptions, serializeSelectJob } from './CreateEditJob.serializer';

import { serializeInsertJobVariables } from './CreateEditJob.serializer';

const validation_prompt_schema = Yup.object().shape({
  prompt: Yup.string().min(1).required('Please provide a brief description of the role.'),
});

const validation_job_schema = Yup.object().shape({
  jobTitle: Yup.string().required('This is a required field'),
  numberPositions: Yup.string().required('This is a required field'),
  jobType: Yup.string().required('This is a required field'),
  employmentType: Yup.string().required('This is a required field'),
  contractLength: Yup.string().when('employmentType', {
    is: (employmentType: string) => employmentType === Employment_Type_Choices_Enum.Contract,
    then: Yup.string().required('This is a required field'),
    otherwise: Yup.string().nullable(),
  }),
  locations: Yup.array()
    .of(Yup.string().required('Choose from acceptable locations'))
    .min(1, 'Choose from acceptable locations'),
  useTerminalRange: Yup.boolean().required(),
  minContractRate: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.Contract && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  maxContractRate: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.Contract && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  minimumSalary: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.FullTime && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  maximumSalary: Yup.number().when(['employmentType', 'useTerminalRange'], {
    is: (employmentType: string, useTerminalRange: boolean) =>
      employmentType === Employment_Type_Choices_Enum.FullTime && useTerminalRange === false,
    then: Yup.number().required('Field is required'),
    otherwise: Yup.number().nullable(),
  }),
  offeringBonus: Yup.string().when('employmentType', {
    is: (employmentType: string) => employmentType === Employment_Type_Choices_Enum.FullTime,
    then: Yup.string().required('This is a required field'),
    otherwise: Yup.string().nullable(),
  }),
  offeringEquity: Yup.string(),
  level: Yup.string().required('This is a required field'),
  techStack: Yup.array().of(
    Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required('This is a required field'),
  ),
  yearsExperience: Yup.string().required('This is a required field'),
  requiredSkills: Yup.array()
    .of(
      Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .required('This is a required field'),
    )
    .min(1, 'This is a required field')
    .max(3, 'Maximum 3 options allowed'),
  educationRequired: Yup.string().required('This is a required field'),
  niceToHaveSkills: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    }),
  ),
  aboutRole: Yup.string(),
  whatYouDo: Yup.string(),
  whatYouBring: Yup.string(),
  hiringManager: Yup.string(),
  additionalNotes: Yup.string(),
});

const bullet = '\u2022';
const handleKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
  const target = event.target as HTMLTextAreaElement;

  if (event.key !== '-') return;

  let parsedValue = target.value?.replaceAll('\n-', `\n${bullet}`) || '';

  if (parsedValue.startsWith('-')) {
    parsedValue = parsedValue.replace('-', `\u2022`);
  }

  target.value = parsedValue;
};

/**
 * This feature is close to being refactored, which is why I haven't spent much time refactoring it myself. Instead, I simply removed the abstraction and pasted all the code here to allow for updating the form's state from within this component.
 */
export function CreateEditJob({
  pageLayoutProps,
  options,
  viewMode,
  handleSubmit,
  initialValues,
  viewingOrg,
  generateJob,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  options: ReturnType<typeof serializeCreateJobOptions>['options'];
  viewMode: 'success' | 'form';
  handleSubmit: (formData: InsertJobMutationVariables) => void;
  initialValues: ReturnType<typeof serializeSelectJob>;
  viewingOrg: number;
  generateJob: {
    handleGenerateJob: ({ query }: { query: string }) => Promise<{
      error?: Error;
      data?: {
        aiJob: {
          jobTitle: string | null;
          roleType: RoleTypeEnum | null;
          employmentType: EmploymentTypeEnum | null;
          level: LevelEnum | null;
          yearsOfExperience: YearsOfExperienceEnum | null;
          skillsRequired: Array<string | null> | null;
          acceptableLocations: Array<string | null> | null;
          niceToHaveSkills: Array<string | null> | null;
          educationDegree: EducationDegreeEnum | null;
          aboutTheRole: string | null;
          whatYoullDo: string | null;
          whatYoullBring: string | null;
          mappedSkillsRequired: ({ label: string | null; value: number | null } | null)[] | null;
          mappedNiceToHaveSkills: ({ label: string | null; value: number | null } | null)[] | null;
          mappedAcceptableLocations: (number | null)[] | null;
        } | null;
      };
    }>;
    isLoading: boolean;
    prompt: string | null;
  };
}) {
  const { jobID } = useParams<{ jobID: string }>();
  const [was_draft_generation_successful, set_was_draft_generation_successful] = useState(false);
  const [draftInputError, setDraftInputError] = useState('');

  const {
    headCounts,
    jobTypes,
    acceptableLocations,
    jobLevelChoices,
    techStack,
    yearsOfExperience,
    educationRequirement,
    requiredSkills,
    niceToHaveSkills,
    organizationUsers,
    employmentTypes,
    contactLengths,
  } = options;

  const {
    values,
    errors,
    handleBlur,
    handleSubmit: formikHandleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    touched,
  } = useCustomFormik<FormData>({
    initialValues,
    validationSchema: validation_job_schema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: () => {
      const organizationUserId = organizationUsers.find((el) => el.label === values.hiringManager);
      handleSubmit(
        serializeInsertJobVariables(
          values,
          viewingOrg,
          Job_Status_Choices_Enum.Submitted,
          organizationUserId?.value,
        ),
      );
    },
  });

  const handleSaveDraft = async () => {
    // [TAL-718] We need to do this validation manually without using validateField() formik utility because on our custom hook useCustomFormik
    // we are ignoring the validation errors when we are not submitting the form and if we change that, we break the submit/create new role flow
    try {
      await Yup.reach(validation_job_schema, 'jobTitle').validate(values.jobTitle);
      const organizationUserId = organizationUsers.find((el) => el.label === values.hiringManager);

      setDraftInputError('');
      handleSubmit(
        serializeInsertJobVariables(
          values,
          viewingOrg,
          Job_Status_Choices_Enum.Draft,
          organizationUserId?.value,
        ),
      );
    } catch (error) {
      setDraftInputError(getErrorMessage(error));
    }
  };

  const promptModal = useDisclosure({
    // * Auto-open only on create mode
    defaultIsOpen: !jobID && !generateJob.prompt,
  });

  const promptForm = useCustomFormik<{
    prompt?: string;
  }>({
    initialValues: {
      prompt: undefined,
    },
    validateOnMount: true,
    validationSchema: validation_prompt_schema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async ({ prompt }) => {
      if (!prompt) return;
      const { data, error } = await generateJob.handleGenerateJob({
        query: prompt,
      });

      if (error || !data?.aiJob) return;

      setValues({
        ...values,
        jobTitle: data.aiJob.jobTitle || values.jobTitle,
        jobType: data.aiJob.roleType || values.jobType,
        employmentType:
          (data.aiJob.employmentType as Employment_Type_Choices_Enum | null) ||
          values.employmentType,
        level: data.aiJob.level || values.level,
        yearsExperience: data.aiJob.yearsOfExperience || values.yearsExperience,
        requiredSkills: data.aiJob.mappedSkillsRequired?.length
          ? (data.aiJob.mappedSkillsRequired as unknown as { value: string; label: string }[]) // * fixing the graphql generated type
          : values.requiredSkills,
        niceToHaveSkills: data.aiJob.mappedNiceToHaveSkills?.length
          ? (data.aiJob.mappedNiceToHaveSkills as unknown as { value: string; label: string }[]) // * fixing the graphql generated type
          : values.niceToHaveSkills,
        locations: data.aiJob.mappedAcceptableLocations?.length
          ? data.aiJob.mappedAcceptableLocations.map((id) => `${id}`)
          : values.locations,
        educationRequired: data.aiJob.educationDegree || values.educationRequired,
        aboutRole: data.aiJob.aboutTheRole || values.aboutRole,
        whatYouDo: data.aiJob.whatYoullDo || values.whatYouDo,
        whatYouBring: data.aiJob.whatYoullBring || values.whatYouBring,
      });
      set_was_draft_generation_successful(true);
    },
  });

  const on_handle_close_prompt_modal = () => {
    set_was_draft_generation_successful(false);
    promptModal.onClose();
  };

  return (
    <PageLayout
      {...pageLayoutProps}
      headerTitle={jobID ? 'Edit Draft Role' : 'Open a New Role'}
      superTitle={
        <Link
          textDecoration="none"
          pb={2}
          colorScheme="brand"
          as={RouterLink}
          to="/role"
          textTransform="none"
        >
          <ArrowLeftIcon mr={2} /> Back to Manage Roles
        </Link>
      }
    >
      <Box
        backgroundImage={backgroundImg}
        pos="fixed"
        left={0}
        top={0}
        bottom={0}
        right={0}
        zIndex={-1}
        pointerEvents="none"
      />
      {viewMode === 'success' && (
        <Box px={6}>
          <VStack
            flexDir="column"
            my={6}
            alignItems="center"
            border="1px solid"
            borderColor="ui.secondary"
            p={6}
            backgroundColor="white"
            spacing={4}
          >
            <Image
              src={submittedImg}
              alt=""
              maxH={{ base: 32, sm: '8.5rem' }}
              maxW="15.5rem"
              mt={6}
            />
            <Heading variant="heading-2" textAlign="center">
              Your Open Role Has Been Submitted!
            </Heading>
            <Text textAlign={['center', 'initial']}>
              Your new open role has been submitted and is now pending approval from our Talent
              Team. You can check the Manage Roles page to see the status of this role or submit
              another open role while you wait.
            </Text>
            <Button variant="solid" colorScheme="primary" to="/role" as={RouterLink}>
              OK, Great!
            </Button>
          </VStack>
        </Box>
      )}
      {viewMode === 'form' && (
        <Flex flexDir={['column', 'column', 'row']} alignItems="start" p={6}>
          <Flex w="xs" flexDir="column" mr={[0, 0, 6]}>
            <Flex
              mb={4}
              flexDir="column"
              px={4}
              py={3}
              bgColor="bg.primary"
              border="1px solid"
              borderColor="ui.secondary"
            >
              {generateJob.prompt && !generateJob.isLoading ? (
                <>
                  <Heading variant="heading-3">Job Creation Prompt</Heading>
                  <Tooltip label={generateJob.prompt}>
                    <Text color="text.disabled" mb={2} noOfLines={4}>
                      {generateJob.prompt}
                    </Text>
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label="Running this process will replace the current info in your Role."
                  >
                    <Button
                      variant="ghost"
                      colorScheme="primary"
                      onClick={promptModal.onOpen}
                      w="full"
                    >
                      Update Prompt
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  hasArrow
                  label="Use our assistant to automatically fill role details. You'll still be able to edit the role to your liking."
                >
                  <Button
                    variant="ghost"
                    colorScheme="brand"
                    onClick={promptModal.onOpen}
                    w="full"
                    id="role-creation-assistant-button"
                  >
                    Role Creation Assistant
                  </Button>
                </Tooltip>
              )}
            </Flex>
            <Flex
              flexDir="column"
              py={6}
              px={3}
              bg="bg.tertiary"
              border="1px solid"
              borderColor="ui.secondary"
            >
              <Heading variant="heading-3">Getting Started</Heading>
              <Text mt={2}>
                Reach available engineers and hire faster! Here’s what to expect after submitting
                your role information:
              </Text>
              <UnorderedList pl={2} pt={2}>
                <ListItem>
                  <Text>
                    We&rsquo;ll review your requested position, with approvals for core roles within
                    one business day
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    Your assigned Customer Success Director will contact you to discuss
                    compensation, leveling, and the Terminal process
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>Your new role will be live seven days after approval</Text>
                </ListItem>
                <ListItem>
                  <Text>
                    We&rsquo;ll check in with you on role progress after 2 weeks, 30 days, and 90
                    days
                  </Text>
                </ListItem>
              </UnorderedList>
              <Image alignSelf="center" src={interviewImg} alt="" maxH={48} maxW={48} mt={6} />
            </Flex>
          </Flex>
          <Flex
            w="full"
            h="full"
            flexDir="column"
            py={7}
            px={[6, 6, 10]}
            mt={[6, 6, 0]}
            bg="bg.primary"
            border="1px solid"
            borderColor="ui.secondary"
          >
            <Heading variant="heading-2">Basic Job Information</Heading>
            <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
              <FormControl
                isInvalid={(touched.jobTitle && !!errors.jobTitle) || !!draftInputError}
                isRequired
              >
                <FormLabel htmlFor="jobTitle" mb={2}>
                  Job Title
                </FormLabel>
                <Input
                  id="jobTitle"
                  name="jobTitle"
                  placeholder="Enter a job title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.jobTitle}
                />
                {errors.jobTitle && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.jobTitle}
                  </FormErrorMessage>
                )}
                {!!draftInputError && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {draftInputError}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={touched.numberPositions && !!errors.numberPositions}
                isRequired
              >
                <FormLabel htmlFor="numberPositions" mb={2}>
                  Number of Positions
                </FormLabel>
                <Select
                  id="numberPositions"
                  name="numberPositions"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color={values.numberPositions ? 'text.primary' : 'text.disabled'}
                  style={{
                    color: 'inherit',
                  }}
                >
                  {headCounts.map((head) => (
                    <option
                      value={head.value}
                      key={head.value}
                      selected={values.numberPositions === head.value}
                    >
                      {head.label}
                    </option>
                  ))}
                </Select>
                {errors.numberPositions && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.numberPositions}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.jobType && !!errors.jobType} isRequired>
                <FormLabel htmlFor="jobType" mb={2}>
                  Job Type/Family
                </FormLabel>
                <Select
                  id="jobType"
                  name="jobType"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color={values.jobType ? 'text.primary' : 'text.disabled'}
                  style={{
                    color: 'inherit',
                  }}
                >
                  {jobTypes.map((jobType) => (
                    <option
                      value={jobType.value}
                      key={jobType.value}
                      selected={values.jobType === jobType.value}
                    >
                      {jobType.label}
                    </option>
                  ))}
                </Select>
                {errors.jobType && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.jobType}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Flex gap={[4, 4, 6]}>
                <FormControl
                  isInvalid={touched.employmentType && !!errors.employmentType}
                  isRequired
                >
                  <FormLabel htmlFor="employmentType" mb={2}>
                    Employment Type
                  </FormLabel>
                  <Select
                    id="employmentType"
                    name="employmentType"
                    onChange={(e) => {
                      if (e.target.value === Employment_Type_Choices_Enum.FullTime) {
                        setValues({
                          ...values,
                          useTerminalRange: false,
                          minContractRate: null,
                          maxContractRate: null,
                          contractLength: '',
                        });
                      } else if (e.target.value === Employment_Type_Choices_Enum.Contract) {
                        setValues({
                          ...values,
                          useTerminalRange: false,
                          minimumSalary: null,
                          maximumSalary: null,
                          offeringBonus: '',
                          offeringEquity: '',
                        });
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    style={{
                      color: 'inherit',
                    }}
                  >
                    {employmentTypes.map((employmentType) => (
                      <option
                        value={employmentType.value}
                        key={employmentType.value}
                        selected={values.employmentType === employmentType.value}
                      >
                        {employmentType.label}
                      </option>
                    ))}
                  </Select>
                  {errors.employmentType && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.employmentType}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.contractLength}
                  isRequired={values.employmentType === Employment_Type_Choices_Enum.Contract}
                  isDisabled={values.employmentType !== Employment_Type_Choices_Enum.Contract}
                >
                  <FormLabel htmlFor="contractLength" mb={2}>
                    Contract Length
                  </FormLabel>
                  <Select
                    id="contractLength"
                    name="contractLength"
                    placeholder="Select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    color={values.contractLength ? 'text.primary' : 'text.disabled'}
                    style={{
                      color:
                        values.employmentType === Employment_Type_Choices_Enum.Contract
                          ? 'inherit'
                          : '',
                    }}
                  >
                    {contactLengths.map((contractLength) => (
                      <option
                        value={contractLength.value}
                        key={contractLength.value}
                        selected={values.contractLength === contractLength.value}
                      >
                        {contractLength.label}
                      </option>
                    ))}
                  </Select>
                  {errors.contractLength && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.contractLength}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
            </Grid>
            <Flex mt={[4, 4, 6]}>
              <FormControl isInvalid={touched.locations && !!errors.locations} isRequired>
                <FormLabel htmlFor="locations" w="fit-content">
                  Acceptable Locations
                </FormLabel>
                <Flex flexWrap="wrap">
                  {acceptableLocations.map((location) => (
                    <CheckboxToggle
                      key={location.value}
                      id="locations"
                      name="locations"
                      onChange={handleChange}
                      value={location.value}
                      mr={3}
                      mt={2}
                      isChecked={values.locations.includes(location.value)}
                    >
                      {location.label}
                    </CheckboxToggle>
                  ))}
                </Flex>
                {errors.locations && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> Choose from acceptable
                    locations
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            <Divider mb={10} mt={8} />

            <Heading variant="heading-2">Salary Information</Heading>
            {values.employmentType === Employment_Type_Choices_Enum.FullTime ? (
              <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']}>
                <FormControl
                  isInvalid={
                    (touched.minimumSalary && !!errors.minimumSalary) ||
                    (touched.maximumSalary && !!errors.maximumSalary)
                  }
                  isRequired
                >
                  <FormLabel htmlFor="minimumSalary" mb={2}>
                    Salary Range (USD)
                  </FormLabel>
                  <Flex alignItems="center" mb={2}>
                    <Input
                      id="minimumSalary"
                      name="minimumSalary"
                      key="minimumSalary"
                      placeholder="Minimum Salary"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.minimumSalary ? values.minimumSalary : ''} // * to hide 0
                      disabled={values.useTerminalRange}
                    />
                    <Text mx={2}>to</Text>
                    <Input
                      id="maximumSalary"
                      name="maximumSalary"
                      key="maximumSalary"
                      placeholder="Maximum Salary"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.maximumSalary ? values.maximumSalary : ''} // * to hide 0
                      disabled={values.useTerminalRange}
                    />
                  </Flex>
                  <Checkbox
                    variant="square"
                    mt={2}
                    name="useTerminalRange"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValues({
                          ...values,
                          minimumSalary: 0,
                          maximumSalary: 0,
                        });
                      }
                      handleChange(e);
                    }}
                    isChecked={values.useTerminalRange}
                  >
                    <Text>I want to use Terminal&apos;s recommended ranges</Text>
                  </Checkbox>
                  {(!!errors.minimumSalary || !!errors.maximumSalary) && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> Enter a valid range or use
                      Terminal&apos;s recommended ranges
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  maxW={44}
                  isInvalid={touched.offeringBonus && !!errors.offeringBonus}
                  isRequired
                >
                  <FormLabel htmlFor="offeringBonus" mb={2}>
                    Offering Bonus?
                  </FormLabel>
                  <Flex gridGap={4}>
                    <RadioButton
                      id="offeringBonus"
                      name="offeringBonus"
                      onChange={handleChange}
                      value="YES"
                      isChecked={values.offeringBonus === 'YES'}
                      mr={2}
                    >
                      <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                        <Text textAlign="center">Yes</Text>
                      </Box>
                    </RadioButton>
                    <RadioButton
                      id="offeringBonus"
                      name="offeringBonus"
                      onChange={handleChange}
                      value="NO"
                      isChecked={values.offeringBonus === 'NO'}
                    >
                      <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                        <Text textAlign="center">No</Text>
                      </Box>
                    </RadioButton>
                  </Flex>
                  {errors.offeringBonus && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.offeringBonus}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  maxW={44}
                  isInvalid={touched.offeringEquity && !!errors.offeringEquity}
                >
                  <FormLabel htmlFor="offeringEquity" mb={2}>
                    Offering Equity?
                  </FormLabel>
                  <Flex gridGap={4}>
                    <RadioButton
                      id="offeringEquity"
                      name="offeringEquity"
                      onChange={handleChange}
                      value="YES"
                      isChecked={values.offeringEquity === 'YES'}
                      mr={2}
                    >
                      <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                        <Text textAlign="center">Yes</Text>
                      </Box>
                    </RadioButton>
                    <RadioButton
                      id="offeringEquity"
                      name="offeringEquity"
                      onChange={handleChange}
                      value="NO"
                      isChecked={values.offeringEquity === 'NO'}
                    >
                      <Box p={2} border="1px solid" borderColor="ui.secondary" borderRadius="base">
                        <Text textAlign="center">No</Text>
                      </Box>
                    </RadioButton>
                  </Flex>
                  {errors.offeringEquity && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.offeringEquity}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '2fr 1fr 1fr']}>
                <FormControl
                  isInvalid={
                    (touched.minContractRate && !!errors.minContractRate) ||
                    (touched.maxContractRate && !!errors.maxContractRate)
                  }
                  isRequired
                >
                  <FormLabel htmlFor="minContractRate" mb={2}>
                    Contractor Rate Range (USD)
                  </FormLabel>
                  <Flex alignItems="center" mb={2}>
                    <Input
                      id="minContractRate"
                      name="minContractRate"
                      key="minContractRate"
                      placeholder="Minimum Rate"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.minContractRate ? values.minContractRate : ''} // * to hide 0
                      disabled={values.useTerminalRange}
                    />
                    <Text mx={2}>to</Text>
                    <Input
                      id="maxContractRate"
                      name="maxContractRate"
                      key="maxContractRate"
                      placeholder="Maximum Rate"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.maxContractRate ? values.maxContractRate : ''} // * to hide 0
                      disabled={values.useTerminalRange}
                    />
                  </Flex>
                  <Checkbox
                    variant="square"
                    mt={2}
                    name="useTerminalRange"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValues({
                          ...values,
                          minContractRate: 0,
                          maxContractRate: 0,
                        });
                      }
                      handleChange(e);
                    }}
                    isChecked={values.useTerminalRange}
                  >
                    <Text>I want to use Terminal&apos;s recommended ranges</Text>
                  </Checkbox>
                  {(!!errors.minContractRate || !!errors.maxContractRate) && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> Enter a valid range or use
                      Terminal&apos;s recommended ranges
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Grid>
            )}

            <Divider mb={10} mt={8} />

            <Heading variant="heading-2">Skills & Experience</Heading>
            <Grid mt={7} gap={[4, 4, 6]} gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}>
              <FormControl isInvalid={touched.level && !!errors.level} isRequired>
                <FormLabel htmlFor="level" mb={2}>
                  Level
                </FormLabel>
                <Select
                  id="level"
                  name="level"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color={values.level ? 'text.primary' : 'text.disabled'}
                  style={{
                    color: 'inherit',
                  }}
                >
                  {jobLevelChoices.map((el) => (
                    <option value={el.value} key={el.value} selected={values.level === el.value}>
                      {el.label}
                    </option>
                  ))}
                </Select>
                {errors.level && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.level}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.techStack && !!errors.techStack}>
                <MultiSelectTypeahead
                  noOptionsMatchedCopy="Tech Stack, comma-separated"
                  shouldOpenOnFocus
                  placeholder="Start typing a language or framework to add"
                  options={techStack}
                  fullScreen={{
                    title: 'Tech Stack Skills',
                  }}
                  name="techStack"
                  renderBefore={({ getLabelProps }) => (
                    <FormLabel {...getLabelProps()} mb={2}>
                      Tech Stack
                    </FormLabel>
                  )}
                  containerProps={{
                    display: 'flex',
                    flexDirection: 'column',
                    sx: {
                      '& div': {
                        bg: 'bg.primary',
                      },
                    },
                  }}
                  onBlur={handleBlur}
                  onSelectedItemsChange={(
                    name: string,
                    selectedValues: { value: string; label: string }[],
                  ) => setFieldValue(name, selectedValues)}
                  isInvalid={touched.techStack && !!errors.techStack}
                  initialSelectedOptions={initialValues.techStack}
                />
                {errors.techStack && (
                  <FormErrorMessage>
                    <>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.techStack}
                    </>
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={touched.yearsExperience && !!errors.yearsExperience}
                isRequired
              >
                <FormLabel htmlFor="yearsExperience" mb={2}>
                  Years of Experience
                </FormLabel>
                <Select
                  id="yearsExperience"
                  name="yearsExperience"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color={values.yearsExperience ? 'text.primary' : 'text.disabled'}
                  style={{
                    color: 'inherit',
                  }}
                >
                  {yearsOfExperience.map((el) => (
                    <option
                      value={el.value}
                      key={el.value}
                      selected={values.yearsExperience === el.value}
                    >
                      {el.label}
                    </option>
                  ))}
                </Select>
                {errors.yearsExperience && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.yearsExperience}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={touched.educationRequired && !!errors.educationRequired}
                isRequired
              >
                <FormLabel htmlFor="educationRequired" mb={2}>
                  Education Required
                </FormLabel>
                <Select
                  id="educationRequired"
                  name="educationRequired"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color={values.educationRequired ? 'text.primary' : 'text.disabled'}
                  style={{
                    color: 'inherit',
                  }}
                >
                  {educationRequirement.map((el) => (
                    <option
                      value={el.value}
                      key={el.value}
                      selected={values.educationRequired === el.value}
                    >
                      {el.label}
                    </option>
                  ))}
                </Select>
                {errors.educationRequired && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.educationRequired}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.requiredSkills && !!errors.requiredSkills} isRequired>
                <MultiSelectTypeahead
                  noOptionsMatchedCopy="Required skills, comma-separated"
                  placeholder="Start typing required skills"
                  shouldOpenOnFocus
                  options={requiredSkills}
                  formInputValue={values.requiredSkills?.length}
                  forceNewValues={values.requiredSkills}
                  fullScreen={{
                    title: 'Required Skills (max. 3)',
                  }}
                  name="requiredSkills"
                  renderBefore={({ getLabelProps }) => (
                    <Flex alignItems="baseline">
                      <FormLabel {...getLabelProps()} mb={2}>
                        Required Skills
                      </FormLabel>
                      <Text variant="hint">(max. 3)</Text>
                    </Flex>
                  )}
                  containerProps={{
                    display: 'flex',
                    flexDirection: 'column',
                    sx: {
                      '& div': {
                        bg: 'bg.primary',
                      },
                    },
                  }}
                  onBlur={handleBlur}
                  onSelectedItemsChange={(
                    name: string,
                    selectedValues: { value: string; label: string }[],
                  ) => setFieldValue(name, selectedValues)}
                  isInvalid={touched.requiredSkills && !!errors.requiredSkills}
                  initialSelectedOptions={initialValues.requiredSkills}
                  isDisabled={values.requiredSkills?.length >= 3}
                />
                {errors.requiredSkills && (
                  <FormErrorMessage>
                    <>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.requiredSkills}
                    </>
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.niceToHaveSkills && !!errors.niceToHaveSkills}>
                <MultiSelectTypeahead
                  noOptionsMatchedCopy="Nice-to-Have Skills, comma-separated"
                  placeholder="Start typing nice-to-have skills"
                  shouldOpenOnFocus
                  options={niceToHaveSkills}
                  formInputValue={values.niceToHaveSkills?.length}
                  forceNewValues={values.niceToHaveSkills}
                  fullScreen={{
                    title: 'Nice to have skills',
                  }}
                  name="niceToHaveSkills"
                  renderBefore={({ getLabelProps }) => (
                    <FormLabel {...getLabelProps()} mb={2}>
                      Nice-to-Have Skills
                    </FormLabel>
                  )}
                  containerProps={{
                    display: 'flex',
                    flexDirection: 'column',
                    sx: {
                      '& div': {
                        bg: 'bg.primary',
                      },
                    },
                  }}
                  onBlur={handleBlur}
                  onSelectedItemsChange={(
                    name: string,
                    selectedValues: { value: string; label: string }[],
                  ) => setFieldValue(name, selectedValues)}
                  isInvalid={!!errors.niceToHaveSkills}
                  initialSelectedOptions={initialValues.niceToHaveSkills}
                />
                {errors.niceToHaveSkills && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.niceToHaveSkills}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Grid>

            <Divider mb={10} mt={8} />

            <Heading variant="heading-2">Role Description Details</Heading>
            <Flex w="full" mt={7} flexDir="column" gridGap={6}>
              <FormControl isInvalid={touched.aboutRole && !!errors.aboutRole}>
                <FormLabel htmlFor="aboutRole" mb={2}>
                  About the Role
                </FormLabel>
                <Textarea
                  id="aboutRole"
                  name="aboutRole"
                  rows={8}
                  placeholder="Enter summary information about the role and team"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  value={values.aboutRole}
                  borderColor={
                    touched.aboutRole && !!errors.aboutRole ? 'ui.error' : 'ui.secondary'
                  }
                />
                {errors.aboutRole && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.aboutRole}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.whatYouDo && !!errors.whatYouDo}>
                <FormLabel htmlFor="whatYouDo" mb={2}>
                  What You&apos;ll Do
                </FormLabel>
                <Textarea
                  id="whatYouDo"
                  name="whatYouDo"
                  rows={8}
                  placeholder="Enter the day-to-day responsibilities of this role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  value={values.whatYouDo}
                  borderColor={
                    touched.whatYouDo && !!errors.whatYouDo ? 'ui.error' : 'ui.secondary'
                  }
                />
                {errors.whatYouDo && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.whatYouDo}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={touched.whatYouBring && !!errors.whatYouBring}>
                <FormLabel htmlFor="whatYouBring" mb={2}>
                  What You&apos;ll Bring
                </FormLabel>
                <Textarea
                  id="whatYouBring"
                  name="whatYouBring"
                  rows={8}
                  placeholder="Enter requirements for this role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyUp={handleKeyUp}
                  value={values.whatYouBring}
                  borderColor={
                    touched.whatYouBring && !!errors.whatYouBring ? 'ui.error' : 'ui.secondary'
                  }
                />
                {errors.whatYouBring && (
                  <FormErrorMessage>
                    <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.whatYouBring}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>

            {/* Hiring manager info */}
            <Flex bg="bg.tertiary" mt={9} p={6} flexDir="column">
              <Heading variant="heading-2" mt={2}>
                Hiring Manager Info
              </Heading>
              <Flex w="full" mt={7} flexDir="column" gridGap={6}>
                <FormControl isInvalid={touched.hiringManager && !!errors.hiringManager}>
                  <Typeahead
                    name="hiringManager"
                    fullScreen={{
                      title: 'Hiring Manager',
                    }}
                    placeholder="Enter hiring manager name"
                    shouldOpenOnFocus
                    options={organizationUsers.map((user) => user.label)}
                    formInputValue={values.hiringManager}
                    containerProps={{
                      display: 'flex',
                      flexDirection: 'column',
                      sx: {
                        '& input': {
                          bg: 'bg.primary',
                        },
                      },
                    }}
                    onBlur={(e) => {
                      const hiringUser = organizationUsers.find(
                        (el) => el.label === e.target.value,
                      );
                      if (!hiringUser?.value) {
                        setFieldValue('hiringManager', '');
                      }
                    }}
                    onSelectionChange={(field, value) => setFieldValue(field, value)}
                    onInputChange={(event) => setFieldValue('hiringManager', event.target.value)}
                    renderBefore={({ getLabelProps }) => (
                      <FormLabel {...getLabelProps()} htmlFor="hiringManager" mb={2}>
                        Hiring Manager
                      </FormLabel>
                    )}
                  />
                  {errors.hiringManager && (
                    <FormErrorMessage>
                      <WarningCircleSolidIcon color="ui.error" mr={2} /> {errors.hiringManager}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="additionalNotes" mb={2}>
                    Additional Notes
                  </FormLabel>
                  <Textarea
                    id="additionalNotes"
                    name="additionalNotes"
                    rows={8}
                    placeholder="Enter any additional notes for Terminal about this role"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    value={values.additionalNotes}
                  />
                </FormControl>
              </Flex>
            </Flex>

            {/* General error message */}
            {(Object.keys(errors).length > 0 || !!draftInputError) && (
              <Flex
                bg="ui.lightest.error"
                alignItems="center"
                justifyContent="center"
                p={6}
                mt={6}
                border="1px solid"
                borderColor="ui.error"
              >
                <WarningCircleSolidIcon color="ui.error" mr={2} />
                <Text color="text.error">
                  {draftInputError !== ''
                    ? 'Please enter a Job Title to save this role as a draft.'
                    : 'There are some incomplete or invalid fields in the form above. Please complete them to continue.'}
                </Text>
              </Flex>
            )}
            <Flex direction={['column', 'row', 'row']} mt={6} justifyContent="center">
              <Button size="lg" onClick={() => formikHandleSubmit()} variant="solid">
                Submit
              </Button>
              <Button
                marginLeft={[0, 6, 6]}
                marginTop={[6, 0, 0]}
                size="lg"
                onClick={() => handleSaveDraft()}
                variant="outline"
              >
                Save Draft
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Modal
        isCentered
        isOpen={promptModal.isOpen}
        onClose={on_handle_close_prompt_modal}
        scrollBehavior="outside"
        closeOnEsc={false}
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        motionPreset="scale"
      >
        <ModalOverlay bg="#25383FCC" />

        <ModalContent maxW={['21.938rem', '21.938rem', 'lg']}>
          {was_draft_generation_successful ? (
            <>
              <ModalBody textAlign="center">
                <CheckCircleSolidIcon color="ui.success" w={24} h={24} mb={4} />
                <Heading variant="heading-2">Draft Role Successfully Filled</Heading>
                <Text>
                  Your draft role is ready for your review. Edit any details to your liking, or
                  rerun the assistant with a new prompt to replace the current draft.
                </Text>
              </ModalBody>
              <ModalFooter p={4} px={4}>
                <Button
                  variant="solid"
                  id="review-ia-assistant-modal"
                  colorScheme="primary"
                  size="lg"
                  type="button"
                  w="full"
                  onClick={on_handle_close_prompt_modal}
                >
                  Review Role
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader px={3} py={6} bgColor="bg.tertiary" textAlign="center">
                <Image src={machineImg} alt="" maxW={40} my={4} mx="auto" />
                <ModalCloseButton size="lg" top={5} right={4} id="close-ia-assistant-modal" />
                <Heading variant="heading-2" textAlign="center">
                  Role Creation Assistant
                </Heading>
              </ModalHeader>
              <ModalBody>
                <Text>
                  In 1-3 sentences, tell us a little about the role you&apos;re hiring for. Include
                  any details you know about, such as required skills, years of experience,
                  nice-to-haves, your preferred time zone or location, etc. We&apos;ll tailor the
                  draft role to match your preferences, and you&apos;ll be able to edit anything we
                  pre-fill.
                </Text>
                <FormControl
                  mt={8}
                  isInvalid={promptForm.touched.prompt && !!promptForm.errors.prompt}
                >
                  <Textarea
                    id="prompt"
                    name="prompt"
                    aria-label="job-description"
                    rows={4}
                    onChange={promptForm.handleChange}
                    onBlur={promptForm.handleBlur}
                    value={promptForm.values.prompt}
                    disabled={promptForm.isSubmitting}
                    placeholder="Senior full stack engineer, Node and React, at least 5 years experience..."
                  />
                  {promptForm.errors.prompt && (
                    <FormErrorMessage>{promptForm.errors.prompt}</FormErrorMessage>
                  )}
                </FormControl>
              </ModalBody>
              <ModalFooter p={4} px={4}>
                <Button
                  variant="solid"
                  colorScheme="primary"
                  id="generate-ai-assistant-modal"
                  size="lg"
                  isLoading={generateJob.isLoading || promptForm.isSubmitting}
                  type="button"
                  w="full"
                  onClick={() => promptForm.handleSubmit()}
                >
                  Generate
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </PageLayout>
  );
}
