import { useEffect } from 'react';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { SelectMemberDirectory } from './data/graphql';

import type {
  MemberDirectoryQueryVariables,
  MemberDirectoryQuery as MemberDirectoryQueryType,
} from './data';

import { MemberDirectory } from './MemberDirectory';
import { serializerForMemberDirectory } from './MemberDirectory.serializer';

export function MemberDirectoryController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const { data, loading } = useQuery<MemberDirectoryQueryType, MemberDirectoryQueryVariables>(
    SelectMemberDirectory,
    {
      variables: {
        organization: viewingOrganization.ID,
      },
      fetchPolicy: 'network-only',
    },
  );

  const members = serializerForMemberDirectory(data);

  // Track page views
  useEffect(() => {
    // Track page view
    events.track(events.name.viewedYourMembers);
  }, []);

  return (
    <MemberDirectory
      members={members}
      loading={loading}
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
    />
  );
}
