import { useHistory, useParams } from 'react-router-dom';
import { useCustomerAuthorizedUserSession, useSavedCandidates } from 'talent-hub/utils';
import { CandidateProfileController } from '../../../../../role/client/explore-candidates/candidate-profile';
import { roleTabs } from '../../components';
import { ViewType } from '../../../explore-candidates';

export function CandidateProfileDrawer() {
  const history = useHistory();
  const { candidateID, activeRole } = useParams<{ activeRole?: string; candidateID?: string }>();
  const { user } = useCustomerAuthorizedUserSession();
  const { deleteSavedCandidate, insertSavedCandidate } = useSavedCandidates();
  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };
  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  if (!candidateID) return null;

  return (
    <CandidateProfileController
      key={`candidate-${candidateID}`}
      candidateID={Number(candidateID)}
      onCloseCandidateProfileClick={() => history.push(`/role/${activeRole}/${roleTabs.overview}`)}
      handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
      handleOnSaveCandidate={handleOnSaveCandidate}
      viewPage={ViewType.ROLE_OVERVIEW}
    />
  );
}
