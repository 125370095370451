import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  ModalFooter,
  EmailVerificationIcon,
  MultiSelectTypeahead,
  FormControl,
  FormErrorMessage,
  Text,
  Checkbox,
  MailIcon,
} from '@terminal/design-system';

const validationSchema = Yup.object().shape({
  users: Yup.array()
    .min(1)
    .required()
    .of(
      Yup.object()
        .shape({
          value: Yup.string().email('Must be a valid email').required(),
          label: Yup.string().required(),
        })
        .nullable()
        .required(),
    ),
  isRoleAccess: Yup.boolean(),
});

export function InviteToFeedbackModal({
  onClose,
  isOpen,
  options,
  isLoading,
  handleOnSubmitInviteFeedback,
  modalTitle,
  modalDescription,
  shouldHide_candidateCheckbox,
}: {
  onClose: () => void;
  isOpen: boolean;
  options: { value: string; label: string }[];
  isLoading: boolean;
  modalTitle: string;
  modalDescription: string;
  handleOnSubmitInviteFeedback: (currentValues: {
    users: {
      value: string;
      label: string;
    }[];
    isRoleAccess: boolean;
  }) => { error: boolean };
  shouldHide_candidateCheckbox: boolean;
}) {
  const { isValid, values, errors, handleChange, handleBlur, setValues, handleSubmit } = useFormik<{
    users: { value: string; label: string }[];
    isRoleAccess: boolean;
  }>({
    initialValues: {
      users: [],
      isRoleAccess: shouldHide_candidateCheckbox,
    },
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (currentValues) => {
      const { error } = handleOnSubmitInviteFeedback(currentValues);
      if (!error) {
        onClose();
      }
    },
  });

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      returnFocusOnClose={false}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent maxW={['21.938rem', '21.938rem', 'lg']}>
        <ModalHeader pt={6} bg="transparent" pb={0}>
          <Heading
            borderBottom="1px solid"
            borderColor="ui.inverse.secondary"
            color="brand.darker"
            display="inline-block"
            pt={3}
            pb={6}
            pl={4}
            pr={16}
            variant="heading-4"
          >
            <MailIcon
              w={4}
              h={4}
              mr={3}
              __css={{
                path: {
                  fill: 'ui.lighter.warning',
                },
              }}
            />{' '}
            {modalTitle}
          </Heading>
          <ModalCloseButton size="lg" top={6} right={6} />
        </ModalHeader>
        <ModalBody>
          <Text>{modalDescription}</Text>
          <FormControl id="users" isInvalid={!!errors.users?.length} mt={8}>
            <MultiSelectTypeahead
              noOptionsMatchedCopy="Email, comma-separated"
              placeholder="Select team members or enter email addresses"
              isLoading={isLoading}
              options={options}
              validateEntry={(value: string, index: number) => {
                if (!value) return false;
                if (!Array.isArray(errors.users)) return true;
                return !errors.users?.[index];
              }}
              shouldCreateOptions
              showItemsValue
              fullScreen={{
                title: 'Invite Others',
              }}
              name="users"
              onBlur={handleBlur}
              onSelectedItemsChange={(
                name: string,
                selectedValues: { value: string; label: string }[],
              ) => {
                setValues({ ...values, [name]: selectedValues });
              }}
            />
            {Array.isArray(errors?.users) && (
              <FormErrorMessage>
                {`${
                  (errors.users as []).filter(Boolean).length
                } email address(es) are not formatted properly`}
              </FormErrorMessage>
            )}
          </FormControl>
          {shouldHide_candidateCheckbox ? null : (
            <FormControl mt={8} id="isRoleAccess" aria-checked={values.isRoleAccess}>
              <Checkbox
                name="isRoleAccess"
                onChange={handleChange}
                onBlur={handleBlur}
                isChecked={values.isRoleAccess}
              >
                <Text>
                  Add access to <b>all</b> candidates for this role
                </Text>
              </Checkbox>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter p={4} px={4}>
          <Button
            variant="solid"
            colorScheme="primary"
            size="lg"
            leftIcon={<EmailVerificationIcon fontSize="lg" />}
            type="button"
            onClick={() => handleSubmit()}
            disabled={!isValid}
          >
            Send Invitation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
