import type { CheckboxButtonProps } from '@terminal/design-system';
import { CheckboxButton } from '@terminal/design-system';

export function InvertedCheckboxButton(props: CheckboxButtonProps) {
  return (
    <CheckboxButton
      {...props}
      borderColor="text.inverse"
      color="text.inverse"
      bg={props.isChecked ? 'brand.lighter' : 'inherit'}
      _hover={{
        bg: 'brand.lightest',
        cursor: 'pointer',
        color: 'text.primary',
      }}
    />
  );
}
