import { useCalendlyEventListener, InlineWidget } from 'react-calendly';

const meetingURL = import.meta.env.REACT_APP_CALENDLY_MEETING_URL;

export function CalendlyWidget({
  additionalQueryParam = '',
  onProfilePageViewed,
  onDateAndTimeSelected,
  onEventTypeViewed,
  onEventScheduled,
  ...inlineWidtgetProps
}: { additionalQueryParam?: string } & Omit<React.ComponentProps<typeof InlineWidget>, 'url'> &
  Parameters<typeof useCalendlyEventListener>[number]) {
  useCalendlyEventListener({
    onProfilePageViewed,
    onDateAndTimeSelected,
    onEventTypeViewed,
    onEventScheduled,
  });

  return (
    <InlineWidget
      styles={{ width: '100%', height: '100%' }}
      url={`https://calendly.com/${meetingURL}?hide_gdpr_banner=1${additionalQueryParam}`}
      {...inlineWidtgetProps}
    />
  );
}
