// @ts-nocheck
/* eslint-disable */
import { name as e } from '.';

// This file is meant to allow the marketing team to add custom events for analytics tools that are
// included via third-parties. Example, linkedin, facebook, etc...

/* eslint-disable no-unused-vars */
const notImplemented = (name) => {
  return async () => {
    // console.log(`${name} not implemented`);
  };
};

/* eslint-disable no-unused-vars */
const track = async (name, properties) => {
  try {
    if (
      name === e.authentication.completed &&
      document.location.toString().match('^https://hub.terminal.io/onboard')
    ) {
      window.lintrk('track', { conversion_id: 14152932 });
    }
  } catch (error) {
    // console.log('FAILED to track', name, properties);
  }
};

export default {
  name: 'marketing',
  identify: notImplemented('identify'),
  isInitialized: () => true,
  trackPage: notImplemented('trackPage'),
  setUserProperties: notImplemented('setUserProperties'),
  initialize: notImplemented('initialize'),
  track,
  reset: notImplemented('reset'),
};
