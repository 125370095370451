import moment from 'moment';
import type {
  RoleDataQuery,
  RoleFirstSubmission,
  RoleOfferAccepted,
  RoleFirstInterview,
} from '../../data';

type DataKeys =
  | 'first_approved'
  | 'first_submission'
  | 'first_interview_scheduled'
  | 'first_offer_accepted';

type DataKeyNumbers = [DataKeys, number];

const dataKeysAndNumbers: DataKeyNumbers[] = [
  ['first_approved', 3], // Role Activated
  ['first_submission', 7],
  ['first_interview_scheduled', 10],
  ['first_offer_accepted', 60], // First Offer Signed
];

// To return props to show when the milestones when JobOverviewMetrics is fetching
const createEmptyRoleMilestone = ([,]: DataKeyNumbers) => ({
  completed: false,
  date: '',
  datePrefix: 'Target for',
});

const createRoleMilestone = ({
  data,
  roleOpenedDate,
  tp_kickoff_date,
  current_milestone: [dataKey, numberOfDay],
}: {
  data: RoleDataQuery;
  roleOpenedDate?: string;
  tp_kickoff_date?: string;
  current_milestone: DataKeyNumbers;
}) => {
  const format = 'MMMM D, YYYY';

  // when completed
  if (dataKey === 'first_approved' || (data[dataKey] != null && data[dataKey].length)) {
    let date;

    if (dataKey === 'first_approved') {
      date = tp_kickoff_date || roleOpenedDate;
    } else {
      const milestone:
        | RoleFirstSubmission[number]
        | RoleFirstInterview[number]
        | RoleOfferAccepted[number]
        | undefined = data[dataKey][0];

      date = milestone?.created_at;
    }

    return {
      completed: true,
      date: moment(date).format(format),
      datePrefix: 'Completed',
    };
  }

  return {
    completed: false,
    date: moment(roleOpenedDate).add(numberOfDay, 'days').format(format),
    datePrefix: 'Target for',
  };
};

export function serializeToRoleMilestones({
  data,
  roleOpenedDate,
  tp_kickoff_date,
}: {
  data: RoleDataQuery;
  roleOpenedDate?: string;
  tp_kickoff_date?: string;
}) {
  const [milestone1, milestone2, milestone3, milestone4] = dataKeysAndNumbers.map(
    (current_milestone) => {
      return roleOpenedDate
        ? createRoleMilestone({
            data,
            roleOpenedDate,
            tp_kickoff_date,
            current_milestone,
          })
        : createEmptyRoleMilestone(current_milestone);
    },
  );

  return { milestone1, milestone2, milestone3, milestone4 };
}
