import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as events from 'global/events';
import { useLocalStorage, useQuery } from 'global/utils';

import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { SelectOrganizationList } from './data';
import type { SelectOrganizationListQuery, SelectOrganizationListQueryVariables } from './data';
import { ChangeOrganization } from './ChangeOrganization';
import { serializeOrganizationsList } from './ChangeOrganization.serializer';

export function ChangeOrganizationController() {
  const history = useHistory();
  const {
    setViewingOrg,
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();

  const [favoriteOrganizationIDs, setFavoriteOrganizationsIDs] = useLocalStorage<number[]>(
    'fOrgs',
    [],
  );

  const { loading, data } = useQuery<
    SelectOrganizationListQuery,
    SelectOrganizationListQueryVariables
  >(SelectOrganizationList);

  // Track page views
  useEffect(() => {
    // Track page view
    events.track(events.name.viewedChangeOrganization);
  }, []);

  const organizations = serializeOrganizationsList(favoriteOrganizationIDs, data);

  return (
    <ChangeOrganization
      organizations={organizations}
      loading={loading}
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      onClick_toggleFavoriteOrganization={(ID) => {
        setFavoriteOrganizationsIDs((currentIDs: number[]) => {
          if (currentIDs.includes(ID)) {
            return currentIDs.filter((number) => number !== ID);
          }
          return [ID, ...currentIDs];
        });
      }}
      onChangeCurrentOrganization={(id: number) => {
        setViewingOrg(id);
        history?.push('/');
      }}
    />
  );
}
