import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  useDisclosure,
  VStack,
  Text,
  Image,
} from '@terminal/design-system';
import { useTalentHubFlags } from 'talent-hub/utils';
import type { AsyncStatus } from 'talent-hub/constants';
import { CandidateStageStatus } from 'talent-hub/constants';
import { CandidateInformation } from 'talent-hub/components';
import type { serializeJobCandidateProfile } from 'talent-hub/components/CandidateInformation/JobCandidateProfile.serializer';
import { CandidateResumeViewerModal } from 'talent-hub/components/CandidateResumeViewerModal';
import { RoleLayout, InviteToFeedbackModal, RoleBlankLoadingScreen } from '../../components';
import {
  ModalCandidateStatus,
  ModalCandidateSubmissionReview,
  StageProgressStatuses,
  CandidateInterviewFeedback,
} from './components';
import plantSrc from '../../../../../components/ErrorFallback/assets/plant.svg?url';

export function CandidateProfile({
  handleCandidateSubmissionSubmit,
  handleResumeLinkClicked,
  loading,
  profile,
  reviewerName,
  roleLayoutProps,
  stage,
  submissionViewStatus,
  hasInterviewFeedback,
  isLoadingInsertInterview,
  onInterviewFeedbackSubmit,
  onBackToFeedbackCLick,
  interviewFeedbackUtils,
  interviewStatus,
  isClientReviewer,
  candidateHighlights,
}: {
  handleCandidateSubmissionSubmit: ({ comments, decision, reasons }: any) => void;
  handleResumeLinkClicked: (fileExtension?: string) => Promise<void>;
  loading: boolean;
  // TODO: Improve this type - is not the righ type used here
  profile: ReturnType<typeof serializeJobCandidateProfile>['profile'];
  reviewerName: string;
  roleLayoutProps: React.ComponentProps<typeof RoleLayout>;
  stage: ReturnType<typeof serializeJobCandidateProfile>['stage'];
  submissionViewStatus: AsyncStatus;
  hasInterviewFeedback: boolean;
  isLoadingInsertInterview: boolean;
  onInterviewFeedbackSubmit: (rating: string, comments: string) => void;
  onBackToFeedbackCLick: () => void;
  interviewFeedbackUtils: {
    isLoading: boolean;
    users: { value: string; label: string }[];
    handleOnSubmitInviteFeedback: (currentValues: {
      users: {
        value: string;
        label: string;
      }[];
      isRoleAccess: boolean;
    }) => { error: boolean };
  };
  interviewStatus: string;
  isClientReviewer: boolean;
  candidateHighlights: {
    badges: ReturnType<typeof serializeJobCandidateProfile>['badges'];
    highlights: ReturnType<typeof serializeJobCandidateProfile>['highlights'];
    activeMessage: ReturnType<typeof serializeJobCandidateProfile>['activeMessage'];
  };
}) {
  const feedbackModal = useDisclosure();
  const candidateResumeModal = useDisclosure();
  const featureFlags = useTalentHubFlags();

  const handleOnResumeLinkClicked_CandidateProfile = useCallback(() => {
    if (featureFlags.enableCandidateResumeViewer) {
      const fileExtension = profile?.resume?.fileName?.split('.').pop();

      if (fileExtension && fileExtension === 'pdf') {
        candidateResumeModal.onOpen();
        return;
      }
      handleResumeLinkClicked(`.${fileExtension}`);
    } else {
      handleResumeLinkClicked();
    }
  }, [
    candidateResumeModal,
    featureFlags.enableCandidateResumeViewer,
    handleResumeLinkClicked,
    profile?.resume?.fileName,
  ]);

  if (loading) return <RoleBlankLoadingScreen roleLayoutProps={roleLayoutProps} />;

  if (
    // TODO: (!profile) This would be the ideal validation, but this object is not nullable yet. This alternative validation works, as there is always an associated applicantWorkflowId in this profile page.
    !profile.applicantWorkflowId
  )
    return (
      <RoleLayout {...roleLayoutProps}>
        <Center flexDirection="column" w="full" mt={12} px={4}>
          <Box w="full" maxW="350px">
            <Image src={plantSrc} alt="" mx="auto" />
          </Box>
          <Center flexDirection="column" maxW="lg" mt={6}>
            <Heading variant="heading-1" color="brand.main">
              Reviewer Permission Required
            </Heading>
            <Text mt={2}>
              You don&apos;t have permission to view this candidate&apos;s profile. <br />
            </Text>
            <Text mt={8}>
              <strong>Why?</strong> You are either not assigned as a reviewer for the role
              associated with this candidate or are not assigned to review this specific candidate.
            </Text>
            <Text mt={2}>
              <strong>Next Steps:</strong> Please contact your Terminal Customer Success Manager or
              the hiring manager for this role to be added to this candidate or role
            </Text>
            <Button
              mt={10}
              variant="solid"
              to={{
                pathname: `/`,
              }}
              as={RouterLink}
            >
              Back to Home
            </Button>
          </Center>
        </Center>
      </RoleLayout>
    );

  return (
    <RoleLayout {...roleLayoutProps}>
      <InviteToFeedbackModal
        options={interviewFeedbackUtils.users}
        isLoading={interviewFeedbackUtils.isLoading}
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
        modalTitle={`Invite Others to Leave Feedback for ${profile.fullName}`}
        modalDescription="Team members will be able to see this candidate's profile and leave interview
            feedback. Compensation information will be hidden."
        handleOnSubmitInviteFeedback={interviewFeedbackUtils.handleOnSubmitInviteFeedback}
        shouldHide_candidateCheckbox={false}
      />
      <SimpleGrid
        templateColumns={['1fr', '1fr', '60% 40%']}
        columnGap={[0, 0, 6]}
        bg="bg.primary"
        px={6}
        py={8}
      >
        <CandidateInformation
          showInterviewLink
          profile={profile}
          candidateHighlights={candidateHighlights}
          handleResumeLinkClicked={handleOnResumeLinkClicked_CandidateProfile}
        />
        <VStack pr={[0, 6]} spacing={6} alignItems="start" mt={[6, 0]}>
          {!isClientReviewer && (
            <>
              <ModalCandidateStatus
                stepTitle="Terminal Screen"
                info={stage.screening.info}
                status={stage.screening.status}
              />
              <ModalCandidateStatus
                stepTitle="Submission"
                info={stage.submission.info}
                status={stage.submission.status}
                statusList={
                  <StageProgressStatuses
                    progressStatuses={stage.submission.progressStatuses}
                    isFinal={false}
                  />
                }
              >
                {stage.submission.moreInfo && (
                  <>
                    <Divider mb={3} />
                    <ModalCandidateSubmissionReview
                      candidateName={stage.submission.moreInfo.text}
                      reviewerName={reviewerName}
                      onSubmit={handleCandidateSubmissionSubmit}
                      status={submissionViewStatus}
                    />
                  </>
                )}
              </ModalCandidateStatus>
            </>
          )}
          <ModalCandidateStatus
            stepTitle="Interview"
            info={stage.interview.info}
            status={stage.interview.status}
            showActionNeeded={
              stage.interview.status === CandidateStageStatus.InProgress && !hasInterviewFeedback
            }
            statusList={
              <StageProgressStatuses
                progressStatuses={stage.interview.progressStatuses}
                isFinal={false}
              />
            }
          >
            {stage.interview.status === CandidateStageStatus.InProgress && (
              <CandidateInterviewFeedback
                companyName={roleLayoutProps.pageLayoutProps.orgName}
                candidateName={profile.fullName}
                interviewStatus={interviewStatus}
                roleName={roleLayoutProps.roleTitle}
                hasInterviewFeedback={hasInterviewFeedback}
                loading={isLoadingInsertInterview}
                onSubmit={onInterviewFeedbackSubmit}
                onBackToFeedbackCLick={onBackToFeedbackCLick}
                isClientReviewer={isClientReviewer}
                onClickInviteOthers={feedbackModal.onOpen}
              />
            )}
          </ModalCandidateStatus>
          {!isClientReviewer && (
            <>
              <ModalCandidateStatus
                stepTitle="Offer"
                info={stage.offer.info}
                status={stage.offer.status}
                statusList={
                  <StageProgressStatuses
                    progressStatuses={stage.offer.progressStatuses}
                    isFinal={false}
                  />
                }
              />
              <ModalCandidateStatus
                stepTitle="Hired"
                info={stage.hired.info}
                status={stage.hired.status}
                statusList={
                  <StageProgressStatuses progressStatuses={stage.hired.progressStatuses} isFinal />
                }
              />
            </>
          )}
        </VStack>
        <CandidateResumeViewerModal
          profile={profile}
          handleResumeLinkClicked={handleResumeLinkClicked}
          modalUtils={candidateResumeModal}
        />
      </SimpleGrid>
    </RoleLayout>
  );
}
