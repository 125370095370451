import type { SystemStyleObject } from '@chakra-ui/react';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { cssVar, mode } from '@chakra-ui/theme-tools';

const $popperBg = cssVar('popper-bg');

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const baseStylePopper: SystemStyleObject = {
  zIndex: 'popover',
};

const baseStyleContent: SystemStyleFunction = (props) => {
  const bg = 'bg.tertiary';
  const shadowColor = mode('gray.200', 'whiteAlpha.300')(props);

  return {
    [$popperBg.variable]: `colors.${bg}`,
    bg: $popperBg.reference,
    [$arrowBg.variable]: $popperBg.reference,
    [$arrowShadowColor.variable]: `colors.${shadowColor}`,
    width: 'auto',
    border: 'none',
    borderColor: 'inherit',
    borderRadius: 'sm',
    boxShadow: 'dark',
    zIndex: 'inherit',
    p: 2,
    _focus: {
      outline: 0,
      boxShadow: 'outline',
    },
  };
};

const baseStyleHeader: SystemStyleObject = {
  p: 2,
  borderBottomWidth: '1px',
};

const baseStyleBody: SystemStyleObject = {
  p: 2,
};

const baseStyleFooter: SystemStyleObject = {
  p: 2,
  borderTopWidth: '1px',
};

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  borderRadius: 'md',
  top: 1,
  insetEnd: 2,
  padding: 2,
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
  closeButton: baseStyleCloseButton,
});

export const popoverTheme = {
  parts: parts.keys,
  baseStyle,
};
