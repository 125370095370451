import type { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  borderColor: 'ui.secondary',
  borderWidth: '1px',
};

const variantSolid: SystemStyleObject = {
  borderStyle: 'solid',
};

const variantDashed: SystemStyleObject = {
  borderStyle: 'dashed',
};

const variants = {
  solid: variantSolid,
  dashed: variantDashed,
};

const defaultProps = {
  variant: 'solid',
};

export const dividerTheme = {
  baseStyle,
  variants,
  defaultProps,
};
