import type { TextProps, SpaceProps } from '@terminal/design-system';
import { Box, Collapse, Flex, Button, DropdownIcon, Text } from '@terminal/design-system';
import { stripAllHTMLAttributes } from 'global/utils';
import type { ReactNode } from 'react';
import { useState, useEffect, useRef } from 'react';

export function CardWithViewMore({
  header,
  description,
  onViewMoreClick,
  shouldShowFullBody,
  isDesktop,
  descriptionVariant = 'caption',
  viewMoreText = 'View',
  descriptionProps,
  ...spaceProps
}: {
  header: ReactNode;
  description: string;
  onViewMoreClick: () => void;
  shouldShowFullBody: boolean;
  isDesktop: boolean;
  viewMoreText?: string;
  descriptionVariant?: TextProps['variant'];
  descriptionProps?: TextProps;
} & SpaceProps) {
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const [shouldShowViewMoreButton, setShouldShowViewMoreButton] = useState(false);

  useEffect(() => {
    // We count how many lines the description has. If has more than 3, we show the `View More` button
    const descriptionHTMLElement = descriptionRef.current;

    if (descriptionHTMLElement) {
      const style = window
        .getComputedStyle(descriptionHTMLElement as HTMLParagraphElement, null)
        .getPropertyValue('line-height');
      const lineHeight = parseFloat(style);
      const height = descriptionHTMLElement.clientHeight;
      const numberOfLines = Math.ceil(height / lineHeight);

      setShouldShowViewMoreButton(numberOfLines >= 3);
    }
  }, []);

  return (
    <Box w="full" {...spaceProps}>
      {header}
      <Box
        pos="relative"
        {...(!isDesktop && !shouldShowFullBody
          ? {
              _after: {
                content: '""',
                pos: 'absolute',
                bottom: '0',
                h: '4',
                w: 'full',
                bgGradient: 'linear(to-t, bg.primary, whiteAlpha.300)',
                pointerEvents: 'none',
              },
            }
          : {})}
      >
        {/* startingHeight={lineHeight * 4.5 + paddingTop} */}
        <Collapse in={isDesktop || shouldShowFullBody} startingHeight="5.75rem">
          <Text
            ref={descriptionRef}
            pos="relative"
            pt={2}
            variant={descriptionVariant}
            w="full"
            maxW="full"
            sx={{
              'ul, ol, p': {
                mt: 4,
                '&:first-child': {
                  mt: 0,
                },
              },
              'ul, ol': {
                pl: [6, 6, 4],
              },
            }}
            dangerouslySetInnerHTML={{
              __html: stripAllHTMLAttributes(description),
            }}
            {...descriptionProps}
          />
        </Collapse>
      </Box>
      {!isDesktop && shouldShowViewMoreButton && (
        <Flex justify="center">
          <Button
            mt={2}
            rightIcon={
              <DropdownIcon
                fontSize="2xl"
                transitionDuration="250ms"
                transform={`rotate(${shouldShowFullBody ? '180deg' : 0})`}
              />
            }
            variant="ghost"
            w="min"
            onClick={onViewMoreClick}
          >
            {viewMoreText} {shouldShowFullBody ? 'Less' : 'More'}
          </Button>
        </Flex>
      )}
    </Box>
  );
}
