import { useEffect } from 'react';
import Sentry from 'global/sentry';
import { useHistory, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { firebaseAuth } from 'global/firebaseApp';
import { EmailVerificationIcon, Link, Text } from '@terminal/design-system';
import { CustomerAuthDefaultPageTemplate } from 'talent-hub/app/components/CustomerAuthPages/CustomerAuthDefaultPageTemplate';
import { useCustomerAuth } from 'talent-hub/utils';
import { useQueryString } from 'global/utils';
import { ActivityIndicator } from 'global/components';
import {
  ManualWithSSOSignUp,
  useSignUp,
  googleSignIn,
  AuthMessage,
  AnonymousSignInController,
  useAuthContinueUrl,
} from 'global/auth';
import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Role_Choices_Enum,
  Company_Size_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';

import { UpsertClientProspect, DeleteUpsertClientProspectAndRole } from './data/graphql';
import type {
  UpsertClientProspectMutation,
  UpsertClientProspectMutationVariables,
  DeleteUpsertClientProspectAndRolesMutation,
  DeleteUpsertClientProspectAndRolesMutationVariables,
} from './data/graphql';

export function SignUpPrivateController({ continueURL }: { continueURL: string }) {
  const queryString = useQueryString();
  const predefinedEmailFromQuery = queryString.get('email') || undefined;
  const candidateIDFromQuery = queryString.get('candidate') || undefined;
  const roleType = queryString.get('role-type') || undefined;
  const teamSize = queryString.get('team-size') || undefined;
  const yoe = queryString.get('yoe') || undefined;
  const auth = useCustomerAuth();
  const history = useHistory();
  const { handleSignUpSubmit, isLoading, isResolved, shouldHideGoogleSSO } = useSignUp({
    continueURL,
    contextRole: 'authenticated',
  });
  const [upsertClientProspect] = useMutation<
    UpsertClientProspectMutation,
    UpsertClientProspectMutationVariables
  >(UpsertClientProspect, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });
  const [deleteUpsertClientProspectAndRole] = useMutation<
    DeleteUpsertClientProspectAndRolesMutation,
    DeleteUpsertClientProspectAndRolesMutationVariables
  >(DeleteUpsertClientProspectAndRole, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  const handleProspectCreation = () => {
    if (roleType && teamSize && yoe) {
      return deleteUpsertClientProspectAndRole({
        variables: {
          userId: auth.user?.id as number,
          companyName: 'Trial',
          companySize: teamSize as Company_Size_Choices_Enum,
          yoe: yoe as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
          roleType: roleType as Role_Choices_Enum,
          roleChoicesBlob: `[{ role: "${roleType}", yeo: "${yoe}" }]`,
        },
      });
    }

    return upsertClientProspect({
      variables: {
        userId: auth.user?.id as number,
        companyName: 'Trial',
      },
    });
  };

  useEffect(() => {
    if (!firebaseAuth.currentUser?.isAnonymous && !auth.isEmailVerified) {
      history.replace('/');
    }
    // Disabling to read the first time render value of the isAnonymous.
    // This is justified by the usage of a private controller that is going to be rendered after anonymous login.
    // In case of refreshing the page after a signup the user is not going to be anonymous and we can take them to the home page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (candidateIDFromQuery) {
      localStorage.setItem('redirect_to', `/explore-candidates/candidate/${candidateIDFromQuery}`);
    }
  }, [candidateIDFromQuery]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (auth.isAuthorized) {
      if (
        // To work around a race issue where user gets authorized before the
        // createAuth..putUser function is resolved. Since non-anonymous user
        // will always have first_name, we can wait until first_name is in auth user
        auth.user?.first_name
      ) {
        history.replace('/');
      } else {
        // Handling the edge where user main now have the first_name. we don't want the user
        // get stock on seeing the loading bar if for one reason or other they don't have first_name
        timer = setTimeout(() => {
          history.replace('/');
        }, 4000);
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // Disabling because of usage of history.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthorized, auth?.user?.first_name]);

  if (!firebaseAuth.currentUser?.isAnonymous && !isResolved) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {!isResolved && (
        <CustomerAuthDefaultPageTemplate
          bottomCTALink={{
            text: 'Already have an account?',
            linkText: 'Login',
            href: `/auth/login`,
          }}
          headerTitle="Claim Your Account"
          headerSubtitle="Claim your account and start browsing candidates today."
        >
          <ManualWithSSOSignUp
            onEmailSignSubmitted={(values) => {
              handleProspectCreation().then(() => {
                handleSignUpSubmit(values);
              });
            }}
            showLoadingForEmailSignUp={isLoading}
            shouldHideGoogleSSO={shouldHideGoogleSSO}
            onGoogleLoginClicked={() => {
              handleProspectCreation().then(() => {
                googleSignIn(
                  { auth: firebaseAuth, dispatch: auth.dispatch },
                  { hintEmail: predefinedEmailFromQuery },
                );
              });
            }}
            isEmailInputDisabled={!!predefinedEmailFromQuery}
            email={predefinedEmailFromQuery}
            googleSignUpInstruction={
              predefinedEmailFromQuery
                ? `Please make sure to use the same email: ${predefinedEmailFromQuery}`
                : ''
            }
            termsCopy={
              <>
                By claiming you agree to the{' '}
                <Link
                  fontSize="inherit"
                  href="https://terminal.io/terms-of-use"
                  isExternal
                  display="inline"
                >
                  Terms Of Use
                </Link>
              </>
            }
          />
        </CustomerAuthDefaultPageTemplate>
      )}
      {isResolved && (
        <CustomerAuthDefaultPageTemplate>
          <AuthMessage icon={<EmailVerificationIcon />} title="Please Verify Your Email">
            <Text>
              A verification email has been sent to your email account. Please check your inbox to
              verify. If you have any problems, please{' '}
              <Link href="https://help.terminal.io/" isExternal display="inline">
                contact us.
              </Link>
            </Text>
          </AuthMessage>
        </CustomerAuthDefaultPageTemplate>
      )}
    </>
  );
}

export function SignUpController() {
  const continueURL = useAuthContinueUrl();
  const auth = useCustomerAuth();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/signup`}>
        <AnonymousSignInController auth={auth}>
          <SignUpPrivateController continueURL={continueURL} />
        </AnonymousSignInController>
      </Route>
      <Route
        path={path}
        render={() => (
          <Redirect
            to={{
              pathname: `${path}/signup`,
            }}
          />
        )}
      />
    </Switch>
  );
}
