import { equals } from 'ramda';
import type {
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
  Candidate_Education_Degree_Choices_Enum,
  Role_Choices_Enum,
  Job_Level_Choices_Enum,
  Job_Status_Choices_Enum,
  Job_Tech_Stack_Insert_Input,
} from 'global/types/hasura-tables.generated.types';
import {
  Contract_Length_Choices_Enum,
  Employment_Type_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';
import {
  toFriendlyBoolean,
  toFriendlyDegree,
  toFriendlyRolesNames,
  toFriendlyYearsOfExperienceRange,
} from 'global/utils';
import { employmentTypeDisplayNames } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import type {
  SelectCreateJobOptionsQuery,
  InsertJobMutationVariables,
  FormData,
  SelectJobQuery,
  UpsertDeleteJobMutationVariables,
  InsertJobSubtablesValues,
} from './data';

export const contactLengthsDisplayNames = {
  [Contract_Length_Choices_Enum.ThreeMonths]: '3 months',
  [Contract_Length_Choices_Enum.SixMonths]: '6 months',
  [Contract_Length_Choices_Enum.NineMonths]: '9 months',
  [Contract_Length_Choices_Enum.TwelveMonths]: '12 months',
};

export function serializeCreateJobOptions(data?: SelectCreateJobOptionsQuery) {
  if (!data) {
    return {
      options: {
        headCounts: [],
        jobTypes: [],
        employmentTypes: [],
        contactLengths: [],
        acceptableLocations: [],
        jobLevelChoices: [],
        requiredSkills: [],
        niceToHaveSkills: [],
        techStack: [],
        yearsOfExperience: [],
        educationRequirement: [],
        organizationUsers: [],
      },
    };
  }

  const skills = data.skills.map(({ name, id }) => ({ label: `${name}`, value: `${id}` }));

  return {
    options: {
      /**
       * Numbers between 1 and 6
       */
      headCounts: [...Array.from(Array(6).keys())].map((i) => ({
        value: `${i + 1}`,
        label: `${i + 1}`,
      })),
      jobTypes: data.jobTypes
        .map(({ value }) => ({
          value,
          label: toFriendlyRolesNames(value as Role_Choices_Enum, ''),
        }))
        .filter(({ label }) => !!label),
      employmentTypes: [
        {
          value: Employment_Type_Choices_Enum.FullTime,
          label: employmentTypeDisplayNames.FULL_TIME,
        },
        {
          value: Employment_Type_Choices_Enum.Contract,
          label: employmentTypeDisplayNames.CONTRACT,
        },
      ],
      contactLengths: [
        {
          value: Contract_Length_Choices_Enum.ThreeMonths,
          label: contactLengthsDisplayNames.THREE_MONTHS,
        },
        {
          value: Contract_Length_Choices_Enum.SixMonths,
          label: contactLengthsDisplayNames.SIX_MONTHS,
        },
        {
          value: Contract_Length_Choices_Enum.NineMonths,
          label: contactLengthsDisplayNames.NINE_MONTHS,
        },
        {
          value: Contract_Length_Choices_Enum.TwelveMonths,
          label: contactLengthsDisplayNames.TWELVE_MONTHS,
        },
      ],
      // * Hide united states, since this query is a temporary implementation, while it is being improved.
      acceptableLocations: data.acceptableLocations.reduce((filtered, { id, value }) => {
        if (value === 'United States') return filtered;
        return [...filtered, { label: value, value: `${id}` }];
      }, [] as { label: string; value: string }[]),
      jobLevelChoices: data.job_level_choices.map(({ value }) => ({ value, label: value })),
      requiredSkills: skills,
      niceToHaveSkills: skills,
      techStack: skills,
      yearsOfExperience: data.yearsOfExperience.map(({ value }) => ({
        value,
        label: toFriendlyYearsOfExperienceRange(
          value as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
        ),
      })),
      educationRequirement: data.educationRequirement.map(({ value }) => ({
        value,
        label: toFriendlyDegree(value as Candidate_Education_Degree_Choices_Enum),
      })),
      organizationUsers: data.organizationUsers
        // Temporal filter to remove users with duplicate email
        .filter(
          (currentUser, index, list) =>
            list.findIndex((user) => user.email === currentUser.email) === index,
        )
        .map(({ name = '', email = '', id }) => ({
          label: `${name} - ${email}`,
          value: id,
        })),
    },
  };
}

export function serializeInsertJobVariables(
  formData: FormData,
  viewingOrg: number,
  status: Job_Status_Choices_Enum,
  hiringManagerId?: number,
): InsertJobMutationVariables {
  return {
    organization_id: viewingOrg,
    job_title: formData.jobTitle,
    job_type: formData.jobType === '' ? null : (formData.jobType as Role_Choices_Enum),
    employment_type: formData.employmentType,
    contract_length: formData.contractLength
      ? (formData.contractLength as Contract_Length_Choices_Enum)
      : null,
    job_positions: { data: Array.from(Array(Number(formData.numberPositions)).fill({})) },
    job_locations: { data: formData.locations.map((el) => ({ country_id: Number(el) })) },
    max_contract_rate:
      typeof formData.maxContractRate === 'undefined' ? null : formData.maxContractRate,
    min_contract_rate:
      typeof formData.minContractRate === 'undefined' ? null : formData.minContractRate,
    min_salary: typeof formData.minimumSalary === 'undefined' ? null : formData.minimumSalary,
    max_salary: typeof formData.maximumSalary === 'undefined' ? null : formData.maximumSalary,
    offering_bonus: formData.offeringBonus === 'YES',
    offering_equity: formData.offeringEquity === 'YES',
    level: formData.level === '' ? null : (formData.level as Job_Level_Choices_Enum),

    tech_stack: {
      data: formData.techStack.map(
        (el) => ({ skill_id: Number(el.value) } as Job_Tech_Stack_Insert_Input),
      ),
    },
    years_experience:
      formData.yearsExperience === ''
        ? null
        : (formData.yearsExperience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum),

    required_skills: {
      data: formData.requiredSkills.map(
        (el) => ({ skill_id: Number(el.value) } as Job_Tech_Stack_Insert_Input),
      ),
    },
    nice_to_have_skills: {
      data:
        formData.niceToHaveSkills?.map(
          (el) => ({ skill_id: Number(el.value) } as Job_Tech_Stack_Insert_Input),
        ) || [],
    },
    education_requirement:
      formData.educationRequired === ''
        ? null
        : (formData.educationRequired as Candidate_Education_Degree_Choices_Enum),
    about: formData.aboutRole === '' ? null : formData.aboutRole,
    what_youll_do: formData.whatYouDo === '' ? null : formData.whatYouDo,
    what_youll_bring: formData.whatYouBring === '' ? null : formData.whatYouBring,
    notes: formData.additionalNotes === '' ? null : formData.additionalNotes,
    hiring_manager_id: hiringManagerId,
    status,
  };
}

export function serializeSelectJob(data?: SelectJobQuery): FormData {
  const job = data?.job[0];
  if (!data || !job)
    return {
      jobTitle: '',
      numberPositions: '1',
      jobType: '',
      employmentType: Employment_Type_Choices_Enum.FullTime,
      contractLength: '',
      locations: [],
      useTerminalRange: false,
      maxContractRate: undefined,
      minContractRate: undefined,
      minimumSalary: undefined,
      maximumSalary: undefined,
      offeringBonus: '',
      offeringEquity: '',
      level: '',
      techStack: [],
      yearsExperience: '',
      requiredSkills: [],
      educationRequired: '',
      niceToHaveSkills: undefined,
      aboutRole: '',
      whatYouDo: '',
      whatYouBring: '',
      hiringManager: '',
      additionalNotes: '',
    };

  return {
    jobTitle: job.title,
    numberPositions: job.job_positions_aggregate?.aggregate?.count
      ? job.job_positions_aggregate.aggregate.count.toString()
      : '',
    jobType: job.role_type || '',
    employmentType: job.employment_type || Employment_Type_Choices_Enum.FullTime,
    contractLength: job.contract_length,
    locations: job.job_acceptable_locations.length
      ? job.job_acceptable_locations.map(({ location: { id } }) => id.toString())
      : [],
    // * Since we don't have a field to specify the `useTerminalRange` value, we use both values in 0, to infer it
    useTerminalRange:
      job.employment_type === Employment_Type_Choices_Enum.Contract
        ? job.min_contract_rate === 0 && job.max_contract_rate === 0
        : job.min_salary === 0 && job.max_salary === 0,
    maxContractRate: job.max_contract_rate,
    minContractRate: job.min_contract_rate,
    minimumSalary: job.min_salary,
    maximumSalary: job.max_salary,
    offeringBonus: toFriendlyBoolean(job.offering_bonus).toUpperCase(),
    offeringEquity: toFriendlyBoolean(job.offering_equity).toUpperCase(),
    level: job.level || '',
    techStack: job.job_tech_stack.map(({ skill: { name, id } }) => ({
      label: `${name}`,
      value: `${id}`,
    })),
    yearsExperience: job.min_years_of_experience || '',
    requiredSkills: job.job_required_skills.map(({ skill: { name, id } }) => ({
      label: `${name}`,
      value: `${id}`,
    })),
    educationRequired: job.education_requirement || '',
    niceToHaveSkills: job.job_nice_to_have_skills.map(({ skill: { name, id } }) => ({
      label: `${name}`,
      value: `${id}`,
    })),
    aboutRole: job.about || '',
    whatYouDo: job.what_youll_do || '',
    whatYouBring: job.what_youll_bring || '',
    hiringManager: job.hiring_manager?.email
      ? `${job.hiring_manager?.name || ''} - ${job.hiring_manager.email}`
      : '',
    additionalNotes: job.notes || '',
  };
}

function serializeUpsertDelete_jobSubtables<T, P>(
  formData: InsertJobSubtablesValues,
  jobID: number,
  isEqual: boolean,
): {
  insertData: T | [];
  skipField: P;
} {
  if (!isEqual) {
    return {
      insertData:
        (formData?.data.map((item) => ({
          ...item,
          job_id: jobID,
        })) as unknown as T) || [],
      skipField: false as unknown as P,
    };
  }
  return {
    insertData: [],
    skipField: true as unknown as P,
  };
}

export function serializeUpdateJob(
  formData: InsertJobMutationVariables,
  initialValues: FormData,
  organizationUsers: ReturnType<typeof serializeCreateJobOptions>['options']['organizationUsers'],
  jobID: number,
): UpsertDeleteJobMutationVariables {
  const organizationUserId = organizationUsers.find(
    (el) => el.label === initialValues.hiringManager,
  );

  const formattedInitialValues = serializeInsertJobVariables(
    initialValues,
    formData.organization_id,
    formData.status,
    organizationUserId?.value,
  );

  const { insertData: job_locations, skipField: skip_job_location } =
    serializeUpsertDelete_jobSubtables<
      UpsertDeleteJobMutationVariables['job_locations'],
      UpsertDeleteJobMutationVariables['skip_job_location']
    >(
      formData.job_locations,
      jobID,
      equals(formData.job_locations, formattedInitialValues.job_locations),
    );

  const { insertData: job_positions, skipField: skip_job_position } =
    serializeUpsertDelete_jobSubtables<
      UpsertDeleteJobMutationVariables['job_positions'],
      UpsertDeleteJobMutationVariables['skip_job_position']
    >(
      formData.job_positions,
      jobID,
      equals(formData.job_positions, formattedInitialValues.job_positions),
    );

  const { insertData: tech_stack, skipField: skip_job_tech_stack } =
    serializeUpsertDelete_jobSubtables<
      UpsertDeleteJobMutationVariables['tech_stack'],
      UpsertDeleteJobMutationVariables['skip_job_tech_stack']
    >(formData.tech_stack, jobID, equals(formData.tech_stack, formattedInitialValues.tech_stack));

  const { insertData: required_skills, skipField: skip_job_required_skill } =
    serializeUpsertDelete_jobSubtables<
      UpsertDeleteJobMutationVariables['required_skills'],
      UpsertDeleteJobMutationVariables['skip_job_required_skill']
    >(
      formData.required_skills,
      jobID,
      equals(formData.required_skills, formattedInitialValues.required_skills),
    );

  const { insertData: nice_to_have_skills, skipField: skip_job_nice_to_have_skill } =
    serializeUpsertDelete_jobSubtables<
      UpsertDeleteJobMutationVariables['nice_to_have_skills'],
      UpsertDeleteJobMutationVariables['skip_job_nice_to_have_skill']
    >(
      formData.nice_to_have_skills,
      jobID,
      equals(formData.nice_to_have_skills, formattedInitialValues.nice_to_have_skills),
    );

  return {
    ...formData,
    job_id: jobID,
    job_locations,
    skip_job_location,
    tech_stack,
    skip_job_tech_stack,
    job_positions,
    skip_job_position,
    required_skills,
    skip_job_required_skill,
    nice_to_have_skills,
    skip_job_nice_to_have_skill,
  };
}
