import { avatarAnatomy as parts } from '@chakra-ui/anatomy';
import { isDark, mode, randomColor } from '@chakra-ui/theme-tools';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';

import { sizes as themeSizes } from '../../theme/design-tokens';

const baseStyleBadge: SystemStyleFunction = (props) => {
  return {
    transform: 'translate(25%, 25%)',
    borderRadius: 'full',
    border: '0.2em solid',
    borderColor: mode('white', 'gray.800')(props),
  };
};

const baseStyleExcessLabel: SystemStyleFunction = (props) => {
  return {
    bg: mode('gray.200', 'whiteAlpha.400')(props),
  };
};

const baseStyleContainer: SystemStyleFunction = (props) => {
  const { name, theme } = props;
  const bg = name ? randomColor({ string: name }) : 'gray.400';
  const isBgDark = isDark(bg)(theme);

  let color = 'white';
  if (!isBgDark) color = 'gray.800';

  const borderColor = mode('white', 'gray.800')(props);

  return {
    bg,
    color,
    borderColor,
    verticalAlign: 'top',
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  badge: baseStyleBadge(props),
  excessLabel: baseStyleExcessLabel(props),
  container: baseStyleContainer(props),
});

function getSize(size: keyof typeof themeSizes | '100%'): PartsStyleObject<typeof parts> {
  const themeSize = size !== '100%' ? themeSizes[size] : undefined;
  return {
    container: {
      width: size,
      height: size,
      fontSize: `calc(${themeSize ?? size} / 2.5)`,
    },
    excessLabel: {
      width: size,
      height: size,
    },
    label: {
      fontSize: `calc(${themeSize ?? size} / 2.5)`,
      lineHeight: size !== '100%' ? themeSize ?? size : undefined,
    },
  };
}

const sizes = {
  sm: getSize(6),
  md: getSize(8),
  lg: getSize(10),
  xl: getSize(12),
  '2xl': getSize(16),
};

const defaultProps = {
  size: 'md',
};

export const avatarTheme = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
};
