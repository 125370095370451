import { MenuItem as ChakraMenuItem, forwardRef } from '@chakra-ui/react';
import type { MenuItemProps as ChakraMenuItemProps } from '@chakra-ui/react';

export interface MenuItemProps extends ChakraMenuItemProps {
  /**
   * Menu item active state
   */
  isActive?: boolean;
}

export const MenuItem = forwardRef<MenuItemProps, 'div'>(({ isActive, children, ...rest }, ref) => {
  return (
    <ChakraMenuItem
      {...rest}
      ref={ref}
      bg={isActive ? 'accent.main' : 'none'}
      color={isActive ? 'text.inverse' : 'text.primary'}
    >
      {children}
    </ChakraMenuItem>
  );
});

MenuItem.defaultProps = {
  isActive: false,
};
