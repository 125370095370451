import { Flex } from '@terminal/design-system';

export function AuthContentWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDir="column"
      flex={1}
      alignItems="center"
      justifyContent={['flex-start', 'flex-start', 'center']}
    >
      {children}
    </Flex>
  );
}
