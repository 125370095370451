import { textTheme } from '../../Text/Text.theme';

export const formErrorTheme = {
  baseStyle: {
    text: {
      ...textTheme.variants.caption,
      color: 'text.error',
    },
  },
};
