import { inputTheme } from '../Input/Input.theme';

export const selectTheme = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      ...inputTheme.baseStyle.field,
      appearance: 'none',
      paddingBottom: '1px',
      lineHeight: 'normal',
      '& option, & optgroup': {
        appearance: 'none',
        ...inputTheme.baseStyle.field,
      },
    },
    icon: {
      width: '1.5rem',
      height: '100%',
      insetEnd: '0.5rem',
      position: 'relative',
      color: 'currentColor',
      fontSize: '1.25rem',
      _disabled: {
        opacity: 0.5,
      },
    },
  },
  sizes: inputTheme.sizes,
  variants: inputTheme.variants,
  defaultProps: inputTheme.defaultProps,
};
