import { textTheme } from '../Text';

type Dict = Record<string, any>;

const colorSchemaMaps = {
  black: {
    bgSolid: 'black',
    bgSubtle: 'black',
    color: 'text.primary',
  },
  primary: {
    bgSolid: 'brand.main',
    bgSubtle: 'brand.lighter',
    color: 'text.inverse',
  },
  'accent-lighter': {
    bgSolid: 'accent.darker',
    bgSubtle: 'accent.lighter',
    color: 'text.inverse',
  },
  'accent-lightest': {
    bgSolid: 'accent.main',
    bgSubtle: 'accent.lightest',
    color: 'text.inverse',
  },
  success: {
    bgSolid: 'ui.success',
    bgSubtle: 'ui.lighter.success',
    color: 'text.inverse',
  },
  warning: {
    bgSolid: 'ui.warning',
    bgSubtle: 'ui.lighter.warning',
    color: 'text.inverse',
  },
  info: {
    bgSolid: 'ui.info',
    bgSubtle: 'ui.lighter.info',
    color: 'text.inverse',
  },
  error: {
    bgSolid: 'ui.error',
    bgSubtle: 'ui.lighter.error',
    color: 'text.inverse',
  },
  disabled: {
    bgSolid: 'ui.darker.disabled',
    bgSubtle: 'ui.disabled',
    color: 'text.inverse',
  },
  'disabled-dark': {
    // We don't support Solid variant for disabled-dark, please use disabled solid variant instead
    bgSubtle: 'ui.darker.disabled',
  },
};

export const tagTheme = {
  parts: ['container', 'label', 'closeButton'],
  variants: {
    solid: (props: Dict) => {
      const colorMap =
        // @ts-ignore
        colorSchemaMaps[props.colorScheme as string] || colorSchemaMaps.black;

      return {
        container: { bg: colorMap.bgSolid, color: colorMap.color, borderRadius: '100px' },
      };
    },
    subtle: (props: Dict) => {
      const bg =
        // @ts-ignore
        colorSchemaMaps[props.colorScheme as string].bgSubtle || colorSchemaMaps.disabled.bgSubtle;

      return {
        container: { bg, color: 'text.primary', borderRadius: '100px' },
      };
    },
    toggle: {
      container: {
        borderRadius: 'base',
        border: 'solid 1px',
        color: 'text.primary',
        borderColor: 'ui.secondary',
        bg: 'transparent',

        _checked: {
          bg: 'bg.tertiary',
          border: 'solid 1px',
          borderColor: 'ui.inverse.secondary',
          boxShadow: 'none',

          _hover: {
            bg: 'bg.tertiary',
          },

          _focus: {
            borderColor: 'ui.primary',
          },
        },

        _hover: {
          bg: 'ui.inverse.secondary',
          borderColor: 'ui.secondary',
          cursor: 'pointer',
        },

        _focus: {
          borderColor: 'ui.primary',
        },
      },
    },
    filter: (props: Dict) => {
      const bg =
        // @ts-ignore
        colorSchemaMaps[props.colorScheme as string].bgSubtle || colorSchemaMaps.black.bgSubtle;

      return {
        container: { bg, color: 'text.primary', borderRadius: '4px' },
      };
    },
  },
  baseStyle: {
    container: {
      ...textTheme.variants.body,
      height: 'fit-content',
      outline: 0,
      _focus: {
        boxShadow: 'outline',
      },
      _disabled: {
        bgColor: 'ui.disabled',
        _hover: {
          cursor: 'not-allowed',
        },
      },
      whiteSpace: 'nowrap',
    },
    label: {
      lineHeight: 1.2,
    },
  },
  sizes: {
    sm: {
      container: {
        ...textTheme.variants.caption,
        minH: 1,
        minW: 5,
        py: 1,
        px: 3,
        borderRadius: 'base',
      },
      label: {
        lineHeight: 1.2,
      },
    },
    lg: {
      container: {
        ...textTheme.variants.body,
        minH: 1,
        minW: 6,
        borderRadius: 'base',
        px: 3,
        py: 1,
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
    // We decided to add this to enforce adding a colorScheme when using Tag component
    colorScheme: 'black',
  },
};
