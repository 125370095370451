import {
  Heading,
  Menu,
  MenuItem,
  Box,
  PreferredIcon,
  CheckboxButton,
  Text,
} from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';
import { useTalentHubFlags } from 'talent-hub/utils';
import { AppSubheader, PageLayout, PageLayoutPaddedBody } from 'talent-hub/components';

export function ChangeOrganization({
  organizations = [],
  loading,
  pageLayoutProps,
  onChangeCurrentOrganization,
  onClick_toggleFavoriteOrganization,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  organizations: any[];
  loading: boolean;
  onChangeCurrentOrganization: (id: number) => void;
  onClick_toggleFavoriteOrganization: (id: number) => void;
}) {
  const featureFlag = useTalentHubFlags();
  if (loading)
    return (
      <PageLayout headerTitle="Change Organization" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <AppSubheader title="Select Your New Organization" />
          <BlankScreenLoading />
        </PageLayoutPaddedBody>
      </PageLayout>
    );
  if (!organizations.length)
    return (
      <PageLayout headerTitle="Change Organization" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <Heading variant="heading-2" as="h2">
            No organizations to display.
          </Heading>
        </PageLayoutPaddedBody>
      </PageLayout>
    );

  return (
    <PageLayout headerTitle="Change Organization" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        {featureFlag.demo && (
          <Box
            textAlign="center"
            border="1px solid"
            borderColor="ui.primary"
            color="ui.primary"
            mb={4}
          >
            Feature Flag DEMO ON!! 🚀
          </Box>
        )}
        <AppSubheader title="Select Your New Organization" />
        <Menu autoSelect={false}>
          <Box maxWidth="2xl" marginX="auto">
            {organizations.map(({ id, name, isFavorite }) => (
              <Box
                bg="bg.primary"
                border="1px solid"
                borderColor="ui.secondary"
                key={id}
                mt="-1px"
                width="full"
                display="flex"
              >
                <CheckboxButton
                  isChecked={isFavorite}
                  onClick={async (e: any) => {
                    e.preventDefault();
                    onClick_toggleFavoriteOrganization(id);
                  }}
                  minW="auto"
                  w="auto"
                  h="fit-content"
                  lineHeight={0}
                  mt={0}
                  borderY="0"
                  borderLeft="0"
                  borderColor="ui.secondary"
                  p={4}
                  checkedIcon={
                    <PreferredIcon
                      sx={{
                        '& path': {
                          fill: '#EFD58B',
                          stroke: 'text.primary',
                        },
                      }}
                    />
                  }
                  unCheckedIcon={<PreferredIcon />}
                />
                <MenuItem
                  width="auto"
                  flexGrow={1}
                  w={0}
                  py={3}
                  onClick={() => onChangeCurrentOrganization(id)}
                >
                  <Text isTruncated>{name}</Text>
                </MenuItem>
              </Box>
            ))}
          </Box>
        </Menu>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
