import {
  VStack,
  Box,
  Heading,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Button,
  Text,
  Link,
  ArrowRightIcon,
  Badge,
  HStack,
  SkeletonText,
  Flex,
} from '@terminal/design-system';

import { PageLayout, PageLayoutPaddedBody, TableContentLoading } from 'talent-hub/components';

import { Link as RouterLink } from 'react-router-dom';
import headerUserBg from './assets/header-user-bg.svg?url';
import { RoleSummary, CandidateSummary, EmptyTable, NoOpenRoles } from './components/Icons';
import newChallenges from './assets/new_challenges.svg?url';
import remoteImg from './assets/remote.svg?url';
import standOutCrowd from './assets/stand_out_crowd.svg?url';
import type { CandidateToReviewInfoWithStatusInfo } from './Dashboard.serializer';
import { DashboardCard } from './components/DashboardCard';

function CandidatesToReviewTable({
  loading,
  candidates,
  openRoles,
  viewMoreCandidatesTitle,
}: {
  loading: boolean;
  candidates: CandidateToReviewInfoWithStatusInfo[];
  openRoles: boolean;
  viewMoreCandidatesTitle: string | boolean;
}) {
  if (loading) {
    return (
      <TableContainer whiteSpace="break-spaces">
        <Table>
          <Thead>
            <Tr>
              <Th>Candidate Name</Th>
              <Th>Role</Th>
              <Th>Current Status</Th>
              <Th>
                <Box w={28} />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <TableContentLoading columns={4} rows={4} />
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
  if (!openRoles) {
    return (
      <Flex py={32} flexDirection="column" alignItems="center">
        <NoOpenRoles h={16} w={16} />
        <Heading variant="heading-2" mt={6}>
          No Open Roles
        </Heading>
        <Text width="50%" textAlign="center" mt={1}>
          Candidates needing your review for open roles will appear here. Open a new role today to
          get started.
        </Text>

        <Button variant="solid" mt={6} colorScheme="primary" to="/role/create" as={RouterLink}>
          Open a new role
        </Button>
      </Flex>
    );
  }

  if (!candidates.length) {
    return (
      <VStack py={32}>
        <EmptyTable h={16} w={16} />
        <Heading variant="heading-2" pt={6}>
          You&apos;re All Caught Up
        </Heading>
        <Text width="50%" textAlign="center" mt={1}>
          You have no candidates needing your review right now. Enjoy the extra free time!
        </Text>
      </VStack>
    );
  }

  return (
    <TableContainer whiteSpace="break-spaces">
      <Table>
        <Thead>
          <Tr>
            <Th>Candidate Name</Th>
            <Th>Role</Th>
            <Th>Current Status</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {candidates.map(({ id, name, role, statusText, jobID, status, linkText }) => (
            <Tr key={`${id}-${name}`}>
              <Td>
                <Link
                  variant="label"
                  to={{
                    pathname: `/role/${jobID}/candidate/${id}`,
                    state: { from: 'dashboard' },
                  }}
                  as={RouterLink}
                >
                  {name}
                </Link>
              </Td>
              <Td>{role}</Td>
              <Td>
                <Badge fontSize="sm" colorScheme={status}>
                  {statusText}
                </Badge>
              </Td>
              <Td>
                <Button
                  variant="ghost"
                  rightIcon={<ArrowRightIcon />}
                  colorScheme="primary"
                  mt={2}
                  to={{
                    pathname: `/role/${jobID}/candidate/${id}`,
                    state: { from: 'dashboard' },
                  }}
                  as={RouterLink}
                >
                  {linkText}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {viewMoreCandidatesTitle && (
        <Flex justifyContent="center" w="full">
          <Button variant="ghost" colorScheme="primary" to="/candidates" as={RouterLink}>
            {viewMoreCandidatesTitle}
          </Button>
        </Flex>
      )}
    </TableContainer>
  );
}

export function Dashboard({
  positionsInfo,
  candidatesInfo,
  loading,
  pageLayoutProps,
  userDisplayName,
  candidatesToReview,
  viewMoreCandidatesTitle,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  loading: boolean;
  positionsInfo: { openRoles: number | string; openPositions: number | string };
  candidatesInfo: { activeCandidates: number | string; submittedCandidates: number | string };
  userDisplayName: string;
  candidatesToReview: CandidateToReviewInfoWithStatusInfo[];
  viewMoreCandidatesTitle: string | boolean;
}) {
  return (
    <PageLayout headerTitle="Dashboard" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <Flex backgroundImage={headerUserBg} px={9} py={10} alignItems="center">
          <Heading variant="heading-1" color="text.inverse" my={4}>
            Welcome,
          </Heading>
          <Heading variant="heading-1" color="accent.lighter">
            &nbsp;{userDisplayName}
          </Heading>
          <Heading variant="heading-1" color="text.inverse">
            !
          </Heading>
        </Flex>
        <VStack spacing={6}>
          <Grid
            mt={6}
            gap={[4, 4, 6]}
            templateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr 1fr']}
            w="full"
          >
            <DashboardCard
              title="Find the next outstanding member of your team"
              imageSrc={standOutCrowd}
              link={{
                text: 'Explore Candidates',
                href: `/explore-candidates`,
              }}
              bgColor="bg.tertiary"
              isSmall
            />
            <Box border="1px solid" borderColor="ui.secondary" px={4} py={6} h="auto">
              <HStack backgroundColor="bg.secondary" p={4} spacing={5}>
                <RoleSummary h={16} w={16} />
                <Box>
                  <SkeletonText isLoaded={!loading} noOfLines={2}>
                    <Heading variant="heading-3">
                      {positionsInfo.openPositions} open positions
                    </Heading>
                    <Text>across {positionsInfo.openRoles} open roles</Text>
                  </SkeletonText>
                </Box>
              </HStack>
              <Button
                variant="ghost"
                rightIcon={<ArrowRightIcon />}
                colorScheme="primary"
                mt={2}
                to="/role"
                as={RouterLink}
              >
                View all Active Roles
              </Button>
            </Box>
            <Box border="1px solid" borderColor="ui.secondary" px={4} py={6} h="auto">
              <HStack backgroundColor="bg.secondary" p={4} spacing={5}>
                <CandidateSummary h={16} w={16} />
                <Box>
                  <SkeletonText isLoaded={!loading} noOfLines={2}>
                    <Heading variant="heading-3">
                      {candidatesInfo.activeCandidates} active candidates
                    </Heading>
                    <Text>
                      out of {candidatesInfo.submittedCandidates} total candidates submitted
                    </Text>
                  </SkeletonText>
                </Box>
              </HStack>
              <Button
                variant="ghost"
                rightIcon={<ArrowRightIcon />}
                colorScheme="primary"
                mt={2}
                to="/candidates"
                as={RouterLink}
              >
                View all Active Candidates
              </Button>
            </Box>
          </Grid>

          <Box border="1px solid" borderColor="ui.secondary" p={6} w="full">
            <Heading variant="heading-3" mb={5}>
              Candidates To Review
            </Heading>
            <CandidatesToReviewTable
              candidates={candidatesToReview}
              loading={loading}
              openRoles={
                typeof positionsInfo.openRoles === 'string' ? false : positionsInfo.openRoles !== 0
              }
              viewMoreCandidatesTitle={viewMoreCandidatesTitle}
            />
          </Box>
          <Grid gap={[4, 4, 6]} templateColumns={['1fr', '1fr', '1fr 1fr']} w="full">
            <DashboardCard
              title="Remote Leadership is no small task"
              imageSrc={newChallenges}
              paragraph="Resources, information and learning opportunities for you and your team."
              link={{
                text: 'Resource Center',
                href: 'https://terminal.io/resources',
                openInNewTab: true,
              }}
            />
            <DashboardCard
              title="Expert guidance and end-to-end support"
              imageSrc={remoteImg}
              paragraph="Resources to help you navigate the complexities of multi-market global hiring"
              link={{
                text: 'Terminal Customer Hub',
                href: 'https://www.notion.so/Terminal-Customer-Hub-be5794a160594eac8bc8043fd94f8365',
                openInNewTab: true,
              }}
            />
          </Grid>
        </VStack>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
