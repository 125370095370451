import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'global/utils';
import { useToast, useDisclosure } from '@terminal/design-system';
import {
  useCustomerAuthorizedUserSession,
  useSavedCandidates,
  useSharedCandidates,
} from 'talent-hub/utils';
import { serializeSavedCandidates } from './SavedCandidates.serializer';
import { SelectSavedCandidates } from './data';
import type { SelectSavedCandidatesQuery, SelectSavedCandidatesQueryVariables } from './data';
import type {
  SelectSharedCandidatesQuery,
  SelectSharedCandidatesQueryVariables,
} from '../shared-candidates/data';
import { SelectSharedCandidates } from '../shared-candidates/data';

const candidateSearchDateRange = moment().subtract(45, 'days').utc().format('YYYY-MM-DD');

export function useSavedCandidatesController() {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const { user, isClientProspect, viewingOrganization } = useCustomerAuthorizedUserSession();
  const history = useHistory();
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const { loading, data, refetch } = useQuery<
    SelectSavedCandidatesQuery,
    SelectSavedCandidatesQueryVariables
  >(SelectSavedCandidates, {
    variables: {
      user_id: user?.id || 0,
      updatedAfter: candidateSearchDateRange,
      organization: viewingOrganization.ID,
      isClientProspect,
    },
  });

  const { refetch: refetchSharedCandidates } = useQuery<
    SelectSharedCandidatesQuery,
    SelectSharedCandidatesQueryVariables
  >(SelectSharedCandidates, {
    variables: {
      user_id: user?.id || 0,
      updatedAfter: candidateSearchDateRange,
      organization: viewingOrganization.ID,
      isClientProspect,
    },
  });

  const { candidates } = serializeSavedCandidates(data);

  // Start shared_candidate logic
  const {
    isOpen: isShareWithModalOpen,
    onClose: onCloseShareWithModal,
    onOpen: onOpenShareWithModal,
  } = useDisclosure();
  const { teamMembers, handleOnInsertSharedCandidate } = useSharedCandidates({});

  const onInsertSharedCandidate = (candidateId: number, teamMembersIds: string[]) => {
    handleOnInsertSharedCandidate(candidateId, teamMembersIds, () => {
      toast({
        title: 'Shared Successfully!',
        description: 'The candidate was shared successfully.',
        status: 'success',
      });
      onCloseShareWithModal();
    });
  };
  // end shared_candidate logic

  const {
    insertSavedCandidate,
    deleteSavedCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    refetchSelectFavoriteCandidatesCounts,
  } = useSavedCandidates({
    insertSavedCandidate_onCompletedHandler: () => {
      if (candidateID) {
        refetch();
      }
      refetchSelectFavoriteCandidatesCounts();
      refetchSharedCandidates();
    },
    deleteSavedCandidate_onCompletedHandler: () => {
      if (candidateID) {
        refetch();
      }
      refetchSelectFavoriteCandidatesCounts();
      refetchSharedCandidates();
    },
  });

  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  return {
    loading,
    candidates,
    handleOnSaveCandidate,
    handleOnRemoveSaveCandidate,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    teamMembers,
    onInsertSharedCandidate,
    isShareWithModalOpen,
    onOpenShareWithModal,
    onCloseShareWithModal,
    onCloseCandidateProfileClick: () => history.push(`/explore-candidates/saved-candidates`),
  };
}
