import { createIcon } from '@terminal/design-system';

export const RoleSummary = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <circle cx="32" cy="32" r="32" fill="#A0CFDA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0698 37.8725L13.9847 41.5324C13.6242 41.7191 13.3979 42.0912 13.3979 42.4972C13.3979 42.9032 13.6242 43.2753 13.9847 43.462L31.9912 52.7765C32.7746 53.1811 33.7054 53.1811 34.4887 52.7765L52.4952 43.4726C52.8557 43.2859 53.0821 42.9137 53.0821 42.5078C53.0821 42.1018 52.8557 41.7297 52.4952 41.543L45.4101 37.8725"
        fill="#8FA25B"
      />
      <path
        d="M21.0698 37.8725L13.9847 41.5324C13.6242 41.7191 13.3979 42.0912 13.3979 42.4972C13.3979 42.9032 13.6242 43.2753 13.9847 43.462L31.9912 52.7765C32.7746 53.1811 33.7054 53.1811 34.4887 52.7765L52.4952 43.4726C52.8557 43.2859 53.0821 42.9137 53.0821 42.5078C53.0821 42.1018 52.8557 41.7297 52.4952 41.543L45.4101 37.8725"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#8FA25B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0698 28.6124L13.9847 32.2775C13.6242 32.4642 13.3979 32.8363 13.3979 33.2423C13.3979 33.6483 13.6242 34.0204 13.9847 34.2071L31.9912 43.5217C32.7746 43.9262 33.7054 43.9262 34.4887 43.5217L52.4952 34.2071C52.8557 34.0204 53.0821 33.6483 53.0821 33.2423C53.0821 32.8363 52.8557 32.4642 52.4952 32.2775L45.4101 28.6124"
        fill="#EFD58B"
      />
      <path
        d="M21.0698 28.6124L13.9847 32.2775C13.6242 32.4642 13.3979 32.8363 13.3979 33.2423C13.3979 33.6483 13.6242 34.0204 13.9847 34.2071L31.9912 43.5217C32.7746 43.9262 33.7054 43.9262 34.4887 43.5217L52.4952 34.2071C52.8557 34.0204 53.0821 33.6483 53.0821 33.2423C53.0821 32.8363 52.8557 32.4642 52.4952 32.2775L45.4101 28.6124"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#EFD58B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.4952 23.0126L34.4887 13.7034C33.7054 13.2988 32.7746 13.2988 31.9912 13.7034L13.9847 23.0126C13.6242 23.1993 13.3979 23.5715 13.3979 23.9774C13.3979 24.3834 13.6242 24.7555 13.9847 24.9422L31.9912 34.2568C32.7746 34.6613 33.7054 34.6613 34.4887 34.2568L52.4952 24.9528C52.8557 24.7661 53.0821 24.394 53.0821 23.988C53.0821 23.582 52.8557 23.2099 52.4952 23.0232V23.0126Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const CandidateSummary = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <circle cx="32" cy="32" r="32" fill="#ECB23F" />
      <path
        d="M28.282 45.1136V45.112V34.3404C28.282 33.8468 28.4781 33.3736 28.827 33.0246C29.176 32.6756 29.6493 32.4796 30.1428 32.4796C30.6363 32.4796 31.1096 32.6756 31.4586 33.0246C31.8075 33.3736 32.0036 33.8468 32.0036 34.3404V41.3834C32.0036 41.659 32.1131 41.9234 32.308 42.1183C32.5029 42.3132 32.7672 42.4227 33.0429 42.4227H35.3961C36.6939 42.4227 37.9385 42.9382 38.8562 43.8559C39.7739 44.7736 40.2895 46.0183 40.2895 47.3161V50.9122C40.2895 50.9662 40.268 51.0179 40.2298 51.0561C40.1917 51.0943 40.1399 51.1157 40.0859 51.1157H26.8415C26.8153 51.1141 26.7897 51.1069 26.7664 51.0946C26.7413 51.0814 26.7195 51.0626 26.7027 51.0397L26.6914 51.0243L26.6791 51.0095L22.8179 46.3694L22.7788 46.3224L22.7313 46.2839C22.5624 46.1468 22.4266 45.9735 22.334 45.7767C22.2413 45.5799 22.1943 45.3648 22.1963 45.1473C22.1983 44.9298 22.2493 44.7156 22.3456 44.5206L21.7851 44.244L22.3456 44.5206C22.4418 44.3255 22.5808 44.1547 22.7522 44.0208C22.9236 43.8869 23.123 43.7934 23.3355 43.7472C23.548 43.701 23.7682 43.7033 23.9797 43.754C24.1912 43.8047 24.3886 43.9024 24.5571 44.0399C24.7256 44.1774 24.861 44.3511 24.9531 44.5481L25.0124 44.675L25.1202 44.7645L26.5785 45.9742L26.6014 45.9932L26.626 46.0099C26.7957 46.1253 26.9961 46.1871 27.2013 46.1871C27.3941 46.1871 27.5826 46.1326 27.7454 46.0303C27.9023 45.9417 28.0342 45.8142 28.1281 45.6599C28.2283 45.4953 28.2815 45.3063 28.282 45.1136Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M22.6856 19.629H21.0284C20.8645 19.629 20.7074 19.5639 20.5915 19.448C20.4756 19.3321 20.4105 19.175 20.4105 19.0111C20.4105 18.8472 20.4756 18.6901 20.5915 18.5742C20.7074 18.4583 20.8645 18.3932 21.0284 18.3932H22.6856C22.8495 18.3932 23.0066 18.4583 23.1225 18.5742C23.2384 18.6901 23.3035 18.8472 23.3035 19.0111C23.3035 19.175 23.2384 19.3321 23.1225 19.448C23.0066 19.5639 22.8495 19.629 22.6856 19.629Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M24.4497 14.7572L24.4372 14.7568L24.4248 14.7568C24.3076 14.757 24.193 14.7229 24.095 14.6589L22.8181 13.6766L22.8076 13.6686L22.7967 13.6609C22.6706 13.5722 22.5834 13.4383 22.5532 13.2871C22.5242 13.1423 22.5496 12.9921 22.624 12.8651C22.7149 12.7474 22.8455 12.6663 22.9918 12.637C23.1418 12.607 23.2975 12.6337 23.4289 12.7119L24.8233 13.6306C24.9329 13.7059 25.0153 13.8145 25.0582 13.9404C25.1015 14.0675 25.1023 14.2051 25.0604 14.3326C25.0185 14.4601 24.9363 14.5705 24.8261 14.6471C24.7159 14.7237 24.5838 14.7624 24.4497 14.7572Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M39.1754 14.7569L39.1754 14.7568L39.1668 14.7569C39.06 14.7583 38.9547 14.7311 38.8619 14.6783C38.7691 14.6254 38.692 14.5488 38.6386 14.4563L38.6344 14.4491L38.6301 14.442C38.5486 14.3092 38.5203 14.1505 38.5509 13.9977C38.5798 13.8532 38.6593 13.724 38.7748 13.6332L40.2189 12.7895L40.219 12.7896L40.2304 12.7826C40.3632 12.7011 40.5219 12.6729 40.6746 12.7034C40.8209 12.7327 40.9515 12.8138 41.0424 12.9315C41.1169 13.0585 41.1422 13.2087 41.1133 13.3535C41.0833 13.5035 40.9972 13.6365 40.8726 13.7252L39.518 14.6503L39.518 14.6503L39.5167 14.6513C39.4162 14.7202 39.2972 14.7571 39.1754 14.7569Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M43.0037 18.5789C43.1197 18.6949 43.1862 18.8513 43.1894 19.0152C43.1884 19.1776 43.1234 19.3331 43.0085 19.448C42.8926 19.5639 42.7354 19.629 42.5716 19.629H40.9144C40.7505 19.629 40.5934 19.5639 40.4775 19.448C40.3616 19.3321 40.2965 19.175 40.2965 19.0111C40.2965 18.8472 40.3616 18.6901 40.4775 18.5742C40.5934 18.4583 40.7505 18.3932 40.9144 18.3932H42.5668C42.7309 18.3962 42.8876 18.4628 43.0037 18.5789Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M31.8 20.0596L31.7973 20.0596C31.3613 20.0615 30.9292 19.977 30.526 19.8109C30.1228 19.6449 29.7564 19.4007 29.4481 19.0924C29.1398 18.784 28.8956 18.4177 28.7296 18.0145C28.5635 17.6113 28.479 17.1792 28.4809 16.7432H28.4809V16.7405C28.4809 16.0841 28.6755 15.4424 29.0402 14.8966C29.4049 14.3507 29.9233 13.9253 30.5298 13.6741C31.1363 13.4229 31.8036 13.3572 32.4475 13.4852C33.0913 13.6133 33.6827 13.9294 34.1469 14.3936C34.6111 14.8578 34.9272 15.4492 35.0553 16.093C35.1833 16.7369 35.1176 17.4042 34.8664 18.0107C34.6152 18.6172 34.1898 19.1356 33.6439 19.5003C33.0981 19.865 32.4564 20.0596 31.8 20.0596Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M38.224 28.4759L38.2238 28.4759L38.2259 28.4888C38.2309 28.5201 38.229 28.5521 38.2205 28.5826C38.2119 28.6132 38.1969 28.6415 38.1764 28.6657C38.1559 28.6899 38.1304 28.7093 38.1017 28.7228C38.0735 28.736 38.0427 28.743 38.0116 28.7435H25.5887C25.5575 28.743 25.5268 28.736 25.4985 28.7228C25.4698 28.7093 25.4443 28.6899 25.4238 28.6657L24.9503 29.067L25.4238 28.6657C25.4034 28.6415 25.3883 28.6132 25.3798 28.5826C25.3712 28.5521 25.3694 28.5201 25.3744 28.4888L25.3745 28.4888L25.3763 28.4759C25.5897 26.9238 26.3579 25.5014 27.5388 24.4718C28.7197 23.4422 30.2334 22.875 31.8001 22.875C33.3668 22.875 34.8806 23.4422 36.0615 24.4718C37.2423 25.5014 38.0105 26.9238 38.224 28.4759Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M16.8853 30.1192L16.8815 30.1192C16.6013 30.1209 16.3234 30.0672 16.064 29.9611C15.8046 29.855 15.5687 29.6987 15.37 29.5011C15.1712 29.3035 15.0135 29.0686 14.9058 28.8098C14.7982 28.5511 14.7428 28.2736 14.7428 27.9933L14.7428 27.9896C14.7402 27.5653 14.8637 27.1498 15.0976 26.7958C15.3315 26.4418 15.6652 26.1652 16.0564 26.001C16.4477 25.8369 16.8789 25.7926 17.2953 25.8738C17.7118 25.9551 18.0947 26.1581 18.3956 26.4572L18.8273 26.023L18.3956 26.4572C18.6966 26.7563 18.9019 27.1381 18.9856 27.554C19.0693 27.9699 19.0276 28.4014 18.8659 28.7936C18.7041 29.1859 18.4295 29.5212 18.0769 29.7572C17.7243 29.9932 17.3096 30.1192 16.8853 30.1192Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M21.0947 36.1219L21.5749 36.522L21.0947 36.1219C21.0742 36.1466 21.0484 36.1665 21.0194 36.1802C20.9905 36.1937 20.9591 36.2009 20.9273 36.2011H12.8435C12.8116 36.2009 12.7802 36.1937 12.7514 36.1802C12.7223 36.1665 12.6966 36.1466 12.676 36.1219C12.6555 36.0972 12.6405 36.0683 12.6323 36.0373C12.6246 36.0079 12.623 35.9773 12.6276 35.9473C12.8295 34.9694 13.362 34.0906 14.1357 33.459C14.9119 32.8254 15.8832 32.4794 16.8852 32.4796H16.8855C17.8875 32.4794 18.8588 32.8254 19.635 33.459C20.4088 34.0906 20.9413 34.9694 21.1432 35.9473C21.1478 35.9773 21.1462 36.0079 21.1384 36.0373C21.1302 36.0683 21.1153 36.0972 21.0947 36.1219Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M46.7147 30.1192L46.7109 30.1192C46.4307 30.1209 46.1529 30.0672 45.8935 29.9611C45.6341 29.855 45.3982 29.6987 45.1994 29.5011C45.0006 29.3035 44.8429 29.0686 44.7353 28.8098C44.6276 28.5511 44.5722 28.2736 44.5722 27.9933L44.5722 27.9896C44.5697 27.5653 44.6932 27.1498 44.927 26.7958C45.1609 26.4418 45.4946 26.1652 45.8859 26.001C46.2771 25.8369 46.7083 25.7926 47.1248 25.8738C47.5412 25.9551 47.9242 26.1581 48.2251 26.4572C48.526 26.7563 48.7313 27.1381 48.815 27.554C48.8988 27.9699 48.8571 28.4014 48.6953 28.7936C48.5335 29.1859 48.2589 29.5212 47.9063 29.7572C47.5537 29.9932 47.139 30.1192 46.7147 30.1192Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
      <path
        d="M46.7145 32.4796H46.7148C47.7168 32.4794 48.6881 32.8254 49.4643 33.459C50.238 34.0906 50.7705 34.9694 50.9724 35.9473C50.9771 35.9773 50.9755 36.0079 50.9677 36.0373C50.9595 36.0683 50.9445 36.0972 50.924 36.1219C50.9034 36.1466 50.8777 36.1665 50.8486 36.1802L51.1148 36.7456L50.8486 36.1802C50.8198 36.1937 50.7884 36.2009 50.7565 36.2011H42.6727C42.6409 36.2009 42.6095 36.1937 42.5806 36.1802C42.5516 36.1665 42.5259 36.1466 42.5053 36.1219C42.4847 36.0972 42.4698 36.0683 42.4616 36.0373C42.4538 36.0079 42.4522 35.9773 42.4568 35.9473C42.6587 34.9694 43.1912 34.0906 43.965 33.459C44.7412 32.8254 45.7125 32.4794 46.7145 32.4796Z"
        fill="#E4EEF2"
        stroke="#27393F"
        strokeWidth="1.25"
      />
    </>
  ),
});

export const EmptyTable = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1126_3272)">
        <path
          d="M5.20436 62.8376L1.5 63L1.65482 59.246C2.08027 48.9302 8.51665 39.8231 18.0988 35.9788L28.4697 46.3497C24.6236 55.9362 15.5237 62.3852 5.20436 62.8376Z"
          fill="#25383F"
        />
        <path
          d="M1.5 63L0.500849 62.9588L0.455981 64.0467L1.5438 63.999L1.5 63ZM5.20436 62.8376L5.16057 61.8386L5.20436 62.8376ZM28.4697 46.3497L29.3978 46.722L29.6436 46.1094L29.1768 45.6426L28.4697 46.3497ZM18.0988 35.9788L18.8059 35.2717L18.3391 34.8049L17.7265 35.0507L18.0988 35.9788ZM1.65482 59.246L0.655672 59.2048L1.65482 59.246ZM1.5438 63.999L5.24816 63.8366L5.16057 61.8386L1.4562 62.001L1.5438 63.999ZM29.1768 45.6426L18.8059 35.2717L17.3917 36.6859L27.7626 47.0568L29.1768 45.6426ZM0.655672 59.2048L0.500849 62.9588L2.49915 63.0412L2.65397 59.2872L0.655672 59.2048ZM17.7265 35.0507C7.77906 39.0416 1.09734 48.4958 0.655672 59.2048L2.65397 59.2872C3.06321 49.3646 9.25425 40.6047 18.4712 36.9069L17.7265 35.0507ZM5.24816 63.8366C15.9597 63.3671 25.4055 56.6729 29.3978 46.722L27.5416 45.9774C23.8417 55.1995 15.0877 61.4034 5.16057 61.8386L5.24816 63.8366Z"
          fill="#25383F"
        />
        <path
          d="M33.321 61.5221C41.3958 54.1246 43.1057 44.1182 42.9513 40.0397L30.7415 47.1702C32.4414 50.8883 33.1695 58.2873 33.321 61.5221Z"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33362 31.8701C9.73112 23.7953 19.7375 22.0853 23.816 22.2397L16.6856 34.4496C12.9674 32.7496 5.56839 32.0216 2.33362 31.8701Z"
          stroke="#25383F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.0244 37.1213L31.13 49.4556L15.552 33.8776L27.3215 18.0911C33.9277 9.23011 43.9834 3.58828 54.9888 2.56819L60.3154 2.07447C61.5646 1.95868 62.6107 3.00824 62.4909 4.25704L61.936 10.0369C60.9092 20.7339 55.5141 30.5331 47.0244 37.1213Z"
          fill="white"
        />
        <path
          d="M31.13 49.4556L30.4229 50.1627L31.0464 50.7862L31.7431 50.2456L31.13 49.4556ZM47.0244 37.1213L47.6375 37.9113L47.0244 37.1213ZM61.936 10.0369L62.9314 10.1325L61.936 10.0369ZM62.4909 4.25704L61.4954 4.16148L62.4909 4.25704ZM60.3154 2.07447L60.4077 3.0702L60.4077 3.0702L60.3154 2.07447ZM54.9888 2.56819L54.8965 1.57246L54.9888 2.56819ZM27.3215 18.0911L28.1232 18.6888L27.3215 18.0911ZM15.552 33.8776L14.7503 33.2799L14.2334 33.9732L14.8449 34.5847L15.552 33.8776ZM31.7431 50.2456L47.6375 37.9113L46.4113 36.3312L30.5169 48.6656L31.7431 50.2456ZM62.9314 10.1325L63.4863 4.35259L61.4954 4.16148L60.9406 9.94135L62.9314 10.1325ZM60.2231 1.07874L54.8965 1.57246L55.0811 3.56392L60.4077 3.0702L60.2231 1.07874ZM26.5197 17.4934L14.7503 33.2799L16.3537 34.4753L28.1232 18.6888L26.5197 17.4934ZM14.8449 34.5847L30.4229 50.1627L31.8371 48.7485L16.2591 33.1705L14.8449 34.5847ZM54.8965 1.57246C43.6089 2.61871 33.2953 8.40519 26.5197 17.4934L28.1232 18.6888C34.56 10.055 44.3579 4.55786 55.0811 3.56392L54.8965 1.57246ZM63.4863 4.35259C63.6661 2.4794 62.0969 0.905054 60.2231 1.07874L60.4077 3.0702C61.0323 3.01231 61.5554 3.53709 61.4954 4.16148L63.4863 4.35259ZM47.6375 37.9113C56.3448 31.1542 61.8783 21.1037 62.9314 10.1325L60.9406 9.94135C59.9401 20.364 54.6833 29.912 46.4113 36.3312L47.6375 37.9113Z"
          fill="#25383F"
        />
        <path d="M20 28.0858L36.4572 44.5429" stroke="#25383F" strokeWidth="2" />
        <path d="M59.8552 19.3674L44.8552 4.36741" stroke="#25383F" strokeWidth="2" />
        <circle
          cx="43.1689"
          cy="21.8137"
          r="8"
          transform="rotate(45 43.1689 21.8137)"
          fill="#F9C66C"
        />
        <rect
          x="30.3848"
          y="29.9142"
          width="6"
          height="24"
          rx="3"
          transform="rotate(45 30.3848 29.9142)"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <circle cx="43.1689" cy="21.5" r="3" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1126_3272">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const NoOpenRoles = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1255_1679)">
        <mask id="path-1-inside-1_1255_1679" fill="white">
          <rect x="10" y="11.5" width="44" height="53" rx="1" />
        </mask>
        <rect
          x="10"
          y="11.5"
          width="44"
          height="53"
          rx="1"
          fill="white"
          stroke="#25383F"
          strokeWidth="4"
          mask="url(#path-1-inside-1_1255_1679)"
        />
        <path
          d="M48 16.5H16C15.4477 16.5 15 16.9477 15 17.5V58.5C15 59.0523 15.4477 59.5 16 59.5H40.5858C40.851 59.5 41.1054 59.3946 41.2929 59.2071L48.7071 51.7929C48.8946 51.6054 49 51.351 49 51.0858V17.5C49 16.9477 48.5523 16.5 48 16.5Z"
          fill="#F9C66C"
        />
        <path d="M49 51.5H42C41.4477 51.5 41 51.9477 41 52.5V59.5L49 51.5Z" fill="#25383F" />
        <path
          d="M25.4583 9.18966H26.4583V8.18965V6.97917C26.4583 3.95311 28.9114 1.5 31.9375 1.5C34.9636 1.5 37.4167 3.95311 37.4167 6.97917V8.18965V9.18966H38.4167H43C45.7614 9.18966 48 11.4282 48 14.1897V16.5H16V14.1897C16 11.4282 18.2386 9.18966 21 9.18966H25.4583Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <rect x="15" y="17.5" width="34" height="2" fill="#25383F" />
        <circle cx="32" cy="7.5" r="2" fill="#25383F" />
        <line x1="29" y1="27.5" x2="43" y2="27.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="35.5" x2="43" y2="35.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="44.5" x2="43" y2="44.5" stroke="#25383F" strokeWidth="2" />
        <circle cx="23" cy="27.5" r="2" fill="white" />
        <circle cx="23" cy="35.5" r="2" fill="white" />
        <circle cx="23" cy="44.5" r="2" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1255_1679">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});
