import { NetworkStatus, useMutation } from '@apollo/client';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import Sentry from 'global/sentry';
import { useToast } from '@terminal/design-system';
import type {
  SelectSuggestedOrganizationUsersQuery,
  SelectSuggestedOrganizationUsersQueryVariables,
} from './data';
import { SelectSuggestedOrganizationUsers } from './data';
import {
  serializeSuggestedOrganizationUsers_toFeedback,
  serializeSuggestedOrganizationUsers_toRoleFeedback,
} from './SuggestedOrganizationUsers.serializer';
import type {
  InsertUsersAndFeedbackInvitationsMutation,
  InsertUsersAndFeedbackInvitationsMutationVariables,
} from './subfeatures/candidate-feedback/data';
import { InsertUsersAndFeedbackInvitations } from './subfeatures/candidate-feedback/data';
import { InsertUsersAndFeedbackInvitations_toUserAndFeedbackInvitation } from './UserAndFeedbackInvitation.serializer';

export function useInterviewFeedbackUtils({
  applicantWorkflowID,
  jobID,
  shouldRefreshFeedbackList,
}: {
  applicantWorkflowID: Maybe<number>;
  jobID: number;
  shouldRefreshFeedbackList?: boolean;
}) {
  const { user, isClientReviewer, viewingOrganization } = useCustomerAuthorizedUserSession();
  const toast = useToast({
    position: 'top',
    duration: 6000,
  });

  const { loading, data, networkStatus } = useQuery<
    SelectSuggestedOrganizationUsersQuery,
    SelectSuggestedOrganizationUsersQueryVariables
  >(SelectSuggestedOrganizationUsers, {
    variables: {
      organization: viewingOrganization.ID,
      applicant_workflow_profile_id: Number(applicantWorkflowID) || -1,
      current_user_email: user?.email || '',
      job_id: jobID,
    },
    fetchPolicy: 'network-only',
    skip: isClientReviewer || (!applicantWorkflowID && !jobID),
  });

  const isUsersQueryLoading = loading || networkStatus === NetworkStatus.refetch;

  const users = applicantWorkflowID
    ? serializeSuggestedOrganizationUsers_toFeedback(data)
    : serializeSuggestedOrganizationUsers_toRoleFeedback(data);

  const [insertUserAndFeedbackInvitations, insertUserAndFeedbackInvitationsStatus] = useMutation<
    InsertUsersAndFeedbackInvitationsMutation,
    InsertUsersAndFeedbackInvitationsMutationVariables
  >(InsertUsersAndFeedbackInvitations, {
    refetchQueries: shouldRefreshFeedbackList
      ? ['SelectCandidateFeedback', 'SelectSuggestedOrganizationUsers']
      : undefined,
    awaitRefetchQueries: true,
    onCompleted: ({ insert_interview_feedback_request }) => {
      toast({
        status: 'success',
        description: `Success! Invitation${
          Number(insert_interview_feedback_request?.affected_rows) > 1 ? 's' : ''
        } ${
          Number(insert_interview_feedback_request?.affected_rows) > 1 ? 'have' : 'has'
        } been sent.`,
      });
    },
    onError: (mutationError) => {
      if (
        mutationError.message.startsWith(
          'Uniqueness violation. duplicate key value violates unique constraint',
        )
      ) {
        toast({
          status: 'error',
          description: 'User already invited for feedback. Please remove them.',
        });
      } else {
        toast({
          status: 'error',
          description: 'Interview feedback invitations could not be sent. Please try again.',
        });
      }

      Sentry.captureException(mutationError);
    },
  });
  function handleOnSubmitInviteFeedback(modalInvitedUsers: {
    users: {
      value: string;
      label: string;
    }[];
    isRoleAccess: boolean;
  }) {
    const { error } = insertUserAndFeedbackInvitationsStatus;
    const { users_invites, feedback_invites, job_interview_feedback_request } =
      InsertUsersAndFeedbackInvitations_toUserAndFeedbackInvitation({
        modalInvitedUsers,
        applicantWorkflowID: Number(applicantWorkflowID),
        jobID,
        user: { organization: viewingOrganization.ID, id: Number(user?.id) },
        organizationUsers: data?.users,
        outsideUsersInvited: data?.user_invitation,
      });

    const userAlreadyInvitedForFeedback = feedback_invites.filter(
      ({ interviewer_email: invitationEmail }) =>
        data?.interview_feedback_request.some(
          ({ interviewer_email }) => interviewer_email === invitationEmail,
        ),
    );

    if (userAlreadyInvitedForFeedback.length) {
      toast({
        status: 'error',
        description: `User${
          userAlreadyInvitedForFeedback.length > 1 ? 's' : ''
        } ${userAlreadyInvitedForFeedback
          .map((i) => i.interviewer_email)
          .join(', ')} already invited. Please remove ${
          userAlreadyInvitedForFeedback.length > 1 ? 'them' : 'it'
        }.`,
      });
      return { error: true };
    }

    insertUserAndFeedbackInvitations({
      variables: {
        users_invites,
        feedback_invites,
        job_interview_feedback_request,
      },
    });

    return { error: Boolean(error) };
  }

  return {
    users,
    isLoading: isUsersQueryLoading,
    handleOnSubmitInviteFeedback,
  };
}
