import { BlankScreenLoading } from 'global/components';
import {
  Divider,
  Grid,
  GridItem,
  Heading,
  List,
  ListIcon,
  CalendarIcon,
  LocationIcon,
  ListItem,
  Tag,
  Text,
  Center,
} from '@terminal/design-system';

import {
  AppSubheader,
  Card,
  CardBody,
  PageLayout,
  PageLayoutPaddedBody,
} from 'talent-hub/components';

import type { serializerForMemberDirectory } from './MemberDirectory.serializer';
import { ResumeProfile } from './components';

export function MemberDirectory({
  members,
  loading,
  pageLayoutProps,
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  members: ReturnType<typeof serializerForMemberDirectory>;
  loading: boolean;
}) {
  if (loading)
    return (
      <PageLayout headerTitle="Your Active Members" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <BlankScreenLoading />
        </PageLayoutPaddedBody>
      </PageLayout>
    );

  if (!members.length)
    return (
      <PageLayout headerTitle="Your Active Members" {...pageLayoutProps}>
        <PageLayoutPaddedBody>
          <Center flexDirection="column" w={['80%', '80%', '50%']} textAlign="center" mx="auto">
            <ResumeProfile w={16} h={16} mt={6} />
            <Heading variant="heading-2" mt={4}>
              No Active Members
            </Heading>
            <Text mt={1}>
              We&apos;re working hard to find the next outstanding member of your team! Information
              about hired team members will be displayed here after your first hire
            </Text>
          </Center>
        </PageLayoutPaddedBody>
      </PageLayout>
    );

  return (
    <PageLayout headerTitle="Your Active Members" {...pageLayoutProps}>
      <PageLayoutPaddedBody>
        <AppSubheader title={`${members.length} Active Members`} />
        <Grid templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']} gap={6} w="full">
          {members.map(
            ({
              id,
              name,
              title,
              location,
              start,
              yearsOfDuration,
              monthsOfDuration,
              daysOfDuration,
            }) => {
              return (
                <GridItem key={id}>
                  <Card p={4} h="full">
                    <CardBody>
                      <Heading variant="heading-3" as="h3" color="text.primary">
                        {name}
                      </Heading>
                      <Text variant="label" color="text.secondary">
                        {title}
                      </Text>
                      <Tag mt={6} colorScheme="info" size="sm" alignSelf="start">
                        {yearsOfDuration ? `${yearsOfDuration} year` : ''}
                        {yearsOfDuration > 1 ? 's' : ''}
                        {yearsOfDuration && monthsOfDuration ? ', ' : ''}
                        {monthsOfDuration ? `${monthsOfDuration} month` : ''}
                        {monthsOfDuration > 1 ? 's' : ''}
                        {!yearsOfDuration && monthsOfDuration && daysOfDuration ? ', ' : ''}
                        {!yearsOfDuration && daysOfDuration ? `${daysOfDuration} day` : ''}
                        {!yearsOfDuration && daysOfDuration > 1 ? 's' : ''}
                        {!(yearsOfDuration || monthsOfDuration || daysOfDuration) &&
                          'Started Today'}
                      </Tag>
                      <Divider mt={16} mb={4} orientation="horizontal" borderColor="ui.secondary" />
                      <List>
                        <ListItem color="text.secondary">
                          <ListIcon as={CalendarIcon} verticalAlign="middle" w={4} h={4} />
                          <Text variant="caption" display="inline-block" color="text.secondary">
                            Started&nbsp;
                            {start}
                          </Text>
                        </ListItem>
                        <ListItem color="text.secondary">
                          <ListIcon as={LocationIcon} verticalAlign="middle" w={4} h={4} />
                          <Text variant="caption" display="inline-block" color="text.secondary">
                            {location}
                          </Text>
                        </ListItem>
                      </List>
                    </CardBody>
                  </Card>
                </GridItem>
              );
            },
          )}
        </Grid>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}
