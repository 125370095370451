import { Route } from 'react-router-dom';

import { CustomRoute } from 'global/components';
import { ScrollToTopOnPathChange, SwitchWithPageNotFound } from 'talent-hub/components';
import { ExploreCandidatesRouter } from './explore-candidates';
import { CalendlyModalProvider } from './components/CalendlyWidget';
import { DashboardController } from './dashboard';

export function ProspectRouter() {
  return (
    <CalendlyModalProvider>
      <SwitchWithPageNotFound>
        <Route path="/explore-candidates">
          <ScrollToTopOnPathChange />
          <ExploreCandidatesRouter />
        </Route>
        <CustomRoute path="/">
          <ScrollToTopOnPathChange />
          <DashboardController />
        </CustomRoute>
      </SwitchWithPageNotFound>
    </CalendlyModalProvider>
  );
}
