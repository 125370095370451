import { cssVar } from '@chakra-ui/theme-tools';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';
import { textTheme } from '../Text';

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle: SystemStyleFunction = () => {
  return {
    ...textTheme.variants.hint,
    [$bg.variable]: `colors.bg.secondary`,
    bg: [$bg.reference],
    [$arrowBg.variable]: [$bg.reference],
    p: 2,
    borderRadius: 'sm',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  };
};

export const tooltipTheme = {
  baseStyle,
};
