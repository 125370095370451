export const textTheme = {
  baseStyle: {
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'regular',
    lineHeight: 'base',
    color: 'text.primary',
  },
  variants: {
    body: {
      // we don't have duplicate the base styles in every variant, but since Text is used in a lot
      // of components, having its variants have all its customization we can easily reuse then in
      // other components
      color: 'text.primary',
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'regular',
      lineHeight: 'base',
    },
    label: {
      color: 'text.primary',
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'bold',
      lineHeight: 'base',
    },
    caption: {
      color: 'text.primary',
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'regular',
      lineHeight: 'base',
    },
    hint: {
      color: 'text.primary',
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'regular',
      lineHeight: 'base',
    },
  },
  defaultProps: {
    variant: 'body',
  },
};
