import type { SystemProps, HTMLChakraProps, ThemingProps, ResponsiveValue } from '@chakra-ui/react';
import { chakra, forwardRef, omitThemingProps, useStyleConfig } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

type Color =
  | 'text.primary'
  | 'text.secondary'
  | 'text.disabled'
  | 'text.inverse'
  | 'text.error'
  | 'text.success'
  | 'text.link'
  | 'brand.main'
  | 'brand.darker'
  | 'accent.main'
  | 'accent.darkest'
  | 'accent.lighter'
  | 'ui.primary';

export interface HeadingProps extends HTMLChakraProps<'h2'>, ThemingProps<'Heading'> {
  /**
   * Available text colors
   */
  color?: Color | Color[];

  /**
   * Text Variants
   */
  variant?: 'heading-1' | 'heading-2' | 'heading-3' | 'heading-4';

  /**
   * if `true`, it'll render an ellipsis when the text exceeds the width of the viewport or maxWidth set.
   */
  isTruncated?: boolean;
  /**
   * Used to truncate text at a specific number of lines
   */
  noOfLines?: ResponsiveValue<number>;

  /**
   * The CSS `text-align` property
   */
  align?: SystemProps['textAlign'];
  /**
   * The CSS `text-decoration` property
   */
  decoration?: SystemProps['textDecoration'];
  /**
   * The CSS `text-transform` property
   */
  casing?: SystemProps['textTransform'];
}

export const Heading = forwardRef<HeadingProps, 'h2'>((props, ref) => {
  const styles = useStyleConfig('Heading', props);
  const { className, ...rest } = omitThemingProps(props);

  return (
    <chakra.h2
      ref={ref}
      className={cx('chakra-heading', props.className)}
      {...rest}
      __css={styles}
    />
  );
});

Heading.defaultProps = {
  variant: 'heading-1',
  color: 'text.primary',
};
