import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useCustomerAuth } from 'talent-hub/utils';
import { AnonymousSignInController } from 'global/auth';
import { CandidatePublicProfileController } from './CandidateProfile.controller';

export function CandidatePublicProfileRouter() {
  const auth = useCustomerAuth();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:publicID`}>
        <AnonymousSignInController auth={auth}>
          <CandidatePublicProfileController />
        </AnonymousSignInController>
      </Route>
      <Route
        path={path}
        render={() => (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )}
      />
    </Switch>
  );
}
