import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { FetchResult } from '@apollo/client';
import { Heading, Grid, Skeleton, Flex, Box, Text } from '@terminal/design-system';
import { Card, CardBody, PageLayout, PageLayoutPaddedBody } from 'talent-hub/components';
import type {
  DeleteSavedCandidateMutation,
  InsertSavedCandidateMutation,
  SelectTeamMembersQuery,
} from 'talent-hub/utils';
import {
  ExploreCandidatesTabsLayout,
  ExploreCandidatesTabsLayoutIndex,
  CandidateCard,
  ShareWithOthersModal,
} from '../../components';

import type { serializeCandidateBrowse } from '../explore-candidates/ExploreCandidates.serializer';
import { serializeTeamMembersToShareWith } from '../explore-candidates/ExploreCandidates.serializer';

function Layout({
  children,
  pageLayoutProps,
  savedByMeCandidatesCount,
  sharedWithMeCandidatesCount,
}: {
  children: React.ReactNode;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  savedByMeCandidatesCount: number;
  sharedWithMeCandidatesCount: number;
}) {
  return (
    <PageLayout headerTitle="Explore Candidates" {...pageLayoutProps} disableMaxWidth>
      <ExploreCandidatesTabsLayout
        currentTab={ExploreCandidatesTabsLayoutIndex.SharedWithMe}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
      >
        {children}
      </ExploreCandidatesTabsLayout>
    </PageLayout>
  );
}

export function SharedCandidates({
  pageLayoutProps,
  loading,
  candidates,
  savedByMeCandidatesCount,
  sharedWithMeCandidatesCount,
  handleOnSaveCandidate,
  handleOnRemoveSaveCandidate,
  sharedCandidateWithProps: {
    teamMembers,
    handleInsertSharedWith,
    isShareWithModalOpen,
    onCloseShareWithModal,
    onOpenShareWithModal,
  },
}: {
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  loading: boolean;
  candidates: ReturnType<typeof serializeCandidateBrowse>['candidates'];
  savedByMeCandidatesCount: number;
  sharedWithMeCandidatesCount: number;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  sharedCandidateWithProps: {
    teamMembers: SelectTeamMembersQuery | undefined;
    handleInsertSharedWith: (candidateId: number, teamMembersIds: string[]) => void;
    isShareWithModalOpen: boolean;
    onCloseShareWithModal: () => void;
    onOpenShareWithModal: () => void;
  };
}) {
  const { candidateID } = useParams<{ candidateID?: string }>();

  const [shareWithCandidateInfo, setShareWithCandidateInfo] = useState<
    { id: number; name: string } | undefined
  >();

  if (loading) {
    return (
      <Layout
        pageLayoutProps={pageLayoutProps}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
      >
        <PageLayoutPaddedBody>
          <Heading variant="heading-3">Shared with Me</Heading>
          <Grid gap={6} templateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']} mt={6}>
            {Array(6)
              .fill(0)
              .map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Card key={index}>
                  <CardBody>
                    <Skeleton height={4} borderRadius="lg" width="25%" />
                    <Skeleton height={4} borderRadius="lg" width="50%" mt={1} />
                    <Skeleton height={32} mt={4} endColor="ui.inverse.secondary" />
                    <Flex mt={4}>
                      <Skeleton
                        height={6}
                        flex={1}
                        endColor="ui.inverse.secondary"
                        borderRadius="lg"
                      />
                      <Skeleton
                        height={6}
                        flex={1}
                        endColor="ui.inverse.secondary"
                        borderRadius="lg"
                        ml={2}
                      />
                      <Skeleton
                        height={6}
                        flex={1}
                        endColor="ui.inverse.secondary"
                        borderRadius="lg"
                        ml={2}
                      />
                      <Skeleton
                        height={6}
                        flex={1}
                        endColor="ui.inverse.secondary"
                        borderRadius="lg"
                        ml={2}
                      />
                    </Flex>
                  </CardBody>
                </Card>
              ))}
          </Grid>
        </PageLayoutPaddedBody>
      </Layout>
    );
  }

  if (candidates.length === 0 && !candidateID) {
    return (
      <Layout
        pageLayoutProps={pageLayoutProps}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
      >
        <Box p={6}>
          <Heading variant="heading-3">Shared with Me</Heading>
          <Box bgColor="bg.tertiary" textAlign="center" p={8} mt={4}>
            <Heading variant="heading-2">No candidates have been shared with you.</Heading>
            <Text variant="body">
              Candidates shared with you by other members of your team or by Terminal will appear
              here.
            </Text>
          </Box>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout
      pageLayoutProps={pageLayoutProps}
      savedByMeCandidatesCount={savedByMeCandidatesCount}
      sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
    >
      <Box p={6}>
        <Heading variant="heading-3">Shared with Me</Heading>
        <Grid gap={4} templateColumns={['1fr', '1fr 1fr', 'repeat(3, 1fr)']} mt={4}>
          {candidates.map((candidate) => (
            <CandidateCard
              key={candidate.id}
              candidate={candidate}
              linkTo={`/explore-candidates/shared-candidates/candidate/${candidate.id}`}
              handleOnSaveCandidate={handleOnSaveCandidate}
              handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
              handleOpenShareWithOthers={() => {
                setShareWithCandidateInfo({ id: candidate.id, name: candidate.fullName });
                onOpenShareWithModal();
              }}
            />
          ))}
        </Grid>
      </Box>
      {/* Share candidate with modal */}
      {!!shareWithCandidateInfo?.id && (
        <ShareWithOthersModal
          onClose={onCloseShareWithModal}
          candidateInfo={shareWithCandidateInfo}
          handleInsert={handleInsertSharedWith}
          isOpen={isShareWithModalOpen}
          teamMembers={serializeTeamMembersToShareWith(teamMembers, shareWithCandidateInfo?.id)}
        />
      )}
    </Layout>
  );
}
