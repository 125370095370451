import { Icon, StyleProps, useToast as nativeToastHook } from '@chakra-ui/react';
import type { RenderProps, ToastId, ToastOptions, AlertStatus } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Alert, AlertDescription, AlertTitle } from '../alerts';
import type { AlertVariant } from '../alerts';
import { CloseButton } from '../Button';
import { Flex } from '../layout';
import { CheckCircleSolidIcon, WarningCircleSolidIcon } from '../icons';

export type UseToastOptions = {
  /**
   * The placement of the toast
   *
   * @default "bottom"
   */
  position?: ToastOptions['position'];
  /**
   * The delay before the toast hides (in milliseconds)
   * If set to `null`, toast will never dismiss.
   *
   * @default 5000 ( = 5000ms )
   */
  duration?: ToastOptions['duration'];
  /**
   * Render a component toast component.
   * Any component passed will receive 2 props: `id` and `onClose`.
   */
  render?(props: RenderProps): React.ReactNode;
  /**
   * The title of the toast
   */
  title?: React.ReactNode;
  /**
   * The description of the toast
   */
  description?: React.ReactNode;
  /**
   * If `true`, toast will show a close button
   */
  isClosable?: boolean;
  /**
   * The alert component `variant` to use
   */
  variant?: AlertVariant;
  /**
   * The status of the toast.
   */
  status?: Exclude<AlertStatus, 'loading'>;
  /**
   * The `id` of the toast.
   *
   * Mostly used when you need to prevent duplicate.
   * By default, we generate a unique `id` for each toast
   */
  id?: ToastId;
  /**
   * The Alert boxShadow
   */
  boxShadow?: React.ComponentProps<typeof Alert>['boxShadow'];
  /**
   * Callback function to run when the exit button is clicked
   */
  onCloseClicked?: () => void;
  /**
   * Callback function to run side effects after the toast has closed.
   */
  onCloseComplete?: () => void;
  /**
   * Optional style overrides for the container wrapping the toast component.
   */
  containerStyle?: StyleProps;
};

export function Toast(props: UseToastOptions) {
  const {
    status,
    variant = 'subtle',
    id,
    title,
    isClosable = true,
    onCloseClicked,
    description,
    boxShadow = 'lg',
  } = props;

  const toast = nativeToastHook();

  const onClose = useCallback(() => {
    toast.close(id!);

    if (onCloseClicked) {
      onCloseClicked();
    }
  }, [onCloseClicked, toast, id]);

  const iconColor = {
    success: 'ui.darker.success',
    error: 'ui.error',
    warning: 'ui.warning',
    info: 'ui.darker.info',
  };

  const statusIcon = {
    success: CheckCircleSolidIcon,
    error: WarningCircleSolidIcon,
    warning: CheckCircleSolidIcon,
    info: WarningCircleSolidIcon,
  };

  return (
    <Alert
      status={status}
      variant={variant}
      id={id?.toString()}
      alignItems="center"
      boxShadow={boxShadow}
      p={3}
      width="auto"
    >
      <Icon
        w="1.4rem"
        h="1.4rem"
        // @ts-ignore // TODO: [TP-1919] Fix this type
        color={iconColor[status || 'success'] || 'ui.darker.success'}
        // @ts-ignore // TODO: [TP-1919] Fix this type
        as={statusIcon[status || 'success']}
      />
      <Flex ml={2} flexDirection={['column', 'row']} justifyContent={['center', 'flex-start']}>
        {title && <AlertTitle mb={[1, 0]}>{title}</AlertTitle>}
        {description && (
          <AlertDescription mt={0} display="block">
            {description}
          </AlertDescription>
        )}
      </Flex>
      {isClosable && <CloseButton w={3} h={3} fontSize="smaller" onClick={onClose} mr={1} ml={3} />}
    </Alert>
  );
}

export function useToast(options?: UseToastOptions) {
  const toast = nativeToastHook({
    render: ({ variant, status, ...props }) => (
      <Toast
        {...{ isClosable: true, ...options, ...props }}
        status={status as UseToastOptions['status']}
        variant={variant as UseToastOptions['variant']}
      />
    ),
    isClosable: true,
    ...options,
  });

  const wrapToast = (opts?: UseToastOptions) => {
    return toast({
      render: ({ variant, status, ...props }) => (
        <Toast
          {...{ isClosable: true, ...options, ...props, ...opts }}
          status={status as UseToastOptions['status']}
          variant={variant as UseToastOptions['variant']}
        />
      ),
      isClosable: true,
      ...options,
      ...opts,
    });
  };

  wrapToast.close = toast.close;
  wrapToast.closeAll = toast.closeAll;
  wrapToast.update = toast.update;
  wrapToast.isActive = toast.isActive;

  return wrapToast;
}
