import { forwardRef, useCheckbox } from '@chakra-ui/react';
import type { CheckboxProps } from '@chakra-ui/react';
import { CheckCircleSolidIcon } from '../../icons';
import { Button } from '../../Button';
import { Box, Flex } from '../../layout';

export interface CheckboxCardProps extends CheckboxProps {
  checkboxIcon?: React.ReactNode;
}

export const CheckboxCard = forwardRef<CheckboxCardProps, 'input'>(
  ({ isDisabled, isChecked, onChange, children, checkboxIcon, w, ...rest }, ref) => {
    const { getInputProps, getCheckboxProps, getRootProps, state } = useCheckbox({
      ...rest,
      isDisabled,
      isChecked,
      onChange,
    }) as any; // TODO: [TP-1470] Fix chakra-ui types

    return (
      <Box {...getRootProps()} w={w || 'full'} position="relative">
        <input {...getInputProps({}, ref)} hidden />
        {isChecked && (
          <CheckCircleSolidIcon
            color="brand.main"
            position="absolute"
            right={[2, 2, 3]}
            bottom={[2, 2, 3]}
            role="img"
            aria-label="checked icon"
          />
        )}
        <Button
          border="1px solid"
          borderColor="ui.secondary"
          borderRadius="base"
          variant="unstyled"
          h={[20, 20, '114px']}
          w={['full', 'full', 'full']}
          minW="144px"
          bg="ui.inverse.primary"
          fontFamily="body"
          {...getCheckboxProps()}
          __css={{
            _hover: { bg: 'ui.inverse.secondary' },
            _checked: { bg: 'ui.inverse.secondary', fontWeight: 'bold' },
          }}
        >
          <Flex
            flexDir={['row', 'row', 'column']}
            alignItems="center"
            justifyContent={['flex-start', 'flex-start', 'center']}
            px={[3, 3, 7]}
            h="full"
            fontWeight={state.isChecked ? 'bold' : 'regular'}
            textAlign={['left', 'left', 'center']}
            className="checkbox-card__icon-container"
          >
            {checkboxIcon && (
              <Box mb={[0, 0, 4]} mr={[2, 2, 0]} className="checkbox-card__icon-wrapper">
                {checkboxIcon}
              </Box>
            )}
            {children}
          </Flex>
        </Button>
      </Box>
    );
  },
);
