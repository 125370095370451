import { useParams } from 'react-router-dom';
import * as events from 'global/events';
import { useToast } from '@terminal/design-system';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils/authorizedUserSession';
import { useDownloadFile } from 'talent-hub/utils/file';
import type { JobCandidateProfileQuery } from 'talent-hub/shared/features/roles/subfeatures/candidate-profile/data';
import { useTalentHubFlags } from 'talent-hub/utils/flags';
import { serializeJobCandidateProfile } from './JobCandidateProfile.serializer';

export function useCandidateInformation(
  data: JobCandidateProfileQuery,
  shouldConsiderPreSubmission_markupChange: boolean = false,
) {
  const { viewingOrganization } = useCustomerAuthorizedUserSession();
  const { activeRole, candidateID } = useParams<{ activeRole: string; candidateID: string }>();
  const featureFlags = useTalentHubFlags();

  const { profile, stage, hasInterviewFeedback, badges, activeMessage, highlights } =
    serializeJobCandidateProfile({
      data,
      organizationCurrency: viewingOrganization.currency,
      shouldConsiderPreSubmission_markupChange,
    });

  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });

  const { downloadFile: downloadResume, isLoading: isDownloadingResume } = useDownloadFile();

  const handleResumeLinkClicked = async (fileExtension?: string) => {
    if (!isDownloadingResume && profile.resume.fileName && profile.resume.remotePath) {
      toast({
        title: 'Preparing the resume,',
        description: 'your download will begin shortly.',
        status: 'info',
        duration: 1500,
        isClosable: true,
        position: 'top',
      });
      try {
        await downloadResume({
          path: profile.resume.remotePath,
          fileName: featureFlags.enableCandidateResumeViewer
            ? `${profile.firstName} ${profile?.lastName} - Resume`
            : profile.resume.fileName,
          fileExtension,
        });
      } catch (Exception) {
        toast({
          status: 'error',
          title: 'Error!',
          description: 'Something went wrong trying to download the resume. Please try again!',
        });
      }

      // Track event
      events.track(events.name.requestedCandidateResume, {
        candidateID,
        candidate_name: profile.fullName,
        job: activeRole,
        organization: viewingOrganization.ID,
      });
    }
  };
  return {
    handleResumeLinkClicked,
    profile,
    stage,
    hasInterviewFeedback,
    candidateHighlights: {
      badges,
      activeMessage,
      highlights,
    },
  };
}
