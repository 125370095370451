import { useParams } from 'react-router-dom';
import {
  SharedCandidates,
  useSharedCandidatesController,
  ViewType,
} from 'talent-hub/shared/features/explore-candidates';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { CandidateProfileController } from '../candidate-profile';

export function SharedCandidatesController() {
  const { candidateID } = useParams<{ candidateID?: string }>();
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const {
    loading,
    candidates,
    savedByMeCandidatesCount,
    sharedWithMeCandidatesCount,
    handleOnRemoveSaveCandidate,
    handleOnSaveCandidate,
    handleOnInsertSharedCandidate,
    teamMembers,
    isShareWithModalOpen,
    onOpenShareWithModal,
    onCloseShareWithModal,
    onCloseCandidateProfileClick,
  } = useSharedCandidatesController();
  return (
    <>
      <SharedCandidates
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
        }}
        loading={loading}
        candidates={candidates}
        savedByMeCandidatesCount={savedByMeCandidatesCount}
        sharedWithMeCandidatesCount={sharedWithMeCandidatesCount}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        sharedCandidateWithProps={{
          teamMembers,
          handleInsertSharedWith: handleOnInsertSharedCandidate,
          isShareWithModalOpen,
          onOpenShareWithModal,
          onCloseShareWithModal,
        }}
      />
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={onCloseCandidateProfileClick}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        viewPage={ViewType.SHARED}
      />
    </>
  );
}
