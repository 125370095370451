import { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import moment from 'moment';

import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';
import { CustomerActivityDashboard } from './CustomerActivityDashboard';
import { SelectDashboardCustomerActivities } from './data';
import type {
  SelectDashboardCustomerActivititesQuery,
  SelectDashboardCustomerActivititesQueryVariables,
} from './data';
import { serializerForCustomerActivityDashboard } from './CustomerActivityDashboard.serializer';

export function CustomerActivityDashboardController() {
  // const history = useHistory();
  const { isRecruiter, user, viewingOrganization, userPrioritizedRole, setViewingOrg } =
    useCustomerAuthorizedUserSession();

  const lastActivityDate = moment().subtract(14, 'days').format('YYYY-MM-DD');

  const { loading, data } = useQuery<
    SelectDashboardCustomerActivititesQuery,
    SelectDashboardCustomerActivititesQueryVariables
  >(SelectDashboardCustomerActivities, { variables: { last_activity_date: lastActivityDate } });

  // setViewingOrg(1);
  // Track page views
  useEffect(() => {
    // Track page view
    events.track(events.name.viewedChangeOrganization); // TODO: Change to CustomerActivityDashboard
  }, []);

  if (!data || loading) {
    return (
      <CustomerActivityDashboard
        isLoading
        onChangeOrgRequest={setViewingOrg}
        pageLayoutProps={{
          isClientReviewer: false,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect: false,
          userPrioritizedRole,
        }}
        recentlyCreatedsJobs={[]}
        recentlyCreatedCustomers={[]}
        allJobsActivities={[]}
        recent_interview_feedbacks={[]}
        recent_profile_views={[]}
        recent_submissions_acceptance_statuses={[]}
      />
    );
  }

  const serializedData = serializerForCustomerActivityDashboard(data);

  return (
    <CustomerActivityDashboard
      onChangeOrgRequest={setViewingOrg}
      isLoading={loading}
      pageLayoutProps={{
        isClientReviewer: false,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect: false,
        userPrioritizedRole,
      }}
      recentlyCreatedsJobs={serializedData.recentCreatedJobs}
      recentlyCreatedCustomers={serializedData.recentCreatedCustomer}
      allJobsActivities={serializedData.allJobsActivities}
      recent_interview_feedbacks={serializedData.recent_interview_feedbacks}
      recent_profile_views={serializedData.recent_profile_views}
      recent_submissions_acceptance_statuses={serializedData.recent_submissions_acceptance_statuses}
    />
  );
}
