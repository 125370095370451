import type { BoxProps } from '@terminal/design-system';
import {
  Flex,
  Heading,
  Box,
  Grid,
  Button,
  LinkoutIcon,
  Progress,
  Text,
  Center,
  HStack,
  VStack,
  Avatar,
  forwardRef,
} from '@terminal/design-system';
import { Logo as TerminalLogo } from 'global/components/Logo';
import { Link as RouterLink } from 'react-router-dom';
import { stripAllHTMLAttributes } from 'global/utils';
import backgroundImg from '../assets/background.svg?url';
import type { StepInfo } from '../Onboarding.types';

export function LeftHeaderTitle({ text }: { text: string }) {
  return (
    <>
      <Flex gridGap={2}>
        <Box backgroundColor="#6DA8BB" width={2} height={2} />
        <Box backgroundColor="#F6AE2D" width={2} height={2} />
        <Box backgroundColor="#91B667" width={2} height={2} />
      </Flex>
      <Heading
        mt={8}
        variant="heading-1"
        mx={['auto', 'auto', 0]}
        dangerouslySetInnerHTML={{
          __html: stripAllHTMLAttributes(text || ''),
        }}
        sx={{
          strong: {
            color: 'brand.main',
          },
        }}
      />
    </>
  );
}

export function LeftHeaderSubtitle({ text }: { text: string }) {
  return (
    <Heading
      mt={3}
      variant="heading-3"
      w={['100%', '100%', '90%']}
      textAlign={['justify', 'justify', 'left']}
      textColor="text.secondary"
    >
      {text}
    </Heading>
  );
}

export function LeftQuote({
  children,
  name,
  description,
  imageSrc,
}: {
  children: React.ReactNode;
  name: string;
  description: string;
  imageSrc: React.ComponentProps<typeof Avatar>['src'];
}) {
  return (
    <>
      <Heading variant="heading-3" color="text.secondary">
        What our customers are saying
      </Heading>
      <Text mt={1} color="text.secondary">
        “{children}”
      </Text>
      <HStack mt={6} spacing={4}>
        <Avatar w={12} h={12} name={name} src={imageSrc} />
        <VStack align="flex-start" spacing={1}>
          <Text variant="caption" fontWeight="bold" color="text.secondary">
            {name}
          </Text>
          <Text variant="caption" color="text.secondary">
            {description}
          </Text>
        </VStack>
      </HStack>
    </>
  );
}

export function StepProgressBar({
  stepInfo: { totalStepCount, currentStepIndex },
}: {
  stepInfo: StepInfo;
}) {
  const currentStep = currentStepIndex + 1;

  return (
    <Center w="full" maxW="sm" display="flex" flexDir="column" mb={10}>
      <Text variant="label" fontSize="lg">
        Step {currentStep} of {totalStepCount}
      </Text>
      <Progress
        value={(currentStep * 100) / totalStepCount}
        colorScheme="brand.main"
        bg="brand.lightest"
        w="full"
        h={2}
        mt={2}
      />
    </Center>
  );
}

export function RightFooter({
  onNextStep,
  includeNotSureButton,
  onNotSureClick,
  disabled,
  isLoading,
}: {
  onNextStep: () => void;
  includeNotSureButton?: boolean;
  onNotSureClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <Center w="full" mt={10} maxW="sm" mb={8}>
      {includeNotSureButton && (
        <Button onClick={onNotSureClick} variant="outline" colorScheme="primary" w="full" mr={4}>
          I&apos;m not sure
        </Button>
      )}
      <Button
        onClick={onNextStep}
        variant="solid"
        colorScheme="primary"
        w="full"
        disabled={isLoading || disabled}
        isLoading={isLoading}
      >
        Next
      </Button>
    </Center>
  );
}
export const RightContentContainer = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    display="flex"
    alignItems="center"
    width="full"
    mr={[0, 0, 8]}
    flex={1}
    p={[4, 4, 0]}
    flexDir="column"
    justifyContent={['flex-start', 'flex-start', 'center']}
    zIndex={['docked', 'docked', 'auto']}
    bgColor={['bg.primary', 'bg.primary', 'initial']}
    ref={ref}
    {...props}
  />
));

export function OnBoardingPage({
  rightContent,
  leftContent,
}: {
  rightContent: React.ReactNode;
  leftContent: React.ReactNode;
}) {
  return (
    <Flex
      flexDir="column"
      w="full"
      role="main"
      as="main"
      minH={['auto', 'auto', '100vh']}
      bg="bg.primary"
    >
      <Grid
        templateColumns="1fr 1fr"
        position={['relative', 'relative', 'absolute']}
        w="full"
        px={[4, 4, 8]}
        py={4}
        bg={['bg.tertiary', 'bg.tertiary', 'none']}
      >
        <Flex alignItems="center" zIndex={1}>
          <Box height={['1.875rem', 10, '2.375rem']}>
            <TerminalLogo />
          </Box>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end">
          <Button
            variant="ghost"
            to="/auth/login"
            as={RouterLink}
            colorScheme="accent"
            rightIcon={<LinkoutIcon />}
          >
            Login
          </Button>
        </Flex>
      </Grid>

      <Flex flexDir={['column-reverse', 'column-reverse', 'row']} flex={['none', 'none', 1]} px={0}>
        <Flex
          width={['auto', 'auto', '44.44%']}
          bg="bg.tertiary"
          justifyContent={['center', 'center', 'flex-end']}
          alignItems="center"
          px={[4, 4, 0]}
          py={[6, 6, 0]}
          position="relative"
        >
          <Flex
            mr={[0, 0, '7.5%']}
            alignItems="center"
            justifyContent="flex-start"
            width="full"
            maxW="26.5rem"
            zIndex={1}
          >
            <Flex
              w={['auto', 'auto', 'full']}
              flexDir="column"
              alignItems="flex-start"
              py={[0, 0, 32]}
            >
              <Box pb={[0, 0, 4]} px={[0, 0, 4]} bg="bg.transparent">
                {leftContent}
              </Box>
            </Flex>
          </Flex>
          <Box
            backgroundImage={backgroundImg}
            pointerEvents="none"
            backgroundRepeat="no-repeat"
            position={['fixed', 'fixed', 'absolute']}
            height="full"
            width="95%"
            bottom={0}
            left={0}
            backgroundPosition={['0 100%', '0 100%', 'right bottom']}
          />
        </Flex>
        <Flex
          flex={1}
          justifyContent={['center', 'center', 'flex-start']}
          pb={[4, 4, 4]}
          pt={[6, 6, 32]}
        >
          {rightContent}
        </Flex>
      </Flex>
    </Flex>
  );
}
