/* eslint-disable react/function-component-definition */
import type { ComponentProps } from 'react';
import type { StoryFn, Meta } from '@storybook/react';
import { EmailNotVerifiedWarningPage } from './EmailNotVerifiedWarningPage';
import { EmailNotVerifiedResendSuccessPage } from './EmailNotVerifiedResendSuccessPage';

export default {
  title: 'Global/Pages/Auth/Email Not Verified',
  component: EmailNotVerifiedWarningPage,
  parameters: {
    chromatic: { disableSnapshot: true },
  },
} as Meta;

const Template_EmailNotVerifiedWarningPage: StoryFn<
  ComponentProps<typeof EmailNotVerifiedWarningPage>
> = (args) => <EmailNotVerifiedWarningPage {...args} />;

export const story_EmailNotVerifiedPage_warningPage = Template_EmailNotVerifiedWarningPage.bind(
  {} as {},
);
story_EmailNotVerifiedPage_warningPage.args = {
  isLoading: false,
};
story_EmailNotVerifiedPage_warningPage.storyName = 'Warning Page';

export const story_EmailNotVerifiedPage_loading = Template_EmailNotVerifiedWarningPage.bind(
  {} as {},
);
story_EmailNotVerifiedPage_loading.args = {
  isLoading: true,
};
story_EmailNotVerifiedPage_loading.storyName = 'Loading';

const Template_EmailNotVerifiedResendSuccessPage: StoryFn<
  ComponentProps<typeof EmailNotVerifiedResendSuccessPage>
> = () => <EmailNotVerifiedResendSuccessPage />;

export const story_EmailNotVerifiedPage_success = Template_EmailNotVerifiedResendSuccessPage.bind(
  {} as {},
);
story_EmailNotVerifiedPage_success.storyName = 'Success';
