import type { ComponentProps } from 'react';
import { Typeahead } from '../Typeahead';

export function CustomSelect(
  typeaheadProps: ComponentProps<typeof Typeahead> & {
    isPlaceholderAnOption?: boolean;
  },
) {
  return (
    <Typeahead
      {...typeaheadProps}
      onSelectionChange={(field, value) => {
        (document.activeElement as HTMLElement)?.blur();
        typeaheadProps.onSelectionChange?.(field, value);
      }}
      options={
        typeaheadProps.isPlaceholderAnOption && typeaheadProps.placeholder
          ? [typeaheadProps.placeholder, ...typeaheadProps.options]
          : typeaheadProps.options
      }
      initialValue={
        // With this behavior we want to be as homogeneous as possible with the current api of the select (chakra-ui)
        typeaheadProps.initialValue || typeaheadProps.placeholder || typeaheadProps.options?.[0]
      }
      shouldOpenOnFocus
      showSelectIcon
      isTypingAllowed
    />
  );
}

export function CustomSelectWithCount(
  typeaheadProps: ComponentProps<typeof Typeahead> & {
    isPlaceholderAnOption?: boolean;
    inputValueToShow?: string;
  },
) {
  return (
    <Typeahead
      shouldOpenOnFocus
      showSelectIcon
      isTypingAllowed
      inputProps={{
        ...(!!typeaheadProps.formInputValue ? { _placeholder: { color: 'initial' } } : {}),
        value: !!typeaheadProps.formInputValue
          ? `${typeaheadProps.inputValueToShow || typeaheadProps.placeholder} (1)`
          : '',
        ...typeaheadProps.inputProps,
      }}
      {...typeaheadProps}
    />
  );
}
