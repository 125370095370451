/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import {
  getRedirectResult,
  updateProfile,
  getAdditionalUserInfo,
  getIdTokenResult,
} from 'firebase/auth';
import type { User } from 'firebase/auth';
import isWebview from 'is-ua-webview';
import { getUA } from 'react-device-detect';
import { from, of, defer } from 'rxjs';
import { map, concatAll, filter, catchError } from 'rxjs/operators';
import { firebaseAuth } from 'global/firebaseApp';
import {
  signOut,
  hasuraClaims,
  observeIdTokenChanged,
  observeAuthStateChanged,
  putUser,
  putUser$,
} from './authMethods';
import * as sentry from '../sentry';
import * as events from '../events';
import type { AuthData } from './types';

export function attachAuthToState({
  dispatch,
  appName,
}: {
  dispatch: (action: AsyncAction<AuthData>) => void;
  appName: string;
}) {
  const observeAuthStateChanged_subscription = observeAuthStateChanged(firebaseAuth).subscribe(
    (user) => {
      if (!user) {
        dispatch({
          type: 'resolved',
          data: {
            isAuthenticated: false,
            isEmailVerified: null,
            roles: [],
            user: null,
          },
        });
      }
    },
  );

  const putUser_failure_subscription = putUser$
    .pipe(
      filter((result) => !!result.error),
      map((result) => result.error),
    )
    // handle failed login
    .subscribe(async (error) => {
      await signOut({ auth: firebaseAuth });
      dispatch({
        type: 'rejected',
        data: {
          isAuthenticated: false,
          isEmailVerified: false,
          roles: [],
          user: null,
        },
        error,
      });
    });

  const observeIdTokenChanged_subscription = observeIdTokenChanged(firebaseAuth)
    .pipe(
      map((firebaseUser) =>
        firebaseUser
          ? // Using defer to make sure that the Observable and Promise creation happen only on subscription.
            defer(() =>
              from(putUser(firebaseUser, appName)).pipe(
                filter((result) => !!result.user),
                catchError(() => of(null)),
              ),
            )
          : of(null),
      ),
      concatAll(),
    )
    .subscribe((result) => {
      if (result) {
        const { firebaseUser, user, claims, intercomUserHash } = result;

        // set user in Sentry - start
        events.identify(user?.id, {
          user,
          candidate: user?.candidate,
          intercomUserHash,
        });

        if (user?.name) events.setUserProperties(user?.name, user?.email);

        Sentry.configureScope(sentry.makeConfigureScope(user));
        // set user in Sentry - end

        dispatch({
          type: 'resolved',
          data: {
            isAuthenticated: true,
            isEmailVerified: (firebaseUser as User).emailVerified,
            roles: claims?.[hasuraClaims]?.['x-hasura-allowed-roles'],
            user,
          },
        });
      } else {
        dispatch({
          type: 'resolved',
          data: {
            isAuthenticated: false,
            isEmailVerified: null,
            roles: [],
            user: null,
          },
        });
      }
    });

  // Tracks successful google sign
  getRedirectResult(firebaseAuth)
    .then(async (result) => {
      if (result?.user) {
        try {
          // Firebase doesn't pass name when linking account for Google SSO
          // so that needs to happen manually.
          if (!firebaseAuth.currentUser?.displayName) {
            const displayName = `${getAdditionalUserInfo(result)?.profile?.name}`;
            await updateProfile(result.user, { displayName });
            // syncs the user with the new display new  display name to the backend.
            await getIdTokenResult(result.user, true);
          }
        } catch (_) {
          // Tracking number of occurrences to help us determine if we
          // need to polyfill localStorage or resolve this properly by adding
          // state to auth.
          events.track(events.name.errorDisplayed, {
            type: 'no-local-storage',
            user_agent: getUA,
            is_webview: isWebview(getUA),
          });
        }

        // Track that auth is complete
        events.track(events.name.authentication.completed, {
          type: 'google',
          user_agent: getUA,
          is_webview: isWebview(getUA),
        });
      }
    })
    .catch((error: unknown) => {
      dispatch({
        type: 'rejected',
        error,
        data: { isAuthenticated: false, isEmailVerified: null, roles: [], user: null },
      });
    });

  return () => {
    observeIdTokenChanged_subscription.unsubscribe();
    observeAuthStateChanged_subscription.unsubscribe();
    putUser_failure_subscription.unsubscribe();
  };
}
