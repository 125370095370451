import { useEffect } from 'react';

import * as events from 'global/events';
import { useQuery } from 'global/utils';
import { JOB_FOLDER_APPROVED, JOB_FOLDER_FILLED } from 'talent-hub/constants';
import { useCustomerAuthorizedUserSession } from 'talent-hub/utils';

import { serializeManageRoles } from './ManageRoles.serializer';
import { SelectManageRoles } from './data';
import type { ManageRolesQueryVariables, ManageRolesQuery as ManageRolesQueryType } from './data';

import { ManageRoles } from './ManageRoles';

export function ManageRolesController() {
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const { loading, data } = useQuery<ManageRolesQueryType, ManageRolesQueryVariables>(
    SelectManageRoles,
    {
      variables: {
        organization: viewingOrganization.ID,
        job_folder_approved: JOB_FOLDER_APPROVED,
        job_folder_filled: JOB_FOLDER_FILLED,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { widgets, postingsList, filledRoles, rolesPendingReview } = serializeManageRoles(data);

  // Track page views
  useEffect(() => {
    // Track page view
    events.track(events.name.viewedDashboard);
  }, []);

  return (
    <ManageRoles
      filledRoles={filledRoles}
      isLoading={loading}
      pageLayoutProps={{
        isClientReviewer,
        isRecruiter,
        orgName: viewingOrganization.name,
        user,
        isClientProspect,
        userPrioritizedRole,
      }}
      postingsList={postingsList}
      widgets={widgets}
      rolesPendingReview={rolesPendingReview}
    />
  );
}
