/* eslint-disable no-underscore-dangle, react/no-this-in-sfc */
import { useEffect, useState, createContext, useContext } from 'react';
import Sentry from 'global/sentry';
import type { ReactNode } from 'react';

declare global {
  interface Window {
    experiment: any;
    _experiment: any;
    VWO: any;
    _vis_opt_queue: any;
  }
}

export function createExperimentProvider<T>({ defaultValue }: { defaultValue: T }): {
  ExperimentProvider: React.FC<{ children?: ReactNode }>;
  useExperiment: () => T;
  triggerExperiments: (ids: number[]) => void;
  triggerGoal: ({ goalID, campaignID }: { goalID: number; campaignID: number }) => void;
} {
  const ExperimentContext = createContext<T>(defaultValue);

  function ExperimentProvider({ children }: { children?: ReactNode }) {
    const [experiment, setExperiment] = useState<T>(defaultValue);

    useEffect(() => {
      try {
        let exp: T;
        // Set the value stored in window or set an "object trap" to listen once the value is set
        if (window?.experiment) {
          setExperiment((prevExperiment) => ({
            ...prevExperiment,
            ...((window?.experiment || {}) as T),
          }));
        } else {
          Object.defineProperties(window, {
            experiment: {
              set(value) {
                setExperiment((prevExperiment) => ({
                  ...prevExperiment,
                  ...((value || {}) as T),
                }));

                Sentry.setContext('character', {
                  experiment: JSON.stringify(value, null, 2),
                });
                exp = value;
              },
              get() {
                return exp;
              },
              configurable: true,
            },
          });
        }
      } catch (error: any) {
        const newError = new Error(
          `Non blocking experiment update error | ${error?.message || error}`,
        );
        // @ts-ignore
        newError.stack = error?.stack;
        // @ts-ignore
        newError.cause = error?.cause;

        Sentry.captureException(newError);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ExperimentContext.Provider value={experiment}>{children}</ExperimentContext.Provider>;
  }

  function useExperiment(): T {
    const experiment = useContext(ExperimentContext);

    if (experiment === undefined) {
      throw new Error('useExperiment must be used within a ExperimentProvider');
    }

    return experiment;
  }

  const triggerExperiments = (campaignIDs: number[]) => {
    window.VWO = window.VWO || [];
    window.VWO.push(['activate', false, campaignIDs, true]);
  };

  const triggerGoal = ({ goalID, campaignID }: { goalID: number; campaignID?: number }) => {
    window._vis_opt_queue = window._vis_opt_queue || [];
    window._vis_opt_queue.push(() => {
      // @ts-ignore
      _vis_opt_register_conversion(goalID, campaignID);
    });
  };

  return {
    ExperimentProvider,
    useExperiment,
    triggerExperiments,
    triggerGoal,
  };
}
