import type { SignInFormProps } from './SignInForm';
import { AuthPageTemplate } from '../components';
import type { AuthPageTemplateProps } from '../types';
import { SignInForm } from './SignInForm';

export function SignInPage({
  signInFormProps,
  headerSubtitle,
  onSignUpClick,
  pageTemplateOverwrite,
}: {
  headerSubtitle?: string;
  onSignUpClick?: () => void;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
  signInFormProps: SignInFormProps;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate
      headerTitle="Welcome Back"
      headerSubtitle={headerSubtitle}
      footerLinkOnClick={onSignUpClick}
    >
      <SignInForm
        onGoogleLoginClicked={signInFormProps.onGoogleLoginClicked}
        onForgotPasswordClick={signInFormProps.onForgotPasswordClick}
        onEmailSignSubmitted={signInFormProps.onEmailSignSubmitted}
        showLoadingForEmailSignIn={signInFormProps.showLoadingForEmailSignIn}
        disableGoogleSignInButton={signInFormProps.disableGoogleSignInButton}
        errorMessage={signInFormProps.errorMessage}
        email={signInFormProps.email}
        isEmailInputDisabled={!!signInFormProps.isEmailInputDisabled}
        hideForgotPassword={!!signInFormProps.hideForgotPassword}
        shouldHideGoogleSSO={signInFormProps.shouldHideGoogleSSO}
      />
    </PageTemplate>
  );
}
