import { Button, Text, ErrorCircleIcon } from '@terminal/design-system';
import { AuthMessage, AuthPageTemplate } from '../components';
import type { AuthRedirectTo, AuthPageTemplateProps } from '../types';

export function PasswordResetCodeExpiredPage({
  redirectTo,
  pageTemplateOverwrite,
}: {
  redirectTo: AuthRedirectTo;
  pageTemplateOverwrite?: React.FC<AuthPageTemplateProps>;
}) {
  const PageTemplate = pageTemplateOverwrite ?? AuthPageTemplate;

  return (
    <PageTemplate footerLinkOnClick={() => redirectTo('forgot-password')}>
      <AuthMessage icon={<ErrorCircleIcon />} title="Link Expired">
        <Text>
          This link is no longer active. If you would like to reset your password, please request a
          new link.
        </Text>
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={() => redirectTo('forgot-password')}
          mt={6}
          w={64}
        >
          Retry
        </Button>
      </AuthMessage>
    </PageTemplate>
  );
}
