import { useAuth } from 'global/auth';

/**
 * Assign the role based on highest ranked role, in this case recruiter
 * Ranks: 1. recruiter, 2. client, 3. client-reviewer
 * @param {string[]} userRoles
 * @returns {string} userPrioritizedRole
 */
export function selectUserPrioritizedRole(userRoles: string[]) {
  if (userRoles.includes('recruiter')) {
    return 'recruiter';
  }
  if (userRoles.includes('client')) {
    return 'client';
  }
  if (userRoles.includes('client-reviewer')) {
    return 'client-reviewer';
  }
  if (userRoles.includes('client-prospect')) {
    return 'client-prospect';
  }
  return 'authenticated';
}

export function useCustomerAuth(): {
  isAuthorized: boolean;
  userPrioritizedRole: ReturnType<typeof selectUserPrioritizedRole>;
  isClient: boolean;
  isRecruiter: boolean;
  isClientReviewer: boolean;
  isClientProspect: boolean;
} & ReturnType<typeof useAuth> {
  const useAuthProp = useAuth();

  const userPrioritizedRole = selectUserPrioritizedRole(useAuthProp.roles);

  const isClient = userPrioritizedRole === 'client';
  const isRecruiter = userPrioritizedRole === 'recruiter';
  const isClientReviewer = userPrioritizedRole === 'client-reviewer';
  const isClientProspect = userPrioritizedRole === 'client-prospect';

  return {
    ...useAuthProp,
    // ClientReviewers must verify that they are the owners of the email, as someone with the signup structure could impersonate them and sign up as them.
    isAuthorized:
      isClient ||
      isRecruiter ||
      (isClientReviewer && !!useAuthProp.isEmailVerified) ||
      (isClientProspect &&
        useAuthProp.user?.email &&
        (useAuthProp.user?.skip_email_verify === true || useAuthProp.isEmailVerified === true)),
    userPrioritizedRole,
    isClient,
    isRecruiter,
    isClientReviewer,
    isClientProspect,
  };
}
