import { useEffect } from 'react';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import { useSignUp, googleSignIn, createPasswordYupValidation } from 'global/auth';
import { validators } from 'global/utils';
import * as Yup from 'yup';
import { firebaseAuth } from 'global/firebaseApp';
import Sentry from 'global/sentry';
import { useMutation } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import { useCustomerAuth, triggerGoal } from 'talent-hub/utils';
import { BlankScreenLoading } from 'global/components';
import { UpsertLastStepCompleted } from '../../data/graphql';
import type {
  UpsertLastStepCompletedMutationVariables,
  UpsertLastStepCompletedMutation,
} from '../../data/graphql';
import { SignUp } from './SignUp';
import type { OnboardingState } from '../../../Prospect.types';
import { SignUpSuccessController } from './SignUpSuccessController';

const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please make sure your email address is valid')
    .test(
      'Allowed domains',
      'Please make sure your are using your company email',
      (value) => !/gmail|yahoo|outlook|hotmail/.test(value || ''),
    )
    .required('Please include an email address.'),
  fullName: Yup.string()
    .transform((value) => value?.trim() || '')
    .matches(validators.fullName.regex, validators.fullName.message)
    .required('Please include your full name.'),
  password: createPasswordYupValidation(),
});

export function SignUpController({
  onStepComplete,
  continueURL,
  roles,
}: {
  onStepComplete: () => void;
  continueURL: string;
  roles: OnboardingState['roles'];
}) {
  const auth = useCustomerAuth();

  const [upsertLastStepCompleted] = useMutation<
    UpsertLastStepCompletedMutation,
    UpsertLastStepCompletedMutationVariables
  >(UpsertLastStepCompleted, {
    onError: (error: ApolloError) => {
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (firebaseAuth.currentUser?.isAnonymous)
      return () => {
        clearTimeout(timer);
      };

    // Handling the edge where user main now have the first_name. we don't want the user
    // get stock on seeing the loading bar if for one reason or other they don't have first_name
    if (!auth.user?.first_name) {
      // After 4 second complete the step
      timer = setTimeout(() => {
        triggerGoal({ goalID: 1, campaignID: 82 });
        upsertLastStepCompleted({
          variables: {
            userId: auth.user?.id as number,
            lastStepCompleted: 'Signup',
          },
        });
        onStepComplete();
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }

    // To work around a race issue where user gets authorized before the
    // createAuth..putUser function is resolved. Since non-anonymous user
    // will always have first_name, we can wait until first_name is in auth user
    triggerGoal({ goalID: 1, campaignID: 82 });
    upsertLastStepCompleted({
      variables: {
        userId: auth.user?.id as number,
        lastStepCompleted: 'Signup',
      },
    });

    if (auth.isEmailVerified) {
      // the onStepComplete redirects the user out of the onboarding and should be done after
      // email is verified. For email sign up, the email is verified after user clicks on the email
      // verification link. For Google SSO, the email is verified right away.
      onStepComplete();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    auth.user?.first_name,
    auth.user?.id,
    upsertLastStepCompleted,
    onStepComplete,
    auth.isEmailVerified,
  ]);

  const {
    handleSignUpSubmit,
    isLoading,
    isResolved: signUpIsResolved,
  } = useSignUp({
    continueURL,
    contextRole: 'authenticated',
  });

  const formik = useCustomFormik<{ email: string; fullName: string; password: string }>({
    initialValues: {
      email: '',
      fullName: '',
      password: '',
    },
    validationSchema: signUpValidationSchema,
    onSubmit: ({ email, fullName, password }) => {
      handleSignUpSubmit({ email, fullName, password });
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (auth.isEmailVerified) {
    return <BlankScreenLoading aria-label="Loading" />;
  }

  if (signUpIsResolved || !firebaseAuth.currentUser?.isAnonymous) {
    return <SignUpSuccessController />;
  }

  return (
    <SignUp.Variation.MatchingCandidates
      isLoading={isLoading}
      shouldHideGoogleSSO
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      submitCount={formik.submitCount}
      onSubmit={formik.handleSubmit}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      onGoogleLoginClicked={() => googleSignIn({ auth: firebaseAuth, dispatch: auth.dispatch })}
      highlightedRole={{
        roleName: roles?.[0]?.roleDisplayName || '',
        experienceDescription: roles?.[0]?.experienceDescription || '',
        skills: roles?.[0]?.skillNames || [],
      }}
      hasMatches
    />
  );
}
