import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import type {
  SelectAiCandidatesMatchingJobMutation,
  SelectAiCandidatesMatchingJobMutationVariables,
} from './data';
import { type PostingWithTotals } from '../Dashboard.serializer';
import { DashboardActiveRole } from './DashboardActiveRole';

import { SelectAICandidatesMatchingJob } from './data';
import { dashboard_active_role_serializer } from './DashboardRole.serializer';

export function DashboardActiveRolesController({ role }: { role: PostingWithTotals }) {
  const history = useHistory();
  const [candidates_by_ai_matching_mutation, { data, loading, error }] = useMutation<
    SelectAiCandidatesMatchingJobMutation,
    SelectAiCandidatesMatchingJobMutationVariables
  >(SelectAICandidatesMatchingJob, {
    variables: role.candidates_ai_matching_args,
    context: {
      service: 'service',
    },
  });

  if (error) throw error;

  useEffect(() => {
    candidates_by_ai_matching_mutation();
    // We don't want to run this effect again irrespective of anything changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const active_role = dashboard_active_role_serializer(data, role);

  return (
    <DashboardActiveRole
      id={active_role.id}
      name={active_role.name}
      totalActiveCandidates={active_role.totalActiveCandidates}
      openHeadCount={active_role.openHeadCount}
      totalHired={active_role.totalHired}
      totalCandidates={active_role.totalCandidates}
      candidates_to_review_per_role={active_role.candidates_to_review_per_role}
      currentRoleCandidateMatches={active_role.currentRoleCandidateMatches}
      view_all_candidate_matches_link={active_role.view_all_candidate_matches_link}
      handleCandidateClick={(candidate_id) => {
        history.push(`/dashboard/candidate/${candidate_id}`); // TODO: I think this should be a router link instead?
      }}
      is_loading_candidate_matches_by_role={loading}
    />
  );
}
