import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useQuery } from 'global/utils';
import { useCustomerAuthorizedUserSession, useSavedCandidates } from 'talent-hub/utils';
import * as Yup from 'yup';
import { useToast } from '@terminal/design-system';
import { BlankScreenLoading } from 'global/components';
import Sentry from 'global/sentry';
import { useCalendlyWidget } from 'talent-hub/role/prospect/components/CalendlyWidget';
import {
  Client_Prospect_Events_Choices_Enum,
  Client_Prospect_Constraint,
  Client_Prospect_Update_Column,
} from 'global/types/hasura-tables.generated.types';

import type {
  Role_Choices_Enum,
  Candidate_Curation_Years_Of_Exp_Range_Choices_Enum,
} from 'global/types/hasura-tables.generated.types';

import { candidateSearchDateRange, ViewType } from 'talent-hub/shared/features/explore-candidates';
import { sortOptionBackendValues } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { useCustomFormik } from 'global/utils/useCustomFormik';
import type { ApolloError } from '@apollo/client';

import { CandidateProfileController } from '../explore-candidates/candidate-profile';
import {
  serializeDashboard,
  serializeDashboardCandidates,
  shouldCallSaveCandidateFunction,
} from './Dashboard.serializer';
import {
  InsertClientProspectEvents,
  SelectProspectDashboardQuery,
  SelectBrowseCandidates,
  SelectDashboardBrowseCandidates,
  DeleteInsertClientProspectRoles,
} from './data';
import type {
  SelectProspectDashboardQueryQuery,
  SelectProspectDashboardQueryQueryVariables,
  InsertClientProspectEventsMutation,
  InsertClientProspectEventsMutationVariables,
  SelectDashboardBrowseCandidatesQuery,
  SelectDashboardBrowseCandidatesQueryVariables,
  SelectBrowseCandidatesQuery,
  SelectBrowseCandidatesQueryVariables,
  DeleteInsertClientProspectRolesMutation,
  DeleteInsertClientProspectRolesMutationVariables,
} from './data';
import { Dashboard } from './Dashboard';

const validationSchema = Yup.object().shape({
  role: Yup.string().required('Role is required'),
  yearsOfExperience: Yup.string().required('Years of experience is required'),
});

export function DashboardController() {
  const [candidateID, setCandidateID] = useState<number | undefined>(undefined);
  const calendlyModal = useCalendlyWidget();
  const toast = useToast({
    position: 'bottom-left',
    duration: 4000,
  });
  const {
    isClientReviewer,
    isRecruiter,
    user,
    viewingOrganization,
    isClientProspect,
    userPrioritizedRole,
  } = useCustomerAuthorizedUserSession();
  const [saveRoles, { loading: isLoading_deleteInsertRole }] = useMutation<
    DeleteInsertClientProspectRolesMutation,
    DeleteInsertClientProspectRolesMutationVariables
  >(DeleteInsertClientProspectRoles, {
    onError: (error: ApolloError) => {
      toast({
        description: 'Something went wrong. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
    context: {
      role: 'client-prospect',
    },
    update: (cache, { data: updateData }) => {
      const readData = cache.readQuery<SelectProspectDashboardQueryQuery>({
        query: SelectProspectDashboardQuery,
        variables: {
          user_id: user?.id as number,
        },
      });

      cache.writeQuery({
        query: SelectProspectDashboardQuery,
        broadcast: true,
        variables: {
          user_id: user?.id as number,
        },
        data: {
          ...readData,
          client_prospect: [
            {
              ...readData?.client_prospect,
              client_prospect_roles: [
                {
                  role_type: updateData?.insert_client_prospect_role_one?.role_choice?.value,
                  years_of_experience:
                    updateData?.insert_client_prospect_role_one?.years_of_experience,
                  client_prospect_role_skills: [],
                },
              ],
            },
          ],
        },
      });
    },
  });
  const formik = useCustomFormik<{ role: string; yearsOfExperience: string }>({
    initialValues: {
      role: '',
      yearsOfExperience: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: ({ role, yearsOfExperience }) => {
      saveRoles({
        variables: {
          role: {
            role_type: (role as Role_Choices_Enum) || null,
            years_of_experience:
              (yearsOfExperience as Candidate_Curation_Years_Of_Exp_Range_Choices_Enum) || null,
            client_prospect: {
              data: {
                user_info_id: user?.id as number,
              },
              on_conflict: {
                constraint: Client_Prospect_Constraint.ClientProspectUserInfoIdKey,
                update_columns: [Client_Prospect_Update_Column.Email],
              },
            },
          },
          user_info_id: user?.id as number,
        },
      });
    },
  });

  // #region prospect calendly event info
  const { data, loading: isLoading_prospectData } = useQuery<
    SelectProspectDashboardQueryQuery,
    SelectProspectDashboardQueryQueryVariables
  >(SelectProspectDashboardQuery, {
    variables: {
      user_id: user?.id as number,
    },
    context: {
      role: 'client-prospect',
    },
  });
  const { clientProspect, statusInfo, roleOptions, yoeOptions } = serializeDashboard(data);
  const clientProspect_roleType = clientProspect?.jobOpenings?.[0]?.type;
  // #endregion

  const browseCandidatesQuery_defaultVariables = useMemo(
    () => ({
      organization: viewingOrganization.ID,
      user_id: user?.id,
      updatedAfter: candidateSearchDateRange,
      isClientProspect,
      candidate_args: {
        organization_id: viewingOrganization.ID,
        must_have_skill_ids: [],
        regions: null,
        sort_option: sortOptionBackendValues.Recommended,
        employment_type: null,
        badges: null,
        candidate_role: clientProspect_roleType || null,
        should_have_skill_ids:
          clientProspect?.jobOpenings?.[0]?.skills?.map((skill) => skill.id) || [],
        min_years_of_experience: clientProspect?.jobOpenings?.[0]?.yoe || null,
      },
    }),
    // Ignoring this since the comparison of data that we need is already covered by the change of clientProspect_roleType
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isClientProspect, user?.id, viewingOrganization.ID, clientProspect_roleType],
  );

  const browseOtherCandidatesQuery_defaultVariables = useMemo(() => {
    const candidateBrowseArgs = {
      organization_id: viewingOrganization.ID,
      candidate_role: null,
      must_have_skill_ids: [],
      should_have_skill_ids: [],
      regions: null,
      min_years_of_experience: null,
      sort_option: sortOptionBackendValues.Recommended,
      employment_type: null,
      badges: null,
    };

    return {
      organization: viewingOrganization.ID,
      user_id: user?.id,
      updatedAfter: candidateSearchDateRange,
      isClientProspect,
      candidate_recently_active_args: {
        ...candidateBrowseArgs,
        sort_option: null,
      },
      candidate_top_company_experience_args: {
        ...candidateBrowseArgs,
        badges: ['top_company_exp'],
      },
      candidate_zero_one_experience_args: {
        ...candidateBrowseArgs,
        badges: ['built_new'],
      },
      candidate_tech_lead_args: {
        ...candidateBrowseArgs,
        badges: ['tech_leader'],
      },
    };
  }, [isClientProspect, user?.id, viewingOrganization.ID]);

  const { loading: isLoading_roleCandidates, data: browseCandidates_byRoleData } = useQuery<
    SelectBrowseCandidatesQuery,
    SelectBrowseCandidatesQueryVariables
  >(SelectBrowseCandidates, {
    variables: {
      ...browseCandidatesQuery_defaultVariables,
    },
    notifyOnNetworkStatusChange: true,
    skip: !clientProspect_roleType,
    context: {
      role: 'client-prospect',
    },
  });

  const { loading: isLoading_dashboardCandidates, data: browseCandidates_data } = useQuery<
    SelectDashboardBrowseCandidatesQuery,
    SelectDashboardBrowseCandidatesQueryVariables
  >(SelectDashboardBrowseCandidates, {
    variables: {
      ...browseOtherCandidatesQuery_defaultVariables,
    },
    notifyOnNetworkStatusChange: true,
    skip: isLoading_prospectData,
    context: {
      role: 'client-prospect',
    },
  });

  const {
    candidates,
    candidatesRecentlyActive,
    candidatesTopCandidateExperience,
    candidateZeroOneExperience,
    candidateTechLead,
  } = serializeDashboardCandidates(browseCandidates_byRoleData, browseCandidates_data, {
    skillsToSearch: clientProspect?.jobOpenings?.[0]?.skills?.map((skill) => skill.id) || [],
    selectedYoE: clientProspect.jobOpenings?.[0]?.yoe,
    highlightBadgeType: null,
    isFilterSelected: true,
    isLoadingCandidates: isLoading_roleCandidates || isLoading_dashboardCandidates,
  });

  // #region insert logic
  const [insertProspectEvents] = useMutation<
    InsertClientProspectEventsMutation,
    InsertClientProspectEventsMutationVariables
  >(InsertClientProspectEvents, {
    refetchQueries: [SelectProspectDashboardQuery],
    onError: (error) => {
      toast({
        description: 'Something went wrong trying to save the step info. Please try again!',
        status: 'error',
      });

      Sentry.captureException(error);
    },
  });
  // #endregion

  const handleInsertProspectEvents = useCallback(
    (step: Client_Prospect_Events_Choices_Enum) => {
      insertProspectEvents({
        variables: {
          user_id: user?.id as number,
          event_choice: step,
        },
      });
    },
    [insertProspectEvents, user?.id],
  );

  // #region check if the user already saved a candidate
  const {
    savedByMeCandidatesCount,
    deleteSavedCandidate,
    insertSavedCandidate,
    loadingFavoriteCandidatesCount,
  } = useSavedCandidates();
  const handleOnRemoveSaveCandidate = (savedCandidateId: number) => {
    return deleteSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };
  const handleOnSaveCandidate = (savedCandidateId: number) => {
    return insertSavedCandidate({
      variables: { candidate_id: savedCandidateId, saved_by_user_id: user?.id || 0 },
    });
  };

  useEffect(() => {
    if (
      shouldCallSaveCandidateFunction(
        statusInfo.status.SAVE_CANDIDATE,
        savedByMeCandidatesCount,
        loadingFavoriteCandidatesCount,
      )
    ) {
      handleInsertProspectEvents(Client_Prospect_Events_Choices_Enum.SaveCandidate);
    }
  }, [
    handleInsertProspectEvents,
    savedByMeCandidatesCount,
    loadingFavoriteCandidatesCount,
    statusInfo.status.SAVE_CANDIDATE,
  ]);

  // * Tracked in the first visualization, as pendo will auto-open it.
  useEffect(() => {
    if (statusInfo.status.ENTER_TRIAL === 'pending') {
      handleInsertProspectEvents(Client_Prospect_Events_Choices_Enum.EnterTrial);
    }
  }, [handleInsertProspectEvents, statusInfo.status.ENTER_TRIAL]);

  if (!browseCandidates_data || !data || isLoading_prospectData || isLoading_dashboardCandidates)
    return <BlankScreenLoading />;

  return (
    <>
      <Dashboard
        pageLayoutProps={{
          isClientReviewer,
          isRecruiter,
          orgName: viewingOrganization.name,
          user,
          isClientProspect,
          userPrioritizedRole,
          isCallScheduled: calendlyModal.isCallScheduled,
          onUpgradeAccountClick: calendlyModal.isCallScheduled ? undefined : calendlyModal.onOpen,
        }}
        userDisplayName={user?.first_name || user?.email}
        onCandidatePreviewClick={(id) => setCandidateID(id)}
        hasRolesCreated={!!clientProspect_roleType}
        roleOptions={roleOptions}
        yoeOptions={yoeOptions}
        clientProspect={clientProspect}
        candidates={candidates}
        candidatesRecentlyActive={candidatesRecentlyActive}
        candidatesTopCandidateExperience={candidatesTopCandidateExperience}
        candidateZeroOneExperience={candidateZeroOneExperience}
        candidateTechLead={candidateTechLead}
        values={formik.values}
        errors={formik.errors}
        setFieldValue={formik.setFieldValue}
        submitForm={formik.submitForm}
        resetForm={formik.resetForm}
        isFormValid={formik.isValid}
        isFormSubmitting={isLoading_deleteInsertRole}
        isLoadingMyRoles={isLoading_roleCandidates}
      />
      <CandidateProfileController
        candidateID={Number(candidateID)}
        onCloseCandidateProfileClick={() => {
          setCandidateID(undefined);
        }}
        handleOnRemoveSaveCandidate={handleOnRemoveSaveCandidate}
        handleOnSaveCandidate={handleOnSaveCandidate}
        isInLimitedMode
        onOpenBookCallModal={calendlyModal.onOpen}
        viewPage={ViewType.SEARCH}
      />
    </>
  );
}
