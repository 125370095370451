import 'firebase/auth'; // do we need this?
import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import type { ComponentType } from 'react';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from 'global/sentry';
import * as events from 'global/events';
import mixpanel from 'global/events/mixpanel';
import marketing from 'global/events/marketing';
import { AuthProvider } from 'global/auth';
import { ChakraProvider, Fonts } from '@terminal/design-system';
import { graphqlClient } from 'global/graphqlClient';
import segment from 'global/events/segment';
import { ActivityProvider } from 'global/components';
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { defaultLDFlag, selectUserPrioritizedRole } from 'talent-hub/utils';
import { ErrorFallback } from 'talent-hub/components';
import { ExperimentProvider } from 'talent-hub/utils/experiment';

import { AppRouter } from './App.router';
import { theme } from './theme';

if (['production', 'prod'].includes(process.env.NODE_ENV || '')) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const enabledTrackers = [mixpanel, marketing];
const segmentEnabled = !import.meta.env.REACT_APP_SEGMENT_HUB_KEY?.startsWith('__');

if (segmentEnabled) {
  // @ts-ignore // TODO: [TP-1473] Fix this
  enabledTrackers.push(segment);
}

events.initialize(enabledTrackers, {
  mixpanelToken: import.meta.env.REACT_APP_MIXPANEL_DASHBOARD_TOKEN,
  segmentWriteKey: import.meta.env.REACT_APP_SEGMENT_HUB_KEY,
});

Sentry.init({ dsn: import.meta.env.REACT_APP_SENTRY_DSN_CUSTOMER });

export const apolloClient = graphqlClient(
  'customer:talent-hub',
  (userRoles: string[]) => selectUserPrioritizedRole(userRoles),
  {
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            explore_candidates: offsetLimitPagination(['args']),
          },
        },
      },
    }),
  },
);

/**
 * Wraps the application with all the required HoC's and Providers
 * required for its correct operation. This abstraction allows us to use the MemoryRouter or a different Router in the tests,
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Application component tree
 * @param {React.ComponentProps<typeof AuthProvider>['testing']} [props.authTesting] - Testing mode for AuthProvider
 * @returns {JSX.Element} AppFixedWrappers component
 */
export function AppFixedWrappers({
  children,
  authTesting,
}: {
  children: React.ReactNode;
  authTesting?: React.ComponentProps<typeof AuthProvider>['testing'];
}) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorFallback
          onActionClick={() => {
            window.location.reload();
          }}
        />
      }
    >
      <ExperimentProvider>
        <AuthProvider appName="talent-hub" testing={authTesting}>
          <ApolloProvider client={apolloClient}>
            <ChakraProvider theme={theme} resetCSS>
              <ActivityProvider>{children}</ActivityProvider>
            </ChakraProvider>
          </ApolloProvider>
        </AuthProvider>
      </ExperimentProvider>
    </Sentry.ErrorBoundary>
  );
}

/**
 * App component that wraps AppFixedWrappers with React Router (BrowserRouter) and
 * [LaunchDarkly](https://github.com/terminalinc/product/pull/2301#issue-1436164908)
 *
 * @returns {JSX.Element} App component
 */
const AppWithOptionalLD = (children: ComponentType<{}>) => {
  const launchDarklyClientID = import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID?.trim();
  if (launchDarklyClientID) {
    return withLDProvider({
      clientSideID: launchDarklyClientID,
      user: { key: 'anonymous-client', anonymous: true, custom: { app: 'talent-hub' } },
      deferInitialization: true,
      options: {
        logger: basicLogger({ level: 'error' }),
      },
      // * Launch Darkly default values - https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk - https://docs.launchdarkly.com/sdk/features/evaluating#javascript
      flags: defaultLDFlag,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    })(children);
  }
  return children;
};

// https://github.com/terminalinc/product/pull/2301#issue-1436164908
export const App = AppWithOptionalLD(() => (
  <AppFixedWrappers>
    <BrowserRouter>
      <Fonts />
      <AppRouter />
    </BrowserRouter>
  </AppFixedWrappers>
));
