import { forwardRef, useCheckbox, VisuallyHidden } from '@chakra-ui/react';
import type { CheckboxProps } from '@chakra-ui/react';
import type { ButtonProps } from '../../Button';
import { Button } from '../../Button';
import { PreferredIcon, PreferredFilledIcon } from '../../icons';
import { Box } from '../../layout';

type CheckboxButtonColorScheme = 'primary' | 'accent' | 'error';

const colorMap: {
  [key in CheckboxButtonColorScheme]: {
    color: string;
    hoverColor: string;
    hoverBg: string;
    activeBg: string;
  };
} = {
  primary: {
    color: 'ui.primary',
    hoverColor: 'brand.darkest',
    hoverBg: 'brand.lightest',
    activeBg: 'brand.lighter',
  },
  accent: {
    color: 'accent.main',
    hoverColor: 'accent.darker',
    hoverBg: 'accent.lightest',
    activeBg: 'accent.lighter',
  },
  error: {
    color: 'text.error',
    hoverColor: 'ui.darker.error',
    hoverBg: 'ui.lightest.error',
    activeBg: 'ui.lightest.error',
  },
};

export type CheckboxButtonProps = Omit<CheckboxProps, 'onClick' | 'colorScheme'> & {
  checkedIcon?: React.ReactNode;
  unCheckedIcon?: React.ReactNode;
  onClick: ButtonProps['onClick'];
  colorScheme?: CheckboxButtonColorScheme;
};

export const CheckboxButton = forwardRef<CheckboxButtonProps, 'input'>(
  (
    {
      isDisabled,
      isChecked = false,
      onClick,
      onChange,
      checkedIcon = <PreferredFilledIcon color="brand.main" />,
      unCheckedIcon = <PreferredIcon />,
      children,
      colorScheme = 'primary',
      ...rest
    },
    ref,
  ) => {
    const { getInputProps, getCheckboxProps, getRootProps, state } = useCheckbox({
      ...rest,
      isDisabled,
      isChecked,
      onChange,
    });

    const { activeBg, color, hoverBg } = colorMap[colorScheme];
    return (
      <Button
        color={color}
        bg={state.isChecked ? activeBg : 'inherit'}
        _hover={{
          bg: hoverBg,
          cursor: 'pointer',
        }}
        {...getRootProps()}
        {...getCheckboxProps()}
        variant="outline"
        onClick={onClick}
        aria-checked={state.isChecked}
        as="label"
      >
        <VisuallyHidden as="input" type="checkbox" {...getInputProps({}, ref)} />
        <Box display="inline" mr={children ? 1 : 0}>
          {state.isChecked ? checkedIcon : unCheckedIcon}
        </Box>

        {children}
      </Button>
    );
  },
);
